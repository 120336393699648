import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { FormBuilderServiceService } from '../form-builder-service.service';

@Component({
  selector: 'app-form-builder-json-preview',
  templateUrl: './form-builder-json-preview.component.html',
  styleUrls: ['./form-builder-json-preview.component.scss']
})
export class FormBuilderJsonPreviewComponent implements OnInit {

  // lightbox
  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  // eof: lightbox

  selectedFile: File = null;
  closePeview = false;
  private fileInput: ElementRef;
  private jsonElement: ElementRef;
  @ViewChild('fileInput') set fInput(content: ElementRef) {
    if (content) {
      this.fileInput = content;
    }
  }
  @ViewChild('json') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
        this.jsonElement = content;
        if ((this.formBuilderService.previewJson || this.formBuilderService.previewList) && this.jsonElement) {
          this.jsonElement.nativeElement.innerHTML = '';
          this.jsonElement.nativeElement.appendChild(document.createTextNode(this.formBuilderService.stringifiedJson));
        }
    }
  }

  constructor(
    public formBuilderService: FormBuilderServiceService,
    private helper: HelperServiceService
  ) { }

  ngOnInit(): void {
    console.log('outputs', this.formBuilderService.currentOutputs);
  }

  onInitiateImport_depercated(event) {
    event.preventDefault();
    const confirmation = confirm('Are you sure you want to import new JSON file? It will override the current form!');
    if (!confirmation) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  onInitiateImport(event) {
    event.preventDefault();
    // build the content for the Create new project lightbox
    this.formType = `Confirm starting a new template`;
    this.formClass = '';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = '';
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'delete_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `You are currently editing template <strong>${this.formBuilderService.getNameBySlug(this.formBuilderService.currentTemplate)}</strong>. Are you sure you want to import new JSON file? Any changes made to the current form will not be saved!`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Ok',
                // 'id': 'accept',
                'type': 'button',
                'action': 'onCreateNewFormAccept',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'type': 'button',
                'action': 'onDeleteTemplateReject',
                'class': 'form-button-2',
                'display': true
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onCreateNewFormAccept() {
    this.displayModal = false;
    this.formBuilderService.clearCurrentTemplate();
    this.fileInput.nativeElement.click();
  }

  onInitiateFirstImport(event) {
    event.preventDefault();
    this.closePeview = true;
    // this.formBuilderService.togglePreviewJson();
    this.fileInput.nativeElement.click();
  }

  onImport(event) {
    this.selectedFile = event.target.files[0];
    this.displayLoader = true;
    setTimeout(() => {
      this.processSelectedFile();
      if (this.closePeview) {
        this.formBuilderService.togglePreviewJson();
      }
      this.displayLoader = false;
      this.closePeview = false;
    }, 350);
  }

  processSelectedFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = (reader) => {
      if (reader && reader.target) {
        this.formBuilderService.setFormJson(<string>(reader.target as FileReader).result, true, true);
      }
    };

    fileReader.onerror = (error) => {
      console.log(error);
    };
  }

  // drag and drop file methods
  OnJsonFileUploadDragEnter(event) {
    event.preventDefault();
    $(event.target).css('background', '#f6feff');
  }
  OnJsonFileUploadDragOver(event) {
    event.preventDefault();
  }
  OnJsonFileUploadDragLeave(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
  }
  OnJsonFileUploadDrop(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    const file = event.dataTransfer.files[0];
    const match = ['text/json', 'application/json'];
    if (!match.includes(file.type)) {
      alert('This file type is not supported.\nOnly json files are allowed');
      return;
    }
    this.displayLoader = true;
    setTimeout(() => {
      this.selectedFile = file;
      this.processSelectedFile();
      this.formBuilderService.togglePreviewJson();
      this.displayLoader = false;
    }, 350);
  }

  OnJsonFileUploadClick(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#' + $(event.target).attr('data-target')).trigger('click');
  }

  onLightboxConfirm(returnedAction) {
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    this.displayLoader = true;
  }

  onLightboxClose($event) {
    this.displayModal = false;
  }

}
