<div *ngIf="!debugMode">
  <header class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}" [ngStyle]="{'background-color': projectColor}">

    <div  class="d-flex flex-column flex-fill justify-content-center align-items-center mb-5 vlanding-container">
      <div class="header-top d-flex justify-content-center justify-content-lg-between mx-auto py-3">
        <div class="mx-auto navbar p-0">
          <a  class="navbar-brand mr-0">
            <img class="lightbox-class-logo-img" src ="{{logo}}" *ngIf="logo !== null"></a>
        </div>
      </div>

      <div class="organizational-details" *ngIf="waitingState">
        <h1 [ngClass]="projectTextColorClass" class="mb-4 text-uppercase">{{pleaseWaitMsg}}</h1>
        <div>
          <div class="od-main-container">
              <img src='/assets/images/spinner-green.svg' class="od-image">
            <h4 class="od-subtitle text-center" [innerHtml]="stateMessage">
            </h4>
          </div>
        </div>
      </div>
      <div class="organizational-details" *ngIf="!consent && !(errorState || waitingState)"  [ngStyle]="{'background-color': projectColor}">
        <div class="contentWrapper">
          <div class="od-main-container px-2 pt-2 pb-0">
              <img src='/assets/images/request_screening.svg' class="od-image">
            <h4 class="od-subtitle">
              {{"IDPCS.landing.title" | translate}}
            </h4>
            <p>
              {{ (this.projectMessage === undefined || this.projectMessage === '') ? this.helper.returnDefaultUserVerificationMessage(project) : this.projectMessage}}
            </p>
            <div id="organization-info-wrapper" class="d-flex align-items-center flex-column">
              <p [innerHTML] = "('IDPCS.landing.content2' | translate : { privacyUrl: privacyUrl ? privacyUrl : 'https://meshid.com/privacy'})" class="disclaimer-text">
              </p>
              <button style="font-size:18px;" class="form-button form-button-1 form-button-full-width mt-2" (click)="generalConsentGiven()">
                {{"IDPCS.button" | translate}}
              </button>
            </div>
          </div>
          <app-language-switcher *ngIf="showLanguageOption"></app-language-switcher>
          <div class="load-overlay d-flex position-fixed w-100 h-100" *ngIf="consentGiven">
            <img class="my-auto mx-auto" height="66px" src="/assets/images/spinner.svg" width="66px">
          </div>
        </div>
      </div>
      <div *ngIf="!errorState && !waitingState && consent && consentParsed" class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass">
        <h1 [ngClass]="projectTextColorClass" class="mb-4">{{verificationHeading}}</h1>
        <div class="contentWrapper">
          <app-content-builder [contentDOM]="contentDom" (contentReturn)='parseContentReturn($event)'></app-content-builder>
          <app-language-switcher *ngIf="showLanguageOption"></app-language-switcher>
        </div>
      </div>
    </div>
    <div class="load-overlay d-flex position-fixed w-100 h-100" *ngIf="displayLoader">
      <img src="/assets/images/spinner.svg" width="66px" height="66px" class="my-auto mx-auto">
    </div>
    <button (click)="goBackButton()" *ngIf="showBackButton" class="form-button form-button-1 back-button">
      {{backbuttonText}}
    </button>
  </header>
  </div>


