<div class="project-lightbox" *ngIf="displaySelector">
  <div class="d-flex w-100 h-100">
    <a class="close-form-button" href="#" (click)="OnCloseProjectSelect($event)"><img src="assets/images/close_form_icon.png"></a>
    <div class="d-flex flex-column flex-fill justify-content-center">
      <h3 class="mx-auto text-white mb-5">

        {{
          (projectType) === 'Investor' ? ('appSpecific.MyProfiles' | translate) :
          (projectType) === 'Project' ? 'Your projects' :
          (projectType) === 'Fund' ? 'Your projects' : ''
        }}
      </h3>
      <app-id-verification-project-list (DisplayLightbox)='OnDisplayLightbox()'></app-id-verification-project-list>
    </div>
  </div>
</div>
<!-- DISPLAY PROJECT SETTINGS -->
<!-- <div class="project-settings-lightbox" *ngIf="displaySelectorProjectSettings && !displaySelectorProjectCustomization && activeProjectSettingsContent.length > 0">
    <div class="d-flex w-100 h-100">
      <a class="close-form-button" href="#" (click)="OnCloseProjectSettings($event)"><img src="assets/images/close_form_icon.png"></a>
      <div class="project-settings-wrapper d-flex mx-auto my-auto p-4">
        <app-content-builder [contentDOM]="activeProjectSettingsContent" (contentReturn)='parseContentReturn($event)' class="w-100"></app-content-builder>
      </div>
    </div>
</div> -->
<!-- DISPLAY PROJECT CUSTOMIZATION: SELECT LOGO AND COLOR -->
<div class="project-settings-lightbox" *ngIf="displaySelectorProjectSettings && displaySelectorProjectCustomization && activeProjectCustomizationContent.length > 0">
  <div class="d-flex w-100 h-100">
    <div class="project-customization-settings-wrapper d-flex mx-auto my-auto p-4">
      <app-content-builder [contentDOM]="activeProjectCustomizationContent" (contentReturn)='parseContentReturn($event)' class="w-100"></app-content-builder>
    </div>
  </div>
</div>
