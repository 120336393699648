<div *ngIf="!element.isEmpty">
  <div
    *ngFor="let relatedParty of element.relatedParties first as isFirst"
    [ngClass]='relatedParty.key == element.activeRelatedPartyKey ? "active-related-party" : "" '
    class="person-section-title title-t3 mb-3"
  >
    <div class="active-related-party-border"></div>
    <!-- RELATED PARTY PILLAR -->

    <app-related-party-pillar
      [element]=element
      [relatedParty]=relatedParty
      (contentReturn)='onEmit($event)'
      [isFirst]=isFirst
    ></app-related-party-pillar>

    <!-- RELATED PARTY CONTENT SP SIDE-->
    <app-related-party-content-sp-side
      [element]=element
      [relatedParty]=relatedParty
      (contentReturn)='onEmit($event)'
      (displayLoader)='onDisplayLoaderFromElements($event)'
    ></app-related-party-content-sp-side>

    <!-- RELATED PARTY CONTENT INVESTOR SIDE-->
    <app-related-party-content-investor-side
      [element]=element
      [relatedParty]=relatedParty
      (contentReturn)='onEmit($event)'
      (displayLoader)='onDisplayLoaderFromElements($event)'
    ></app-related-party-content-investor-side>

  </div>
</div>
