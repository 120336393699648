import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { contains } from 'jquery';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { ElementsService } from '../elements.service';
import { UiService } from 'src/app/ui/ui.service';

@Component({
  selector: 'app-digital-verification-evidence-share-dialog',
  templateUrl: './digital-verification-evidence-share-dialog.component.html',
  styleUrls: ['./digital-verification-evidence-share-dialog.component.scss']
})
export class DigitalVerificationEvidenceShareDialogComponent implements OnInit {

  constructor(
    public elementService: ElementsService,
    private helperService: HelperServiceService,
    public translate: TranslateService,
    public idVerificationService : IdVerificationService,
    public userService : UserService,
    private UIservice: UiService,
  ) { }
  @Output() contentReturn = new EventEmitter<any>();
  @Input() element;
  entityName;
  digitalEvidenceTypes;
  confirmedEmail;
  typeOfInvestor;
  isIdentityGo =  false;
  isIdentityGoRP = false;
  isContract = false;
  contractVerificaitonLink;
  cosigners;
  isSigned : boolean = true;

  ngOnInit(): void {
    // we need to find the name of the entity the digital verificaitons belong too
    if (this.element && this.element.evidence) {
      let rKey = this.element.evidence.belongsToRequest;
      if (rKey) {
        this.element.params.evidenceTypes.forEach(evidenceType => {
          if (evidenceType.investorReviewName && evidenceType.IRkey === rKey) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      } else {
        rKey = this.element.evidence.relatedPartyKey;
        this.element.params.evidenceTypes.forEach(evidenceType => {
          if (evidenceType.investorReviewName && evidenceType.relatedPartyKey === rKey) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      }
    }



    this.digitalEvidenceTypes = this.element.params.digitalEvidenceTypes;
    if (this.element.params.singleIdentityGoStep && !this.element.params.loadingFromBe) {
      this.idVerificationService.getActiveInvestorDetaisl().singleIdentityGoStep = false;
      this.isIdentityGo = true;
      this.digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl().identityGoStep;
    } else if(this.element.params.singleIdentityGoStepRelated && !this.element.params.loadingFromBe){
      this.isIdentityGo = true;
      this.isIdentityGoRP = true;
      this.digitalEvidenceTypes = this.element.params.singleIdentityGoStepRelated
    } else if (this.element.params.singleContractStep && !this.element.params.loadingFromBe) {
      this.idVerificationService.getActiveInvestorDetaisl().singleContractStep = false;
      this.isContract = true;
      this.digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl().contractStep;
    } else {
      this.digitalEvidenceTypes = this.element.params.digitalEvidenceTypes;
    }
    if(this.isContract)
    {
      this.userService.getEndPoint(this.idVerificationService.buildEndPoint(
        'coSigners', 
        this.idVerificationService.getActiveProject(), 
        this.element.params.requestKey, '', true,
        this.idVerificationService.getActiveInvestorDetaisl().contractStep[0].originalEvidenceKey), 
        { responseType: 'json' }, { observe: 'body' }, 'funds').subscribe(response => {
          if (response === undefined) {
            console.log('Error while fetching record co-signers!');
            return;
          }
          this.cosigners = response;
          let isInvestorCosigner: boolean = false;
          this.cosigners?.forEach(cosigner =>
            {
              if(cosigner.email == this.userService.getUserInfo()['username'])
              {
                isInvestorCosigner = true;
                this.isSigned = cosigner.isSigned
                cosigner.isSigned = true;
                return;
              }
            }
          )
          if(!isInvestorCosigner)
          {
            this.cosigners.push({email: this.userService.getUserInfo()['username'], isSigned: true});
            this.submitCosigner(null, this.userService.getUserInfo()['username']);
            this.isSigned = false;
          }
         
        },
          error => {
            return;
          });
    }

  }

    /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
     onEmit(emitPayload) {
      if (emitPayload) {
        this.contentReturn.emit(emitPayload);
      }
    }
    newCollaborator()
    {
      $('.invite-sent').removeClass('show') ;
      console.log("testovski");
    }


    initiateContract($event) {

      if($event) {
        $event.preventDefault();
      }
      let email = $('#ContractEmail').val().toString();
      $('.contract-error-field').addClass('d-none');
      $('.contract-error-field').text(this.translate.instant('validation.validation5'));
      if(email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        $('.contract-button-contr ').addClass('loadinge');

        this.userService.postEndPoint(
          'api/projects/' + this.idVerificationService.getActiveProject()
          + '/information/requests/' + this.element.params.requestKey +
          '/verifications/' + this.idVerificationService.getActiveInvestorDetaisl().contractStep[0].originalEvidenceKey + '/initiate'
          , {email : $('#ContractEmail').val().toString() }, { responseType: 'json' }, { observe: 'body' }, 'funds').subscribe(response => {

            $('.contract-button-contr ').removeClass('loadinge');
            $('.invite-sent').addClass('show') ;
            $('#ContractEmail').val('');
            if(response['verificationLink']){ 
              this.contractVerificaitonLink = response['verificationLink'];
            }
          },
          error => {
            $('.contract-error-field').text(error.message.message);
          });
      }
      else {
        $('.contract-error-field').removeClass('d-none');
      }
    }
    
    deleteValidation()
    {
      $('.contract-signer-error-field').addClass('d-none');
    }

    removeCosigner($event, email:string, index: number)
    {
      $event.preventDefault();
      $('#delete-co-signer'+ index).addClass('loadinge');
      this.userService.postEndPoint(
        this.idVerificationService.buildEndPoint('removeCosigner', 
        this.idVerificationService.getActiveProject(), 
        this.element.params.requestKey, '', true,
        this.idVerificationService.getActiveInvestorDetaisl().contractStep[0].originalEvidenceKey),
        {singerEmails : [email] }, { responseType: 'json' }, { observe: 'body' }, 'funds'
      ).subscribe(response => {
        if (typeof response === undefined) {
          console.log('There was an unexpected error while deleting a co-signer');
          return;
        }
        this.cosigners.splice(index, 1);
        $('#delete-co-signer').removeClass('loadinge');
      },
      error => {
        $('.contract-error-field').text(error.message.message);
      });
      

    }

    submitCosigner($event, emailCosigner=null)
    {
      if($event) {
        $event.preventDefault();
      }
      let email = emailCosigner ? emailCosigner : $('#ContractEmailSigner').val().toString().trim();
      let emailValid = true;
      if(!emailCosigner){
      if(email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
      {
        this.cosigners?.forEach(addedEmail => 
          {
            if(addedEmail.email == email)
            {
              emailValid = false;
              $('.contract-signer-error-field').text(this.translate.instant('validation.emailWasAdded'));
              return;
  
            }
          })
      }
      else{
        emailValid = false;
        $('.contract-signer-error-field').text(this.translate.instant('validation.validation5'));
      }
    }

      if(emailValid) 
      {
        emailCosigner??$('#contractSubmitButtonSigner').addClass('loadinge');
        this.userService.postEndPoint(
          this.idVerificationService.buildEndPoint('coSigners', 
          this.idVerificationService.getActiveProject(), 
          this.element.params.requestKey, '', true,
          this.idVerificationService.getActiveInvestorDetaisl().contractStep[0].originalEvidenceKey),
          {singerEmails : [email] }, { responseType: 'json' }, { observe: 'body' }, 'funds'
        ).subscribe(response => {
          if (typeof response === undefined) {
            console.log('There was an unexpected error while adding a co-signer');
            return;
          }
          emailCosigner??this.cosigners.push({email: email, isSigned: false});
          emailCosigner??$('#contractSubmitButtonSigner').removeClass('loadinge');
        },
        error => {
          $('.contract-error-field').text(error.message.message);
        });
      }
      else
      {
        $('.contract-signer-error-field').removeClass('d-none');
      }

    }
    redirectInvestorToDocument()
    {
      if(!this.contractVerificaitonLink)
      {
        $('#continueToDocument').addClass('loadinge');
        this.userService.postEndPoint(
          'api/projects/' + this.idVerificationService.getActiveProject()
          + '/information/requests/' + this.element.params.requestKey +
          '/verifications/' + this.idVerificationService.getActiveInvestorDetaisl().contractStep[0].originalEvidenceKey + '/initiate'
          , {email : this.userService.getUserInfo()['username'] }, { responseType: 'json' }, { observe: 'body' }, 'funds').subscribe(response => {
            if (typeof response === undefined) {
              console.log('There was an unexpected error while redirecting to the document');
              return;
            }
            if(response['verificationLink']){ 
              this.contractVerificaitonLink = response['verificationLink'];
              window.location.href = this.contractVerificaitonLink;
              $('#continueToDocument').removeClass('loadinge');
            }
          },
          error => {
            $('.contract-error-field').text(error.message.message);
          });
      }
      else
      {
        window.location.href = this.contractVerificaitonLink;
      }
    }

    parseVerificationName(backendName) {
      if (backendName === 'Identity') {
        return 'Identity verification';
      }
      if (backendName === 'Address') {
        return 'Address verification';
      }
      if (backendName === 'Contract') {
        return 'Contract signing';
      }
      if (backendName === 'APS') {
        return 'Compliance check';
      }
    }

}
