import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private var: []; // array of vars that will controll the behaviour of components
  private validatorEmitInit = new Subject<boolean>();
  public validatorEmitInitStatus = this.validatorEmitInit.asObservable(); // currently no subscribers

  constructor(public translate: TranslateService) { }

  initValidatorEmit(emitVal) {
    console.log('EMITING SUBSRPIRTIJ');
    this.validatorEmitInit.next(emitVal);
  }

  validateInput(event) {
    let eventTarget = event;
    if (event.target) {
      eventTarget = $(event.target);
    }
    if (eventTarget.attr('isRequired') && eventTarget.attr('isRequired') !== 'false' && eventTarget.val().toString().trim() === '') {
      this.setInvalidInput(eventTarget, this.translate.instant('validation.validation1'));
      return false;
    }
    if ((eventTarget.attr('isMinLength') && !isNaN(parseFloat(eventTarget.attr('isMinLength'))))
        && ((String) (eventTarget.val())).length < parseFloat(eventTarget.attr('isMinLength'))) {
      this.setInvalidInput(eventTarget, this.translate.instant('validation.validation2', {'minLength' :  eventTarget.attr('isMinLength')}));
      return false;
    }
    if ((eventTarget.attr('isMaxLength') && !isNaN(parseFloat(eventTarget.attr('isMaxLength'))))
        && (eventTarget.val()).length > parseFloat(eventTarget.attr('isMaxLength'))) {
      this.setInvalidInput(eventTarget, this.translate.instant('validation.validation3', {'maxLength' :  eventTarget.attr('isMaxLength')}));
      return false;
    }
    if (eventTarget.attr('patternMatch')) {
      if (eventTarget.val() === '') {
        // since it got here it is obviously not marked as required
        // if the email value is empty return true;
        return true;
      }
      const re = (RegExp) (eventTarget.attr('patternMatch').substr(1, eventTarget.attr('patternMatch').length - 2));
      if (!re.test((String) (eventTarget.val()))) {
        // validatorMessageField has been introduced since validatorField is sometimes overwritten
        // with the default 'this field is required' message and then it cannot display the field
        // specific message set into the validatorMessage field
        this.setInvalidInput(eventTarget, eventTarget.next().attr('validatorMessageField'));
        return false;
      }
    }
    return true;
  }

  validateSelect(event) {
    let eventTarget = event;
    if (event.target) {
      eventTarget = $(event.target);
    }
    if (eventTarget.attr('isRequired')
        && eventTarget.attr('isRequired') !== 'false'
        && (eventTarget.children('option:selected').val() === '-1'
        || eventTarget.children('option:selected').val() === 'emptySelect'
        || eventTarget[0].selectedIndex === -1)) {
          // console.log(eventTarget, eventTarget.parent().next(), eventTarget.parent().next().attr('validatorMessageField'));
          this.setInvalidInput(eventTarget, (eventTarget.parent().next().attr('validatorMessageField') !== undefined) ?
          eventTarget.parent().next().attr('validatorMessageField') :  this.translate.instant('validation.validation1'));
          return false;
    }
    return true;
  }

  validateCheckboxGroup(event) {
    let eventTarget = event;
    if (event.target) {
      eventTarget = $(event.target);
    }
    const group = eventTarget.closest('.checkbox-container');
    if (group.attr('isRequired') !== 'true') {
      return true;
    }
    // iterate through all checkboxes and check if there is at least one checked
    let res = false;
    $.each(group.find('input'), function(index, value) {
      // console.log('type', $(this).attr('type'), 'is it checked', $(this).prop('checked'));
      if ($(this).attr('type') === 'checkbox' && $(this).prop('checked')) {
        res = true;
      }
    });
    if (res === false) {
      this.setInvalidInput(group, 'you must select at least one option');
    }
    return res;
  }

  setInvalidInput(eventTarget, message: string) {
    let next = eventTarget.next();
    if (!next.hasClass('error-field')) {
      next = eventTarget.closest('.element-wrapper').find('.error-field');
    }
    eventTarget.not('.purposes-container').addClass('invalid-input');
    next.addClass('invalid-input').attr('validatorMessage', message);
  }

  clearInvalidation(event) {
    let eventTarget = event;
    if (event.target) {
      eventTarget = $(event.target);
    }
    let next = eventTarget.next();
    if (!next.hasClass('error-field')) {
      next = eventTarget.closest('.element-wrapper').find('.error-field');
    }
    eventTarget.removeClass('invalid-input');
    next.removeClass('invalid-input');
    // also remove any custom error fields
    $('.error-field.custom').removeClass('invalid-input');
  }

  // clears invalidaiton when target is provided
  clearInvalidationTarget(eventTarget) {
    let next = eventTarget.next();
    if (!next.hasClass('error-field')) {
      next = eventTarget.closest('.element-wrapper').find('.error-field');
    }
    eventTarget.removeClass('invalid-input');
    next.removeClass('invalid-input');
    // also remove any custom error fields
    $('.error-field.custom').removeClass('invalid-input');
  }

  checkAndClearInvalidation(event)
  {
    if(this.validateInput(event))
      {
        this.clearInvalidation(event);
      }
  }


  /**
   * parse and validate all input fields
   * into currently displayed lightbox
   */
  validateLightboxFields(event) {
    let eventTarget = event;
    if (event.target) {
      eventTarget = $(event.target);
    }
    const _this = this;
    let ret = true;
    $.each(eventTarget.closest('.form-container').find('input, select, .checkbox-container,textarea'), function(index, value) {
      if ($(this).hasClass('invalid-input'))  {
        ret = false;
      } else {
        if ($(this).hasClass('checkbox-container')) {
          if (!_this.validateCheckboxGroup($(this))) {
            ret = false;
            console.log("invalid checkbox");
          }
        } else if ($(this).prop('tagName') === 'SELECT') {
          if (!_this.validateSelect($(this))) {
            console.log("invalid select");
            ret = false;
          }
        } else {
          if (!_this.validateInput($(this))) {
            ret = false;
          }
        }
      }
    });
    return ret;
  }

  /**
   * validation has been triggered by content-builder.component submit btn
   */
  // emitValidationResult(event, action) {
  //   console.log('emit val result', event);
  //   console.log('validating from submit', $('.form-content-container form'));
  //   this.triggeredValidation.emit({ 'status': this.validateLightboxFields($('.form-content-container form')),
  //                                   // 'target': $('.form-content-container form .form-button')[0]});
  //                                   'target': event,
  //                                   'action': action });
  // }

}
