import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-verification-statuses',
  templateUrl: './verification-statuses.component.html',
  styleUrls: ['./verification-statuses.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VerificationStatusesComponent  implements OnInit {

  @Input() statuses;
  @Input() requestKey;
  @Input() evidenceTypes;
  @Input() vtype;
  @Output() displayLoader = new EventEmitter<any>();

  @Output() contentReturn = new EventEmitter<any>();
  constructor(
    private elementService: ElementsService,
    private idVerificationService: IdVerificationService
  ) { }

    ngOnInit(): void {
    }

    /**
     * emit event back to parent component
     * @param emitPayload params that should be emitted by parent component
     */
    onEmit(emitPayload) {
      if (emitPayload) {
        this.contentReturn.emit(emitPayload);
      }
    }

    onTriggerIDVPreview(event) {
      this.idVerificationService.triggerIDPreview(event);
    }

     /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
      onDisplayLoader(emitPayload: boolean) {
        if (emitPayload) {
          this.displayLoader.emit(emitPayload);
        }
      }

}
