<!-- QUESTIONNAIRE PREVIEW DIALOG -->
<div class="preview container questionare-preview-container">
  <div class="content">
    <!-- <h3 class="mt-3 mb-2">Preview your questionnaire:</h3> -->
    <div class="wrapper mt-4">
      <formio (submit)="onSubmit($event, false)" [options]="options" [form]='qBuilderService.formattedJson'></formio>
    </div>
  </div>
</div>


<app-lightbox *ngIf='displayModal && !errorState'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
