import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperServiceService } from 'src/app/helper-service.service';
import { VerificationsService } from 'src/app/verifications/verifications.service';
import { IdVerificationService } from '../../id-verification.service';

@Component({
  selector: 'app-contributor-invitation',
  templateUrl: './contributor-invitation.component.html',
  styleUrls: ['./contributor-invitation.component.scss']
})
export class ContributorInvitationComponent implements OnInit {

  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  message: string;
  invitationKey: string;
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;
  initiated: boolean;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;
  contributorType: string;
  displayLoader: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private idVerificationService: IdVerificationService,
    private verificationsService: VerificationsService,
    private helper: HelperServiceService,
    private router: Router) { }

ngOnInit() {
  this.logo = 'assets/images/meshId_logo.svg';
  this.displayLoader = true;
  this.projectColor = '#00889c';
  // the default is already set even though background color is not retreived
  this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
  this.message = 'Please stand by while we fetch the invitation details';
  this.consent = false;
  this.project = '';
  this.initiated = false;
  this.headerInfo = {
    'headerType': 'full', // 'inline',
    'headerTitle': 'Invitation as a colaborator',
    'activeNavLink': 'idVerificationInvitation'
  };
  this.headerClasses = {
    'backgroundClass': 'tealish-gradient-bg',
    'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
  };

  this.invitationKey = this.activatedRoute.snapshot.params.invitationKey;
  this.verificationsService.setInvitationKey(this.invitationKey);
  }
}
