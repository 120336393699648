import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';

@Component({
  selector: 'app-preview-basic-fields',
  templateUrl: './preview-basic-fields.component.html',
  styleUrls: ['./preview-basic-fields.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewBasicFieldsComponent implements OnInit {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  constructor(private helper: HelperServiceService) { }

  ngOnInit(): void {
    // console.log('this is element', this.element);
    if (this.element && this.element['params'] && this.element['params'].fieldTypes && !this.element['params'].fieldTypesDV) {
      let lastTitle;
      lastTitle = {};
      this.element['params'].fieldTypes.forEach(fieldType => {
        if (fieldType.type === 'title') {
          lastTitle = fieldType;
          lastTitle.showTitle = false;
        } else if (fieldType.value) {
          lastTitle.showTitle = true;
        }
      });
    }

  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

}
