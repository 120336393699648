import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  verificationKey: string;

  constructor(
              private activatedRoute: ActivatedRoute,
              public translate: TranslateService,
              private router: Router) {}

  ngOnInit(): void {
    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;
  }

  startIdVerification()
  {
    this.router.navigate(['/verifications/' + this.verificationKey + '/identity']);
  }
  closeIdVerification()
  {
    this.router.navigate(['/verifications/'+ this.verificationKey]);
  }

}
