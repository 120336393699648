import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss']
})

export class PreviewImageComponent implements OnInit, OnDestroy {
  constructor(
   private idVerificationService: IdVerificationService,
  ) { }
  @Input() documentImage;
  showIframe;
  iframeData: Subscription;

  ngOnInit(): void {
    this.iframeData = this.idVerificationService.detectcompleteIframeData.subscribe( response => {
      if (this.documentImage.Key === response.documentEvidence.Key) {
      this.documentImage.data = response.data;
      this.showIframe = true;
      // console.log(response);
      }
    });
  }
  ngOnDestroy() {
    this.iframeData.unsubscribe();
  }

}

