import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { VerificationsService } from './verifications.service';
import { HelperServiceService } from '../helper-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
  styleUrls: ['./verifications.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class VerificationsComponent implements OnInit, OnDestroy {

  debugMode: boolean; // if set to true it will test local iframe embedding for debugging purposes
  unsubscribe$: Subject<void> = new Subject<void>();
  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  projectMessage: string;
  message: string;
  verificationKey: string;
  privacyUrl: string;
  headerInfo: {};
  headerClasses: {};
  iframeSrc;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;
  displayLoader: boolean;
  iosUnsupported: boolean;
  androidUnsupported: boolean;
  isUnsupportedBrowser: boolean;
  isCustomLogo = false; // Variable for adding extra classes for logo size
  userAgent;
  resolved = false;

  constructor(
              private activatedRoute: ActivatedRoute,
              private verificationsService: VerificationsService,
              private helper: HelperServiceService,
              private domSanitizer: DomSanitizer,
              private router: Router,
              public translate: TranslateService
              ) { }

  ngOnInit() {
    this.debugMode = false; // SET THIS TO TRUE TO DIRECTLY EMBED A SUCCESS OR FAILURE PAGE
    this.displayLoader = false;
    this.logo = null;
    this.projectColor = '#ffffff';
    // the default is already set even though background color is not retreived
    this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
    this.message = '';
    this.consent = false;
    this.project = '';
    this.iosUnsupported = false;
    this.isUnsupportedBrowser = false;
    this.androidUnsupported = false;
    this.userAgent = navigator.userAgent;
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };

    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;

    // call GET verifications/data/:verificationKey to fetch verification event
    // look if it is already rejected/verified if yes, notifyt he user of it,
    // check if it is expired, if yes, notify the user of it. If all is good then
    // load the url in an iframe
    this.verificationsService.getVerificationToken(this.verificationKey).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      this.resolved = true;
      if (response === null) {
        this.message = this.translate.instant('IDPCS.error');
        if (response && response.projectBranding && response.projectBranding.projectColor) {
          this.verificationsService.setProjectColor(response.projectBranding.projectColor);
        } else {
          this.verificationsService.setProjectColor('#00889c');
        }
        this.router.navigateByUrl('/verifications', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/verifications/' + this.verificationKey]); // navigate to same route
        }).catch();
        return;
      }
      if (this.helper.getTimestamp(response.expiresOn) <= this.helper.getTimestamp('current')) {
        this.message = this.translate.instant('IDPCS.idVerification.content');
        return;
      }
      if (response.projectBranding && response.projectBranding.projectColor) {
        this.verificationsService.setProjectColor(response.projectBranding.projectColor);
      } else {
        this.verificationsService.setProjectColor('#00889c');
      }
      this.projectColor =  this.verificationsService.getProjectColor();
      this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
      this.project = response.projectName;
      this.projectMessage = response.projectBranding ? response.projectBranding.consentMessage : undefined;
      this.privacyUrl = response.projectBranding ?  response.projectBranding.privacyUrl : undefined;
      if (response.consentedAt !== undefined) {
        this.consent = true;
      }
      if (response.status && response.status.toLowerCase() !== 'pending') {

        this.router.navigateByUrl('/verifications', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/verifications/' + this.verificationKey]); // navigate to same route
        }).catch();
      }
      if (!this.debugMode) {
          this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
            response.url);

      } else {
        // this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/verifications/failed');
        this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/verifications/success');
      }
    }, error => {
      this.router.navigateByUrl('/verifications', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/verifications/' + this.verificationKey]); // navigate to same route
    }).catch();
    });

    /**
     * ask for verification logo from BE
     */
    this.verificationsService.getVerificationLogo(this.verificationKey);
    /**
     * subscribe to verification logo changes
     */
    this.verificationsService.detectMyVerificationLogo.pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      this.logo = 'assets/images/meshId_logo.svg';
      if (response !== null) {
        this.logo = response;
        this.isCustomLogo = true;
      }
      this.verificationsService.setProjectLogo(this.logo);
    });

    // need to check if IOS
    // need to check if chrome
    // if it's both a message should be displayed that IOS chrome is not supported


    const isSafari =
    !(/CriOS/).test(navigator.userAgent) &&
    !(/FxiOS/).test(navigator.userAgent) &&
    !(/OPiOS/).test(navigator.userAgent) &&
    !(/mercury/).test(navigator.userAgent) &&
    !(/EdgiOS/).test(navigator.userAgent);
    const isIos = (/(iPad|iPhone|iPod)/gi).test(navigator.userAgent);
    if (isIos && !isSafari) {
      this.iosUnsupported = true;
    }
    const browser = this.helper.myBrowser();
    if (!this.helper.isMobile() &&  browser !== 'Chrome' && browser !== 'Safari' && browser !== 'Firefox' && browser !== 'Edge') {
      this.isUnsupportedBrowser = true;
    }
    const isAndroid = navigator.userAgent.match(/(android)|(webOS)/i);
    const isAndroidSupportedBrowser = navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.match(/SamsungBrowser/i);
    if (isAndroid && !isAndroidSupportedBrowser) {
      this.androidUnsupported = true;
    }
    window.addEventListener('message', (event) => {
      // extract the data from the message event
      console.log('LOGGING IFRAME MESSAGE');
    });

    const _this = this;
    window.onmessage = function(e) {
      if (e.data === 'lightbox id-verification is completed') {
        _this.onJumioIframeClose();
     }
   };

  }

  onJumioIframeClose () {
    $('.iframe-verifications').remove();
    this.router.navigateByUrl('/verifications', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/verifications/' + this.verificationKey]); // navigate to same route
  }).catch();
  }


  OnExplicitConsent(event) {
    // add a spinner to the button
  //   this.displayLoader = true;
  //   this.verificationsService.recordConsent(this.verificationKey).subscribe(response => {
  //     console.log('***** RESPONSE:', response)
  //     if (response) {
  //       // allow user to move forward with the verification
  //       this.consent = true;
  //       return;
  //     }
  //     // if response != true
  //     this.displayLoader = false;
  //     this.message = 'The verification link has expired or is no longer available! Please ask the requesting party for a new link';
  //   });
  this.consent = true;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
