const checkboxComponent = Formio.Components.components.radio;
import CheckboxComponentForm from './switch.form';
import { HelperServiceService } from 'src/app/helper-service.service';

class SwitchExtendedComponent extends checkboxComponent {

  static schema(...extend) {
    return super.schema({
          type: 'SwitchExtendedComponent',
          label: "",
          any_other_fields: "",
    }, ...extend)};

  static get builderInfo() {
    return {
      // title: 'Name in the Builder',
      title: 'Toggle',
      group: 'customBasic',
      icon: 'fa-solid fa-toggle-on',
      weight: 6,
      schema: this.schema()
    };
  }

    render() {

      const submission = _.get(this.root, 'submission', {});
      const checkboxes = this.component.values;
      // console.log('checkboxes', this.component)
      const mapped = checkboxes.map(checkbox => {
        const value = HelperServiceService.generateRandomString(5);
        return `
        <div class="form-check formio-custom-switch mt-2">
          <label  class="form-check-label label-position-right switch">
            <input type="checkbox" name="data[checkbox]" form-check-input />
            <span class="slider round"></span>
          </label>
          <div class="d-inline">${checkbox.label} 
            <span class="weight-count" style="margin-left: 12px"> (${(checkbox.answerWeight || checkbox.answerWeight === 0) 
                                                                     ? checkbox.answerWeight : ''} points)</span>
          </div>
        </div>`;
      });
      this.tpl = `
        <div ref='refExtSwitch'>
          <div class="col-form-label">${this.component.label}</div>
          ${mapped.join('')}
        </div>
      `;
      // Note the use of the 'ref' tag, which is used later to find
      // parts of your rendered element.

      // If you need to render the superclass,
      // you can do that with
      // tpl+=super.render(element);

      // This wraps your template to give it the standard label and bulider decorations
      return Formio.Components.components.component.prototype.render.call(this, this.tpl);

    }

    attach(element) {
      // This code is called after rendering, when the DOM has been created.
      // You can find your elements above like this:
      this.loadRefs(element, {refExtSwitch: 'single'});

      // var superattach = super.attach(element);
      //  // Here do whatever you need to attach event handlers to the dom.
      // this.refs.refExtCheckbox.on('click',()=>{alert("hi!");});

      // return superattach;

      this.addEventListener(this.refs.customRef, 'click', () => {
        console.log('Custom Ref has been clicked!!!');
    });
    return super.attach(element);
    }

    getvalue() {
      return 3; // the value this element puts into the form
    }
    // OR, depending on which component type you're basing on:
    getValueAt(index,value,flags) {}

    setValue(value) {
      // modify your DOM here to reflect that the value should be 'value'.
    };
    // OR, depending on what component type:
    getValueAt(index) {}

  }

  // This sets the form that pops up in the Builder when you create
  // one of these.  It is basically just a standard form, and you
  // can look at the contents of this in the debugger.
  // RadioExtendedComponent.editForm = checkboxComponent.editForm;
  SwitchExtendedComponent.editForm = CheckboxComponentForm;


  // Register your component to Formio
  Formio.Components.addComponent('SwitchExtendedComponent', SwitchExtendedComponent);
  export default SwitchExtendedComponent;
