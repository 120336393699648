<div style="box-shadow: none; padding-bottom: 0; padding:24px;"  class="form-container-side"
*ngIf = "!element.params.reviewStateGroup && element.params.dverificationUrl ">
  <div class="mt-3  element-wrapper" *ngIf="element.params.confirmPersonage === true">
    <div>
      <img src="/assets/images/request_screening.svg"
      class="result-image request-share-img-2" >
    </div>
    <h3 class="verify-title mt-4 mb-2"> {{"review.digitalVerifications.title2" | translate}}</h3>
    <p
    [innerHTML] = "('review.digitalVerifications.content8' | translate : {'entity' : (entityName ? entityName : 'entity')})"
    *ngIf="typeOfInvestor !=='LegalPerson'"  class="mb-0" style="color:#767676">
    </p>
    <p
    [innerHTML] = "('review.digitalVerifications.content9' | translate : {'entity' : (entityName ? entityName : 'entity')})"
    *ngIf="typeOfInvestor ==='LegalPerson'" class="mb-0" style="color:#767676">
     </p>
    <div class="mb-3 mt-3 d-flex justify-content-between flex-wrap" >
      <div class="sharing-wrapper d-flex">
        <div>
          <div class="sharing-wrapper-title">{{"review.digitalVerifications.button2" | translate}}</div>
          <div *ngIf="typeOfInvestor !=='LegalPerson'" class="sharing-wrapper-sub"
          [innerHTML] = "('review.digitalVerifications.content10' | translate : {'entity' : (entityName ? entityName : 'entity')})"
          ></div>
          <div *ngIf="typeOfInvestor ==='LegalPerson'" class="sharing-wrapper-sub"
          [innerHTML] = "('review.digitalVerifications.content10Legal' | translate : {'entity' : (entityName ? entityName : 'entity')})"
          ></div>
        </div>
        <div>
          <a target="_blank"
          style="color: white !important;"
          (click) = "onShareDVNextButton($event, element.params.dverificationUrl)"
          class="mt-3 form-button start-link form-button-1  evidence-submit-button text-center">
          {{"review.digitalVerifications.button3" | translate}}</a>
        </div>
      </div>
      <div class="sharing-wrapper d-flex">
        <div>
          <div class="sharing-wrapper-title"> {{"review.digitalVerifications.label" | translate}}</div>
          <div  *ngIf="typeOfInvestor !=='LegalPerson'" class="sharing-wrapper-sub"
          [innerHTML] = "('review.digitalVerifications.content11' | translate : {'entity' : (entityName ? entityName : 'entity')})"
          ></div>
          <div  *ngIf="typeOfInvestor ==='LegalPerson'" class="sharing-wrapper-sub"
          [innerHTML] = "('review.digitalVerifications.content11Legal' | translate : {'entity' : (entityName ? entityName : 'entity')})"
          ></div>
        </div>
        <div>
          <button target="_blank"
          style="font-weight: 700; font-size: 18px; text-decoration: none;"
          [href]="element.params.dverificationUrl "
          (click) = "switchScreen(false)"
          class="mt-3 form-button  form-button-link-2  evidence-submit-button">
          {{"common.share" | translate}}
         </button>
        </div>
      </div>
      <div class="sharing-wrapper d-flex">
        <div>
          <div class="sharing-wrapper-title">{{"review.digitalVerifications.button4" | translate}}</div>
          <div class="sharing-wrapper-sub">{{"review.digitalVerifications.label2" | translate}}</div>
        </div>
        <div>
          <button
          style="font-weight: 700; font-size: 18px; text-decoration: none;"
          [href]="element.params.dverificationUrl "
          (click) = "onShareDVSkip($event)"
          class="mt-3 form-button  form-button-link-2  evidence-submit-button">
          {{"common.skip" | translate}}

        </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="element.params.confirmPersonage === false" class="element-wrapper   mb-4">
    <h4 class="verify-heading">{{'review.digitalVerifications.title3' | translate}} </h4>
    <p>{{'review.digitalVerifications.content12' | translate}}</p>
    <div class="element-wrapper">
      <span class="input-label">{{'review.digitalVerifications.label4' | translate}}</span>
      <div class="d-flex position-relative input-text-button-wrapper">
        <!-- Status notification -->
        <div class="input-action-status"></div>
        <div *ngIf="element.params.innerLabel" class="text-button-label mr-3">URL</div>
        <!-- Input Text -->
        <input [readonly] = "true"  type="text" class="text-input" id="generatedVerificationLink" name="generatedVerificationLink" value='{{ element.params.dverificationUrl }}'>
        <!-- Button -->
        <button class="form-button form-button-1 ml-2 mr-0"  [attr.clickmethod]="'CopyLinkToClipboard2'" (click)="onEmit(elementService.OnButtonClick($event))">
          {{"common.copy" | translate}}
        </button>
      </div>
    </div>
    <div class="element-wrapper">
      <span class="input-label"> {{'review.digitalVerifications.label3' | translate}}</span>
      <div class="d-flex position-relative input-text-button-wrapper ">
        <!-- Status notification -->
        <div class="input-action-status"></div>
        <div *ngIf="element.params.innerLabel" class="text-button-label mr-3">URL</div>
        <!-- Input Text -->
        <input [value]="confirmedEmail ? confirmedEmail : ''" type="text" class="text-input" placeholder= "Type in an email address" id="generatedVerificationLink"
        name="generatedVerificationLink" >
        <!-- Button -->
        <button class="form-button form-button-1 ml-2 mr-0"
        [attr.requestKey] = "rKey ? rKey : element.params.requestKey"
        [attr.clickmethod]="'sendVerificationLink'" (click)="onEmit(elementService.OnButtonClick($event))">
        {{"common.send" | translate}}

        </button>
      </div>
      <div class="button-error-field">
        {{'validation.validation5' | translate}}
      </div>
     </div>
     <div class="d-flex justify-content-between mt-4">
      <button
      class="form-button form-button-1 evidence-submit-button"
      (click) = "onShareDVNextButton()"
      >
      {{isLastEvidence ? ("common.finalise" | translate) : ("common.next" | translate)}}
      </button>
     <button target="_blank"
      (click)="switchScreen(true)"
      class="switching-screen-link">
      {{'review.digitalVerifications.button5' | translate}}
    </button>
     </div>
  </div>
</div>
<div *ngIf="isContract">
  <p class="my-3 d-flex justify-content-center align-items-center">
    Email has been sent
  </p>
</div>