import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { IntegrityService } from '../../integrity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integrity-check-search',
  templateUrl: './integrity-check-search.component.html',
  styleUrls: ['./integrity-check-search.component.scss']
})
export class IntegrityCheckSearchComponent implements OnInit {

  refreshType: string;

  // header variables
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;

  constructor(private cd: ChangeDetectorRef, private integrityService: IntegrityService, private router: Router) { }

  ngOnInit() {
    // initialize the header
    this.headerInfo = {
      'headerType': 'full',
      'headerTitle': 'Integrity Check'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page',
    };


    // init modal lightbox
    this.displayModal = false;
    this.formType = 'Perform Integrity Check';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = 'Agree';
    this.formValidator = '';
    this.btnCancelText = 'Disagree';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
  }

  onKvkResultsChanged() {
    // this.kvkResults = 'NOVA VREDNOST'; console.log(this.cd); this.cd.detectChanges();
  }

  OnSelectedCompanyChange(companyDetails) {
    this.formTitle = companyDetails.companyName;
    this.integrityService.setCurrentCompany(companyDetails);

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        // 'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'image',
          'params': {
            'src': 'assets/images/integrity_check_image.png'
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'Be aware ...'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'By performing an integrity check you agree that all actions you take on this entity from this point forward will be recorded for future retrieval by anyone within your organisation'
          }
        },
        // BEGINING OF FORM
        // disable this for now
        // {
        //   'type': 'checkbox',
        //   'params': {
        //     'id': 'dont_show_message',
        //     'name': 'dont_show_message',
        //     'label': 'Don\'t show this message again',
        //     'value': '1'
        //   }
        // },
        // EOF elements of form

        // don't submit for now
        // {
        //   'type': 'submit',
        //   'params': {
        //     'content': 'Send request',
        //     'action': 'OnSendRequestInformation',
        //     'display': true
        //   }
        // },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  onSearchTermChanged(event) {
    if (event === '') {
      this.kvkResults = null;
      this.cd.detectChanges();
      return;
    }
    this.integrityService.getIntegrityCheckResults(event)
      .subscribe((data: any) => {
        if (data === undefined || data.length === 0) {
          data = null;
        }
        console.log('from server: ', data);
        // this.kvkResults = data;
        this.kvkResults = data.companies;
        this.cd.detectChanges();
        });
  }

  onLightboxConfirm(event) {
    if (typeof(event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params)) {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    // this.router.navigateByUrl('integrity-check/result');
    if (typeof(event.method) === 'undefined' ) { // allow only strings as acceptable method name
      this.displayLoader = true; // display the loading screen
      this.integrityService.postIntegrityCheck();
    }
  }

  OnConfirmViewExistingCheck() {
    this.formType = 'Perform Integrity Check';
    this.formTitle = this.integrityService.getCurrentCompanyName();
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.btnOkEnabled = false;
    this.btnCancelEnabled = false;

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        // 'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'image',
          'params': {
            'src': 'assets/images/arrow_already_there_for_you.png'
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'Already there for you.'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'You have an active integrity check on this legal entity.'
          }
        },
        {
          'type': 'button_group',
          'params': [
            {
              'content': 'Go to existing check',
              'action': 'OnGoToExistingCheck',
              'display': true
            },
            {
              'content': 'Cancel',
              'action': 'OnCancelToExistingCheck',
              'class': 'form-button-gray',
              'display': true
            }
          ]
        },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  OnGoToExistingCheck() {
    // no need for this stuf as it is going to change the page:
    // if (this.refreshType === 'open') {
    //   // refresh active integrity checks
    //   this.integrityService.getIntegrityChecks('open');
    // } else {
    //   // refresh closed integrity checks
    //   this.integrityService.getIntegrityChecks('closed');
    // }
    // this.displayLoader = false; // hide the loading screen
    // this.displayModal = false;
    this.integrityService.scrollToSelectedCompany();
    this.router.navigateByUrl('integrity-check/result');
  }

  OnCancelToExistingCheck() {
    this.refreshType = '';
    this.displayLoader = false; // hide the loading screen
    this.displayModal = false;
    this.router.navigateByUrl('integrity-check/result');
  }

  onLightboxClose() {
    this.kvkResults = null;
    this.cd.detectChanges();
    this.displayModal = false;
  }


}
