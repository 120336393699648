import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-funds-parse-evidence-sp-side',
  templateUrl: './funds-parse-evidence-sp-side.component.html',
  styleUrls: ['./funds-parse-evidence-sp-side.component.scss']
})
export class FundsParseEvidenceSpSideComponent implements OnInit {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  constructor(
      private elementService: ElementsService
    , private idVerificationService: IdVerificationService
    , public translate : TranslateService
    , private helperService : HelperServiceService
  ) { }

  ngOnInit(): void {

  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onDisplayLoader(emitPayload: boolean) {
      if (emitPayload) {
        this.displayLoader.emit(emitPayload);
      }
    }

}
