import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { environment } from 'src/environments/environment';
import { IdVerificationService } from '../../id-verification.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-basic-information-verification-subject',
  templateUrl: './basic-information-verification-subject.component.html',
  styleUrls: ['./basic-information-verification-subject.component.scss']
})
export class BasicInformationVerificationSubjectComponent implements OnInit {

  // starting parameters
  headerInfo;
  headerClasses = {};
  projectColor;
  logo;
  projectTextColorClass: string;
  editMode = true;
  previewPdf: any;
  firstName: any;
  lastName: any;
  callLoaded;
  // data coming from IDV that needs to always be included in PDF
  constFormData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  };
  formattedJson: Object = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private helper: HelperServiceService,
    private ipService: IpServiceService,
    private idVerificationService: IdVerificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  unsubscribe$: Subject<void> = new Subject<void>();
  errorState = false;
  basicInformationInputed = false ;
  submissionData;
  btnContentElements: string;
  refreshType: string;
  baseUrl = environment.APIEndpoint;
  verificationRecord; // verification record contract/schema details
  verificationKey: string;
  activeVerificationKey: string;
  cryptoKey: string;
  stateMessage = 'WE COULD NOT START THE VERIFICATION PROCESS FOR YOU!';
  evidenceType = null;
  verificationHeading = 'Provide your basic Information';

  // there is only one step, but we add it anyway for consistency
  stepsArray: any = [{
    name: 'Information input', count: 1, id: 'basic_info'
  }];

  ngOnInit(): void {
    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;
    // TODO: get verification details that include the information about basic information request
    this.http.get(this.baseUrl
                        + 'api/verifications/'
                        + this.verificationKey
                        )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async response => {
      if (response['record']['projectBranding'] && response['record']['projectBranding'].projectColor) {
        this.projectColor = response['record']['projectBranding'].projectColor;
        this.projectTextColorClass = this.helper.detectTextColor(this.helper.hexToRgb(this.projectColor));
      }
      if (response['record']['projectBranding'] && response['record']['projectBranding'].projectLogo) {
        this.logo = response['record']['projectBranding'].projectLogo;
      } else {
        // get verification logo
        this.logo = 'assets/images/meshId_logo.svg';
        this.http.get(this.baseUrl + 'api/verifications/'
        + this.verificationKey
          + '/logo'
          , { responseType: 'blob' }).subscribe(async response2 => {
            if (response2['type'] === 'application/json') {
              this.logo = JSON.parse(await (response2 as Blob).text()).link;
            } else {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                this.logo = reader.result;
                // GO TO STEP FETCHING LOGO
              }, false);
              reader.readAsDataURL(response2);
            }
          }, error => {
            console.error('Error occured while fetching the project logo', error);
        });
      }

      this.headerInfo = {
        'headerType': 'full', // 'inline',
        'headerTitle': 'Integrity Check',
        'activeNavLink': 'integrityCheck'
      };
      this.headerClasses = {
        'backgroundClass': 'tealish-gradient-bg',
        'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
      };

      this.evidenceType = response['record'].lastRequestedDataVerification.typeOfInvestor;
      if (!this.evidenceType) {
        this.errorState = true;
        console.error('We could not iidentify the evidence type');
        return;
      }
      this.callLoaded = true;
      this.onEvidencePreviewInvestor(response);
    },
    error => {
      this.errorState = true;
      this.projectTextColorClass = 'light-color';
      this.projectColor = '#00889c';
      this.logo = 'assets/images/meshId_logo.svg';
      console.error('Error occured while fetching the access token', error);
    });
  }

  onEvidencePreviewInvestor(inputArgs) {
    const isMobile = this.helper.isMobile();
    let lightboxClass  = 'form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable';
    if (isMobile) {
      lightboxClass = 'form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list'
                      + ' not-closable form-lightbox-fullscreen-mobile';
    }

    // 'section_class' : 'results d-flex flex-column justify-items-center p-0 document-list-containers',
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0 text-left',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'requestKey',
      'result_details' : [
        {
        'group_result_title' : 'create_new_representative_group',
        'elements' : [{
          'type' : 'basicFieldsPCS',
          'id' : this.evidenceType,
          'enabled' : true,
          'isActive' :  true,
          'investorSide': true,
          'params':  {
            'lockedFields' : [],
            'reviewState' : true,
            'fundVerification' : (this.headerInfo['projectType'] === 'Fund') ? true : false,
            'image_upload_id': 'evidence_image_id',
            'minDate': this.helper.getAsStartDate('01.01.1915'),
            'maxDate': this.helper.getAdultAsMaxDate(),
            'supportedCountries' : this.idVerificationService.parseCountryOptions(),
            'email_pattern' : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'evidenceCategories' :  this.idVerificationService.parseEvidenceFields(this.evidenceType, this.getPreFilledData(inputArgs)),
            'loadingFromBe' : false,
            // hide the evidence from the different projects unless a button is clicked
            'displayShowMoreEvidenceButton' : false,
            'isAutomaticRequest' : false,
          }
        }]
    }]
  };

  this.lightboxContent = [];
  this.lightboxContent.push(group);
  this.displayModal = true;
}

/**
 * Extract the prefilled parameters on Investor side
 * @param inputArgs response containing all the info for this verification
 */
getPreFilledData(inputArgs) {
  if (inputArgs
    && inputArgs.record
    && inputArgs.record.lastRequestedDataVerification
    && inputArgs.record.lastRequestedDataVerification.privateData
  ) {
    return inputArgs.record.lastRequestedDataVerification.privateData;
  }
  return '';
}

  // HELPER FUNCTIONS FOR THE LIGHTBOX
    /**
   * handle click on lightbox confirm
   * @param event event
  */

  submitPcsBasicInformation (event) {
    $('.parseEvidenceList').addClass('evidence-loading');
    const evidenceKey = $(event.target).attr('data-evidenceKey');
    const verificationType = $(event.target).attr('data-verificationType');
    const basicInfo: any = this.idVerificationService.saveFieldsState(evidenceKey, this.evidenceType, verificationType);
    console.log('basicInfo', basicInfo.fields);
    this.http.post(
        this.baseUrl + 'api/verifications/' + this.verificationKey + '/data'
      , {'investorFields': basicInfo.fields}
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(response => {
      // TODO: check if the response is a success and handle error
      this.helper.navigateToGeneralVerificationsLink(this.verificationKey);
    },
    error => {
      console.log('Error occured while fetching the access token', error);
    });
  }

  onLightboxConfirm(event) {
    if (typeof(event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params)
          || (typeof(event.params) === 'object' && event.params !== null )) {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
  }

}

