import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-party-loading-pillar',
  templateUrl: './related-party-loading-pillar.component.html',
  styleUrls: ['./related-party-loading-pillar.component.scss']
})
export class RelatedPartyLoadingPillarComponent implements OnInit {

  constructor() { }

  @Input() element;

  ngOnInit(): void {
  }

}
