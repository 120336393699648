<div class="preview" *ngIf="formBuilderService.previewJson">
  <div class="content">
    <!-- <div class="close-preview" (click)="formBuilderService.togglePreviewJson()">
      <img _ngcontent-c4="" src="assets/images/close_form_icon.png">
    </div> -->
    <div
      *ngIf="displayLoader"
        class="position-absolute w-100 h-100"
      >
        <div
          class="jsonLoader d-flex w-100 h-100 align-items-center justify-content-center"
        >
          <img
            width="40px"
            height="40px"
            src="/assets/images/spinner-green.svg" />
        </div>
    </div>
    <!-- <h1 _ngcontent-c4="" class="form-type-heading">Preview/Export/Import JSON</h1> -->
    <div class="well jsonviewer">
      <pre id="json" *ngIf="formBuilderService.stringifiedJson"><code class="language-json" #json></code></pre>
      <pre
        id="jsonUpload"
        (dragenter)="OnJsonFileUploadDragEnter($event)"
        (dragover)="OnJsonFileUploadDragOver($event)"
        (drop)="OnJsonFileUploadDrop($event)"
        (click)="OnJsonFileUploadClick($event)"
        class="button-wrapper text-right"
        class="jsonUpload d-flex flex-column w-100 h-100 align-items-center justify-content-center"
        *ngIf="!formBuilderService.stringifiedJson"
      >
        <div class="mb-3">Edit an existing form from locally saved JSON schema</div>
        <button class="form-button form-button-1"  (click)="onInitiateFirstImport($event)"> Import JSON file </button>
      </pre>
    </div>
    <div
      class="text-right"
      *ngIf="formBuilderService.stringifiedJson"
    >
      <a class="form-button form-button-1"  href="#" (click)="onInitiateImport($event)"> Import New JSON</a>
      <a class="form-button form-button-1" [href]="formBuilderService.downloadJson"  download="form.json"> Export </a>
      <a class="form-button form-button-1" (click)="formBuilderService.exportFiles()"> Export all</a>
      <a class="form-button form-button-grey" href="#" (click)="$event.preventDefault(); formBuilderService.togglePreviewJson();"> Close </a>
    </div>
    <div
      class="d-flex flex-row w-100 align-items-center justify-content-end pr-4"
      *ngIf="!formBuilderService.stringifiedJson">
      <div class="pr-2">or start a new form from scratch:</div>
      <a
        class="form-button form-button-1 text-center"
        href="#"
        (click)="$event.preventDefault(); formBuilderService.togglePreviewJson();"> New Form </a>
    </div>
    <input
        style="display: none"
        type="file" (change)="onImport($event)"
        #fileInput>
  </div>
</div>


<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
