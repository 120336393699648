import RadioEditData from './editForm/radio.edit.data';
import RadioEditDisplay from './editForm/radio.edit.display';
import RadioEditValidation from './editForm/radio.edit.validation';

export default function(...extend) {
  return {
    components: [
      {
          components: [
            {
              label: "Configuration",
              key: 'data',
              components: RadioEditData
            },
            // {
            //   label: "Display",
            //   key: "display",
            //   components: RadioEditDisplay
            // },
          ],
          "key": "tabs",
          "type": "tabs",
          "input": false,
          "tableView": false
      },
    ]
  }
}
