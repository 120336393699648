import { Component, HostListener, Input, Output, forwardRef,
         ViewChild, ElementRef, EventEmitter, OnChanges, SimpleChanges, SimpleChange  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateCustomParserFormatter} from './ngbdateformatter';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { HelperServiceService } from 'src/app/helper-service.service';

@Component({
  selector: 'app-my-date-picker',
  // use maxDate and minDate to define a valid period
  // [maxDate]="{year: 2019, month: 1, day: 21}"
  // 05.02.2019: original date format: yyyy-mm-dd replaced with dd.mm.yyyy
  template: `<div class="input-group" id="{{ name }}">
              <input
                class="form-control datepicker-input-field {{additionalClass}}"
                placeholder="dd.mm.yyyy"
                [name]="name"
                [(ngModel)]="bindStartDateValue"
                (ngModelChange)="onModelChange($event)"
                (keyup) = "onKeyUp($event)"
                (focusout) = "onFocusOut()"
                [readOnly] = "readOnly"
                ngbDatepicker #dp="ngbDatepicker"
                [startDate]="bindStartDate"
                [minDate]="bindMinDate"
                [maxDate]="bindMaxDate"
                [attr.method]="method"
                [attr.isRequired]="isReqiured"
                [attr.inputformId] = "dateInputId"
              >
              <div class="input-group-addon" (click)="dp.toggle();">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
              </div>
            </div>`,
  styleUrls: ['./content-elements.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MyDatePickerComponent), multi: true},
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class MyDatePickerComponent implements ControlValueAccessor, OnChanges {
  @Input() name = '';
  @Input() model: any;
  @Input() method: string;
  @Input() startDate: {};
  @Input() minDate: {};
  @Input() maxDate: {};
  @Input() isReqiured: string;
  @Input() dateInputId = '';
  @Input() value: string | number = '';
  @Input() readOnly = false;
  @Input() additionalClass = '';
  startDateValue = '';
  bindStartDate = {};
  bindStartDateValue = {};
  bindMinDate = {};
  bindMaxDate = {};
  @Output() dateChange = new EventEmitter<any>();
  @ViewChild('dp', { static: true }) dp;
  private propagateChange: any = () => {};

  constructor(private _eref: ElementRef, private helper: HelperServiceService) { }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this._eref.nativeElement.contains(event.target)) {
      return true;
    }
    setTimeout(() => this.dp.close(), 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    let startDate;
    if (changes && changes.startDate) {
      startDate = changes.startDate;
      if (startDate.currentValue && startDate.currentValue !== '' ) {
        this.bindStartDate = JSON.parse(startDate.currentValue);
      }
    }
    // if (startDate !== undefined && startDate.currentValue !== undefined && startDate.currentValue !== '') {
    //   this.bindStartDate = JSON.parse(startDate.currentValue);
    // }
    const minDate: SimpleChange = changes.minDate;
    if (minDate !== undefined && minDate.currentValue !== undefined && minDate.currentValue !== '') {
      this.bindMinDate = JSON.parse(minDate.currentValue);
    }
    const maxDate: SimpleChange = changes.maxDate;
    if (maxDate !== undefined &&  maxDate.currentValue !== undefined && maxDate.currentValue !== undefined && maxDate.currentValue !== '') {
      this.bindMaxDate = JSON.parse(maxDate.currentValue);
    }
    const startDateValue: SimpleChange = changes.value;
    if (startDateValue !== undefined &&  startDateValue.currentValue !== undefined
        && startDateValue.currentValue !== undefined && startDateValue.currentValue !== '') {
      this.bindStartDateValue = JSON.parse(startDateValue.currentValue);
      // make sure the startDate and initial date value are the same:
      this.bindStartDate = JSON.parse(startDateValue.currentValue);
    }
  }

  onModelChange(event) {
    // FIND THE FIELDS WE NEED
    // THIS CHECK IS ONLY FOR VALID FORMAT,THE VALIDATOR DOES THE REQUIRED FORMAT
    // ON KEY UP EVENT WILL HANDLE ADDING A DOT BECAUSE IT CAN SEE IF ITS BACKSPACE OR DELETE
    const dateInput = $(this._eref.nativeElement).find('input');
    const inputValue = dateInput.val();
    const fieldErrorMessageDiv =  dateInput.closest('.element-wrapper').find('.error-field-wrong-format');
    if (!this.testDate(inputValue)) {
      // IF ITS INVALID ADD CLASS INVALID TO INPUT AND DISPLAY MESSAGE

    } else {
      // REMOVE THE MESSAGE
      $(fieldErrorMessageDiv).addClass('d-none');
      const method = dateInput.attr('method');
      if (method !== undefined && method !== '') {
        this.dateChange.emit({
          'method' : method,
          'params' : { targetElement: this._eref.nativeElement,
                        model: this.model }
        });
      }
    }
  }

  onFocusOut() {
    const dateInput = $(this._eref.nativeElement).find('input');
    const inputValue = dateInput.val();
    const fieldErrorMessageDiv =  dateInput.closest('.element-wrapper').find('.error-field-wrong-format');
    if (!this.testDate(inputValue) && !this.readOnly && inputValue !== '') {
      // IF ITS INVALID ADD CLASS INVALID TO INPUT AND DISPLAY MESSAGE
      fieldErrorMessageDiv.attr('validatormessage', '*the date must be in the correct format: dd.mm.yyyy');
      $(dateInput).addClass('invalid-input');
      $(fieldErrorMessageDiv).removeClass('d-none');
    } else if (this.testDate(inputValue) && !this.readOnly && this.helper.convertToDate(inputValue)
               > this.helper.convertToDate(this.turnPluginDateToOrdinaryDate(this.maxDate))) {
      // correct format but higher than max value
      // IF ITS INVALID ADD CLASS INVALID TO INPUT AND DISPLAY MESSAGE
      fieldErrorMessageDiv.attr('validatormessage', '*the current date is greater than the maximum allowed date');
      $(dateInput).addClass('invalid-input');
      $(fieldErrorMessageDiv).removeClass('d-none');
    } else if (this.testDate(inputValue) && !this.readOnly && this.helper.convertToDate(inputValue)
              < this.helper.convertToDate(this.turnPluginDateToOrdinaryDate(this.minDate))) {
      // correct format but lower than min value
      // IF ITS INVALID ADD CLASS INVALID TO INPUT AND DISPLAY MESSAGE
      fieldErrorMessageDiv.attr('validatormessage', '*the current date is less than the minimum allowed date');
      $(dateInput).addClass('invalid-input');
      $(fieldErrorMessageDiv).removeClass('d-none');
    } else {
      $(dateInput).removeClass('invalid-input');
      $(fieldErrorMessageDiv).addClass('d-none');
    }
  }

  turnPluginDateToOrdinaryDate(pluginDate) {
    pluginDate = JSON.parse(pluginDate);
    if (pluginDate.day.toString().length === 1) {
        pluginDate.day  = '0' + pluginDate.day;
    }
    if (pluginDate.month.toString().length === 1) {
      pluginDate.month  = '0' + pluginDate.month;
  }
    return pluginDate.day + '.' + pluginDate.month + '.' + pluginDate.year;
  }

  onKeyUp(event) {
    // FIND THE FIELDS WE NEED
    // THIS CHECK IS ONLY FOR VALID FORMAT,THE VALIDATOR DOES THE REQUIRED FORMAT
    const dateInput = $(this._eref.nativeElement).find('input');
    const inputValue = dateInput.val();
    const inputValueString = inputValue.toString();
    // IF KEY IS DELET OR BACKSPACE DONT ADD DOT
    const eventKey = event.key;
    if ( inputValueString.length === 2 &&
       // IF THERE'S TWO CHARACTERS THAT ARE NUMBERS ADD A DOT
       !isNaN(parseFloat(inputValueString.charAt(0)))
    && !isNaN(parseFloat(inputValueString.charAt(1)))
    && eventKey !== 'Delete' && eventKey !== 'Backspace'
    ) {
      $(dateInput).val(inputValueString + '.');
    } else {
      // IF THERE'S FIVE CHARACTERS THAT ARE NUMBERS ADD A DOT
      if ( inputValueString.length === 5 &&
        !isNaN(parseFloat(inputValueString.charAt(3)))
      && !isNaN(parseFloat(inputValueString.charAt(4)))
      && eventKey !== 'Delete' && eventKey !== 'Backspace'
      ) {
        $(dateInput).val(inputValueString + '.');
      }
    }
  }

  writeValue(value) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  preventUserInput(event) {
    event.preventDefault();
  }

  testDate(str) {
    const t = str.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
    if (t === null) {
      return false;
    }
    const d = +t[1], m = +t[2], y = +t[3];
    // Below should be a more acurate algorithm
    if (m >= 1 && m <= 12 && d >= 1 && d <= 31) {
      return true;
    }
    return false;
  }


}
