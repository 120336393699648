import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-forbidden-access',
  templateUrl: './forbidden-access.component.html',
  styleUrls: ['./forbidden-access.component.scss']
})
export class ForbiddenAccessComponent implements OnInit {

  // header variables
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    // initialize the header
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };
  }

  logout() {
    this.authService.logout();
  }

}
