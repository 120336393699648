import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { PdfServerService } from 'src/app/pdf-server.service';
import { FormBuilderServiceService } from '../form-builder-service.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import QRCode from 'qrcode';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';

@Component({
  selector: 'app-form-builder-preview',
  templateUrl: './form-builder-preview.component.html',
  styleUrls: ['./form-builder-preview.component.scss']
})
export class FormBuilderPreviewComponent implements OnInit {

  submissionData = null;
  constFormData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  };
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;

  editMode = true;
  errorState = false;
  cryptoKey = 'VFE:preview-mode-key';
  activeVerificationKey = 'xxxxxxxxx-yyyyyy-zzzzzzzzzzzzzz';
  firstName: any;
  lastName: any;
  dateOfBirth: any;
  contractBlob: any;
  contractFirstName;
  contractLatName;
  contractTemplate;
  contractTemplates;
  contractDOB;
  isMultipleContracts; // variable to know wether to change some text depending if there is one or more contracts

  stateMessage;
  contractCloseCountInterval = 25; // 25 seconds
  contractInformationInputed = false;

  currentForm = null;

  baseUrl = environment.APIEndpoint;

  constructor(
    public formBuilderService: FormBuilderServiceService,
    private helper: HelperServiceService,
    private pdf: PdfServerService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    private ipService: IpServiceService,
    private http: HttpClient,
    private idVerificationService: IdVerificationService
  ) { }

  ready(event){
    this.currentForm = event.formio;
  }

  /**
   * executed when user clicks on Submit button of the form
   * @param submission parameters submitted by the form
   */
    onSubmit(submission) {
      // this.previewFormio = false;
      this.currentForm.emit('submitDone');

      this.submissionData = submission.data;
      this.submissionData.constantData = this.constFormData;
      for (let [key, value] of Object.entries(submission.data)) {
        if (value) {
          if (this.formBuilderService.checkIfFormioDate([key, value], this.formBuilderService.formattedJson['components'])) {
            value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
            submission.data[key] = value;
          } else {
            // if not date parse it
            value = this.helper.parseBackendName(value.toString());
          }
          // we would no longer replace the values in memory
          // instead we will be using the html response from PDF server
          // templateTemp = templateTemp.split('{{' + key + '}}').join(value);
        }
      }

      if (this.formBuilderService.checkIfMultipleModes()) {
        this.ConfirmTemplateMode();
      } else {
        this.generatePreview(this.formBuilderService.currentMode);
      }

      $('.alert.alert-success').hide();
      $('.help-block').hide();
      $('.formio-component-submit > button').removeClass('btn-success submit-success');
    }

    ConfirmTemplateMode() {
      this.formType = `Confirm template mode`;
      this.displayLoader = false;
      // this.btnOkText = 'Ok';
      // this.btnCancelText = 'Cancel';
      // build the content for the Create new project lightbox
      const group = {
        'section_class' : 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'copy_form',
        'result_details' : [
          {
          'group_result_title' : 'confirm_template_mode',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          'elements' : [
            {
              'type': 'subtitle-alternate',
              'class': 'increased-margin-top  mt-3 location-text',
              'params': {
                'content': 'Please confirm your action'
              }
            },
            {
              'type': 'paragraph',
              'class' : 'text-center mb-3',
              'params': {
                'content':
                  `Please select which type of template you would want to be used as base for preview`
              }
            },
            {
              'type' : 'select',
              'class': 'reduced-margin-top input-transparent manualInput',
              'params' : {
                  'label': 'Template mode:',
                  'id': 'templateMode',
                  'name': 'mode',
                  'options':  [
                    {key : 'Draft', name : 'Draft'},
                    {key : 'Published', name: 'Published'},
                  ],
                  'optionselected': this.formBuilderService.currentMode,
                  'labelClass': 'font-weight-bold'
                },
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Ok',
                  // 'id': 'accept',
                  'action': 'onConfirmTemplateMode',
                  'class': 'form-button form-button-1',
                  'display': true
                },
                // {
                //   'content': 'Cancel',
                //   // 'id': 'rescan',
                //   'action': 'onCancelPublishForm',
                //   'class': 'form-button-2',
                //   'display': true // TODO: check if user may edit the information
                // }
              ]
            }
          ]
        }]
      };
      // set the content to the lightbox

      $('.alert.alert-success').hide();
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
    }

    onConfirmTemplateMode() {
      const mode = $('#templateMode').val().toString().toLowerCase();
      this.displayModal = false;
      this.generatePreview(mode);
    }

    generatePreview(mode) {
      this.displayLoadingPreviewContract();
      $('.alert.alert-success').hide();
      const payload = this.compilePayload('html');
      payload.jsonData.mode = mode;
      console.log('payload', payload);
      this.pdf.generatePDF(
        this.formBuilderService.currentTemplate
        , 'temp.html'
        , JSON.stringify(payload.jsonData)
        , ''
        , false
        , true
      )
      .then(response => {
        // check if json encoded response
        try {
          response = JSON.parse(response as any);
        } catch (e) {}

        if (Array.isArray(response)) {
          this.contractTemplate = '';
          this.contractTemplates = response.map(template => template[1]);
          this.isMultipleContracts = true;
        } else {
          this.contractTemplates = '';
          this.contractTemplate = response;
        }
        this.displaySignContract(false);
      }).catch();
    }

    compilePayload(reportFileFormat: string, genQrCode?: string, ipAddress?: string) {
      this.submissionData.reportFileFormat = reportFileFormat; // request html version of the document
      this.submissionData.generatedOnText = 'Document signed on'; // set the generatedOn text
      this.submissionData.orgId = this.formBuilderService.getUserOrgId().replace('ORG:', '');
      return {
        jsonData: this.submissionData,
        otherData: {
          qrCode: (genQrCode) ? genQrCode : '',
          documentName: 'Contract',
          verificationKey: this.cryptoKey,
          customOptions: {
          topMargin: '110px',
        }
      },
      userData: {
        userAgent: this.helper.myFullBrowser(),
        ipAddress: (ipAddress) ? ipAddress : '',
      },
      // htmlTemplateFile: 'signedContract',
      htmlTemplateFile: this.formBuilderService.currentTemplate,
      // email: (reportFileFormat === 'pdf' && !this.skipEmailSubmission) ? this.verificationRecord['personData']['MID_NP_EMAIL'] : ''
      email: ''
    };
  }

    displayLoadingPreviewContract() {
      const isMobile = this.helper.isMobile();
      let lightboxClass  = 'form-lightbox-fullscreen contract-vfs form-lightbox-breadcrumbs document-upload-list display-flex not-closable';
      if (isMobile) {
        lightboxClass = 'form-lightbox-fullscreen contract-vfs '
                                  + 'contract-vfs-mobile form-lightbox-breadcrumbs '
                                  + 'document-upload-list not-closable form-lightbox-fullscreen-mobile';
      }
      this.formClass = '';
      this.formType = '';
      this.formTitle = '';
      this.btnOkText = '';
      this.formValidator = '';
      this.btnCancelText = '';
      this.lightboxClass =  {
        class: lightboxClass,
        // showStepsMobile: isMobile,
        // logoImage: this.logo,
        // projectColor: this.projectColor,
        // projectTextColorClass: this.projectTextColorClass,
        // steps: this.stepsArray
      };
      // build the content for the Create new project lightbox
      const group = {
        'section_class' : 'results d-flex flex-column justify-items-center p-0 ',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'request_new_verification',
        'result_details' : [
          {
          'group_result_title' : 'request_new_address_verification_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          'elements' : [
            {
              'type' : 'documentVsSideVerification',
              'id' : 'activeDocument.type',
              'enabled': true,
              // 'isActive':  true,
              'editMode': false,
              'signedMode': false,
              'loaderMode': true,
              'class' : 'document-upload-no-list',
              'contractTemplate':
              '<div style="width: 100%; height: 60vh;" class="d-flex align-items-center justify-content-center"> <img class="align-self-center; width:40px; height:40px;" width="70px" height="70px" src="/assets/images/spinner-green.svg"/></div>',
              'params':  {
                'formTitle': this.translate.instant('IDPCS.contract.title2'),
                // 'evidenceKey': this.cryptoKey,
                'firstName': this.firstName,
                'lastName': this.lastName,
                'dateOfBirth': this.dateOfBirth,
                'startDate': (this.dateOfBirth !== undefined) ? this.helper.getAsStartDate(this.dateOfBirth) : undefined,
                'reviewState': false,
                'minDate': this.helper.getAsStartDate('01.01.1915'),
                'maxDate': this.helper.getAdultAsMaxDate(),
                'today': this.helper.getTodayDate('.'),
                'name' : 'csDateOfBirth',
                'contractInformationInputed' : this.contractInformationInputed,
                'isMultipleContracts' : this.isMultipleContracts
              }
            }
          ]
        }]
      };
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
    }

    /**
   * display the field value population/confirmation modal
   */
  displaySignContract(editMode, signed = false) {
    // prepare contract template


    const isMobile = this.helper.isMobile();
    let lightboxClass  = 'form-lightbox-fullscreen contract-vfs form-lightbox-breadcrumbs document-upload-list display-flex not-closable';
    if (isMobile) {
      lightboxClass = 'form-lightbox-fullscreen contract-vfs '
                                + 'contract-vfs-mobile form-lightbox-breadcrumbs '
                                + 'document-upload-list not-closable form-lightbox-fullscreen-mobile';
    }
    this.formClass = '';
    this.formType = '';
    this.formTitle = '';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass =  {
      class: lightboxClass,
      // showStepsMobile: isMobile,
      // logoImage: this.logo,
      // projectColor: this.projectColor,
      // projectTextColorClass: this.projectTextColorClass,
      // steps: this.stepsArray
    };
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0 ',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_new_verification',
      'result_details' : [
        {
        'group_result_title' : 'request_new_address_verification_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type' : 'documentVsSideVerification',
            'id' : 'activeDocument.type',
            'enabled': true,
            // 'isActive':  true,
            'editMode': editMode,
            'signedMode': signed,
            'quickPreview': true, // we are only previewing the PDF not actual signing
            'class' : 'document-upload-no-list',
            'contractTemplate': (this.contractTemplate) ? this.sanitizer.bypassSecurityTrustHtml(this.contractTemplate.replace('/font-size: ([0-9]+)px;/', '')) : '',
            'contractTemplates': (this.contractTemplates)
                                              ? this.contractTemplates.map(t => this.sanitizer.bypassSecurityTrustHtml(t.replace('/font-size: ([0-9]+)px;/', '')))
                                              : '',
            'params':  {
              'formTitle': signed ? this.translate.instant('IDPCS.contract.title1') : this.translate.instant('IDPCS.contract.title2'),
              // 'evidenceKey': this.cryptoKey,
              'firstName': this.firstName,
              'lastName': this.lastName,
              'dateOfBirth': this.dateOfBirth,
              'startDate': (this.dateOfBirth !== undefined) ? this.helper.getAsStartDate(this.dateOfBirth) : undefined,
              'reviewState': false,
              'minDate': this.helper.getAsStartDate('01.01.1915'),
              'maxDate': this.helper.getAdultAsMaxDate(),
              'today': this.helper.formatISODate( new Date().toISOString(), this.helper.getPdfFormatDate(), false ),
              'name': 'csDateOfBirth',
              'labelSignContract': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label1') : this.translate.instant('IDPCS.contract.label2'),
              'labelElectronicallySigning': (this.contractTemplates) ?
                    this.translate.instant('IDPCS.contract.label3') : this.translate.instant('IDPCS.contract.label4'),
              'labelPreparingDocument': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label5') : this.translate.instant('IDPCS.contract.label6'),
              'labelLegalySealing': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label7') : this.translate.instant('IDPCS.contract.label8'),
              'labelPleaseWait': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label9') : this.translate.instant('IDPCS.contract.label10'),
              'closeContractCounter': this.contractCloseCountInterval,
              'contractInformationInputed' : this.contractInformationInputed,
              'isMultipleContracts' : this.isMultipleContracts
            }
          }
        ]
      }]
    };

    if (!editMode && signed) {
      // TODO: SHOW DOWNLOAD BUTTON
      // THAT WILL TRIGGER DOWNLOAD OF ALL GENERATED CONTRACTS
      // this.countdownAndCloseContract();
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  async signContract() {

    $('.visual-steps-overlay').addClass('d-flex');
    $('.form-button-1.evidence-submit-button').attr('disabled', 'true');
    this.contractInformationInputed = true;
    const extendedDomain = `${location.protocol}//` + window.location.hostname + ((!location.port) ? '' : `:${location.port}`);
    const vfeCode = (this.activeVerificationKey) ? this.activeVerificationKey.replace('VFE:', '') : '';
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;

    // generate the qrcode
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }

    // fetch the IP address info
    let ipAddress = null;
    try {
      await this.ipService.getIPAddress()
                          .then(result => ipAddress = result)
                          .catch(error => console.error('could not retrieve IP information', error));
    } catch (err) {
      console.error(err);
    }

    // compile the payload
    const payload = this.compilePayload('pdfBuffer', genQrCode, ipAddress);

    // add dummy visual animation 1
    setTimeout(function () {
        $('.visual-contract-step:first-child').removeClass('step-in-progress');
        $('.visual-contract-step:first-child').addClass('step-finished');
        $('.visual-contract-step:nth-child(2)').addClass('step-in-progress');
    }, 100);

    // add dummy visual animation 2
    setTimeout(function () {
        $('.visual-contract-step:nth-child(2)').removeClass('step-in-progress');
        $('.visual-contract-step:nth-child(2)').addClass('step-finished');
        $('.visual-contract-step:nth-child(3)').addClass('step-in-progress');
    }, 300);

    const _this = this;
    setTimeout(function () {
    // _this.http.post(
    //             _this.baseUrl + 'api/verifications/' + _this.formBuilderService.currentTemplate + '/contract', payload
    //             , { responseType: 'text', observe: 'body'}
    //           )
    _this.pdf.generatePDF(
                _this.formBuilderService.currentTemplate
                , 'preview.pdf'
                , JSON.stringify(payload.jsonData)
                , ''
                , false
                , true
              )
              .then(
                async response => {
                  $('.visual-contract-step:nth-child(3)').removeClass('step-in-progress');
                  $('.visual-contract-step:nth-child(3)').addClass('step-finished');
                  try {
                    if (typeof(response) === 'string') {
                      response = JSON.parse(response);
                    }
                  } catch (error) {}

                  if (Array.isArray(response)) {
                    const generatedFiles = await Promise.all(response.map(async file => {
                      return await _this.convertToFile(file.data);
                    }));
                    _this.contractBlob = generatedFiles;
                  } else {
                    // do nothing, already base64 pdf string
                    // _this.contractBlob = response;
                    const newBlob = new Blob([response as Blob], { type: 'application/pdf' });
                    _this.contractBlob = window.URL.createObjectURL(newBlob as Blob);
                    // console.log('response type', typeof response, response);
                    // await _this.idVerificationService.convertBlobToBase64Img(newBlob)
                    //       .then(blobResponse => {
                    //         // this.contractBlob =  blobResponse;
                    //         console.log('this is the blob response', blobResponse);
                    //         _this.contractBlob = window.URL.createObjectURL(blobResponse as Blob);
                    //       })
                    //       .catch(error => {
                    //         this.errorState = true; this.stateMessage = 'We could not download the signed contract!';
                    //         console.error(error);
                    //       });
                  }

                  setTimeout(function () {
                    $('.visual-steps-overlay').removeClass('d-flex');
                    _this.displaySignContract(false, true);
                  }, 1500);

                //   await  _this.http.post(
                //     _this.baseUrl + 'api/verifications/' + _this.formBuilderService.currentTemplate + '/contract/complete', ''
                //     , { responseType: 'text', observe: 'body'}
                //   ).toPromise()
                //   .then(response => {
                //     // this.contractBlob = 'data:application/pdf;base64,' + this.verificationRecord.contractTemplate;
                //     console.log('response from complete call', response);
                //     setTimeout(function () {
                //       $('.visual-steps-overlay').removeClass('d-flex');
                //       _this.displaySignContract(false, true);
                //     }, 1500);
                //     }
                //   )
                //   .catch(error => {
                //     console.error('We could not complete the contract', error);
                //     _this.errorState = true;
                //     _this.stateMessage = this.translate.instant('IDPCS.contract.content1');
                //     $('.visual-steps-overlay').removeClass('d-flex');
                //   });
                }
              )
              .catch(error => {
                console.error('We could not sign the contract', error);
                _this.errorState = true;
                _this.stateMessage = this.translate.instant('IDPCS.contract.content1');
                // for testing purposes until BE stabilizes
                // this.contractBlob = new Blob([this.previewPdf as Blob], { type: "application/pdf" });
                // this.errorState = false;
                $('.visual-steps-overlay').removeClass('d-flex');
                // this.displaySignContract(false, true);
              });
    }, 600);
  }

  convertToFile(file) {
    return new Promise(async (resolve, reject) => {
      const bufferArray = new Uint8Array(file);
      const blob = new Blob([bufferArray], { type: 'application/pdf' });
      const data = window.URL.createObjectURL(blob);
      resolve(data);
      // const newBlob = new Blob([file as Blob], { type: 'application/pdf' });
      // // IE doesn't allow using a blob object directly as link href
      // // instead it is necessary to use msSaveOrOpenBlob
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //     window.navigator.msSaveOrOpenBlob(newBlob);
      //     return;
      // }
      // // this.contractBlob = window.URL.createObjectURL(newBlob);
      // // possible solution to iOS download issue: this.contractBlobUrl = window.URL.createObjectURL(newBlob);
      // // android web browsers do not support blob in iframe
      // // convert it to base64
      // await this.idVerificationService.convertBlobToBase64Img(newBlob)
      //       .then(blobResponse => {
      //         // this.contractBlob =  blobResponse;
      //         console.log('this is the blob response', blobResponse);
      //         resolve (blobResponse);
      //       })
      //       .catch(error => {
      //         this.errorState = true; this.stateMessage = 'We could not download the signed contract!';
      //         console.error(error);
      //       });
    });
  }

  onDownloadContract() {
    this.onCloseContract(null, true);
  }

  onCloseContract($event, download = false) {
    console.log(download, !this.helper.isIOS(), this.contractBlob);
    // if (download && !this.helper.isIOS()) {
      console.log('download not from ios');
      const link = document.createElement('a');
      if (Array.isArray(this.contractBlob)) {
        this.contractBlob.map(contract => {
          link.href = contract;
          link.target = '_blank';
          link.download = `${this.helper.getTodayDate()}-${this.cryptoKey.replace('VFE:', '')}-contract.pdf`;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        });
      } else {
        console.log(this.contractBlob);
        link.href = this.contractBlob;
        link.target = '_blank';
        link.download = `${this.helper.getTodayDate()}-${this.cryptoKey.replace('VFE:', '')}-contract.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      }
    this.displayModal = false;
    this.formBuilderService.displayEditForm();
  }

  /**
   * iterate trough the schema and add default value if any backend data matches a schema key
   * @param personData prefill data from the BE
   * @param schemaData the schema as we retreive it from the PDF server
   */
  prefillBEData(personData, schemaData) {
    // iterate through the person data
    for (let [key, value] of Object.entries(personData)) {
      if (key === 'dateOfBirth') {
        value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
      }
      if (typeof value === 'string') {
        value = this.helper.capitalize(value as string);
      }
      // recursively iterate through all components of components
      this.prefillComponents(schemaData.components, [key, value]);
    }
    return schemaData;
  }

  // prefill components with gathered data from previous steps
  private prefillComponents(components, entry) {
    components.map(component => {
      if (component.key === entry[0]) { component.defaultValue = entry[1]; }
      if (component.properties && component.properties.populatedBy)  {
        const populateByArr = component.properties.populatedBy.split('+');
        if (populateByArr.includes(entry[0])) {
          if (component.multiple) {
            component.defaultValue += ` ${[entry[1]]}`;
          } else if (component.properties.valueKey) {
            component.defaultValue = [entry[1][component.properties.valueKey]][0];
          } else {
            component.defaultValue = [entry[1]];
          }
        }
      }
      // if address
      // WARNING: this is not working/the issue is with Formio
      // if (component.type === 'address' && entry[0] === 'addressData')  {
      //   // normalize address for formio:
      //   component.defaultValue.mode = 'manual';
      //   component.defaultValue.address = {
      //     address1: entry[1].Address,
      //     address2: '',
      //     city: entry[1].City,
      //     state: (entry[1].State) ? entry[1].State : '',
      //     country: entry[1].Country,
      //     zip: entry[1].ZipCode,
      //   };
      // }
      if (component.components) {
        this.prefillComponents(component.components, entry);
      }
    });
  }

  /**
   * called when user clicked EDIT INFORMATION button
   */
  onEditInformation() {
    this.contractInformationInputed = false;

    // recursively iterate through all components of components
    Object.entries(this.submissionData).map(entry => {
      this.formBuilderService.parseComponents(this.formBuilderService.formattedJson['components'], entry);
    });

    this.formBuilderService.refreshPreviewForm();
    this.displayModal = false;
    // this.formBuilderService.displayEditForm();
    this.formBuilderService.displayPreview();
  }

  /**
   * handle click on lightbox confirm
   * @param event event
  */
   onLightboxConfirm(event) {
    if (typeof(event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params) || typeof(event.params) === 'object') {
        params = event.params;
      }
      if (typeof(event.params.name) === 'string') {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', 'sending params:', params, error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    // execute default action
    if (typeof(event.method) === 'undefined' ) { // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
    }
  }

  /**
   * handle click on lightbox close
   */
  onLightboxClose(event) {
    let classes = '';
    if ($(event.target).hasClass('close-form-button')) {
      classes =  $(event.target).attr('class');
    } else {
      classes =  $(event.target).closest('.close-form-button').attr('class');
    }
    let lightboxType = 'displayModal';
    if (classes && classes.includes('top-dialog-box')) {
      lightboxType = 'topDisplayModal';
    }
    if (classes && classes.includes('foremost-dialog-box')) {
      lightboxType = 'foremostDisplayModal';
    }
    this[lightboxType] = false;
  }

  ngOnInit(): void {
  }

}
