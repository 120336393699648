<section class="form-wrapper d-flex flex-column justify-items-center mx-auto">
    <div class="mb-2 mt-3 filter-element mx-auto d-flex justify-content-between align-items-center w-100">
      <h2>LIST OF KYC TEMPLATES</h2>
      <div class="d-flex flex-row align-items-center justify-content-end mt-4 mb-5 mr-3">
        <div class="button-add flex-row align-items-center justify-content-end" (click)=" CreateNewTemplate($event);">
          <img src="/assets/images/ico-add-template.svg" width="45px" height="45px" class="mr-2"/>
          <div >Add new template</div>
        </div>
      </div>
    </div>
    <div class="templates-wrapper pb-5">
      <div
        class="card d-flex flex-row align-items-between"
        *ngFor="let template of myOrganisationTemplates"
        [ngClass]="{ 'published': template.record.published, 'draft': !template.record.published}"
      >
        <div
          class="d-flex flex-fill align-items-center body"
          [attr.data-template-key]="template.key"
          [attr.data-template-name]="template.record.templateName"
          [attr.data-template-published]="template.record.published"
          (click)="onTemplateEdit($event)"
          style="cursor: pointer; max-width: 400px; margin-right: auto;"
        >
          <img src="/assets/images/ico-templates.svg" width="16px" height="20px" class="mr-2 non-interactive" *ngIf="template.type=='single'"/>
          <img src="/assets/images/ico-templates-multiple.svg" width="16px" height="20px" class="mr-2 non-interactive" *ngIf="template.type!='single'"/>
          {{template.record.templateName}}
        </div>
        <div class="menu-wrapper d-flex pt-2">
          <!-- <a href="#" class="settings-icon justify-content-end align-items-end ml-auto mr-0" (mouseover)="DisplayMyOptions($event)" (mouseleave)="HideMyOptions($event)"> -->
          <a href="#" class="settings-icon justify-content-end align-items-end ml-auto mr-0">
            <!-- <img src="/assets/images/ico-settings.svg" width="20px" height="20px" alt="d-flex settings icon"> -->
            <div class="d-flex flex-column more-options"></div>
          </a>
          <ul style="z-index: 1000" class="submenu flex-row align-items center">
            <li class="mr-2" >
              <a href="#" (click)="onTemplateEdit($event)"><img src="/assets/images/ico-edit-template.svg" alt="Edit template" width="20px" height="20px" class="mr-1 non-interactive" /></a>
              <div class="callout non-interactive">Edit template</div>
            </li>
            <li class="mr-2">
              <a href="#" (click)="publishToggle($event, template.record.published, template.key)">
                <img *ngIf="template.record.published" src="/assets/images/ico-unpublish-template.svg" alt="Unpublish template" width="20px" height="20px" class="mr-1 non-interactive" />
                <img *ngIf="!template.record.published" src="/assets/images/ico-publish-template.svg" alt="Unpublish template" width="20px" height="20px" class="mr-1 non-interactive" />
              </a>
              <div class="callout">{{ template.record.published ? 'Unpublish template' : 'Publish template' }}</div>
            </li>
            <li class="mr-2" *ngIf="!template.record.inUse">
              <a href="#" (click)="OnTemplateDelete($event)"><img src="/assets/images/ico-delete-template.svg" alt="Delete template" width="20px" height="20px" class="mr-1 non-interactive" /></a>
              <div class="callout non-interactive">Delete template</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  
  
  <!-- <app-lightbox *ngIf='displayModal'
    [formType]='formType'
    [formTitle]='formTitle'
    [formClass]='formClass'
    [formSubTitle]='formSubTitle'
    [lightboxClass]='lightboxClass'
    [btnOkText]='btnOkText'
    [btnCancelText]='btnCancelText'
    [displayModal]='displayModal'
    [displayLoader]='displayLoader'
    [contentDOM]="lightboxContent"
    (confirmLightbox)="onLightboxConfirm($event)"
    (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox> -->