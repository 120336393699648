import { Component, OnInit } from '@angular/core';
import { VerificationsService } from '../verifications.service';
import { ActivatedRoute } from '@angular/router';
import { WindowRefService } from 'src/app/window-ref.service';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { HelperServiceService } from 'src/app/helper-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponentGo implements OnInit {

  displayLoader: boolean;
  verificationKey: string;
  logo: string;
  postUrl: string;
  projectColor: string;
  projectTextColorClass: string;
  message = '';
  headerInfo: {};
  headerClasses: {};

  constructor(
    private verificationsService: VerificationsService
    , private winRef: WindowRefService
    , private http: HttpClient
    , private helper: HelperServiceService,
      public translate: TranslateService
    ) {
      this.postUrl = environment.APIEndpoint + 'api/verifications/data/completed';
    }

  ngOnInit() {
    this.displayLoader = true;
    this.projectColor = '#ffffff';
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };
    // This page will be implemented into an iframe
    // get reference to the window.parent and extract the verificationKey
    const location_parts = this.winRef.nativeWindow.parent.location.pathname.split('/');
    // if (location_parts[location_parts.length - 1].indexOf('VFE:') !== -1) {
    // if (location_parts[2]) {
      this.verificationKey = location_parts[location_parts.length - 2];
      this.verificationsService.getVerificationToken(this.verificationKey).subscribe(response => {
        if (response && response.projectBranding && response.projectBranding.projectColor) {
          this.verificationsService.setProjectColor(response.projectBranding.projectColor);
        } else {
          this.verificationsService.setProjectColor('#00889c');
        }
        this.projectColor =  this.verificationsService.getProjectColor();
        this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();

        /**
         * logo is not needed as this page is displayed in an iframe with logo already implemented
         */

        // this.message = 'THANK YOU FOR UPLOADING YOUR DOCUMENTS WHICH HAVE BEEN RECEIVED FOR FURTHER PROCESSING. THE REQUESTING PARTY WILL BE IN CONTACT SHOULD ANYTHING FURTHER BE REQUIRED. YOU MAY CLOSE THIS WINDOW.';
        this.message = this.translate.instant('common.pleaseWait');
        this.displayLoader = true;
        // Get the url query parameter to send to the backend
        const urlQuery = window.location.search;
        this.postUrl = this.postUrl + urlQuery;
        // Send the url query parameter to the backend
        if (!window.top.location.href.includes('lightbox')) {
            this.message = this.translate.instant('IDPCS.idVerification.content3');
            this.displayLoader = false;
          } else {
            // if it's a CDD lightbox send a message trough the iframe
            if (window.top.location.href.includes('lightbox')) {
              parent.postMessage('lightbox id-verification is completed GO',undefined);
            } else {
              this.message = this.translate.instant('IDPCS.idVerification.content3');

            }
          }
      });

  }

}
