import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { QuestionnaireBuilderService } from '../questionnaire-builder.service';
import { options } from '../custom-builder/options';

@Component({
  selector: 'app-q-builder-preview',
  templateUrl: './q-builder-preview.component.html',
  styleUrls: ['./q-builder-preview.component.scss']
})
export class QBuilderPreviewComponent implements OnInit {

  submissionData = null;
  constFormData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  };

  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;

  editMode = true;
  errorState = false;

  public options: any;

  constructor(
    public qBuilderService: QuestionnaireBuilderService,
    private helper: HelperServiceService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
  ) {
    this.options = options;
  }

  ngOnInit(): void {
  }

  /**
   * TODO: save the weight of the questions
   * executed when user clicks on Submit button of the form
   * @param submission parameters submitted by the form
   */
  onSubmit(submission, form) {
    console.log('this is the submitted data', submission);
    // this.displayLoadingPreviewContract();
    // this.submissionData = submission.data;
    // this.submissionData.constantData = this.constFormData;
    // for (let [key, value] of Object.entries(submission.data)) {
    //   if (value) {
    //     if (this.formBuilderService.checkIfFormioDate([key, value], this.formBuilderService.formattedJson['components'])) {
    //       value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
    //       submission.data[key] = value;
    //     } else {
    //       // if not date parse it
    //       value = this.helper.parseBackendName(value.toString());
    //     }
    //     // we would no longer replace the values in memory
    //     // instead we will be using the html response from PDF server
    //     // templateTemp = templateTemp.split('{{' + key + '}}').join(value);
    //   }
    // }

    // const payload = this.compilePayload('html');
    // payload.jsonData.mode = this.formBuilderService.currentMode;
    // console.log('payload', payload);
    // this.pdf.generatePDF(
    //   this.formBuilderService.currentTemplate
    //   , 'temp.html'
    //   , JSON.stringify(payload.jsonData)
    //   , ''
    //   , false
    //   , true
    // )
    // .then(response => {
    //   // check if json encoded response
    //   try {
    //     response = JSON.parse(response as any);
    //   } catch (e) {}

    //   if (Array.isArray(response)) {
    //     this.contractTemplate = '';
    //     this.contractTemplates = response.map(template => template[1]);
    //     this.isMultipleContracts = true;
    //   } else {
    //     this.contractTemplates = '';
    //     this.contractTemplate = response;
    //   }
    //   this.displaySignContract(false);
    // });
  }

}
