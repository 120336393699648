import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent implements OnInit {

  constructor(private router:Router, private auth: AuthService) { }
  headerInfo;
  headerClasses;

  ngOnInit() {

    if (this.router.url === '/logout') {
      this.auth.logout();
    }
    // initialize the header
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      // 'headerTitle': 'Integrity Check',
      // 'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };

  }

}
