import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef, AfterViewChecked, OnChanges, AfterViewInit } from '@angular/core';
import { IntegrityService } from 'src/app/integrity-check/integrity.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { HelperServiceService } from 'src/app/helper-service.service';
import { UserService } from 'src/app/user.service';
import { UiService } from '../ui.service';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { last, takeUntil } from 'rxjs/operators';
import { PdfServerService } from 'src/app/pdf-server.service';
import { NotificationsService } from '../notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { not } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked, OnChanges {
  displayFlagging: boolean;
  displayUnderline: boolean;
  underlineTimeout: any;
  loggedUser: {};
  @Input() headerInfo: {};
  @Input() headerClasses: {};
  @Input() kvkResults: any[];
  @Input() message: string;
  @Input() initiated: boolean;
  @Output() searchTerm = new EventEmitter<string>();
  @Output() selectedCompany = new EventEmitter<any>();
  @Output() closeLightbox = new EventEmitter();
  @Output() formBuilderPreview = new EventEmitter();
  unsubscribe$: Subject<void> = new Subject<void>();


  activeUser: number;
  availableServices: [];

  public backgroundClass;
  public sectionClass;
  public topText;

  // lightbox vars
  displayModal: boolean;
  formType: string;
  formTitle: string;
  formClass: string;
  formSubTitle: string;
  lightboxContent: any[];
  lightboxClass: string;
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  displayLoader: boolean;
  flagEntityEnabled: boolean;
  displayOrganisation: boolean;
  displayFundsOrganization: boolean;
  displayFundsOrganizationAdmin: boolean;
  isInvestor: boolean;
  currentLang: string;
  contractAuthoring: boolean;

  // notifications vars
  limitNotifications = 5; // it will determine max number of notification when collapsed
  displayNotifications: number; // it will determine how many notifications are currently displayed
  seenTimestamp: any;  // timestamp in miliseconds since hover on notification occured (used to mark unseen notifications as seen)
  notifications = {
    all: [],
    new: [],
    seen: [],
  };

  constructor(
    private cd: ChangeDetectorRef,
    private helper: HelperServiceService,
    private integrityService: IntegrityService,
    private notificationService: NotificationsService,
    private idVerificationService: IdVerificationService,
    private route: Router,
    private authService: AuthService,
    private userService: UserService,
    private uiService: UiService,
    public translate: TranslateService,
    private pdfService: PdfServerService) { }

  ngOnInit() {


    // this.availableServices = this.authService.user.permissions;
    this.availableServices = this.userService.getUserPermissions();
    // console.log('*******PERMISSIONS', this.availableServices);
    // console.log('*******USER', this.userService.getUserInfo());
    this.displayUnderline = false;
    this.displayFlagging = true;
    this.backgroundClass = this.headerClasses['backgroundClass']; // 'tealish-gradient-bg';
    this.sectionClass = this.headerClasses['sectionClass'];  // 'ic_height_full_page';
    this.topText = 'Second Trust Services B.V.';
    this.displayLoader = false;
    this.flagEntityEnabled = false;
    this.displayOrganisation = this.userService.getUserPermission('access:integrity');
    this.displayFundsOrganization = this.userService.getUserPermission('access:funds')
      && this.userService.getUserPermission('write:org');
    this.displayFundsOrganizationAdmin = this.userService.getUserPermission('access:admin');
    this.contractAuthoring = this.userService.getUserPermission('write:contract-authoring');
    this.displayModal = false;

    // set the default user status to -1
    this.activeUser = -1;
    if (this.userService.getUserInfo() !== null) {
      this.activeUser = 1;
      this.loggedUser = this.userService.getUserInfo();
    }

    // subscribe to user status updates
    this.userService.activeUserStatus.subscribe(status => {
      if (status !== false) {
        this.activeUser = 1;
        this.loggedUser = this.userService.getUserInfo();
        this.availableServices = this.userService.getUserPermissions();
        this.displayOrganisation = this.userService.getUserPermission('access:integrity');
        this.displayFundsOrganization = this.userService.getUserPermission('access:funds')
          && this.userService.getUserPermission('write:org');
        this.displayFundsOrganizationAdmin = this.userService.getUserPermission('access:admin');
        this.contractAuthoring = this.userService.getUserPermission('write:contract-authoring');
        this.alterMenuAfterLoad();
        if (this.userService.getUserPermission('access:investor')) {
          this.currentLang = this.translate.currentLang;
          this.isInvestor = true;
        }
        return;
      }
      this.activeUser = 0;
    });

    // get system properties
    this.integrityService.loadSystemProperties();

    // check if user is valid
    if (this.userService.getUserPermission('access:integrity')) {
      this.integrityService.validateUser();
    }

    // set default notifications
    this.displayNotifications = this.limitNotifications;

    // if (this.headerInfo['activeNavLink'] === 'integrityCheck') {
    // get notifications
    this.notificationService.getNotifications();
    // }


    this.integrityService.flagedCompany.subscribe(
      result => {
        this.OnFlagComplete(result.status);
        // set the ScrollToKey
        this.integrityService.setScrollToKey(result.response.key);
        // reload flagged companies
        this.integrityService.getMyFlaggedCompanies();
      }
    );

    this.notificationService.notificationsList.subscribe(notificationList => {
      // parse the notifications into new and seen
      this.parseNotifications(notificationList);
    });

    // handle update user status
    this.integrityService.updateUserInfoStatus.subscribe(
      (status => {
        this.formType = 'Profile';
        this.formTitle = ''; // 'Financial Holdings B.V';
        this.btnOkText = '';
        this.formValidator = '';
        this.btnCancelText = 'Ok';
        // build the content for the Request Information light box
        const group = {
          'section_class': 'results d-flex flex-column justify-items-center p-0',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'perform_integrity_check',
          'result_details': [
            {
              'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
              // 'result_title' : 'What would you like to know?',
              'elements': [
                {
                  'type': 'image',
                  'params': {
                    'src': '/assets/images/flag.png'
                  }
                },
                {
                  'type': 'paragraph',
                  'params': {
                    'content': (status === null) ? 'Unexpected error! We could not update your profile.' : status,
                  }
                },
              ]
            }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.displayModal = true;
        this.displayLoader = false;
        this.cd.detectChanges();

      })
    );

    this.integrityService.markCloseNotificationStatus.subscribe(notificationKey => {
      if (notificationKey !== undefined && notificationKey !== null) {
        const _this = this;
        this.notifications.all.forEach(function (notification, index) {
          if (notification.key === notificationKey) {
            _this.notifications.all.splice(index, 1);
          }
        });
        this.notifications.new.forEach(function (notification, index) {
          if (notification.key === notificationKey) {
            _this.notifications.new.splice(index, 1);
          }
        });
        this.notifications.seen.forEach(function (notification, index) {
          if (notification.key === notificationKey) {
            _this.notifications.seen.splice(index, 1);
          }
        });
      }
    });

    this.idVerificationService.createLegalRepresentativeStatus.subscribe(response => {
      this.displayLoader = false;
      // console.log('enter in attempt to display the error dialog', response);

      if (response === undefined || response.status === false) {
        console.log('attempt to display the error dialog');
        // display unexpected error
        this.DisplayFailedCreatePerson(response.personData, response.status);
        return;
      }
      if (response && !response.onCreate) {
        this.displayModal = false;
      }
      if (!response) {
        this.displayModal = false;
      }
    });

    this.idVerificationService.uuiDUpdatesStatus.subscribe(response => {
      this.onUpdateOrganizationFunds(undefined, true, response);

    });

  }

  /**
   * on toggle menu
   */
  toggleMenu() {
    $('.navbar-toggler').toggleClass('collapsed');
    $('.nav').toggleClass('collapsed');
  }

  dispatchFormBuilderPreview($event) {
    this.formBuilderPreview.emit($event);
  }


  generateARLinkAgain() {
    this.displayLoader = true;
    let investorDetaisl = this.idVerificationService.getActiveInvestorDetaisl();
    if (investorDetaisl.record) {
      investorDetaisl = investorDetaisl.record;
    }
    const allowReuse = $('#allowReuse').is(':checked');

    this.idVerificationService.genereateShareInvestorUrlAutomatic(
      this.idVerificationService.getActiveProject(), this.idVerificationService.getActiveInvestor(),
      { 'email': investorDetaisl.investorFields.EmailAddress, 'allowReuse': allowReuse, 'templateKey': investorDetaisl.templateKey });
  }
  /**
 * Returns to dashboard
 * @param event event
 */
  backtodashboard(event) {
    $('.close-form-button,#lightBoxCloseImage').trigger('click');
  }
  showMoreDetails() {
    $('.tempHideInput').removeClass('tempHideInput');
    $('.showOrHideDetails').addClass('d-none');
  }
  showMoreDetailsCDD() {

    $('.showOrHideDetails').addClass('d-none');
  }
  /**
   * On add new person
   * @param event event
   */
  DisplayFailedCreatePerson(personData, errorMessage) {
    console.log('displaying error dialog');
    const params = {
      'formType': 'Create a new person',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'formClass': '',
      'lightboxClass': {
        'noScroll': true,
      },
      // build the content for the  lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'create_new_representative',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'paragraph',
                'params': {
                  'contentHTML': `We were unable to create the person at the moment!<div class="font-italic font-bold">Reason: <span class="text-red">\"${errorMessage.message ? errorMessage.message : errorMessage}\"</span></div>`,
                }
              },
              {
                'type': 'inputText',
                'params': {
                  'label': 'First name',
                  'id': 'lb-representative-fname',
                  'name': 'lbRepresentativeName',
                  'required': true,
                  'content': personData.firstName,
                  'maxLength': 100,
                }
              },
              {
                'type': 'inputText',
                'params': {
                  'label': 'Last name ',
                  'id': 'lb-representative-lname',
                  'name': 'lbRepresentativeName',
                  'required': true,
                  'content': personData.lastName,
                  'maxLength': 100,
                }
              },
              {
                'type': 'inputText',
                'params': {
                  'label': 'E-mail',
                  'id': 'lb-representative-email',
                  'name': 'lbRepresentativeEmail',
                  'patternMatch': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'validatorMessage': this.translate.instant('validation.validation5'),
                  'content': personData.email,
                  'required': false,
                }
              },
              {
                'type': 'input_date',
                'params': {
                  'label': 'Date of birth',
                  'id': 'lb-representative-dob',
                  'name': 'lbRepresentativeDob',
                  'startDate': (personData.dateOfBirth !== undefined) ? this.helper.getAsStartDate(personData.dateOfBirth) : undefined,
                  'minDate': this.helper.getAsStartDate('01.01.1915'),
                  'maxDate': this.helper.getAdultAsMaxDate(),
                  'required': false,
                  'content': this.helper.getAsStartDate(personData.dateOfBirth),
                }
              },

              // 16072019 it is decided that the relationship field will be hidden
              // https://gofinos.atlassian.net/browse/ST-781
              // TODO: setting selected type of representative from personData
              // {
              //   'type': 'select',
              //   'params': {
              //     'label': 'Relationship',
              //     'id': 'lb-representative-relationship',
              //     'name': 'lbRepresentativeRelationship',
              //     'options': [
              //       {'key': '-1', 'name': 'Please choose'},
              //       {'key': 'shareholder', 'name': 'Shareholder'},
              //       {'key': 'director', 'name': 'Director'},
              //     ],
              //     'optionselected': '-1',
              //     'required': false,
              //   }
              // },
              {
                'type': 'button',
                'params': {
                  'content': 'Save',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnSaveNewRepresentative'
                }
              },
            ]
          }]
      },
    };

    this.DisplayLightbox(params);
  }


  idOnAddSuggestedTag(event) {
    const tagValue = event.target.getAttribute('tagValue');
    const unadedArray = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
    if (!unadedArray) {
      this.idVerificationService.setActiveLegalRepresentativesUnadedTags([tagValue]);
    } else {
      if (!unadedArray.includes(tagValue)) {
        unadedArray.push(tagValue);
        this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
          unadedArray
        );
      }
    }
    const savedData = {
      // 'nameOfPerson' : $('#lb-representative-name').val() ,
      'firstNameOfPerson': $('#lb-representative-fname').val(),
      'lastNameOfPerson': $('#lb-representative-lname').val(),
      'emailOfPerson': $('#lb-representative-email').val(),
      'dobOfPerson': $('#lbRepresentativeDob input').val(),
      'tagInputValue': $('#addingTagInputText').val(),
      'internalReferenceOfPerson': $('#lb-internal-reference').val(),
      'nohideClass': true,
    };
    this.idVerificationService.skipToStep(17, savedData);
    // this.OnAddNewPerson();
  }

  checkForNewTag(event) {
    let tagValue = event.target.value;
    const splitArray = tagValue.split(',');
    if (splitArray[splitArray.length - 1] === '') {
      splitArray.pop();
    }

    if (splitArray.length > 1) {
      splitArray.forEach(splitTag => {
        splitTag = splitTag.trim();
        if (splitTag !== '') {
          const unadedArray = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
          if (!unadedArray) {
            this.idVerificationService.setActiveLegalRepresentativesUnadedTags([splitTag]);
          } else {
            if (!unadedArray.includes(splitTag)) {
              unadedArray.push(splitTag);
              this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
                unadedArray
              );
            }
          }
        }
      });
      event.target.value = '';
      const savedData = {
        // 'nameOfPerson' : $('#lb-representative-name').val() ,
        'firstNameOfPerson': $('#lb-representative-fname').val(),
        'lastNameOfPerson': $('#lb-representative-lname').val(),
        'emailOfPerson': $('#lb-representative-email').val(),
        'dobOfPerson': $('#lbRepresentativeDob input').val(),
        'internalReferenceOfPerson': $('#lb-internal-reference').val(),
        'tagInputValue': $('#addingTagInputText').val(),
        'nohideClass': true,
      };
    } else {
      if (tagValue.endsWith(',')) {
        tagValue = tagValue.slice(0, -1);
        tagValue = tagValue.trim();
        const unadedArray = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
        if (tagValue !== '') {
          if (!unadedArray) {
            this.idVerificationService.setActiveLegalRepresentativesUnadedTags([tagValue]);
          } else {
            if (!unadedArray.includes(tagValue)) {
              unadedArray.push(tagValue);
              this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
                unadedArray
              );
            }
          }
          event.target.value = '';
          const savedData = {
            // 'nameOfPerson' : $('#lb-representative-name').val() ,
            'firstNameOfPerson': $('#lb-representative-fname').val(),
            'lastNameOfPerson': $('#lb-representative-lname').val(),
            'emailOfPerson': $('#lb-representative-email').val(),
            'dobOfPerson': $('#lbRepresentativeDob input').val(),
            'internalReferenceOfPerson': $('#lb-internal-reference').val(),
            'tagInputValue': $('#addingTagInputText').val(),
            'nohideClass': true,
          };
          this.idVerificationService.skipToStep(17, savedData);
        } else {
          event.target.value = '';
        }
      }
    }
  }


  ngAfterViewChecked() {
    $('#jsd-widget').addClass('visible');
    // set default underline
    if (!this.displayUnderline && $('.navbar').find('.active').length) {
      this.HoverDefaultPrimary($('.navbar .active'));
      this.displayUnderline = true;
    }

  }

  changeCountryReviewFlow() {
    const selectedValue = $('#LegalPersonCountryOfRegistration option:selected').val();
    if (selectedValue !== 'emptySelect' && selectedValue !== '') {
      if (!this.idVerificationService.localFundCreationData.investorFields) {
        this.idVerificationService.localFundCreationData.investorFields = {};
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = selectedValue;
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry = selectedValue;
      } else {
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = selectedValue;
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry = selectedValue;
      }
      $('.company-review-wrapper').removeClass('d-none');
    } else {
      $('.company-review-wrapper').addClass('d-none');
    }
  }

  alterMenuAfterLoad() {
    let numMenuItems = 0;
    if (this.userService.getUserPermission('access:funds')) {
      numMenuItems++;
    }
    if (this.userService.getUserPermission('access:reporting')) {
      numMenuItems++;
    }
    if (this.userService.getUserPermission('access:verification')) {
      numMenuItems++;
    }
    if (this.userService.getUserPermission('access:kyc-template-management')) {
      numMenuItems++;
    }
    if (this.userService.getUserPermission('write:contract-authoring')) {
      numMenuItems++;
    }

    if (numMenuItems < 2) {
      $('.navbar-toggler').addClass('navbar-disabled');
    }
  }

  OnDisplayNotifications(event) {
    event.preventDefault();
    // if (this.notifications.all.length > 0) {
    const _this = this;
    const eventTarget = $(event.target).closest('.notification-wrapper').find('.all-notifications-outer');
    // eventTarget.fadeToggle(500);
    $('.all-notifications-outer').stop().fadeOut(60, function () {
      eventTarget.closest('.notification-wrapper').stop().find('.all-notifications-outer').fadeIn(500);
    });
    setTimeout(function () {
      _this.notificationService.markNotificationsAsSeen();
      if (_this.notifications.new.length > 0) {
        _this.notifications.all.forEach((notification) => {
          notification.record.status = 'seen';
        });
        _this.notifications.new = [];
        _this.notifications.seen = _this.notifications.all;
      }
      eventTarget.find('ul').niceScroll({
        cursorcolor: '#105f6b',
        cursorwidth: '4px',
        cursoropacitymin: 1,
        railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
      });
    }, 150);
  }

  onSearchTermChanged(event) {
    this.searchTerm.emit(event);
  }

  OnSelectedCompanyChange(event) {
    this.selectedCompany.emit(event);
  }

  ngOnChanges(changes: any) {
    if (changes.headerInfo !== undefined) {
      this.displayFlagging = (this.activeUser !== 0 && changes.headerInfo.currentValue['headerType'] === 'full');
    }
  }

  /**
   * it will segment notifications into all, new, seen
   * @param raw_notifications: []
   */
  parseNotifications(raw_notifications) {
    if (raw_notifications && raw_notifications.records) {
      raw_notifications = raw_notifications.records;
    }
    if (!raw_notifications || raw_notifications.length < 1) {
      return;
    }
    if (Array.isArray(raw_notifications)) {
      raw_notifications.forEach(notification => {
        if (notification.record.status.toLowerCase() === 'pending') {
          this.notifications.new.push(notification);
        } else {
          this.notifications.seen.push(notification);
        }
      });
      this.notifications.all = this.notifications.new.concat(this.notifications.seen);
      // remove duplicate notifications
      const ids = this.notifications.all.map(o => o.key);
      const filtered = this.notifications.all.filter(({ key }, index) => !ids.includes(key, index + 1));
      this.notifications.all = filtered;
    }
  }

  /**
   * it will display all notifications
   */
  OnShowAllNotifications(event) {
    event.preventDefault();
    this.displayNotifications = this.notifications.all.length;
  }

  /**
   * On flagging a company
   */
  OnFlagCompany(event) {
    event.preventDefault();
    this.formType = 'Submit a new flag';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = 'Flag entity';
    this.formValidator = 'true';
    // this.btnCancelText = 'Disagree';
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'h3',
              'firstOfType': true,
              'params': {
                'classes': 'p-0',
                'content': 'Flag a legal entity'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Please specify the legal entity you would like to flag, the date you started servicing the entity, and the date you terminated services.'
              }
            },
            {
              'type': 'company_selector',
              'params': {
                'content': 'Please specify the legal entity you would like to flag, the date you started servicing the entity, and the date you terminated services.'
              }
            },
            {
              'type': 'input_date',
              'params': {
                'id': 'service_start_date',
                'name': 'service_start_date',
                'label': 'Service start date',
                'default_value': '',
                'required': true,
                'maxDate': this.helper.getTodayAsMaxDate(),
              }
            },
            {
              'type': 'input_date',
              'params': {
                'id': 'service_end_date',
                'name': 'service_end_date',
                'label': 'Service end date',
                'default_value': '',
                'required': true,
                'maxDate': this.helper.getTodayAsMaxDate(),
              }
            },
            {
              'type': 'checkbox',
              'params': {
                'id': 'integrity_risk_1',
                'name': 'integrity_risk_question_1',
                'label': 'I agree that the information I provide will be visible and searchable by other service providers, however the identity of my organisation will not be disclosed.',
                'value': 'What was the nature of the integrity risk?',
                'onclick': 'toggleFlagButtonEnable'
              }
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  changeLanguage(event, lang) {
    event.preventDefault();
    this.helper.changeLanguage(lang);
    this.helper.reloadLocation();
  }

  toggleFlagButtonEnable(params) {
    // alert('I should toggle flag button enabled status');
    // this.flagEntityEnabled = !this.flagEntityEnabled;
    if (!$('#integrity_risk_1').prop('checked')) {
      $('#integrity_risk_1')
        .parent()
        .find('label')
        .addClass('text-red');
      if ($('.title-prepend').length === 0) {
        $('#integrity_risk_1')
          .parent()
          .find('label')
          .prepend('<div class="title-prepend text-uppercase"><strong>PLEASE ACCEPT BEFORE CONTINUING!</strong></div>');
      }
      return;
    }
    $('#integrity_risk_1').parent().find('label').removeClass('text-red');
    $('#integrity_risk_1').parent().find('label .title-prepend').remove();
  }

  onLightboxClick(event) {
    // execute onclick action on element
    if (typeof (event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params)
        || (typeof (event.params) === 'object' && event.params !== null)) {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }

    if (typeof (event.error) !== 'undefined' && event.error === 'forceStop') {
      // something went wrong display error dialog and null selectedFlagCompany
      this.onDisplayError(event.message);
      this.integrityService.clearSelectedCompanyForFlag();
      return;
    }

    if (typeof (event.method) === 'undefined') { // allow only strings as acceptable method name
      // execute default OnConfirm action
      // validate the start and end date and also the selected legal entity
      const company = this.integrityService.getSelectedCompanyForFlag();
      if ((typeof company === 'undefined' || company === null) && $('#fallbackKvk').length > 0) {
        if ($('#fallbackKvk').val() === '') {
          alert('You must first enter a Kvk number!');
          return;
        }
        this.integrityService.setSelectedCompanyForFlag({
          'kvkNumber': $('#fallbackKvk').val()
        });
      } else if (typeof company === 'undefined' || company === null) {
        alert('You must first select a company!');
        return;
      }
      if ($('#service_start_date > input').val() === '') {
        alert('You must first select a start date!');
        return;
      }
      if ($('#service_end_date > input').val() === '') {
        alert('You must first select an end date!');
        return;
      }
      if (this.helper.getTimestamp($('#service_end_date > input').val())
        <=
        this.helper.getTimestamp($('#service_start_date > input').val())) {
        // this should not be allowed
        this.uiService.setInvalidInput($('#service_start_date > input'), 'Start date must be before end date');
        this.uiService.setInvalidInput($('#service_end_date > input'), 'End date must be after start date');
        return;
      }

      if (!$('#integrity_risk_1').prop('checked')) {
        this.toggleFlagButtonEnable('');
        return;
      }
      if (!$('#integrity_risk_1').prop('checked')) {
        // $('#integrity_risk_1').parent().find('label').addClass('text-red');
        this.toggleFlagButtonEnable('');
        return;
      }
      if (this.integrityService.setDatesForSelectedCompanyForFlag({
        startDate: this.helper.prepareDateForApi($('#service_start_date > input').val()),
        endDate: this.helper.prepareDateForApi($('#service_end_date > input').val()),
      })) {
        // pass the data to confirmSetCompanyFlag method
        this.confirmSetCompanyFlag();
      } else {
        this.onDisplayError('A company for flaging is not selected!');
      }
    }
  }

  onLightboxClose() {
    // this.cd.detectChanges();
    this.displayModal = false;
  }

  CopyLinkToClipboard2(event) {
    const inputEl = $(event.target).siblings('input');
    const statusEl = inputEl.siblings('.input-action-status');
    this.helper.copyToClipboard(inputEl.val());
    statusEl.text('Successfully copied!').fadeIn();
    setTimeout(function () {
      statusEl.fadeOut();
    }, 2500);
  }

  downloadPreviewFiles(event, isCustomEvidenceType) {
    const fileHtmlElements = $('.downloadble-preview-file');
    for (let i = 0; i < fileHtmlElements.length; i++) {
      this.helper.downloadingDocument(fileHtmlElements[i].src, $(fileHtmlElements[i]).attr('filename'));
    }
  }

  confirmSetCompanyFlag() {
    this.formType = 'Submit a new flag';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'h3',
              'firstOfType': true,
              'params': {
                'classes': 'p-0',
                'content': 'Are you sure?'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'The entity you selected will be flagged and your service start and end dates will be visible to other service providers.'
              }
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Yes, I am sure',
                  'action': 'OnConfirmFlagCompany',
                  'display': true
                },
                {
                  'content': 'No, cancel',
                  'action': 'OnCancelFlagCompany',
                  'display': true
                }
              ]
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  onDisplayError(message) {
    this.formType = 'Submit a new flag';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.displayLoader = false;
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'display_error_integrity_check',
      'result_details': [
        {
          'group_result_title': 'display_error_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'h3',
              'firstOfType': true,
              'params': {
                'content': 'Something went wrong!'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': message
              }
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'OK',
                  'action': 'OnCancelFlagCompany',
                  'display': true
                },
              ]
            },
          ]
        }]
    };

    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  /**
   * display the result of submiting company for flag
   * @param status boolean
   */
  OnFlagComplete(status) {
    this.formType = 'Submit a new flag';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.displayLoader = false;
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'display_error_integrity_check',
      'result_details': [
        {
          'group_result_title': 'display_error_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/flag.png',
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': (status) ? 'You have flagged the legal entity' : 'We could not flag the legal entity, please try again later',
              }
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Close',
                  'action': 'OnCancelFlagCompany',
                  'display': true
                },
              ]
            },
          ]
        }]
    };

    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    // this.cd.detectChanges();
  }

  /**
   * do the actual flaging of the company
   */
  OnConfirmFlagCompany() {
    // console.log('on confirm sending: ',
    //   this.integrityService.getSelectedCompanyForFlag()
    // );
    // return;
    // this.integrityService.setScrollToId(this.integrityService.getSelectedCompanyForFlag().kvkNumber);
    this.integrityService.flagCompany();
    this.displayModal = true;
    this.displayLoader = true;
  }

  /**
   * close the dialog and remove the selectedCompanyForFlag
   */
  OnCancelFlagCompany() {
    this.integrityService.clearSelectedCompanyForFlag();
    this.displayModal = false;
  }

  /**
   * Instead of reopenintegrity check we need to do a new perform integrity check
   * @param event: event
   */
  OnReOpenIntegrityCheck(event) {
    event.preventDefault();
    // set company under review kvk number
    // this.integrityService.setCurrentCompany({'kvkNumber' : $(event.target).attr('kvk') });
    // send to BE
    // this.integrityService.reopenIntegrityCheck($(event.target).attr('key'), $(event.target).attr('kvk'));
    // set the kvk number for the current company
    const targetKvk = $(event.target).attr('kvk');
    const targetNotificationKey = $(event.target).attr('notificationkey');
    // hide the link so it cannot be clicked multiple times in succession
    $(event.target).hide();
    $(event.target).parent().find('.my-spinner').attr('style', 'display: block !important');
    this.integrityService.setCurrentCompany({ 'kvkNumber': targetKvk });
    // mark notification as closed:
    this.integrityService.markNotificationAsClosed(targetNotificationKey);
    // go to integrity check
    this.integrityService.postIntegrityCheck();
  }

  OnUpdateProfile(event) {
    event.preventDefault();
    this.formType = 'Organisation';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // don't use the default submit button
    this.formValidator = '';
    this.btnCancelText = '';
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'inputText',
              'class': 'm-0',
              'params': {
                'id': 'entity_name',
                'label': 'Name of entity',
                'name': 'name_of_the_entity',
                'content': this.integrityService.getUserInfo()['userCompanyName'],
                'required': 'true'
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'email_address',
                'label': 'E-mail',
                'name': 'email',
                'content': this.integrityService.getUserInfo()['userEmail'],
                'required': 'true',
                'patternMatch': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'validatorMessage': this.translate.instant('validation.validation5'),
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Preferences',
              }
            },
            {
              'type': 'checkbox',
              'params': {
                'id': 'showICConfirmDialog',
                'name': 'display_notifications_on_new_integrity_search',
                'label': 'Don’t show recorded actions notification on every new integrity search.',
                'value': true,
                'checked': !this.integrityService.getUserPreference('showIntegrityCheckRecordConfirmDialog')
              }
            },
            {
              'type': 'checkbox',
              'params': {
                'id': 'sendEmail',
                'name': 'send_email_notifications',
                'label': 'Send email notifications',
                'value': true,
                'checked': this.integrityService.getUserPreference('sendEmail')
              }
            },
            {
              'type': 'paragraphIcon',
              'params': {
                // tslint:disable-next-line:max-line-length
                'content': 'Keep in mind that the changes you make will be visible by all profiles connected to this organisation.',
                'svgIcon': {
                  'src': '/assets/images/icon-alert-triangle-ng-prepared.svg',
                  'class': 'svg-orange',
                  'width': 20,  // required
                  'height': 16.9,  // required
                },
              },
              'displayHistory': false
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Save',
                  'action': 'OnSaveUserProfile',
                  'type': 'submit',
                  'display': true
                },
              ]
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    this.cd.detectChanges();
  }

  onUpdateOrganizationFundsAdmin(event, isAdminStepTwo?) {
    event.preventDefault();

    this.displayLoader = false;
    this.formType = 'Organisation';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.formClass = 'customization-form',
      this.btnOkText = '';  // don't use the default submit button
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass = 'organization-lightbox-wrapper';
    const organizationAdmin = this.userService.getUserPermission('access:admin');

    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0 ',
      'result_container_class': '',
      'is_collapsable': false,
      'lightboxClass': {

      },
      'form_id': 'perform_integrity_check',

      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'inputText',
              'class': 'mt-0',
              'params': {
                'id': 'orgUUID',
                'label': 'orgUUID',
                'labelClass': 'font-weight-bold',
                'name': 'Notes',
                'required': true,
                'patternMatch': /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
                'validatorMessage': 'invalid UUID',
              }
            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Confirm',
                  'labelClass': 'font-weight-bold',
                  'action': 'onGetUUID',
                  'type': 'submit',
                  'display': true
                },
              ]
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    if (!isAdminStepTwo) {
      this.cd.detectChanges();
    }
  }

  onGetUUID() {
    const orgUUId = $('#orgUUID').val();
    this.idVerificationService.getAdminInfoByUUID(orgUUId);
  }

  onUpdateOrganizationFunds(event?, isAdminStepTwo?, uuid?) {
    if (event) {
      event.preventDefault();
    }
    this.displayLoader = false;
    let showReportButton;
    if (this.idVerificationService.permissionUpdateReportGeneration()
      || this.idVerificationService.permissionAdmin()) {
      showReportButton = true;
    }
    this.formType = 'Organisation';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.formClass = 'customization-form',
      this.btnOkText = '';  // don't use the default submit button
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass = 'organization-lightbox-wrapper';
    const organizationAdmin = this.userService.getUserPermission('access:admin');

    let editedTypesOfWebhooks : Map<string, string> = new Map(
        this.idVerificationService.getfundsOrganizationDetails()?.Webhooks?.map(webhook => {
        return [webhook.type, webhook.url];
      }),
    )

    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0 ',
      'result_container_class': '',
      'is_collapsable': false,
      'lightboxClass': {

      },
      'form_id': 'perform_integrity_check',

      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'inputText',
              'class': 'm-0',
              'params': {
                'id': 'RegisteredName',
                'readOnly': !organizationAdmin,
                'required': organizationAdmin,
                'label': 'Registered name',
                'labelClass': 'font-weight-bold',
                'name': 'registered_name',
                'content': this.idVerificationService.getfundsOrganizationDetails()
                  ? this.idVerificationService.getfundsOrganizationDetails().RegisteredName : '',
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'RegistrationNumber',
                'label': 'Registration number',
                'readOnly': !organizationAdmin,
                'required': organizationAdmin,
                'labelClass': 'font-weight-bold',
                'name': 'registration_number',
                'content': this.idVerificationService.getfundsOrganizationDetails()
                  ? this.idVerificationService.getfundsOrganizationDetails().RegistrationNumber : '',
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'Website',
                'readOnly': !organizationAdmin,
                'required': organizationAdmin,
                'label': 'Website',
                'name': 'Website',
                'labelClass': 'font-weight-bold',
                'content': this.idVerificationService.getfundsOrganizationDetails()
                  ? this.idVerificationService.getfundsOrganizationDetails().Website
                  : '',
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'Logo',
                'readOnly': !organizationAdmin,
                'required': organizationAdmin,
                'label': 'Logo',
                'labelClass': 'font-weight-bold',
                'name': 'Logo',
                'content': this.idVerificationService.getfundsOrganizationDetails()
                  ? this.idVerificationService.getfundsOrganizationDetails().Logo
                  : '',
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'webhookCompletedProfile',
                'label': 'Webhook for completed profile',
                'labelClass': 'font-weight-bold',
                'name': 'WebhookCompletedProfile',
                'content': editedTypesOfWebhooks.get('completed_profile')
                  ? editedTypesOfWebhooks.get('completed_profile')
                  : '',
                'required': false
              }
            },
             {
              'type': 'inputText',
              'params': {
                'id': 'webhookActionRequiredProfile',
                'label': 'Webhook for action required profile',
                'labelClass': 'font-weight-bold',
                'name': 'WebhookActionRequiredProfile',
                'content': editedTypesOfWebhooks.get('action_required')
                  ? editedTypesOfWebhooks.get('action_required')
                  : '',
                'required': false
              }
            },
              {
              'type': 'inputText',
              'params': {
                'id': 'webhookClaimedProfile',
                'label': 'Webhook for claimed profile',
                'labelClass': 'font-weight-bold',
                'name': 'WebhookClaimedProfile',
                'content': editedTypesOfWebhooks.get('claimed_profile')
                  ? editedTypesOfWebhooks.get('claimed_profile')
                  : '',
                'required': false
              }
            },
            {
              'type': 'inputText',
              'params': {
                'id': 'Notes',
                'label': 'Notes',
                'labelClass': 'font-weight-bold',
                'name': 'Notes',
                'content': this.idVerificationService.getfundsOrganizationDetails()
                  ? this.idVerificationService.getfundsOrganizationDetails().Notes
                  : '',
                'required': false
              }
            },
            {
              'type': 'inputHidden',
              'params': {
                'id': 'uuid',
                'content': uuid
              }
            },
            {
              'type': 'toggle',
              'class': 'font-weight-bold d-flex align-items-center',
              'params': {
                'id': 'monitoring-toggle',
                'toggleOn': this.idVerificationService.getfundsOrganizationDetails()?.hasOwnProperty('MonitorAPSByDefault')
                  ? this.idVerificationService.getfundsOrganizationDetails().MonitorAPSByDefault
                  : false,
                'toggleClass': 'monitoring-toggle',
                'monitoringText': 'Allow monitoring of compliance checks'
              }

            },
            {
              'type': 'button_group',
              'params': [
                {
                  'content': 'Save',
                  'labelClass': 'font-weight-bold',
                  'action': 'onUpdateFundsOrganization',
                  'type': 'submit',
                  'display': true
                },
                {
                  'content': 'Generate report',
                  'labelClass': 'font-weight-bold',
                  'action': 'onGenerateCDDReport',
                  'type': uuid,
                  'display': showReportButton
                },
              ]
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    if (!isAdminStepTwo) {
      this.cd.detectChanges();
    }
  }

  OnDisplayProfileMenu(event) {
    event.preventDefault();
    const eventTarget = $(event.target).closest('.notification-wrapper');
    $('.all-notifications-outer').fadeOut(60).promise().done(function () {
      eventTarget.find('.all-notifications-outer').fadeIn(500);
    });
  }

  OnSaveUserProfile() {
    if ($('#entity_name').val() === '') {
      alert('Entity name field is required!');
      return;
    }
    const userParams = {
      'companyName': $('#entity_name').val(),
      'email': $('#email_address').val(),
      'preferences': {
        'showIntegrityCheckRecordConfirmDialog': !$('#showICConfirmDialog').prop('checked'),
        'sendEmail': $('#sendEmail').prop('checked'),
      }
    };
    this.integrityService.updateUser(userParams);
    this.displayLoader = true;
  }

  onUpdateFundsOrganization() {
    if ($('#organizationName').val() === '') {
      alert('Organization name field is required!');
      return;
    }

    const organizationAdmin = this.userService.getUserPermission('access:admin');
    let userParams = {};
    if (organizationAdmin) {
      const uuid = $('#uuid').val();

      let webhooks = []
      if($('#webhookCompletedProfile').val())
      {
        webhooks.push(
          {
            "url": $('#webhookCompletedProfile').val(),
            "type": "completed_profile"
          }
        )
      }
      if($('#webhookActionRequiredProfile').val())
      {
        webhooks.push(
          {
            "url": $('#webhookActionRequiredProfile').val(),
            "type": "action_required"
          }
        )
      }
      if($('#webhookClaimedProfile').val())
      {
        webhooks.push(
          {
            "url": $('#webhookClaimedProfile').val(),
            "type": "claimed_profile"
          }
        )
      }
      userParams = {
        'RegisteredName': $('#RegisteredName').val(),
        'RegistrationNumber': $('#RegistrationNumber').val(),
        'Website': $('#Website').val(),
        'Logo': $('#Logo').val(),
        'Webhooks': webhooks,
        'Notes': $('#Notes').val(),
        'MonitorAPSByDefault': $('#monitoring-toggle').is(':checked')
      };
      this.idVerificationService.postAdminInfoByUUId(uuid, userParams);
    } else {
      userParams = {
        'Notes': $('#Notes').val()
      };
      this.idVerificationService.postFinosFundsOrganization(userParams);
    }

    this.displayLoader = true;
  }

  onGenerateCDDReport(event) {
    event.preventDefault();
    $(event.target).html('<img style="width:20px;height:20px;" src="/assets/images/spinner.svg"/>');
    $('.button-group-error-message').html();
    $('.button-group-error-message').removeClass('d-flex');
    let endpoint = 'generateCDDReport';
    const organizationKey = $(event.target).attr('type');
    if (this.idVerificationService.permissionAdmin()) {
      endpoint = 'generateCDDReportAdmin';
    }

    this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(endpoint, organizationKey), { responseType: 'text/csv' },
      { observe: 'body' }, 'funds').subscribe(response => {
        if (response === undefined || response === null) {
          $('.button-group-error-message').html('*the report could not be generated');
          $('.button-group-error-message').addClass('d-flex');
          $(event.target).html('Generate report');
        } else {
          const link = document.createElement('a');
          let data = 'data:text/csv;charset=utf-8,';
          data += response.toString();
          const name = 'Mesh ID - CDD Usage Report.csv';
          link.href = encodeURI(data);
          link.download = name;
          link.style.display = 'none';
          const evt = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
          document.body.appendChild(link);
          link.dispatchEvent(evt);
          document.body.removeChild(link);
          $(event.target).html('Generate report');
        }
      }, error => {
        $('.button-group-error-message').html('*the report could not be generated');
        $('.button-group-error-message').addClass('d-flex');
        $(event.target).html('Generate report');
      });
  }

  OnLogOut(event) {
    event.preventDefault();
    this.authService.logout();
  }

  /**
   * mark this notification as seen
   * @param event: event
   */
  OnMarkNotificationAsClosed(event) {
    let eventTarget = $(event.target);
    let eventImg = $(event.target).find('img');
    if (eventTarget.is('img')) {
      eventTarget = $(event.target).closest('a');
      eventImg = $(event.target);
    }
    const key = eventTarget.attr('key');
    if (key !== undefined && key !== null) {
      event.preventDefault();
      this.integrityService.markNotificationAsClosed(key);
    }
  }


  /**
   * set min Service end date
   */
  setMinEndDate(params) {
    if (this.lightboxContent[0].result_details[0].elements[4]) {
      this.lightboxContent[0].result_details[0].elements[4].params.minDate = JSON.stringify(params.model);

    }
    this.cd.detectChanges();
  }




  // temp
  noIdVerificationYet(event) {
    return;
    event.preventDefault();
    // alert('You are not authorized to access ID Verification module');
    this.formType = 'Access denied!';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    // build the content for the Request Information light box
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details': [
        {
          'group_result_title': 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements': [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/icon-triangle-orange.svg',
                'width': '116px',
                'height': '116px',
                'class': 'color-orange'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'You currently do not have a subscription to this product'
              }
            },
            {
              'type': 'button',
              'params': {
                'content': 'Ok',
                'display': true,
                'action': 'onLightboxClose'
              }
            },
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  OnHoverPrimary(event) {
    clearTimeout(this.underlineTimeout);
    const eventTarget = $(event.target);
    eventTarget.closest('.navbar').find('hr')
      .css({
        'width': eventTarget.width(),
        'margin-left': eventTarget.offset().left - eventTarget.closest('.navbar').offset().left,
      });
  }
  OnHoverOutPrimary(event) {
    const eventTarget = $(event.target).closest('.navbar').find('.active');
    this.HoverDefaultPrimary(eventTarget);
  }
  HoverDefaultPrimary(eventTarget) {
    this.underlineTimeout = setTimeout(function () {
      if (eventTarget.offset()) {
        eventTarget.closest('.navbar').find('hr')
          .css({
            'width': eventTarget.width(),
            'margin-left': eventTarget.offset().left - eventTarget.closest('.navbar').offset().left,
          });
      }
    }, 100);
  }
  OnHoverSecondary(event) {
    const eventTarget = $(event.target).closest('.underline-wrapper');
    let offset = 20;
    if ($(window).width() < 1024) {
      offset = 10;
    }
    eventTarget.closest('.menu-container').find('> hr')
      .css({
        'width': eventTarget.width(),
        'margin-left': eventTarget.offset().left - eventTarget.closest('.menu-container').offset().left + offset,
        // 'bottom': '-30px'
      });
  }
  OnHoverOutSecondary(event) {
    const eventTarget = $(event.target);
    eventTarget.closest('.menu-container').find('hr')
      .css({
        'width': 0,
      });
  }

  /**
   *
   */
  DisplayLightbox(event) {
    if (!event.group) {
      console.log('Group must be defined!!!');
      return;
    }
    this.formType = (!event.formType) ? '' : event.formType;
    this.formTitle = (!event.formTitle) ? '' : event.formTitle; // 'Financial Holdings B.V';
    this.lightboxClass = (!event.lightboxClass) ? '' : event.lightboxClass; // 'Financial Holdings B.V';

    this.btnOkText = (!event.btnOkText) ? '' : event.btnOkText;
    this.formValidator = (!event.formValidator) ? '' : event.formValidator;
    this.btnCancelText = (!event.btnCancelText) ? '' : event.btnCancelText;
    // build the content for the Request Information light box
    const group = event.group;
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    this.displayLoader = false;
    // this.cd.detectChanges();
  }
  /**
   * handle unexpected error
   */
  DisplayUnexptectedError() {
    // don't change the title
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'result_details': [
        {
          'group_result_title': 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          'elements': [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Your list of questions have NOT been sent due to unexpected error. Please try again later!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
        }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnCancelText = 'Close';
    this.displayModal = true;
  }

  /**
   * displays lightbox project form
   */
  hpmOnDisplayCreateProjectLightbox() {
    this.formType = 'Create a new project';
    this.formTitle = '';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    // build the content for the Create new project lightbox
    const group = {
      'section_class': 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'create_new_project',
      'result_details': [
        {
          'group_result_title': 'create_new_project_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          'elements': [
            {
              'type': 'title',
              'params': {
                'display': true,
                'content': 'Basic information',
              }
            },
            {
              'type': 'inputText',
              'params': {
                'label': 'Project name',
                'id': 'lb-create-project-name',
                'name': 'lbCreateProjectName',
                'content': '',
                'required': 'true'
              }
            },
            {
              'type': 'button',
              'params': {
                'content': 'Create new project',
                'display': true,
                'action': 'hpmOnCreateProject',
                'type': 'submit'
              }
            },
          ]
        }]
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  hpmOnCreateProject() {
    if ($('#lb-create-project-name').val() === '') {
      alert('You must enter a project name!');
      return;
    }
    let defaultProjectTemplate;
    if ($('#lb-create-project-template')) {
      defaultProjectTemplate = $('#lb-create-project-template').find(':selected').val();
    }
    this.idVerificationService.createProject($('#lb-create-project-name').val(), defaultProjectTemplate);
    this.displayModal = false;
    this.cd.detectChanges();
  }

  htpmOnSaveNewRepresentative() {
    if ($('#lb-representative-name').val() === '') {
      alert('You must enter a representative name!');
      return;
    }
    if ($('#lb-representative-email').val() !== '' && !this.helper.validateEmail($('#lb-representative-email').val())) {
      alert('You must use a valid email address! If you don\'t know person\'s email address leave this field blank.');
      return;
    }

    let tagFromInput = '';
    if ($('#addingTagInputText').length) {
      tagFromInput = $('#addingTagInputText').val().trim();

    }
    let tagsArray = [];
    if (this.idVerificationService.getActiveLegalRepresentativesUnadedTags()) {
      tagsArray = this.idVerificationService.getActiveLegalRepresentativesUnadedTags().slice();
    }
    tagsArray = tagsArray.map(tag => tag.trim());
    // add the value from the input
    if (tagFromInput !== '') {
      tagsArray.push(tagFromInput);
    }
    const tagsPayload = { 'tags': tagsArray };
    this.idVerificationService.setActiveLegalRepresentativesUnadedTags(undefined);
    this.displayLoader = true;
    this.idVerificationService.createLegalRepresentatives(
      this.idVerificationService.getActiveProject(),
      {
        'firstName': $('#lb-representative-fname').val(),
        'lastName': $('#lb-representative-lname').val(),
        'email': (this.helper.validateEmail($('#lb-representative-email').val())) ? $('#lb-representative-email').val() : undefined,
        'internalReference': $('#lb-internal-reference').val(),
        'dateOfBirth': ($('#lbRepresentativeDob > input').val() !== '')
          ? this.helper.prepareDateForApi($('#lbRepresentativeDob > input').val()) : undefined,
        'relationship': ($('#lb-representative-relationship').find(':selected').val() !== -1)
          ? $('#lb-representative-relationship').find(':selected').val() : undefined,
      },
      tagsPayload
    );
    this.cd.detectChanges();
  }



  /**
   * FUND FUNCTIONS
   */
  /**
 * Done with documents going to preview
 * @param event event
 */
  htpmOnShareEvidenceFromFlow() {
    this.idVerificationService.skipToStep(10, this.idVerificationService.getActiveInvestor);
  }
  htpmOnInformationPreview() {
    this.idVerificationService.skipToStep(3);
  }
  htpmOnShareStep() {
    this.idVerificationService.skipToStep(4);
  }
  htpmOnFinalizeUpload(event) {
    this.idVerificationService.loadAdditionalInvestorDetail(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveInvestor(),
      true
    );
    this.idVerificationService.changeInformationRequestStep(0, { 'confirm': true });
  }

  htpmOnNewInvestorStepTwo() {
    const response: any = {};
    if ($('#ai-investor-type').val() === '') {
      alert('You must enter a request name');
      return;
    }
    response.type = $('#ai-investor-type').val();
    // check if it's an AR
    if ($('#automaticRequestTemplate').val() !== '') {
      response.selectedTemplate = $('#automaticRequestTemplate').val();
      response.EmailAddress = $('#automaticRequestEmail').val();
      response.allowInvestorEdit = $('#allowInvestorProfileDetails').prop('checked');
    }

    this.idVerificationService.changeCreationRequestStep(5, response);
  }

  changeToManualCreation() {

    let additionalData: any = {};

    if ($('#automaticRequestTemplate').val() !== '') {
      additionalData.selectedTemplate = $('#automaticRequestTemplate').val();
      additionalData.EmailAddress = $('#automaticRequestEmail').val();
      additionalData.allowInvestorEdit = $('#allowInvestorProfileDetails').prop('checked');
    }

    if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
      additionalData.relatedPartyRole = this.idVerificationService.localFundCreationData.relatedPartyRole;
      additionalData.relatedPartyType = 'LegalPerson';

      this.idVerificationService.changeCreationRequestStep(12.2,
        additionalData);
    } else {
      additionalData.type = 'LegalPerson';
      this.idVerificationService.changeCreationRequestStep(5.1, additionalData);
    }
  }

  selectTypeKompany() {
    const selectedValue = $('#Type option:selected').val();
    if (selectedValue !== 'emptySelect') {
      if (!this.idVerificationService.localFundCreationData.investorFields) {
        this.idVerificationService.localFundCreationData.investorFields = {};
        this.idVerificationService.localFundCreationData.investorFields.Type = selectedValue;
      } else {
        this.idVerificationService.localFundCreationData.investorFields.Type = selectedValue;
      }
    }
  }

  changeToManualCreationReset() {
    if (this.idVerificationService.localFundCreationData.fieldsSource) {
      this.idVerificationService.localFundCreationData.fieldsSource = undefined;
    }
    if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
      this.idVerificationService.localFundCreationData.linkToStep = 'none';
      this.idVerificationService.changeCreationRequestStep(12.2,
        { relatedPartyRole: this.idVerificationService.localFundCreationData.relatedPartyRole, relatedPartyType: 'LegalPerson' });
    } else {
      this.idVerificationService.localFundCreationData.linkToStep = 'none';
      this.idVerificationService.changeCreationRequestStep(5.1, 'LegalPerson');
    }
  }

  changeToKompanyCreation() {
    // if (this.idVerificationService.localFundCreationData && this.idVerificationService.localFundCreationData.investorFields) {
    //   this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = undefined;
    //   this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry = undefined;
    // }


    let additionalData: any = {};
    if ($('#automaticRequestTemplate').val() !== '') {
      additionalData.selectedTemplate = $('#automaticRequestTemplate').val();
      additionalData.EmailAddress = $('#automaticRequestEmail').val();
      additionalData.allowInvestorEdit = $('#allowInvestorProfileDetails').prop('checked');
    }

    if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
      additionalData.relatedPartyRole = this.idVerificationService.localFundCreationData.relatedPartyRole;
      additionalData.relatedPartyType = 'LegalPerson';
      this.idVerificationService.changeCreationRequestStep(12.1,
        additionalData);
    } else {
      additionalData.type = 'LegalPerson';
      this.idVerificationService.changeCreationRequestStep(5.2, additionalData);
    }
  }


  hideGeneralError() {
    $('.button-error-field ').removeClass('d-none');
  }

  /**
   *
   * Final step of creating investor
   */
  htpmOnNewInvestorEnd(event) {
    const investorType = $(event.target).closest($('form')).attr('form_id');
    this.displayLoader = true;
    const inputs = $('.form-lightbox .element-wrapper input,.form-lightbox .element-wrapper select');
    const investorFields = {};
    inputs.each(function () {
      let _ = $(this);
      if (_.attr('id') === undefined) {
        _ = $(this).parent();
      }
      const id = _.attr('id');
      const skip = _.parent().hasClass('skip');
      const value = $(this).val();
      if (value !== undefined && value !== null && value !== '' && value !== 'emptySelect' && !skip
        && id
        && id !== 'useClassicAddress'
        && id !== 'googleAutoComplete'
        && id !== 'isMinor'
      ) {
        investorFields[id] = value;
      }
    });
    if (investorFields['DateOfBirth']) {
      investorFields['DateOfBirth'] = this.helper.prepareDateForApi(investorFields['DateOfBirth']);
    }
    if (investorFields['DateOfRegistration']) {
      investorFields['DateOfRegistration'] = this.helper.prepareDateForApi(investorFields['DateOfRegistration']);
    }
    if ($('#isMinor').prop('checked')) {
      investorFields['isMinor'] = true;
    }
    const payload: any = {
      'typeOfInvestor': investorType,
      'investorFields': investorFields
    };

    if (this.idVerificationService.localFundCreationData && this.idVerificationService.localFundCreationData.fieldsSource) {
      payload.fieldsSource = [this.idVerificationService.localFundCreationData.fieldsSource];
    }

    if (!!$('#automaticRequestTemplate').val()) {
      if (investorType === 'NaturalPerson') {
        payload.investorFields.EmailAddress = $('#automaticRequestEmail').val();
      }
      payload.isAutomaticRequest = true;
      // if we have this flag it means that the SP has allowed the investor to change the fields
      // hence no fields source will be provided
      if ($('#automaticRequestAllowInvestorEdit').val() === 'true') {
      } else {
        // when fieldSource is provided the investor will not be able to make edits
        payload.fieldsSource = this.generateManualFieldSource(payload.investorFields);
        console.log(payload);
      }
      this.idVerificationService.createInformationRequestAutomatic(
        this.idVerificationService.getActiveProject(),
        payload,
        $('#automaticRequestTemplate').val(),
        $('#automaticRequestEmail').val(),
        this.idVerificationService.getAllowReuseProfile(),
      );
    } else {
      this.idVerificationService.createInformationRequest(
        this.idVerificationService.getActiveProject(),
        payload,
      );
    }
  }

  generateManualFieldSource(fields) {
    let fieldsSource = [];
    fieldsSource.push({
      'name': 'MANUAL',
      'fieldNames': Object.keys(fields),
      'requestTime': new Date().getTime(),
      'lastUpdate': new Date().getTime(),
    })
    return fieldsSource;

  }

  htpmOnNewInvestorEndAutomatic(event) {
    this.displayLoader = true;
    const EmailAddress = $('#AREmail').val();
    const selectedTemplate = $('#ARTemplate option:selected').val();
    this.idVerificationService.skipToStep(8, {
      isAutomaticRequest: true, EmailAddress: EmailAddress,
      selectedTemplate: selectedTemplate
    });
    // this.idVerificationService.createInformationRequestAutomatic(
    //   this.idVerificationService.getActiveProject(),
    //   {
    //     'typeOfInvestor': 'AutomaticRequest',
    //     'investorFields': investorFields
    //   },
    //   selectedTemplate
    // );
  }

  htpmOnNewInvestorEndAutomaticSkipDetails(event) {
    this.displayLoader = true;
    const allowReuse = $('#allowReuse').is(':checked');
    const investorFields = {};
    investorFields['EmailAddress'] = $('#AREmail').val();
    const selectedTemplate = $('#ARTemplate option:selected').val();
    this.idVerificationService.createInformationRequestAutomatic(
      this.idVerificationService.getActiveProject(),
      {
        'typeOfInvestor': 'AutomaticRequest',
        'investorFields': investorFields,
      },
      selectedTemplate,
      $('#AREmail').val(),
      allowReuse
    );
  }

  htpmOnInvestorUpdate(event) {

    const requestKey = $(event.target).closest('form').attr('formkey');
    this.displayLoader = true;
    const inputs = $('.form-lightbox .element-wrapper input,.form-lightbox .element-wrapper select');
    const investorFields = {};
    inputs.each(function () {
      let id = $(this).attr('id');
      if (id === undefined) {
        id = $(this).parent().attr('id');
      }
      const value = $(this).val();
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        value !== 'emptySelect'
        && id !== 'useClassicAddress'
        && id !== 'googleAutoComplete'
      ) {
        investorFields[id] = value;
      }
    });

    if (investorFields['DateOfBirth']) {
      investorFields['DateOfBirth'] = this.helper.prepareDateForApi(investorFields['DateOfBirth']);
    }
    if (investorFields['DateOfRegistration']) {
      investorFields['DateOfRegistration'] = this.helper.prepareDateForApi(investorFields['DateOfRegistration']);
    }

    let payload;
    payload = {};
    payload.investorFields = investorFields;
    if (this.idVerificationService.getActiveInvestorDetaisl().fieldsSource) {
      payload.fieldsSource = this.idVerificationService.getActiveInvestorDetaisl().fieldsSource;
    } else {
      if (this.idVerificationService.getActiveInvestorDetaisl().record
        && this.idVerificationService.getActiveInvestorDetaisl().record) {
        payload.fieldsSource = this.idVerificationService.getActiveInvestorDetaisl().record.fieldsSource;
      }
    }
    this.idVerificationService.updateInformationRequestBasicFields(
      this.idVerificationService.getActiveProject(),
      requestKey,
      payload
    );
  }


  htpmOnSelectedDocuments(event) {
    this.displayLoader = true;
    const requestKey = $(event.target).closest('form').attr('form_id');
    const evidenceTypes = [];
    const verificationTypes = [];
    const counters = $(event.target).closest('form').find('.custom-counter-count').filter(
      function () {
        return this.value !== '0';
      }
    );
    if (counters.length === 0) {
      $('.button-error-field').text('*at least one new document type must be selected.');
      this.displayLoader = false;
      $(event.target).siblings('.button-error-field').show();
    } else {
      const documentTypes = [];
      let order = 0;
      let emptyCustomEvidence = false;
      counters.each(function () {
        // total count
        const evidenceCount = $(this).val().toString();
        const fieldType = $(this).attr('id');
        const verificationdisabled = $(this).attr('verificationdisabled');
        const existingEvidence = $('.hidden-counter-field[data-oftype="' + fieldType + '"]');
        // difference between total count and already existing
        const remainingEvidence = parseInt(evidenceCount, 10) - existingEvidence.length;
        // separately counting the other type
        let otherCount = existingEvidence.length;
        if (existingEvidence.length > 0) {
          // existingEvidence.each(function() {
          //   order++;
          //   evidenceTypes.push(
          //     {
          //       'order': order,
          //       'type': fieldType,
          //       'key' : existingEvidence.val()
          //     }
          //     );
          //   });
          for (let i = 0; i < remainingEvidence; i++) {
            order++;
            if (fieldType === 'Other') {
              otherCount++;
              if ($('#customEvidenceType' + otherCount).val() === '') {
                emptyCustomEvidence = true;
              }
              evidenceTypes.push(
                {
                  'order': order,
                  'type': fieldType,
                  'customEvidenceType': $('#customEvidenceType' + otherCount).val(),
                }
              );
            } else {
              evidenceTypes.push(
                {
                  'order': order,
                  'type': fieldType,
                }
              );
            }
          }
        } else {
          for (let i = 0; i < remainingEvidence; i++) {
            order++;
            if (fieldType === 'Other') {
              otherCount++;
              if ($('#customEvidenceType' + otherCount).val() === '') {
                emptyCustomEvidence = true;
              }
              evidenceTypes.push(
                {
                  'order': order,
                  'type': fieldType,
                  'customEvidenceType': $('#customEvidenceType' + otherCount).val(),
                }
              );
            } else if (fieldType === 'IdentityVerification') {
              if (!verificationdisabled) {
                verificationTypes.push({ 'type': 'Identity' });
              }
            } else if (fieldType === 'IdentityInstantAI') {
              if (!verificationdisabled) {
                verificationTypes.push({ 'type': 'IdentityInstantAI' });
              }
            } else if (fieldType === 'AddressVerification') {
              if (!verificationdisabled) {
                verificationTypes.push({ 'type': 'Address' });
              }
            } else if (fieldType === 'ContractVerification') {
              if (!verificationdisabled) {
                verificationTypes.push({ 'type': 'Contract' });
              }
            } else if (fieldType === 'APSSelect') {
              if (!verificationdisabled) {
                verificationTypes.push({ 'type': 'APS' });
              }
            } else {
              evidenceTypes.push(
                {
                  'order': order,
                  'type': fieldType,
                }
              );
            }
          }
        }
      });

      if (!emptyCustomEvidence) {
        const investorType = $('#investor_document_upload').attr('data-type');
        let payload;
        payload = {
          'evidenceTypes': evidenceTypes
        };
        if (verificationTypes.length) {
          payload.verificationTypes = verificationTypes;
        }
        this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType = undefined;
        this.idVerificationService.createUploadDocumentOutline(
          this.idVerificationService.getActiveProject(),
          requestKey, payload, documentTypes, investorType);
      } else {
        $('.button-error-field').text('*custom evidence type cannot be empty');
        this.displayLoader = false;
        $(event.target).siblings('.button-error-field').show();
      }
    }
  }

  onSaveTemplateDataConfig(event) {
    const localTemplateData = this.idVerificationService.getLocalTemplateData();
    if (localTemplateData.activeEdit.mainType != "workflowSteps") {
      const mainCounters = $(event.target).closest('form').find('.main-template-types-group .custom-counter-count').filter(
        function () {
          return this.value !== '0';
        }
      );
      const rpCountersNP = $(event.target).closest('form').find('.rp-template-types-group-np .custom-counter-count').filter(
        function () {
          return this.value !== '0';
        }
      );
      const rpCountersLP = $(event.target).closest('form').find('.rp-template-types-group-lp .custom-counter-count').filter(
        function () {
          return this.value !== '0';
        }
      );

      if (mainCounters.length === 0) {
        $('.button-error-field').text('*at least one main profile type must be selected in order to continue');
        $('.button-error-field').show();
        this.displayLoader = false;
        $(event.target).siblings('.button-error-field').show();
      } else {
        // Check to see if RP are disabled
        // commented out since RP are not required anymore
        // if (rpCountersLP.length === 0 ) {
        //   $('.button-error-field').text('*at least one legal person related party type must be selected.');
        //   $('.button-error-field').show();
        //   return;
        // }
        // if (mainCounters.length === 1)  {
        //   $('.button-error-field').text('*at least one legal person main template type must be selected ');
        //   $('.button-error-field').show();
        //   return;
        // }
        if (!localTemplateData.mainRequestTemplates) {
          localTemplateData.mainRequestTemplates = [];
        }
        if (!localTemplateData.relatedPartyTemplates) {
          localTemplateData.relatedPartyTemplates = [];
        }
        if (!localTemplateData.workflowSteps) {
          localTemplateData.workflowSteps = [];
        }
        // Adding all of types that are selected for main profiles to the Template
        mainCounters.each(function () {
          const fieldType = $(this).attr('id');
          const existingTemplate = localTemplateData.mainRequestTemplates.find(template => template.name === fieldType);
          if (!existingTemplate) {
            localTemplateData.mainRequestTemplates.push({ name: fieldType, token: true });
          } else {
            existingTemplate.token = true;
          }
        });
        // Adding all of types that are selected for related NP profiles to the Template
        rpCountersNP.each(function () {
          const fieldType = $(this).attr('id');
          const existingTemplate = localTemplateData.relatedPartyTemplates.find(template => template.name === fieldType);
          if (!existingTemplate) {
            localTemplateData.relatedPartyTemplates.push({ name: fieldType, token: true });
          } else {
            existingTemplate.token = true;
          }
        });
        // Adding all of types that are selected for related LP profiles to the Template
        rpCountersLP.each(function () {
          const fieldType = $(this).attr('id');
          const existingTemplate = localTemplateData.relatedPartyTemplates.find(template => template.name === fieldType);
          if (!existingTemplate) {
            localTemplateData.relatedPartyTemplates.push({ name: fieldType, token: true });
          } else {
            existingTemplate.token = true;
          }
        });

        for (let i = 0; i < localTemplateData.mainRequestTemplates.length; i++) {
          if (localTemplateData.mainRequestTemplates[i].token) {
            delete localTemplateData.mainRequestTemplates[i].token;
          } else {
            localTemplateData.mainRequestTemplates.splice(i, 1);
            i -= 1;
          }
        }
        for (let i = 0; i < localTemplateData.relatedPartyTemplates.length; i++) {
          if (localTemplateData.relatedPartyTemplates[i].token) {
            delete localTemplateData.relatedPartyTemplates[i].token;
          } else {
            localTemplateData.relatedPartyTemplates.splice(i, 1);
            i -= 1;
          }
        }

        if ($('#publishTemplate').prop('checked')) {
          localTemplateData.published = true;
        } else {
          localTemplateData.published = false;
        }
        localTemplateData.filledDataExists = true;
        localTemplateData.templateName = $('#TemplateName').val();
        localTemplateData.richDescription = $('#templateDescription').text();
        // localTemplateData.activeEdit = {'mainType' : 'mainRequest', 'type' :  localTemplateData.mainRequestTemplates[0].name, 'count' : 0};
        localTemplateData.activeEdit = { 'mainType': 'workflowSteps', 'count': 0 };
        this.displayLoader = true;
        this.idVerificationService.skipToStep(22, localTemplateData);
      }
    }
    else {
      if (!localTemplateData.workflowSteps) {
        localTemplateData.workflowSteps = [];
      }

      // $(event.target).closest('form').find('#edit-workflow-step').forEach(element=> console.log(element));
      let workflowSteps = [];
      $(event.target).closest('form').find('.edit-workflow-step').each(
        function () {
          let workflowStep = this.getAttribute('workflowStep');
          if ($(this).find('.workflowStepToggle input').prop('checked')) {
            let step: any = {}
            step.type = workflowStep;
            step.doNotDisplay = false;
            if ($(this).find('#en' + workflowStep).text() || $(this).find('#nl' + workflowStep).text()) {
              step.description = {};
            }
            if ($(this).find('#en' + workflowStep).text()) {
              step.description.en = $(this).find('#en' + workflowStep).text()
            }
            if ($(this).find('#nl' + workflowStep).text()) {
              step.description.nl = $(this).find('#nl' + workflowStep).text()
            }
            workflowSteps.push(step);
          }
          else {
            workflowSteps.push({
              "type": workflowStep,
              "doNotDisplay": true
            }
            )
          }
        }
      );
      localTemplateData.workflowSteps = workflowSteps
      localTemplateData.activeEdit = { 'mainType': 'mainRequest', 'type': localTemplateData.mainRequestTemplates[0].name, 'count': 0 };
      this.displayLoader = true;
      this.idVerificationService.skipToStep(22, localTemplateData);
    }

  }

  onDeleteTemplateDataConfig() {
    this.displayLoader = true;
    const localTemplateData = this.idVerificationService.getLocalTemplateData();
    this.idVerificationService.deleteExistingTemplate(localTemplateData.existingTemplateKey);
  }

  htpmOnDocumentDelete(params) {
    console.log('IN HTML DELETE FUNCTION');
    const buttonClicked = params.event.target;
    const evidenceKey = $(buttonClicked).attr('data-evidenceKey');
    const documentKey = $(buttonClicked).attr('data-documentKey');
    // we need to use the RPE key if we're currently in an RPE evidence, this is how we check
    let requestKey = this.idVerificationService.getActiveInvestor();
    if (this.idVerificationService.isKeyRelatedParty($('.document-category-main.category-active').attr('data-requestkey'))) {
      requestKey = $('.document-category-main.category-active').attr('data-requestkey');
    }
    this.idVerificationService.deleteRequestDocument(
      this.idVerificationService.getActiveProject(),
      requestKey,
      evidenceKey,
      documentKey,
      buttonClicked
    );
  }

  // for investor side
  onChangeTypeCountry(params) {

    const countryList = [...this.idVerificationService.jumioTypeCountries];
    const selectedCountry = $('.type-country-wrapper option:selected').text();
    // if the default option is selected hide the types
    if (selectedCountry === 'All countries') {
      $('.verification-type-wrapper').addClass('d-none');
      $('.verification-type-wrapper-stand-in').removeClass('d-none');
    } else if (countryList) {

      const selectedCountryObject = countryList
        .find(country => country.countryName.trim() === selectedCountry.toString());
      // const selectedCountryObject = countryList[2];
      if (selectedCountryObject && selectedCountryObject.idTypes && selectedCountryObject.idTypes.length >= 1) {
        // empty the type list
        $('#IDVerificationType').empty();
        selectedCountryObject.idTypes.forEach(selectedCountryType => {
          const option = $('<option></option>').attr('value', selectedCountryType.idType)
            .text(this.helper.parseTypeName(selectedCountryType.idType));
          if (params.type === selectedCountryType.idType) {
            option.attr('selected', true);
          }
          $('#IDVerificationType').append(option);
        }
        );
        $('.verification-type-wrapper').removeClass('d-none');
        $('.verification-type-wrapper-stand-in').addClass('d-none');
      } else {
        // if for some reason we can't find the object hide the types
        $('.verification-type-wrapper').addClass('d-none');
        $('.verification-type-wrapper-stand-in').removeClass('d-none');
      }
    }
  }

  onRejectIDVDV() {
    this.idVerificationService.previewWindowPopUp.next('rejectIDVDV');
    $('.expire-lightbox').removeClass('d-none');
  }

  onAcceptIDVDV() {
    this.idVerificationService.previewWindowPopUp.next('acceptIDVDV');
    $('.expire-lightbox').removeClass('d-none');
  }



  htpmOnInvestorShare(event) {
    let requestKey = $('#shareEmailAdress').attr('name');
    if (!requestKey) {
      requestKey = this.idVerificationService.getActiveInvestor();
    }
    const email = $('#shareEmailAdress').val();
    const allowReuse = $('#allowReuse').is(':checked');
    const payload = {
      'email': email,
      'allowReuse': allowReuse,
    };
    this.displayLoader = true;
    this.idVerificationService.genereateShareInvestorUrl(this.idVerificationService.getActiveProject(), requestKey, payload);
  }


  htpmOnRelatedPartyStepTwo(event) {
    const relatedPartyType = $(event.target).closest('form').find('#relatedPartyType').val();
    let relatedPartyRole = $(event.target).closest('form').find('#relatedPartyRole').val();
    if (relatedPartyRole === 'other') {
      relatedPartyRole = $(event.target).closest('form').find('#relatedPartyRoleText').val();
    }
    const values = {
      'relatedPartyType': relatedPartyType,
      'relatedPartyRole': relatedPartyRole,
    };
    this.idVerificationService.skipToStep(12, values);
  }
  /**
   * Used when changing value when creating a related party
   */
  onChangeRelatedPartyRole(event) {
    if ($(event.target).attr('changemethod') === 'onChangeRelatedPartyRole') {
      if ($(event.target).children('option:selected').attr('value') === 'other') {
        $('#relatedPartyRoleText').closest('.element-wrapper ').removeClass('d-none');
        $('#relatedPartyRoleText').val('');
      } else {
        $('#relatedPartyRoleText').closest('.element-wrapper ').addClass('d-none');
        $('#relatedPartyRoleText').val('Other');
      }
    }
  }

  CopyLinkToClipboard() {
    this.helper.copyToClipboard($('#generatedVerificationLink').val());
    $('#generatedVerificationLink').siblings('.input-action-status').text('Successfully copied!').fadeIn();
    $('#generatedVerificationLink').siblings('.input-action-status').text('Successfully copied!').fadeIn();

    setTimeout(function () {
      $('.input-action-status').fadeOut();
    }, 2500);
  }



  htpmOnRelatedPartyCreate(event) {
    const relatedPartyType = $(event.target).closest('form').attr('form_id');
    const relatedPartyRole = $(event.target).closest('form').parent().attr('id');
    const requestKey = $(event.target).attr('data-requestKey');
    const fields = $(event.target).closest('form').find('input,select,textarea');
    this.displayLoader = true;
    let payload = {};
    const investorFields = {};
    for (const field of fields) {
      if ($(field).attr('id') === undefined) {
        if ($(field).val() !== null && $(field).val() !== undefined && $(field).val() !== '' && $(field).val() !== 'emptySelect'
        ) {
          investorFields[$(field).parent().attr('id')] = this.helper.prepareDateForApi($(field).val());
        }
      } else {
        if ($(field).val() !== null
          && $(field).val() !== undefined
          && $(field).val() !== ''
          && $(field).val() !== 'emptySelect'
          && $(field).attr('id') !== 'useClassicAddress'
          && $(field).attr('id') !== 'googleAutoComplete'
          && $(field).attr('id') !== 'isMinor'
        ) {
          investorFields[$(field).attr('id')] = $(field).val();
        }
      }
    }
    if ($('#isMinor').prop('checked')) {
      investorFields['isMinor'] = true;
    }
    payload = {
      'investorFields': investorFields,
      'typeOfInvestor': relatedPartyType,
      'relatedPartyRole': relatedPartyRole,
    };
    this.idVerificationService.createRelatedParty(this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor(), payload);
  }

  htpmOnFinalizeReview() {
    this.idVerificationService.skipToStep(0);
  }

  addDocumentNotes(event) {
    $(event.target).closest('.element-wrapper').addClass('d-none');
    $('#client_notes_wrapper').removeClass('d-none');
  }

  htpmSubmitEvidenceFields(params) {
    let showLoader = true;
    let additionalParam;
    const evidenceKey = $(params.event.target).attr('data-evidenceKey');
    const requestKey = $('.document-category-main.category-active').attr('data-requestKey');
    // digital verification type, will be used only for digital verifications
    const originalKey = $(params.event.target).attr('data-originalKey');
    const dvType = $(params.event.target).attr('data-dvType');
    const verificationType = $(params.event.target).attr('data-verificationType');
    if (dvType) {
      this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType = undefined;
      if (this.idVerificationService.isInvestorType()) {
        showLoader = false;
        $('.dv-review-step-loading').addClass('d-flex');
      }
    }

    const payload = this.idVerificationService.saveFieldsState(evidenceKey, params.additionalParam,
      verificationType, undefined, undefined, undefined, requestKey);

    // Special condition for address verification, it can sometimes have empty fields
    if (verificationType === 'Address'
      && payload === undefined
      && !this.idVerificationService.isInvestorType()
    ) {
      // do nothing
    } else {
      const dvPersonAnswer = $(params.event.target).attr('data-confirmPerson');
      if (dvPersonAnswer === 'true') {
        additionalParam = true;
      } else {
        additionalParam = false;
      }
      $('.document-category-main').addClass('uploading-document');
      if (showLoader) {
        $('.documentUploaderSide').addClass('uploading-document');
      }
      // check if there are notes
      if (!this.idVerificationService.isInvestorType()) {
        if ($('#client_notes').length && $('#client_notes').val().trim() !== '') {
          payload['fields'].Notes = $('#client_notes').val().trim();
        }
      }
      this.idVerificationService.fillEvidenceFields(
        this.idVerificationService.getActiveProject()
        , (requestKey) ? requestKey : this.idVerificationService.getActiveInvestor()
        , evidenceKey
        , params.additionalParam
        , payload
        , originalKey
        , additionalParam
      );
    }
  }

  parseNotificationLink(link) {
    return link.replace(/https?:\/\/[^\/]+/i, '');
  }

  htpmSubmitReplaceFields(params) {
    const evidenceKey = $(params.event.target).attr('data-evidenceKey');
    const requestKey = $(params.event.target).attr('data-requestKey');

    $('.document-category-main').addClass('uploading-document');
    $('.documentUploaderSide').addClass('uploading-document');
    const payload = this.idVerificationService.saveFieldsState(evidenceKey, params.additionalParam);
    this.idVerificationService.getVersionAccess(
      this.idVerificationService.getActiveProject(),
      requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
      evidenceKey,
      payload
    );
  }

  onDeleteRPAccept() {
    console.log('ACCEPT');
    this.displayLoader = true;
    this.idVerificationService.deleteProfile(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestorDetaisl()['activeRelatedParty']['key'],
      true
    );
  }

  onDeletePAccept() {
    this.displayLoader = true;
    this.idVerificationService.deleteProfile(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor()
    );
  }

  onDeleteRPReject() {
    console.log('REJECT');
    $('.close-form-button,#lightBoxCloseImage').trigger('click');

  }

  sendVerificationLink(event) {
    let payload;
    payload = $(event.target).closest('.input-text-button-wrapper').find('input').val();
    let requestKey = $(event.target).attr('requestKey');
    if (!requestKey || requestKey === '') {
      requestKey = this.idVerificationService.getActiveInvestor();
    }
    $(event.target).closest('.element-wrapper').find('.button-error-field').hide();
    if (payload !== '' && payload.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      $(event.target).closest('.element-wrapper').find('.input-action-status').text('Link sent!').fadeIn();
      setTimeout(function () {
        $(event.target).closest('.element-wrapper').find('.input-action-status').text('Link sent!').fadeOut();
      }, 2500);
      // $('#sendAddressVerificationLink').val('');
      const path = '/api/projects/' + this.idVerificationService.getActiveProject() +
        '/information/requests/' + requestKey + (this.idVerificationService.isInvestorType() ? '/verifications/email' : '/email');
      this.userService.postEndPoint(path, { email: payload }, { responseType: 'json' }, { observe: 'body' }, 'funds')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(response => {
          if (typeof response === undefined) {
            return;
          }
        },
          error => {
            console.log('The following error occured when generating id verification link', error);
          });
    } else {
      $(event.target).closest('.element-wrapper').find('.button-error-field').show();
    }
  }

  addressVerificationTypeChange(event) {
    if ($(event.target).attr('id') === 'idAddressVerificationInvestor') {
      $('.addressVerificationManualField').addClass('d-none');
      $('.addressVerificationManualField input,.addressVerificationManualField select').attr('isrequired', false);
      $('.addressVerificationManualField input,.addressVerificationManualField select').removeClass('invalid-input');
    } else {
      $('.addressVerificationManualField').removeClass('d-none');
      $('.addressVerificationManualField input,.addressVerificationManualField select').attr('isrequired', true);
    }
  }

  addressVerificationTypeChangeConfig(event) {
    if ($(event.target).attr('id') === 'idAddressVerificationInvestor') {
      $('.addressVerificationManualField').addClass('d-none');
      $('.addressVerificationManualField input,.addressVerificationManualField select').attr('isrequired', false);
      $('.addressVerificationManualField input,.addressVerificationManualField select').removeClass('invalid-input');
      $('.manualInput').addClass('d-none');
    } else {
      $('.addressVerificationManualField').removeClass('d-none');
      $('.addressVerificationManualField input,.addressVerificationManualField select').attr('isrequired', true);
      $('.manualInput').removeClass('d-none');
    }
  }

  addressVerificationDocumentChange(event) {
    $('.counter-group').toggleClass('d-none');
  }

  addressTemplateVerificationDocumentChange(event) {
    $('.address-counter-group').toggleClass('d-none');

  }

  /**
     * generate and download ID PDF on CCD Sharing Investor side
     */
  OnGenerateDVIdentityPDF(event, replaceLightboxLoader = true) {
    event.preventDefault();
    // set the loading animation:
    this.idVerificationService.setActiveVerification($('.result-container.expanded').attr('verificationKey'));
    if (!replaceLightboxLoader) {
      $('.result-container[verificationkey="'
        + this.idVerificationService.getActiveVerification()
        + '"] .pdf-gen-button').html('<img src="/assets/images/ajax-loader-white.gif" class="pdf-gen-icon" style="padding: 0 26px;">');
    } else {
      $(event.target).closest('a').addClass('pdf-gen-loading').find('img').attr('src', '/assets/images/loader-small.gif');
      $('.pdf-gen-loading span').text('Generating PDF Report ...');
    }
    if (this.idVerificationService.getPDFStatus().status !== 'IN_PROGRESS') {
      this.idVerificationService.generateDVIdVerificationPDF();
    }
  }

  OnGenerateDVIdentityInstantAIPDF(event, replaceLightboxLoader = true) {
    event.preventDefault();
    // set the loading animation:
    this.idVerificationService.setActiveVerification($('.result-container.expanded').attr('verificationKey'));
    if (!replaceLightboxLoader) {
      $('.result-container[verificationkey="'
        + this.idVerificationService.getActiveVerification()
        + '"] .pdf-gen-button').html('<img src="/assets/images/ajax-loader-white.gif" class="pdf-gen-icon" style="padding: 0 26px;">');
    } else {
      $(event.target).closest('a').addClass('pdf-gen-loading').find('img').attr('src', '/assets/images/loader-small.gif');
      $('.pdf-gen-loading span').text('Generating PDF Report ...');
    }
    if (this.idVerificationService.getPDFStatus().status !== 'IN_PROGRESS') {
      this.idVerificationService.generateDVIdVerificationPDF(false, true);
    }
  }

  /**
   * ogenerate and download AV PDF on CCD Sharing Investor side
   * @param event js event
   * @param replaceLightboxLoader  if the lightbox should be replaced
   */
  OnGenerateDVAddressVerificationPDF(event, replaceLightboxLoader = true) {
    // check if files are finished parsing and if we can proceed with download
    // if there are files that need to be downloaded but aren't finished parsing
    // prevent the pdf generation
    if (!this.idVerificationService.getActiveDigitalVerificationDetails().filesFinishedParsing) {
      return;
    }

    event.preventDefault();
    this.idVerificationService.setActiveAddressVerification($('.result-container.expanded').attr('addressVerificationKey'));
    if (!replaceLightboxLoader) {
      $('.result-container[addressVerificationKey="'
        + this.idVerificationService.getActiveAddressVerification()
        + '"] .pdf-gen-button.address-verification').html('<img src="/assets/images/ajax-loader-white.gif" class="pdf-gen-icon" style="padding: 0 26px;">');
    } else {
      $(event.target).closest('a').addClass('pdf-gen-loading').find('img').attr('src', '/assets/images/loader-small.gif');
      $('.pdf-gen-loading span').text('Generating PDF Report ...');
    }
    if (this.idVerificationService.getPDFStatus().status !== 'IN_PROGRESS') {
      this.idVerificationService.generateDVAddressVerificationPDF();
    }
  }

  OnGenerateEnhancedAvRequest(event, replaceLightboxLoader) {
    // TODO
  }

  OnGenerateDVContractPDF(event, replaceLightboxLoader) {
    if (!this.idVerificationService.getActiveDigitalVerificationDetails().filesFinishedParsing) {
      return;
    }

    event.preventDefault();
    $(event.target).closest('a').addClass('download-loading');
    $('.download-loading span').text('Generating PDF Report ...');

    setTimeout(() => {
      this.idVerificationService.generateDVContractPdf(this.idVerificationService.getActiveDigitalVerificationDetails());
      $('.download-loading span').text('Download PDF Report');
      $(event.target).closest('a').removeClass('download-loading');
    }, 1000);
  }

  OnGenerateDVApsPDF(event, replaceLightboxLoader) {
    if (!this.idVerificationService.getActiveDigitalVerificationDetails().filesFinishedParsing) {
      return;
    }

    event.preventDefault();
    $(event.target).closest('a').addClass('download-loading');
    $('.download-loading span').text('Generating PDF Report ...');

    let evidenceKey = this.idVerificationService.getActiveDigitalVerificationDetails().response.key;
    const vfeCode = (evidenceKey) ? evidenceKey.replace('EVD:', '') : '';
    this.idVerificationService.parseDVApsDataPdf(this.idVerificationService.getActiveDigitalVerificationDetails().response)
      // temporary disable pdf generation so we could test the parsing
      .then(parsedAPS => {
        const test = this.pdfService.generatePDF(
          'apsUpdated', this.helper.formatISODate(new Date().toISOString(), 'yyyy-MM-dd-', false)
        + vfeCode, JSON.stringify(parsedAPS))
          .then(() => {
            $('.download-loading span').text('Download PDF Report');
            $(event.target).closest('a').removeClass('download-loading');
          })
          .catch(error => { console.error(error); });
      })
      .catch(error => { console.error(error); });
  }

  async onAcceptDv(event) {
    this.idVerificationService.previewWindowPopUp.next('acceptAPS');
    $('.expire-lightbox').removeClass('d-none');
  }

  async onAcceptDvConfirm(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    await this.idVerificationService.acceptOrRejectDV(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].record.originRequest
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].key
    );
  }

  async onTurnOffMonitoring(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    await this.idVerificationService.turnOffMonitoring(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].record.originRequest
    );
  }

  async onRejectDv(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    this.idVerificationService.previewWindowPopUp.next('rejectAPS');
    $('.expire-lightbox').removeClass('d-none');
  }

  async onRejectDvConfirm(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    await this.idVerificationService.acceptOrRejectDV(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].record.originRequest
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].key
      , false
    );
  }

  async onRejectIDVDvConfirm(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    await this.idVerificationService.acceptOrRejectIDVDV(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].record.originRequest
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].key
      , false
    );
  }

  async onAcceptIDVDvConfirm(event) {
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    await this.idVerificationService.acceptOrRejectIDVDV(
      this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].record.originRequest
      , this.idVerificationService.getActiveDigitalVerificationDetails()['response'].key
      , true
    );
  }

  async turnOffMonitoring(event) {
    this.idVerificationService.previewWindowPopUp.next('turnOffMonitoringAPS');
    $('.expire-lightbox').removeClass('d-none');
  }

  htpmOnCreateTemplate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.createNewTemplate();
  }
  htpmOnEditTemplate(event) {
    this.displayLoader = true;
    event.preventDefault();
    event.stopPropagation();
    // get selected template id
    const selectedTemplate = $('#ARTemplate option:selected').val();
    // retreive selected template data
    this.userService.getEndPoint('/api/organizations/templates/' + selectedTemplate,
      { responseType: 'json' }, { observe: 'body' }, 'funds').subscribe(response => {
        if (response === undefined) {
          console.log('Error while fetching record for additional data!');
          return;
        }
        response['record']['existingTemplateKey'] = selectedTemplate;
        this.displayLoader = false;
        this.idVerificationService.createNewTemplate(response['record']);
      },
        error => {
          console.log('Following error occured while fetching record for additional data!', error);
          return;
        });
  }

  useClassicAddressToggle() {
    if ($('#useClassicAddress').is(':checked')) {
      $('.manualInput').removeClass('d-none');
    } else {
      $('.manualInput').addClass('d-none');
    }
  }

  updateAllowReuse()
  {
    this.idVerificationService.changeAllowReuseProfile();
  }
}
