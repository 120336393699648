<header class="d-flex flex-column p-0 bg-none d-flex flex-column ic_height_full_page p-0"  >
    <div class="d-flex flex-column flex-fill justify-content-center error-msg-wrapper">
      <div class="header-top mx-auto">
        <div class="navbar p-0 justify-content-center">

        </div>
      </div>
      <div style="" class="text-center mx-auto pb-5 mb-5 error-msg" [ngClass]="">
        {{message}}
      </div>
    </div>
    <div style="top:15px;" class="load-overlay d-flex position-fixed w-100 h-100 justify-content-center text-center" *ngIf="displayLoader">
      <div class="d-flex pt-3">
        <img src="/assets/images/Spinner-1s-200px-tealish.svg" width="100px" height="100px" class="my-auto mx-auto">
      </div>
    </div>
</header>
