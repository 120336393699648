import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation  } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../../elements.service';

@Component({
  selector: 'app-related-party-pillar',
  templateUrl: './related-party-pillar.component.html',
  styleUrls: ['./related-party-pillar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RelatedPartyPillarComponent implements OnInit {

  @Input() element;
  @Input() relatedParty;
  @Input() isFirst;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  isInvestorType;
  constructor(
      public elementService: ElementsService, 
      private idVerificationService: IdVerificationService,
      private helperService: HelperServiceService,
  ) { }

  ngOnInit(): void {
    this.isInvestorType = this.idVerificationService.isInvestorType();
    let rpGeneralStatus;
    rpGeneralStatus = {};
    if (this.relatedParty && this.relatedParty.record.verificationSubjectStatus) {
      rpGeneralStatus =
      this.idVerificationService.buildVerificationStatusObject(this.relatedParty['record'].verificationSubjectStatus);
      this.relatedParty.rpGeneralStatus = this.helperService.translateRequestStatus(rpGeneralStatus.content, this.isInvestorType);
      this.relatedParty.rpGeneralStatusClasses = rpGeneralStatus.class;
    }
   }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onDisplayLoader(emitPayload: boolean) {
      if (emitPayload) {
        this.displayLoader.emit(emitPayload);
      }
    }

    /* COMPONENT SPECIFIC FUNCTIONS */
    loadRelatedParty(event, relatedPartyKey) {
      // always make sure you add the loading to the right element no matter what is clicked
      if ($(event.target).children().length === 0) {
        $(event.target).attr('src', '/assets/images/spinner-gray.svg');
      } else {
        $(event.target).children('img').attr('src', '/assets/images/spinner-gray.svg');
      }
      $(event.target).css('pointer-events' , 'none');
      $(event.target).children('img').css('pointer-events' , 'none');

      this.idVerificationService.retreiveRP(this.idVerificationService.getActiveProject(), relatedPartyKey);
    }

    loadRelatedPartyClaim(event, requestKey, relatedPartyKey) {
      if ($(event.target).children().length === 0) {
        $(event.target).attr('src', '/assets/images/spinner-gray.svg');
      } else {
        $(event.target).children('img').attr('src', '/assets/images/spinner-gray.svg');
      }
      $(event.target).css('pointer-events' , 'none');
      $(event.target).children('img').css('pointer-events' , 'none');

      this.idVerificationService.retreiveRelatedPartyClaim(requestKey, relatedPartyKey);
    }

  // toggle actions button
    toggleActionsMenu(event) {
      event.preventDefault();
      $(event.target).closest('.actionsMenuWrapper').find('ul').toggleClass('d-none');
      $(event.target).closest('.actionsMenuWrapper').find('.action-icon').toggleClass('icon-minus');
    }
        /* COMPONENT SPECIFIC FUNCTIONS */

    /**
     * document upload screen
     */
     documentUploadScreenRelatedParty(inputargs) {
      this.idVerificationService.skipToStep(6, inputargs);
    }


  onDeleteRelatedParty() {
    this.idVerificationService.skipToStep(18);
  }

  onDeleteProfile() {
    this.idVerificationService.skipToStep(23);
  }

}
