import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { QuestionnaireBuilderService } from './questionnaire-builder.service';
import { options } from './custom-builder/options';
import { Formio } from 'formiojs';
// include custom formio components:
import RadioExtendedComponent from './custom-builder/components/extended/radio/radio';
Formio.use(RadioExtendedComponent);
import CheckboxExtendedComponent from './custom-builder/components/extended/checkbox/checkbox';
Formio.use(CheckboxExtendedComponent);
import SwitchExtendedComponent from './custom-builder/components/extended/switch/switch';
Formio.use(SwitchExtendedComponent);
import SelectExtendedComponent from './custom-builder/components/extended/select/select';
Formio.use(SelectExtendedComponent);
import HeaderExtendedComponent from './custom-builder/components/extended/header/header';
Formio.use(HeaderExtendedComponent);
import ParagraphExtendedComponent from './custom-builder/components/extended/paragraph/paragraph';
Formio.use(ParagraphExtendedComponent);

@Component({
  selector: 'app-questionnaire-builder',
  templateUrl: './questionnaire-builder.component.html',
  styleUrls: ['./questionnaire-builder.component.scss']
})
export class QuestionnaireBuilderComponent implements OnInit, AfterContentChecked {

  // header vars
  headerInfo = {
    'headerType': 'inline', // 'inline',
    'headerTitle': 'Risk Questionnaires',
    'activeNavLink': 'questionnaires-builder',
    'projectType' : 'QuestionnairesBuilder'
  };
  headerClasses = {
    'backgroundClass': 'tealish-gradient-bg',
    'sectionClass': 'ic_height_auto', // 'ic_height_full_page',
  };
  kvkResults: any;

  // local vars
  private fileInput: ElementRef;
  private jsonElement: ElementRef;
  @ViewChild('fileInput') set fInput(content: ElementRef) {
    if (content) {
      this.fileInput = content;
    }
  }
  @ViewChild('json') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
        this.jsonElement = content;
        if ((this.previewJson || this.previewList) && this.jsonElement) {
          this.jsonElement.nativeElement.innerHTML = '';
          this.jsonElement.nativeElement.appendChild(document.createTextNode(this.stringifiedJson));
        }
    }
  }
  public form: Object = {
    components: []
  };
  public options: any;
  public rebuildEmitter: Subject<void> = new Subject<void>();

  preview = false;
  previewList = true;
  previewJson = false;
  formattedJson = {};
  stringifiedJson = null;
  downloadJson = null;
  selectedFile: File = null;
  displayLoader = false;
  closePeview = false;
  myOrganisationTemplates;
  availableServices;
  formType = '';
  copyFrom = null;
  displayModal = false;
  lightboxContent: any[];
  btnOkText = 'Ok';
  btnCancelText = 'Cancel';


  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private idVerification: IdVerificationService,
    public qBuilderService: QuestionnaireBuilderService,
    private router: Router,
  ) {
    this.options = options;
  }

  ngOnInit(): void {
    this.form = this.qBuilderService.formattedJson;
    // this.rebuildEmitter.next(this.options);
    $('body').addClass('app-questionnaire-builder-class');
  }

  ngAfterContentChecked() {

  }

  setFormJson(formJson, setForm = false, stringified = false) {
    if (!formJson) {
      return;
    }
    if (stringified) {
      this.formattedJson =
      this.qBuilderService.formattedJson =
      JSON.parse(formJson);
    }

    // populate stringifiedJson
    this.stringifiedJson =
    this.qBuilderService.stringifiedJson =
    JSON.stringify(this.formattedJson, null, 4);

    // populate downloadJson
    this.downloadJson =
    this.qBuilderService.downloadJson =
    this.sanitizer.bypassSecurityTrustUrl('data:text/json;charset=UTF-8,' + encodeURIComponent(this.stringifiedJson));

    if (setForm) {
      this.form = this.qBuilderService.form = this.qBuilderService.formattedJson;
      if (this.jsonElement) {
        this.jsonElement.nativeElement.innerHTML = '';
        this.jsonElement.nativeElement.appendChild(document.createTextNode(this.qBuilderService.stringifiedJson));
      }
    }
  }

  onChange(event) {
    // allow JSON object to be updated only if the event type is set to updateComponent
    // this will prevent making updates to the JSON when for instance clicking a radio button in a formbuilder
    // triggers an event of type 'change'
    const allowedEvents = ['addComponent', 'updateComponent', 'deleteComponent', 'saveComponent'];
    if (!allowedEvents.includes(event.type)) {
      return;
    }

    if (this.jsonElement) {
      this.jsonElement.nativeElement.innerHTML = '';
      this.jsonElement.nativeElement.appendChild(document.createTextNode(JSON.stringify(event.form, null, 4)));
    }
    this.formattedJson =
    this.qBuilderService.formattedJson =
    event.form;
    this.setFormJson(event.form);
  }

  togglePreviewJson() {
    this.previewJson = !this.previewJson;
    if (this.previewJson) {
      this.previewList = !this.previewJson;
      if (this.jsonElement) {
        this.preview = !this.previewJson;
      }
    }
  }

  togglePreviewList() {
    this.previewList = !this.previewList;
    if (this.previewList) {
      this.preview = !this.previewList;
      this.previewJson = !this.previewList;
    }
  }


  formBuilderPreview($event) {
    console.log($event.method);
    if ($event.method === 'form') {
      this.preview = true;
    } else {
      this.togglePreviewJson();
    }
  }

  async loadMyForm(template) {
    // TODO:
    // GET THE LIST OF QUESTIONNAIRES
    // if (this.userService.getUserOrganizationId()) {
    //   await this.userService
    //       .getEndPoint('api/pdf/templates/'
    //         + template
    //         + '/schema/'
    //         + this.userService.getUserOrganizationId()
    //       , {responseType: 'json'}, {observe: 'body'}, 'verification')
    //       .toPromise()
    //       .then(schema => {
    //         this.formattedJson =
    //         this.qBuilderService.formattedJson =
    //         JSON.parse(schema[0].data);
    //         this.setFormJson(this.formattedJson, true);
    //         this.togglePreviewList();
    //       })
    //       .catch(error => console.error(error));
    // }
  }

  async copyMyForm(newName) {

    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {'newName': newName};
      await this.userService
          .postEndPoint('api/pdf/templates/'
            + this.copyFrom
            + '/copy/'
            + this.userService.getUserOrganizationId()
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(schema => {
            this.formattedJson =
            this.qBuilderService.formattedJson =
            JSON.parse(schema[0].data);
            this.setFormJson(this.formattedJson, true);
            this.togglePreviewList();
          })
          .catch(error => console.error(error));
    }
  }

  // UI methods

  /**
   * Displays the submenu for this form
   * @param event click event
   */
  DisplayMyOptions(event) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: set active form
    // this.idVerificationService.setActiveProjectSettings($(event.target).closest('.card').attr('id'));
    // $('.project-submenu').hide();
    // get the height -> take it offscreen
    const seticon = $(event.target).closest('.card').find('.settings-icon').offset().top - $(window).scrollTop();
    const submenu = $(event.target).closest('.card').find('.form-submenu');
    submenu.css('top', '-10000px').show();
    const submenu_height = submenu.height();
    submenu.hide();
    submenu.css('top', ((seticon - submenu_height) + 20) + 'px');
    submenu.fadeIn();
  }

  OnFormEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    // closestCard.find('.form-submenu').fadeOut(); // close the menu
    this.loadMyForm(closestCard.find('.body').attr('data-template-key'));
    // TODO: set active form
    // this.idVerificationService.setActiveProjectSettings(closestCard.attr('id'));
    // this.displayCustomizeProjectLightbox();
    // this.loadingLocked = false;
    // this.allProjectsLoaded = false;
  }

  OnCopyFrom(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    // closestCard.find('.form-submenu').fadeOut(); // close the menu
    this.copyFrom = closestCard.find('.body').attr('data-template-key');
    // this.copyMyForm(closestCard.find('.body').attr('data-template-key'));
    this.DisplayEnterNameCopyForm(closestCard.find('.body').attr('data-template-name'));
  }

  DisplayEnterNameCopyForm(suggestedName) {
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top text-center px-4 mt-5 location-text',
            'params': {
              'content': 'Enter the name of the new form'
            }
          },
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `Please enter the name for the new form that will be based on ${suggestedName}`
            }
          },
          {
            'type' : 'inputText',
            'params' : {
                'label': 'New form name ',
                'id': 'newFormName',
                'name': 'newFormName',
                'content': `Copy of ${suggestedName}`,
                'required': 'true',
                'labelClass': 'font-weight-bold',
                'maxLength' : 60,
              },
          },
          // {
          //   'type': 'button_group',
          //   'params': [
          //     {
          //       'content': 'Ok',
          //       // 'id': 'accept',
          //       'action': 'onDeletePersonAccept',
          //       'class': 'form-button form-button-1',
          //       'display': true
          //     },
          //     {
          //       'content': 'Cancel',
          //       // 'id': 'rescan',
          //       'action': 'onDeletePersonReject',
          //       'class': 'form-button-2',
          //       'display': true // TODO: check if user may edit the information
          //     }
          //   ]
          // }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onApplyRiskRanges(){
    
  }

  onLightboxConfirm($event) {
    this.displayLoader = true;
    this.copyMyForm($('#newFormName').val());
  }

  onLightboxClose($event) {
    this.copyFrom = null;
    this.displayModal = false;
  }

}
