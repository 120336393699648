<div class="element-wrapper">
  <div class="d-flex flex-row align-items-start notification unselectable">
    <img  src="assets/images/icon-triangle-orange.svg" width="16px">
    <div class="d-flex flex-column pl-3 content">
      <h3 class="ml-0 mr-auto">There are items which have not yet been requested</h3>
      <div class="description">In order to request outstanding items, please click below to review the items and share the request.</div>
      <button
        class="button"
        clickmethod='OnReviewNotSharedDocuments'
        (click)="(element.evidenceTypes)
                      ? elementService.documentUpdateFunction($event, element.evidenceTypes, element.evidence, element.requestKey, element.evidence.key)
                      : elementService.documentShareFunction(element.key)"
      >
        Share request
    </button>
    </div>
  </div>
</div>
