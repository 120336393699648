import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { TooltipPosition } from 'src/app/ui/components/tooltip/tooltip-directive/tooltip.enums';

@Component({
  selector: 'app-preview-window-aps',
  templateUrl: './preview-window-aps.component.html',
  styleUrls: ['./preview-window-aps.component.scss']
})


export class PreviewWindowApsComponent implements OnInit, OnDestroy {

  @Input() apsFields;
  @Input() isFunds;
  @Input() historyEntries;
  @Input() olderComplianceCheck : boolean;
  private activeFirstHit :  boolean = true;
  private TooltipPositionDynamic : TooltipPosition  = TooltipPosition.DYNAMIC;
  private resetHits = true;
  private resetHitsSub: Subscription;

  constructor(
    private helperService: HelperServiceService,
    public idVerificationService: IdVerificationService,
  ) { }

  ngOnInit(): void {
    this.resetHitsSub = this.idVerificationService.detectResetHits.subscribe(status => {
      this.resetHits = status;
    });
  }
   
  ngOnDestroy() {
    this.resetHitsSub.unsubscribe();
  }

  // idv preview window methods
  // APS functions
  // Change the main APS screen
  apsChangeResults(event) {
    const hitId = $(event.target).attr('id');
    $('.hit-results-container').addClass('d-none');
    $('#' + hitId + 'result').removeClass('d-none');
    // change active class
    $('.document-category-main').removeClass('category-active');
    $(event.target).closest('.document-category-main').addClass('category-active');

    // take the active tab and show that data
    const tabContentInner = '#' + hitId + 'result .aps-result-tab.active';
    const tabContentId = '#' + $(tabContentInner).attr('id') + 'result';
    $(tabContentId).removeClass('d-none');
    this.activeFirstHit = false;
  }

  apsChangeResultTab(event) {
    // Change the tab active class (green line underneath)
    $(event.target).closest('.hit-results-container').find('.aps-result-tab').removeClass('active');
    $(event.target).addClass('active');
    // Change the content
    $('.aps-result-tab-content').addClass('d-none');
    const tabContentId = '#' + $(event.target).attr('id') + 'result';
    $(tabContentId).removeClass('d-none');
  }

  onWhitelistingHit(index) {
    this.idVerificationService.previewWindowPopUp.next({
      reason :'whitelisting',
      payload : {
      "isWhitelisted" :  !this.apsFields.hits[index].isWhitelisted,
      "hits" : [this.apsFields.hits[index].id]
      },
      "index" : index,
    });
    $('.expire-lightbox').removeClass('d-none');
  }
  onCommentHit(index)
  {
    this.idVerificationService.previewWindowPopUp.next({
      reason :'commentHit',
      index : index,
    });
    $('.expire-lightbox').removeClass('d-none');
  }

}
