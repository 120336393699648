<!-- <div>
  <h1>form builder</h1>
</div> -->

<div class="id-verification-container d-flex flex-column flex-fill justify-content-center ">
  <!-- <h1 class="text-center m-0">{{ headerInfo['headerTitle']}}</h1> -->
  <div
    class="active-projects-wrapper d-flex flex-row"
  >
    <div class="ic-ctas new-person-wrapper mx-auto mt-3 mb-3 pl-0">
      <!-- <a class="position-relative" href="#" (click)="previewForm($event, 'form');" routerLinkActive="router-link-active">Preview Form</a>
      <a class="position-relative" href="#" (click)="previewForm($event, 'json'); " routerLinkActive="router-link-active" class="ml-3">Preview JSON</a> -->
      <!-- <span *ngIf="!formBuilderService.currentTemplate">Currently editing new form</span>
      <span *ngIf="formBuilderService.currentTemplate">Currently editing <strong>{{formBuilderService.getNameBySlug(formBuilderService.currentTemplate)}}</strong> template in <strong>{{formBuilderService.currentMode}}</strong> mode</span> -->
    </div>
  </div>
</div>
