<div class="id-statuses-container flex-wrap d-flex justify-content-between">
    <div [ngClass]="status.statusClass" *ngFor="let status of statuses" class="id-status">
     <!-- STATUS ICON -->
      <div class="d-flex position-relative align-items-center"
        [attr.id]="status.id"
        [ngClass]="(status.class) ? status.class : ''"
        *ngIf="(status.display === undefined) || (status.display !== undefined && status.display === true)">
        <svg-icon
        [src]="status.svgIcon.src"
        [svgStyle]="{'width.px': status.svgIcon.width , 'height.px': status.svgIcon.height}"
        [ngClass]="(status.svgIcon.class) ? status.svgIcon.class : ''"
        *ngIf="status.svgIcon"></svg-icon>
        <p  *ngIf="status.svgIcon && status.svgIcon.content" class = "result-paragraph mt-0 ml-2 mr-3" >
          {{ status.svgIcon.content }}
        </p>
        <svg-icon
        *ngIf="status.svgIcon2"
        [src]="status.svgIcon2.src"
        [svgStyle]="{'width.px': status.svgIcon2.width , 'height.px': status.svgIcon2.height}"
        [ngClass]="(status.svgIcon2.class) ? status.svgIcon2.class : ''"
        ></svg-icon>
        <p  *ngIf="status.svgIcon2 && status.svgIcon2.content" class = "result-paragraph mt-0 ml-2 mr-3" >
          {{ status.svgIcon2.content }}
        </p>
      <p class = "result-paragraph status-title pl-1 font-weight-bold mt-0 ml-2">
        {{ status.title }}
      </p>
    </div>
    <p class = "result-paragraph status-content mt-1">
      {{ status.content }}
    </p>
    <button
      *ngIf="status.showButton && !status.showButtonWithIcon"
      class="status-button"
      [attr.data-dvType] = "status.evidence ? status.evidence.originalEvidenceKey : undefined"
      [attr.clickmethod]="status.buttonAction"
      (click)="
        vtype === 'funds' && status.canUpdate ? elementService.documentUpdateFunction($event,evidenceTypes,status.evidence, requestKey,status.key):
        vtype === 'funds' ? onDisplayLoader(elementService.documentPreviewFunction(evidenceTypes,status.evidence,requestKey, status.originalEvidenceKey)) :
        vtype === 'investor' ?  onDisplayLoader(elementService.onReviewEvidenceClick(status.originalEvidenceKey,requestKey, status.evidence['documents'])) :
        vtype == 'claimScreen' ?  onDisplayLoader(elementService.documentPreviewFunctionOnClaim(evidenceTypes,status.evidence,requestKey, status.key)) :
        onTriggerIDVPreview($event)
      "
    >
    {{vtype === 'funds' && status.canUpdate ? ('common.update' | translate) : ('common.seeDetails' | translate)}}
    </button>
    <!-- <a *ngIf="status.showButton && status.showButtonWithIcon" class="status-button" [attr.clickmethod]="status.buttonAction" (click)="OnButtonClick($event)">
      <img class="preview-download-icon" src="/assets/images/download.svg"><span>Download PDF Report</span>
    </a> -->
    <div class="result-paragraph requested mt-1" *ngIf="status.requestedBy && vtype === 'investor'">{{'appSpecific.RequestedBy' | translate}}</div>
    <div style="color: #333333; margin-top:-1px" class="result-paragraph mt-0 requested" *ngIf="status.requestedBy  && vtype ==='investor'">{{status.requestedBy}}</div>
    </div>
  </div>
