export const options = {
  builder: {
    basic: false,
    data: false,
    premium: false,
    advanced: false,
    customBasic: {
      title: 'Add question',
      default: true,
      weight: 0,
      components: {
        // textfield: true,
        // textarea: true,
        // email: true
        // radio: true
        HeaderExtendedComponent: true,
        ParagraphExtendedComponent: true,
      }
    },
    // advanced: {
    //   components: {
    //     email: false,
    //     url: false,
    //     tags: false,
    //     address: false,
    //     survey: false,
    //     currency: false,
    //     signature: false,
    //     day: false,
    //     phoneNumber: false,
    //     datetime: false
    //   }
    // },
    layout: {
      title: 'Layout',
      weight: 0,
      components: {
        tabs: true,
        panel: false,
        table: false,
        well: false,
        columns: false,
        fieldset: false,
        content: false,
        htmlelement: true,
        HeaderExtendedComponent: false,
        ParagraphExtendedComponent: false,
      }
    },
    // custom: {
    //   title: 'Pre-Defined Fields',
    //   weight: 10,
    //   components: {
    //       firstName: {
    //           title: 'First Name',
    //           key: 'firstName',
    //           icon: 'terminal',
    //           schema: {
    //               label: 'First Name',
    //               type: 'textfield',
    //               key: 'firstName',
    //               input: true
    //           }
    //       },
    //       lastName: {
    //           title: 'Last Name',
    //           key: 'lastName',
    //           icon: 'terminal',
    //           schema: {
    //             label: 'Last Name',
    //             type: 'textfield',
    //             key: 'lastName',
    //             input: true
    //           }
    //       },
    //       email: {
    //           title: 'Email',
    //           key: 'email',
    //           icon: 'at',
    //           schema: {
    //               label: 'Email',
    //               type: 'email',
    //               key: 'email',
    //               input: true
    //           }
    //       },
    //       phoneNumber: {
    //           title: 'Mobile Phone',
    //           key: 'mobilePhone',
    //           icon: 'phone-square',
    //           schema: {
    //               label: 'Mobile Phone',
    //               type: 'phoneNumber',
    //               key: 'mobilePhone',
    //               input: true
    //           }
    //       }
    //   }
  // }
  },
  language: 'en',
};
