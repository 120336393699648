<div class="autocomplete-container">
    <input 
    [attr.isRequired]="isRequired" 
    [attr.isMaxLength]="(isMaxLength? isMaxLength : null)"
    [id]="id" 
    type="text" 
    [(ngModel)]="inputValue" 
    autocomplete="off"
    class="autocomplete-input text-input" 
    (input)="filterOptions(inputValue); uiService.checkAndClearInvalidation($event)"
    (focus)="onFocus(inputValue)"/>
    <ul class="autocomplete-list" *ngIf="filteredOptions.length > 0">
      <li *ngFor="let option of filteredOptions" (click)="selectOption(option);">
        {{ option }}
      </li>
    </ul>
    <div class="error-field"
      [attr.validatorMessage] = "'*this field is required'"
      [attr.validatorMessageField] = "'*this field is required'">
    </div>
  </div>