<div class="form-menu-wrapper d-flex flex-row align-items-center justify-content-between">
  <a href="#" class="d-flex align-items-center" (click)="$event.preventDefault(); formBuilderService.displayPreviewList()">
    <img src="assets/images/back-to-templates.svg" class="mr-2"/> Back to All Contract Templates
  </a>
  <ul class="d-flex m-0">
    <li
      class="mr-3"
      title="create a form according to your needs"
      [ngClass]="{'active': !formBuilderService.previewList && !formBuilderService.preview && !formBuilderService.previewJson && !formBuilderService.editOutput}"
      (click)="formBuilderService.displayEditForm()"
    >Edit Form</li>
    <li
      class="mr-3"
      [ngClass]="{'active': formBuilderService.editOutput}"
      (click)="formBuilderService.setCurrentOutput(0, true)"
    >
      Edit Document(s)
      <ul *ngIf="formBuilderService.currentOutputs && formBuilderService.currentOutputs.length > 1">
        <li *ngFor="let output of formBuilderService.currentOutputs; let i = index" (click)="$event.stopPropagation(); formBuilderService.setCurrentOutput(i, true)">{{formBuilderService.getOutputName(output.fileName)}}</li>
        <li (click)="DisplayEnterNameNewOutput()">+ Add New Output</li>
      </ul>
      <ul *ngIf="!formBuilderService.currentOutputs || formBuilderService.currentOutputs.length == 0 || formBuilderService.currentOutputs.length == 1">
        <li (click)="DisplayEnterNameNewOutput()">+ Add New Output</li>
      </ul>
    </li>
    <li
      class="mr-3"
      title="Preview how the for will look like for your clients or submit it to generate and preview the PDF"
      [ngClass]="{'active': formBuilderService.preview}"
      (click)="formBuilderService.displayPreview()"
    >Preview Contract</li>
    <li
      class="mr-3"
      [ngClass]="{'active': formBuilderService.previewJson}"
      (click)="formBuilderService.displayPreviewJson()"
    >View code</li>
  </ul>
  <!-- href="#" -->
  <div class="save-btn-wrapper d-flex">
    <a
      class="d-flex align-items-center mr-2 hover"
      (click)="clearDefaultValues()"
      title="Clear the template default values"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
    </a>
    <a
      class="d-flex align-items-center mr-2 hover"
      (click)="formBuilderService.exportFiles()"
      title="Export all files: schema and output html"
    >
      <i class="fa fa-download" aria-hidden="true"></i>
    </a>
    <a
      class="d-flex form-button form-button-1 save-as-draft"
      (click)="OnSaveAsDraft($event)"
      *ngIf="!!formBuilderService.currentMode"
    > Save Draft </a>
    <a
      class="d-flex form-button form-button-1"
      (click)="OnAttemptPublishForm($event)"
    > Publish </a>
  </div>
</div>


<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
