<!-- ADD NEW DOCUMENTS IF THERE ARE NONE -->
<div  *ngIf=" element.typeOfInvestor !== 'AutomaticRequest' && !element.investorSide && !element.beforeClaim && (!element.evidenceTypes || element.evidenceTypes.length==0)">
  <div class="d-flex justify-content-between">
    <div
    style="margin-bottom: -7px;"
    [attr.data-evidenceKey] = ""
    class="document-inner-entry no-documents"
    (click)="onEmit(elementService.OnButtonClick($event))"
    [attr.clickmethod]="'documentUploadScreen'"
    >
      <div
      class="document-upload-entry-image"
      style="pointer-events:none;"
      >
        +
      </div>
      <div class="document-upload-entry-detail"
      style="pointer-events:none;"
      >
        <h5 class="document-name"
        style="pointer-events:none;"
        > {{ element.typeOfInvestor === 'NaturalPerson' ? 'Add new documents or verifications' : 'Add new documents'}}</h5>
      </div>
    </div>
  </div>
</div>
