// code for radio component: https://github.com/formio/formio.js/blob/master/src/components/radio/Radio.js
const radioComponent = Formio.Components.components.radio;
import RadioComponentForm from './radio.form.js';
import { HelperServiceService } from 'src/app/helper-service.service';

class RadioExtendedComponent extends radioComponent {

  static schema(...extend) {
    return super.schema({
          type: 'RadioExtendedComponent',
          label: "",
          any_other_fields: "",
    }, ...extend)};

  static get builderInfo() {
    return {
      // title: 'Name in the Builder',
      title: 'Radio button(s)',
      group: 'customBasic',
      icon: 'dot-circle-o',
      weight: 5,
      schema: this.schema()
    };
  }
   render(element) {

      const submission = _.get(this.root, 'submission', {});
      const radios = this.component.values;
      const id = 'e8rbv53n';
      let radio_counter  = 0;
      const mapped = radios.map(radio => {
        const value = HelperServiceService.generateRandomString(5);
        radio_counter ++;
        return `
        <div class="form-check mt-2">
          <label for="${id}-${value}" class="form-check-label d-flex label-position-right">
            <input class="custom-radio-button" ref="input" id="${id}-${value}" type="radio" name="data[radio][${id}]" value="${value}_aw:${radio.answerWeight}" />
            <div class="custom-radio-label"></div>   
            <div class="d-inline">${radio.label} 
            <span class="weight-count" style="margin-left: 12px">(${(radio.answerWeight || radio.answerWeight === 0  ) 
                                                                ? radio.answerWeight : ''} points)</span></div>
          </label>
        </div>`;
      });
      this.tpl = `
        <div class="required" ref='refExtRadio' id="${id}">
          <div class="field-required col-form-label">${this.component.label}</div>
          <div class="formio-positonal-wrapper ${this.component.optionsLabelPosition}">
            ${mapped.join('')}
          </div>
        </div>
      `;
      // Note the use of the 'ref' tag, which is used later to find
      // parts of your rendered element.

      // If you need to render the superclass,
      // you can do that with
      // this.tpl+=super.render(element);

      // This wraps your template to give it the standard label and bulider decorations
      return Formio.Components.components.component.prototype.render.call(this, this.tpl);

    }

    // attach(element) {
    // //   // This code is called after rendering, when the DOM has been created.
    // //   // You can find your elements above like this:
    // //   this.loadRefs(element, {refExtRadio: 'single'});

    // //   // var superattach = super.attach(element);
    // //   //  // Here do whatever you need to attach event handlers to the dom.
    // //   // this.refs.refExtRadio.on('click',()=>{alert("hi!");});

    // //   // return superattach;

    // // return super.attach(element);

    //       // This code is called after rendering, when the DOM has been created.
    //   // You can find your elements above like this:
    //   this.loadRefs(element, {refExtRadio: 'single'});
    //   var superattach = super.attach(element);
    //   //  // Here do whatever you need to attach event handlers to the dom.
    //   console.log(this.refs.refExtRadio);

    //   this.addEventListener(this.refs.refExtRadio, 'click', () => {
    //    alert('Custom Ref has been clicked!!!');
    // });
    //   // this.refs.refExtRadio.on('click',()=>{alert("hi!");});
    //   return superattach;

    // }

    getvalue() {
      return 20;
    }

    // // OR, depending on which component type you're basing on:
    getValueAt(index,value,flags) {
      return 30;
    }

    // setValue(value) {
    //   // modify your DOM here to reflect that the value should be 'value'.
    // };
    // setValueAt(index, value) {
    //   if (this.refs.input && this.refs.input[index] && value !== null && value !== undefined) {
    //     const inputValue = this.refs.input[index].value;
    //     this.refs.input[index].checked = (inputValue === value.toString());
    //   }
    // }

    // // OR, depending on what component type:
    getValueAt(index) {
      return 40;
    }

  }

  // This sets the form that pops up in the Builder when you create
  // one of these.  It is basically just a standard form, and you
  // can look at the contents of this in the debugger.
  // console.log('edit form', radioComponent.editForm);
  // RadioExtendedComponent.editForm = radioComponent.editForm;
  RadioExtendedComponent.editForm = RadioComponentForm;


  // Register your component to Formio
  Formio.Components.addComponent('RadioExtendedComponent', RadioExtendedComponent);
  export default RadioExtendedComponent;
