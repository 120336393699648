<div  class="projects-content-wrapper py-2">


  <div class="d-flex justify-content-center text-white my-3 select-project" *ngIf="projectsType !== 'investor' &&  activeProjectList && activeProjectList.length < 4">
    <span>
        {{ projectsType === 'funds' ? 'Select a project' :
            projectsType === 'id-verification' ? 'Select a project' :
            ('appSpecific.SelectInvestor' | translate)
        }}:
      </span>
  </div>

  <div *ngIf="projectsType !== 'investor' && activeProjectList.length >= 4" class="d-flex justify-content-between my-5 project-filter">
    <div class="select-a-project-label">
      {{this.projectsType === 'funds' ? 'Your projects' : 'Your projects' }}
    </div>
    <div class="d-flex align-items-center">
      <div class="sort-by-label">
        Sort by:
      </div>
      <div class="select-wrapper">
        <select
        class="select-input input-transparent"
        (change)="OnChangeSelectFilter($event)">
          <option value="last_modified" selected label="Last updated" >
          <option value="created_on" label="Created on">
        </select>
      </div>
      <button [ngClass]="{'form-button form-button-1 mr-0': true}" (click)="OnDisplayCreateProjectLightbox()">
        {{this.projectsType === 'funds' ? ' Create a new project' : 'Create a new project' }}
      </button>
    </div>
  </div>
  <div
    [ngStyle] = "!allProjectsLoaded && activeProjectList.length >= 4 ? {'padding-bottom' : '70px'}: {}"
    (scroll)="onScrollProjectList($event)" [ngClass]="{'d-flex justify-content-center flex-wrap mx-auto active-project-list position-relative': true }">
      <div [ngClass]="{'card': true, 'active': (activeProject !== undefined && project.key === activeProject['key'])}"
        style="width: 18rem;"
        [attr.id]="project.key"
        (click)="OnSelectActiveProject($event)"
        *ngFor="let project of activeProjectList">
        <div class="d-flex card-img-wrapper" [ngStyle]="{'background-color': (project.record.projectBranding !== undefined
                                                        && project.record.projectBranding.projectColor) ? project.record.projectBranding.projectColor : '#ffffff'}">
          <div class="notification-dot" *ngIf="project.record.notify"></div>
          <img class="card-img-top loading-project-icon" src="/assets/images/spinner-gray.svg" width="32px" height="32px" alt="{{ project.record.name }}" *ngIf="project.key === 'empty'">
          <img class="card-img-top failed-project-icon" src="/assets/images/icon-triangle-orange.svg" alt="{{ project.record.name }}" *ngIf="project.key === 'failed'">
          <img  class="card-img-top default-project-icon my-auto"
                [ngClass]="((project.record.scaledW !== undefined && project.record.scaledW !== 0) || (project.record.scaledH !== undefined && project.record.scaledH !== 0)) ? 'big' : ''"
                src="{{(project.record.projectBranding && project.record.projectBranding.projectLogo) ? project.record.projectBranding.projectLogo : '/assets/images/ico-project-dark-color.svg'}}"
                alt="{{ project.record.name }}"
                *ngIf="project.key !== 'empty' && project.key !== 'failed'">
        </div>
      <div class="card-body position-relative">
        <div class="d-flex w-75">
          <h5 class="d-flex card-title" (click)="OnEditName($event)" *ngIf="showEditNameFor !== project.key" [attr.title]="project.record.name">{{ project.record.name }}</h5>
          <div class="d-flex click-edit" (click)="OnEditName($event)"  *ngIf="showEditNameFor !== project.key"><img src="/assets/images/pencil-icon.svg"></div>
          <div class="error-field"  *ngIf="(showEditNameFor !== '') && showEditNameFor === project.key"></div>
          <input type="text" class="d-flex card-title-editor" maxlength="60" (click)="OnFocusName($event)" (keyup)="OnEditNameKeyUp($event)" (blur)="OnEditNameComplete($event)" *ngIf="(showEditNameFor !== '') && showEditNameFor === project.key" value="{{ project.record.name }}">
          <div class="click-save" *ngIf="(showEditNameFor !== '') && showEditNameFor === project.key"><img src="/assets/images/solid-checkmark.svg" width="20px" height="20px"></div>
        </div>
        <a href="#" *ngIf="project.key !== 'empty' && project.key !== 'failed' &&  projectsType != 'investor'" (click)="DisplayMyOptions($event)" class="d-flex justify-content-center align-items-center settings-icon"><img src="/assets/images/ico-settings.svg" width="20px" height="20px" alt="d-flex settings icon"
                    /></a>
        <ul class="project-submenu">
          <li *ngIf="permissionCustomizeProject
                    && project.record.permissionRecord && project.record.permissionRecord.type === 'owner'"><a href="#" (click)="OnCustomizeProject($event)">Customize project</a></li>
          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type !== 'member' && projectsType != 'investor'"><a href="#" (click)="OnInviteContributors($event)">Invite contributors</a></li>
          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type !== 'member'"><a href="#" (click)="OnManageContributors($event)">{{'appSpecific.ManageContributors' | translate }}</a></li>
          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type !== 'member' && permissionReportGeneration  && projectsType === 'id-verification'"><a href="#" (click)="OnGenerateReport($event)">Generate report</a></li>
          <li *ngIf="project.record.permissionRecord && projectsType === 'funds'  && permissionKYCEmplates"><a href="#" (click)="onCreateTemplate($event)">Create KYC template</a></li>
          <li *ngIf="project.record.permissionRecord
          && projectsType === 'funds' &&  permissionKYCEmplates && idVerificationService.getfundsOrganizationTemplates()
          && idVerificationService.getfundsOrganizationTemplates().length"><a href="#" (click)="onEditTemplate($event)">Edit KYC templates</a></li>
          <!-- <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type === 'owner' && projectsType === 'funds'"><a href="#" (click)="onCreateTemplate($event)">Create template</a></li> -->
          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type !== 'member'"><a href="#" (click)="OnAttemptLeaveAProject($event)"> {{'appSpecific.LeaveProject' | translate}}</a></li>

          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type === 'member'"><a href="#" (click)="OnAttemptLeaveAProjectMember($event)">{{'appSpecific.LeaveProject' | translate}}</a></li>
          <li *ngIf="project.record.permissionRecord && project.record.permissionRecord.type === 'owner' && projectsType === 'id-verification'"><a href="#" (click)="onAttemptDeleteProject($event)">Delete project</a></li>

        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="showLoader && !allProjectsLoaded" style="margin-top:-30px;" class="text-center mt-2">
    <img style="width:40px; height:40px;" src="/assets/images/spinner.svg">
  </div>
  <div *ngIf="projectsType !== 'investor' && activeProjectList && activeProjectList.length < 4" class="d-flex justify-content-center mt-5">
    <button [ngClass]="{'form-button form-button-1': true}" (click)="OnDisplayCreateProjectLightbox()">
       {{this.projectsType === 'funds' ? ' Create a new project' : 'Create a new project' }}
    </button>
    <!-- <button [ngClass]="{'form-button form-button-1 mr-0': true}" (click)="OnGenerateDummyTemplate()">
      Generate dummy template
    </button> -->
  </div>
</div>

