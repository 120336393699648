<app-header
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  (formBuilderPreview)='formBuilderPreview($event)'
></app-header>

<!-- FORMIO HEADER -->
<app-q-header
  *ngIf="!qBuilderService.previewList"
></app-q-header>

<!-- EDIT FORM SCREEN -->
<div
  class="container"
  *ngIf="!qBuilderService.previewList && !qBuilderService.preview && !qBuilderService.previewJson && !qBuilderService.editOutput"
>
  <div class="pb-5"></div>
  <form-builder [form]="qBuilderService.form" (change)="onChange($event)" [options]="options" [rebuild]="rebuildEmitter.asObservable()"></form-builder>
    <a
    *ngIf="false"
    class="publish-questionare"
    (click)="onApplyRiskRanges($event)"
  > Apply risk ranges </a>
</div>

<!-- EDIT OUTPUT(s) SCREEN -->
<app-form-outputs-editor
  *ngIf="qBuilderService.editOutput"></app-form-outputs-editor>

<!-- list all forms/contract for my organization -->
<app-q-builder-list
  *ngIf="qBuilderService.previewList"></app-q-builder-list>

<!-- FORM PREVIEW DIALOG -->
<app-q-builder-preview
  *ngIf="qBuilderService.preview"></app-q-builder-preview>

<!-- JSON PREVIEW DIALOG -->
<app-q-builder-json-preview
  *ngIf="qBuilderService.previewJson"></app-q-builder-json-preview>


<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
