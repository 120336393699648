<div

      [ngClass]="{'first-related-party' : isFirst }"
      class="relatedPartyToggle pb-0"
    >
    <div>
    <div>
      <span  class="list-inner-title">
        {{relatedParty.record.typeOfInvestor === 'NaturalPerson'
        ? relatedParty.record.investorFields.FirstName  + ' ' + relatedParty.record.investorFields.LastName
        : relatedParty.record.investorFields.RegisteredName}}
      </span>
    </div>
      <div class="related-party-role" *ngIf="relatedParty.key == element.activeRelatedPartyKey">
        {{relatedParty.record.relatedPartyRoles? elementService.parseRelatedPartyRole(relatedParty.record.relatedPartyRoles[0]): '' }}
      </div>
    </div>
      <!-- RELATED PARTY ACTIONS BUTTONS -->
        <div class="actionsMenuWrapper unselectable action-new-style" *ngIf="!element.beforeClaim && !element.investorSide">
          <a href="#" class="d-flex actionsMenuBtn justify-content-center align-items-center" (click)="toggleActionsMenu($event)">
            <img class="mr-1 action-icon">
            <span>Actions</span>
          </a>
          <ul class="d-none">
            <li
              *ngIf="element.showCreateNewAction"
              (click)="documentUploadScreenRelatedParty(
                {
                  'requestKey' : relatedParty.key,
                  'typeofInvestor' : relatedParty.record.typeOfInvestor,
                  'existingEvidenceTypes' : element.activeRelatedParty.evidenceTypes,
                  'isRelatedParty' : true, existingDigitalVerifications:element.activeRelatedParty.digitalVerifications
                }
              );
              toggleActionsMenu($event)"
            >
            <img class="action-plus" src="/assets/images/add-action.svg">
            <div>{{relatedParty.record.typeOfInvestor === 'NaturalPerson' ? 'Add documents or verifications' : 'Add documents'}}</div>
            </li>
            <li
              *ngIf="element.showDeleteAction"
              (click)="onDeleteRelatedParty(
                {
                  'requestKey' : relatedParty.key,
                  'typeofInvestor' : relatedParty.record.typeOfInvestor,
                  'existingEvidenceTypes' : element.activeRelatedParty.evidenceTypes,
                  'isRelatedParty' : true, existingDigitalVerifications:element.activeRelatedParty.digitalVerifications
                }
              );
              toggleActionsMenu($event)"
            >
            <img class="action-plus" src="/assets/images/ico-delete.svg">
            <div>Delete related profile</div>
          </li>
          <li
              *ngIf="element.showRetriggerAction"
              [attr.isRelatedParty] = true
              [ngClass] = 'element.retriggerActionClass'
              [attr.clickmethod]="relatedParty.record.typeOfInvestor === 'NaturalPerson' ? 'generateApsLightbox' : 'generateApsLightboxLegal'"
              [tooltip]="element.tooltip"
              (click)="onEmit(elementService.OnButtonClick($event));
              toggleActionsMenu($event)"
            >
            <img style="pointer-events: none;" class="action-plus" src='/assets/images/screening-action.svg'>
            <div style="pointer-events: none;">Request screening</div>
        </li>
          </ul>
      </div>
      <!-- END RELATED PARTY ACTIONS BUTTONS -->
      <div  class="d-flex" style="height:fit-content">
        <img [ngClass] = "{'d-block' : isInvestorType && relatedParty.rpGeneralStatusClasses === 'status-pending' }"
        style="width:20px; height:auto;margin-right:4px;" class="d-none" src="assets/images/icon-triangle-blue.svg">
        <div *ngIf="!element.beforeClaim" class="additional-info-container unselectable additional-info-rp">
          <span class="additional-info"
          [ngClass]="[(relatedParty.rpGeneralStatusClasses !== undefined) ? relatedParty.rpGeneralStatusClasses : '']">
          {{ relatedParty.rpGeneralStatus }}
        </span>
        </div>
        <a
          [ngClass]="'collapse-button'"
          (click)="element.beforeClaim === true ? loadRelatedPartyClaim($event,element.requestKey,relatedParty.key ) : loadRelatedParty($event,relatedParty.key )"
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          [attr.data-CollapseRPKey]=(relatedParty.key)
        >
        <img src ="assets/images/result_arrow_down.png" >
        </a>
     </div>
    </div>
    <app-profiles-status-information 
    *ngIf="relatedParty.record"
    [profileRecord] = "relatedParty.record"
    ></app-profiles-status-information>