import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fullscren-loading-overlay',
  templateUrl: './fullscren-loading-overlay.component.html',
  styleUrls: ['./fullscren-loading-overlay.component.scss']
})
export class FullscrenLoadingOverlayComponent implements OnInit {

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    console.log('onInit');
  }

}
