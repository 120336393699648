import { Component, OnInit } from '@angular/core';
import { VerificationsService } from '../verifications.service';
import { WindowRefService } from 'src/app/window-ref.service';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {

  displayLoader: boolean;
  verificationKey: string;
  logo: string;
  projectColor: string;
  postUrl: string;
  projectTextColorClass: string;
  message = '';
  headerInfo: {};
  headerClasses: {};
  errorCodeVar = '';
  errorCodeOne;
  errorCodeTwo;
  errorCodeThree;
  errorCodeFour;
  errorCodeFive;
  errorCodeSix;
  errorCodeSeven;

  constructor(  private verificationsService: VerificationsService,
                private winRef: WindowRefService,
                private http: HttpClient,
                public translate: TranslateService
                ) {
                  this.postUrl = environment.APIEndpoint + 'api/verifications/data/completed';
                }

  ngOnInit() {
    this.displayLoader = true;
    this.projectColor = '#ffffff';
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': '',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };
    // This page will be implemented into an iframe
    // get reference to the window.parent and extract the verificationKey
    const location_parts = this.winRef.nativeWindow.parent.location.pathname.split('/');
    // if (location_parts[location_parts.length - 1].indexOf('VFE:') !== -1) {
      this.verificationKey = location_parts[location_parts.length - 2];
      this.verificationsService.getVerificationToken(this.verificationKey).subscribe(response => {

         /**
         * Under every error message there should be text in a much smaller font size and in italics, with the 4 digit error code above:
           “Error code = XXXX”
        */
        // 9200, 9210
        this.errorCodeOne = this.translate.instant('IDPCS.idVerification.errorCodeOne');
        // 9100, 9300, 9800, 9801, 9810
        this.errorCodeTwo = this.translate.instant('IDPCS.idVerification.errorCodeTwo');
        // 9400
        this.errorCodeThree = this.translate.instant('IDPCS.idVerification.errorCodeThree');
        // 9820
        this.errorCodeFour = this.translate.instant('IDPCS.idVerification.errorCodeFour');
        // 9821
        this.errorCodeFive = this.translate.instant('IDPCS.idVerification.errorCodeFive');
        // 9822
        this.errorCodeSix = this.translate.instant('IDPCS.idVerification.errorCodeSix');
        // 9835
        this.errorCodeSeven = this.translate.instant('IDPCS.idVerification.errorCodeSeven');

        if ( response && response.projectBranding && response.projectBranding.projectColor) {
          this.verificationsService.setProjectColor(response.projectBranding.projectColor);
        } else {
          this.verificationsService.setProjectColor('');
        }
        this.projectColor =  this.verificationsService.getProjectColor();
        this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();

        /**
         * logo is not needed as this page is displayed in an iframe with logo already implemented
         */

        this.message = 'The documents you provided have not been accepted for further processing because they did not meet the minimum requirements. Please request a new verification link to try again. You may close this window.';
        this.displayLoader = false;
        const urlQuery = window.location.search;
        const errorCode = urlQuery.slice(urlQuery.length - 4);
        this.errorCodeVar = errorCode;
        switch (errorCode) {
          case '9200': case '9210':
            this.message = this.errorCodeOne;
            break;
          case '9100' : case '9300' : case '9800' : case '9801' : case '9810':
            this.message = this.errorCodeTwo;
            break;
          case '9400' :
            this.message = this.errorCodeThree;
            break;
          case '9820' :
            this.message = this.errorCodeFour;
            break;
          case '9821' :
            this.message = this.errorCodeFive;
            break;
          case '9822' :
            this.message = this.errorCodeSix;
            break;
          case '9835' :
            this.message = this.errorCodeSeven;
            break;
          default:
            this.errorCodeVar = undefined;
        }

        // Get the url query parameter to send to the backend
        this.postUrl = this.postUrl + urlQuery;
        // Send the url query parameter to the backend
        this.http.post(this.postUrl, urlQuery).subscribe(() => {
          console.log('backend log success');
        }, error => {
          console.log('backend log fail');
        });
      });
  }
}
