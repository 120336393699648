import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { IntegrityService } from 'src/app/integrity-check/integrity.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit {

  public companySearchTerm: string;
  public fallbackKVK: string;
  private kvkForSubmit: string;
  private tmoSearch: any;
  private kvkServiceUnavailable: boolean;
  @Input()  kvkResults: [];
  @Input() displayFlagging: boolean;
  @Output() searchTerm = new EventEmitter<string>();
  @Output() selectedCompany = new EventEmitter<any>();
  @Output() initiateFlagging = new EventEmitter<any>();
  validatorMessage: string;

  constructor(private integrityService: IntegrityService) { }

  ngOnInit() {
    this.setKvkServiceState(false); // it is expected that by default kvk service is available

    this.integrityService.kvkStatusChange.subscribe((response) => {
        if (response.trigger !== 'headerSearch') {
          // this call is not for me
          return;
        }
        const status = response.status;
        if (status === undefined) {
          // do nothing
          return;
        }
        console.log('CHANGING KVK STATUS TO', status);
        this.setKvkServiceState(status);
        this.companySearchTerm = '';
      },
      error => {
        // do nothing
        return;
      }
    );
  }

  setKvkServiceState(state) {
    this.kvkServiceUnavailable = state;
  }
  getKvkServiceState() {
    return this.kvkServiceUnavailable;
  }

  OnAutoSuggest(event: any) {
    this.validatorMessage = '';
    delete this.kvkForSubmit;
    clearTimeout(this.tmoSearch);
    const _this = this;
    this.tmoSearch = setTimeout(
      function() {
        _this.searchTerm.emit(event.target.value);
      }, 300
    );
  }

  OnAutoSuggestFocus(event: Event) {
    this.clearInvalidation();
  }

  clearInvalidation() {
    this.validatorMessage = '';
  }

  OnSelectCompanyForReview(event: any) {
    event.preventDefault();
    this.clearInvalidation();
    this.kvkForSubmit = event.target.parentNode.getAttribute('kvkNumber');
    this.companySearchTerm = event.target.parentNode.getAttribute('companyName');
    this.kvkResults = null;
    // execute submit company for review without clicking the Perform Check button
    this.OnSubmitCompanyForReview();
  }

  OnSubmitCompanyForReview() {
    if (!this.kvkForSubmit) {
      // alert('Please select a company first!');
      this.validatorMessage = 'Please select a company first!';
      return;
    }
    this.selectedCompany.emit( {  'kvkNumber' : this.kvkForSubmit,
                                  'companyName' : this.companySearchTerm,
                                  'showMessage': $('#hide-message').is(':checked') } );
    this.companySearchTerm = '';
  }

  OnSubmitKvkOnlyCompanyForReview() {
    if (!this.fallbackKVK || !$.isNumeric(this.fallbackKVK)) {
      alert('Please enter a valid Kvk number first!');
      return;
    }
    this.selectedCompany.emit( {  'kvkNumber' : this.fallbackKVK,
                                  'companyName' : '',
                                  'showMessage': $('#hide-message').is(':checked') } );
    this.fallbackKVK = '';
    // reset the kvkStatus
    this.integrityService.resetKvkStatusFailed();
    // reset companySearchTerm
    this.companySearchTerm = '';
  }

  OnFlagCompany(event) {
    this.initiateFlagging.emit( event );
  }

}
