import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user.service';
import { ToastNotificationsService } from '../../toast-notifications.service';
import { FormBuilderServiceService } from '../form-builder-service.service';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {

  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;

  constructor(
    public formBuilderService: FormBuilderServiceService,
    private userService: UserService,
    private toastNotification: ToastNotificationsService
  ) { }

  ngOnInit(): void {
  }

  OnAttemptPublishForm(event) {
    event.preventDefault();
    // TODO: add logic that will determine if this is a:
    // * new form
    // * existing published form
    // * existing draft form
    if (this.formBuilderService.currentTemplate && this.formBuilderService.currentMode) {
      this.ConfirmPublishExitingForm();
    } else {
      this.DisplayEnterNameNewForm();
    }
  }

  DisplayEnterNameNewForm() {
    this.formType = `Save the contract template`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    console.log('current outputs', this.formBuilderService.currentOutputs)
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top mt-3 location-text',
            'params': {
              'content': 'Name the new contract template, which includes the form and the related document templates'
            }
          },
          // {
          //   'type': 'paragraph',
          //   'class' : 'text-center mb-3',
          //   'params': {
          //     'content':
          //       `if you change the name `
          //   }
          // },
          {
            'type' : 'inputText',
            'params' : {
                'label': 'New contract template name',
                'id': 'newFormName',
                'name': 'newFormName',
                'content': (this.formBuilderService.currentOutputs.length === 1) ? this.formBuilderService.currentOutputs[0].fileName : '',
                'required': 'true',
                'labelClass': 'font-weight-bold',
                'maxLength' : 60,
              },
          },
          {
            'type' : 'select',
            // 'subType' : 'addressManualInput',
            'class': 'reduced-margin-top input-transparent manualInput',
            'params' : {
                'label': 'Save as:',
                'id': 'saveAs',
                'name': 'saveAs',
                'options':  [
                  {key : 'Draft', name : 'Draft'},
                  {key : 'Published', name: 'Published'},
              ],
                'labelClass': 'font-weight-bold'
              },
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Save',
                // 'id': 'accept',
                'action': 'onPublishForm',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Go back',
                // 'id': 'rescan',
                'action': 'onCancelPublishForm',
                'class': 'form-button form-button-6',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  ConfirmPublishExitingForm() {
    this.formType = `Confirm updating a template`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top  mt-3 location-text',
            'params': {
              'content': 'Please confirm your action'
            }
          },
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `You are about to update the content and/or status of the "<strong>${this.formBuilderService.currentTemplate}</strong>" template. Please confirm that this is intentional as the action is non-reversable!`
            }
          },
          {
            'type' : 'select',
            'class': 'reduced-margin-top input-transparent manualInput',
            'params' : {
                'label': 'Save as:',
                'id': 'saveAs',
                'name': 'saveAs',
                'options':  [
                  {key : 'Draft', name : 'Draft'},
                  {key : 'Published', name: 'Published'},
                ],
                'optionselected': this.formBuilderService.currentMode,
                'labelClass': 'font-weight-bold'
              },
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Ok',
                // 'id': 'accept',
                'action': 'onConfirmPublishExistingForm',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'action': 'onCancelPublishForm',
                'class': 'form-button-2',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  DisplayEnterNameNewOutput() {
    this.formType = `Create new document template`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top mt-3 location-text',
            'params': {
              'content': 'Enter the name of the new output template'
            }
          },
          // {
          //   'type': 'paragraph',
          //   'class' : 'text-center mb-3',
          //   'params': {
          //     'content':
          //       `Please enter the name for the new form`
          //   }
          // },
          {
            'type' : 'inputText',
            'params' : {
                'label': 'New document template ',
                'id': 'newOutputName',
                'name': 'newOutputName',
                'content': '',
                'required': 'true',
                'labelClass': 'font-weight-bold',
                'maxLength' : 60,
              },
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Create',
                // 'id': 'accept',
                'action': 'onCreateNewOutput',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Go back',
                // 'id': 'rescan',
                'action': 'onCancelPublishForm',
                'class': 'form-button form-button-6',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * used to publish newly created forms
   * @returns void
   */
  onPublishForm() {
    // check if the form name is already taken
    // take the formBuilderService.formattedJson
    // take the formBuilderService.formattedHTML
    // and submit them to PDF Server for writing to files
    if ($('#newFormName').val() !== '') {
      if (!this.formBuilderService.isFormioReady()) {
        alert('Cannot save, your form is empty!');
        this.displayLoader = false;
        this.displayModal = false;
        this.formBuilderService.displayEditForm()
        return;
      }
      if (!this.formBuilderService.areOutputsReady()) {
        alert('Cannot save, your output is empty!');
        this.displayLoader = false;
        this.displayModal = false;
        this.formBuilderService.displayEditOutput()
        return;
      }
      const formSlug = this.formBuilderService.slugify($('#newFormName').val());

      if (this.formBuilderService.currentOutputs.length === 1) {
        // if there is only one output make sure it's name is same as the form name
        this.formBuilderService.currentOutputs[0].fileName = $('#newFormName').val();
      }

      if (!this.formBuilderService.checkIfNameIsUniqueBySlug(formSlug)) {
        alert('You must provide unique name for the new template');
        return;
      }
      this.displayLoader = true;
      this.publishMyForm(formSlug, $('#saveAs').val(), true);
    } else {
      this.displayLoader = false;
      alert('You must provide name for the new template');
    }
  }

  onConfirmPublishExistingForm() {
    // take the formBuilderService.formattedJson
    // take the formBuilderService.formattedHTML
    // and submit them to PDF Server for writing to files
    if ($('#newFormName').val() !== '') {
      if (this.formBuilderService.formattedJson === '') {
        alert('Cannot save, your form is empty!');
        return;
      }
      if (!this.formBuilderService.areOutputsReady()) {
        alert('Cannot save, your output is empty!');
        return;
      }
      this.displayLoader = true;
      this.publishMyForm(this.formBuilderService.currentTemplate, $('#saveAs').val(), false);
    }
  }

  OnSaveAsDraft() {
    // take the formBuilderService.formattedJson
    // take the formBuilderService.formattedHTML
    // and submit them to PDF Server for writing to files
    if ($('#newFormName').val() !== '') {
      if (this.formBuilderService.formattedJson === '') {
        alert('Cannot save, your form is empty!');
        return;
      }
      if (!this.formBuilderService.areOutputsReady()) {
        alert('Cannot save, your output is empty!');
        return;
      }
      this.displayLoader = true;
      $('.save-as-draft').addClass('loading');
      this.publishMyForm(this.formBuilderService.currentTemplate, 'draft', false, false);
    }
  }

  onCreateNewOutput() {
    if ($('#newOutputName').val() !== '') {
      this.formBuilderService.addNewOutput($('#newOutputName').val());
      this.displayModal = false;
    }
  }

  onCancelPublishForm() {
    this.onLightboxClose(null);
  }

  async publishMyForm(newName, saveAs, isNewForm, backToList = true) {
    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {
        'newName': newName,
        'mode': saveAs.toLowerCase(),
        'formattedJson': JSON.stringify(this.formBuilderService.formattedJson),
        'formattedHTML': this.formBuilderService.formattedHTML,
        'outputs': JSON.stringify(this.formBuilderService.prepareOutputsForPayload())
      };
      await this.userService
          .postEndPoint('api/pdf/templates/'
            + newName
            + '/template/'
            + this.userService.getUserOrganizationId()
            + ((isNewForm) ? '/writeTemplate' : '/updateTemplate')
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(schema => {
            // this.formBuilderService.formattedJson =  JSON.parse(schema[0].data);
            // this.formBuilderService.setFormJson(this.formBuilderService.formattedJson, true);
            if (backToList) {
              this.formBuilderService.displayPreviewList();
              this.displayLoader = false;
              this.displayModal = false;
            } else {
              this.toastNotification.newEvent({
                mode: 'info',
                title: 'Draft has been saved',
                fade: true
              });
              $('.save-as-draft').removeClass('loading');
            }
          })
          .catch(error => {
            alert(error.message);
            this.displayLoader = false;
          });
    }
  }

  // lightbox handler methods
  onLightboxConfirm(returnedAction) {
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    // this.displayLoader = true;
  }

  onLightboxClose($event) {
    // this.copyFrom = null;
    this.displayModal = false;
  }

  clearDefaultValues() {
    this.formBuilderService.formattedJson['components'] =
    this.formBuilderService.clearJsonDefaultValues(this.formBuilderService.formattedJson['components']);
    this.formBuilderService.setFormJson(this.formBuilderService.formattedJson, true);
    let mode = this.formBuilderService.currentMode;
    if (this.formBuilderService.checkIfMultipleModes()) {
      mode = 'draft';
    }
    this.publishMyForm(this.formBuilderService.currentTemplate, mode, false, true);
  }

}
