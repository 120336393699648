import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { environment } from 'src/environments/environment';
import { IdVerificationService } from '../../id-verification.service';
import QRCode from 'qrcode';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfServerService } from 'src/app/pdf-server.service';
import { emailPattern} from 'src/app/id-verification/id-verification-const';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';

@Component({
  selector: 'app-contract-verification-onboard-subject',
  templateUrl: './contract-verification-onboard-subject.component.html',
  styleUrls: ['./contract-verification-onboard-subject.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContractVerificationOnboardSubjectComponent implements OnInit, OnDestroy {

  // starting parameters
  headerInfo;
  headerClasses;
  projectColor;
  logo;
  projectTextColorClass: string;
  editMode = true;
  previewPdf: any;
  firstName: any;
  lastName: any;
  dateOfBirth: any;
  contractBlob: any;
  contractFirstName;
  contractLatName;
  contractTemplate;
  contractTemplates;
  contractDOB;
  isMultipleContracts; // variable to know wether to change some text depending if there is one or more contracts
  // data coming from IDV that needs to always be included in PDF
  isSigner: boolean = false;
  signedOn;
  constFormData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  };
  formattedJson: Object = {};
  skipEmailSubmission = false;
  isInvestor = false;
  investorProjectId;
  contractDataState = new Map([
    ["saveChanges" , true],
    ["savingChanges", false],
    ["changesSaved", false]
  ]);
  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private helper: HelperServiceService,
    private ipService: IpServiceService,
    private idVerificationService: IdVerificationService,
    private pdf: PdfServerService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public translate: TranslateService
  ) { }
  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  map: google.maps.Map;
  position: any;
  ipAddress: string;
  geoIpAddress: any;
  ipInfo: any;
  unsubscribe$: Subject<void> = new Subject<void>();
  errorState = false;
  contractInformationInputed = false ;
  previewFormio = false; // preview for the formio form
  submissionData;
  btnContentElements: string;
  refreshType: string;
  baseUrl = environment.APIEndpoint;
  originalContractTemplate;
  verificationRecord; // verification record contract/schema details
  stepsArray; // information on what steps there are in the lightbox
  verificationKey: string;
  activeVerificationKey: string;
  cryptoKey: string;
  stateMessage;;
  contractCloseCountInterval = 5; // 5 seconds
  sessionTimerSec = 300;
  sessionTimerCheckSec = 30;
  @ViewChild('myForm') myForm: FormioComponent;


  updateTimer (_this) {
    _this.sessionTimerSec--;
    _this.sessionTimerCheckSec--;

    // Check if the timer has reached zero
    if(_this.sessionTimerCheckSec < 0)
    {
      _this.updateInvestor(_this, 70);
    }
    if(_this.sessionTimerCheckSec < 65)
    {
      this.changecontractDataState('saveChanges');
    }
    if (_this.sessionTimerSec < 60) {
      $('.expire-indicator').removeClass('d-none');
    } else {
      $('.expire-indicator').addClass('d-none');

    }
    if (_this.sessionTimerSec < 0) {
      _this.updateInvestor(_this, 20);
      this.countdownAndCloseContract();
    }


  }

  ngOnInit() {
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };

    this.contractFirstName = ' &lt;FIRST NAME&gt;';
    this.contractLatName = '&lt;LAST NAME&gt;';
    this.contractDOB = '&lt;DATE OF BIRTH&gt;';

    // this.projectColor = '#00889c';
    // this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
    // this.logo = 'assets/images/meshId_logo.svg';
    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;
    // get verification details that include the contract template detals

    this.http.post(this.baseUrl
    + 'api/digital/verifications/'
    + this.verificationKey
    + '/contract/start', {}).subscribe(async response => {
      this.stateMessage = this.translate.instant('IDPCS.error');
    if (response) {
     let _this = this; 
    const timerInterval = setInterval(function(){
      _this.updateTimer(_this);
      }, 1000);
    this.http.get(this.baseUrl
                        + 'api/digital/verifications/'
                        + this.verificationKey
                        + '/contract')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async response => {
      this.stateMessage = this.translate.instant('IDPCS.error');
      if (response) {
        // retrieve full details of this verification
        // needed to extract the address
        // turns out this is not possible as we are not exposing details about AV so it will not be useful
        // await this.http.get(this.baseUrl
        //                           + 'api/verifications/'
        //                           + this.verificationKey
        //                           + '/address'
        // )
        // .pipe(takeUntil(this.unsubscribe$))
        // .toPromise()
        // .then(res => console.log('verification full details', this.verificationRecordDetails = res))
        // .catch(error => console.error('error happened', error));


        this.cryptoKey = response['key'];
        if (response['record']) {
          // console.log('this is the record', response['record']);
          this.isInvestor = response['record']['isInvestor'];
          this.investorProjectId = response['record']['projectKey'];
          this.verificationRecord = response['record'];
          this.activeVerificationKey =  response['key'];
          this.isSigner = response['record']['isSigner'];
          this.signedOn = response['record']['signedOn'];
          if(this.verificationRecord.contractContent) {
            this.submissionData = this.verificationRecord.contractContent;
          }
          if (!this.verificationRecord.projectBranding) {
            this.projectColor = '#00889c';
            this.projectTextColorClass = 'light-color';
          } else {
            if (this.verificationRecord.projectBranding.projectColor) {
              this.projectColor = this.verificationRecord.projectBranding.projectColor;
              this.projectTextColorClass = this.helper.detectTextColor(this.helper.hexToRgb(this.projectColor));
            }
            if (this.verificationRecord.projectBranding.projectLogo) {
              this.logo = this.verificationRecord.projectBranding.projectLogo;
            } else {
              // get verification logo
              this.logo = 'assets/images/meshId_logo.svg';
              this.http.get(this.baseUrl + 'api/verifications/'
              + this.verificationKey
                + '/logo'
                , { responseType: 'blob' }).subscribe(async response2 => {
                  if (response2['type'] === 'application/json') {
                    this.logo = JSON.parse(await (response2 as Blob).text()).link;
                  } else {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                      this.logo = reader.result;
                      // GO TO STEP FETCHING LOGO
                    }, false);
                    reader.readAsDataURL(response2);
                  }
                }, error => {
                  console.error('Error occured while fetching the project logo', error);
              });
            }
          }
          // check if contract step is already complete
          // if it is redirect to PCR
          if (this.verificationRecord.completedAt) {
            this.router.navigate(['../'], { relativeTo: this.route });
          }

          // GET THE TEMPLATE
          this.http.get(this.baseUrl
            + 'api/pdf/templates/'
            + this.verificationRecord.template
            + '/template/'
            + this.verificationRecord.organizationKey.replace('ORG:', '')
            , {responseType: 'text'}
          )
          .pipe(takeUntil(this.unsubscribe$)).subscribe(async template => {
            try {
              template = JSON.parse(template);
              if (Array.isArray(template)) {
                template = template[0].data;
              }
            } catch (e) { }
            this.contractTemplate = template;
            this.previewPdf = 'data:application/pdf;base64,' + template;
          }, error => {
            alert(this.translate.instant('errors.error9'));
            this.previewFormio = false;
            this.errorState = true;
          });
          // GET THE SCHEMA
          this.http.get(this.baseUrl
            + 'api/pdf/templates/'
            + this.verificationRecord.template
            + '/schema/'
            + this.verificationRecord.organizationKey.replace('ORG:', '')
          )
          .pipe(takeUntil(this.unsubscribe$)).subscribe(async schema => {
            // We will alter the schema to prefill any additional data the BE has sent us under personData
            // console.log('this is the new schema:', schema);
            // console.log('this is the new schema:', schema, atob(schema[0].data as any));
            // schema = atob(schema[0].data as any);
            if (this.verificationRecord.personData) {
              // let newSchema = JSON.parse(atob(schema[0].data as any));
              let newSchema = JSON.parse(schema[0].data);
              newSchema = this.prefillBEData(this.verificationRecord.personData, newSchema);
              this.formattedJson = newSchema;
            } else {
              // const convertedSchema = atob(schema[0].data as any);
              this.formattedJson =  JSON.parse(schema[0].data);
            }
            if(this.submissionData)
            Object.entries(this.submissionData).map(entry => {
              this.parseComponents(this.formattedJson['components'], entry);
            });
            if(!this.signedOn)
            {
              this.previewFormio = true;
            }

            let _this = this;
            setTimeout(function() 
            {
              // Attach mouseenter and mouseleave event handlers to all input elements
              $('input').on('focusin', function(){
                _this.inputWasChanged(_this);
                }).on('focusout',  function(){
                _this.inputWasChanged(_this);

              }).on('change',  function(){
                _this.inputWasChanged(_this);

              });
              // Attach change event handler to radio buttons
              $('input[type="radio"]').on('change',function(){
                _this.inputWasChanged(_this);
              });
            }, 500);

          }, error => {
            alert(this.translate.instant('errors.error10'));
            this.previewFormio = false;
            this.errorState = true;
          });

          if(this.signedOn && this.isSigner)
          {
            this.generatePDFForSigner();
          }
        }
      }
    },
    error => {
      this.errorState = true;
      this.projectTextColorClass = 'light-color';
      this.projectColor = '#00889c';
      this.logo = 'assets/images/meshId_logo.svg';
      console.error('Error occured while fetching the access token', error);
    });
      }
    },
    error => {
      this.errorState = true;
      this.stateMessage = error.error.message;
      this.projectTextColorClass = 'light-color';
      this.projectColor = '#00889c';
      this.logo = 'assets/images/meshId_logo.svg';
      console.error('Error occured while fetching the access token', error);
    });
  }

  generatePDFForSigner()
  {
    this.previewFormio = false;
    this.displayLoadingPreviewContract();
    const payload = this.compilePayload('html');
      this.pdf.generatePDF(
        this.verificationRecord.template
        , 'temp.html'
        , JSON.stringify(payload.jsonData)
        , ''
        , false
        , true
      )
      .then(response => {
        // check if json encoded response
        try {
          response = JSON.parse(response as any);
        } catch (e) {}
  
        if (Array.isArray(response)) {
          this.contractTemplate = '';
          this.contractTemplates = response.map(template => template[1]);
          this.isMultipleContracts = true;
        } else {
          this.contractTemplates = '';
          this.contractTemplate = response;
        }
        this.displaySignContract(false);
      }).catch();
  }

  parseContractData()
  {
    for (let [key, value] of Object.entries(this.submissionData)) {
      if (value) {
        if (this.checkIfFormioDate([key, value], this.formattedJson['components'])) {
          value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
          this.submissionData.data[key] = value;
        } else {
          // if not date parse it
          value = this.helper.parseBackendName(value.toString());
        }
        // we would no longer replace the values in memory
        // instead we will be using the html response from PDF server
        // templateTemp = templateTemp.split('{{' + key + '}}').join(value);
      }
    }
  }
  inputWasChanged(_this)
  {
    $('.expire-indicator').addClass('d-none');
      _this.sessionTimerSec = 300;
      _this.sessionTimerCheckSec = 70;
      this.changecontractDataState('saveChanges');

  }

  // currentState can be     
  // "saveChanges", "savingChanges", "changesSaved"
  changecontractDataState(currentState : string)
  {
    this.contractDataState.forEach((value, key) =>{
      if(currentState == key)
      {
        this.contractDataState.set(key, true);
      }
      else
      {
        this.contractDataState.set(key, false);
      }
    })
    
  } 

  updateInvestor(_this, sessionTimerCheckSec:number, updateTimer: boolean = false)
  {

    if(updateTimer)
    {
      $('.expire-indicator').addClass('d-none');
      _this.sessionTimerSec = 300;
    }
    if(this.sessionTimerCheckSec < 0 && this.contractDataState.get("saveChanges"))
    {
      !updateTimer?? $('.expire-indicator').addClass('d-none');
      this.changecontractDataState('savingChanges');
      this.submissionData = _this.myForm?.formio?._data;
      if(this.submissionData)
      {
        this.parseContractData();
        this.sessionTimerCheckSec = sessionTimerCheckSec;
        _this.http.post(
        this.baseUrl + 'api/digital/verifications/' + this.verificationKey + '/contract/',  {'contractContent' : this.submissionData  }
        , { responseType: 'text', observe: 'body'}
      ).toPromise()
      .then(response => {
          this.changecontractDataState('changesSaved');
          if(updateTimer)
          {
            _this.sessionTimerSec = 300;
            _this.sessionTimerCheckSec = 70;
          }
        }
      )
      .catch(error => {
        this.errorState = true;
        this.stateMessage = error.error.message;
        this.projectTextColorClass = 'light-color';
        this.projectColor = '#00889c';
        this.logo = 'assets/images/meshId_logo.svg';
        console.error('Error occured while fetching the access token', error);
      }); 
      }



    }
  }
  updateInvestorButton() {
    this.sessionTimerCheckSec = -1;
    this.updateInvestor(this, 70, true)
  }


  displayLoadingPreviewContract() {
    const isMobile = this.helper.isMobile();
    let lightboxClass  = 'form-lightbox-fullscreen contract-vfs form-lightbox-breadcrumbs document-upload-list display-flex not-closable';
    if (isMobile) {
      lightboxClass = 'form-lightbox-fullscreen contract-vfs '
                                + 'contract-vfs-mobile form-lightbox-breadcrumbs '
                                + 'document-upload-list not-closable form-lightbox-fullscreen-mobile';
    }
    this.formClass = '';
    this.formType = '';
    this.formTitle = '';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass =  {
      class: lightboxClass,
      showStepsMobile: isMobile,
      logoImage: this.logo,
      logoClass: "logo-height",
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray
    };
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0 ',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_new_verification',
      'result_details' : [
        {
        'group_result_title' : 'request_new_address_verification_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type' : 'documentVsSideVerification',
            'id' : 'activeDocument.type',
            'enabled': true,
            // 'isActive':  true,
            'editMode': false,
            'signedMode': false,
            'loaderMode': true,
            'class' : 'document-upload-no-list',
            'contractTemplate':
            '<div style="width: 100%; height: 60vh;" class="d-flex align-items-center justify-content-center"> <img class="align-self-center; width:40px; height:40px;" width="70px" height="70px" src="/assets/images/spinner-green.svg"/></div>',
            'params':  {
              'formTitle': this.isInvestor || (this.isSigner && this.signedOn) ? this.translate.instant('IDPCS.contract.title2') : this.translate.instant('IDPCS.contract.previewDocument'),
              'evidenceKey': this.cryptoKey,
              'signedOn': this.signedOn,
              'isInvestor': this.isInvestor,
              'isSigner': this.isSigner,
              'firstName': this.firstName,
              'lastName': this.lastName,
              'dateOfBirth': this.dateOfBirth,
              'startDate': (this.dateOfBirth !== undefined) ? this.helper.getAsStartDate(this.dateOfBirth) : undefined,
              'reviewState': false,
              'minDate': this.helper.getAsStartDate('01.01.1915'),
              'maxDate': this.helper.getAdultAsMaxDate(),
              'today': this.helper.getTodayDate('.'),
              'name' : 'csDateOfBirth',
              'contractInformationInputed' : this.contractInformationInputed,
              'isMultipleContracts' : this.isMultipleContracts
            }
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * display the field value population/confirmation modal
   */
  displaySignContract(editMode, signed = false) {
    // prepare contract template


    const isMobile = this.helper.isMobile();
    let lightboxClass  = 'form-lightbox-fullscreen contract-vfs form-lightbox-breadcrumbs document-upload-list display-flex not-closable';
    if (isMobile) {
      lightboxClass = 'form-lightbox-fullscreen contract-vfs '
                                + 'contract-vfs-mobile form-lightbox-breadcrumbs '
                                + 'document-upload-list not-closable form-lightbox-fullscreen-mobile';
    }
    this.formClass = '';
    this.formType = '';
    this.formTitle = '';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass =  {
      class: lightboxClass,
      showStepsMobile: isMobile,
      logoImage: this.logo,
      logoClass: "logo-height",
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray
    };
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0 ',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_new_verification',
      'result_details' : [
        {
        'group_result_title' : 'request_new_address_verification_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type' : 'documentVsSideVerification',
            'id' : 'activeDocument.type',
            'enabled': true,
            // 'isActive':  true,
            'editMode': editMode,
            'signedMode': signed,
            'class' : 'document-upload-no-list',
            'contractTemplate': (this.contractTemplate) ? this.sanitizer.bypassSecurityTrustHtml(this.contractTemplate.replace('/font-size: ([0-9]+)px;/', '')) : '',
            'contractTemplates': (this.contractTemplates)
                                              ? this.contractTemplates.map(t => this.sanitizer.bypassSecurityTrustHtml(t.replace('/font-size: ([0-9]+)px;/', '')))
                                              : '',
            'params':  {
              'formTitle': signed ? this.translate.instant('IDPCS.contract.title1') :(this.isInvestor || (this.isSigner && this.signedOn) ? this.translate.instant('IDPCS.contract.title2') : this.translate.instant('IDPCS.contract.previewDocument')) ,
              'evidenceKey': this.cryptoKey,
              'signedOn': this.signedOn,
              'isInvestor': this.isInvestor,
              'isSigner': this.isSigner,
              'firstName': this.firstName,
              'lastName': this.lastName,
              'dateOfBirth': this.dateOfBirth,
              'startDate': (this.dateOfBirth !== undefined) ? this.helper.getAsStartDate(this.dateOfBirth) : undefined,
              'reviewState': false,
              'minDate': this.helper.getAsStartDate('01.01.1915'),
              'maxDate': this.helper.getAdultAsMaxDate(),
              'today': this.helper.formatISODate( new Date().toISOString(), this.helper.getPdfFormatDate(), false ),
              'name': 'csDateOfBirth',
              'labelSignContract': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label1') : this.translate.instant('IDPCS.contract.label2'),
              'labelElectronicallySigning': (this.contractTemplates) ?
                    this.translate.instant('IDPCS.contract.label3') : this.translate.instant('IDPCS.contract.label4'),
              'labelPreparingDocument': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label5') : this.translate.instant('IDPCS.contract.label6'),
              'labelLegalySealing': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label7') : this.translate.instant('IDPCS.contract.label8'),
              'labelPleaseWait': (this.contractTemplates) ? this.translate.instant('IDPCS.contract.label9') : this.translate.instant('IDPCS.contract.label10'),
              'closeContractCounter': this.contractCloseCountInterval,
              'contractInformationInputed' : this.contractInformationInputed,
              'isMultipleContracts' : this.isMultipleContracts
            }
          }
        ]
      }]
    };

    if (!editMode && signed) {
      // this.countdownAndCloseContract();
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * display the email population modal
   */
   displayProvideEmail() {
    const isMobile = this.helper.isMobile();
    let lightboxClass  = 'form-lightbox-fullscreen contract-vfs form-lightbox-breadcrumbs document-upload-list display-flex not-closable';
    if (isMobile) {
      lightboxClass = 'form-lightbox-fullscreen contract-vfs '
                                + 'contract-vfs-mobile form-lightbox-breadcrumbs '
                                + 'document-upload-list not-closable form-lightbox-fullscreen-mobile';
    }
    this.formClass = '';
    this.formType = '';
    this.formTitle = '';
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.lightboxClass =  {
      class: lightboxClass,
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray
    };
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-4 ',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_new_verification',
      'result_details' : [
        {
        'group_result_title' : 'request_new_address_verification_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'title',
            'params': {
              'content': this.translate.instant('IDPCS.contract.label11')
            }
          },
          {
            'type': 'paragraph',
            'params': {
              'content': this.translate.instant('IDPCS.contract.label12'),
            }
          },
          {
            'type' : 'inputText',
            'class': 'reduced-margin-top',
            'length': '60',
            'params' : {
              'id': 'MID_NP_EMAIL',
              'name' : 'EmailAddressNP',
              'label' : this.translate.instant('IDPCS.contract.label13'),
              'labelClass': 'font-weight-bold',
              'patternMatch': emailPattern,
              'validatorMessage': this.translate.instant('validation.validation5'),
              },
          },
          {
            'type': 'button_group',
            'class' : 'justify-content-end d-flex mt-3',
            'params': [
              {
                'content': this.translate.instant('common.skip'),
                'action': 'OnSkipEmailAndSign',
                'class': 'form-button-link mr-2',
                'display': true
              },
              {
                'content': this.translate.instant('common.submit'),
                'action': 'OnProvideEmailAndSign',
                'class': 'form-button-1 mx-auto',
                'display': true
              },
            ]
          }
        ]
      }]
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  endSesion()
  {
    this.http.post(this.baseUrl
      + 'api/digital/verifications/'
      + this.verificationKey
      + '/contract/end', {}).subscribe(async response => {
        this.stateMessage = this.translate.instant('IDPCS.error');
      if(!response)
      {
        console.log("the session could not be stoped")
      }
      },
      error => {
        this.errorState = true;
        this.stateMessage = error.error.message;
        this.projectTextColorClass = 'light-color';
        this.projectColor = '#00889c';
        this.logo = 'assets/images/meshId_logo.svg';
        console.error('Error occured while fetching the access token', error);
      });
  }
  OnSkipEmailAndSign() {
    this.skipEmailSubmission = true;
    this.displaySignContract(false);
    const _ = this;
    setTimeout(function() {
      _.signContract();
    }, 100);
  }

  OnProvideEmailAndSign() {
    if (!this.verificationRecord['personData']) {
      this.verificationRecord['personData'] = {};
    }
    this.verificationRecord['personData']['MID_NP_EMAIL'] = $('#MID_NP_EMAIL').val();
    if (!this.verificationRecord['personData'].MID_NP_EMAIL) {
      alert(this.translate.instant('errors.error11'));
      return;
    }
    this.displaySignContract(false);
    const _ = this;
    setTimeout(function() {
      _.signContract();
    }, 100);
  }

  countdownAndCloseContract() {
    if (this.helper.isIOS()) {
      // if ios directly close
      this.onCloseContract(null, false);
    }

    let counter = this.contractCloseCountInterval;
    $('.close-contract-counter').text(`(${counter}s)`);
    const _this = this;
    const counterInterval = setInterval(function() {
      counter = counter - 1;
      if (counter > 0) {
        $('.close-contract-counter').text(`(${counter}s)`);
      } else {
        clearInterval(counterInterval);
        _this.onCloseContract(null, false);
      }
    }, 1000);
  }

  // HELPER FUNCTIONS FOR THE LIGHTBOX
  /**
   * handle click on lightbox confirm
   * @param event event
  */
  onLightboxConfirm(event) {
    if (typeof(event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params) || typeof(event.params) === 'object') {
        params = event.params;
      }
      if (typeof(event.params.name) === 'string') {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', 'sending params:', params, error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    // execute default action
    if (typeof(event.method) === 'undefined' ) { // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
    }
  }

  /**
   * handle click on lightbox close
   */
  onLightboxClose(event) {
    let classes = '';
    if ($(event.target).hasClass('close-form-button')) {
      classes =  $(event.target).attr('class');
    } else {
      classes =  $(event.target).closest('.close-form-button').attr('class');
    }
    let lightboxType = 'displayModal';
    if (classes && classes.includes('top-dialog-box')) {
      lightboxType = 'topDisplayModal';
    }
    if (classes && classes.includes('foremost-dialog-box')) {
      lightboxType = 'foremostDisplayModal';
    }
    this[lightboxType] = false;
  }

  confirmContractSigning() {
    this.contractFirstName = this.firstName = $('#csFirstName').val();
    this.contractLatName = this.lastName = $('#csLastName').val();
    this.contractDOB = this.dateOfBirth = $('#csDateOfBirth').find('input').val();
    this.contractInformationInputed = true;
    this.displaySignContract(false);
  }

  /**
   * called when user clicked EDIT INFORMATION button
   */
  onEditInformation() {
    this.contractInformationInputed = false;

    // recursively iterate through all components of components
    Object.entries(this.submissionData).map(entry => {
      this.parseComponents(this.formattedJson['components'], entry);
    });

    this.displaySignContract(true);
    this.previewFormio = true;
  }

  /**
   * recursive helper method to iterate through all components of a schema
   * @param components components of the current property of the schema object
   * @param entry key, value pair that will match the key of the component and populate it's value
   */
  private parseComponents(components, entry) {
    components.map(component => {
      if (component.key === entry[0]) { component.defaultValue = entry[1]; }
      if (component.components) {
        this.parseComponents(component.components, entry);
      }
    });
  }

  /**
   * executed when user clicks on Submit button of the form
   * @param submission parameters submitted by the form
   */
  onSubmit(submission) {
    this.previewFormio = false;
    this.displayLoadingPreviewContract();
    this.submissionData = submission.data;
    this.submissionData.constantData = this.constFormData;
    // let templateTemp  = this.originalContractTemplate;
    for (let [key, value] of Object.entries(this.submissionData)) {
      if (value) {
        if (this.checkIfFormioDate([key, value], this.formattedJson['components'])) {
          value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
          submission.data[key] = value;
        } else {
          // if not date parse it
          value = this.helper.parseBackendName(value.toString());
        }
        // we would no longer replace the values in memory
        // instead we will be using the html response from PDF server
        // templateTemp = templateTemp.split('{{' + key + '}}').join(value);
      }
    }
      const payload = this.compilePayload('html');
      this.pdf.generatePDF(
        this.verificationRecord.template
        , 'temp.html'
        , JSON.stringify(payload.jsonData)
        , ''
        , false
        , true
      )
      .then(response => {
        // check if json encoded response
        try {
          response = JSON.parse(response as any);
        } catch (e) {}
  
        if (Array.isArray(response)) {
          this.contractTemplate = '';
          this.contractTemplates = response.map(template => template[1]);
          this.isMultipleContracts = true;
        } else {
          this.contractTemplates = '';
          this.contractTemplate = response;
        }
        this.http.post(
          this.baseUrl + 'api/digital/verifications/' + this.verificationKey + '/contract/',  {'contractContent' : this.submissionData  }
          , { responseType: 'text', observe: 'body'}
        ).toPromise()
        .then(response => {
          this.displaySignContract(false);
          }
        )
        .catch(error => {
          this.errorState = true;
          this.stateMessage = error.error.message;
          this.projectTextColorClass = 'light-color';
          this.projectColor = '#00889c';
          this.logo = 'assets/images/meshId_logo.svg';
          console.error('Error occured while fetching the access token', error);
        });   
      }).catch();
  }

  checkIfFormioDate(field, components) {
    let ret = false;
    components.map(component => {
      if (field[0] === component.key && component.widget && component.type === 'datetime') {
        ret = true;
      } else if (component.components) {
        if (this.checkIfFormioDate(field, component.components)) {
          ret = true;
        }
      }
    });
    return ret;
  }

  checkIfEmailIsNeeded() {
    if ((!this.verificationRecord.personData || !this.verificationRecord.personData.MID_NP_EMAIL) && !this.skipEmailSubmission) {
      this.displayProvideEmail();
      return true;
    }
    return false;
  }

  async signContract() {
    if (this.checkIfEmailIsNeeded()) {
      return;
    }
    $('.visual-steps-overlay').addClass('d-flex');
    $('.form-button-1.evidence-submit-button').attr('disabled', 'true');
    this.contractInformationInputed = true;
    const extendedDomain = `${location.protocol}//` + window.location.hostname + ((!location.port) ? '' : `:${location.port}`);
    const vfeCode = (this.activeVerificationKey) ? this.activeVerificationKey.replace('VFE:', '') : '';
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;

    // generate the qrcode
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }

    // fetch the IP address info
    let ipAddress = null;
    try {
      await this.ipService.getIPAddress()
                          .then(result => ipAddress = result)
                          .catch(error => console.error('could not retrieve IP information', error));
    } catch (err) {
      console.error(err);
    }

    // compile the payload
    const payload = this.compilePayload('pdf', genQrCode, ipAddress);

    // add dummy visual animation 1
    setTimeout(function () {
        $('.visual-contract-step:first-child').removeClass('step-in-progress');
        $('.visual-contract-step:first-child').addClass('step-finished');
        $('.visual-contract-step:nth-child(2)').addClass('step-in-progress');
    }, 3000);

    // add dummy visual animation 2
    setTimeout(function () {
        $('.visual-contract-step:nth-child(2)').removeClass('step-in-progress');
        $('.visual-contract-step:nth-child(2)').addClass('step-finished');
        $('.visual-contract-step:nth-child(3)').addClass('step-in-progress');
    }, 6000);

    const _this = this;
    setTimeout(function () {
    _this.http.post(
                _this.baseUrl + 'api/digital/verifications/' + _this.verificationKey + '/contract/sign', payload
                , { responseType: 'text', observe: 'body'}
              )
              .toPromise()
              .then(
                async response => {
                  $('.visual-contract-step:nth-child(3)').removeClass('step-in-progress');
                  $('.visual-contract-step:nth-child(3)').addClass('step-finished');
                  try {
                    response = JSON.parse(response);
                  } catch (error) {}

                  if (Array.isArray(response)) {
                    const generatedFiles = await Promise.all(response.map(async file => {
                      return await _this.convertToFile(file.data);
                    }));
                    _this.contractBlob = generatedFiles;
                  }
                  setTimeout(function () {
                        $('.visual-steps-overlay').removeClass('d-flex');
                        _this.displaySignContract(false, true);
                        _this.endSesion();
                      }, 1500);

                  // await  _this.http.post(
                  //   _this.baseUrl + 'api/verifications/' + _this.verificationKey + '/contract/sign', ''
                  //   , { responseType: 'text', observe: 'body'}
                  // ).toPromise()
                  // .then(response => {
                  //   // this.contractBlob = 'data:application/pdf;base64,' + this.verificationRecord.contractTemplate;
                  //   console.log('response from complete call', response);
                  //   setTimeout(function () {
                  //     $('.visual-steps-overlay').removeClass('d-flex');
                  //     _this.displaySignContract(false, true);
                  //   }, 1500);
                  //   }
                  // )
                  // .catch(error => {
                  //   console.error('We could not complete the contract', error);
                  //   _this.errorState = true;
                  //   _this.stateMessage = this.translate.instant('IDPCS.contract.content1');
                  //    $('.visual-steps-overlay').removeClass('d-flex');
                  // });
                }
              )
              .catch(error => {
                console.error('We could not sign the contract', error);
                _this.errorState = true;
                _this.stateMessage = this.translate.instant('IDPCS.contract.content1');
                // for testing purposes until BE stabilizes
                // this.contractBlob = new Blob([this.previewPdf as Blob], { type: "application/pdf" });
                // this.errorState = false;
                 $('.visual-steps-overlay').removeClass('d-flex');
                // this.displaySignContract(false, true);
              });
     }, 9000);
  }

  convertToFile(file) {
    return new Promise(async (resolve, reject) => {
      const bufferArray = new Uint8Array(file);
      const blob = new Blob([bufferArray], { type: 'application/pdf' });
      const data = window.URL.createObjectURL(blob);
      resolve(data);
      // const newBlob = new Blob([file as Blob], { type: 'application/pdf' });
      // // IE doesn't allow using a blob object directly as link href
      // // instead it is necessary to use msSaveOrOpenBlob
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //     window.navigator.msSaveOrOpenBlob(newBlob);
      //     return;
      // }
      // // this.contractBlob = window.URL.createObjectURL(newBlob);
      // // possible solution to iOS download issue: this.contractBlobUrl = window.URL.createObjectURL(newBlob);
      // // android web browsers do not support blob in iframe
      // // convert it to base64
      // await this.idVerificationService.convertBlobToBase64Img(newBlob)
      //       .then(blobResponse => {
      //         // this.contractBlob =  blobResponse;
      //         console.log('this is the blob response', blobResponse);
      //         resolve (blobResponse);
      //       })
      //       .catch(error => {
      //         this.errorState = true; this.stateMessage = 'We could not download the signed contract!';
      //         console.error(error);
      //       });
    });
  }

  compilePayload(reportFileFormat: string, genQrCode?: string, ipAddress?: string) {
      this.submissionData.reportFileFormat = reportFileFormat; // request html version of the document
      this.submissionData.generatedOnText = 'Document signed on'; // set the generatedOn text
      this.submissionData.orgId = this.verificationRecord.organizationKey.replace('ORG:', '');
      let customOptions;
      customOptions = {
        topMargin: '110px'
      };
      if (this.verificationRecord.template === 'OGFII') {
        customOptions = {
          topMargin: '110px',
          headerTemplate: ' '
        };
      }
      return {
        jsonData: this.submissionData,
        otherData: {
          qrCode: (genQrCode) ? genQrCode : '',
          documentName: 'Contract',
          verificationKey: this.cryptoKey,
          customOptions: customOptions,
        },
      userData: {
        userAgent: this.helper.myFullBrowser(),
        ipAddress: (ipAddress) ? ipAddress : '',
      },
      htmlTemplateFile: 'signedContract',
      email: (reportFileFormat === 'pdf' && !this.skipEmailSubmission) ? this.verificationRecord['personData']['MID_NP_EMAIL'] : ''
    };
  }

  onDownloadContract() {
    this.onCloseContract(null, true);
  }

  onCloseContract($event, download = false) {
    if (download && !this.helper.isIOS()) {
      const link = document.createElement('a');
      if (Array.isArray(this.contractBlob)) {
        this.contractBlob.map(contract => {
          link.href = contract;
          link.target = '_blank';
          link.download = `${this.helper.getTodayDate()}-${this.cryptoKey.replace('VFE:', '')}-contract.pdf`;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        });
      } else {
        link.href = this.contractBlob;
        link.target = '_blank';
        link.download = `${this.helper.getTodayDate()}-${this.cryptoKey.replace('VFE:', '')}-contract.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      }
    }
    // redirect to PCS and let it figure out the next step
    this.displayLoader = true;
    const _this = this;
    setTimeout(function () { window.location.href = '/investor/dashboard/' + _this.investorProjectId; }, 100);
  }

  /**
   * iterate trough the schema and add default value if any backend data matches a schema key
   * @param personData prefill data from the BE
   * @param schemaData the schema as we retreive it from the PDF server
   */
  prefillBEData(personData, schemaData) {
    // iterate through the person data
    for (let [key, value] of Object.entries(personData)) {
      if (key === 'dateOfBirth') {
        value = this.helper.formatISODate(value.toString(), 'yyy.MM.dd', false);
      }
      if (typeof value === 'string') {
        value = this.helper.capitalize(value as string);
      }
      // recursively iterate through all components of components
      this.prefillComponents(schemaData.components, [key, value]);
    }
    return schemaData;
  }

  // prefill components with gathered data from previous steps
  private prefillComponents(components, entry) {
    components.map(component => {
      if (component.key === entry[0]) { component.defaultValue = entry[1]; }
      if (component.properties && component.properties.populatedBy)  {
        const populateByArr = component.properties.populatedBy.split('+');
        if (populateByArr.includes(entry[0])) {
          if (component.multiple) {
            component.defaultValue += ` ${[entry[1]]}`;
          } else if (component.properties.valueKey) {
            component.defaultValue = [entry[1][component.properties.valueKey]][0];
          } else {
            component.defaultValue = [entry[1]];
          }
        }
      }
      // if address
      // WARNING: this is not working/the issue is with Formio
      // if (component.type === 'address' && entry[0] === 'addressData')  {
      //   // normalize address for formio:
      //   component.defaultValue.mode = 'manual';
      //   component.defaultValue.address = {
      //     address1: entry[1].Address,
      //     address2: '',
      //     city: entry[1].City,
      //     state: (entry[1].State) ? entry[1].State : '',
      //     country: entry[1].Country,
      //     zip: entry[1].ZipCode,
      //   };
      // }
      if (component.components) {
        this.prefillComponents(component.components, entry);
      }
    });
  }

  redirectToInvestor() {
    this.router.navigate(['/investor/dashboard/' + this.investorProjectId]);
  }



  /**
   * handle onDestroy
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
