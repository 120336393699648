<header class="d-flex flex-column p-2 p-sm-3 bg-none d-flex flex-column ic_height_full_page p-0 " >
    <div class="d-flex flex-column flex-fill justify-content-center error-msg-wrapper">
      <div class="header-top mx-auto">

      </div>
      <div style="" class="text-center mx-auto pb-5 mb-5 error-msg" [ngClass]="">
        {{message}}
      </div>
         <div class="unauth-message-small text-center mx-auto mt-3" [innerHTML]="('IDPCS.idVerification.error' | translate : { 'errorCodeVar' : errorCodeVar })"  *ngIf='errorCodeVar' [ngClass]="projectTextColorClass">

        </div>
    </div>
    <div class="load-overlay d-flex position-fixed w-100 h-100" *ngIf="displayLoader">
      <img src="/assets/images/Spinner-1s-200px-tealish.svg" width="100px" height="100px" class="my-auto mx-auto">
    </div>
</header>
