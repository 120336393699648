<div class="verification-warning">
  <div class="verification-warning-wrapper">
    <div class="context">
      <img class="image" src="../../../assets/images/request_screening.svg">
      <h3>{{'IDPCS.warning.title' | translate}}</h3>
      <p class="paragraph">
        {{'IDPCS.warning.paragraf1' | translate}}
      </p>
      <p class="paragraph">
        {{'IDPCS.warning.paragraf2' | translate}}
      </p>

      <div class="buttons">
        <button class="form-button form-button-1" (click)="startIdVerification()">
          {{'IDPCS.warning.verifyButton' | translate}} </button>
        <button class="form-button form-button-grey" (click)="closeIdVerification()">
          {{'common.close' | translate}} </button>
      </div>
 
    </div>
  </div>
</div>