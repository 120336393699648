import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { UserService } from '../user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private auth: AuthService, private userService: UserService) {}

    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      if (this.auth.returnSAuthentication()) {
        return true;
      }
      if (!this.auth.isAuthenticated$) {
        this.auth.login(state.url);
        return false;
      }
      return this.auth.isAuthenticated$.pipe(
        tap(loggedIn => {
          if (!loggedIn) {
            this.auth.login(state.url);
          } else {
            if (next.data.noRedirect !== undefined) {
              this.userService.auth(false);
            } else {
              this.userService.auth();
            }
          }
        })
      );
    }

}
