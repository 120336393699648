<div class="rp-index-wrapper"><h6 class="rp-index"> {{mainTitle}} </h6></div>
<div class="rp-profile-t type-of-profile d-flex ">
  <a *ngIf="legalRPOptionAllowed"  href="#" (click)="onSelectType($event, 'legal')"
  [ngClass]="{'active': selectedProfile.type==='legal'}"
  class="d-flex mr-3 align-items-center ">
    <img src="assets/images/ico-legal-person-gray.svg"  />
    <span >{{'appSpecific.LegalPerson' | translate}}</span>
  </a>
  <a *ngIf="naturalRPOptionAllowed" href="#" (click)="onSelectType($event, 'natural')"
  [ngClass]="{'active': selectedProfile.type==='natural'}"
  class="d-flex   align-items-center">
    <img src="assets/images/ico-natural-small.svg"  />
    <span >{{'appSpecific.NaturalPerson' | translate}}</span>
  </a>
</div>
<div class="error-field no-selected-error" *ngIf="noSelectedTypeError">
  {{'ARPCS.content7' | translate}}
</div>

<div class="legalFields text-left" *ngIf="selectedProfile.type==='legal'">
  <div class="first-last-name-wrapper">
    <div class="element-wrapper entity-name-type">
      <span class="input-label {{element.params.labelClass}}"> {{'ARPCS.label' | translate}}:<sup class="sup-required">*</sup></span>
      <!-- Input Text -->
      <!-- [attr.enterkeyaction] = 'element.params.onenterkeyaction' -->
      <input
          type="text"
          class="text-input"
          id="rp_profileLegalName"
          [attr.isValid] = 'true'
          [attr.isRequired] = 'true'
      />

      <div class="error-field"
          [attr.validatorMessage] = 'element.params.validatorMessage'
          [attr.validatorMessageField] = 'element.params.validatorMessage'>
          *{{'ARPCS.validation' | translate}}
      </div>
    </div>
    <div class="element-wrapper entity-name-type" [ngClass]="(element.class) ? element.class : ''">
      <span class="input-label {{(element.params.labelClass) ? element.params.labelClass : ''}}">{{'ARPCS.label2' | translate}}:<sup class="sup-required">*</sup></span>
      <span class="select-wrapper">
        <select class="select-input input-transparent"
          id="rp_profileLegalEntityType"
          (change)="OnChangeSelect($event)"
          [attr.changemethod]='element.params.changemethod'
          [attr.isRequired]='element.params.required'
          [value]="(element.params.optionselected) ? element.params.optionselected : -1">
          <option [selected] = "element.params.legalEntityTypesOptionSelected === option.key" value="{{option.key}}" *ngFor="let option of element.params.legalEntityRPTypes">{{option.name}}</option>
        </select>
      </span>
      <div class="error-field"
        [attr.validatorMessage] = 'element.params.validatorMessage'
        [attr.validatorMessageField] = 'element.params.validatorMessage'>
        *{{'ARPCS.validation2' | translate}}
      </div>
    </div>
  </div>
  <div class="element-wrapper" [ngClass]="(hideRelatedPartyRoles) ? 'invisible-rp-list' : ''">
    <span class="input-label {{(element.params.labelClass) ? element.params.labelClass : ''}}">{{'common.role' | translate}}: <sup class="sup-required">*</sup></span>
    <span class="select-wrapper">
      <select class="select-input input-transparent"
        id="rp_LegalRole"
        style="width: 48.79%"
        (change)="OnChangeSelectLegalRole($event)"
        [attr.changemethod]='element.params.changemethod'
        [attr.isRequired]='element.params.required'
        [value]="(element.params.optionselected) ? element.params.optionselected : -1">
        <option [selected] = "element.params.relatedPartyRoleSelected === option.key"
          value="{{option.key}}" *ngFor="let option of relatedPartyRoles">{{option.name}}</option>
      </select>
    </span>
    <div class="error-field"
      [attr.validatorMessage] = 'element.params.validatorMessage'
      [attr.validatorMessageField] = 'element.params.validatorMessage'>
      *{{'ARPCS.validation5' | translate}}
    </div>
  </div>
</div>

<div class="naturalFields text-left" *ngIf="selectedProfile.type==='natural'">
  <div class="first-last-name-wrapper">
    <div class="element-wrapper first-last-name">
      <span class="input-label {{element.params.labelClass}}">{{'appSpecific.FirstName' | translate}}<sup class="sup-required">*</sup></span>
      <!-- Input Text -->
      <input
          type="text"
          class="text-input"
          id="rp_profileNaturalFirstName"
          [attr.isValid] = 'true'
          [attr.isRequired] = 'true'
       />
      <div class="error-field"
          [attr.validatorMessage] = 'element.params.validatorMessage'
          [attr.validatorMessageField] = 'element.params.validatorMessage'>
          *{{'ARPCS.validation3' | translate}}
      </div>
    </div>
    <div class="element-wrapper first-last-name">
      <span class="input-label {{element.params.labelClass}}">{{'appSpecific.LastName' | translate}}<sup class="sup-required">*</sup></span>
      <!-- Input Text -->
      <input
          type="text"
          class="text-input"
          id="rp_profileNaturalLastName"
          [attr.isValid] = 'true'
          [attr.isRequired] = 'true'
       />
      <div class="error-field"
          [attr.validatorMessage] = 'element.params.validatorMessage'
          [attr.validatorMessageField] = 'element.params.validatorMessage'>
          *{{'ARPCS.validation4' | translate}}
      </div>
    </div>
  </div>
  <div class="element-wrapper is-minor">
    <input class="custom-checkbox"
      type="checkbox"
      id="rp_profileNaturalMinor"
      name="rp_profileNaturalMinorN">
    <label class="custom-checkbox-label"
      for="rp_profileNaturalMinor">{{'ARPCS.content11' | translate}}</label>
  </div>
  <div class="element-wrapper role"  [ngClass]="(hideRelatedPartyRoles) ? 'invisible-rp-list' : ''">
    <span class="input-label {{(element.params.labelClass) ? element.params.labelClass : ''}}">{{'common.role' | translate}}: <sup class="sup-required">*</sup></span>
    <span class="select-wrapper">
      <select class="select-input input-transparent"
        id="rp_naturalRole"
        style="width: 48.79%"
        (change)="OnChangeSelectNaturalRole($event)"
        [attr.changemethod]='element.params.changemethod'
        [attr.isRequired]='element.params.required'
        [value]="(element.params.optionselected) ? element.params.optionselected : -1">
        <option [selected] = "element.params.relatedPartyRoleSelected === option.key"
          value="{{option.key}}" *ngFor="let option of relatedPartyRoles">{{option.name}}</option>
      </select>
    </span>
    <div class="error-field"
      [attr.validatorMessage] = 'element.params.validatorMessage'
      [attr.validatorMessageField] = 'element.params.validatorMessage'>
      *{{'ARPCS.validation5' | translate}}
    </div>
  </div>
</div>

<div class="element-wrapper buttons">
  <span class="wrapper d-flex flex-row">
    <span class="ml-0 mr-auto d-flex">
      <button  *ngIf="!hideCancelButton" (click)='cancelRP($event)' [attr.clickmethod]="element.params.submitMethod" class="form-button form-button-link-2 cancel pr-3">
        {{ alternateCancelText ? alternateCancelText : ('common.cancel' | translate) }} </button>
      <button (click)='processProfileInfo($event)' [attr.clickmethod]="element.params.submitMethod" class="form-button form-button-link-2 save mt-0">
        {{'common.save' | translate }} </button>
    </span>
  </span>
</div>
