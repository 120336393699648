import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { of, Subject, Subscription } from 'rxjs';
import { HelperServiceService } from 'src/app/helper-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/user.service';
import { takeUntil } from 'rxjs/operators';
import { param } from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { trigger } from '@angular/animations';

declare var $: any;

@Component({
  selector: 'app-header-project-manager',
  templateUrl: './header-project-manager.component.html',
  styleUrls: ['./header-project-manager.component.scss']
})
export class HeaderProjectManagerComponent implements OnInit, OnDestroy {

  createProjectForm: {};
  activeProject: {};
  activeProjectList: any[];
  activeProjectSelectGroup: any[] = [];
  displayProjectSelector: boolean;

  @Output() DisplayLightbox = new EventEmitter<any>();
  @Output() closeLightbox = new EventEmitter();

  // subscriptions
  activeProjectsStatus: Subscription;
  activeProjectStatus: Subscription;
  informationRequestStep: Subscription;
  routeParamStatus: Subscription;
  parseInvestorsStatus: Subscription;
  unsubscribe$: Subject<void> = new Subject<void>();
  permissionIDIN: boolean;
  showCreateProfileButton;

  @Input() headerInfo: {};

  constructor(private helper: HelperServiceService,
    private idVerificationService: IdVerificationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.loadCreateProjectForm();
    if (this.router.url.includes('id-verification') || this.router.url.includes('funds') || this.router.url.includes('investor')) {
      this.routeParamStatus = new Subscription();
      this.routeParamStatus.add(this.route.params.subscribe(params => {
        const urlProjectId = params['projectId'];
        if (urlProjectId !== undefined && urlProjectId !== '') {
          $('.id-verification-container').attr('style', 'visibility:hidden !important');
        }
      }));
    }
    this.activeProject = this.idVerificationService.getActiveProjectDetails();
    this.activeProjectList = this.idVerificationService.getActiveProjects();
    this.permissionIDIN = this.idVerificationService.permissionIDIN();

    this.activeProjectsStatus = this.idVerificationService.detectActiveProjectsStatus.subscribe(() => {
      // a change in Active Project list is detected so reload it from the service
      this.activeProjectList = this.idVerificationService.getActiveProjects();
    });

    this.parseInvestorsStatus = this.idVerificationService.detectActiveInvestorsStatus.subscribe(response => {
      $('.new-person-funds').removeClass('d-none');
      if ((this.idVerificationService.getActiveInvestors() &&
        this.idVerificationService.getActiveInvestors().length === 0 &&
        !this.idVerificationService.getActiveLegalrepresentativesFilters().showFilters ? 'true' : undefined)) {
        $('.new-person-funds').addClass('d-none');
      }
    });


    this.activeProjectStatus = this.idVerificationService.detectActiveProjectStatus.subscribe(() => {
      this.activeProject = this.idVerificationService.getActiveProjectDetails();
      // console.log('ACTIVE PROJECT CHANGE DETECTED', this.activeProject);
      console.log('ACTIVE PROJECT CHANGE DETECTED');
      this.idVerificationService.resetActiveInvestor();

    });
    if (true) {
      this.informationRequestStep = this.idVerificationService.detectInformationRequestStepStatus.subscribe((response) => {
        // need to change to switch at some point
        if (response.step === -1) {
          // this will close the preview window and reload the current investor
          $('#lightBoxCloseImage3').click();
        } else if (response.step === 0) {
          if (response.response && response.response.confirm) {
            $('#lightBoxCloseImage').click();
          } else {
            $('#lightBoxCloseImage2').click();
          }
        } else if (response.step === 5) {
          // reset local creation data
          this.idVerificationService.localFundCreationData = {};
          // "access:company-search"
          if (response.response.type === 'NaturalPerson' || !this.idVerificationService.permissionKompany()
            || response.response.selectedTemplate) {
            this.onNewInvestorStepTWoManual(response.response);
          } else {
            this.onNewInvestorStepTWoKompany(response.response);
          }
        } else if (response.step === 5.1) {
          this.onNewInvestorStepTWoManual(response.response);
        } else if (response.step === 5.2) {
          console.log(response);
          this.onNewInvestorStepTWoKompany(response.response);
        } else if (response.step === 5.3) {
          this.onNewInvestorStepTWoManual(response.response);
        } else if (response.step === 8) {
          this.onNewInvestor(response.response);
        } else if (response.step === 6) {
          this.onUploadDocumentStepOne(response.response);
        } else if (response.step === 7) {
          this.onUploadDocumentStepTwo(response.response);
        } else if (response.step === 10) {
          this.onEvidenceShare(response.response);
        } else if (response.step === 11) {
          this.onCreateRelatedParty(response.response);
        } else if (response.step === 12) {
          this.idVerificationService.localFundCreationData = {};
          this.idVerificationService.localFundCreationData.relatedPartyCreation = true;
          this.idVerificationService.localFundCreationData.relatedPartyRole = response.response.relatedPartyRole;
          // "access:company-search"
          if (response.response.relatedPartyType === 'NaturalPerson' || !this.idVerificationService.permissionKompany()) {
            this.onCreateRelatedPartyTwoManual(response.response);
          } else {
            this.onCreateRelatedPartyTwoKompany(response.response);
          }
        } else if (response.step === 12.1) {
          this.onCreateRelatedPartyTwoKompany(response.response);
        } else if (response.step === 12.2) {
          this.onCreateRelatedPartyTwoManual(response.response);
        } else if (response.step === 12.3) {
          this.onCreateRelatedPartyTwoManual(
            { relatedPartyRole: this.idVerificationService.localFundCreationData.relatedPartyRole, relatedPartyType: 'LegalPerson' });
        } else if (response.step === 13) {
          this.onEvidencePreview(response.response);
        } else if (response.step === 14) {
          // this.onDocumentVersionChange(response.response);
        } else if (response.step === 15) {
          this.onEvidenceReview(response.response);
        } else if (response.step === 16) {
          this.onUpdateInvestor(response.response);
        } else if (response.step === 17) {
          this.OnAddNewPerson(undefined, response.response);
        } else if (response.step === 18) {
          this.deleteRelatedParty(undefined, response.response);
        } else if (response.step === 19) {
          this.createAutomaticRequest();
        } else if (response.step === 20) {
          this.createAutomaticRequestShare(response.response);
        } else if (response.step === 21) {
          this.openTemplateConfigScreen(response.response);
        } else if (response.step === 22) {
          this.openTemplateScreen(response.response);
        } else if (response.step === 23) {
          this.deleteProfile(response.response);
        }
      });
    }
  }

  /**
   * creates a select group from active projects, for now used in select list
   */
  createActiveProjectSelectGroup() {
    for (let index = 0; index < this.activeProjectList.length; index++) {
      this.activeProjectSelectGroup.push({ 'name': this.activeProjectList[index].record.name, 'key': this.activeProjectList[index].key });
    }
  }

  /**
   * displays embeded project form only if
   * there are no active projects for this user
   */
  loadCreateProjectForm() {
    if (this.activeProjectList === undefined || this.activeProjectList.length === 0) {
      this.createProjectForm = [
        {
          'type': 'inputText',
          'params': {
            'label': (this.headerInfo['projectType'] === 'Fund') ? 'Project name' : 'Project name',
            'id': 'embed-create-project-name',
            'name': 'embedCreateProjectName',
            'onenterkeyaction': 'OnEmbedCreateProject',
            'content': '',
            'required': 'true'
          }
        },
        {
          'type': 'button',
          'params': {
            'content': (this.headerInfo['projectType'] === 'Fund') ? 'Create ' : 'Create ',
            'display': true,
            'action': 'OnEmbedCreateProject',
            'type': 'submit'
          }
        },
      ];
    }
  }

  /**
   * create new project directly from the embeded form
   * @param params: event
   */
  OnEmbedCreateProject(params) {
    if ($('#embed-create-project-name').val() === '') {
      alert('You must enter a project name!');
      return;
    }
    this.idVerificationService.createProject($('#embed-create-project-name').val());
  }

  /**
   * Display the lightbox with list of active projects
   * @param event event
   */
  OnSelectFromProjectList(event) {
    event.preventDefault();
    // $('.ic-ctas.new-person-wrapper').removeClass('mt-4').addClass('mt-5');
    // $('.projects-content-wrapper').removeClass('collapse');
    // this.idVerificationService.resetActivePersons();
    // this.idVerificationService.resetActivePerson();
    this.idVerificationService.displayProjectSelector(true);
  }

  resetRepresentativesTags() {
    this.idVerificationService.setActiveLegalRepresentativesUnadedTags(undefined);
  }
  /**
   * On add new person
   * @param event event
   */
  OnAddNewPerson(event?, savedData?) {
    if (event) {
      event.preventDefault();
    }
    let firstNameOfPerson;
    let lastNameOfPerson;
    let emailOfPerson;
    let dobOfPerson;
    let tagInputValue;
    let nohideClass = false;
    let internalReference;
    if (savedData) {
      firstNameOfPerson = savedData.firstNameOfPerson;
      lastNameOfPerson = savedData.lastNameOfPerson;
      emailOfPerson = savedData.emailOfPerson;
      dobOfPerson = savedData.dobOfPerson !== '' ? savedData.dobOfPerson : undefined;
      tagInputValue = savedData.tagInputValue;
      nohideClass = savedData.nohideClass;
      internalReference = savedData.internalReferenceOfPerson;

    }

    const params = {
      'formType': 'Create a new person',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'formClass': '',
      'lightboxClass': {
        'noScroll': true
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'create_new_representative',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'inputText',
                'params': {
                  'label': 'First name',
                  'id': 'lb-representative-fname',
                  'name': 'lbRepresentativeName',
                  'required': true,
                  'content': firstNameOfPerson,
                  'maxLength': 100,
                }
              },
              {
                'type': 'inputText',
                'params': {
                  'label': 'Last name',
                  'id': 'lb-representative-lname',
                  'name': 'lbRepresentativeName',
                  'required': true,
                  'content': lastNameOfPerson,
                  'maxLength': 100,
                }
              },
              {
                'type': 'inputText',
                'class': !nohideClass ? 'tempHideInput' : '',
                'params': {
                  'label': 'E-mail',
                  'id': 'lb-representative-email',
                  'name': 'lbRepresentativeEmail',
                  'patternMatch': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'validatorMessage': this.translate.instant('validation.validation5'),
                  'content': emailOfPerson,
                  'required': false,
                }
              },
              {
                'type': 'input_date',
                'class': !nohideClass ? 'tempHideInput' : '',
                'params': {
                  'label': 'Date of birth',
                  'id': 'lb-representative-dob',
                  'name': 'lbRepresentativeDob',
                  'minDate': this.helper.getAsStartDate('01.01.1915'),
                  'maxDate': this.helper.getAdultAsMaxDate(),
                  'required': false,
                  'content': this.helper.getAsStartDate(dobOfPerson),
                }
              },
              {
                'type': 'inputText',
                'class': !nohideClass ? 'tempHideInput' : '',
                'params': {
                  'label': 'Internal reference',
                  'id': 'lb-internal-reference',
                  'name': 'lbInternalReference',
                  'content': internalReference,
                  'required': false,
                  'maxLength': 100,
                }
              },
              {
                'type': 'inputText',
                'class': !nohideClass ? 'mt-4 tempHideInput' : 'mt-4',
                'params': {
                  'label': 'Type the tags that you want to add below. You can add multiple tags separated by commas:',
                  'id': 'addingTagInputText',
                  'name': 'addingTagInputText',
                  'onEnterChangeAction': 'checkForNewTag',
                  'content': tagInputValue,
                  'validatorMessage': 'At least one tag is required',
                  'required': false,

                }
              },
              {
                'type': 'person_tags',
                'class': !nohideClass ? 'mt-0 tempHideInput' : 'mt-0',
                'params': {
                  'unaddedTags': this.idVerificationService.getActiveLegalRepresentativesUnadedTags(),
                  'showAddButton': false,
                  'showTitle': false,
                  'suggestedTags': this.helper.filterSuggestedTags(this.idVerificationService.getActiveLegalRepresentativesTopTags(),
                    undefined, this.idVerificationService.getActiveLegalRepresentativesUnadedTags()),
                  'onAddPerson': true,
                  'allowDelete': true,
                }
              },
              {
                'type': 'show_or_hide_details',
                'class': nohideClass ? 'd-none' : '',
                'method': 'showMoreDetails',
              },
              {
                'type': 'button',
                'params': {
                  'content': 'Save',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnSaveNewRepresentative'
                }
              },
            ]
          }]
      },
    };
    if (nohideClass && savedData) {
      setTimeout(function () {
        $('#addingTagInputText').focus();
      }, 250);
    }

    this.DisplayLightbox.emit(params);
  }

  deleteRelatedParty(event, argInfo?) {
    const params = {
      'formType': 'Delete related profile',
      'formClass': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': '',
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'edit_new_representative',
        'result_details': [
          {
            'group_result_title': 'edit_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'paragraph',
                'class': ' mt-2 result-paragraph',
                'params': {
                  'paragraphClass': 'font-weight-bold',
                  'content':
                    'You are about to delete the related profile, and cancel all active verifications. All personal data will be lost. Are you sure that you want to continue?'
                }
              },
              {
                'type': 'button_group',
                'params': [
                  {
                    'content': 'Yes',
                    // 'id': 'accept',
                    'action': 'onDeleteRPAccept',
                    'class': 'form-button form-button-red',
                    'display': true
                  },
                  {
                    'content': 'No',
                    // 'id': 'rescan',
                    'action': 'onDeleteRPReject',
                    'class': 'form-button-1',
                    'display': true // TODO: check if user may edit the information
                  }
                ]
              }
            ]
          }]
      }
    };

    this.DisplayLightbox.emit(params);
  }

  deleteProfile(event, argInfo?) {
    const params = {
      'formType': 'Delete profile',
      'formClass': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': '',
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'edit_new_representative',
        'result_details': [
          {
            'group_result_title': 'edit_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'paragraph',
                'class': ' mt-2 result-paragraph',
                'paragraphClass': 'font-weight-bold mt-1 text-center',
                'params': {
                  'content':
                    'You are about to delete the profile, and cancel all active verifications. All personal data will be lost. Are you sure that you want to continue?'
                }
              },
              {
                'type': 'button_group',
                'params': [
                  {
                    'content': 'Yes',
                    // 'id': 'accept',
                    'action': 'onDeletePAccept',
                    'class': 'form-button form-button-red',
                    'display': true
                  },
                  {
                    'content': 'No',
                    // 'id': 'rescan',
                    'action': 'onDeleteRPReject',
                    'class': 'form-button-1',
                    'display': true // TODO: check if user may edit the information
                  }
                ]
              }
            ]
          }]
      }
    };

    this.DisplayLightbox.emit(params);
  }

  createAutomaticRequest() {
    let params;

    // const params = {
    //   'formType': '',
    //   'formTitle': '',
    //   'btnOkText': '',
    //   'formValidator': '',
    //   'btnCancelText': '',
    //   'lightboxClass': {
    //     class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
    //     greyVersion: true,
    //     stepsTitle: 'Create profile',
    //     stepsTitleClass: 'no-left',
    //     showDefaultLogo: true,
    //     stepsSubtitle: '',
    //     steps: [
    //       { name: 'Type of profile', count: 1, class: 'form-step-active' },
    //       { name: 'Profile details', count: 2 },
    //     ],
    //     routeManipulation: true,
    //   },

    params = {
      'formType': '',
      'formClass': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'Create profile',
        stepsTitleClass: 'no-left',
        showDefaultLogo: true,
        stepsSubtitle: '',
        steps: [
          { name: 'Choose template', count: 1, class: 'form-step-active' },
          { name: 'Share link', count: 2 },
        ],
        routeManipulation: true,
      },
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'edit_new_representative',
        'result_details': [
          {
            'group_result_title': 'edit_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'inputText',
                'class': 'reduced-margin-top',
                'length': '60',
                'params': {
                  'id': 'AREmail',
                  'name': 'AREmailN',
                  'label': 'Email address',
                  'labelClass': 'font-weight-bold',
                  'patternMatch': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'validatorMessage': this.translate.instant('validation.validation5'),
                  'required': true,
                },
              }
            ]
          }]
      }
    };

    const projectTemplates = this.idVerificationService.getfundsOrganizationTemplates();
    // if project templates exist we need to add the option to choose a template
    if (projectTemplates !== undefined) {
      // we need to build the object which will hold the list values
      const templateValues = [];
      projectTemplates.forEach(template => {
        if (template.record.published) {
          templateValues.push(
            {
              'key': template.key,
              'name': template.record.templateName
            }
          );
        }

      });
      params.group.result_details[0].elements.push(
        {
          'type': 'select',
          'class': 'reduced-margin-top input-transparent',
          'length': '60',
          'params': {
            'labelClass': 'font-weight-bold',
            'id': 'ARTemplate',
            'name': 'ARTemplateN',
            'label': 'Choose template',
            'options': templateValues,
          },
        },
        {
          'type': 'checkbox',
          'class': 'reduced-margin-top skip',
          'params': {
            'id': 'allowReuse',
            'name': 'allowReuse',
            'label': 'Allow the recipient to re-use information from an existing Mesh ID KYC profile',
            'checked': this.idVerificationService.getAllowReuseProfile(),
            'onclick': 'updateAllowReuse',
            'disabled': false,
          }
        },
      );
    }
    params.group.result_details[0].elements.push(
      {
        'type': 'button_group',
        'hideErrorField': true,
        'params': [
          {
            'content': 'Create new template',
            // 'id': 'accept',
            'action': 'htpmOnCreateTemplate',
            'class': 'form-button form-button-link-2 px-0',
            'display': true
          },
          {
            'content': 'View and edit chosen template',
            // 'id': 'rescan',
            'action': 'htpmOnEditTemplate',
            'class': 'form-button form-button-link-2 px-0',
            'display': true // TODO: check if user may edit the information
          }
        ]
      }
    );

    params.group.result_details[0].elements.push(
      {
        'type': 'button_group',
        'params': [
          {
            'class': 'form-button-6 pr-2 pl-0',
            'content': 'Add profile details',
            'display': true,
            'action': 'htpmOnNewInvestorEndAutomatic',
            'type': 'submit'
          },
          {
            'content': 'Get share link',
            'display': true,
            'action': 'htpmOnNewInvestorEndAutomaticSkipDetails',
            'type': 'submit'
          }

        ]
      }
    );

    this.DisplayLightbox.emit(params);
  }

  createAutomaticRequestShare(response) {
    let params;
    let shareUrl = response.shareUrl;
    let email = response.email;
    let disableAllowReuse = false;
    if (!shareUrl && !email) {
      let investorDetails = this.idVerificationService.getActiveInvestorDetaisl();
      if (investorDetails.record) {
        investorDetails = investorDetails.record;
      disableAllowReuse = investorDetails.record.convertedOn || investorDetails.record.claimedOn ? true : false

      }
      shareUrl = investorDetails.shareLink;
      email = investorDetails.investorFields.EmailAddress;
    }
    let steps =
      [
        { name: 'Choose template', count: 1 },
        { name: 'Share link', count: 2, class: 'form-step-active' },
      ];
    if (response.additionalDetails) {
      steps = [
        { name: 'Choose template', count: 1, },
        { name: 'Type of profile', count: 2, },
        { name: 'Profile details', count: 3, },
        { name: 'Share link', count: 4, class: 'form-step-active' }
      ]
    }
    params = {
      'formType': '',
      'formClass': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'Create profile',
        stepsTitleClass: 'no-left',
        showDefaultLogo: true,
        stepsSubtitle: '',
        steps: steps,
        routeManipulation: true,
      },
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'edit_new_representative',
        'result_details': [
          {
            'group_result_title': 'edit_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'image',
                'params': {
                  'src': '/assets/images/form_image_example.png',
                  'class': 'request-share-img-2'
                }
              },
              {
                'type': 'h3',
                'class': 'pb-2',
                'params': {
                  'content': 'Automatically request information'
                }
              },
              {
                'type': 'numberedList',
                'params': {
                  'entries': [
                    {
                      'title': 'Send share link',
                      'text': 'Send the share link to the recipient by email (press send or copy and send it yourself)', 'count': '1'
                    },
                    {
                      'title': 'Person defines their structure',
                      'text': 'Based on their structure, information is requested as per your chosen KYC template', 'count': '2'
                    },
                    {
                      'title': 'Person fulfills request',
                      'text': 'The recipient will be guided through the process of providing information', 'count': '3'
                    }
                  ]
                }
              },
              {
                'type': 'checkbox',
                'class': 'reduced-margin-top skip',
                'params': {
                  'id': 'allowReuse',
                  'name': 'allowReuse',
                  'label': 'Allow the recipient to re-use information from an existing Mesh ID KYC profile',
                  'checked': this.idVerificationService.getAllowReuseProfile(),
                  'onclick': 'updateAllowReuse',
                  'disabled': disableAllowReuse,
                }
              },
              {
                'type': 'inputTextWithButton',
                'class': 'reduced-margin-top ',
                'params': {
                  'id': 'generatedVerificationLink',
                  'content': shareUrl,
                  'label': 'Please send the below share link to the recipient',
                  'classes': 'generated-link generated-link-fund-share',
                  'readonly': true,
                  'button': {
                    'display': true,
                    'content': 'Copy',
                    'class': 'ml-2 mr-0 form-button-1',
                    'action': 'CopyLinkToClipboard'
                  }
                }
              },
              {
                'type': 'inputTextWithButton',
                'class': 'mt-3 text-with-button-dash',
                'params': {
                  'id': 'sendVerificationLink',
                  'content': email,
                  'label': 'Email the share link now',
                  'classes': 'generated-link generated-link-fund-share',
                  'errorMessage': this.translate.instant('validation.validation5'),
                  'placeholder': 'Type in an email address',
                  'readonly': true,
                  'requestKey': this.idVerificationService.getActiveInvestor(),
                  'button': {
                    'display': true,
                    'content': 'Send',
                    'class': 'ml-2 mr-0 form-button-1',
                    'action': 'sendVerificationLink'
                  }
                }
              },
              {
                'type': 'button_group',
                'clas': 'd-flex justify-content-center',
                'displayFlex': true,
                'params': [
                  {
                    'type': 'button',
                    'class': 'mb-3 form-button-6 pl-0',
                    'content': 'Back to the dashboard',
                    'display': true,
                    'action': 'backtodashboard'
                  },
                  {
                    'type': 'button',
                    'class': 'form-button form-button-link-2 ml-0 pr-0',
                    'content': 'Generate link again',
                    'display': true,
                    'action': 'generateARLinkAgain'
                  }
                ]
              },
            ]
          }]
      }
    };
    this.DisplayLightbox.emit(params);
  }

  openTemplateScreen(data) {

    const documentBuildList = this.buildTemplateRPStructure(data);
    // we would need to get and parse existingEvidenceList from the localTemplateData
    let requestType = 'LegalPerson';
    const relatedPartyTypeList = [...this.idVerificationService.getRelatedPartyRoles()];
    // if the type is natural person or it belongs to the NP roles
    const isNPRole = relatedPartyTypeList.find(role => role.key === data.activeEdit.type);
    // SMENI requestType
    if (data.activeEdit.type === 'NaturalPerson' || data.activeEdit.type === 'NaturalPersonMinor' || isNPRole) {
      requestType = 'NaturalPerson';
    }
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs breadcrumbs-templates document-upload-list document-add-list',
        stepsTitle: data && data.editMode ? 'Edit KYC template' : 'New template',
        showDefaultLogo: true,
        stepsSubtitle: '',
        stepsTitleClass: 'no-left',
        confirmDialogue: true,
        // steps would need to be dynamic
        steps: this.dynamicallyGenerateTemplateSteps(data),
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'is_collapsable': false,
        'resultShowAlways': 'true',
        'result_details': [
          {
            'group_result_title': 'investor_document_upload',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': data?.activeEdit?.mainType == "workflowSteps"
              ? documentBuildList
              : [
                {
                  'type': 'templateCreationConfig',
                  'params': {
                    'defaultLocale': 'en',
                    'templateType': data.activeEdit.type,
                    'requestType': requestType,
                    'idTypeList': this.idVerificationService.getIDTypeList(),
                    'localeList': this.idVerificationService.getLocaleList(),
                    'showCountriesAndTypes': this.idVerificationService.jumioTypeCountries ? true : false,
                    'workflowList': this.idVerificationService.getWorkFlowList(),
                    'countryList': this.idVerificationService.jumioTypeCountries,
                    'allowiDIN': this.permissionIDIN,
                    'contractTemplates': this.idVerificationService.organizationContractTemplates,
                    'checkboxes': [
                      {
                        'type': 'checkbox',
                        'params': {
                          'id': 'Other',
                          'htpmOnSaveNewRepresentativename': 'OtherN',
                          'label': 'Custom documents',
                          'value': 'Other',
                          'existingCustomTypes': 0,
                          'counter': 0,
                        }
                      }
                    ],
                  }
                }]
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  openTemplateConfigScreen(data) {
    const documentBuildList = this.buildTemplateConfigList(data);
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs breadcrumbs-templates document-upload-list document-add-list',
        stepsTitle: data && data.editMode ? 'Edit KYC template' : 'New template',
        showDefaultLogo: true,
        stepsSubtitle: '',
        stepsTitleClass: 'no-left',
        confirmDialogue: true,
        // steps would need to be dynamic
        steps: this.dynamicallyGenerateTemplateSteps(data),
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'is_collapsable': false,
        'resultShowAlways': 'true',
        'result_details': [
          {
            'group_result_title': 'investor_document_upload',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': documentBuildList
          }]
      },
    };
    params.group.result_details[0].elements.push(
      {
        'type': 'button_group',
        'class': '',
        // 'errorMessage' : '*at least one legal person related party type must be selected',
        'params': [
          {
            'content': 'Next',
            'display': true,
            'type': 'submit',
            'action': 'onSaveTemplateDataConfig',
          },
          {
            'content': 'Delete',
            'display': !data.inUse && data.editMode ? true : false,
            'action': 'onDeleteTemplateDataConfig',
            'class': 'form-button-red'
          }
        ],
      },
    );
    this.DisplayLightbox.emit(params);
  }

  dynamicallyGenerateTemplateSteps(data, activeStep?) {
    const templatSteps = [];
    if (!data.filledDataExists) {
      templatSteps.push(
        { 'name': 'Select types and roles', 'count': '1', class: 'form-step-active' },
        { 'name': 'Edit workflow steps', 'count': '2' },
        { 'name': 'Main profiles', 'count': '3' },
        { 'name': 'Related profiles', 'count': '4' },
      );
    } else {
      templatSteps.push(
        { 'name': 'Select types and roles', 'count': '1' });
      if (data.workflowSteps) {
        templatSteps.push(
          {
            'name': 'Edit workflow steps', 'count': '2',
            'class': data.activeEdit.mainType === 'workflowSteps' ? 'form-step-active' : ''
          });
      }
      if (data.mainRequestTemplates) {
        const mainRequestTemplateSubSteps = [];
        let counter = 0;
        data.mainRequestTemplates.forEach(type => {
          mainRequestTemplateSubSteps.push(
            {
              count: counter,
              name: type.name,
              label: this.helper.parseKYCTemplateCreationName(type.name),
              active: type.name === data.activeEdit.type && data.activeEdit.mainType === 'mainRequest' ? true : false,
              clickMethod: 'changeTemplateMainStep',
              showWarning: type.errorState
            }
          );
          counter++;
        });
        templatSteps.push(
          {
            'name': 'Main profiles', 'count': '3', 'class': data.activeEdit.mainType === 'mainRequest' ? 'form-step-active' : '',
            'substeps': mainRequestTemplateSubSteps
          });
      }
      if (data.relatedPartyTemplates && data.relatedPartyTemplates.length) {
        const relatedPartyTemplateSubsteps = [];
        let counter = 0;
        data.relatedPartyTemplates.forEach(type => {
          relatedPartyTemplateSubsteps.push(
            {
              count: counter,
              name: type.name,
              label: this.helper.parseKYCTemplateCreationName(type.name),
              active: type.name === data.activeEdit.type && data.activeEdit.mainType === 'relatedParty' ? true : false,
              clickMethod: 'changeTemplateRPStep',
              showWarning: type.errorState
            }
          );
          counter++;
        });
        templatSteps.push(
          {
            'name': 'Related profiles', 'count': '4', 'substeps': relatedPartyTemplateSubsteps,
            'class': data.activeEdit.mainType === 'relatedParty' ? 'form-step-active' : ''
          });
      }
    }
    return templatSteps;
  }

  onDeleteRPAccept() {
    console.log('ACCEPT');
  }

  onDeleteRPReject() {
    console.log('REJECT');
  }


  // UDD1
  onUploadDocumentStepOne(argInfo) {
    // event.preventDefault();
    // this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const documentBuildList = this.idVerificationService.buildDocumentList(argInfo.typeofInvestor, argInfo.existingEvidenceTypes);
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list document-add-list',
        stepsTitle: argInfo.typeofInvestor === 'NaturalPerson' ? 'Add documents and verifications' : 'Add documents',
        showDefaultLogo: true,
        stepsSubtitle: '',
        stepsTitleClass: 'no-left',
        steps: [
          { name: 'Select', count: 1, class: 'form-step-active' },
          { name: 'Customise', count: 2 },
          { name: 'Request', count: 3 },
        ],
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'is_collapsable': false,
        'resultShowAlways': 'true',
        'form_id': argInfo.requestKey,
        'result_details': [
          {
            'group_result_title': 'investor_document_upload',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'group_result_type': argInfo.typeofInvestor,
            'elements': documentBuildList.investorFields
          }]
      },
    };
    // Add in the digital verifications
    if (argInfo.typeofInvestor === 'NaturalPerson') {
      let digitalVerifications = JSON.parse(JSON.stringify(this.idVerificationService.getDocumentTypeList().digitalVerifications));

      // See if there is a digital verification existing
      if (argInfo.existingDigitalVerifications) {
        argInfo.existingDigitalVerifications.forEach(verification => {

          if (verification.verificationType === 'IdentityInstantAI') {
            if (verification.isResolved === true) {

            } else {
              digitalVerifications.params.checkboxes[0].params.oneDocumentDisabled = true;
              digitalVerifications.params.checkboxes[0].params.counter = 1;
            }

          }
          if (verification.verificationType === 'Identity') {
            if (verification.isResolved === true) {

            } else {
              digitalVerifications.params.checkboxes[1].params.oneDocumentDisabled = true;
              digitalVerifications.params.checkboxes[1].params.counter = 1;
            }

          }
          if (verification.verificationType === 'Address') {
            if (verification.isResolved === true && verification.status !== 'Action required') {

            } else {
              digitalVerifications.params.checkboxes[2].params.oneDocumentDisabled = true;
              digitalVerifications.params.checkboxes[2].params.counter = 1;
            }
          }
          if (verification.verificationType === 'Contract') {
            if (verification.isResolved === true) {

            } else {
              digitalVerifications.params.checkboxes[3].params.oneDocumentDisabled = true;
              digitalVerifications.params.checkboxes[3].params.counter = 1;
            }
          }
        });
      }
      // check if user has access to jumio go
      if (!this.idVerificationService.permissionJumioGo()) {
        digitalVerifications.params.checkboxes[0].class = 'd-none';
      }

      params.group.result_details[0].elements.splice(
        1,
        0,
        digitalVerifications
      );
      params.group.result_details[0].elements.splice(
        2,
        0,
        {
          'type': 'hr',
          'class': 'mt-0',
          'params': {
            'class': 'border-type-2'
          },
        }
      );
    } else {
      const digitalVerifications = JSON.parse(JSON.stringify(this.idVerificationService.getDocumentTypeList()
        .digitalVerificationsLegalPerson));
      if (argInfo.existingDigitalVerifications) {
        argInfo.existingDigitalVerifications.forEach(verification => {
          if (verification.verificationType === 'Contract') {
            if (verification.isResolved === true) {

            } else {
              digitalVerifications.params.checkboxes[0].params.oneDocumentDisabled = true;
              digitalVerifications.params.checkboxes[0].params.counter = 1;
            }
          }
        });
      }
      params.group.result_details[0].elements.splice(
        1,
        0,
        digitalVerifications
      );
      params.group.result_details[0].elements.splice(
        2,
        0,
        {
          'type': 'hr',
          'class': 'mt-0',
          'params': {
            'class': 'border-type-2'
          },
        }
      );
    }
    // Add in the custom fields element
    params.group.result_details[0].elements.push(
      {
        'type': 'custom_evidecne_type_select',
        'class': argInfo.typeofInvestor === 'NaturalPerson' ? 'tempHideInput mt-3' : 'mt-3',
        'params': {
          'checkboxes': [
            {
              'type': 'checkbox',
              'params': {
                'id': 'Other',
                'name': 'OtherN',
                'label': 'Custom evidence type',
                'value': 'Other',
                'existingCustomTypes': documentBuildList.existingCustomTypes,
                'counter': documentBuildList.existingCustomTypes.length,
                'dataEvidenceKeys': documentBuildList.otherDataEvidenceKeys
              }
            }
          ],
        },
      }
    );
    params.group.result_details[0].elements.push(
      {
        'type': 'button',
        'params': {
          'content': 'Next',
          'type': 'submit',
          'display': true,
          'action': 'htpmOnSelectedDocuments',
          'errorMessage': '*at least one new document type must be selected.'
        }
      },
    );
    this.DisplayLightbox.emit(params);
  }

  buildTemplateConfigList(data) {
    const returnElements = [];
    const legalPersonTypeList = this.idVerificationService.getLegalPersontypeListCopyForKYCTemplates();
    const relatedPartyRoleTypeList = [...this.idVerificationService.getRelatedPartyRoles()];

    let mainTemplateNaturalPerson;
    if (data && data.mainRequestTemplates) {
      mainTemplateNaturalPerson = data.mainRequestTemplates.find(template => template.type === 'NaturalPerson');
    }
    returnElements.push(
      {
        'type': 'inputText',
        'class': 'w-50 ml-1 mt-4',
        'params': {
          'label': 'Template name',
          'id': 'TemplateName',
          'name': 'TemplateNameNP',
          'content': data.templateName ? data.templateName : '',
          'required': 'true',
          'labelClass': 'font-weight-bold',
          'maxLength': 60,
        },
      },
      {
        'type': 'subtitle-alternate',
        'class': 'input-label-large mx-2',
        'params': {
          'content': 'Choose allowed types and roles'
        }
      },
      {
        'type': 'paragraph',
        'class': 'mt-3 text-with-button-dash short-overlay mx-2',
        'paragraphClass': 'mt-2',
        'params': {
          'content': 'The types and roles you choose below will be included in the KYC Template, and can be configured in the next steps. They will also be the only types and roles available to the (legal) person fulfilling the request for information.',
        }
      }
    );
    // Add in the main template types
    const mainTemplateCheckboxes = [];
    const rpNPTemplateCheckboxes = [];
    const rpLPTemplateCheckboxes = [];
    legalPersonTypeList.forEach(element => {
      let existingMainTemplate;
      if (data && data.mainRequestTemplates) {
        existingMainTemplate = data.mainRequestTemplates.find(template => template.name === element.key);
      }
      mainTemplateCheckboxes.push(
        {
          'type': 'checkbox',
          'params': {
            'id': element.key,
            'name': element.key + 'N',
            'label': this.helper.parseKYCTemplateCreationName(element.key),
            'value': element.key,
            'oneDocument': true,
            'class': 'counter-alternate',
            'counter': (!data.editMode && ((element.key === 'NaturalPerson') || (element.key === 'LegalPerson'))) || existingMainTemplate ? 1 : 0,
            'notDisabled': true
          }
        },
      );
      let existingRelatedTemplate;
      if (data && data.relatedPartyTemplates) {
        existingRelatedTemplate = data.relatedPartyTemplates.find(template => template.name === element.key);
      }
      // We need to skip naturalPerson for related party legal person templates
      if (element.key !== 'NaturalPerson' && element.key !== 'NaturalPersonMinor') {
        rpLPTemplateCheckboxes.push(
          {
            'type': 'checkbox',
            'params': {
              'id': element.key,
              'name': element.key + 'N',
              'label': this.helper.parseKYCTemplateCreationName(element.key),
              'value': element.key,
              'oneDocument': true,
              'class': 'counter-alternate',
              'counter': existingRelatedTemplate || (element.key === 'LegalPerson' && !data.editMode) ? 1 : 0,
              'notDisabled': true
            }
          },
        );
      }
    });
    relatedPartyRoleTypeList.unshift({ 'key': 'NaturalPersonMinor', 'name': 'Natural person - minor' });
    relatedPartyRoleTypeList.unshift({ 'key': 'NaturalPerson', 'name': 'Natural person' });
    relatedPartyRoleTypeList.forEach(element => {
      let existingRelatedTemplate;
      if (data && data.relatedPartyTemplates) {
        existingRelatedTemplate = data.relatedPartyTemplates.find(template => template.name === element.key);
      }
      if (element.key !== 'LegalPerson' && element.key !== 'emptySelect') {
        rpNPTemplateCheckboxes.push(
          {
            'type': 'checkbox',
            'params': {
              'id': element.key,
              'name': element.key + 'N',
              'label': this.helper.parseKYCTemplateCreationName(element.key),
              'value': element.key,
              'oneDocument': true,
              'class': 'counter-alternate',
              'counter': existingRelatedTemplate || (element.key === 'NaturalPerson' && !data.editMode) ? 1 : 0,
              'notDisabled': true
            }
          },
        );
      }
    });

    returnElements.push(
      {
        'type': 'counter_group',
        'class': 'checkbox-borderless mt-3 main-template-types-group',
        'params': {
          'groupTitle': 'Main profile types',
          'checkboxes': mainTemplateCheckboxes
        }
      },
      {
        'type': 'hr',
        'class': 'mt-0',
        'params': {
          'class': 'border-type-2'
        },
      },
      {
        'type': 'counter_group',
        'class': 'checkbox-borderless mt-3 rp-template-types-group rp-template-types-group-np',
        'params': {
          'groupTitle': 'Related profile roles for natural persons',
          'checkboxes': rpNPTemplateCheckboxes
        }
      },
      {
        'type': 'counter_group',
        'class': 'checkbox-borderless mt-3 rp-template-types-group rp-template-types-group-lp',
        'params': {
          'groupTitle': 'Related profile types for legal persons',
          'checkboxes': rpLPTemplateCheckboxes
        }
      },
      {
        'type': 'hr',
        'class': 'mt-0',
        'params': {
          'class': 'border-type-2'
        },
      },
      {
        'type': 'tinyMCE',
        'params': {
          'label': 'Explanatory notes',
          'labelClass': 'font-weight-bold',
          'id': 'templateDescription',
          'value': data.richDescription ? data.richDescription : '',
        }
      },
      {
        'type': 'checkbox_group',
        'class': 'checkbox-borderless',
        'params': {
          // 'required': true,
          'checkboxes': [
            {
              'type': 'checkbox',
              'params': {
                'id': 'publishTemplate',
                'name': 'integrity_risk_identity_reveal',
                'label': data.editMode ? 'Publish on save' : 'Publish  on completion',
                'value': '1',
                'checked': data.published ? true : false,
                'disabled': false
              }
            },
          ]
        }
      }
    );
    return returnElements;
  }


  buildTemplateRPStructure(data) {
    const returnElements = [];
    returnElements.push(
      {
        'type': 'subtitle-alternate',
        'class': 'input-label-large mx-2',
        'params': {
          'content': ' Edit workflow steps'
        }
      },
      {
        'type': 'paragraph',
        'class': 'mt-3 text-with-button-dash short-overlay mx-2 mb-5',
        'paragraphClass': 'mt-2',
        'params': {
          'content': "You may switch on or off the various workflow steps which make part of the 'Basic Information' part of the process. Each step requests a different type of related party. Only steps which are switched on will be presented, all other steps will be skipped. If all steps are switched off, no related parties will be requested.",
        }
      },
      {
        'type': 'editWorkflowStep',
        'class': 'mx-2',
        'params': {
          'id': 'ultimateBeneficialOwners',
          'workflowStep': 'Ultimate Beneficial Owner (UBO)',
          'workflowStepClass': 'w-25',
          'workflowStepChecked': !this.helper.getDoNotDisplay('ultimateBeneficialOwners', data.workflowSteps, data.editMode),
          'defaultLng': 'en',
          'enDescription': this.helper.getDescriptionHelper('ultimateBeneficialOwners', data.workflowSteps, 'en') != ''
            ? this.helper.getDescriptionHelper('ultimateBeneficialOwners', data.workflowSteps, 'en')
            : "An Ultimate Beneficial Owner (UBO) is the natural person who ultimately owns or controls a legal entity and benefits from its ownership. This includes individuals who hold a substantial ownership (e.g. more than 25%) interest in the entity or exercise control through other means, such as voting rights. In the case of trusts, the beneficiaries, settlors, trustees and protectors may be considered UBOs. Pseudo-UBOs will need to be added if the true UBOs cannot be determined. The senior management officials are considered Pseudo-UBOs in case no natural person directly or indirectly controls or owns (e.g. more than 25% of) the entity.",
          'nlDescription': this.helper.getDescriptionHelper('ultimateBeneficialOwners', data.workflowSteps, 'nl') != ''
            ? this.helper.getDescriptionHelper('ultimateBeneficialOwners', data.workflowSteps, 'nl')
            : "Een uiteindelijk begunstigde (UBO) is de natuurlijk persoon die uiteindelijk een juridische entiteit bezit of controleert en ervan profiteert. Dit omvat personen die een substantieel belang (bijv. 25% of meer) in de entiteit hebben of controle uitoefenen via andere middelen, zoals stemrechten. In het geval van trusts kunnen de begunstigden, oprichter, trustee of protector als UBO worden beschouwd. Pseudo-UBO's moeten worden toegevoegd als de echte UBO's niet kunnen worden vastgesteld. De senior managementfunctionarissen worden beschouwd als Pseudo-UBO's als geen natuurlijk persoon direct of indirect controleert of bezit (bijv. meer dan 25%) van de entiteit.",
        }
      },
      {
        'type': 'hr',
        'class': 'mt-0',
        'params': {
          'class': 'border-type-2'
        },
      },
      {
        'type': 'editWorkflowStep',
        'class': 'mx-2',
        'params': {
          'id': 'indirectOwners',
          'workflowStep': '(In)direct owners',
          'workflowStepClass': 'w-25',
          'workflowStepChecked': !this.helper.getDoNotDisplay('indirectOwners', data.workflowSteps, data.editMode),
          'defaultLng': 'en',
          'enDescription': this.helper.getDescriptionHelper('indirectOwners', data.workflowSteps, 'en') != ''
            ? this.helper.getDescriptionHelper('indirectOwners', data.workflowSteps, 'en')
            : "Other types of owners (who are not considered UBOs) could include nominal or registered owners, such as nominees or trusts, who hold legal title to the assets on behalf of another individual, as well as direct shareholders to the entity.",
          'nlDescription': this.helper.getDescriptionHelper('indirectOwners', data.workflowSteps, 'nl') != ''
            ? this.helper.getDescriptionHelper('indirectOwners', data.workflowSteps, 'nl')
            : "Andere soorten eigenaren (die geen UBO's worden genoemd) kunnen nominaal of geregistreerde eigenaren zijn, zoals gemachtigden of trusts, die de juridische eigendom van de activa namens een andere persoon houden, evenals directe aandeelhouders van de entiteit.",
        }
      },
      {
        'type': 'hr',
        'class': 'mt-0',
        'params': {
          'class': 'border-type-2'
        },
      },
      {
        'type': 'editWorkflowStep',
        'class': 'mx-2',
        'params': {
          'id': 'indirectControl',
          'workflowStep': '(In)direct control',
          'workflowStepClass': 'w-25',
          'workflowStepChecked': !this.helper.getDoNotDisplay('indirectControl', data.workflowSteps, data.editMode),
          'defaultLng': 'en',
          'enDescription': this.helper.getDescriptionHelper('indirectControl', data.workflowSteps, 'en') != ''
            ? this.helper.getDescriptionHelper('indirectControl', data.workflowSteps, 'en')
            : "A controlling person is a natural or legal person who has the power to direct or influence the management or policies of the legal entity in question, regardless of their ownership stake. This can include the legal entity's directors or executives.",
          'nlDescription': this.helper.getDescriptionHelper('indirectControl', data.workflowSteps, 'nl') != ''
            ? this.helper.getDescriptionHelper('indirectControl', data.workflowSteps, 'nl')
            : "Een controlerende persoon is een natuurlijk persoon of rechtspersoon die de macht heeft om het beheer of beleid van de betrokken juridische entiteit te sturen of beïnvloeden, ongeacht hun eigendomsbelang. Dit kan bijvoorbeeld de directeuren of leidinggevenden van de juridische entiteit omvatten.",
        }
      },
      {
        'type': 'hr',
        'class': 'mt-0',
        'params': {
          'class': 'border-type-2'
        },
      },
      {
        'type': 'editWorkflowStep',
        'class': 'mx-2',
        'params': {
          'id': 'relevantParty',
          'workflowStep': 'Other relevant party',
          'workflowStepChecked': !this.helper.getDoNotDisplay('relevantParty', data.workflowSteps, data.editMode),
          'defaultLng': 'en',
          'enDescription': this.helper.getDescriptionHelper('relevantParty', data.workflowSteps, 'en') != ''
            ? this.helper.getDescriptionHelper('relevantParty', data.workflowSteps, 'en')
            : "Other relevant parties that may need to be added could include natural or legal persons that perform key functions for the legal entity, such as a (fund) manager, intermediary, authorised or legal representative, or significant service provider.",
          'nlDescription': this.helper.getDescriptionHelper('relevantParty', data.workflowSteps, 'nl') != ''
            ? this.helper.getDescriptionHelper('relevantParty', data.workflowSteps, 'nl')
            : "Andere relevante partijen die moeten worden toegevoegd, kunnen natuurlijke of rechtspersonen zijn die belangrijke functies voor de juridische entiteit vervullen, zoals een (fonds)beheerder, tussenpersoon, geautoriseerde of wettelijke vertegenwoordiger of belangrijke dienstverlener.",
        }
      },
      {
        'type': 'button_group',
        'class': '',
        // 'errorMessage' : '*at least one legal person related party type must be selected',
        'params': [
          {
            'content': 'Next',
            'display': true,
            'type': 'submit',
            'action': 'onSaveTemplateDataConfig',
          },
          {
            'content': 'Delete',
            'display': !data.inUse && data.editMode ? true : false,
            'action': 'onDeleteTemplateDataConfig',
            'class': 'form-button-red'
          }
        ],
      },
    );
    return returnElements;
  }

  // STEP 7
  onUploadDocumentStepTwo(inputArgs) {
    // PARAMS NEW INFORMATION
    // EVIDENCE TO EXPAND
    const activeEvidence = inputArgs.activeEvidence;
    // ALL EVIDENCE FIELDS (NOT JUST KEYS BUT ALL INFO)
    const evidenceTypes = inputArgs.evidenceTypes;
    // PARAMETAR TO TELL LIGHTBOX WHEN IT CLOSES TO RELOAD SAID INVESTOR FROM BE
    const requestKey = inputArgs.requestKey ? inputArgs.requestKey : this.idVerificationService.getActiveInvestor();
    // FIELDS LOADED FROM STATE/BE
    const existingFields = inputArgs.existingFields;
    // PURPOSES LOADED FROM STATE/BE
    const existingPurposes = inputArgs.existingPurposes;
    let lightboxSteps;
    let lightboxTitle;
    // flowType arg is an easy way to tell how the form is supposed to look like since they all look the same
    // can be easily reused for investor review
    if (inputArgs.flowType === 'Update') {
      lightboxSteps = [
        { name: 'Select', class: 'form-step-past', count: 1 },
        { name: 'Customise', count: 2, class: 'form-step-active' },
        { name: 'Request', count: 3 },
      ];
      lightboxTitle = 'UPDATE items';
    } else {
      lightboxSteps = [
        { name: 'Select', class: 'form-step-past', count: 1 },
        { name: 'Customise', count: 2, class: 'form-step-active' },
        { name: 'Request', count: 3 },
      ];
      lightboxTitle = 'Customise items';
    }
    if (inputArgs.replaceFlow) {
      lightboxTitle = this.translate.instant('replace.title');
      lightboxSteps = [
        { name: this.translate.instant('replace.title2'), count: 1, class: 'form-step-active' },
      ];
    }
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding border-top-left-fix'
          + (inputArgs.loadingFromBe ? ' border-top-right-fix' : ''),
        marginclass: 'steps-no-margin',
        stepsTitle: lightboxTitle,
        stepsSubtitle: '',
        reloadfund: requestKey,
        activateFirstEvidenceBox: true,
        steps: lightboxSteps,
        showDefaultLogo: true,
        confirmDialogue: true,
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0 document-list-containers',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': requestKey,
        // 'formKey' : inputArgs.requestKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',
            'elements':
              this.parseDocumentUploadStepTwoElements(
                evidenceTypes, requestKey, activeEvidence, existingFields,
                existingPurposes, inputArgs.flowType, inputArgs.loadingFromBe, inputArgs.isFinalize, inputArgs.replaceFlow)
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  /*
    Main function to render the collapsible evidence
    stateFields = fields returned from the state (could be fetched from BE or not)
  */
  parseDocumentUploadStepTwoElements(evidenceTypes, requestKey, activeEvidenceKey = '',
    stateFields = {}, statePurposes = [], flowtype = 'Create', loadingFromBe = false, isFinalize = false, replaceFlow = false) {
    const return_elements = [];
    let order = 0;
    let digitalevidence;
    let digitalEvidenceTypes;

    const evidenceTypes2 = [...evidenceTypes];
    const supportedCountries = this.idVerificationService.parseCountryOptions();
    const supportedTitles = this.idVerificationService.getTitleList();
    const supportedGenders = this.idVerificationService.getGenderList();
    const ynList = this.idVerificationService.getYNList();

    for (let i = evidenceTypes2.length - 1; i >= 0; i--) {
      if (evidenceTypes2[i].subjectIsOwner === true || evidenceTypes2[i].hideFromList || evidenceTypes2[i].isResolved) {
        evidenceTypes2.splice(i, 1);
      }
    }
    let hasDigitalVerifications = false;
    evidenceTypes2.forEach(async evidence => {
      if (!evidence.hideFromList && !evidence.subjectIsOwner && !evidence.isResolved) {
        if (evidence.type !== 'DigitalVerification') {
          return_elements.push(
            {
              'type': 'documentUploaderMain',
              'isActive': (activeEvidenceKey === evidence.key) ? true : false,
              'params': {
                'dataActivates': evidence.type,
                'clickFunction': 'expandSide',
                'evidenceKey': evidence.key,
                'document_category_name': evidence.customEvidenceType
                  ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
                'flowType': flowtype,
                'order': order,
                'evidenceTypes': evidenceTypes2,
                'loadingFromBe': loadingFromBe,
                'requestKey': requestKey,
                'replaceFlow': replaceFlow,
                'notSharable': evidence.notSharable ? true : false
              }
            });
        } else if (!hasDigitalVerifications) {
          // we add the digital verificaiton main key only once
          if (this.idVerificationService.isKeyRelatedParty(requestKey)) {
            digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['activeRelatedPartyDigitalVerification'];
          } else {
            digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['digitalVerifications'];
          }
          digitalevidence = digitalEvidenceTypes[0];
          return_elements.push(
            {
              'type': 'documentUploaderMain',
              'isActive': (activeEvidenceKey === digitalevidence.key) ? true : false,
              'params': {
                'dataActivates': 'DigitalVerification',
                'clickFunction': 'expandSide',
                'evidenceKey': digitalevidence ? digitalevidence.key : undefined,
                'document_category_name': 'Digital verifications',
                'flowType': flowtype,
                'order': order,
                'evidenceTypes': evidenceTypes2,
                'loadingFromBe': loadingFromBe,
                'requestKey': requestKey,
                'replaceFlow': replaceFlow,
                // 'Needs to be calculated'
                'notSharable': !this.idVerificationService.allEvidenceShareable(digitalEvidenceTypes)
              }
            });
          hasDigitalVerifications = true;
        }
        if (activeEvidenceKey === evidence.key && evidence.type !== 'DigitalVerification') {
          // Here we account for the basic fields replace flow, if an address has any input we will collapse the manual address fields
          let selectedCountryOfRegistration;
          let showAddressFields = false;
          if (stateFields) {
            if (stateFields['CountryOfRegistration']) {
              selectedCountryOfRegistration = stateFields['CountryOfRegistration'];
              showAddressFields = true;
            }
            if (stateFields['AddressCity']) {
              showAddressFields = true;
            }
            if (stateFields['AddressStreet']) {
              showAddressFields = true;
            }
          }
          let isAutomaticRequestLocal = this.idVerificationService.getActiveInvestorDetaisl().isAutomaticRequest
            && !this.idVerificationService.getActiveInvestorDetaisl().convertedOn
          const evidenceCategoriesLocal =
            this.idVerificationService.parseEvidenceFields
              (evidence.type, stateFields, false, false,
                isAutomaticRequestLocal, false, showAddressFields);
          //
          return_elements.push(
            {
              'type': 'documentUploaderSide',
              'id': evidence.type,
              'enabled': true,
              'evidence': evidence,
              'isActive': (activeEvidenceKey === evidence.key) ? true : false,
              'notOlderThanValue': this.helper.getAsStartDate(evidence.notOlderThan),
              'forceValue': evidence.force,
              'finalizeRequiredChecked': evidence.finalizeRequired,
              'notOlderThanModel': 'notOlderThan',
              'params': {
                'lockedFields': this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'] &&
                  this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'].length ?
                  this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'][0]['fieldNames'] : [],
                'lockedFieldsDate': this.idVerificationService.getActiveInvestorDetaisl()['lastUpdate'] ?
                  this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'][0]['lastUpdate'] : undefined,
                'reviewState': evidence.reviewState,
                'evidenceTypes': evidenceTypes2,
                'requestKey': requestKey,
                'evidenceKey': evidence.key,
                'fundVerification': (this.headerInfo['projectType'] === 'Fund') ? true : false,
                'document_category_name': evidence.customEvidenceType
                  ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
                'image_upload_id': 'evidence_image_id',
                'minDate': this.helper.getAsStartDate('01.01.1915'),
                'maxDate': this.helper.getAdultAsMaxDate(),
                'supportedCountries': supportedCountries,
                'email_pattern': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'documents': evidence.documents,
                'purposesOfUse': this.idVerificationService.parsePurposeOfUse(statePurposes),
                'evidenceCategories': evidenceCategoriesLocal,
                'stateFields': stateFields,
                'loadingFromBe': loadingFromBe,
                'flowType': flowtype,
                'isFinalize': isFinalize,
                'replaceFlow': replaceFlow,
                'relatedPartyKey': evidence.relatedPartyKey,
                'Notes': stateFields && stateFields['Notes'],
              }
            },
          );
        }
        order++;
      }
    });
    if (hasDigitalVerifications) {
      if (activeEvidenceKey === digitalevidence.key) {
        const activeDVKey = this.getActiveDVType(
          this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType,
          digitalEvidenceTypes,
          'Funds'
        );
        return_elements.push(
          {
            'type': 'documentUploaderSideDigitalEvidence',
            'isActive': true,
            // 'notOlderThanValue' : this.helper.getAsStartDate(digitalevidence.notOlderThan),
            'notOlderThanModel': 'notOlderThan',
            'forceValue': digitalevidence.force,
            'params': {
              'countryList': this.idVerificationService.jumioTypeCountries,
              'contractTemplates': this.idVerificationService.organizationContractTemplates,
              'idTypeList': this.idVerificationService.getIDTypeList(),
              'localeList': this.idVerificationService.getLocaleList(),
              'defaultLocale': 'en',
              'workflowList': this.idVerificationService.getWorkFlowList(),
              'dateModel': 'NotOlderThanM',
              'showCountriesAndTypes': this.idVerificationService.jumioTypeCountries ? true : false,
              'CDDSharing': true,
              'loadingFromBe': loadingFromBe,
              'evidenceKey': digitalevidence ? digitalevidence.key : undefined,
              'evidenceTypes': evidenceTypes2,
              'requestKey': requestKey,
              'digitalEvidenceTypes': digitalEvidenceTypes,
              'identityVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Identity'),
              'addressVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Address'),
              'activeDVKey': activeDVKey,
              'allowiDIN': this.permissionIDIN,
              'checkboxes': [
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'Other',
                    'htpmOnSaveNewRepresentativename': 'OtherN',
                    'label': 'Custom documents',
                    'value': 'Other',
                    'existingCustomTypes': 0,
                    'counter': 0,
                  }
                }
              ],
            },
          },
        );
      }
    }
    const shareable = this.idVerificationService.allEvidenceShareable(evidenceTypes2);
    return_elements.push(
      {
        'type': 'button_group',
        'class': 'mt-3',
        'params': [
          {
            'class': 'separate-padding ml-4 mr-0 form-button-1',
            'content': shareable ? this.translate.instant('appSpecific.ShareRequest') : this.translate.instant('common.close'),
            'display': shareable && !this.idVerificationService.isInvestorType() ? true : false,
            'action': shareable && !replaceFlow ? 'htpmOnShareEvidenceFromFlow' : !replaceFlow ? 'htpmOnFinalizeUpload' : 'htpmOnFinalizeReview'
          },
          {
            'class': !shareable ? 'form-button-arrow separate-padding mr-0 ml-4 form-button-6' : 'separate-padding mr-0 form-button-6 ml-0',
            'content': this.translate.instant('appSpecific.backToDashboard'),
            'display': true,
            'action': 'backtodashboard'

          }
        ]
      },
    );
    return return_elements;
  }

  getActiveDVType(activeKey, digitalEvidenceTypes, projectType) {
    // return activetype;
    let returnKey;
    if (activeKey) {
      const activeType = this.idVerificationService.returnEvidenceFieldByOrigKey(activeKey, digitalEvidenceTypes);
      if (projectType === 'Funds') {
        if (activeKey && !activeType.hideFromList) {
          returnKey = activeKey;
          return returnKey;
        }
      }
      if (projectType === 'Investor') {
        if (activeKey && activeType.reviewState) {
          returnKey = activeKey;
          return returnKey;
        }
      }
    }

    for (let i = 0; i < digitalEvidenceTypes.length; i++) {
      if (projectType === 'Funds') {
        if (digitalEvidenceTypes[i].notSharable) {
          return digitalEvidenceTypes[i].originalEvidenceKey;
        }
      }
      if (projectType === 'Investor') {
        if (digitalEvidenceTypes[i].reviewState) {
          return digitalEvidenceTypes[i].originalEvidenceKey;
        }
      }
    }
    return digitalEvidenceTypes[0].originalEvidenceKey;
  }

  toBase64(arr) {
    // arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  }



  onEvidencePreview(inputArgs) {
    let historyEntries;
    let parsedHistoryEntries;
    let purposesOfUse;
    let isLoading = false;
    let dontReloadAfterClosing = true;
    const evidence = inputArgs[0].evidence;
    let evidenceType = evidence.type;
    let documentName = evidenceType.slice(0);
    if (evidenceType === 'BASIC_REQUEST_INFO') {
      documentName = ' ';
    } else if (evidenceType === 'DigitalVerification') {
      documentName = this.translate.instant('appSpecific.DigitalVerification');
    } else {
      documentName = this.helper.translateEvidenceType(documentName);
    }
    let versionNotInUse;
    let hasNewerVersions;
    let fieldValues;
    let basicFieldsPreview = false;
    const reviewState = evidence.reviewState;
    let claimedCorrect;
    let expired;
    let latestVersionKey;
    let showVersions = false;
    let versionInfo;
    let subjectIsOwner;
    let showVersionsLightbox = false;
    let currentEvidenceKey;
    let latestVersion;
    let claimScreen;
    let reasonForReplacement;
    let fieldTypesDV;
    let statuses;
    let isDigitalVerification;
    let documentKeys;
    let isAPSPreview;
    let isAPSpreviewEmpty;
    let apsData;
    let apsSearchSubject;
    let vtype;
    let generalStatus;
    let finalizeRequired;
    let evidenceStatus;

    // means no data has been loaded yet
    if (inputArgs[1] === undefined) {
      isLoading = true;
    } else {
      if (evidenceType === 'BASIC_REQUEST_INFO') {
        basicFieldsPreview = true;
        evidenceType = inputArgs[2].record.investorType;
        if (evidenceType === 'NaturalPerson') {
          documentName = inputArgs[2].record.fields.FirstName + ' ' + inputArgs[2].record.fields.LastName;
        }
        if (evidenceType === 'LegalPerson') {
          documentName = inputArgs[2].record.fields.RegisteredName;
        }
      }
      if (evidenceType === 'DigitalVerification') {
        documentName = this.helper.translateVerificationType(evidence.verificationType);
        if (documentName === undefined) {
          if (evidence.verificationData) {
            documentName = this.helper.translateVerificationType(evidence.verificationData.verificationType);
          }
        }
        isDigitalVerification = true;
        vtype = evidence.verificationType;
        if (vtype === undefined) {
          if (evidence.verificationData) {
            vtype = evidence.verificationData.verificationType;
          }
        }
        if (vtype === 'APS') {
          let typeOfInvestor = this.idVerificationService.getActiveInvestorDetaisl().typeOfInvestor;
          let investorFields = this.idVerificationService.getActiveInvestorDetaisl().investorFields;
          apsData = inputArgs[0].apsData;
          if (apsData?.hits.length) {
            isAPSPreview = true;
            if (apsData && apsData.fields) {
              apsSearchSubject = inputArgs[0].apsData.fields.searchSubject;
            } else {
              apsSearchSubject = (typeOfInvestor === 'NaturalPerson')
                ? `${investorFields.FirstName} ${investorFields.LastName}`
                : investorFields.RegisteredName;
            }
          } else {
            isAPSpreviewEmpty = true;
            if (apsData && apsData.fields) {
              apsSearchSubject = inputArgs[0].apsData.fields.searchSubject;
            } else {
              apsSearchSubject = (typeOfInvestor === 'NaturalPerson')
                ? `${investorFields.FirstName} ${investorFields.LastName}`
                : investorFields.RegisteredName;
            }
          }
        }
      }
      // calls to get data has been completed
      claimScreen = inputArgs[0].claimScreen;
      expired = inputArgs[2].record.access ? inputArgs[2].record.access.expired : undefined;
      versionNotInUse = inputArgs[2].record.access ? !inputArgs[2].record.access.inUse : undefined;
      dontReloadAfterClosing = inputArgs[0].dontReloadAfterClosing;
      historyEntries = inputArgs[1].historyEntries;
      fieldValues = inputArgs[2].record.fields;
      reasonForReplacement = inputArgs[2].record ? inputArgs[2].record.reasonForReplacement : undefined;
      purposesOfUse = inputArgs[2].record.access ? inputArgs[2].record.access.purposesOfUse : undefined;
      subjectIsOwner = inputArgs[2].record ? inputArgs[2].record.subjectIsOwner : undefined;
      claimedCorrect = inputArgs[2].record.access ? inputArgs[2].record.access.claimedCorrect : undefined;
      finalizeRequired = inputArgs[2].record ? inputArgs[2].record.finalizeRequired : undefined,
        evidenceStatus = inputArgs[2].record ? inputArgs[2].record.status : undefined;
      currentEvidenceKey = inputArgs[2].key;
      const evidenceFields = inputArgs[2];
      versionInfo = inputArgs[3];
      if (versionInfo.newer) {
        versionInfo.newer[versionInfo.newer.length - 1].label = this.translate.instant('evidencePreview.documentLatest');;
        latestVersionKey = versionInfo.newer[versionInfo.newer.length - 1].key;
        latestVersion = versionInfo.newer[versionInfo.newer.length - 1];
        hasNewerVersions = true;
      }
      if ((versionInfo.newer || versionInfo.previous)) {
        showVersions = true;
      }
      showVersionsLightbox = inputArgs[0].showVersionsLightbox;
      if (historyEntries !== undefined) {
        parsedHistoryEntries = this.idVerificationService.parseHistoryEntries(historyEntries, vtype);
      }
      fieldTypesDV = inputArgs[0].fieldTypesDV;
      statuses = inputArgs[0].statuses;
      documentKeys = inputArgs[0].documentKeys;
      generalStatus = inputArgs[0].generalStatus;
    }

    if (inputArgs[2] && inputArgs[2].record &&
      inputArgs[2].record.verificationData && inputArgs[2].record.verificationData.verificationType.toLowerCase() === 'aps') {
      inputArgs[2].record.fieldTypesDV = inputArgs[0].fieldTypesDV;
    }

    this.idVerificationService.setActiveDigitalVerificationDetails(
      {
        response: inputArgs[2]
        , files: []
        , filesFinishedParsing: (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.documents) ? false : true
      }
    );

    const params = {
      'formType': documentName && documentName !== 'BASIC_REQUEST_INFO' ? documentName : this.translate.instant('appSpecific.Document'),
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        'class': 'document-preview-lightbox',
        reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
        'reloadAPS' : vtype === 'APS' ? this.idVerificationService.getActiveInvestor(): null
        // 'reloadfund' : dontReloadAfterClosing !== false ? false  : this.idVerificationService.getActiveInvestor()
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,

        // 'formKey' : inputArgs.requestKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',
            'elements': [
              {
                'type': 'evidencePreview',
                'class': isLoading ? 'isLoading' : '',
                'params': {
                  'documentPreviewTitle':
                    basicFieldsPreview ? undefined
                      : vtype === 'APS' ? apsSearchSubject
                        : isDigitalVerification ? this.translate.instant('evidencePreview.verificationDetails')
                          : this.translate.instant('evidencePreview.documentDetails'),
                  'evidenceTypes': inputArgs[0].evidenceTypes,
                  'requestKey': inputArgs[0].requestKey,
                  'evidenceKey': inputArgs[0].evidenceKeyClicked,
                  'evidence': evidence,
                  'historyEntries': parsedHistoryEntries ? parsedHistoryEntries['parsedHistoryEntries'] : [],
                  'documentImages': '',
                  'documentKeys': documentKeys,
                  'hideArrows': documentKeys && documentKeys.length <= 1 ? true : false,
                  'fieldTypes': fieldTypesDV ? fieldTypesDV : evidenceType ?
                    this.idVerificationService.parseEvidenceFields(evidenceType, fieldValues, true, true) : [],
                  'fieldTypesDV': fieldTypesDV ? true : false,
                  'statuses': statuses,
                  'purposesOfUse': purposesOfUse,
                  'hasAccessTab': false,
                  'reviewState': reviewState,
                  'claimedCorrect': claimedCorrect,
                  'subjectIsOwner': subjectIsOwner,
                  'versionInfo': versionInfo,
                  'expired': expired,
                  'reasonForExpire': reasonForReplacement,
                  // version not in use means that the current version we are viewing has not been set in use
                  // (newer or older version of evidence)
                  'versionNotInUse': versionNotInUse,
                  'hasNewerVersions': hasNewerVersions,
                  'ownerEmail': parsedHistoryEntries ? parsedHistoryEntries['owner'] : '',
                  // difference between the two is that with the second one the lightbox will be collapsed
                  'showVersions': showVersions,
                  'showVersionsLightbox': showVersionsLightbox,
                  'latestVersion': latestVersion,
                  // only relevant when switching versions
                  'currentEvidenceKey': currentEvidenceKey,
                  'latestVersionKey': latestVersionKey,
                  // 'accessInformation' : accessInformation,
                  // lets us know if we're in the claim screen where certain features aren't visible
                  'claimScreen': claimScreen,
                  'basicFieldsPreview': basicFieldsPreview,
                  'hasNewer': versionInfo ? versionInfo.newer : undefined,
                  'noDocumentsDisplay': !basicFieldsPreview && documentKeys && documentKeys.length === 0 ? true : false,
                  'isDigitalVerification': isDigitalVerification,
                  'isAPSPreview': isAPSPreview,
                  'isAPSpreviewEmpty': isAPSpreviewEmpty,
                  'apsData': apsData,
                  'apsSearchSubject': apsSearchSubject,
                  'generalStatus': generalStatus,
                  'fieldsSource': fieldValues ? fieldValues.fieldsSource : undefined,
                  'finalizeRequired': !this.idVerificationService.isInvestorType() && finalizeRequired && evidenceStatus === 'ACTION_REQUIRED',
                  'vtype': vtype,
                  'isSP': !this.idVerificationService.isInvestorType()
                }
              },
            ]
          }]
      },
    };
    if ((inputArgs[0].evidence.isResolved || (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.verificationData
      && inputArgs[2].record.verificationData.isResolved))
      && inputArgs[0].evidence.verificationType) {
      // console.log('input args', inputArgs);
      // const evidence = inputArgs[0].evidence.filter(x => x.key === inputArgs[2].key);
      params.group.result_details[0].elements[0].params['pdfDownloadButton'] = true;
      // params.group.result_details[0].elements[0].params['isAPSPreview'] = true;
      if (inputArgs[0].evidence.verificationType.toLowerCase() === 'identity') {
        params.group.result_details[0].elements[0].params['pdfDownloadFuction'] = 'OnGenerateDVIdentityPDF';
        if (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.state
          && inputArgs[2].record.state.documents === 'NO_DOCUMENTS'
        ) {
          params.group.result_details[0].elements[0].params['pdfDownloadButton'] = false;
        }
      }
      params.group.result_details[0].elements[0].params['pdfDownloadButton'] = true;
      // params.group.result_details[0].elements[0].params['isAPSPreview'] = true;
      if (inputArgs[0].evidence.verificationType.toLowerCase() === 'identityinstantai') {
        params.group.result_details[0].elements[0].params['pdfDownloadFuction'] = 'OnGenerateDVIdentityInstantAIPDF';
        if (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.state
          && inputArgs[2].record.state.documents === 'NO_DOCUMENTS'
        ) {
          params.group.result_details[0].elements[0].params['pdfDownloadButton'] = false;
        }
      }
      if (inputArgs[0].evidence.verificationType.toLowerCase() === 'address') {
        params.group.result_details[0].elements[0].params['pdfDownloadFuction'] = 'OnGenerateDVAddressVerificationPDF';
        if (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.status && inputArgs[2].record.status.toLowerCase() === 'action_required') {
          // display accept/reject buttons for AV
          params.group.result_details[0].elements[0].params['buttons'] = this.idVerificationService.resolveAvDetailsButtons(inputArgs[2], 'dv');
        }
      }
      if (inputArgs[0].evidence.verificationType.toLowerCase() === 'contract') {
        params.group.result_details[0].elements[0].params['noDocumentsDownload'] = true;
        params.group.result_details[0].elements[0].params['pdfDownloadFuction'] = 'OnGenerateDVContractPDF';
      }
      if (inputArgs[0].evidence.verificationType.toLowerCase() === 'aps') {
        params.group.result_details[0].elements[0].params['noDocumentsDownload'] = true;
        params.group.result_details[0].elements[0].params['buttons'] =
          this.idVerificationService.resolveApsDetailsButtons(inputArgs[2], false, 'dv');
        params.group.result_details[0].elements[0].params['isMonitored'] = inputArgs[0].evidence?.verificationData?.publicVerificationData?.isMonitored;
      }
    }
    this.DisplayLightbox.emit(params);
    // setTimeout(function() {
    //   $('.document-slide-container-imgs').zoom({'on' : 'grab', 'magnify' : '1.33'});
    //   }, 250);
  }

  /**
    * Helper function for evidence preview
  */
  onDocumentVersionChange() {
    const params = {
      'formType': 'Document',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        'class': 'document-preview-lightbox'
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,

        // 'formKey' : inputArgs.requestKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',
            'elements': [
              {
                'type': 'evidenceVersion',
              },
            ]
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  /**
   * method that will execute once the create share link button is clicked
   * @param inputArgs object of input arguments used to control output
   */
  onEvidenceShare(inputArgs) {
    let investorDetails = this.idVerificationService.getActiveInvestorDetaisl();
    if (investorDetails.record) {
      investorDetails = investorDetails.record;
    }
    const typeofInvestor = investorDetails['typeOfInvestor'];
    const showShareUrl: boolean = (inputArgs.shareUrl === undefined);
    const relatedParties = investorDetails['relatedParties'];
    const existingShareEmail = this.idVerificationService.getActiveInvestorShareData() ?
      this.idVerificationService.getActiveInvestorShareData().shareEntities[0].sharedWith
      : investorDetails.investorFields ? investorDetails.investorFields.EmailAddress : undefined;
    let inputClasses = '';
    if (showShareUrl) {
      inputClasses = 'generated-link-hidden';
    }
    /**
     * build array of evidence for checkbox element
     */
    const evidenceCheckboxArray = [];
    if (!investorDetails || !investorDetails['evidenceTypes'] || investorDetails['evidenceTypes'].length === 0) {
      // HANDLE SHARING UNAVAILABLE
    } else {
      // BUILD SHARING CHECKBOX ARRAY
      investorDetails['evidenceTypes'].forEach(evidence => {
        evidenceCheckboxArray.push({
          'type': 'checkbox',
          'params': {
            'id': evidence['key'],
            'name': evidence['type'],
            'label': this.helper.parseBackendName(evidence['type']),
            'value': evidence
          }
        });
      });
    }

    let loadingRelatedParties = true;
    // if there are no related parties we do not go with this process
    if (!relatedParties || (relatedParties && relatedParties.length === 0) || inputArgs.parsedEvidenceType) {
      loadingRelatedParties = false;
    }

    const originalEvidenceTypes = this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(
      investorDetails['evidenceTypes']
      , investorDetails['digitalVerifications']
    );

    this.idVerificationService.resetBEStatus(originalEvidenceTypes);

    let evidenceTypes = [];
    if (originalEvidenceTypes) {
      evidenceTypes = JSON.parse(JSON.stringify(originalEvidenceTypes));
    }

    const evidencePresent = true; // boolean to know if there are any evidece to share
    // push a label for the investor
    evidenceTypes.unshift({
      'type': (typeofInvestor === 'NaturalPerson')
        ? `${investorDetails.investorFields.FirstName} ${investorDetails.investorFields.LastName}`
        : investorDetails.investorFields.RegisteredName,
      'justLabel': true,
    });

    // if the related parties aren't loaded yet push a loading label
    if (loadingRelatedParties) {
      evidenceTypes.push({
        'type': 'Loading additional information',
        'justLabel': true,
        'loadingRP': true,
      });
    }

    // use evidence types provided by the input arguments
    if (inputArgs.parsedEvidenceType) {
      evidenceTypes = inputArgs.parsedEvidenceType;
    }

    const isSharable = this.idVerificationService.allEvidenceShareable(evidenceTypes);
    let additionalClasses = '';
    if (evidencePresent) {
      additionalClasses += ' border-top-left-null';
    }

    const params: any = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs form-lightbox-sharing' + additionalClasses,
        marginclass: evidencePresent ? 'steps-no-margin' : 'steps-no-margin-two',
        stepsTitle: 'Share',
        stepsSubtitle: '',
        showDefaultLogo: true,
        reloadfund: inputArgs.requestKey ? inputArgs.requestKey : this.idVerificationService.getActiveInvestor(),
        activateFirstEvidenceBox: true,
        confirmDialogue: false,
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,

        // 'formKey' : inputArgs.requestKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',
            'elements': [
              {
                'type': 'documentUploaderMainSharing',
                'params': {
                  'evidenceTypes': evidenceTypes,
                  'originalEvidenceTypes': originalEvidenceTypes,
                  'requestKey': inputArgs.requestKey ? inputArgs.requestKey : this.idVerificationService.getActiveInvestor(),
                  'evidencePresent': evidencePresent,
                  'relatedPartyObject': inputArgs.relatedPartyObject,
                  'steps': [
                    { count: 1, title: 'Select', class: 'form-step-past' },
                    { count: 2, title: 'Customise', class: 'form-step-past' },
                    { count: 3, title: 'Request', class: 'form-step-active' },
                  ]
                }
              },
              {
                'type': 'image',
                'params': {
                  'src': '/assets/images/form_image_example.png',
                  'class': 'request-share-img-2'
                }
              },
              {
                'type': 'h3',
                'class': 'pb-2',
                'params': {
                  'content': 'Securely share and request information'
                }
              },
              {
                'type': 'numberedList',
                'params': {
                  'entries': [
                    {
                      'title': 'Create share link',
                      'text': 'Enter the email address of the request recepient, and press "Create share link"', 'count': '1'
                    },
                    {
                      'title': 'Send share link',
                      'text': 'Send the share link to the recepient by email (Mesh ID will not send emails)', 'count': '2'
                    },
                    {
                      'title': 'Person fulfills request',
                      'text': 'The recepient will be asked to create a Mesh ID account if none exists', 'count': '3'
                    }
                  ]
                }
              },
              {
                'type': 'checkbox',
                'class': 'reduced-margin-top skip',
                'params': {
                  'id': 'allowReuse',
                  'name': 'allowReuse',
                  'label': 'Allow the recipient to re-use information from an existing Mesh ID KYC profile',
                  'checked': this.idVerificationService.getAllowReuseProfile(),
                  'onclick': 'updateAllowReuse',
                  'disabled': !showShareUrl || investorDetails.claimedOn
                }
              },
              {
                'type': 'inputText',
                'class': 'reduced-margin-top ' + (!isSharable ? 'non-interactive' : '') + (!inputClasses ? ' d-none' : ''),
                'params': {
                  'id': 'shareEmailAdress',
                  'name': inputArgs.requestKey,
                  'label': 'Email address of the person fulfilling the request',
                  'required': 'true',
                  'labelClass': 'font-weight-bold',
                  'content': inputArgs.email ? inputArgs.email : existingShareEmail,
                  'readOnly': !showShareUrl || !isSharable || investorDetails.claimedOn ? true : false,
                  // tslint:disable-next-line: max-line-length
                  'patternMatch': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'validatorMessage': this.translate.instant('validation.validation5'),
                },
              },
              {
                'type': 'inputTextWithButton',
                'class': inputClasses,
                'params': {
                  'id': 'generatedVerificationLink',
                  'content': inputArgs.shareUrl,
                  'label': 'Please send the below share link to the recipient',
                  'classes': 'generated-link generated-link-fund-share',
                  'readonly': true,
                  'button': {
                    'display': true,
                    'content': 'Copy',
                    'class': 'ml-2 mr-0 form-button-1',
                    'action': 'CopyLinkToClipboard'
                  }
                }
              },
              {
                'type': 'inputTextWithButton',
                'class': 'mt-3 text-with-button-dash ' + inputClasses,
                'params': {
                  'id': 'sendVerificationLink',
                  'content': inputArgs.email ? inputArgs.email : existingShareEmail,
                  'label': 'Email the share link',
                  'classes': 'generated-link generated-link-fund-share',
                  'errorMessage': this.translate.instant('validation.validation5'),
                  'placeholder': 'Type in an email address',
                  'readonly': true,
                  'requestKey': this.idVerificationService.getActiveInvestor(),
                  'button': {
                    'display': true,
                    'content': 'Send',
                    'class': 'ml-2 mr-0 form-button-1',
                    'action': 'sendVerificationLink'
                  }
                }
              },
              {
                'type': 'paragraph',
                'class': !inputArgs.errorMessage ? 'd-none ' : 'text-red-important',
                'params': {
                  'content': inputArgs.errorMessage
                }
              },
            ]
          }]
      },
    };

    // add the buttons
    params['group'].result_details[0].elements.push(
      {
        'type': 'button_group',
        'params': [
          {
            'class': isSharable ? 'mb-3 form-button-1' : 'button-disabled mb-3',
            'content': 'Create share link',
            'type': 'submit',
            'errorMessage': 'At last one evidence must be selected',
            'display': !showShareUrl ? false : true,
            'action': 'htpmOnInvestorShare'
          },
          {
            'type': 'button',
            'class': !showShareUrl ? 'mb-3 form-button-6 pl-0' : 'mb-3 form-button-6',
            'content': !showShareUrl ? 'Back to dashboard' : 'Skip for now',
            'display': true,
            'action': 'backtodashboard'
          }
        ]
      }
    );

    this.DisplayLightbox.emit(params);

    // if there are related parties but we are not  doing a share link
    // parse all the RP information
    if (loadingRelatedParties && !inputArgs.parsedEvidenceType && !inputArgs.doNotRefreshRelatedProfiles) {
      this.idVerificationService.buildEvidenceForSharing(
        relatedParties
        , (inputArgs.doNotRefreshRelatedProfiles)
          ? this.idVerificationService.getActiveInvestorDetaisl().parsedEvidenceTypes : evidenceTypes
        , inputArgs.shareUrl
        , inputArgs.email
        , inputArgs.requestKey
      );
    }
  }

  /**
   * Review document and evidence on investor side
   */
  onEvidenceReview(inputArgs) {
    // PARAMS NEW INFORMATION
    // EVIDENCE TO EXPAND
    const activeEvidence = inputArgs.activeEvidence;
    // ALL EVIDENCE FIELDS (NOT JUST KEYS BUT ALL INFO)
    const evidenceTypes = inputArgs.evidenceTypes;
    // PARAMETAR TO TELL LIGHTBOX WHEN IT CLOSES TO RELOAD SAID INVESTOR FROM BE
    const requestKey = this.idVerificationService.getActiveInvestor();
    // FIELDS LOADED FROM STATE/BE
    const existingFields = inputArgs.existingFields;
    // PURPOSES LOADED FROM STATE/BE
    const existingPurposes = inputArgs.existingPurposes;
    const isSingleStep = inputArgs.isSingleStep;
    // flowType arg is an easy way to tell how the form is supposed to look like since they all look the same
    // can be easily reused for investor review
    // lightboxTitle = this.translate.instant('review.title');

    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding review-data-form review-data-form-mobile border-top-left-fix'
          + (inputArgs.loadingFromBe ? ' border-top-right-fix review-data-loading' : '')
          + (isSingleStep ? ' hide-steps-review-desktop' : ''),
        reloadfundAlternate: requestKey,
        marginclass: 'steps-no-margin',
        stepsSubtitle: '',
        // reloadfund : requestKey,
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        evidenceMobileDropdown: isSingleStep ? false : true,
        'reviewFlowProcess': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class':
          'results d-flex flex-column justify-items-center p-0 document-list-containers steps-form-mobile '
          + (isSingleStep ? ' hide-steps-desktop' : ''),
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': requestKey,
        // 'formKey' : inputArgs.requestKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',
            'elements': this.parseDocumentReviewStepOneElements(
              evidenceTypes
              , requestKey
              , activeEvidence
              , existingFields
              , existingPurposes
              , inputArgs.flowType
              , inputArgs.loadingFromBe
              , inputArgs.isSingleStep
            )
          }]
      },
    };

    this.DisplayLightbox.emit(params);
  }

  /*
  A helper function to build the elements for step one of the review of evidence
  */
  parseDocumentReviewStepOneElements(
    evidenceTypes
    , requestKey
    , activeEvidenceKey = ''
    , stateFields = {}
    , statePurposes = []
    , flowtype = 'Review'
    , loadingFromBe = false,
    isSingleStep = false) {
    let return_elements = [];
    // order means order in array
    let order = 0;
    const activeInvestor = this.idVerificationService.getActiveInvestorDetaisl();
    if (!activeInvestor) {
      return;
    }
    const supportedCountries = this.idVerificationService.parseCountryOptions();
    const supportedTitles = this.idVerificationService.getTitleList();
    const supportedGenders = this.idVerificationService.getGenderList();
    const ynList = this.idVerificationService.getYNList();
    const alreadyParsedDV = [];
    const rightSideEvidence = [];
    let digitalEvidenceTypes;
    let identityGoStep;
    let digitalevidence;
    let digitalEvidencePushed = false;
    // a flag to let us know if there is evidence from different projects that can be accepted
    let hasEvidenceFromDifferentProject = false;
    // a flag to let us know if there is evidence from different projects that can be accepted
    let hasEvidenceFromSameProject = false;
    const hasDigitalVerifications = false;
    // add the list for the mobile view
    evidenceTypes.forEach(evidence => {
      if (evidence.justLabel) {
        return_elements.push(
          {
            'type': 'documentUploaderOwner',
            'isActive': isSingleStep,
            'params': {
              'ownerName': evidence.investorReviewName,
              'loadingFromBe': loadingFromBe,
              'RProle': evidence.RProle ? evidence.RProle : undefined,
            }
          },
        );
      } else if (evidence.type === 'DigitalVerification' && !alreadyParsedDV.includes(evidence.key)) {
        // we add the digital verificaiton main key only once
        if (evidence.relatedPartyKey) {
          digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['DigitalVerificationsMatchArray'][evidence.key];
          identityGoStep = this.idVerificationService.getActiveInvestorDetaisl()['IdentityGoMatchArray'][evidence.key];
        } else {
          digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['digitalVerifications'];
        }
        digitalevidence = digitalEvidenceTypes[0];
        if(!digitalevidence && this.idVerificationService.getActiveInvestorDetaisl().contractStep) {
          console.log(this.idVerificationService.getActiveInvestorDetaisl());
          digitalevidence = this.idVerificationService.getActiveInvestorDetaisl().contractStep.filter(type => type.verificationType === 'Contract')[0];
        }
        alreadyParsedDV.push(evidence.key);
        if (digitalevidence) {
          return_elements.push(
            {
              'type': 'documentUploaderMain',
              'wrapperClass': true,
              'isActive': (activeEvidenceKey === evidence.key) ? true : false,
              'params': {
                'dataActivates': 'DigitalVerification',
                'isDigitalVerification': true,
                'clickFunction': 'expandSide',
                'evidenceKey': digitalevidence.key,
                'reviewState': !this.idVerificationService.allEvidenceReviewed(digitalEvidenceTypes),
                'document_category_name': this.translate.instant('appSpecific.DigitalVerificationsReviewLabel'),
                'RProle': evidence.RProle ? evidence.RProle : undefined,
                'flowType': 'Update',
                'order': order,
                'evidenceTypes': evidenceTypes,
                'loadingFromBe': loadingFromBe,
                'requestKey': evidence.relatedPartyKey ? evidence.relatedPartyKey : this.idVerificationService.getActiveInvestor(),
                'originRequest': evidence.originRequest,
                'isBasicFields': evidence.isBasicFields,
                'rpOptionsLength': evidence.rpOptons ? evidence.rpOptons.length : undefined,
                'rpOptions': evidence.rpOptons,
                'dverificationUrl': evidence.dverificationUrl,
                'basicFieldsNotReviewed': evidence.basicFieldsNotReviewed
              }
            }
          );
          order++;
        }
        if (identityGoStep && identityGoStep.length) {
          identityGoStep = identityGoStep[0]
          return_elements.push(
            {
              'type': 'documentUploaderMain',
              'wrapperClass': true,
              'isActive': (activeEvidenceKey === identityGoStep.originalEvidenceKey) ? true : false,
              'params': {
                'dataActivates': 'IdentityInstantAI',
                'isDigitalVerification': true,
                'clickFunction': 'expandSide',
                'evidenceKey': identityGoStep.originalEvidenceKey,
                'reviewState': !identityGoStep.reviewState,
                'document_category_name': this.translate.instant('verificationType.Identity'),
                'RProle': identityGoStep.RProle ? identityGoStep.RProle : undefined,
                'flowType': 'Update',
                'order': order,
                'evidenceTypes': evidenceTypes,
                'loadingFromBe': loadingFromBe,
                'requestKey': identityGoStep.relatedPartyKey ? identityGoStep.relatedPartyKey : this.idVerificationService.getActiveInvestor(),
                'originRequest': identityGoStep.originRequest,
                'isBasicFields': identityGoStep.isBasicFields,
                'rpOptionsLength': identityGoStep.rpOptons ? identityGoStep.rpOptons.length : undefined,
                'rpOptions': identityGoStep.rpOptons,
                'dverificationUrl': identityGoStep.dverificationUrl,
                'basicFieldsNotReviewed': identityGoStep.basicFieldsNotReviewed
              }
            }
          );
          order++;
        }
      }
      else if (evidence.type !== 'DigitalVerification') {
        return_elements.push(
          {
            'type': 'documentUploaderMain',
            'wrapperClass': true,
            'isActive': (activeEvidenceKey === evidence.key) ? true : false,
            'params': {
              'dataActivates': evidence.type,
              'clickFunction': 'expandSide',
              'evidenceKey': evidence.key,
              'reviewState': evidence.reviewState,
              'document_category_name': evidence.isBasicFields ? evidence.investorReviewName : evidence.customEvidenceType
                ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
              'RProle': evidence.RProle ? evidence.RProle : undefined,
              'flowType': 'Update',
              'order': order,
              'evidenceTypes': evidenceTypes,
              'loadingFromBe': loadingFromBe,
              'requestKey': evidence.relatedPartyKey ? evidence.relatedPartyKey : this.idVerificationService.getActiveInvestor(),
              'originRequest': evidence.originRequest,
              'isBasicFields': evidence.isBasicFields,
              'rpOptionsLength': evidence.rpOptons ? evidence.rpOptons.length : undefined,
              'rpOptions': evidence.rpOptons,
              'basicFieldsNotReviewed': evidence.basicFieldsNotReviewed
            }
          }
        );
        order++;
      }
    });
    evidenceTypes.forEach(evidence => {
      if (activeEvidenceKey === evidence?.key || activeEvidenceKey === evidence?.originalEvidenceKey) {
        if (evidence.type === 'DigitalVerification') {
          if (evidence.relatedPartyKey) {
            digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['DigitalVerificationsMatchArray'][evidence.key];
            identityGoStep = this.idVerificationService.getActiveInvestorDetaisl()['IdentityGoMatchArray'][evidence.key];
          } else {
            digitalEvidenceTypes = this.idVerificationService.getActiveInvestorDetaisl()['digitalVerifications'];
          }
          digitalevidence = digitalEvidenceTypes[0];
          let activeDVKey;
          if (digitalevidence) {
            activeDVKey = this.getActiveDVType(
              this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType,
              digitalEvidenceTypes,
              'Investor'
            );
          }

          if (identityGoStep && identityGoStep[0] && activeEvidenceKey === identityGoStep[0]?.originalEvidenceKey) {
            digitalevidence = identityGoStep[0];
            rightSideEvidence.push({
              'type': 'documentUploaderSideDigitalEvidence',
              'id': evidence.verificationType,
              'enabled': true,
              'isActive': (activeEvidenceKey === evidence?.originalEvidenceKey) ? true : false,
              'investorSide': true,
              'evidence': evidence,
              'params': {
                'workflowList': this.idVerificationService.getWorkFlowList(),
                'countryList': this.idVerificationService.jumioTypeCountries,
                'contractTemplates': this.idVerificationService.organizationContractTemplates,
                'idTypeList': this.idVerificationService.getIDTypeList2(),
                'localeList': this.idVerificationService.getLocaleList(),
                'digitalEvidenceTypes': digitalEvidenceTypes,
                'identityVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Identity'),
                'addressVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Address'),
                'defaultLocale': 'en',
                'showCountriesAndTypes': this.idVerificationService.jumioTypeCountries ? true : false,
                'CDDSharing': true,
                'showIDVerification': true,
                'showADVerification': false,
                'reviewState': evidence.reviewState,
                'reviewSide': true,
                'evidenceTypes': evidenceTypes,
                'requestKey': requestKey,
                'evidenceKey': evidence.key,
                'originalEvidenceKey': evidence.originalEvidenceKey,
                'document_category_name': evidence.customEvidenceType
                  ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
                'image_upload_id': 'evidence_image_id',
                'minDate': this.helper.getAsStartDate('01.01.1915'),
                'maxDate': this.helper.getAdultAsMaxDate(),
                'supportedCountries': this.idVerificationService.parseCountryOptions(),
                'email_pattern': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'documents': evidence.documents ? evidence.documents : [],
                'purposesOfUse': this.idVerificationService.parsePurposeOfUse(statePurposes),
                'evidenceCategories': this.idVerificationService.parseEvidenceFields(evidence.type, stateFields, false, false),
                'stateFields': stateFields,
                'loadingFromBe': loadingFromBe,
                'originRequest': evidence.originRequest,
                // hide the evidence from the different projects unless a button is clicked
                'displayShowMoreEvidenceButton': hasEvidenceFromDifferentProject && hasEvidenceFromSameProject,
                'reviewStateGroup': false,
                'dverificationUrl': evidence.dverificationUrl,
                'activeDVKey': activeDVKey,
                'confirmPersonage': evidence.confirmPersonage,
                'allowiDIN': this.permissionIDIN,
                'singleIdentityGoStep': this.idVerificationService.getActiveInvestorDetaisl().singleIdentityGoStep,
                'singleContractStep' : this.idVerificationService.getActiveInvestorDetaisl().singleContractStep,
                'singleIdentityGoStepRelated': identityGoStep
              }
            },
            );
          }
          else if (!digitalEvidencePushed) {

            rightSideEvidence.push({
              'type': 'documentUploaderSideDigitalEvidence',
              'id': evidence.type,
              'enabled': true,
              'isActive': (activeEvidenceKey === evidence.key) ? true : false,
              'investorSide': true,
              'evidence': evidence,
              'params': {
                'workflowList': this.idVerificationService.getWorkFlowList(),
                'countryList': this.idVerificationService.jumioTypeCountries,
                'contractTemplates': this.idVerificationService.organizationContractTemplates,
                'idTypeList': this.idVerificationService.getIDTypeList2(),
                'localeList': this.idVerificationService.getLocaleList(),
                'digitalEvidenceTypes': digitalEvidenceTypes,
                'identityVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Identity'),
                'addressVerification': this.idVerificationService.checkDigitalVerification(digitalEvidenceTypes, 'Address'),
                'defaultLocale': 'en',
                'showCountriesAndTypes': this.idVerificationService.jumioTypeCountries ? true : false,
                'CDDSharing': true,
                'showIDVerification': true,
                'showADVerification': false,
                'reviewState': evidence.reviewState,
                'reviewSide': true,
                'evidenceTypes': evidenceTypes,
                'requestKey': requestKey,
                'evidenceKey': evidence.key,
                'document_category_name': evidence.customEvidenceType
                  ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
                'image_upload_id': 'evidence_image_id',
                'minDate': this.helper.getAsStartDate('01.01.1915'),
                'maxDate': this.helper.getAdultAsMaxDate(),
                'supportedCountries': this.idVerificationService.parseCountryOptions(),
                'email_pattern': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'documents': evidence.documents ? evidence.documents : [],
                'purposesOfUse': this.idVerificationService.parsePurposeOfUse(statePurposes),
                'evidenceCategories': this.idVerificationService.parseEvidenceFields(evidence.type, stateFields, false, false),
                'stateFields': stateFields,
                'loadingFromBe': loadingFromBe,
                'originRequest': evidence.originRequest,
                // hide the evidence from the different projects unless a button is clicked
                'displayShowMoreEvidenceButton': hasEvidenceFromDifferentProject && hasEvidenceFromSameProject,
                'reviewStateGroup': !this.idVerificationService.allEvidenceReviewed(digitalEvidenceTypes),
                'dverificationUrl': evidence.dverificationUrl ,
                'activeDVKey': activeDVKey,
                'confirmPersonage': evidence.confirmPersonage,
                'allowiDIN': this.permissionIDIN,
                'singleIdentityGoStep': this.idVerificationService.getActiveInvestorDetaisl().singleIdentityGoStep,
                'singleContractStep': this.idVerificationService.getActiveInvestorDetaisl().singleContractStep
              }
            },
            );
            digitalEvidencePushed = true;
          }
        } else {
          const existing_documents = [];
          if (evidence.existingDocuments && evidence.existingDocuments.length > 0) {
            evidence.existingDocuments.forEach(existingDocument => {
              if (this.idVerificationService.getActiveProject() !== existingDocument.evidence.record.projectKey) {
                hasEvidenceFromDifferentProject = true;
              }
              if (this.idVerificationService.getActiveProject() === existingDocument.evidence.record.projectKey) {
                hasEvidenceFromSameProject = true;
              }
              existing_documents.push(
                {
                  'type': 'radio',
                  'params': {
                    'id': existingDocument.evidence.key.replace('EVD:', 'provide'),
                    'name': 'replace_existing_documents',
                    // mmarker should be translated
                    'label': 'Provide existing document',
                    'value': existingDocument.evidence.key,
                    'elementType': this.helper.parseBackendName(existingDocument.evidence.record.customEvidenceType
                      ? existingDocument.evidence.record.customEvidenceType : existingDocument.evidence.record.type),
                    'elementTypeUnparsed': existingDocument.evidence.record.customEvidenceType
                      ? existingDocument.evidence.record.customEvidenceType : existingDocument.evidence.record.type,
                    'disabled': false,
                    'selected': false,
                    'documents': existingDocument.evidence.record.documents,
                    'differentProjectEvidence': this.idVerificationService.getActiveProject()
                      !== existingDocument.evidence.record.projectKey,
                  }
                }
              );
            });
          }
          // Kompany search related
          let companySearchCompleted = false;
          let hideGoogleAddress = false;
          if (this.idVerificationService.localFundCreationData) {
            if (this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils) {
              companySearchCompleted = true;
            }
            if (this.idVerificationService.localFundCreationData.formattedAddress) {
              hideGoogleAddress = true;
            }
          }

          let manualSPfields = activeInvestor.fieldsSource && activeInvestor.fieldsSource.length && activeInvestor.fieldsSource[0].name === "MANUAL";
          // Wether to initially show the address fields
          // Automatically they're hidden
          // If there is a value for the address the manually enter address button will be ticked and the fields will be unhidden
          let selectedCountryOfRegistration;
          let showAddressFields = false;
          if (stateFields) {
            if (stateFields['CountryOfRegistration']) {
              selectedCountryOfRegistration = stateFields['CountryOfRegistration'];
            }
            if (stateFields['AddressCountry'] || stateFields['RegisteredAddressCountry']) {
              showAddressFields = true;
            }
            if (stateFields['AddressCity'] || stateFields['RegisteredAddressCity']) {
              showAddressFields = true;
            }
            if (stateFields['AddressStreet'] || stateFields['RegisteredAddressStreet']) {
              showAddressFields = true;
            }
          }
          let isAutomaticRequestLocal = this.idVerificationService.getActiveInvestorDetaisl().isAutomaticRequest
            && !this.idVerificationService.getActiveInvestorDetaisl().convertedOn;
          const evidenceCategoriesLocal =
            this.idVerificationService.parseEvidenceFields
              (evidence.type, stateFields, false, false,
                isAutomaticRequestLocal, hideGoogleAddress, showAddressFields);
          evidence.formattedExistingDocuments = existing_documents;
          rightSideEvidence.push({
            'type': 'documentUploaderSide',
            'id': evidence.type,
            'enabled': true,
            'isActive': (activeEvidenceKey === evidence.key) ? true : false,
            'investorSide': true,
            'evidence': evidence,
            'params': {
              'lockedFields': this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource']
                && this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'].length ?
                this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'][0]['fieldNames'] : [],
              'lockedFieldsDate': this.idVerificationService.getActiveInvestorDetaisl()['lastUpdate'] ?
                this.idVerificationService.getActiveInvestorDetaisl()['fieldsSource'][0]['lastUpdate'] : undefined,
              'reviewState': evidence.reviewState,
              'evidenceTypes': evidenceTypes,
              'requestKey': requestKey,
              'evidenceKey': evidence.key,
              'fundVerification': (this.headerInfo['projectType'] === 'Fund') ? true : false,
              'document_category_name': evidence.isBasicFields ? evidence.investorReviewName : evidence.customEvidenceType
                ? evidence.customEvidenceType : this.helper.translateEvidenceType(evidence.type),
              'image_upload_id': 'evidence_image_id',
              'minDate': this.helper.getAsStartDate('01.01.1915'),
              'maxDate': this.helper.getAdultAsMaxDate(),
              'supportedCountries': this.idVerificationService.parseCountryOptions(),
              'email_pattern': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'documents': evidence.documents ? evidence.documents : [],
              'purposesOfUse': this.idVerificationService.parsePurposeOfUse(statePurposes),
              'evidenceCategories': evidenceCategoriesLocal,
              'stateFields': stateFields,
              'loadingFromBe': loadingFromBe,
              'originRequest': evidence.originRequest,
              // hide the evidence from the different projects unless a button is clicked
              'displayShowMoreEvidenceButton': hasEvidenceFromDifferentProject && hasEvidenceFromSameProject,
              'isAutomaticRequest': this.idVerificationService.getActiveInvestorDetaisl().isAutomaticRequest && !this.idVerificationService.getActiveInvestorDetaisl().convertedOn,
              'Notes': stateFields['Notes'],
              'selectedCountryValueReview': selectedCountryOfRegistration,
              'permissionKompany': this.idVerificationService.permissionKompany(),
              'manualSPfields': manualSPfields,
              'companySearchCompleted': companySearchCompleted,
              'relatedPartyKey': evidence.relatedPartyKey,
              'kompanyInitialObject': {
                'class': 'reviewProcess',
                'params': {
                  'countryList': this.idVerificationService.kompanyTypeCountries,
                  'reviewProcess': true,
                  'processStart': true,
                  'evidenceKey': evidence.key,
                  'evidenceTypes': evidenceTypes,
                  'selectedCountryValueReview': selectedCountryOfRegistration
                }
              },
            }
          },
          );
        }
      }
    });
    return_elements = return_elements.concat(rightSideEvidence);
    // add the confirm button
    return_elements.push(
      {
        'type': 'button',
        'class': 'separate-padding dont-display-below-large ' + (isSingleStep ? ' d-none' : ''),
        'params': {
          'content': this.translate.instant('common.close'),
          'display': true,
          'action': 'htpmOnFinalizeReview'
        }
      },
    );
    return return_elements;
  }

  /**
  * On add new information request
  * @param event event
  * REDUNDANT FUNCTION NEVER GOT FAR INTO DEVELOPMENT
  */
  OnNewInformationRequest(event) {
    event.preventDefault();
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs',
        stepsTitle: 'INFORMATION REQUEST',
        stepsSubtitle: 'Generate requests for data from investor',
        steps: [
          { name: 'Overview', count: 1, class: 'form-step-active' },
          { name: 'Documents', count: 2 },
          { name: 'Preview', count: 3 },
          { name: 'Finalize', count: 4 }
        ]
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'create_new_representative',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'select',
                'class': 'input-transparent',
                'params': {
                  'id': 'ir-selected-fund',
                  'label': 'Related Funds',
                  'labelClass': 'font-weight-bold',
                  'options': this.activeProjectSelectGroup,
                  'optionselected': this.idVerificationService.getActiveProject(),
                  'required': 'true',
                  'validatorMessage': 'A contributor type must be selected',
                }
              },
              {
                'type': 'inputText',
                'class': 'reduced-margin-top',
                'params': {
                  'label': 'Name of the request',
                  'id': 'ir-request-name',
                  'name': 'ir-request-name-n',
                  'content': 'Proof of ID',
                  'required': 'true',
                  'labelClass': 'font-weight-bold'
                }
              },
              {
                'type': 'subtitle-alternate',
                'class': 'input-label-large increased-margin-top',
                'params': {
                  'content': 'Introduction'
                }
              },
              {
                'type': 'textarea',
                'class': 'reduced-margin-top',
                'params': {
                  'id': 'ir-introduction',
                  'name': 'additional_info',
                  'default_value': '',
                }
              },
              {
                'type': 'subtitle-alternate',
                'class': 'input-label-large increased-margin-top',
                'params': {
                  'content': 'Type of information'
                }
              },
              {
                'type': 'select',
                'class': 'input-transparent reduced-margin-top',
                'params': {
                  'id': 'ir-person-type',
                  'label': 'Choose profile type',
                  'labelClass': 'font-weight-bold',
                  'options':
                    [
                      { 'key': 'NaturalPerson', 'name': 'Natural person' },
                      { 'key': 'Company', 'name': 'Legal person' },
                    ],
                  'optionselected': 'NaturalPerson',
                  'required': 'true',
                  'validatorMessage': 'A fund must be selected',
                }
              },
              {
                'type': 'checkbox_group',
                'id': 'checkbox-group-proofs',
                'class': 'checkbox-borderless',
                'params': {
                  'required': true,
                  'checkboxes': [
                    {
                      'type': 'checkbox',
                      'params': {
                        'id': 'ir-proof-identity',
                        'name': 'ir-proof-identity_n',
                        'label': 'Proof of identity',
                        'value': 'Proof of identity',
                      }
                    },
                    {
                      'type': 'checkbox',
                      'params': {
                        'id': 'ir-proof-addresss',
                        'name': 'ir-proof-address_2',
                        'label': 'Proof of address',
                        'value': 'Proof of address',
                      }
                    },
                    {
                      'type': 'checkbox',
                      'params': {
                        'id': 'ir-proof-wealth',
                        'name': 'ir-proof-wealth-n',
                        'label': 'Source of wealth',
                        'value': 'Source of wealth',
                      }
                    },
                    {
                      'type': 'checkbox',
                      'params': {
                        'id': 'ir-proof-payment',
                        'name': 'ir-proof-payment-n',
                        'label': 'Payment details',
                        'value': 'Payment details',
                      }
                    },
                  ]
                }
              },
              {
                'type': 'button',
                'params': {
                  'content': 'Next',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnNewInformationReques'
                }
              },
            ]
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  onNewInvestorChoice() {
    this.createActiveProjectSelectGroup();

  }

  onNewInvestor(additionalData) {
    let isAutomaticRequest = false;
    let steps = [
      { name: 'Type of profile', count: 1, class: 'form-step-active' },
      { name: 'Profile details', count: 2 },
    ];
    if (additionalData && additionalData.isAutomaticRequest) {
      isAutomaticRequest = true;
      steps = [
        { name: 'Choose template', count: 1, },
        { name: 'Type of profile', count: 2, class: 'form-step-active' },
        { name: 'Profile details', count: 3 },
        { name: 'Share link', count: 4 },
      ];
    }
    // event.preventDefault();
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'Create profile',
        stepsTitleClass: 'no-left',
        showDefaultLogo: true,
        stepsSubtitle: '',
        steps: steps,
        routeManipulation: true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'documentBuilder',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'select',
                'class': 'input-transparent',
                'params': {
                  'id': 'ai-investor-type',
                  'label': 'Choose profile type',
                  'labelClass': 'font-weight-bold label-large mb-3',
                  'options':
                    [
                      { 'key': 'NaturalPerson', 'name': 'Natural person' },
                      { 'key': 'LegalPerson', 'name': 'Legal person' },
                    ],
                  'optionselected': 'NaturalPerson',
                  'required': 'true',
                  'validatorMessage': 'A contributor type must be selected',
                }
              },
              {
                'type': 'checkbox',
                'class': additionalData.selectedTemplate ? '' : 'd-none',
                'params': {
                  'id': 'allowInvestorProfileDetails',
                  'name': 'allowInvestorProfileDetailsN',
                  'label': 'Allow the type and profile details to be changed',
                  'value': false,
                }
              },
              {
                'type': 'inputHidden',
                'class': 'd-none',
                'params': {
                  'id': 'automaticRequestTemplate',
                  'content': additionalData.selectedTemplate
                }
              },
              {
                'type': 'inputHidden',
                'class': 'd-none',
                'params': {
                  'id': 'automaticRequestEmail',
                  'content': additionalData.EmailAddress
                }
              },
              {
                'type': 'button',
                'params': {
                  'content': 'Next',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnNewInvestorStepTwo'
                }
              },
            ]
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  handleImageUpload(file) {
    console.log(file);
  }

  onNewInvestorStepTWoManual(additionalData) {
    let typeInvestor;
    let steps;
    if (!additionalData.selectedTemplate) {
      if (additionalData.type) {
        typeInvestor = additionalData.type;
      } else {
        typeInvestor = additionalData;
      }
      steps = [
        { name: 'Type of profile', count: 1, },
        { name: 'Profile details', count: 2, class: 'form-step-active' },
      ];
    } else {
      typeInvestor = additionalData.type;
      steps = [
        { name: 'Choose template', count: 1, },
        { name: 'Type of profile', count: 2, },
        { name: 'Profile details', count: 3, class: 'form-step-active' },
        { name: 'Share link', count: 4 },
      ]
    }
    // event.preventDefault();
    let investorFields;
    let fieldsSource;
    if (this.idVerificationService.localFundCreationData) {
      if (this.idVerificationService.localFundCreationData.investorFields) {
        investorFields = this.idVerificationService.localFundCreationData.investorFields;
      }
      if (this.idVerificationService.localFundCreationData.fieldsSource) {
        fieldsSource = this.idVerificationService.localFundCreationData.fieldsSource;
      }
    }
    this.createActiveProjectSelectGroup();
    const elementsTemp = this.buildCrateInvestorForm(typeInvestor,
      '', investorFields, '', fieldsSource);

    if (additionalData.selectedTemplate) {
      elementsTemp.push(
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestTemplate',
            'content': additionalData.selectedTemplate
          }
        },
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestAllowInvestorEdit',
            'content': additionalData.allowInvestorEdit
          }
        },
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestEmail',
            'content': additionalData.EmailAddress
          }
        },
      );
    }

    if (this.idVerificationService.localFundCreationData && this.idVerificationService.localFundCreationData.unhideFields) {
      elementsTemp.forEach(element => {
        if (element.type === 'show_or_hide_details') {
          element.class = 'd-none';
        } else if (element.class) {
          element.class = element.class.replace('tempHideInput', '');
        }
      });
    }
    if (typeInvestor === 'LegalPerson' && this.idVerificationService.permissionKompany()
      && !this.idVerificationService.localFundCreationData.kompanyCallError &&
      this.idVerificationService.localFundCreationData.linkToStep !== 'manual'
      && this.idVerificationService.localFundCreationData.linkToStep !== 'none') {
      elementsTemp.unshift(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'find a company instead...',
            'action': 'changeToKompanyCreation'
          }
        },
      );
    }
    if (this.idVerificationService.localFundCreationData &&
      this.idVerificationService.localFundCreationData.linkToStep === 'manual') {
      elementsTemp.unshift(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'Create manually instead',
            'action': 'changeToManualCreationReset'
          }
        },
      );
    }
    // PARAMS NEW INFORMATION
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'Create profile',
        stepsTitleClass: 'no-left',
        showDefaultLogo: true,
        stepsSubtitle: '',
        steps: steps
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': typeInvestor,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': elementsTemp
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  onNewInvestorStepTWoKompany(additionalData) {
    let typeInvestor;
    let steps;
    if (!additionalData.selectedTemplate) {
      typeInvestor = additionalData;
      steps = [
        { name: 'Type of profile', count: 1, click: 'onNewInvestor()', class: 'clickable-step' },
        { name: 'Profile details', count: 2, class: 'form-step-active' },
      ];
    } else {
      typeInvestor = additionalData.type;
      steps = [
        { name: 'Choose template', count: 1, },
        { name: 'Type of profile', count: 2, click: 'onNewInvestor()', class: 'clickable-step' },
        { name: 'Profile details', count: 3, class: 'form-step-active' },
        { name: 'Share link', count: 4 },
      ]
    }
    // event.preventDefault();
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const elementsTemp =
      [...this.buildCrateInvestorForm('LegalPersonKompany', '', this.idVerificationService.localFundCreationData.investorFields)];
    const kompanyElement = elementsTemp.find(element => element.type === 'kompanyInput');
    if (!this.idVerificationService.localFundCreationData.kompanyCallError) {
      elementsTemp.push(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'Create manually instead',
            'action': 'changeToManualCreation'
          }
        },
      );
    }
    if (additionalData.selectedTemplate) {
      elementsTemp.push(
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestTemplate',
            'content': additionalData.selectedTemplate
          }
        },
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestAllowInvestorEdit',
            'content': additionalData.allowInvestorEdit
          }
        },
        {
          'type': 'inputHidden',
          'class': 'd-none skip',
          'params': {
            'id': 'automaticRequestEmail',
            'content': additionalData.EmailAddress
          }
        },
      );
    }
    if (this.idVerificationService.localFundCreationData.kompanyCallError) {
      kompanyElement.params.kompanyCallError = true;
      kompanyElement.params.errorMessage = 'There was an error while searching the company. Click the button below to input the information manually or try again later.';
      kompanyElement.class = {};
    } else if (this.idVerificationService.localFundCreationData.fetchedCompanyList) {
      if (this.idVerificationService.localFundCreationData.fetchedCompanyList.length === 0) {
        kompanyElement.params.kompanyCallError = true;
        kompanyElement.params.errorMessage = 'No search results found.Try again with different parameters or click the button below to input the information manually.';
      } else {
        kompanyElement.params.fetchedCompanyList = this.idVerificationService.localFundCreationData.fetchedCompanyList;
        kompanyElement.class = {};
      }
    } else {
      kompanyElement.params.processStart = true;
    }
    if (this.idVerificationService.localFundCreationData.investorFields &&
      this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration) {
      kompanyElement.params.dontBlur = true;
      kompanyElement.params.selectedCountryValue =
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;
    }

    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'Create profile',
        stepsTitleClass: 'no-left',
        showDefaultLogo: true,
        stepsSubtitle: '',
        steps: steps
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': typeInvestor,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': elementsTemp
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }


  onUpdateInvestor(response) {
    // event.preventDefault();
    let typeInvestor = response.typeOfInvestor;
    let existingFields = response.investorFields;
    const rpKey = response.key;
    const fieldsSource = response.fieldsSource;
    this.createActiveProjectSelectGroup();
    if (typeInvestor === undefined && this.idVerificationService.getActiveInvestorDetaisl()) {
      typeInvestor = this.idVerificationService.getActiveInvestorDetaisl().record.typeOfInvestor;
    }
    if (existingFields === undefined && this.idVerificationService.getActiveInvestorDetaisl()) {
      existingFields = this.idVerificationService.getActiveInvestorDetaisl().record.investorFields;
    }
    // determine fieldsource object
    const elementsTemp = this.buildCrateInvestorForm
      (typeInvestor, 'htpmOnInvestorUpdate', existingFields, 'Update', fieldsSource, true);
    elementsTemp.forEach(element => {
      if (element.type === 'show_or_hide_details') {
        element.class = 'd-none';
      } else if (element.class) {
        element.class = element.class.replace('tempHideInput', '');
      }
    });
    // PARAMS NEW INFORMATION
    const params = {
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        showDefaultLogo: true,
        stepsTitle: !rpKey ? 'UPDATE PROFILE' : 'UPDATE RELATED PROFILE',
        stepsSubtitle: '',
        steps: [
          { name: 'Profile details', count: 1, class: 'form-step-active' },
        ],
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': typeInvestor,
        'formKey': !rpKey ? this.idVerificationService.getActiveInvestor() : rpKey,
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': elementsTemp,
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  /**
  * Helper function for adding a new investor
  * @param investorType event
  */
  buildCrateInvestorForm(investorType: string, submitFunction: string = '', existingFields?, newfunctionName: string = '',
    fieldsSource?, showAddressFields?) {
    // elements to be added to element-builder
    let investorFields = [];
    investorFields = $.extend(true, investorFields, this.idVerificationService.getDocumentTypeList()[investorType].fields);


    // add the dynamic kompany supported countries to the company element
    if (investorType === 'LegalPersonKompany') {
      investorFields[1].params.countryList = this.idVerificationService.kompanyTypeCountries;
    }
    // if there's kompany information hide the google map
    if (fieldsSource) {
      const googleMapElement = investorFields.find(field => field.type === 'inputGoogleAutocomplete');
      if (googleMapElement) {
        googleMapElement.class += ' d-none';
      }
      // if there's kompany information hide the checkbox
      const mapCheckbox = investorFields.find(field => field.subType === 'addressManualInputCheck');
      if (mapCheckbox) {
        mapCheckbox.class += ' d-none';
      }
      // if there's kompany information unhide
      const addressFields = investorFields.filter(field => field.subType === 'addressManualInput');
      addressFields.forEach(field => {
        field.class = field.class.replace('d-none', '');
      });
    }
    if (showAddressFields) {
      const mapCheckbox = investorFields.find(field => field.subType === 'addressManualInputCheck');
      if (mapCheckbox) {
        mapCheckbox.params.checked = true;
      }
      const addressFields = investorFields.filter(field => field.subType === 'addressManualInput');
      addressFields.forEach(field => {
        field.class = field.class.replace('d-none', '');
      });
    }
    // adding all the existing fields
    if (existingFields) {
      // tslint:disable-next-line: forin
      for (const field in existingFields) {
        investorFields.forEach(investorField => {
          if (investorField.params.id === field) {
            // this.helper.getAsStartDateFromTimestamp
            if (field === 'DateOfBirth' || field === 'DateOfRegistration') {
              investorField.params.content = this.helper.getAsStartDateFromTimestamp(existingFields[field]);
            } else {
              investorField.params.content = existingFields[field];
              investorField.params.optionselected = existingFields[field];
              if (fieldsSource && fieldsSource.fieldNames) {
                if (fieldsSource.fieldNames.includes(field)) {
                  if (fieldsSource.lastUpdate !== undefined) {
                    if (fieldsSource.lastUpdate) {
                      investorField.params.additionalInfo =
                        'last updated on ' + this.helper.getDateFromTimeStamp(fieldsSource.lastUpdate);
                    }
                    investorField.params.tooltipIcon = '/assets/images/lock-icon.svg';
                    investorField.params.tooltip = this.translate.instant('kompanySearch.content5');
                  }
                  if (fieldsSource.fieldsLocked) {
                    investorField.params.readOnly = true;
                  }
                }
              }
            }
          }
        });
      }
    }
    // updating the function that is defined in the service
    if (submitFunction !== '') {
      investorFields[investorFields.length - 1].params.action = submitFunction;
    } else {
      investorFields[investorFields.length - 1].params.action = 'htpmOnNewInvestorEnd';
    }
    if (newfunctionName !== '') {
      investorFields[investorFields.length - 1].params.content = newfunctionName;
    } else {
      investorFields[investorFields.length - 1].params.content = 'Create';
    }
    return investorFields;
  }

  /**
  * On add new information request step two
  * @param event event
  */
  OnNewInformationRequestTwo() {
    // event.preventDefault();
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding',
        stepsTitle: 'INFORMATION REQUEST',
        stepsSubtitle: 'Generate requests for data from investor',
        steps: [
          { name: 'Overview', count: 1, class: 'form-step-past' },
          { name: 'Documents', count: 2, class: 'form-step-active' },
          { name: 'Preview', count: 3 },
          { name: 'Finalize', count: 4 }
        ]
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'documentBuilder',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'documentListBuilder',
                'class': '',
                'params': {
                  'id': 'ir-selected-fund',
                  'label': 'Related Funds',
                  'labelClass': 'font-weight-bold',
                  'documentCategories': [
                    {
                      documentCategoryName: 'Proof of Identity',
                      documentOptions: [{ name: 'Passport', key: 'passport' }, { name: 'Identity Card', key: 'IdentityCard' }, { name: 'Drivers License', key: 'driverLicence' }, { name: 'Utility Bill', key: 'Utility Bill' }],
                      currentDocuments: [{ name: 'Passport', key: 'passport' }, { name: 'Identity Card', key: 'IdentityCard' }]
                    },
                    {
                      documentCategoryName: 'Proof of Address',
                      documentOptions: [{ name: 'Passport', key: 'passport' }, { name: 'Identity Card', key: 'IdentityCard' }, { name: 'Drivers License', key: 'driverLicence' }, { name: 'Utility Bill', key: 'Utility Bill' }],
                      currentDocuments: [{ name: 'Passport', key: 'passport' }, { name: 'Identity Card', key: 'IdentityCard' }]
                    },
                  ],
                  'optionselected2': 'IdentityCard',
                  'required': 'true',
                  'validatorMessage': 'A contributor type must be selected',
                }
              },
              {
                'type': 'button',
                'class': 'separate-padding',
                'params': {
                  'content': 'Next',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnFinalizeUpload'
                }
              },
            ]
          }]
      },
    };

    this.DisplayLightbox.emit(params);
    setTimeout(function () {
      $('.document-container-wrapper').sortable({
        containment: 'parent',
        tolerance: 'pointer'
      });
      $('.document-category-wrapper').sortable({
        containment: $('form'),
        tolerance: 'pointer'
      });
      $('.delete-document-icon').on('click', function () {
        $(this).closest('.documents-container').hide();
      });
      $('.add-document-button').on('click', function () {
        const new_container = $(this).closest('.document-category').find('.documents-container').first().clone();
        new_container.appendTo($(this).closest('.document-category').find('.document-container-wrapper'));
        $('.delete-document-icon').on('click', function () {
          $(this).closest('.documents-container').hide();
        });
      });

    }, 500);

  }

  /**
  * On add new information request step three
  * @param event event
  */
  OnNewInformationRequestThree() {
    // event.preventDefault();

    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    alert(1);
    const params = {
      'formType': '',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding',
        stepsTitle: 'INFORMATION REQUEST',
        stepsSubtitle: 'Generate requests for data from investor',
        steps: [
          { name: 'Overview', count: 1, class: 'form-step-past' },
          { name: 'Documents', count: 2, class: 'form-step-past' },
          { name: 'Preview', count: 3, class: 'form-step-active' },
          { name: 'Share', count: 4 }
        ]
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'documentBuilder',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'paragraph',
                'class': 'separate-padding',
                'paragraphClass': 'color-black mb-3',
                'params': {
                  'content': 'For the purposes of invesment fund Albatross, we would need the information below:',
                }
              },
              {
                'type': 'documentUploaderMain',
                'params': {
                  'document_category_name': 'Proof of ID',
                  'dataActivates': 'proofOfId',
                  'clickFunction': 'expandSide',
                }
              },
              {
                'type': 'documentUploaderSide',
                'id': 'proofOfId',
                'params': {
                  'document_category_name': 'Proof of ID',
                }
              },
              {
                'type': 'documentUploaderMain',
                'params': {
                  'document_category_name': 'Proof of address',
                  'dataActivates': 'proofOfAddress'
                }
              },
              {
                'type': 'documentUploaderSide',
                'id': 'proofOfAddress',
                'params': {
                  'document_category_name': 'Proof of address',
                }
              },
              {
                'type': 'paragraphWicon',
                'class': 'separate-padding',
                'paragraphClass': 'my-0',
                'params': {
                  'content': 'This is a snapshot of how your investors will see your request for information. Click finalize when you are ready to share the request.',
                }
              },
              {
                'type': 'button',
                'class': 'separate-padding',
                'params': {
                  'content': 'Next',
                  'type': 'Confirm',
                  'display': true,
                  'action': 'htpmOnShareStep'
                }
              },
            ]
          }]
      },
    };

    this.DisplayLightbox.emit(params);
    setTimeout(function () {
      $('.document-container-wrapper').sortable({
        containment: 'parent',
        tolerance: 'pointer'
      });
      $('.document-category-wrapper').sortable({
        containment: $('form'),
        tolerance: 'pointer'
      });
      $('.delete-document-section-icon').on('click', function () {
        $(this).closest('.document-category').hide();
      });
      $('.delete-document-icon').on('click', function () {
        $(this).closest('.documents-container').hide();
      });
      $('.add-document-button').on('click', function () {
        const new_container = $(this).closest('.document-category').find('.documents-container').first().clone();
        new_container.appendTo($(this).closest('.document-category').find('.document-container-wrapper'));
        $('.delete-document-icon').on('click', function () {
          $(this).closest('.documents-container').hide();
        });
      });
    }, 500);

  }

  /**
   * parse return from the content elements component
   */
  parseContentReturn(returnedAction) {
    if (typeof (returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
  }
  /**
   * displays lightbox project form
   */
  OnDisplayCreateProjectLightbox(event = null) {
    if (event !== null) {
      event.preventDefault();
      event.stopPropagation();
    }

    let params;
    params = {
      'formType': 'Create a new project',
      'formTitle': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      // build the content for the Create new project lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'create_new_project',
        'result_details': [
          {
            'group_result_title': 'create_new_project_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'title',
                'class': 'mb-1 mt-1',
                'params': {
                  'display': true,
                  'content': 'Basic details',
                  'titleClass': 'mb-1 verify-title'
                }
              },
              {
                'type': 'inputText',
                'class': 'reduced-margin-top',
                'params': {
                  'label': this.headerInfo['projectType'] === 'Fund' ? 'Project name' : 'Project name',
                  'id': 'lb-create-project-name',
                  'name': 'lbCreateProjectName',
                  'content': '',
                  'required': 'true'
                }
              }
            ]
          }]
      },
    };
    const projectTemplates = this.idVerificationService.getfundsOrganizationTemplates();
    // if project templates exist we need to add the option to choose a template
    if (projectTemplates !== undefined && !this.idVerificationService.isIdVerification()) {
      // we need to build the object which will hold the list values
      const templateValues = [];
      projectTemplates.forEach(template => {
        templateValues.push(
          {
            'key': template.key,
            'name': template.record.templateName
          }
        );
      });
      params.group.result_details[0].elements.push(
        {
          'type': 'title',
          'class': 'mb-1 mt-4',
          'params': {
            'display': true,
            'content': 'Project template',
            'titleClass': 'mb-1 verify-title'
          }
        },
        {
          'type': 'select',
          'class': 'reduced-margin-top input-transparent',
          'length': '60',
          'params': {
            'id': 'lb-create-project-template',
            'name': 'TemplatesNP',
            'label': 'Choose template',
            'options': templateValues,
          },
        },
      );
    }
    params.group.result_details[0].elements.push(
      {
        'type': 'button',
        'params': {
          'content': this.headerInfo['projectType'] === 'Fund' ? 'Create new project' : 'Create new project',
          'display': true,
          'action': 'hpmOnCreateProject',
          'type': 'submit'
        }
      }
    );


    this.DisplayLightbox.emit(params);
  }
  // Creating related Party step 2
  onCreateRelatedPartyTwoManual(response) {
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    let investorFields;
    let fieldsSource;
    if (this.idVerificationService.localFundCreationData) {
      if (this.idVerificationService.localFundCreationData.investorFields) {
        investorFields = this.idVerificationService.localFundCreationData.investorFields;
      }
      if (this.idVerificationService.localFundCreationData.fieldsSource) {
        fieldsSource = this.idVerificationService.localFundCreationData.fieldsSource;
      }
    }
    const elementsTemp = this.buildCrateInvestorForm(response.relatedPartyType,
      'htpmOnRelatedPartyCreate', investorFields, '', fieldsSource);
    if (this.idVerificationService.localFundCreationData && this.idVerificationService.localFundCreationData.unhideFields) {
      elementsTemp.forEach(element => {
        if (element.type === 'show_or_hide_details') {
          element.class = 'd-none';
        } else if (element.class) {
          element.class = element.class.replace('tempHideInput', '');
        }
      });
    }
    if (response.relatedPartyType === 'LegalPerson' && this.idVerificationService.permissionKompany()
      && !this.idVerificationService.localFundCreationData.kompanyCallError &&
      this.idVerificationService.localFundCreationData.linkToStep !== 'manual'
      && this.idVerificationService.localFundCreationData.linkToStep !== 'none') {
      elementsTemp.unshift(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'find a company instead...',
            'action': 'changeToKompanyCreation'
          }
        },
      );
    }
    if (this.idVerificationService.localFundCreationData &&
      this.idVerificationService.localFundCreationData.linkToStep === 'manual') {
      elementsTemp.unshift(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'Create manually instead',
            'action': 'changeToManualCreationReset'
          }
        },
      );
    }
    const params = {
      'formType': '',
      'formTitle': '',
      'formClass': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'ADD RELATED PROFILE',
        stepsSubtitle: '',
        steps: [
          { name: 'Type of related profile', count: 1, class: 'form-step-past' },
          { name: 'Details', count: 2, class: 'form-step-active' },
        ],
        'routeManipulation': true,
        'reloadFund': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': response.relatedPartyType,
        'result_details': [
          {
            'group_result_title': response.relatedPartyRole,
            'elements': elementsTemp,
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  onCreateRelatedPartyTwoKompany(response) {
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION

    const elementsTemp =
      [...this.buildCrateInvestorForm('LegalPersonKompany', 'htpmOnRelatedPartyCreate',
        this.idVerificationService.localFundCreationData.investorFields)];
    const kompanyElement = elementsTemp.find(element => element.type === 'kompanyInput');
    if (!this.idVerificationService.localFundCreationData.kompanyCallError) {
      elementsTemp.push(
        {
          'type': 'absolute-link',
          'params': {
            'content': 'Create manually instead',
            'action': 'changeToManualCreation'
          }
        },
      );
    }
    if (this.idVerificationService.localFundCreationData.kompanyCallError) {
      kompanyElement.params.kompanyCallError = true;
      kompanyElement.params.errorMessage = 'There was an error while searching the company. Click the button below to input the information manually or try again later.';
      kompanyElement.class = {};
    } else if (this.idVerificationService.localFundCreationData.fetchedCompanyList) {
      if (this.idVerificationService.localFundCreationData.fetchedCompanyList.length === 0) {
        kompanyElement.params.kompanyCallError = true;
        kompanyElement.params.errorMessage = 'No search results found.Try again with different parameters or click the button below to input the information manually.';
      } else {
        kompanyElement.params.fetchedCompanyList = this.idVerificationService.localFundCreationData.fetchedCompanyList;
        kompanyElement.class = {};
      }
    } else {
      kompanyElement.params.processStart = true;
    }
    if (this.idVerificationService.localFundCreationData.investorFields &&
      this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration) {
      kompanyElement.params.dontBlur = true;
      kompanyElement.params.selectedCountryValue =
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;
    }
    const params = {
      'formType': '',
      'formTitle': '',
      'formClass': '',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsTitle: 'ADD RELATED PROFILE',
        stepsSubtitle: '',
        steps: [
          { name: 'Type of related profile', count: 1, class: 'form-step-past' },
          { name: 'Details', count: 2, class: 'form-step-active' },
        ],
        'routeManipulation': true,
        'reloadFund': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': response.relatedPartyType,
        'result_details': [
          {
            'group_result_title': response.relatedPartyRole,
            'elements': elementsTemp,
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }
  // Creating related Party step 1
  onCreateRelatedParty(response) {
    // event.preventDefault();
    this.createActiveProjectSelectGroup();
    // PARAMS NEW INFORMATION
    const params = {
      'formType': '',
      'formTitle': '',
      'formClass': 'document-upload-list',
      'btnOkText': '',
      'formValidator': '',
      'btnCancelText': '',
      'lightboxClass': {
        class: 'form-lightbox-fullscreen form-lightbox-fullscreen-grey form-lightbox-breadcrumbs document-upload-list',
        greyVersion: true,
        stepsSubtitle: '',
        stepsTitle: 'ADD RELATED PROFILE',
        steps: [
          { name: 'Type of related profile', count: 1, class: 'form-step-active' },
          { name: 'Details', count: 2 },
        ],
        'routeManipulation': true,
      },
      // build the content for the Create new person lightbox
      'group': {
        'section_class': 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'documentBuilder',
        'result_details': [
          {
            'group_result_title': 'create_new_representative_group',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements': [
              {
                'type': 'select',
                'class': 'input-transparent',
                'params': {
                  'id': 'relatedPartyType',
                  'label': 'Type of related profile',
                  'labelClass': 'font-weight-bold label-large mb-3',
                  'options':
                    [
                      { 'key': 'NaturalPerson', 'name': 'Natural person' },
                      { 'key': 'LegalPerson', 'name': 'Legal person' },
                    ],
                  'optionselected': 'NaturalPerson',
                  'required': 'true',
                  'validatorMessage': 'A contributor type must be selected',
                }
              },
              {
                'type': 'select',
                'class': 'mt-4 input-transparent',
                'params': {
                  'label': 'Role',
                  'id': 'relatedPartyRole',
                  'name': 'relatedPartyRoleN',
                  'options': this.idVerificationService.getRelatedPartyRoles(),
                  'required': 'true',
                  'labelClass': 'font-weight-bold',
                  'changemethod': 'onChangeRelatedPartyRole'
                },
              },
              {
                'type': 'inputText',
                'class': 'reduced-margin-top d-none',
                'length': '10',
                'params': {
                  'id': 'relatedPartyRoleText',
                  'name': 'relatedPartyRoleTextN',
                  'required': 'true',
                  'labelClass': 'font-weight-bold d-none',
                  'content': 'Other'
                },
              },
              {
                'type': 'button',
                'params': {
                  'content': 'Next',
                  'type': 'submit',
                  'display': true,
                  'action': 'htpmOnRelatedPartyStepTwo'
                }
              },
            ]
          }]
      },
    };
    this.DisplayLightbox.emit(params);
  }

  onAddNewInvestor(event) {
    // check to see if there are any templates for the organization
    // if there are offer the option for automatic template
    // if not continue to normal flow
    const organizationTemplates = this.idVerificationService.getfundsOrganizationTemplates();
    let showSubmenu = false;
    if (organizationTemplates !== undefined && organizationTemplates.length) {
      organizationTemplates.forEach(template => {
        if (template.record.published) {
          showSubmenu = true;
        }
      });
    }
    if (showSubmenu) {
      $('#actionsMenuProfileHeader').removeClass('d-none');
    } else {
      this.idVerificationService.skipToStep(8, {});
    }
  }
  onNewInvestorManual(event) {
    this.idVerificationService.skipToStep(8, {});
  }
  onNewInvestorAutomatic(event) {
    this.idVerificationService.skipToStep(19, {});
  }

  OnDisplayLightbox() {
    // simply re-emit
    // this.DisplayLightbox.emit( params );
    this.OnDisplayCreateProjectLightbox();
  }

  ngOnDestroy() {
    $('.nicescroll-rails').remove();
    this.activeProjectsStatus.unsubscribe();
    this.activeProjectStatus.unsubscribe();
    if (this.informationRequestStep) {
      this.informationRequestStep.unsubscribe();
    }
    if (this.routeParamStatus) {
      this.routeParamStatus.unsubscribe();
    }
    this.parseInvestorsStatus.unsubscribe();
  }
}

