import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-digital-verification-evidence-share-link',
  templateUrl: './digital-verification-evidence-share-link.component.html',
  styleUrls: ['./digital-verification-evidence-share-link.component.scss']
})
export class DigitalVerificationEvidenceShareLinkComponent implements OnInit,  AfterViewInit {

  isLastEvidence; // variable to know if this is the last evidence in the review process
  nextEvidenceElem; // element of the next evidence in the order
  entityName;
  confirmedEmail;
  typeOfInvestor;
  isContract;
  rKey;
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  constructor(
      private elementService: ElementsService,
      private idVerificationService: IdVerificationService
  ) { }

  ngOnInit(): void {


    // we need to find the name of the entity the digital verificaitons belong too
    if (this.element && this.element.evidence) {
      this.rKey = this.element.evidence.belongsToRequest;
      if (this.rKey) {
        this.element.params.evidenceTypes.forEach(evidenceType => {
          if (evidenceType.investorReviewName && evidenceType.IRkey === this.rKey) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      } else {
        this.rKey = this.element.evidence.relatedPartyKey;
        this.element.params.evidenceTypes.forEach(evidenceType => {
          if (evidenceType.investorReviewName && evidenceType.relatedPartyKey === this.rKey) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      }
      if(this.element.evidence.verificationType === 'Contract') {
        this.isContract = true;
      }
    }

    if (this.element && this.element.params) {
      if (this.element.params.confirmPersonage === undefined)  {
        this.element.params.confirmPersonage = true;
      }
    }

    if (this.element.params.singleContractStep && !this.element.params.loadingFromBe) {
      this.idVerificationService.getActiveInvestorDetaisl().singleContractStep = false;
    }

    if (this.element.params.singleIdentityGoStep && !this.element.params.loadingFromBe) {
      this.idVerificationService.getActiveInvestorDetaisl().singleIdentityGoStep = false;
    }
    let _this = this;
    window.onmessage = function(e) {
      if (e.data === 'iframe is closed') {
        _this.onIframeClose();
     }
     if (e.data === 'iframe is closed back') {
      _this.onIframeCloseBack();
    }

    if (e.data === 'iframe is closed GO') {
      _this.onIframeCloseGo();
    }


  };

  }

  ngAfterViewInit() {

  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  switchScreen(newValue) {
    this.element.params.confirmPersonage = newValue;
  }

  onShareDVSkip() {
    this.idVerificationService.skipToStep(0);
  }

  onShareDVNextButton(event, link = '') {
    if (link !== '') {
      event.preventDefault();
        $(`<iframe src="${link}" frameborder="0" id="dvIframe" style="position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 999999;"></iframe>`)
        .appendTo('.tealish-gradient-bg.ic_height_auto');
      return;
    }
    const currentExpandedEvidence =  $('.document-category-main[data-EvidenceKey="'
          + this.idVerificationService.getActiveEvidenceKey() + '"]').not($('.uploading-document'));
    const currentExpandedEvidenceOrder = $(currentExpandedEvidence).attr('data-evidenceOrder');
    const numberofLabels = $('.documentUploaderOwner').length;
    const numberOfEvidenceInList = $('.document-category-main').not($('.uploading-document')).length;
    const totalNumberOfEvidence = numberOfEvidenceInList - numberofLabels;
    const nextExpandedEvidenceOrder = this.idVerificationService.findNextEvidenceOrder(parseInt(currentExpandedEvidenceOrder, 10),
                                      totalNumberOfEvidence, this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType']);
    if (nextExpandedEvidenceOrder === totalNumberOfEvidence ) {
      this.isLastEvidence = true;
    } else {
      this.nextEvidenceElem = $('.document-category-main[data-evidenceOrder="' + nextExpandedEvidenceOrder + '"]');
    }
   if (this.isLastEvidence) {
    this.idVerificationService.skipToStep(0);
   } else {
    this.nextEvidenceElem.trigger('click');
    this.nextEvidenceElem.parent().trigger('click');
    this.nextEvidenceElem.find('.clickable-element').trigger('click');
   }
  }

  onIframeCloseBack() {
    $('#dvIframe').remove();
    setTimeout(function () {
      $('#dvIframe').remove();
    }, 1500);
    this.element.params.digitalEvidenceTypes.forEach(dv => {
      dv.iframeStatus = closed;
    });
  }


  onIframeClose() {
    $('#dvIframe').remove();
    setTimeout(function () {
      $('#dvIframe').remove();
    }, 1500);
    this.element.params.digitalEvidenceTypes.forEach(dv => {
      dv.iframeStatus = closed;
    });

    let activeKeyHtml = $('.document-category-main.category-active');
    let requestKey = activeKeyHtml.attr('data-requestkey');
    let localDVs;
    if(this.idVerificationService.isKeyRelatedParty(requestKey)) {
      let localrelatedParty = this.idVerificationService.getActiveInvestorDetaisl().relatedParties.find(rp => rp.key === requestKey);
      localDVs = localrelatedParty?.record.digitalVerifications;
      // update the projectEvidenceType structure
      for (let i = 0; i < localDVs?.length; i++) {
        if(localDVs[i].verificationType !== 'IdentityInstantAI')
        {
          localDVs[i].status = 'Completed';
          localDVs[i].reviewState = false;
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'] =
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'].filter(
            type =>
            type.originalEvidenceKey !== localDVs[i].originalEvidenceKey
          );
        }
      };
      if(localrelatedParty && localrelatedParty.record) {
        localrelatedParty.record.digitalVerifications = [...localDVs];
        localrelatedParty.record.hideDVs = true;
        localrelatedParty.record.basicFieldsEvidence.status = "Completed";
        let deleteBasicField = true;
        localrelatedParty.record.evidenceTypes.forEach(evidenceType => {
          if(evidenceType.reviewState === true) {
            deleteBasicField = false;
          }
        });
        if(deleteBasicField) {
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'] =
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'].filter(
            type =>
            type.relatedPartyKey !== localrelatedParty.key
          );
        }
      }
      // update the activeInvestorDetaiilsStructure
    } else {
      localDVs = this.idVerificationService.getActiveInvestorDetaisl()?.originalBEDigitalVerifications;
      for (let i = 0; i < localDVs?.length; i++) {
        if(localDVs[i].verificationType !== 'IdentityInstantAI')
        {
          localDVs[i].status = 'Completed';

        }
      };
      if(localDVs) {
        this.idVerificationService.getActiveInvestorDetaisl().originalBEDigitalVerifications = [...localDVs];
        this.idVerificationService.getActiveInvestorDetaisl().digitalVerifications = [...localDVs];
      }
    }
     this.idVerificationService.reviewWindowClosed.next();
     this.idVerificationService.skipToStep(0);
  }

  onIframeCloseGo() {
    $('#dvIframe').remove();
    setTimeout(function () {
      $('#dvIframe').remove();
    }, 1500);
    this.element.params.digitalEvidenceTypes.forEach(dv => {
      dv.iframeStatus = closed;
    });

    let activeKeyHtml = $('.document-category-main.category-active');
    let requestKey = activeKeyHtml.attr('data-requestkey');
    let localDVs;
    if(this.idVerificationService.isKeyRelatedParty(requestKey)) {
      let localrelatedParty = this.idVerificationService.getActiveInvestorDetaisl().relatedParties.find(rp => rp.key === requestKey);
      localDVs = localrelatedParty?.record.digitalVerifications;
      // update the projectEvidenceType structure
      for (let i = 0; i < localDVs?.length; i++) {
        if(localDVs[i].verificationType === 'IdentityInstantAI')
        {
          localDVs[i].status = 'Completed';
          localDVs[i].reviewState = false;
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'] =
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'].filter(
            type =>
            type.originalEvidenceKey !== localDVs[i].originalEvidenceKey
          );
        }
      };
      if(localrelatedParty && localrelatedParty.record) {
        localrelatedParty.record.digitalVerifications = [...localDVs];
        localrelatedParty.record.hideDVs = true;
        localrelatedParty.record.basicFieldsEvidence.status = "Completed";
        let deleteBasicField = true;
        localrelatedParty.record.evidenceTypes.forEach(evidenceType => {
          if(evidenceType.reviewState === true) {
            deleteBasicField = false;
          }
        });
        if(deleteBasicField) {
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'] =
          this.idVerificationService.getActiveInvestorDetaisl()['projectEvidenceType'].filter(
            type =>
            type.relatedPartyKey !== localrelatedParty.key
          );
        }
      }
      // update the activeInvestorDetaiilsStructure
    } else {
      localDVs = this.idVerificationService.getActiveInvestorDetaisl()?.originalBEDigitalVerifications;
      for (let i = 0; i < localDVs?.length; i++) {
        if(localDVs[i].verificationType === 'IdentityInstantAI')
        {
          localDVs[i].status = 'Completed';

        }
      };
      if(localDVs) {
        this.idVerificationService.getActiveInvestorDetaisl().originalBEDigitalVerifications = [...localDVs];
        this.idVerificationService.getActiveInvestorDetaisl().digitalVerifications = [...localDVs];
      }
    }
     this.idVerificationService.reviewWindowClosed.next();
     this.idVerificationService.skipToStep(0);
  }
}
