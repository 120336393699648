import SelectEditData from './editForm/select.edit.data';
import SelectEditDisplay from './editForm/select.edit.display';
// import SelectEditValidation from './editForm/Select.edit.validation';
 
export default function(...extend) {
    return {
        components: [
        {
            components: [
            {
                label: "Configuration",
                key: 'data',
                components: SelectEditData
            },
            // {
            //     label: "Display",
            //     key: "display",
            //     components: SelectEditDisplay
            // },
            ],
            "key": "tabs",
            "type": "tabs",
            "input": false,
            "tableView": false
        },
        ]
    }
}
