<div [hidden]="multipleStepManagement.stepType !== 'finalize'"  *ngIf="multipleStepManagement.timelineImgLP && selectedProfile.type == 'legal'" class="d-none d-sm-block">
  <img [src]="multipleStepManagement.timelineImgLP" alt="AR Timeline" class="w-100">
</div>
<div [hidden]="multipleStepManagement.stepType !== 'finalize'"  *ngIf="multipleStepManagement.timelineImgNP && selectedProfile.type == 'natural'" class="d-none d-sm-block">
  <img [src]="multipleStepManagement.timelineImgNP" alt="AR NP Timeline" class="w-100">
</div>
  <hr class="hr-line" [ngClass]="{'mb-0': explanatoryNotes}"/>
  <div  class="select-investor-wrapper">
    <button
    *ngIf="explanatoryNotes"
    class="form-button form-button-link-2 mr-0 pl-0 d-flex explanatory-notes"
    (click)="displayModal=true"
  >
  {{'ARPCS.explanatoryNotes'|translate}}
  </button>
  <!-- MAIN PROFILE  -->
  <app-lightbox
    *ngIf='displayModal'
    [formType]='formType'
    [formTitle]='formTitle'
    [formClass]='formClass'
    [formSubTitle]='formSubTitle'
    [lightboxClass]='lightboxClass'
    [btnOkText]='btnOkText'
    [btnCancelText]='btnCancelText'
    [displayModal]='displayModal'
    [displayLoader]='displayLoader'
    [contentDOM]="lightboxContent"
    (closeLightbox)="onLightboxClose($event)"
    ></app-lightbox>
  <div [hidden]="multipleStepManagement.stepType !== 'mainProfile'">
    <div  *ngIf="!onlyOneOption" class="d-flex element-wrapper align-items-start cdd-pcs-kompany">
      <h4  class="result-inner-title mb-2 text-left pr-2">{{element.params.openingTitle}}</h4>
      <app-tooltip
        [tooltipText] = "'ARPCS.content3' | translate">
      </app-tooltip>
    </div>
    <div class="type-of-profile d-flex flex-row mt-4 justify-content-center">
      <a id="mainRequestLegalPersonButton" *ngIf="legalMainOptionAllowed"  href="#" (click)="onSelectType($event, 'legal')"
      [ngClass]="{'active': selectedProfile.type==='legal'}"
      class="d-flex flex-column w-50 ml-1 mr-3 align-items-center justify-content-center type-of-profile-link">
        <img src="assets/images/ico-legal-person-gray.svg" width="45px" class="m-4"/>
        <span class="mb-2">{{'appSpecific.LegalPerson' | translate}}</span>
      </a>
      <a id="mainRequestNaturalPersonButton" *ngIf="naturalMainOptionAllowed"  href="#" (click)="onSelectType($event, 'natural')"
      [ngClass]="{'active': selectedProfile.type==='natural'}"
        class="d-flex flex-column w-50 ml-3 mr-1 align-items-center justify-content-center type-of-profile-link">
        <img src="assets/images/ico-natural-small.svg" width="45px" class="m-4"/>
        <span class="mb-2">{{'appSpecific.NaturalPerson' | translate}}</span>
      </a>
    </div>
    <div class="d-flex element-wrapper align-items-start cdd-pcs-kompany flex-column">
      <div class="result-paragraph " [innerHTML] = "'ARPCS.content3-2' | translate"></div>
    </div>
    <div class="error-field no-selected-error" *ngIf="noSelectedTypeError">
      {{'ARPCS.content7' | translate}}
    </div>
    <div class="cdd-pcs-kompany mt-3 full-width"
    *ngIf="selectedProfile.type==='legal'
      && permissionKompany && !kompanyDataRetreived && automaticCompanySearch && allowKompanySearch">
      <div class="d-flex element-wrapper align-items-center">
          <h4 class="result-inner-title mb-2">{{'ARPCS.content8' | translate}}</h4>
          <app-tooltip
            [tooltipText] = "'kompanySearch.content' | translate">
          </app-tooltip>
      </div>
      <div class="element-wrapper mt-0" [ngClass]= "element.class ? element.class : ''" >
        <app-kompany-seach
        *ngIf="!kompanyDataRetreived"
        [element]=kompanyElement (contentReturn)="onAddKompanyInformation($event)"
        ></app-kompany-seach>
      </div>
      <div class="d-flex element-wrapper btn-mt">
        <button
        *ngIf="!element.params.basicFieldsProcess && !element.params.reviewProcess"
        class="form-button form-button-link-2 ml-0 mr-1 ml-sm-auto pl-0"
        [attr.clickmethod]="'changeToManualCreation'"
        (click)="changeToManualCreation()">
          {{'kompanySearch.label8' | translate}}
        </button>
      </div>
    </div>

    <div class="legalFields text-left" *ngIf="selectedProfile.type==='legal'">
      <div class="first-last-name-wrapper">
        <div [ngClass]="{'d-none' : (automaticCompanySearch && !kompanyDataRetreived )}" class="element-wrapper entity-name-type">
          <span class="input-label {{element.params.labelClass}}">
            {{'ARPCS.label' | translate}}: <sup class="sup-required">*</sup>
            <app-tooltip
              *ngIf="kompanyDataRetreived"
              [tooltipText]="'kompanySearch.content5' | translate"
              [icon]="'/assets/images/lock-icon.svg'">
            </app-tooltip>
          </span>
          <!-- Input Text -->
          <!-- [attr.enterkeyaction] = 'element.params.onenterkeyaction' -->
          <input
              type="text"
              class="text-input"
              id="profileLegalName"
              [(ngModel)]="savedInformation.profileLegalName"
              [attr.isValid] = 'true'
              [attr.isRequired] = 'true'
              [attr.disabled] = "savedInformation.profileLegalNameLocked"
          />
          <div class="error-field"
              [attr.validatorMessage] = 'element.params.validatorMessage'
              [attr.validatorMessageField] = 'element.params.validatorMessage'>
              *{{'ARPCS.validation' | translate}}
          </div>
        </div>
        <div class="element-wrapper entity-name-type" [ngClass]="(element.class) ? element.class : ''">
          <span class="input-label {{(element.params.labelClass) ? element.params.labelClass : ''}}">
            {{'ARPCS.label2' | translate}}:<sup class="sup-required">*</sup></span>
          <span class="select-wrapper">
            <select class="select-input input-transparent"
              id="profileLegalEntityType"
              (change)="OnChangeSelect($event); onChangeSelectLegalType($event)"
              [attr.changemethod]='element.params.changemethod'
              [attr.isRequired]='element.params.required'
              [attr.disabled] = 'savedInformation.profileLegalEntityTypeLocked'
              [value]="(element.params.optionselected) ? element.params.optionselected : -1">
              <option
                [selected] = "element.params.legalEntityTypesOptionSelected === option.key
                || savedInformation.profileLegalEntityType == option.key"
                value="{{option.key}}"
                *ngFor="let option of element.params.legalEntityTypes"
              >{{option.name}}</option>
            </select>
          </span>
          <div class="error-field"
            [attr.validatorMessage] = 'element.params.validatorMessage'
            [attr.validatorMessageField] = 'element.params.validatorMessage'>
            *{{'ARPCS.validation2' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="naturalFields text-left" *ngIf="selectedProfile.type==='natural'">
      <div class="first-last-name-wrapper">
        <div class="element-wrapper first-last-name">
          <span class="input-label {{element.params.labelClass}}">{{'appSpecific.FirstName' | translate}}:<sup class="sup-required">*</sup></span>
          <!-- Input Text -->
          <input
              type="text"
              class="text-input"
              id="profileNaturalFirstName"
              [(ngModel)]="savedInformation.profileNaturalFirstName"
              [attr.isValid] = 'true'
              [attr.isRequired] = 'true'
              [attr.disabled] = "savedInformation.profileNaturalFirstNameLocked"
          />
          <div class="error-field"
              [attr.validatorMessage] = 'element.params.validatorMessage'
              [attr.validatorMessageField] = 'element.params.validatorMessage'>
              *{{'ARPCS.validation3' | translate}}
          </div>
        </div>
        <div class="element-wrapper first-last-name">
          <span class="input-label {{element.params.labelClass}}">{{'appSpecific.LastName' | translate}}:<sup class="sup-required">*</sup></span>
          <!-- Input Text -->
          <input
              type="text"
              class="text-input"
              id="profileNaturalLastName"
              [(ngModel)]="savedInformation.profileNaturalLastName"
              [attr.isValid] = 'true'
              [attr.isRequired] = 'true'
              [attr.disabled] = "savedInformation.profileNaturalLastNameLocked"
          />
          <div class="error-field"
              [attr.validatorMessage] = 'element.params.validatorMessage'
              [attr.validatorMessageField] = 'element.params.validatorMessage'>
              *{{'ARPCS.validation4' | translate}}
          </div>
        </div>
      </div>

      <div class="element-wrapper is-minor">
        <input class="custom-checkbox"
          type="checkbox"
          id="profileNaturalMinor"
          name="profileNaturalMinorN">
        <label class="custom-checkbox-label"
          for="profileNaturalMinor">{{'ARPCS.content11' | translate}}</label>
      </div>
    </div>

    <div class="d-flex mt-2" *ngIf ="selectedProfile.type==='legal'
      && permissionKompany && ( !automaticCompanySearch || kompanyDataRetreived) && allowKompanySearch">
      <button
      class="form-button form-button-link-2 mr-auto mr-1 pl-0"
      (click)="changeToAutomaticCreation()">
      {{ kompanyDataRetreived ?
        ('kompanySearch.button2' | translate) : ('kompanySearch.button3' | translate)}}
      </button>
    </div>

     <div *ngIf="showTypeNotification" class="d-flex flex-row align-items-start notification info">
      <img src="assets/images/icon-information-prepared-teal.svg" width="16px">
      <div class="d-flex flex-column pl-3 content">
        <h3 class="ml-0 mr-auto">{{'ARPCS.title7' | translate}}</h3>
        <div class="description">{{typeNotificationText}}</div>
      </div>
    </div>

      <div *ngIf="false" class="element-wrapper d-flex">
        <input class="custom-checkbox"
          type="checkbox"
          id="additionalRelatedParties"
          name="additionalRelatedPartiesN">
        <label class="custom-checkbox-label"
          for="additionalRelatedParties">This profile has no additional related parties</label>
      </div>

    <div class="element-wrapper" *ngIf="
    !showAdditionalInformation && !confirmationMode ">
    <hr class="hr-line"/>
      <span class="wrapper d-flex flex-row">
        <span class="mx-auto">
          <button
            (click)='selectedProfile.type==="legal" ? processProfileInfo($event) : processProfileInfo($event)'
            [attr.clickmethod]="element.params.submitMethod"
            class="form-button form-button-1 mr-0"
          > {{'common.continue' | translate}} </button>
        </span>
      </span>
      <div class="button-group-error-message button-error-field"></div>
    </div>

    <div class="element-wrapper" *ngIf="!showRelatedProfile && confirmationMode">
      <span class="wrapper d-flex flex-column ">
        <p class="confirmation-p">      {{'ARPCS.content6' | translate}}
        </p>
        <div class="d-flex ml-auto">
          <span >
            <button
              (click)='processProfileInfo($event)'
              [attr.clickmethod]="element.params.submitMethod"
              class="form-button form-button-1 mr-0"
            > {{'common.yesContinue' | translate}}</button>
          </span>
          <span >
            <button
              (click)='backToEditing()'
              [attr.clickmethod]="element.params.submitMethod"
              class="form-button form-button-6 pr-3 mr-0"
            > {{'common.goBack' | translate}} </button>
          </span>
        </div>
      </span>
      <div class="button-group-error-message button-error-field"></div>
    </div>

    <div class="error-field no-selected-error" style="line-height:1; margin-top:15px; text-align: right; font-weight : bold" *ngIf="kompanySearchInProgressError">
      {{'ARPCS.content10' | translate}}
    </div>
  </div>
  <!-- END MAIN PROFILE -->
  <!-- RELATED PARTY STEPS -->
  <div [hidden]="multipleStepManagement.stepType !== 'relatedParty' && multipleStepManagement.stepType !== 'finalize'" >
    <h1 style="color:#00889c; text-transform: none;" class="text-align-left font-weight-bold ">{{multipleStepManagement.title}}</h1>

    <div class="d-flex element-wrapper cdd-pcs-kompany flex-column">
      <div class="result-paragraph "
      [ngClass]="{'text-left': !(multipleStepManagement.stepType === 'finalize' && !relatedProfiles.length)}"
      [innerHTML] = "
       multipleStepManagement.stepType === 'finalize' && !relatedProfiles.length ?
       multipleStepManagement.alternateSubtitle
       : multipleStepManagement.subtitle"></div>
    </div>

    <div *ngIf="multipleStepManagement.stepType !== 'finalize' && resetExplanationToggle" class="form-clarification my-4 p-3">
      <div  href="#relatedPartyExplanation" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="multiCollapseExample1" class="d-flex collapse-header">
        <img src="assets/images/book_icon_blue.svg" width="20px" height="20px" class="icon-tooltip">
        <h2  class="form-clarification-title pl-2 mb-0">
        {{multipleStepManagement.dropdownTitle}}
      </h2>

      <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown">
    </div>
    <div class="collapse clarification-content show" id="relatedPartyExplanation" >
        <p class="result-paragraph pt-3 px-2">
          {{multipleStepManagement.dropdownText}}
        </p>
      </div>
    </div>


    <div class="addedRelatedProfiles custom-table"  *ngIf="multipleStepManagement.relatedProfiles.length > 0">
      <p style="font-weight: 700; color : #1a93a5 ">{{multipleStepManagement.listTitle}}</p>
      <table>
        <thead>
          <tr>
            <td>{{'common.type' | translate}}</td>
            <td>{{'common.name' | translate}}</td>
            <td>{{'common.role' | translate}}</td>
            <td></td>
            <td  *ngIf="multipleStepManagement.stepType === 'finalize'" >Type</td>
          </tr>
        </thead>
        <tr *ngFor="let profile of multipleStepManagement.relatedProfiles; let i = index">
          <td *ngIf="profile.type === 'legal'">    <img title="Legal person" style="width: 22px;" src="assets/images/ico-legal-person-gray.svg"/></td>
          <td *ngIf="profile.type === 'natural'">  <img title="Natural person"style="width: 22px;" src="assets/images/ico-natural-small.svg"  /></td>
          <td>{{profile.legalName}} {{profile.naturalFirstName}} {{profile.naturalLastName}}</td>
          <td *ngIf="profile.type === 'legal'">{{('ARRelatedParty.allowedRelatedPartyType.'+ profile.legalRole) | translate}}</td>
          <td *ngIf="profile.type === 'natural'">{{('ARRelatedParty.allowedRelatedPartyType.'+ profile.naturalRole) | translate}}</td>
          <td>
            <a href="#" *ngIf="multipleStepManagement.stepType !== 'finalize'" class="delete-rp-icon d-flex"
            [attr.profileIndex] = 'i'
            (click)="removeRelatedProfile($event, profile)">
              <img class="icon-normal" src="assets/images/delete-document.svg" width="16px"/>
              <img class="icon-red" src="assets/images/delete-document-red.svg" width="16px"/>
            </a>
          </td>
          <td *ngIf="multipleStepManagement.stepType === 'finalize'" >{{profile.relatedPartyTypeName}}</td>
        </tr>
      </table>
    </div>

    <div class="addRelatedProfile"  *ngIf="!!selectedProfile.type
        && multipleStepManagement.stepType !== 'finalize'" [ngClass]="{'show-related-profile': showRelatedProfile}">
      <div class="element-wrapper showOrHideDetails" [ngClass]= "element.class ? element.class : ''" >
        <a (click)="onShowRelatedProfile($event)"  class="more-details-button" *ngIf="!showRelatedProfile">
          <img  src="/assets/images/ico_green.svg">
          <span>{{multipleStepManagement.buttonLabel}}</span>
        </a>
        <app-related-party
          *ngIf="showRelatedProfile"
          [element]=element
          [index]="relatedProfiles.length"
          [mainTitle] = "multipleStepManagement.buttonLabel"
          (contentReturn)="onAddRelatedProfile($event)"
          [allowedProfileType] = "multipleStepManagement.allowedProfileType"
          [allowedRelatedPartyType] = "multipleStepManagement.allowedRelatedPartyType"
          [alternateCancelText] = "multipleStepManagement.alternateCancelText"
          [hideCancelButton] = "multipleStepManagement.hideCancelButton"
          class="rp-block"
        ></app-related-party>
      </div>
    </div>
      <div [ngClass]="{'disable-click': showRelatedProfile}" class="element-wrapper">
        <span class="wrapper d-flex flex-column ">
          <div class="d-flex mx-auto">
            <span >
              <button
                (click)='goToPreviousStep()'
                class="form-button form-button-6 pr-3 mr-0"
              > {{'common.goBack' | translate}} </button>
            </span>
            <span >
              <button
                (click)='multipleStepManagement.stepType === "finalize" ? finalizeSteps($event) : goToNextStep()'
                [attr.clickmethod]="element.params.submitMethod"
                class="form-button form-button-1 mr-0"
              > {{ (multipleStepManagement.stepType === 'finalize' ?
                  'common.acceptAndContinue' : 'common.next') | translate}}</button>
            </span>
          </div>
        </span>
        <div class="button-group-error-message button-error-field"></div>
      </div>
  </div>
  <!-- END RELATED PARTY STEPS -->

  <!-- FINISHING STEP OVERVIEW -->
  <!-- <div [hidden]="multipleStepManagement.stepType !== 'finalize'">
    <h1>Overview</h1>
  </div> -->
</div>
