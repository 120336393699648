import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';
import { commentsOnWhitelistingHit } from 'src/app/id-verification/id-verification-const';
import { UiService } from 'src/app/ui/ui.service';

@Component({
  selector: 'app-preview-window-cdd-sharing',
  templateUrl: './preview-window-cdd-sharing.component.html',
  styleUrls: ['./preview-window-cdd-sharing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewWindowCddSharingComponent implements OnInit, OnDestroy {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  reasonDialogObs;
  commentsOnWhitelistingHit : string[];

  reasonDialogueType; // what mode is the "reason for action" dialogue set too, the default is expiry

  constructor(
    public elementService: ElementsService
  , public idVerificationService: IdVerificationService
  , private helperService: HelperServiceService
  , public translate: TranslateService
  , public uiService: UiService
  ) { }


  ngOnInit(): void {
    this.reasonDialogueType = 'expiry';
    this.reasonDialogObs = this.idVerificationService.detectPreviewWindowPopUp.subscribe(
      status => {
        $('.expire-lightbox .loading-icon').addClass('d-none');
        this.reasonDialogueType = status;
      }
    );
    if(this.element.params.apsData)
    {
      this.idVerificationService.setAPSSTatusses(this.element.params.statuses)
      this.idVerificationService.setAPSFields(this.element.params.fieldTypes);
      this.idVerificationService.sortingHitsOnComplianceCheck();
      this.idVerificationService.calculatingWhitelistedHits();
    }
    this.commentsOnWhitelistingHit = commentsOnWhitelistingHit;
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onDisplayLoader(emitPayload: boolean) {
      if (emitPayload) {
        this.displayLoader.emit(emitPayload);
      }
    }


    ngOnDestroy() {
      this.reasonDialogObs.unsubscribe()
      $('.downloadable-iframe-preview').each((index, iframe) => {
        window.URL.revokeObjectURL(iframe.getAttribute('src'));
      });
    }
    async onAcceptEVDConfirm (event) {
      console.log(this.element);
      $('#evidenceAcceptDocument').addClass('download-loading');
      await this.idVerificationService.acceptOrRejectDV(
        this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveInvestor(),
      this.element.params.evidenceKey,
      true,
      true
    );
  }
    async onRejectEVDConfirm (event) {
      console.log(this.element);
      $('#evidenceRejectDocument').addClass('download-loading');
      await this.idVerificationService.acceptOrRejectDV(
        this.idVerificationService.getActiveProject()
      , this.idVerificationService.getActiveInvestor(),
      this.element.params.evidenceKey
      , false,
      true
    );
  }


}
