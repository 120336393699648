<header class="d-flex flex-column {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}">

  <div class="header-top d-flex justify-content-between">
      <div class="navbar p-0 mx-auto text-center">
        <a [routerLink]="['/']" class="navbar-brand"><img class="finos-logo-img" src ="assets/images/meshId_logo.svg" ></a>
      </div>
  </div>
  <div class="id-verification-check-container d-flex flex-column justify-content-center mt-5">
    <h1 class="text-center m-0">{{ headerInfo['headerTitle'] }}</h1>
    <div class="ic-input-container d-flex flex-column mx-auto justify-content-center">
        <div class="d-flex title">Enter verification code</div>
        <div class="d-flex position-relative">
          <input
            type="text"
            class="ic-input max-w-8"
            maxlength="8"
            placeholder="12345678"
            id="seg1"
            value="{{verificationKeySegments[0]}}"
            (keyup)="movecur($event, 8)"
          />
          <span class="dash d-flex align-items-center mx-1">-</span>
          <input
            type="text"
            class="ic-input max-w-4"
            maxlength="4"
            placeholder="1234"
            id="seg2"
            value="{{verificationKeySegments[1]}}"
            (keyup)="movecur($event, 4)"
          />
          <span class="dash d-flex align-items-center mx-1">-</span>
          <input
            type="text"
            class="ic-input max-w-4"
            maxlength="4"
            placeholder="1234"
            id="seg3"
            value="{{verificationKeySegments[2]}}"
            (keyup)="movecur($event, 4)"
          />
          <span class="dash d-flex align-items-center mx-1">-</span>
          <input
            type="text"
            class="ic-input max-w-4"
            maxlength="4"
            placeholder="1234"
            id="seg4"
            value="{{verificationKeySegments[3]}}"
            (keyup)="movecur($event, 4)"
          />
          <span class="dash d-flex align-items-center mx-1">-</span>
          <input
            type="text"
            class="ic-input max-w-12"
            maxlength="12"
            placeholder="123456789012"
            id="seg5"
            value="{{verificationKeySegments[4]}}"
          />
          <button class="ic-check-button ml-2"
            (click)="OnSubmitVerificationKeyForReview()"
          >Check Validity</button>
        </div>
        <!-- <div class=""> -->
          <button class="ic-check-button mobile mx-auto mt-4"
              (click)="OnSubmitVerificationKeyForReview(true)"
            >Check Validity</button>
        <!-- </div> -->
    </div>
  </div>
  <app-id-verification-result [contentDOM]="contentDOM" style="z-index: 1;" (closeAlert)="closeAlert()" class="id-verification-result-wrapper"></app-id-verification-result>


</header>
<app-footer></app-footer>
