<app-header
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  (selectedCompany)='OnSelectedCompanyChange($event)'
  (searchTerm)="onSearchTermChanged($event)"></app-header>
<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formSubTitle]='formSubTitle'
  [contentDOM]="lightboxContent"
  [btnOkText]='btnOkText'
  [formValidator] = 'formValidator';
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
  <section class="search-history d-flex flex-column justify-items-center">
    <div class="search-history-container">
        <h4 class="search-history-title">
            {{shTitle}}
        </h4>
        <!-- ENTRIES WRAPPER -->

        <div class="search-history-entries">
            <!-- ENTRY 1 NO RISK-->
            <div class="search-history-entry" [attr.key]="item.key" *ngFor="let item of searchHistory" (click)="onGoToIntegrityCheck($event)">
              <p class="entry-date">
                  {{ item.record.createdOn | date:'dd.MM.yyyy' }}
              </p>
              <h2 class="entry-title">
                  {{item.record.company.businessName}}
              </h2>
              <h2 class="entry-title" *ngIf="!item.record.company.businessName">
                  {{item.record.company.kvkNumber}}
              </h2>
              <p class="entry-comment" *ngIf="item.record.noOfFlags == 0">
                Integrity risk flag NOT found
              </p>
              <p class="entry-comment" *ngIf="item.record.noOfFlags > 0">
                Integrity risk flag found
              </p>
              <div [ngClass]="['entry-marker', (item.record.noOfFlags == 0) ? 'no-risk-entry-marker' : 'risk-entry-marker' ]">
              </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
