import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { IntegrityService } from 'src/app/integrity-check/integrity.service';
import { HelperServiceService } from 'src/app/helper-service.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit, OnChanges {
  public companySearchTerm: string;
  public fallbackKVK: string;
  private kvkForSubmit: string;
  private tmoSearch: any;
  public companyDetails: any;
  public contentDetails: any;
  private kvkServiceUnavailable: boolean;
  @Input()  kvkResults: [];
  @Output() searchTerm = new EventEmitter<string>();
  @Output() selectedCompany = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();

  constructor(private integrityService: IntegrityService, private helper: HelperServiceService) { }

  ngOnInit() {
    this.setKvkService(false); // it is expected that by default kvk service is available
    console.log('kvk results: ', this.kvkResults);

    this.integrityService.currentCompanyDetails.subscribe( details => {
      console.log('company details: ', details);
      this.companyDetails = details;
      this.parseCompanyDetails(details);
    });

    this.integrityService.kvkStatusChange.subscribe((response) => {
        if (response.trigger !== 'companySearch') {
          // this call is not for me
          return;
        }
        const status = response.status;
        if (status === undefined) {
          // do nothing
          return;
        }
        console.log('CHANGING KVK STATUS TO', status);
        this.setKvkService(status);
      },
      error => {
        // do nothing
        return;
      }
    );
  }

  OnAutoSuggest(event: any) {
    clearTimeout(this.tmoSearch);
    const _this = this;
    this.tmoSearch = setTimeout(
      function() {
        _this.searchTerm.emit(event.target.value);
      }, 300
    );
  }

  setKvkService(state) {
    this.kvkServiceUnavailable = state;
  }
  getKvkServiceState() {
    return this.kvkServiceUnavailable;
  }

  OnSelectCompanyForReview(event: any) {
    event.preventDefault();
    this.displayLoader.emit(true); // display the loading screen
    this.kvkForSubmit = event.target.parentNode.getAttribute('kvkNumber');
    this.companySearchTerm = event.target.parentNode.getAttribute('companyName');
    this.kvkResults = null;
    if (this.integrityService
        .setSelectedCompanyForFlag({ 'kvkNumber' : this.kvkForSubmit, 'companyName' : this.companySearchTerm })) {
          // this.selectedCompany.emit( { 'kvkNumber' : this.kvkForSubmit, 'companyName' : this.companySearchTerm } );
          this.integrityService.getCompanyDetails(this.kvkForSubmit);
        } else {
          // this.forceCancelFlag('A company could not be selected for flaging!');
          this.selectedCompany.emit( { 'error' : 'forceStop', 'message' : 'A company could not be selected for flaging!' } );
        }
  }

 parseCompanyDetails(result) {
    this.displayLoader.emit(false); // hide the loading screen
    // display the details form
    this.contentDetails = [{
      'type': 'title',
      'params': {
        'content': 'Latest KVK Information'
      }
    },
    {
      'type': 'tabbed_content',
      'params': {
        'tabs': [
          {
            'id': 'general_information',
            'title': 'General information',
            'status': 'active',
            'content': [
              { 'key': 'Kvk number', 'content' : result.kvkNumber }, // '22517301',
              { 'key': 'Place', 'content' : result.address.city }, // 'Rotterdam',
              { 'key': 'Activities', 'content' : this.helper.parseActivities( result.businessActivities ), 'type': 'html' },
              { 'key': 'Address', 'content' : ((result.address.street !== undefined) ? (result.address.street + ' ') : '')
                        + ((result.address.houseNumber !== undefined) ? (result.address.houseNumber + ', ') : '')
                        + ((result.address.postalCode !== undefined) ? (result.address.postalCode + ' ') : '')
                        + ((result.address.city !== undefined) ? (result.address.city) : '') }
            ]
          },
        ]
      }
    },
    {
      'type': 'anchor',
      'params': {
        'content': 'See more detailed information about this company',
        'url': 'https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer='
                + (result.kvkNumber
                + '000000000000').substring(0, 12),
        'open_new_tab': true,
        'external': true
      }
    },
  ];
  }

  ngOnChanges(changes) {
    console.log('VO HEADER SEARCH: ', changes.kvkResults.currentValue);
  }

}
