<section class="form-wrapper d-flex flex-column justify-items-center mx-auto">
  <div class="mb-2 mt-3 filter-element mx-auto d-flex justify-content-between align-items-center w-100">
    <h2>LIST OF QUESTIONNAIRES</h2>
    <div class="filter-options">
      Filter By:
      <select>
        <option selected value="all">All</option>
        <option value="published">Published</option>
        <option value="unpublished">Drafts</option>
      </select>
    </div>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-end mt-4 mb-5">
    <div class="mr-0 actionsMenuWrapper position-relative unselectable">
      <a href="#" class="d-flex actionsMenuBtn justify-content-center align-items-center" (click)="toggleActionsMenu($event)">
        <img src="assets/images/icon-action-plus.svg" class="mr-1"><span>Actions</span>
      </a>
      <ul class="d-none">
        <li
        (click)="$event.preventDefault(); CreateNewQ(); toggleActionsMenu($event)">
        <div>Create new</div>
        </li>
        <!-- <li
        (click)="$event.preventDefault(); CreateNewForm(); toggleActionsMenu($event)">
        <div>Import from file</div>
        </li> -->
      </ul>
    </div>
  </div>
  <div *ngIf="myOrganisationTemplates && myOrganisationTemplates.length > 0" class="templates-wrapper pb-5">
    <div
      class="card d-flex flex-row align-items-between"
      *ngFor="let template of myOrganisationTemplates"
      [ngClass]="{'disabled': (template.key == 'defaultContractTemplate' || template.mode === 'empty'), 'published': template.published,
      'draft': !template.published, 'empty': template.type === 'empty'}"
    >
      <div
        class="d-flex flex-fill align-items-center body"
        [attr.data-template-key]="template.key"
        [attr.data-template-name]="template.name"
        [attr.data-template-mode]="template.mode"
        (click)="OnFormEdit($event)"
      >
        <img src="/assets/images/ico-templates.svg" width="16px" height="20px" class="mr-2" *ngIf="template.type=='single'"/>
        <img src="/assets/images/ico-templates-multiple.svg" width="16px" height="20px" class="mr-2" *ngIf="template.type!='single'"/>
        {{template.name}}
      </div>
      <div class="menu-wrapper d-flex pt-2">
        <!-- <a href="#" class="settings-icon justify-content-end align-items-end ml-auto mr-0" (mouseover)="DisplayMyOptions($event)" (mouseleave)="HideMyOptions($event)"> -->
        <a href="#" class="settings-icon justify-content-end align-items-end ml-auto mr-0">
          <!-- <img src="/assets/images/ico-settings.svg" width="20px" height="20px" alt="d-flex settings icon"> -->
          <div class="d-flex flex-column more-options"></div>
        </a>
        <ul class="submenu flex-row align-items center">
          <li class="mr-2" *ngIf="template.key !== 'defaultContractTemplate' && !isBroken(template)">
            <a href="#" (click)="OnFormEdit($event)"><img src="/assets/images/ico-edit-template.svg" alt="Edit template" width="20px" height="20px" class="mr-1" /></a>
            <div class="callout">Edit template</div>
          </li>
          <li class="mr-2" *ngIf="!isBroken(template)">
            <a href="#" (click)="OnCopyFrom($event)"><img src="/assets/images/ico-clone-template.svg" alt="Clone template" width="20px" height="20px" class="mr-1" /></a>
            <div class="callout">Clone template</div>
          </li>
          <li class="mr-2" *ngIf="template.key != 'defaultContractTemplate' && !isBroken(template)">
            <a href="#" (click)="publishToggle($event, template.published, template.key)">
              <img *ngIf="template.published" src="/assets/images/ico-unpublish-template.svg" alt="Unpublish template" width="20px" height="20px" class="mr-1 non-interactive" />
              <img *ngIf="!template.published" src="/assets/images/ico-publish-template.svg" alt="Unpublish template" width="20px" height="20px" class="mr-1 non-interactive" />
            </a>
            <div class="callout">{{ template.published ? 'Unpublish template' : 'Publish template' }}</div>
          </li>
          <li class="mr-2" *ngIf="template.key != 'defaultContractTemplate'">
            <a href="#" (click)="OnFormDelete($event)"><img src="/assets/images/ico-delete-template.svg" alt="Delete template" width="20px" height="20px" class="mr-1" /></a>
            <div class="callout">Delete template</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="(myOrganisationTemplates && myOrganisationTemplates.length == 0)" style="color: #767676;" class="empty-message text-center mt-2 font-italic">
    There are currently no questionnaires to display
  </div>
</section>


<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>

<!-- <app-content-builder [contentDOM]="" (contentReturn)='parseContentReturn($event)'></app-content-builder> -->
