import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild,
         ElementRef, ChangeDetectorRef, AfterContentInit, OnDestroy } from '@angular/core';
import { IntegrityService } from 'src/app/integrity-check/integrity.service';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { HelperServiceService } from 'src/app/helper-service.service';
import { UiService } from '../../ui.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
// import {  } from '@types/googlemaps';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { IpServiceService } from 'src/app/ip-service.service';
import { ElementsService } from './components/elements.service';
import { first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

declare var google: any;


@Component({
  selector: 'app-content-elements',
  templateUrl: './content-elements.component.html',
  styleUrls: ['./content-elements.component.scss']
})
export class ContentElementsComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {

  model: any = {};
  @Input()  kvkResults: any[];
  @Input()  triggerValidation: any;
  @Output() searchTerm = new EventEmitter<string>();
  @Output() selectedCompany = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();

  @Input() contentDOM: any[];
  @Output() contentReturn = new EventEmitter<any>();
  @Output() elementChangesCompleted = new EventEmitter<any>();  // trigered when content is updated
  image: string | ArrayBuffer;
  elements: any;
  autocomplete: any;
  @ViewChild('gautocomplete') addresstextElement: ElementRef;
  map: google.maps.Map;
  @ViewChild('mapWrapper') mapElement: ElementRef;
  addressImageURL: string;
  private observerHack = new MutationObserver(() => {
    this.observerHack.disconnect();
    // this.addresstextElement.nativeElement.setAttribute('autocomplete', 'street-address');
    this.addresstextElement.nativeElement.setAttribute('autocomplete', 'chrome-off');
  });
  stepMethod;
  unsubscribe$: Subject<void> = new Subject<void>();
  toggleBfCheckbox = true;
  tinyHtml='';
  projectType='';

  constructor(
      private integrityService: IntegrityService
    , private helperService: HelperServiceService
    , private uiService: UiService
    , private idVerificationService: IdVerificationService
    , private ref: ChangeDetectorRef
    , private sanitizer: DomSanitizer
    , private ipService: IpServiceService
    , private elementService: ElementsService
    , public translate: TranslateService
    , private router: Router,) { }

  ngOnInit() {

    if (this.router.url.includes('digital/verifications')) {
      this.projectType = 'funds';
    } else if (this.router.url.includes('verifications')) {
      this.projectType = 'id-verification';
    }

    this.map = null;
    this.elements = this.contentDOM;
    this.image = '';
    // this.elementService
    //       .returnOnClickAction
    //       .pipe(takeUntil(this.unsubscribe$))
    //       // .pipe(first())
    //       .subscribe(
    //         emitPayload => { this.contentReturn.next(emitPayload); console.log('&&&&&&EMITING!!!')}
    //       );
    // check if displayGoogleMap is present in contentDOM and invoke map:
    if (this.elements) {
      this.elements.map(element => {
        // console.log('this is the element', element);
        if (element.type === 'displayGoogleMap' && element.params.latitude && element.params.longitude) {
          this.initializeMap(element.params.latitude, element.params.longitude);
        }
      });
    }
  }

  ngAfterContentInit() {
    // code for custom evidence type counter
    $('.custom-evidence-types-container:last .custom-evidence-delete-button').on('click', function() {
      $(this).closest('.custom-evidence-types-container').addClass('marked-for-delete');
      $('.custom-evidence-counter-container .button-minus').trigger('click');
    });

    // if it contains element for the review upload side, give sign that the content is loaded
    if (this.elements && this.elements.length) {
      if (this.elements.find(element => element.type === 'documentUploaderSide') ||
         this.elements.find(element => element.type === 'documentUploaderSideDigitalEvidence')) {
        this.idVerificationService.reviewDataLoadedIn.next();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges (changes) {
    this.elements = this.contentDOM;
    this.elementChangesCompleted.emit();
  }

  isString(obj) {
    if (typeof obj === 'string') {
      return true;
    }
    return false;
  }

  counterLabelClick(event) {
    const input = $(event.target).closest('.custom-counter').find('.custom-counter-count');
  }

  /**
   * this method will get executed on any button click
   * @param event: any
   */
  OnButtonClick(event, additionalParam = '') {
    $('.submit-fields-general-error').hide();
    $('.button-error-field').hide();
    $('.button-group-error-message').hide();
    if ($(event.target).attr('type') && $(event.target).attr('type') === 'submit') {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log('**not all fields are valid');
        event.preventDefault();
        $('.submit-fields-general-error').show();
        $('.button-error-field').show();
        $('.button-group-error-message').show();
        return;
      }
    }
    let eventTarget = event.target;
    if (eventTarget.nodeName !== 'A'
        && eventTarget.nodeName !== 'BUTTON'
        && eventTarget.nodeName !== 'TR'
        && eventTarget.nodeName !== 'DIV') {
          eventTarget = $(eventTarget).closest('a'); // for now only support fallback to anchor elements to cover the svg scenario
          if (eventTarget.length > 0) {
            eventTarget = eventTarget[0];
          }
    }
    event.preventDefault();
    const method = eventTarget.getAttribute('clickmethod');
    if (method === '' || method === null) {
      return;
    }
    this.contentReturn.emit({
      'method' : method,
      'params' : additionalParam ? {event, additionalParam} : event
    });
  }

  /**
   * handle click when anchor is complex element
   * it will always attempt to find closest anchor element
   */
  OnComplexAncorClick(event, additionalParam = '') {
    if ($(event.target).attr('type') && $(event.target).attr('type') === 'submit') {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log('**not all fields are valid');
        event.preventDefault();
        return;
      }
    }
    let eventTarget = event.target;
    if (eventTarget.nodeName !== 'A') {
          eventTarget = $(eventTarget).closest('a'); // for now only support fallback to anchor elements to cover the svg scenario
          if (eventTarget.length > 0) {
            eventTarget = eventTarget[0];
          }
    }
    if (typeof eventTarget.getAttribute !== 'function') {
      return;
    }
    event.preventDefault();
    const method = eventTarget.getAttribute('clickmethod');
    if (method === '' || method === null) {
      return;
    }
    this.contentReturn.emit({
      'method' : method,
      'params' : additionalParam ? {event, additionalParam} : event
    });
  }

  OnReviewButtonClick(event, additionalParam = '', performCheck? , isBasicFields?, isBasicFieldRP?) {
    let skipCheck;
    $('.submit-fields-general-error').hide();
    if ($('#continue_rp_with_new_info').length && !$('#continue_rp_with_new_info').is(':checked') && isBasicFieldRP ) {
      skipCheck = true;
    }
    if ($('#transfer_to_pcs_bf').length && !$('#transfer_to_pcs_bf').is(':checked')) {
      skipCheck = true;
    }
    if(
      ($('#continue_rp_with_new_info').length && $('#continue_rp_with_new_info').prop('checked')) &&
      ($('#transfer_to_pcs_bf').length && !$('#transfer_to_pcs_bf').is(':checked')) &&
      ($('#continue_with_new_info_bf').length && !$('#continue_with_new_info_bf').is(':checked'))
      ) {
        return;
    }
    if (($('#continue_with_new_info').is(':checked') || performCheck) && !skipCheck) {
      if ($(event.target).attr('type') && $(event.target).attr('type') === 'submit') {
        // CHECK WITH VALIDATOR
        if (!this.uiService.validateLightboxFields(event)) {
          console.log('**not all fields are valid');
          event.preventDefault();
          // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
          // WE ARE ADDING IT AGAIN
          if (!$('.evidence-list-image-container').length && !isBasicFields && !skipCheck) {
            event.preventDefault();
            $('.error-field-required-image').removeClass('d-none');
            $('.dropzone').addClass('error-required');
          }
          $('.submit-fields-general-error').show();
          return;
        }
        // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
        if (!$('.evidence-list-image-container').length && !isBasicFields && !skipCheck) {
          event.preventDefault();
          $('.error-field-required-image').removeClass('d-none');
          $('.dropzone').addClass('error-required');
          console.log('**no image');
          $('.submit-fields-general-error').show();
          return;
        }
      }
    }
    let eventTarget = event.target;
    if (eventTarget.nodeName !== 'A'
        && eventTarget.nodeName !== 'BUTTON'
        && eventTarget.nodeName !== 'DIV') {
          eventTarget = $(eventTarget).closest('a'); // for now only support fallback to anchor elements to cover the svg scenario
          if (eventTarget.length > 0) {
            eventTarget = eventTarget[0];
          }
    }
    event.preventDefault();
    const method = eventTarget.getAttribute('clickmethod');
    $('.review-flow-add-spinner').removeClass('d-none')
    this.contentReturn.emit({
      'method' : method,
      'params' : additionalParam ? {event, additionalParam} : event
    });
  }

  onReplaceButtonClick(evidenceKey) {
    console.log('replaceButtonClick');
  }

  OnButtonClickReviewGroup(requestKey, evidence, action) {
    if (action === 'initiateCreateNewDocument') {
      this.idVerificationService.createNewDocumentWithSPData(requestKey, evidence);
    } else {
      this.contentReturn.emit({
        'method' : action,
        'params' : requestKey ? {event, requestKey} : event
      });
    }
  }
    /**
   * this method will get executed on the Next button during evidence upload/review
   * @param event: any
   */
  OnButtonClickNext(event) {
    const eventTarget = event.target;
    event.preventDefault();
    const method = eventTarget.getAttribute('clickmethod');
    this.contentReturn.emit({
      'method' : method,
      'params' : event
    });
  }

  /**
   * toggle visibility of group of fields on click of a link or button
   */
  OnToggleContentFields(event) {
    event.preventDefault();
    $('#' + event.target.getAttribute('toggleTarget')).toggle();
  }

  toggleClass(elementId, classToSwitch, event?) {
    $($('div[data-purposeEvidenceKey = ' + '"' + elementId + '"' + ']')).toggleClass(classToSwitch);
    $('.purposes-button-form').toggleClass('collapsed');
  }

  /**
   * toggle visibility of read more excerpt that will toggle into place
   */
  OnToggleExcerptFields(event) {
    event.preventDefault();
    $(event.target).prev().hide();
    $(event.target).hide();
    $(event.target).next().show();
  }

  /**
   * it will be executed on click on any content element having attribute clickaction
   * @param event event
   */
  OnContentElementClick(event) {
    this.uiService.clearInvalidation($(event.target).closest('.checkbox-container'));
    if ($(event.target).prop('tagName') === 'INPUT' && $(event.target).attr('type') === 'checkbox') {
      // console.log('calling checkbox validator');
      this.uiService.validateCheckboxGroup(event);
    }
    if (typeof $(event.target).attr('clickaction') !== 'undefined') {
      if (typeof $(event.target).attr('preventdefault') !== 'undefined' && $(event.target).attr('preventdefault') === 'true') {
        event.preventDefault();
      }
      this.contentReturn.emit({
        'method' : $(event.target).attr('clickaction'),
        'params' : event
      });
    }
  }

  /**
   * it will be executed on click on any content element having attribute changemethod
   * @param event event
   */
  OnChangeSelect(event) {
    // first validate
    this.uiService.clearInvalidation($(event.target));
    if (!this.uiService.validateSelect(event)) {
      event.preventDefault();
      return false;
    }
    if (typeof $(event.target).attr('changemethod') !== 'undefined') {
      if (typeof $(event.target).attr('preventdefault') !== 'undefined' && $(event.target).attr('preventdefault') === 'true') {
        event.preventDefault();
    }
      this.contentReturn.emit({
        'method' : $(event.target).attr('changemethod'),
        'params' : event
      });
    }
  }

  onChangeNotes(event) {
    const value = $(event.target).val();
    $(event.target).val(this.helperService.sanitizePropery(value));
  }

  // in evidence forms
  onSelectPurpose(event, dontHideContainer?) {
    // checkbox is just checked
    // show the tag
    const checkbox = $(event.target);
    const checkboxId = $(checkbox).attr('id');
    if ($(checkbox).is(':checked')) {
      $('.purpose-tag[data-purposeId="' + checkboxId + '"]').removeClass('d-none');
      // if it's just unchecked
      // hide the tag
    } else {
      $('.purpose-tag[data-purposeId="' + checkboxId + '"]').addClass('d-none');
   }
   if (!dontHideContainer) {
    $('.purposes-checkbox-list').addClass('d-none');
   }
   $('.submit-fields-general-error').hide();
  }
  // in evidence forms
  onPurposeDelete(event) {
    const checkboxTag = $(event.target).closest('.purpose-tag');
    const checkboxId = $(checkboxTag).attr('data-purposeId');
    $(checkboxTag).addClass('d-none');
    $('.purpose-checkbox[data-purposeId="' + checkboxId + '"]').prop('checked', false);
  }
  onPersonTagDelete(event) {
    $(event.target).find('img').attr('src', '/assets/images/ajax-loader-white.gif');
    $(event.target).attr('src', '/assets/images/ajax-loader-white.gif');
    $(event.target).closest('.purpose-tag-wrapper').find('.close-button').addClass('delete-disabled');
    const tagString = $(event.target).closest('.purpose-tag').attr('tag-value');
    this.idVerificationService.deletePersonTag
    (
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      tagString,
      event.target
    );
  }
  // since this tag is unaded in the state we just delete the html element
  onPersonTagUnadedDelete(event, onAddPerson?) {
    $(event.target).closest('.purpose-tag').remove();
    const tagString = $(event.target).closest('.purpose-tag').attr('tag-value');
    if (onAddPerson) {
      this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
        this.idVerificationService.getActiveLegalRepresentativesUnadedTags().filter(t => t !== tagString )
      )
     ;
    } else {
      this.idVerificationService.getActivePersonDetails()['record'].unaddedTags =
      this.idVerificationService.getActivePersonDetails()['record'].unaddedTags.filter(t => t !== tagString );
    }
  }

  onSearchTermChanged(event) {
    if (event === '') {
      this.kvkResults = null;
      // this.cd.detectChanges();
      return;
    }
    this.integrityService.getIntegrityCheckResults(event)
      .subscribe((data: any) => {
        if (data === undefined || data.length === 0) {
          data = null;
        }
        this.kvkResults = data.companies;
        // this.kvkResults = data;
        // this.cd.detectChanges();
        },
        error => {
          if (error.status === 503) {
            this.integrityService.setKvkStatusFailed(true, 'companySearch');
          } else {
            console.log('this is the ERROR from subscription to searchCompany', error);
          }
        }
      );
  }

  OnSelectedCompanyChange(event) {
    this.contentReturn.emit(event);
  }

  OnDisplayLoader(event) {
    this.displayLoader.emit(event);
  }

  parseDateContentReturn(event) {
    if (event.params.model !== undefined) {
      this.uiService.clearInvalidation($('#' + event.params.model).find('input'));
    }
    this.contentReturn.emit(event);
  }

  OnImageUploadDragEnter(event) {
    event.preventDefault();
    $(event.target).css('background', '#f6feff');
  }
  OnImageUploadDragOver(event) {
    event.preventDefault();
  }
  OnImageUploadDragLeave(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
  }
  OnImageUploadDrop(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    const file = event.dataTransfer.files[0];
    const match = ['image/jpeg', 'image/png', 'image/gif'];
    if (!((file.type === match[0]) || (file.type === match[1])) || (file.type === match[2])) {
      alert('This file type is not supported.\nOnly jpeg, jpg and png Images type allowed');
      return;
    }
    if (file.size > 5242880 && file.type === 'application/pdf') {
      alert('The file you are trying to upload is too big!\nPlease use images under 5MB');
      return;
    }
    // handle preview
    this.displayImagePreview(file);
    // bubble up
    let eventTarget = $(event.target);
    if (!eventTarget.hasClass('dropzone')) {
      eventTarget = $(event.target).closest('.dropzone');
    }
    const method = eventTarget.attr('uploadmethod');
    this.contentReturn.emit({
      'method' : method,
      'params' : file
    });
    // this.uploadFile(file);
  }

  OnImageUploadClick(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#' + $(event.target).attr('data-target')).trigger('click');
  }

  OnAddressDropFileUpload(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    const files = event.dataTransfer.files;

    // Array.from(files).forEach(file =>{
    //   const match = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    //   if (!((file['type'] === match[0]) || (file['type'] === match[1]) || (file['type'] === match[2]) || (file['type'] === match[3]))) {
    //     alert('This file type is not supported.\nOnly jpg, png and pdf type allowed');
    //     return;
    //     }
    //   if (file['size'] > 5242880 && file['type'] === 'application/pdf') {
    //     alert('The file you are trying to upload is too big!\nPlease use images under 5MB');
    //     return;
    //   }
    // });

    const evidenceKey = ($(event.target).attr('data-evidenceKey'));
    $('input[data-InputEvidenceKey = ' + '"' + evidenceKey + '"' + ']').prop('files', event.dataTransfer.files);
    // this.OnImageUploadAddressVerification($event);
    this.contentReturn.emit({
      'method' : 'addressVerificaitonOnImageUpload',
      'params' : files
    });

  }

  OnDocumentUploadClick(event) {
    event.preventDefault();
    event.stopPropagation();
    const evidenceKey = ($(event.target).attr('data-evidenceKey'));
    $('input[data-InputEvidenceKey = ' + '"' + evidenceKey + '"' + ']').trigger('click');
  }

  OnImageUploadSelected(event) {
    event.preventDefault();
    const file = $(event.target).prop('files')[0];
    const match = ['image/jpeg', 'image/png', 'image/gif'];
    if (!((file.type === match[0]) || (file.type === match[1])) || (file.type === match[2])) {
      alert('This file type is not supported.\nOnly jpeg, jpg and png Images type allowed');
      return;
    }
    if (file.size > 5242880 && file.type === 'application/pdf') {
      alert('The file you are trying to upload is too big!\nPlease use images under 5MB');
      return;
    }
    // handle preview
    this.displayImagePreview(file);
    // bubble up
    let eventTarget = $(event.target);
    if (!eventTarget.attr('uploadmethod')) {
      if (!eventTarget.hasClass('dropzone')) {
        eventTarget = $(event.target).closest('.dropzone');
      }
    }
    const method = eventTarget.attr('uploadmethod');
    this.contentReturn.emit({
      'method' : method,
      'params' : file
    });
  }

  OnImageUploadSelectedEvidence(event, requestKey, evidenceKey, evidenceTypes, flowtype, investorSide, evidence) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    // const file = event.dataTransfer.files[0];
    const files = event.target.files;
    const match = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    for (let i = 0; i < files.length; i++) {
      if (!((files[i].type === match[0]) || (files[i].type === match[1]) || (files[i].type === match[2]) || (files[i].type === match[3]))) {
        alert(this.translate.instant('errors.error12'));
        return;
      }

      // get existing files
      const existingFilesNumber = $('.evidence-list-delete-button').length;
      if ((existingFilesNumber + files.length) >= 6) {
        alert(this.translate.instant('errors.error13'));
        return;
      }


      if (files[i].size > 26214400 && files[i].type === 'application/pdf') {
        alert(this.translate.instant('errors.error14'));
        return;
      }
    }
    if (investorSide) {
      if ($('#continue_with_new_info').is(':checked')) {
        this.idVerificationService.returnEvidenceFieldByKey(evidenceKey, evidenceTypes).reviewStateStelected = 'continue_with_new_info';
      } else {

      }
    }
    let eventTarget = $(event.target);
    if (!eventTarget.attr('uploadmethod')) {
      if (!eventTarget.hasClass('dropzone')) {
        eventTarget = $(event.target).closest('.dropzone');
      }
    }
        // we need to use the RPE key if we're currently in an RPE evidence, this is how we check
    let currentlyActiveRequest =  $('.documentUploaderSide.evidence-is-active').attr('data-requestkey');
    if(this.idVerificationService.isKeyRelatedParty($('.document-category-main.category-active').attr('data-requestkey'))){
      currentlyActiveRequest = $('.document-category-main.category-active').attr('data-requestkey');
    }
    // const currentlyActiveRequest =  $('.document-category-main.category-active').attr('data-requestkey');
    $('.error-field-required-image').addClass('d-none');
    $('.dropzone').removeClass('error-required');
    this.idVerificationService.uploadRequestDocument (
      this.idVerificationService.getActiveProject(),
      currentlyActiveRequest,
      evidenceKey,
      files[0],
      evidenceTypes,
      flowtype
    );
  }
  // IMAGE UPLOAD FOR ADDRESS VERIFICATION USER SIDE
  OnImageUploadAddressVerification(event) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    const files = event.target.files;
    const match = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    this.contentReturn.emit({
      'method' : 'addressVerificaitonOnImageUpload',
      'params' : files
    });
  }

  OnImageUploadDropEvidence(event, requestKey, evidenceKey, evidenceTypes, flowType) {
    event.preventDefault();
    $(event.target).css('background', 'transparent');
    const files = event.dataTransfer.files;
    const file = event.dataTransfer.files[0];
    const match = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    if (!((file.type === match[0]) || (file.type === match[1]) || (file.type === match[2]) || (file.type === match[3]))) {
      alert(this.translate.instant('errors.error12'));
      return;
      }

    // retreive number of current files

    // get existing files
    const existingFilesNumber = $('.evidence-list-delete-button').length;
    if (existingFilesNumber === 5 ) {
      alert(this.translate.instant('errors.error13'));
      return;
    }

    if (file.size > 26214400   && file.type === 'application/pdf' ) {
      alert(this.translate.instant('errors.error14'));
      return;
    }

    let eventTarget = $(event.target);
        // we need to use the RPE key if we're currently in an RPE evidence, this is how we check
    let currentlyActiveRequest =  $('.documentUploaderSide.evidence-is-active').attr('data-requestkey');
    if(this.idVerificationService.isKeyRelatedParty($('.document-category-main.category-active').attr('data-requestkey'))){
      currentlyActiveRequest = $('.document-category-main.category-active').attr('data-requestkey');
    }

    if (!eventTarget.hasClass('dropzone')) {
      eventTarget = $(event.target).closest('.dropzone');
    }

    $('.error-field-required-image').addClass('d-none');
    $('.dropzone').removeClass('error-required');
    this.idVerificationService.uploadRequestDocument (
      this.idVerificationService.getActiveProject(),
      currentlyActiveRequest,
      evidenceKey,
      files[0],
      evidenceTypes,
      flowType
    );

  }

  OnDocumentDelete(evidenceTypes, evidenceKey, document) {
    let requestKey  = this.idVerificationService.getActiveInvestor();
    // we need to use the RPE key if we're currently in an RPE evidence, this is how we check
    if(this.idVerificationService.isKeyRelatedParty($('.document-category-main.category-active').attr('data-requestkey'))) {
      requestKey = $('.document-category-main.category-active').attr('data-requestkey');
    }
    this.idVerificationService.deleteRequestDocument (
     this.idVerificationService.getActiveProject(),
     requestKey,
     evidenceKey,
     document['Key'],
     evidenceTypes,
    );
  }

  // document deletion for address verification
  onDocumentAddressVerificationDelete(document) {
    this.contentReturn.emit({
      'method' : 'addressVerificaitonOnDocumentDelete',
      'params' : document
    });
  }

  displayImagePreview(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      // remove additional classes
      $('.preview-window .finos-logo-img').removeClass('rect-1 rect-2');
      this.image = reader.result;
      $('.preview-window .finos-logo-img').attr('src', reader.result as string);
      // check if we need to add additional classes
      const i = new Image();
      i.src = (reader.result as string);
      i.onload = function() {
        const ratio = i.width / i.height;
        let additionalClass = '';
        if (ratio > 2) {
          additionalClass = 'rect-1';
        }
        if (ratio > 3) {
          additionalClass = 'rect-2';
        }
        $('.preview-window .finos-logo-img').addClass(additionalClass);
       };
    };
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    // simply re-emit the returnedAction
    this.contentReturn.emit(returnedAction);
  }

  OnColorPickerChange(newHexColor) {
    if ($('.preview-window').length !== 0) {
      $('.preview-window').css('background-color', newHexColor);
      // $('.dropzone').css('background-color', newHexColor);
      // $('#projectCustomMessage').css('background-color', newHexColor);
      const textClass = this.helperService.detectTextColor(this.helperService.hexToRgb(newHexColor));
      // $('#projectCustomMessage').removeClass('dark-color light-color').addClass(textClass);
      $('.form-button-clear-bg').removeClass('dark-color light-color').addClass(textClass);
      $('.unauth-message').removeClass('dark-color light-color').addClass(textClass);
      $('#projectEditColor').removeClass('dark-color light-color').addClass(textClass);
      $('.image-upload-instructions').removeClass('dark-color light-color').addClass(textClass);
    }
  }

  refreshIframe() {
    // $('.downloadable-iframe-preview').each(function() {
    //   const tempSrc = $(this).attr('src');
    //   $(this).attr('src', tempSrc);
    // });
  }

    /**
   * call this method when a personalized message is changed (not a submit)
   * @param returnedAction event
   */
  onPersonalizedMessageTextChange(e) {
    $('#projectCustomMessageDisplay').text(this.helperService.returnShortenedUserVerificationMessage(e.target.value));
  }

  /**
   * handle enter action on input field key up
   */
  OnInputKeyUp(event) {
    this.uiService.clearInvalidation(event);
    const keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === '13' || keycode === 13) {
      // enter key is pressed - check if there is action defined for this input field
      if ($(event.target).attr('enterkeyaction')) {
        // emit to be executed
        this.contentReturn.emit({
          'method' : $(event.target).attr('enterkeyaction'),
          'params' : event
        });
      }
    }
  }

  OnChange(event) {
    if ($(event.target).attr('enterChangeAction')) {
      // emit to be executed
      this.contentReturn.emit({
        'method' : $(event.target).attr('enterChangeAction'),
        'params' : event
      });
    }
  }

  /**
   * handle on Blur action
   */
  OnInputBlur(event) {
    // do validation
    this.uiService.validateInput(event);
  }

  onUpdateInvestorFields(evidenceKey, requestKey, documents, noScroll = false, hideActions = false, basicFields) {
    this.idVerificationService.buildEvidenceLightboxReview (
      this.idVerificationService.getActiveProject(),
      requestKey, evidenceKey, noScroll, documents, hideActions, basicFields);
      // ENTER PREVIEW MODE AND THE THIRD LIGHTBOX
  }

  /**
   *
   * document and evidence update function
   * activated troguh the collapsed investor screen
   */
  documentUpdateFunction(event, evidenceTypes, evidence, requestKey, evidenceKeyClicked, relatedPartyObject?) {

    const dvType = $(event.target).closest('.document-inner-entry').attr('data-dvType');
    if (dvType) {
      this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType = dvType;
    }
    // exception case only needed during sharing of RP
    // set the active related party to the party the evidence belongs too
    if (relatedPartyObject && this.idVerificationService.isKeyRelatedParty(requestKey)) {
      let newActiveRP = this.idVerificationService.returnEvidenceFieldByKey(requestKey, relatedPartyObject);
      this.idVerificationService.getActiveInvestorDetaisl()['activeRelatedParty'] = newActiveRP;
      evidenceTypes=this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(
        newActiveRP['record']['evidenceTypes'],
        newActiveRP['record']['digitalVerifications']
      );
      this.idVerificationService.getActiveInvestorDetaisl()['activeRelatedPartyDigitalVerification'] = newActiveRP['record']['digitalVerifications'];
    }
    // end
    this.idVerificationService.setExpandedEvidence(evidenceKeyClicked);
    this.idVerificationService.loadEvidenceFieldAndImages(
      this.idVerificationService.getActiveProject(),
      requestKey,
      evidenceKeyClicked, 'Update',
      evidenceTypes
    );
  }

  /**
   *
   * document and evidence review function
   * activated troguh the collapsed investor screen
   */
  documentReviewFunction(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    this.contentReturn.emit({
      'method': 'startReviewProcess',
      'params': {'evidenceKeyClicked' : evidenceKeyClicked, 'requestKey' : requestKey}
    });
  }
  evidenceNextFunction(evidenceKey, evidenceTypes) {
    // NEED TO SAVE STATES
    $('.document-category-main').addClass('uploading-document');
    $('.documentUploaderSide').addClass('uploading-document');
    const payload = this.idVerificationService.saveFieldsState(evidenceKey, evidenceTypes);
    this.idVerificationService.fillEvidenceFields(this.idVerificationService.getActiveProject(),
          this.idVerificationService.getActiveInvestor(), evidenceKey, evidenceTypes, payload );
  }
   /**
   * sharing informationRequest
   */
  documentShareFunction(requestKey) {
    this.idVerificationService.skipToStep(10, {'requestKey': requestKey});
  }
   /**
   * Creating related party for information request
   */
  createRelatedParty(requestKey) {
    this.idVerificationService.skipToStep(11, {'requestKey': requestKey});
  }
    /**
   * change the tab for the fund document preview step
   */
  changePreviewClass(tabid) {
    // if (tabid === 'previewHistoryTab') {
    //   $('#previewHistoryTab').addClass('active');
    //   $('#previewHistoryTabContent').removeClass('d-none');
    //   $('#previewAccessTab').removeClass('active');
    //   $('#previewAccessTabContentTAB').addClass('d-none');
    // } else if (tabid === 'previewAccessTab') {
    //   $('#previewAccessTab').addClass('active');
    //   $('#previewHistoryTabContent').addClass('d-none');
    //   $('#previewHistoryTab').removeClass('active');
    //   $('#previewAccessTabContentTAB').removeClass('d-none');
    // }
    $('.ev-preview-tab').removeClass('active');
    $('#' + tabid).addClass('active');
    $('.ev-preview-tab-content').addClass('d-none');
    $('#' + tabid + 'Content').removeClass('d-none');
  }


  toggleOverlayAccess() {
    $('.existing-providers-overlay').toggleClass('show');
  }

  // ENTER PREVIEW MODE AND THE THIRD LIGHTBOX
  onReviewEvidenceClick(evidenceKey, requestKey, documents, noScroll = false, hideActions = false, hideAccess?, startingFilename?) {
    this.idVerificationService.buildEvidenceLightboxReview (
        this.idVerificationService.getActiveProject()
      , requestKey, evidenceKey, noScroll, documents, hideActions, undefined, undefined, startingFilename
    );
  }


  /**
   *
   * document and evidence preview function
   * activated troguh the collapsed investor screen
   */
  documentPreviewFunction(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    const stepArguments  = {'evidenceTypes' : evidenceTypes, 'evidence' : evidence,
                            'requestKey' : requestKey, 'evidenceKeyClicked' : evidenceKeyClicked };
    this.idVerificationService.skipToStep(13, [stepArguments]);
    this.displayLoader.emit();
    let evidenceDocs = [];
    if (evidence) {
      evidenceDocs = evidence.documents;
    }
    // retreive the evidence history
    // this.idVerificationService.retreiveEvidenceHistory(this.idVerificationService.getActiveProject(),requestKey,evidenceKeyClicked);
    // this.idVerificationService.retreiveEvidenceAccess(this.idVerificationService.getActiveProject(),requestKey,evidenceKeyClicked);
    this.idVerificationService.buildEvidenceLightbox(this.idVerificationService.getActiveProject(),
                                                     requestKey, evidenceKeyClicked, stepArguments, evidenceDocs);
  }

  /**
   *
   * document and evidence preview function on the CLAIM / SHARE Screen
   * activated troguh the collapsed investor screen
   */
  documentPreviewFunctionOnClaim(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    const stepArguments  = {'evidenceTypes' : evidenceTypes, 'evidence' : evidence,
                            'requestKey' : requestKey, 'evidenceKeyClicked' : evidenceKeyClicked, 'claimScreen' : true };
    this.idVerificationService.skipToStep(13, [stepArguments]);
    this.displayLoader.emit();
    this.idVerificationService.buildEvidenceLightBoxInvestorOnClaim(
      requestKey,
      evidenceKeyClicked,
      stepArguments,
      evidence.documents);
  }

  loadRelatedParty(event, relatedPartyKey) {
    // always make sure you add the loading to the right element no matter what is clicked
    if ($(event.target).children().length === 0) {
      $(event.target).attr('src', '/assets/images/spinner-gray.svg');
    } else {
      $(event.target).children('img').attr('src', '/assets/images/spinner-gray.svg');
    }
    $(event.target).css('pointer-events' , 'none');
    $(event.target).children('img').css('pointer-events' , 'none');

    this.idVerificationService.retreiveRP(this.idVerificationService.getActiveProject(), relatedPartyKey);
  }

  loadRelatedPartyClaim(event, requestKey, relatedPartyKey) {
    if ($(event.target).children().length === 0) {
      $(event.target).attr('src', '/assets/images/spinner-gray.svg');
    } else {
      $(event.target).children('img').attr('src', '/assets/images/spinner-gray.svg');
    }
    $(event.target).css('pointer-events' , 'none');
    $(event.target).children('img').css('pointer-events' , 'none');

    this.idVerificationService.retreiveRelatedPartyClaim(requestKey, relatedPartyKey);
  }

  newDocumentVersion() {
    this.idVerificationService.skipToStep(14, {});
  }

  /**
   * handle on Focus action
   */
  OnInputFocus(event) {
    // do validation
    this.uiService.clearInvalidation($(event.target));
  }

  OnExpandSide(event, idSideElement, evidenceKey, evidenceTypes) {
      this.idVerificationService.localFundCreationData = {};
      // GET CURRENTLY ACTIVE EVIDENCE (BEFORE SWITCH)
      const currentlyActiveEvidence = $('.documentUploaderSide.evidence-is-active').attr('id');
      const currentlyActiveRequest =  $('.documentUploaderSide.evidence-is-active').attr('data-requestkey');
      const nextActiveRequest =  $('.document-category-main[data-EvidenceKey="' + evidenceKey + '"]').attr('data-requestkey');
      // FOR UPDATE FLOWTYPE THE COMPONENT IS RENDERED DIFFERENTLY
      // FOR UPDATE COMPONENT (AND REVIEW PROCESS)  INFOMATION CAN BE FETCHED FROM THE BE IF IT'S ALREADY NOT
      const flowType = $('.document-category-main.position-relative.category-active').attr('data-flowtype');
      // NOTHING PREVIOUSLY SELECTED OPENING NEW
      if ($('.documentUploaderSide.evidence-is-active').length === 0) {
        $('.form-steps-container').addClass('steps-no-margin');
        this.idVerificationService.setExpandedEvidence(evidenceKey);
        this.idVerificationService.loadEvidenceFieldAndImages(
          this.idVerificationService.getActiveProject(),
          currentlyActiveRequest,
          evidenceKey, flowType, evidenceTypes
        );
      } else {
        this.idVerificationService.saveFieldsState(currentlyActiveEvidence, evidenceTypes, undefined, undefined, undefined, true);
        this.idVerificationService.setExpandedEvidence(evidenceKey);
        this.idVerificationService.loadEvidenceFieldAndImages(
          this.idVerificationService.getActiveProject(),
          nextActiveRequest,
          evidenceKey, flowType, evidenceTypes
        );
    }
  }

  onDeleteEvidence($event, requestKey, evidenceTypes, evidenceKey) {
    this.idVerificationService.deleteEvidence (
      this.idVerificationService.getActiveProject(),
      requestKey,
      evidenceKey,
      evidenceTypes
    );
  }

  /*
  FUNCTIONS FOR COUNTER ELEMENT
  **targetClass is for when we have multiple counters on one screen
  */
   increaseCountEmpty(event, isCustomEvidenceType, targetClass= '') {
     if (isCustomEvidenceType) {
       $(targetClass + '.custom-evidence-types-input-wrapper').removeClass('d-none');
     }
     // hide passive button
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').addClass('d-none');
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').removeClass('d-flex');
     // increase counter
     const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
     const nextValue =  parseInt(currentValue, 10) + 1;
     $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
     $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').removeClass('button-inactive');
     // show active button
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').addClass('d-flex');
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').removeClass('d-none');
     $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').on('click', function() {
      $(this).closest('.custom-evidence-types-container').addClass('marked-for-delete');
      $(targetClass + '.custom-evidence-counter-container .button-minus').trigger('click');
    });
      // if EITHER identity verificaiton OR contract signing is checked APS needs to be shown
      // this will tell us if we are in the right window
     if ($('.verification-configuration-screen').length) {
      if (this.idVerificationService.getCCType() === 'funds') {
        if ($('#IdentityVerification').val() !== '0' || $('#ContractVerification').val() !== '0' ) {
          $('.aps-checkbox-class').show();
        }
      } else {
        if ($('#idVerificationSelect').val() !== '0' || $('#ContractSigningSelect').val() !== '0' ) {
          $('.aps-checkbox-class').show();
        }
      }
    }
   }
   increaseCountPlus(event, isCustomEvidenceType, targetClass = '') {
      const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
      const nextValue =  parseInt(currentValue, 10) + 1;
      $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').removeClass('button-inactive');
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
      if (isCustomEvidenceType) {
        const newElement = $(targetClass + '.custom-evidence-types-container:first').clone();
        $(targetClass + '.custom-evidence-types-input-wrapper').append(newElement);
        $(targetClass + '.custom-evidence-types-container:last input ').val('');
        $(targetClass + '.custom-evidence-types-container:last input ').prop('disabled', false);
        $(targetClass + '.custom-evidence-types-container:last input').attr('id', 'customEvidenceType' + nextValue);
        $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').removeClass('d-none');
        $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').on('click', function() {
          $(this).closest('.custom-evidence-types-container').addClass('marked-for-delete');
          $(targetClass + '.custom-evidence-counter-container .button-minus').trigger('click');
        });
      }
   }
   increaseCountMinus(event, baseValue, isCustomEvidenceType,targetClass = '') {
    const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
    const nextValue =  parseInt(currentValue, 10) - 1;
    const baseValueI = !baseValue ? 0 : parseInt(baseValue, 10);
    if (nextValue > baseValueI) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
    }
    if (nextValue === baseValueI) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
      $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').addClass('button-inactive');
    }
    if (nextValue === 0) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
      // show passive button
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').addClass('d-flex');
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').removeClass('d-none');
     // hide active button
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').addClass('d-none');
     $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').removeClass('d-flex');
    }
    if (isCustomEvidenceType) {
      if ($( targetClass + '.custom-evidence-types-container').length === 1 ) {
        $(targetClass + '.custom-evidence-types-input-wrapper').addClass('d-none');
        $(targetClass + '.custom-evidence-types-container input').val('');
        $(targetClass + '.custom-evidence-types-container').removeClass('marked-for-delete');
      } else {
        $(targetClass + '.custom-evidence-types-container.marked-for-delete').remove();
      }
    }
    // if identity verificaiton and contract signing are both unchecked then compliance check needs to be hidden
    // this will tell us if we are in the right window
    if ($('.verification-configuration-screen').length) {
      if (this.idVerificationService.getCCType() === 'funds') {
        if ($('#IdentityVerification').val() === '0' && $('#ContractVerification').val() === '0' ) {
          $('.aps-checkbox-class').hide();
          $('.aps-checkbox-class .button-minus').trigger('click');
        }
      } else {
        if((!$('#idVerificationSelect').length || $('#idVerificationSelect').val() === '0')
        && (!$('#ContractSigningSelect').length || $('#ContractSigningSelect').val() === '0')) {
          $('.aps-checkbox-class').hide();
          $('.aps-checkbox-class .button-minus').trigger('click');
        }
      }
    }
   }
  /*
  FUNCTIONS FOR COUNTER ELEMENT
  */


  /**
 * helper method to parse BE naming convention , example SourceOFWealth will become Source of wealth
 */
  parseBackendName(name: string) {
    name = name.replace(/([A-Z][a-z])/g, ' $1').trim().toLocaleLowerCase();
    let returnValue = name.charAt(0).toUpperCase() + name.slice(1);
    if (returnValue === 'Aps' || returnValue === 'APS') {
      returnValue = 'Compliance check';
    }
    return returnValue;
  }

  parseRelatedPartyRole(role: string) {
    const string = "relatedPartyRoles." + role;
    return this.translate.instant(string)
  }

  OnInitGoogleAutocomplete(e) {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    if (!this.addresstextElement || this.autocomplete) {
      console.log('no address text element or autocomplete already initialized! returning ...');
      return;
    }
    this.addresstextElement.nativeElement.style.borderColor = '#00889c';
    // make sure google maps disable autocomplete for street-address
    this.observerHack.observe(this.addresstextElement.nativeElement, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });
    // $('.text-input.google-autocomplete').attr('autocomplete', 'chrome-off');
    this.autocomplete = new google.maps.places.Autocomplete(this.addresstextElement.nativeElement,
    {
        // componentRestrictions: { country: 'US' },
        types: ['address'],  // 'establishment' / 'address' / 'geocode'
        fields: ['address_components', 'geometry']
    });
    const _this = this;
    google.maps.event.addListener(this.autocomplete, 'place_changed', function (event) {
        const place = _this.autocomplete.getPlace();
        _this.invokeEvent(place);
    });
    var input = document.getElementById('googleAutoComplete');
    google.maps.event.addDomListener(input, 'keydown', function(event) {
      if (event.keyCode === 13) {
          event.preventDefault();
      }
    });
  }

  invokeEvent(place: any) {
    // if auto to manual, try and populate the manual fields:
    const naturalAddressFields = {
      AddressStreet: 'getStreet'
    , AddressNumber: 'getStreetNumber'
    , AddressCity: 'getCity'
    , AddressPostalCode: 'getPostCode'
    , AddressCountry: 'getCountry'
    , AddressCountryShort: 'getCountryShort'
  };
  const legalAddressFields = {
      RegisteredAddressStreet: 'getStreet'
    , RegisteredAddressNumber: 'getStreetNumber'
    , RegisteredAddressCity: 'getCity'
    , RegisteredAddressPostalCode: 'getPostCode'
    , RegisteredAddressCountry: 'getCountry'
    , RegisteredAddressCountryShort: 'getCountryShort'
  };

  if ($('form[form_id=LegalPerson]').length > 0) {
    for (const [key, value] of Object.entries(legalAddressFields)) {
      if (this.ipService[value](place)) {
        if (key === 'AddressCountry' || key === 'RegisteredAddressCountry') {
          let locPlace = this.ipService[value](place).replace(/ /g, '_');
          $(`[id="${key}"]`).val(locPlace);
          // if we cannot find the long name try the short name
          // this happened when using different localizations of chrome
          if (!$(`[id="${key}"]`).val()) {
            const name_short = this.ipService['getCountryShort'](place);
            locPlace = this.idVerificationService.getCountryListAV().find(country => country.value === name_short);
            if (locPlace) {
              locPlace = locPlace.label.toString().replace(/ /g, '_');
              $(`[id="${key}"]`).val(locPlace);
            }
          }
        } else {
          $(`[id="${key}"]`).val(this.ipService[value](place));
        }
        this.uiService.clearInvalidationTarget($(`[id="${key}"]`));
      } else {
        if (!$(`[name="useClassicAddress"]`).prop('checked')) {
          $(`[name="useClassicAddress"]`).trigger('click');
        }
        this.uiService.validateInput($(`[id="${key}"]`));
        console.log(key);
      }
    }
  } else {
      for (const [key, value] of Object.entries(naturalAddressFields)) {
        if (this.ipService[value](place)) {
          if (key === 'AddressCountry' || key === 'RegisteredAddressCountry') {
            let locPlace = this.ipService[value](place).replace(/ /g, '_');
            $(`[id="${key}"]`).val(locPlace);
            if (!$(`[id="${key}"]`).val()) {
              const name_short = this.ipService['getCountryShort'](place);
              locPlace = this.idVerificationService.getCountryListAV().find(country => country.value === name_short);
              if (locPlace) {
                locPlace = locPlace.label.toString().replace(/ /g, '_');
                $(`[id="${key}"]`).val(locPlace);
              }
            }
          } else {
            $(`[id="${key}"]`).val(this.ipService[value](place));
          }
          this.uiService.clearInvalidationTarget($(`[id="${key}"]`));
        } else {
          if (!$(`[name="useClassicAddress"]`).prop('checked')) {
            $(`[name="useClassicAddress"]`).trigger('click');
          }
          this.uiService.validateInput($(`[id="${key}"]`));
        }
      }
  }

    const streetNumber = (this.ipService.getStreetNumber(place)) ? `, ${this.ipService.getStreetNumber(place)}` : '';
    // this.getControl('street').patchValue(`${this.getStreet(place)}${streetNumber}`, {onlySelf: true} );
    // this.getControl('town').patchValue(this.getCity(place), {onlySelf: true});
    // this.addresstextElement.nativeElement.value = `${this.ipService.getStreet(place)}${streetNumber}`;
    $('.checkboxUseClassicAddress').removeClass('d-none');
    $('#AddressInputField').val(`${this.ipService.getStreet(place)}${streetNumber}`);
    $('#CountryInputField').val(this.ipService.getCountryShort(place));
    this.uiService.clearInvalidationTarget($('#CountryInputField'));
    $('#CityInputField').val(this.ipService.getCity(place));
    this.uiService.clearInvalidationTarget($('#CityInputField'));
    const zip  = this.ipService.getPostCode(place);
    if (zip || zip === '') {
      $('#ZipInputField').val(zip);
      this.uiService.clearInvalidationTarget($('#ZipInputField'));
    }
    if ($('#LocationInputField').length > 0) {
      $('#LocationInputField').val(this.ipService.getAddrLocation(place, 'string') as string);
    }
    this.initializeMap(place.geometry.location.lat(), place.geometry.location.lng());
    this.ref.detectChanges();
  }

  initializeMap(lat, long) {
    console.log('initializing map with lat', lat, 'long', long);
    this.addressImageURL = 'https://maps.google.com/maps?q=,139.664123&hl=en&z=13&amp;output=embed';
    const lngLat = new google.maps.LatLng(lat, long);

    const mapOptions = {
      zoom: 13,
      center: lngLat,
      scrollwheel: false
    };

    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      const marker = new google.maps.Marker({
        position: lngLat
      });
      marker.setMap(this.map);
      this.ref.detectChanges();
    }, 500);
  }

  toggleDocumentsClasses(ignoreRPList?) {
    $('#existingDocumentsWrapper').toggleClass('disabled');
    $('.input-fields-wrapper').toggleClass('disabled');
    if (!ignoreRPList) {
      $('.rp-deduplication-wrapper').toggleClass('disabled');
    }
  }

  toggleDocumentsClassesRP(ignoreRPList?) {
    $('#existingDocumentsWrapper').toggleClass('disabled');
    if (!ignoreRPList) {
      $('.rp-deduplication-wrapper').toggleClass('disabled');
    }
    if (!$('#continue_rp_with_new_info').prop('checked')) {
      $('#transfer_to_pcs_bf').prop('checked', false);
      $('#transfer_to_pcs_bf').prop('disabled', true);
      $('#continue_with_new_info_bf').prop('disabled', true);
      $('#continue_with_new_info_bf').prop('checked', false);
      $('.input-fields-wrapper').addClass('disabled');
    } else {
      $('#transfer_to_pcs_bf').prop('disabled', false);
      $('#continue_with_new_info_bf').prop('disabled', false);
      if(!$('#transfer_to_pcs_bf').length) {
        $('.input-fields-wrapper').removeClass('disabled');
      }
    }
  }

  toggleBFCheckbox(element) {
    $('.submit-fields-general-error').hide();
    if($(element.target).attr('id') === 'continue_with_new_info_bf' ) {
      $('#transfer_to_pcs_bf').prop('checked', false);
      $('.input-fields-wrapper').addClass('disabled');
      if (!$('#continue_with_new_info_bf').prop('checked')) {
        $('#continue_with_new_info_bf').prop('checked', true);
      }
    } else {
      $('#continue_with_new_info_bf').prop('checked', false);
      $('.input-fields-wrapper').removeClass('disabled');
      if (!$('#transfer_to_pcs_bf').prop('checked')) {
        $('#transfer_to_pcs_bf').prop('checked', true);
      }
    }
  }

  isPcsChecked() {
    return $('#continue_with_new_info_bf').prop('checked');
  }

  onClickSuggestedTag(tag) {
    const existingValue = $('#addingTagInputText').val();
    if (existingValue === '') {
      $('#addingTagInputText').val(tag);
    } else {
      $('#addingTagInputText').val(existingValue + ',' + tag);
    }
  }

  OnToggleDocumentAccessClick(providerId, changeStateAllowed, event?, accessKey?, evidenceKey?, requestKey?) {
    if (changeStateAllowed) {
      const evidence: any = this.idVerificationService.getEvidenceDetails(this.idVerificationService.getActiveEvidenceKey());
      if (evidence) {
        evidence.formattedAccess.forEach((accessInfo, akey) => {
          accessInfo.providers.forEach((provider, pkey) => {
            if (providerId === provider.id && akey === accessKey ) {
              evidence.formattedAccess[akey].providers[pkey].access = !evidence.formattedAccess[akey].providers[pkey].access;
            }
          });
        });
      }
    } else {
      const checked = $(event.target).is(':checked');
      const toggle = $(event.target).closest('label');
      $(toggle).addClass('disabled');
      if (!checked) {
        this.idVerificationService.revokeEvidenceAccess(
          this.idVerificationService.getActiveProject(),
          requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
          evidenceKey,
          accessKey,
          providerId,
          toggle
        );
      } else {
        this.idVerificationService.addEvidenceAccess(
          this.idVerificationService.getActiveProject(),
          requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
          evidenceKey,
          accessKey,
          providerId,
          toggle
        );
      }
    }
  }

  showHiddenEvidence() {
    $('.show-all-evidence-button').addClass('d-none');
    $('.evidence-radio-wrapp').removeClass('different-project-evd');
  }

  // FUNCTIONS FOR CODE VERIFICATION
  vCodeOnKeyUp(e) {
    let key = e.keyCode || e.which;
    const t = $(e.target);
    // Get the next input
    let sib = t.closest('div').next().find('.smsCode');
    const prevSib = t.closest('div').prev().find('.smsCode');
    const lastSib = $('.SMSArea div').last().find('.smsCode');
    if (key === 0 || key === 229) {
      key = this.getKeyCode(t.val());
    }

    // Not allow any keys to work except for tab and number
    if (key !== 9 && key !== 46 && key !== 8 && (key < 48 || key > 57)
        && (key < 96 || key > 105) && (key < 65 || key > 91) && (key < 96 || key > 122)) {
        e.preventDefault();
        return false;
    }

    // Tab
    if (key === 9) {
      // Go back to the first one
      if (!sib || !sib.length) {
          sib = $('.smsCode').eq(0);
      }
      return true;
    }

    if (key === 8) {  // backspace
        // Go back to the prev one
        if (prevSib && prevSib.length) {
          prevSib.select().focus();
          prevSib.click();
          return true;
      } else {
        // console.log('last sib');
        // lastSib.select().focus();
        return true;
      }
    }

    if (key === 46) { // delete
        // remove the value
        $(t).val('');
        return true;
    }

     // Go back to the first one
      if (!sib || !sib.length) {
        console.log('!sib || !sib.length');
        sib = $('.smsCode').eq(0);
      }
      sib.trigger('touchstart');
      sib[0].focus();
  }

  getKeyCode(str) {
    return str.charCodeAt(str.length - 1);
  }

  vCodeOnKeyDown(e) {
    const key = e.which;
    if (key === 9 || key === 46  || (key >= 48 && key <= 57) || key === 8 || (key >= 96 && key <= 105)
        || (key >= 65 && key < 91) || (key >= 97 && key < 123) ) {
        return true;
    }
    return false;
  }

  vCodeOnFocus(e) {
    $(e.target).select();
    $(e.target).click();
  }

  setStepMethod(method, type, isAcitve) {
    if (!isAcitve) {
      return;
    }
    this.stepMethod = {method: method, type: type};
    $(`substep-continue-button`).addClass('disabled');
    $(`#${type}_continue`).removeClass('disabled');
    console.log(this.stepMethod);
  }

  continueStep(event) {
    if ($(event.target).attr('type') === this.stepMethod.type) {
      // const method = this.stepMethod.filter(method => ($(event.target).attr('type') === method.type));
        this.contentReturn.emit({
          'method' : this.stepMethod.method,
          'params' : event
        });
    }
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  collapseDigitalVerification(event) {
    if ($(event.target).hasClass('collapsed')) {
      $(event.target).removeClass('collapsed');
      const targetElement = $(event.target).attr('elementToCollapse');
      $('div[collapseKey="' + targetElement + '"]').removeClass('collapsed');
      $('.collapsible-verification').removeClass('collapsed');
      $('.collapsible-title').removeClass('collapsed');
    } else {
      $('.collapsible-verification').removeClass('collapsed');
      $('.collapsible-title').removeClass('collapsed');
      $(event.target).addClass('collapsed');
      const targetElement = $(event.target).attr('elementToCollapse');
      $('div[collapseKey="' + targetElement + '"]').addClass('collapsed');
    }
  }

  OnChangeDashTab(event) {
    const activatesTab = $(event.target).attr('activatesTab');
    const tabGroup = $(event.target).attr('fromTabGroup');
    const tabGroupElements = $('div[tabGroup="' + tabGroup + '"]');
    const tabContentActive =  $('div[tabKey ="' + activatesTab + '"]');

    if(this.idVerificationService.getActiveInvestorDetaisl()) {
      this.idVerificationService.getActiveInvestorDetaisl().activeTabMainProfile = $(event.target).text();
    }

    // remove active class from all tabs
    $(event.target).closest('.dashboard-tabs').find('.tab').removeClass('active');
    // add active class to clicked tab
    document.getElementById(activatesTab).classList.add('active');
    // hide all the content in the same group tab
    tabGroupElements.addClass('d-none');
    // only show the content we selected
    tabContentActive.removeClass('d-none');
  }

  idOnAddSuggestedRR(event) {
    event.preventDefault();
    const riskValue  = event.target.getAttribute('tagValue');
    const riskColor = event.target.getAttribute('colorValue');
    const suggestions = $('.suggested-rr');
    suggestions.removeClass('selected');
    $(event.target).addClass('selected');
    $('#RiskRating').val(riskValue);
    if(riskColor)
    {
      $('#RiskRatingColor').val(riskColor);
      document.getElementById("RiskRatingColor").style.backgroundColor= riskColor;
    }
    // if (!this.idVerificationService.getActivePersonDetails()['record'].unaddedTags) {
    //   if (!this.idVerificationService.getActivePersonDetails()['record'].tags.includes(tagValue)) {
    //     this.idVerificationService.getActivePersonDetails()['record'].unaddedTags = [tagValue];
    //   }
    // } else {
    //   if (!this.idVerificationService.getActivePersonDetails()['record'].tags.includes(tagValue)) {
    //     this.idVerificationService.getActivePersonDetails()['record'].unaddedTags.push(tagValue);
    //   }
    // }
    // this.idOnAddTags(undefined, tagInputValue);
  }

  onContentReturnFromElements(params) {
    this.contentReturn.emit(params);
  }

  onDisplayLoaderFromElements(params) {
    this.displayLoader.emit(params);
  }



  setTinyVal(value) {
    this.tinyHtml = value;
  }

}

