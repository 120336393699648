import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, OnDestroy  } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';
import { commentsOnWhitelistingHit } from 'src/app/id-verification/id-verification-const';
import { UiService } from 'src/app/ui/ui.service';

@Component({
  selector: 'app-preview-window-id-verification',
  templateUrl: './preview-window-id-verification.component.html',
  styleUrls: ['./preview-window-id-verification.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class PreviewWindowIdVerificationComponent implements OnInit, OnDestroy {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  reasonDialogueType;
  reasonDialogObs;
  commentsOnWhitelistingHit : string[];

  constructor(
    public elementService: ElementsService
  , public idVerificationService: IdVerificationService
  , private helperService: HelperServiceService
  , private uiService: UiService
) { }

  ngOnInit(): void {
    this.reasonDialogueType = 'expiry';
    this.reasonDialogObs = this.idVerificationService.detectPreviewWindowPopUp.subscribe(
      status => {
        $('.expire-lightbox .loading-icon').addClass('d-none');
        this.reasonDialogueType = status;
      }
    );
    if(this.element.params.apsFields)
    {
      this.idVerificationService.setAPSFields(this.element.params.apsFields)
      this.idVerificationService.sortingHitsOnComplianceCheck();
      this.idVerificationService.calculatingWhitelistedHits();
      this.idVerificationService.setAPSSTatusses(this.element.params.verificationStatuses);
    }
    this.commentsOnWhitelistingHit = commentsOnWhitelistingHit;
  }

  ngOnDestroy(): void {
    this.reasonDialogObs.unsubscribe();
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onEmit(emitPayload) {
      if (emitPayload) {
        this.contentReturn.emit(emitPayload);
      }
    }

     /**
     * emit event back to parent component
     * @param emitPayload params that should be emitted by parent component
     */
      onDisplayLoader(emitPayload: boolean) {
        if (emitPayload) {
          this.displayLoader.emit(emitPayload);
        }
      }

}
