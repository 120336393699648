import { Component, Input, OnInit, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperServiceService } from 'src/app/helper-service.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-digital-verification-evidence-contracts',
  templateUrl: './digital-verification-evidence-contracts.component.html',
  styleUrls: ['./digital-verification-evidence-contracts.component.scss']
})
export class DigitalVerificationEvidenceContractsComponent implements OnInit, AfterContentInit {

  @Input() element;
  @Input() digitalVerification;
  @Output() contentReturn = new EventEmitter<any>();

  verificationPresets;
  isLastElement;

  constructor(
    private elementService: ElementsService,
    private helperService : HelperServiceService
  ) { }

  ngAfterContentInit(): void {
    this.verificationPresets  = this.digitalVerification.verificationData ?
    this.digitalVerification.verificationData.verificationPresets : undefined;
    if (this.element && this.element['investorSide'] && this.verificationPresets) {
      this.element['params'].contractTemplates = [];
      this.element['params'].contractTemplates.push({'key' : this.verificationPresets.template,
                                                     'name' : this.helperService.parseBackendName(this.verificationPresets.template)});
    }
    this.isLastElement = this.elementService.isLastDVEvidenceToReview(
      this.element.params.evidenceTypes,
      this.digitalVerification,
      this.element.params.reviewSide
    );

  }

  ngOnInit(): void {
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

}
