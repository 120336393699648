<div class="container">
  <h2 class="mt-3 text-center">This is just an html holder for now :D and it only supports one output template at a time!</h2>
  <div class="d-flex flex-row align-items-center" *ngIf="formBuilderService.currentOutputIndex !== null && formBuilderService.currentOutputIndex > -1">
    <div class="mr-4">Currently editing <strong>{{formBuilderService.getOutputNameByIndex(formBuilderService.currentOutputIndex)}}</strong> output</div>
    <!-- <button (click)="toggleOutputPreview()" class="form-button form-button-1">Toggle {{outputPreviewMode}}</button> -->
    <button (click)="deleteOutputTemplate()" class="form-button form-button-1 ml-1" *ngIf="this.formBuilderService.currentOutputs.lenght > 1">Delete</button>
  </div>
  <!-- <div
    *ngIf="this.outputPreviewMode.toLowerCase() !== 'preview'"
    id="editor"
    contenteditable="true"
    [innerHTML]="this.formBuilderService.formattedHTML"
    (keyup)="onKeyUp($event)"
    (keydown)="overrideEnter($event)"
    (contextmenu)="onRightClick($event)"
    appEditorContent spellcheck="false"
  ></div> -->
  <div class="togglers">
    <button
      (click)="setOutputPreview('Source')"
      class="form-button form-button-1"
      [ngClass]="{'active': this.outputPreviewMode.toLowerCase() === 'source'}"
    >Edit the code</button>
    <button
      (click)="setOutputPreview('Preview')"
      class="form-button form-button-1"
      [ngClass]="{'active': this.outputPreviewMode.toLowerCase() === 'preview'}"
    >Preview output</button>
  </div>
  <div
    *ngIf="this.outputPreviewMode.toLowerCase() === 'preview'"
    id="editor"
    [innerHTML]="getCurrentOutputContent()"
    (keyup)="onKeyUp($event)"
    (keydown)="overrideEnter($event)"
    (contextmenu)="onRightClick($event)"
    appEditorContent spellcheck="false"
  ></div>
  <div
    *ngIf="this.outputPreviewMode.toLowerCase() === 'source-with-div'"
    id="HTMLeditor"
    contenteditable="true"
    [innerText]="this.formBuilderService.formattedHTML"
    (keyup)="onKeyUp($event, 'HTMLeditor', 'text')"
    appEditorContent spellcheck="false"
  ></div>

  <textarea
    *ngIf="this.outputPreviewMode.toLowerCase() === 'source'"
    id="HTMLeditor"
    contenteditable="true"
    (keyup)="onKeyUp($event, 'HTMLeditor', 'text')"
    appEditorContent spellcheck="false"
  >{{getCurrentOutputContent()}}</textarea>
</div>

<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
