  <div class="d-flex flex-column flex-fill justify-content-center" *ngIf="!initiated">
    <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass" *ngIf="message !== ''" innerHtml="{{message}}">
    </div>
    <div class="d-flex mx-auto text-center" *ngIf="displayLoader">
      <img src="/assets/images/spinner-gray.svg" width="32px" height="32px"></div>
    <!-- <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass" *ngIf="message === ''">
      {{message}}
    </div> -->
  </div>

  <div class="d-flex flex-column flex-fill justify-content-center" *ngIf="initiated">
    <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass">
      Please confirm that you understand that by accepting the invitation you will become project {{contributorType}} of "{{project}}"
      <div class="d-flex mt-3"><button class="form-button form-button-1 mx-auto" (click)="OnExplicitConsent()">I consent</button></div>
    </div>
  </div>
