import { Component, OnInit } from '@angular/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { EditorService } from '../editor.service';
import { FormBuilderServiceService } from '../form-builder-service.service';

@Component({
  selector: 'app-form-outputs-editor',
  templateUrl: './form-outputs-editor.component.html',
  styleUrls: ['./form-outputs-editor.component.scss']
})
export class FormOutputsEditorComponent implements OnInit {

  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;

  content: string;
  outputPreviewMode = 'Source';

  constructor(
    public formBuilderService: FormBuilderServiceService,
    private editorService: EditorService,
    private userService: UserService,
    private idVerification: IdVerificationService
  ) { }

  ngOnInit(): void {
    // this.content = this.formBuilderService.formattedHTML;
  }

  toggleOutputPreview() {
    this.outputPreviewMode.toLowerCase() === 'preview' ? this.outputPreviewMode = 'Source' : this.outputPreviewMode = 'Preview';
  }

  setOutputPreview(mode) {
    this.outputPreviewMode = mode;
  }

  deleteOutputTemplate() {
    if (this.formBuilderService.currentOutputs.length > 1) {
      this.ConfirmDeleteOutput();
    } else {
      // to do
      // this.DisplayConfirmDeleteForm();
      console.log(this.formBuilderService.currentTemplate);
    }
  }

  ConfirmDeleteOutput() {
    this.formType = `Confirm deleting an output`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top mt-3 location-text',
            'params': {
              'content': 'Please confirm your action'
            }
          },
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `You are about to delete the content of the "<strong>${this.formBuilderService.currentOutputs[this.formBuilderService.currentOutputIndex].fileName}</strong>" output. Please confirm that this is intentional as the action is non-reversable!`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Delete',
                // 'id': 'accept',
                'action': 'onConfirmDeleteOutput',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'action': 'onLightboxClose',
                'class': 'form-button-2',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  DisplayConfirmDeleteForm(suggestedName) {
    // build the content for the Create new project lightbox
    this.formType = `Confirm deleting a template`;
    this.formClass = '';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = '';
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'delete_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `Please confirm that you are sure you want to delete the template <strong>${suggestedName}</strong>`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Delete',
                // 'id': 'accept',
                'type': 'button',
                'action': 'onDeleteTemplateAccept',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'type': 'button',
                'action': 'onLightboxClose',
                'class': 'form-button ',
                'display': true
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onDeleteTemplateAccept(event) {
    this.displayLoader = true;
    // this.deleteMyForm();
    console.log(this.formBuilderService.currentTemplate);
  }

  async deleteMyForm() {
    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {'templateKey': this.formBuilderService.currentTemplate, 'templateMode': this.formBuilderService.currentMode};
      console.log('payload', payload);
      await this.userService
          // .postEndPoint('api/pdf/templates/'
          //   + this.copyFrom
          //   + '/copy/'
          //   + this.userService.getUserOrganizationId()
          //   , payload
          //   , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .postEndPoint('api/pdf/templates/'
            + this.formBuilderService.currentTemplate
            + '/template/'
            + this.userService.getUserOrganizationId()
            + '/delete'
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(response => {
            this.idVerification.parseContractTemplatesWithTypes(response, 'funds');
            this.formBuilderService.myOrganisationTemplates = this.idVerification.organizationContractTemplates;
            this.displayLoader = false;
            this.displayModal = false;
          })
          .catch(error => console.error(error));
    }
  }

  async deleteMyOutput(outputKey) {
    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {'templateKey': this.formBuilderService.currentTemplate, 'templateMode': this.formBuilderService.currentMode, 'outputKey': outputKey};
      console.log('payload', payload);
      await this.userService
          .postEndPoint('api/pdf/templates/'
            + this.formBuilderService.currentTemplate
            + '/template/'
            + this.userService.getUserOrganizationId()
            + '/deleteOutput'
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(response => {
            this.idVerification.parseContractTemplatesWithTypes(response, 'funds');
            this.formBuilderService.myOrganisationTemplates = this.idVerification.organizationContractTemplates;
            this.displayLoader = false;
            this.displayModal = false;
          })
          .catch(error => console.error(error));
    }
  }

  async onConfirmDeleteOutput() {
    console.log('outputs list', this.formBuilderService.currentOutputs);
    const outputSlug = this.formBuilderService.slugify(
      this.formBuilderService.currentOutputs[this.formBuilderService.currentOutputIndex].fileName
    );
    await this.deleteMyOutput(outputSlug);
    this.formBuilderService.currentOutputs.splice(this.formBuilderService.currentOutputIndex, 1);
    this.formBuilderService.setCurrentOutput(0);
    this.formBuilderService.displayEditOutput();
    // this.displayLoader = false;
    // this.displayModal = false;
  }

  onKeyUp($event, editor = 'editor', mode = 'html') {
    this.editorService.setTypingMode();
    clearTimeout(this.editorService.timeOutHelper);
    this.editorService.timeOutHelper = setTimeout(() => {
      this.editorService.removeTypingMode();
      this.formBuilderService.currentOutputs[this.formBuilderService.currentOutputIndex].data = (mode === 'text') ? $(`#${editor}`).val() :  $(`#${editor}`).html();
    }, this.editorService.typeSensitivity);
  }

  getCurrentOutputContent() {
    return this.formBuilderService.currentOutputs[this.formBuilderService.currentOutputIndex].data;
  }

  overrideEnter($event) {
    // this.editorService.overrideEnter($event);
  }

  onRightClick(event) {
    console.log(event);
    console.log(event.target);

    return false;
  }

   // lightbox handler methods
  onLightboxConfirm(returnedAction) {
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    this.displayLoader = true;
  }

  onLightboxClose($event) {
    // this.copyFrom = null;
    this.displayModal = false;
  }

}
