import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsyncSubject, BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UiService } from 'src/app/ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {

  private contentReturn = new BehaviorSubject({});
  // private  contentReturn = new AsyncSubject();
  public dvCompleted = new Subject<any>();;
  returnOnClickAction = this.contentReturn.asObservable();
  returnDvCompleted = this.dvCompleted.asObservable();

  constructor(
    private uiService: UiService
    , private idVerificationService: IdVerificationService
    , private helperService: HelperServiceService
    , public translate: TranslateService
  ) { }

  /**
   * this method will be executed on expand/collapse digital verification single record
   * @param event DOM event
   */
  collapseDigitalVerification(event) {
    if ($(event.target).hasClass('collapsed')) {
      $(event.target).removeClass('collapsed');
      const targetElement = $(event.target).attr('elementToCollapse');
      $('div[collapseKey="' + targetElement + '"]').removeClass('collapsed');
      $('.collapsible-verification').removeClass('collapsed');
      $('.collapsible-title').removeClass('collapsed');
    } else {
      $('.collapsible-verification').removeClass('collapsed');
      $('.collapsible-title').removeClass('collapsed');
      $(event.target).addClass('collapsed');
      const targetElement = $(event.target).attr('elementToCollapse');
      $('div[collapseKey="' + targetElement + '"]').addClass('collapsed');
    }
  }

  /**
   * execute this method to delete evidence
   * @param $event DOM event
   * @param requestKey key of the request
   * @param evidenceTypes type of evidence
   * @param evidenceKey key of evidence
   */
  onDeleteEvidence($event, requestKey, evidenceTypes, evidenceKey, evidenceOriginalKey) {
    // return;
    this.idVerificationService.deleteEvidence(
      this.idVerificationService.getActiveProject(),
      requestKey,
      evidenceKey,
      evidenceTypes,
      evidenceOriginalKey
    );
  }

  /**
   * handle enter action on input field key up
   */
  OnInputKeyUp(event) {
    this.uiService.clearInvalidation(event);
    const keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === '13' || keycode === 13) {
      // enter key is pressed - check if there is action defined for this input field
      if ($(event.target).attr('enterkeyaction')) {
        // emit to be executed
        return {
          'method': $(event.target).attr('enterkeyaction'),
          'params': event
        };
      }
    }
  }

  // Function currently used only to determine if digital verification is last evidence of it's type
  isLastDVEvidenceToReview(evidenceTypes, evidenceType, isReview?) {
    let returnValue = true;
    for (let i = 0; i < evidenceTypes.length; i++) {
      // if it's the same key then it's irelevant what the value is
      if (evidenceTypes[i].originalEvidenceKey === evidenceType.originalEvidenceKey) {
      } else if (isReview) {
        // if the evidence different from the one we are checking is not yet reviewed the current is not the last
        if (evidenceTypes[i].reviewState === true) {
          returnValue = false;
        }
      } else {
        // if the evidence different from the one we are checking is not yet configured the current is not the last
        if (evidenceTypes[i].notSharable) {
          returnValue = false;
        }
      }
    }
    return returnValue;
  }


  /**
   * handle on Blur action
   */
  OnInputBlur(event) {
    // do validation
    this.uiService.validateInput(event);
  }

  /**
   * handle on Focus action
   */
  OnInputFocus(event) {
    // do validation
    this.uiService.clearInvalidation($(event.target));
  }

  parseDateContentReturn(event) {
    if (event.params.model !== undefined) {
      this.uiService.clearInvalidation($('#' + event.params.model).find('input'));
    }
    // return (event);
  }

  parseDateContentReturnOlderThan(event) {
    if (event.params.model !== undefined) {
      this.uiService.clearInvalidation($(event.params.targetElement).find('input'));
    }
  }

  /**
   * it will be executed on click on any content element having attribute clickaction
   * @param event event
   */
  OnContentElementClick(event) {
    this.uiService.clearInvalidation($(event.target).closest('.checkbox-container'));
    if ($(event.target).prop('tagName') === 'INPUT' && $(event.target).attr('type') === 'checkbox') {
      // console.log('calling checkbox validator');
      this.uiService.validateCheckboxGroup(event);
    }
    if (typeof $(event.target).attr('clickaction') !== 'undefined') {
      if (typeof $(event.target).attr('preventdefault') !== 'undefined' && $(event.target).attr('preventdefault') === 'true') {
        event.preventDefault();
      }
      return {
        'method': $(event.target).attr('clickaction'),
        'params': event
      };
    }
  }

  /**
   * it will be executed on click on any content element having attribute changemethod
   * @param event event
   */
  OnChangeSelect(event) {
    // first validate
    this.uiService.clearInvalidation($(event.target));
    if (!this.uiService.validateSelect(event)) {
      event.preventDefault();
      // return false;
    }
    if (typeof $(event.target).attr('changemethod') !== 'undefined') {
      if (typeof $(event.target).attr('preventdefault') !== 'undefined' && $(event.target).attr('preventdefault') === 'true') {
        event.preventDefault();
      }
      return {
        'method': $(event.target).attr('changemethod'),
        'params': event
      };
    }
  }

  /**
   * this method will get executed on any button click
   * @param event: any
   */
  OnButtonClick(event, additionalParam = '') {
    if ($(event.target).attr('type') && $(event.target).attr('type') === 'submit') {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log('**not all fields are valid');
        event.preventDefault();
        return;
      }
    }
    let eventTarget = event.target;
    if (eventTarget.nodeName !== 'A'
      && eventTarget.nodeName !== 'BUTTON'
      && eventTarget.nodeName !== 'LI'
      && eventTarget.nodeName !== 'DIV') {
      eventTarget = $(eventTarget).closest('a'); // for now only support fallback to anchor elements to cover the svg scenario
      if (eventTarget.length > 0) {
        eventTarget = eventTarget[0];
      }
    }
    event.preventDefault();
    event.stopPropagation();
    const method = eventTarget.getAttribute('clickmethod');
    if (method === '' || method === null) {
      return;
    }
    return {
      'method': method,
      'params': additionalParam ? { event, additionalParam } : event
    };
  }

  /**
   * helper method to parse BE naming convention , example SourceOFWealth will become Source of wealth
   */
  parseBackendName(name: string) {
    name = name.replace(/([A-Z][a-z])/g, ' $1').trim().toLocaleLowerCase();
    let returnValue = name.charAt(0).toUpperCase() + name.slice(1);
    if (returnValue === 'Aps' || returnValue === 'APS') {
      returnValue = 'Compliance check';
    }
    return returnValue;
  }

  parseRelatedPartyRole(role: string) {
    const string = "relatedPartyRoles." + role;
    return this.translate.instant(string)
  }


  /**
   * document and evidence update function
   * activated troguh the collapsed investor screen
   */
  documentUpdateFunction(event, evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    let dvType = $(event.target).closest('.document-inner-entry').attr('data-dvType');
    if (!dvType) {
      dvType = $(event.target).attr('data-dvType');
    }
    if (dvType) {
      this.idVerificationService.getActiveInvestorDetaisl().activeDigitalVerificationType = dvType;
    }
    this.idVerificationService.setExpandedEvidence(evidenceKeyClicked);
    this.idVerificationService.loadEvidenceFieldAndImages(
      this.idVerificationService.getActiveProject()
      , requestKey
      , evidenceKeyClicked, 'Update'
      , evidenceTypes
    );
  }

  /**
   * document and evidence preview function
   * activated troguh the collapsed investor screen
   */
  documentPreviewFunction(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    const stepArguments = {
      'evidenceTypes': evidenceTypes
      , 'evidence': evidence
      , 'requestKey': requestKey
      , 'evidenceKeyClicked': evidenceKeyClicked
    };
    this.idVerificationService.skipToStep(13, [stepArguments]);
    // this.displayLoader.emit();
    let evidenceDocs = [];
    if (evidence) {
      evidenceDocs = evidence.documents;
    }
    // retreive the evidence history
    this.idVerificationService.buildEvidenceLightbox(
      this.idVerificationService.getActiveProject()
      , requestKey
      , evidenceKeyClicked
      , stepArguments
      , evidenceDocs
    );

    // returns true to set displayLoader to true
    return true;
  }

  /**
 * document and evidence preview function on investor side
 * activated troguh the collapsed investor screen
 */
  documentPreviewFunctionInvestor(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    const stepArguments = {
      'evidenceTypes': evidenceTypes
      , 'evidence': evidence
      , 'requestKey': requestKey
      , 'evidenceKeyClicked': evidenceKeyClicked
    };

    let evidenceDocs = [];
    if (evidence) {
      evidenceDocs = evidence.documents;
    }
    // retreive the evidence history
    this.idVerificationService.buildEvidenceLightboxReview(
      this.idVerificationService.getActiveProject()
      , requestKey
      , evidenceKeyClicked
      , false
      , evidenceDocs,
      false
    );

    // returns true to set displayLoader to true
    return true;
  }


  /**
   *
   * document and evidence preview function on the CLAIM / SHARE Screen
   * activated troguh the collapsed investor screen
   */
  documentPreviewFunctionOnClaim(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    const stepArguments = {
      'evidenceTypes': evidenceTypes
      , 'evidence': evidence
      , 'requestKey': requestKey
      , 'evidenceKeyClicked': evidenceKeyClicked
      , 'claimScreen': true
    };

    this.idVerificationService.skipToStep(13, [stepArguments]);
    // this.displayLoader.emit();
    this.idVerificationService.buildEvidenceLightBoxInvestorOnClaim(
      requestKey
      , evidenceKeyClicked
      , stepArguments
      , evidence.documents
    );

    // returns true to set displayLoader to true
    return true;
  }

  /**
   * document and evidence review function
   * activated troguh the collapsed investor screen
   */
  documentReviewFunction(evidenceTypes, evidence, requestKey, evidenceKeyClicked) {
    return {
      'method': 'startReviewProcess',
      'params': { 'evidenceKeyClicked': evidenceKeyClicked, 'requestKey': requestKey }
    };
  }

  /**
  * evidence review function
  */
  onReviewEvidenceClick(evidenceKey, requestKey, documents, noScroll = false, hideActions = false, hideAccess?, startingFilename?) {
    this.idVerificationService.buildEvidenceLightboxReview(
      this.idVerificationService.getActiveProject()
      , requestKey
      , evidenceKey
      , noScroll
      , documents
      , hideActions
      , undefined
      , undefined
      , startingFilename
    );
  }

  /**
   * sharing informationRequest
   */
  documentShareFunction(requestKey) {
    this.idVerificationService.skipToStep(10, { 'requestKey': requestKey });
  }

  onChangeNotes(event) {
    const value = $(event.target).val();
    $(event.target).val(this.helperService.sanitizePropery(value));
  }

  /*
FUNCTIONS FOR COUNTER ELEMENT
**targetClass is for when we have multiple counters on one screen
*/
  increaseCountEmpty(event, isCustomEvidenceType, targetClass = '') {
    if (isCustomEvidenceType) {
      $(targetClass + '.custom-evidence-types-input-wrapper').removeClass('d-none');
    }
    // hide passive button
    $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').addClass('d-none');
    $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').removeClass('d-flex');
    // increase counter
    const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
    const nextValue = parseInt(currentValue, 10) + 1;
    $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
    $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').removeClass('button-inactive');
    // show active button
    $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').addClass('d-flex');
    $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').removeClass('d-none');
    $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').on('click', function () {
      $(this).closest('.custom-evidence-types-container').addClass('marked-for-delete');
      $(targetClass + '.custom-evidence-counter-container .button-minus').trigger('click');
    });
    // if EITHER identity verificaiton OR contract signing is checked APS needs to be shown
    // this will tell us if we are in the right window
    if ($('.verification-configuration-screen').length) {
      if (this.idVerificationService.getCCType() === 'funds') {
        if ($('#IdentityVerification').val() !== '0' || $('#ContractVerification').val() !== '0') {
          $('.aps-checkbox-class').show();
        }
      } else {
        if ($('#idVerificationSelect').val() !== '0' || $('#ContractSigningSelect').val() !== '0') {
          $('.aps-checkbox-class').show();
        }
      }
    }
  }
  increaseCountPlus(event, isCustomEvidenceType, targetClass = '') {
    const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
    const nextValue = parseInt(currentValue, 10) + 1;
    $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').removeClass('button-inactive');
    $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
    if (isCustomEvidenceType) {
      const newElement = $(targetClass + '.custom-evidence-types-container:first').clone();
      $(targetClass + '.custom-evidence-types-input-wrapper').append(newElement);
      $(targetClass + '.custom-evidence-types-container:last input ').val('');
      $(targetClass + '.custom-evidence-types-container:last input ').prop('disabled', false);
      $(targetClass + '.custom-evidence-types-container:last input').attr('id', 'customEvidenceType' + nextValue);
      $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').removeClass('d-none');
      $(targetClass + '.custom-evidence-types-container:last .custom-evidence-delete-button').on('click', function () {
        $(this).closest('.custom-evidence-types-container').addClass('marked-for-delete');
        $(targetClass + '.custom-evidence-counter-container .button-minus').trigger('click');
      });
    }
  }
  increaseCountMinus(event, baseValue, isCustomEvidenceType, targetClass = '') {
    const currentValue = $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val().toString();
    const nextValue = parseInt(currentValue, 10) - 1;
    const baseValueI = !baseValue ? 0 : parseInt(baseValue, 10);
    if (nextValue > baseValueI) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
    }
    if (nextValue === baseValueI) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
      $(event.target).closest('.single-checkbox-wrapper').find('.button-minus').addClass('button-inactive');
    }
    if (nextValue === 0) {
      $(event.target).closest('.single-checkbox-wrapper').find('.custom-counter-count').val(nextValue);
      // show passive button
      $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').addClass('d-flex');
      $(event.target).closest('.single-checkbox-wrapper').find('.counter-passive').removeClass('d-none');
      // hide active button
      $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').addClass('d-none');
      $(event.target).closest('.single-checkbox-wrapper').find('.counter-active').removeClass('d-flex');
    }
    if (isCustomEvidenceType) {
      if ($(targetClass + '.custom-evidence-types-container').length === 1) {
        $(targetClass + '.custom-evidence-types-input-wrapper').addClass('d-none');
        $(targetClass + '.custom-evidence-types-container input').val('');
        $(targetClass + '.custom-evidence-types-container').removeClass('marked-for-delete');
      } else {
        $(targetClass + '.custom-evidence-types-container.marked-for-delete').remove();
      }
    }
    // if identity verificaiton and contract signing are both unchecked then compliance check needs to be hidden
    // this will tell us if we are in the right window
    if ($('.verification-configuration-screen').length) {
      if (this.idVerificationService.getCCType() === 'funds') {
        if ($('#IdentityVerification').val() === '0' && $('#ContractVerification').val() === '0') {
          $('.aps-checkbox-class').hide();
          $('.aps-checkbox-class .button-minus').trigger('click');
        }
      } else {
        if ((!$('#idVerificationSelect').length || $('#idVerificationSelect').val() === '0')
          && (!$('#ContractSigningSelect').length || $('#ContractSigningSelect').val() === '0')) {
          $('.aps-checkbox-class').hide();
          $('.aps-checkbox-class .button-minus').trigger('click');
        }
      }
    }
  }
  /*
  FUNCTIONS FOR COUNTER ELEMENT
  */

  /**
   * method for switching between tabs on dashboard
   */
  OnChangeDashTab(event) {
    const activatesTab = $(event.target).attr('activatesTab');
    const tabGroup = $(event.target).attr('fromTabGroup');
    const tabGroupElements = $('div[tabGroup="' + tabGroup + '"]');
    const tabContentActive = $('div[tabKey ="' + activatesTab + '"]');
    // remove active class from all tabs
    $(event.target).closest('.dashboard-tabs').find('.tab').removeClass('active');
    // add active class to clicked tab
    document.getElementById(activatesTab).classList.add('active');
    // hide all the content in the same group tab
    tabGroupElements.addClass('d-none');
    // only show the content we selected
    tabContentActive.removeClass('d-none');
  }

  /**
   * method for switching between tabs in preview window
   * @param tabid id of the destination tab
   */
  changePreviewClass(tabid) {
    $('.ev-preview-tab').removeClass('active');
    $('#' + tabid).addClass('active');
    $('.ev-preview-tab-content').addClass('d-none');
    $('#' + tabid + 'Content').removeClass('d-none');
  }

  /**
   * on toggle document access in preview window
   * @param providerId
   * @param changeStateAllowed
   * @param event
   * @param accessKey
   * @param evidenceKey
   * @param requestKey
   */
  OnToggleDocumentAccessClick(providerId, changeStateAllowed, event?, accessKey?, evidenceKey?, requestKey?) {
    if (changeStateAllowed) {
      const evidence: any = this.idVerificationService.getEvidenceDetails(this.idVerificationService.getActiveEvidenceKey());
      if (evidence) {
        evidence.formattedAccess.forEach((accessInfo, akey) => {
          accessInfo.providers.forEach((provider, pkey) => {
            if (providerId === provider.id && akey === accessKey) {
              evidence.formattedAccess[akey].providers[pkey].access = !evidence.formattedAccess[akey].providers[pkey].access;
            }
          });
        });
      }
    } else {
      const checked = $(event.target).is(':checked');
      const toggle = $(event.target).closest('label');
      $(toggle).addClass('disabled');
      if (!checked) {
        this.idVerificationService.revokeEvidenceAccess(
          this.idVerificationService.getActiveProject(),
          requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
          evidenceKey,
          accessKey,
          providerId,
          toggle
        );
      } else {
        this.idVerificationService.addEvidenceAccess(
          this.idVerificationService.getActiveProject(),
          requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
          evidenceKey,
          accessKey,
          providerId,
          toggle
        );
      }
    }
  }

  // cdd sharing preview methods
  startEditFlow(evidenceTypes, fields, requestKey, evidenceKeyClicked, evidenceType, isBasicFields?) {
    // first create the new version and get the evidence key
    this.idVerificationService.createNewVersionofEvidence(
      this.idVerificationService.getActiveProject(),
      requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
      evidenceKeyClicked,
      fields,
      evidenceType,
      (evidenceType === 'NaturalPerson' || evidenceType === 'LegalPerson')
    );
  }

  // Edit flow for incomplete evidence
  startEditFlowIncomplete(evidenceTypes, fields, requestKey, evidenceKeyClicked, evidenceType, documents) {
    this.idVerificationService.editIncompleteVersionOfEvidence(
      this.idVerificationService.getActiveProject(),
      requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
      evidenceKeyClicked,
      fields,
      evidenceType,
      documents,
      (evidenceType === 'NaturalPerson' || evidenceType === 'LegalPerson')
    );
  }

  // sets the latest evidence version as InUse
  setLatestVersionInUse(currentEvidenceKey, latestVersionKey, evidence, evidenceTypes, requestKey?) {
    const stepArguments = {
      'requestKey': requestKey,
      'evidenceTypes': evidenceTypes,
      'evidence': evidence,
      'dontReloadAfterClosing': false
    };
    this.idVerificationService.skipToStep(13, [stepArguments]);
    this.idVerificationService.setLatestEvidenceInUse(
      this.idVerificationService.getActiveProject()
      , requestKey
      , currentEvidenceKey
      , latestVersionKey
      , stepArguments
    );
  }

  previewVersion(originalEvidenceKey, evidenceKey, evidence, evidenceTypes, documents, requestKey?) {
    if (!this.idVerificationService.isInvestorType()) {
      // special check because the data is organized differently on the BE for different things...
      if (evidence && evidence.verificationData) {
        evidence.isResolved = evidence.verificationData.isResolved;
        evidence.verificationType = evidence.verificationData.verificationType;
      }

      const stepArguments = {
        'requestKey': requestKey,
        'evidenceKeyClicked': originalEvidenceKey,
        'evidenceTypes': evidenceTypes,
        'evidence': evidence,
        'showVersionsLightbox': true
      };
      this.idVerificationService.skipToStep(13, [stepArguments]);
      this.idVerificationService.previewNewerVersion(
        this.idVerificationService.getActiveProject()
        , requestKey
        , evidenceKey
        , stepArguments
        , documents
      );
    } else {
      console.log('previewVersionInvestor');
      this.idVerificationService.buildEvidenceLightboxReview(
        this.idVerificationService.getActiveProject()
        , requestKey
        , evidenceKey
        , true
        , documents
        , false
        , undefined
        , undefined
        , undefined
        , true
      );
    }
  }

  revertEvidence(requestKey, evidenceKey, documentKeys) {
    this.idVerificationService.revertEvidence(
      this.idVerificationService.getActiveProject(),
      requestKey,
      evidenceKey,
      documentKeys,
    );
  }

  expireDocument(event, evidenceTypes, fields, requestKey, evidenceKeyClicked, documentKeys) {
    if ($(event.target).attr('type') && $(event.target).attr('type') === 'submit') {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log('**not all fields are valid');
        event.preventDefault();
        return;
      }
    }
    const reasonForReplacement = $('#actionReason').val();
    if (reasonForReplacement === '') {
      return;
    }
    $('.expire-lightbox .loading-icon').removeClass('d-none');
    this.idVerificationService.markEvidenceAsExpired(
      this.idVerificationService.getActiveProject(),
      requestKey ? requestKey : this.idVerificationService.getActiveInvestor(),
      evidenceKeyClicked,
      reasonForReplacement,
      documentKeys
    );
  }

  showExpireWindow() {
    this.idVerificationService.previewWindowPopUp.next('expiry');
    $('.expire-lightbox').removeClass('d-none');
  }

  closeExpireWindow() {
    $("#actionReason").val('');
    $("#actionReason").removeClass('invalid-input');
    $(".error-field").removeClass('invalid-input');
    $('.expire-lightbox').addClass('d-none');
  }

  showVersionLightbox(event) {
    $('#versionLightbox').removeClass('d-none');
  }

  closeVersionLightbox(event) {
    $('#versionLightbox').addClass('d-none');
  }

  refreshIframe() {
    $('.downloadable-iframe-preview').each(function () {
      const tempSrc = $(this).attr('src');
      $(this).attr('src', tempSrc);
    });
  }

}
