<div class="integrity-check-container d-flex flex-column flex-fill justify-content-center ">
    <div class="ic-input-container d-flex justify-content-center">
        <div class="d-flex flex-column position-relative w-100">
          <input
            class="form-control w-100"
            value="{{ companySearchTerm }}"
            (keyup)="OnAutoSuggest($event)"
            [attr.isRequired]='true'
            [disabled]="getKvkServiceState()">
          <div class="error-field"
            [attr.validatorMessage] = ''>
          </div>
          <div class="autosuggest-container" *ngIf='kvkResults'>
            <ul>
              <li *ngFor='let result of kvkResults'>
                <a href="#" [attr.kvkNumber]='result.kvkNumber' [attr.companyName]='result.businessName' (click)='OnSelectCompanyForReview($event)'>
                  <div class="autosuggest-name">{{ result.businessName }}</div>
                  <div class="autosuggest-kvk">{{ result.kvkNumber }}</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="element-wrapper" *ngIf="getKvkServiceState()">
      <div class="d-flex position-relative w-100">
        <span class="input-label">KVK service is unavailable at the moment.<br>Please enter the KVK Number below to flag a company!</span>
      </div>
    </div>
    <div class="d-flex position-relative w-100">
      <input
        id="fallbackKvk"
        class="form-control"
        [(ngModel)]="fallbackKVK"
        *ngIf="getKvkServiceState()">
    </div>
  </div>

  <app-content-elements [contentDOM]="contentDetails" *ngIf="companyDetails"></app-content-elements>
