<app-header
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  (formBuilderPreview)='formBuilderPreview($event)'
  ></app-header>

<!-- FORMIO HEADER -->
<app-form-header
  *ngIf="!formBuilderService.previewList"
></app-form-header>

<!-- EDIT FORM SCREEN -->
<div
  class="formio-wrapper"
  *ngIf="!formBuilderService.previewList && !formBuilderService.preview && !formBuilderService.previewJson && !formBuilderService.editOutput"
>
  <div class="container">
    <!-- <div class="d-flex flex-column pb-2 buttons">
      <button (click)="formBuilderService.togglePreviewList()" class="preview-button mb-3" [ngClass]='{"display": formattedJson}'>My Org Forms</button>
      <button (click)="formBuilderService.preview = !formBuilderService.preview" class="preview-button mb-3" [ngClass]='{"display": formattedJson}'>Preview Form</button>
      <button (click)="togglePreviewJson()" class="preview-button" [ngClass]='{"display": formattedJson}'>Preview JSON</button>
    </div> -->
    <div class="pb-5"></div>
    <form-builder [form]="formBuilderService.form" (change)="onChange($event)" (ready)="onRender($event)"></form-builder>
  </div>
</div>

<!-- EDIT OUTPUT(s) SCREEN -->
<app-form-outputs-editor
  *ngIf="formBuilderService.editOutput"></app-form-outputs-editor>

<!-- list all forms/contract for my organization -->
<app-form-builder-list-of-contracts
  *ngIf="formBuilderService.previewList"></app-form-builder-list-of-contracts>

<!-- FORM PREVIEW DIALOG -->
<app-form-builder-preview
  *ngIf="formBuilderService.preview"></app-form-builder-preview>

<!-- JSON PREVIEW DIALOG -->
<app-form-builder-json-preview
  *ngIf="formBuilderService.previewJson"></app-form-builder-json-preview>

<!-- FORM LIST PREVIEW DIALOG -->
<!-- DEPERCATED PREVIEW IN DIALOG -->
<!-- <div class="preview" *ngIf="false && formBuilderService.previewList">
  <div class="content">
    <div class="close-preview" (click)="formBuilderService.togglePreviewList()">
      <img _ngcontent-c4="" src="assets/images/close_form_icon.png">
    </div>
    <div
      *ngIf="displayLoader"
        class="position-absolute w-100 h-100">
        <div
          class="jsonLoader d-flex w-100 h-100 align-items-center justify-content-center">
          <img
            width="40px"
            height="40px"
            src="/assets/images/spinner-green.svg" />
        </div>
      </div>
    <h1 _ngcontent-c4="" class="form-type-heading">My organisation templates</h1>
    <div class="well jsonviewer d-flex flex-column">
      <div class="d-flex flex-row w-100 align-items-start justify-content-start mb-3">
        <div
          class="card d-flex flex-column"
          *ngFor="let template of myOrganisationTemplates"
          [ngClass]="{'disabled': template.key == 'defaultContractTemplate'}"
        >
          <div class="d-flex flex-fill mx-auto align-items-center body" [attr.data-template-key]="template.key" [attr.data-template-name]="template.name" (click)="OnFormEdit($event)">{{template.name}}</div>
          <div class="d-flex w-100 menu-wrapper pt-2">
            <a href="#" class="d-flex justify-content-end align-items-end settings-icon ml-auto mr-0" (click)="DisplayMyOptions($event)">
              <img src="/assets/images/ico-settings.svg" width="20px" height="20px" alt="d-flex settings icon">
            </a>
            <ul class="form-submenu" >
              <li *ngIf="template.key != 'defaultContractTemplate'"><a href="#" (click)="OnFormEdit($event)">Edit</a></li>
              <li><a href="#" (click)="OnCopyFrom($event)">Copy from ...</a></li>
              <li *ngIf="template.key != 'defaultContractTemplate'"><a href="#" (click)="OnFormUnpublish($event)">Unpublish</a></li>
              <li *ngIf="template.key != 'defaultContractTemplate'"><a href="#" (click)="OnFormDelete($event)">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
      <pre id="json2" *ngIf="stringifiedJson"><code class="language-json" #json></code></pre>
      <pre
        id="jsonUpload"
        (dragenter)="OnJsonFileUploadDragEnter($event)"
        (dragover)="OnJsonFileUploadDragOver($event)"
        (drop)="OnJsonFileUploadDrop($event)"
        (click)="OnJsonFileUploadClick($event)"
        class="button-wrapper text-right"
        class="jsonUpload d-flex flex-column w-100 flex-fill align-items-center justify-content-center"
        *ngIf="!stringifiedJson">
        <div class="mb-3">Edit an existing form from locally saved JSON schema</div>
        <button class="form-button form-button-1"  (click)="onInitiateFirstImport($event)"> Import JSON file </button>
      </pre>
    </div>
    <div
      class="text-right"
      *ngIf="stringifiedJson">
      <a class="form-button form-button-1"  href="#" (click)="onInitiateImport($event)"> Import New JSON</a>
      <a class="form-button form-button-1" [href]="downloadJson"  download="form.json"> Export </a>
      <a class="form-button form-button-grey" href="#" (click)="$event.preventDefault(); togglePreviewJson();"> Close </a>
    </div>
    <div
      class="d-flex flex-row w-100 align-items-center justify-content-end pr-4"
      *ngIf="!stringifiedJson">
      <div class="pr-2">or start a new form from scratch:</div>
      <a
        class="form-button form-button-1 text-center"
        href="#"
        (click)="$event.preventDefault(); formBuilderService.togglePreviewJson();"> New Form </a>
    </div>
    <input
        style="display: none"
        type="file" (change)="onImport($event)"
        #fileInput>
  </div>
</div> -->


<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
