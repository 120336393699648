<!-- FORM PREVIEW DIALOG -->
<div
  class="formio-wrapper"
>
  <div class="preview container">
    <div class="content">
      <!-- <div class="close-preview" (click)="formBuilderService.preview = !formBuilderService.preview">
        <img _ngcontent-c4="" src="assets/images/close_form_icon.png">
      </div>
      <h1 _ngcontent-c4="" class="form-type-heading">Preview Form</h1> -->
      <h3 class="mt-3 mb-2">Your form in action</h3>
      <div>See the form in action. Populate the form below and cliick the submit button to be able to preview the generated contract as HTML or download it as PDF</div>
      <div class="wrapper mt-4">
        <formio (ready)="ready($event)" (submit)="onSubmit($event)" [form]='formBuilderService.formattedJson'></formio>
      </div>
    </div>
  </div>
</div>


<app-lightbox *ngIf='displayModal && !errorState'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
