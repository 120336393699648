import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IntegrityService } from '../../integrity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integrity-search-history',
  templateUrl: './integrity-search-history.component.html',
  styleUrls: ['./integrity-search-history.component.scss']
})
export class IntegritySearchHistoryComponent implements OnInit {

   // header variables
   headerInfo: {};
   headerClasses: {};
   kvkResults: any;

  // lightbox parameters:
  displayModal: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;

  // search history
  shTitle: string;
  searchHistory: any[]; // will hold a list with all the searches

   constructor(private cd: ChangeDetectorRef, private integrityService: IntegrityService, private router: Router) { }

  ngOnInit() {
    // initialize the header
    this.headerInfo = {
      'headerType': 'inline_minimal',
      'headerTitle': 'Search History',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg ic_height_auto header-inline',
      'sectionClass': 'ic_height_auto',
    };

    // init modal lightbox
    this.displayModal = false;
    this.formType = 'Perform Integrity Check';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = 'Agree';
    this.formValidator = '';
    this.btnCancelText = 'Disagree';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;

    // search history
    this.shTitle = 'Complete list of your previous search actions';
    // for testing purposes:
    // this.searchHistory = [
    //   {
    //     'key': 'string',
    //     'record': {
    //       'noOfFlags': 2,
    //       'createdOn': '2015-07-20T15:49:04-07:00',
    //       'serviceStartDate': '2015-07-20T15:49:04-07:00',
    //       'serviceEndDate': '2015-07-20',
    //       'status': 'open',
    //       'company': {
    //         'businessName': 'Finos',
    //         'kvkNumber': 12345678
    //       }
    //     }
    //   },
    //   {
    //     'key': 'string',
    //     'record': {
    //       'noOfFlags': 0,
    //       'createdOn': '2016-07-20T15:49:04-07:00',
    //       'serviceStartDate': '2017-07-20T15:49:04-07:00',
    //       'serviceEndDate': '2018-07-20',
    //       'status': 'open',
    //       'company': {
    //         'businessName': 'Financial Holdings B.V.',
    //         'kvkNumber': 2154878
    //       }
    //     }
    //   },
    //   {
    //     'key': 'string',
    //     'record': {
    //       'noOfFlags': 8,
    //       'createdOn': '2016-07-20T15:49:04-07:00',
    //       'serviceStartDate': '2017-07-20T15:49:04-07:00',
    //       'serviceEndDate': '2018-07-20',
    //       'status': 'open',
    //       'company': {
    //         'businessName': 'Financial Holdings B.V.N.',
    //         'kvkNumber': 2154878
    //       }
    //     }
    //   },
    //   {
    //     'key': 'string',
    //     'record': {
    //       'noOfFlags': 8,
    //       'createdOn': '2016-07-20T15:49:04-07:00',
    //       'serviceStartDate': '2017-07-20T15:49:04-07:00',
    //       'serviceEndDate': '2018-07-20',
    //       'status': 'open',
    //       'company': {
    //         'businessName': 'Holdings A.C.B.',
    //         'kvkNumber': 2154878
    //       }
    //     }
    //   }
    // ];

    // load search history results
    this.integrityService.loadSearchHistoryResults();

    // subscribe to serch history results change
    this.integrityService.searchHistoryResults.subscribe(
      result => {
        this.searchHistory = result;
      }
    );
  }

  OnSelectedCompanyChange(companyDetails) {
    this.formTitle = companyDetails.companyName;
    this.integrityService.setCurrentCompany(companyDetails);

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        // 'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'image',
          'params': {
            'src': 'assets/images/integrity_check_image.png'
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'Be aware ...'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'By performing an integrity check you agree that all actions you take on this entity from this point forward will be recorded for future retrieval by anyone within your organisation'
          }
        },
        // BEGINING OF FORM
        // disable this for now
        // {
        //   'type': 'checkbox',
        //   'params': {
        //     'id': 'dont_show_message',
        //     'name': 'dont_show_message',
        //     'label': 'Don\'t show this message again',
        //     'value': '1'
        //   }
        // },
        // EOF elements of form

        // don't submit for now
        // {
        //   'type': 'submit',
        //   'params': {
        //     'content': 'Send request',
        //     'action': 'OnSendRequestInformation',
        //     'display': true
        //   }
        // },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  onSearchTermChanged(event) {
    if (event === '') {
      this.kvkResults = null;
      this.cd.detectChanges();
      return;
    }
    this.integrityService.getIntegrityCheckResults(event)
      .subscribe((data: any) => {
        if (data === undefined || data.length === 0) {
          data = null;
        }
        console.log('from server: ', data);
        this.kvkResults = data;
        // this.kvkResults = data.companies;
        this.cd.detectChanges();
        });
  }

  onLightboxConfirm(event) {
    this.displayModal = false;
    this.router.navigateByUrl('integrity-check/dashboard');
  }

  onLightboxClose() {
    this.kvkResults = null;
    this.cd.detectChanges();
    this.displayModal = false;
  }

  onGoToIntegrityCheck(event) {
    this.integrityService.setScrollToKey($(event.target).closest('.search-history-entry').attr('key'));
    this.router.navigateByUrl('integrity-check/dashboard');
  }

}
