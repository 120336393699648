import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-profiles-status-information',
  templateUrl: './profiles-status-information.component.html',
  styleUrls: ['./profiles-status-information.component.scss']
})
export class ProfilesStatusInformationComponent implements OnInit {
  @Input() profileRecord : any [];
  evidenceTypes : any []= [];
  statuses = {};
  items : boolean;
  readonly statusClass = {
    "not requested": "not-started",
    "in progress": "pending",
    "completed": "completed",
    "expired": "failed",
    "failed": "failed",
    "action required": "pending",
    "request rejected": "failed",
    "requested": "pending",
    "in review": 'pending'
  }

  constructor(
    public elementService: ElementsService,
    public translate: TranslateService,
    private helperService : HelperServiceService,
    private idVerificationService : IdVerificationService

  ) { }


  ngOnInit(): void {

    this.generateAllEvidenceForProfile();
    this.generateProfileStatusInformationObject()

    this.items = this.evidenceTypes?.length > 1;
  }

  generateAllEvidenceForProfile(): void
  {
    let profileRecordDeepCopy = JSON.parse(JSON.stringify(this.profileRecord));
    if(profileRecordDeepCopy['evidenceTypes'] || profileRecordDeepCopy['digitalVerifications'])
    {
      this.evidenceTypes = [... this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(profileRecordDeepCopy['evidenceTypes'], profileRecordDeepCopy['digitalVerifications'])];
    }
    if(profileRecordDeepCopy['basicFieldsEvidence'])
    {
      this.evidenceTypes.push(profileRecordDeepCopy['basicFieldsEvidence']);
    }
    if(profileRecordDeepCopy['relatedParties'])
    {
        profileRecordDeepCopy['relatedParties'].forEach(relatedParty =>{
        if(relatedParty['record']['digitalVerifications']?.length>0 || relatedParty['record']['evidenceTypes']?.length>0)
        {
          let tmp = this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(relatedParty['record']['digitalVerifications'], relatedParty['record']['evidenceTypes']);
          this.evidenceTypes.push(...tmp);
        }
        if(relatedParty['record']['basicFieldsEvidence'])
        {
          this.evidenceTypes.push(relatedParty['record']['basicFieldsEvidence']);
        }

      });
    }
    // filter duplicate evidence

    this.evidenceTypes= this.evidenceTypes.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.originalEvidenceKey === value.originalEvidenceKey && t.key === value.key
      )));
  }

  generateProfileStatusInformationObject(): void
  {
    this.evidenceTypes?.forEach(evidence =>
      {
        let status = evidence.status.toLowerCase();
        if(this.idVerificationService.isInvestorType() && status === 'action required')
        {
          status = 'in review';
        }
        // Count the evidences in that status
        this.statuses[status] = 1 + (this.statuses[evidence.status.toLowerCase()] || 0)
      }
    );

  }

}
