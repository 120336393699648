<div *ngIf="apsFields.hits.length > 0" class="document-preview-aps full-width isLoading" >
    <div>
      <h5 class="aps-hits-top-label">{{idVerificationService.getRelevantHits()==0 
                                      ? ('evidencePreview.apsPreview.noRelevantHits' | translate ) 
                                      : !olderComplianceCheck
                                      ? (('evidencePreview.apsPreview.label1' | translate) + ' (' + idVerificationService.getRelevantHits() + ' active)')
                                      : ('evidencePreview.apsPreview.label1' | translate)
                                     }} 
                                    </h5>
      <small *ngIf="apsFields.checkDateText">{{apsFields.checkDateText}}</small>
      <div class="hits-wrapper">
        <div 
        *ngFor="let hit of apsFields.hits; let i = index" 
        class="hit-name-container"
        [ngClass]="{'not-relevant-hit' : hit.isWhitelisted }">
          <a class="document-category-main" [ngClass]="i== 0 && activeFirstHit ?  'category-active' : ''" *ngIf="!hit.isWhitelisted || (hit.isWhitelisted && idVerificationService.getShowIrrelevantHits())">
            <h3 class="content" [ngClass]="{'orange-dot': hit.isReviewed == false }"> {{hit.name}} </h3>
            <div class="position-absolute document-category-arrow d-flex align-items-center">
              <div class="hit-list-pills">
                <div class="d-flex" style="margin-bottom: 1px; margin-top: 1px;" *ngFor="let hit of hit.keyData.hitsMinified">
                  <div class="purpose-tag purpose-tag-no-close hit-list-pill" >
                    {{hit}}
                  </div>
                </div>
              </div>
              <img class="hit-list-arrow" src="assets/images/collapse_document_upload.png">
            </div>
            <div [id]="'apsHit' + i"  (click)="apsChangeResults($event)"
                style="z-index:100; top:0;left:0;"   class="clickable-element h-100 w-100 position-absolute">
            </div>
          </a>
        </div>
      </div>
      <div class="single-checkbox-wrapper" *ngIf="!olderComplianceCheck">
        <input class="custom-checkbox"
          type="checkbox"
          id="show-irrelevant-hits"
          name="irrelevant-hits"
          (change) = "idVerificationService.changeShowIrrelevantHits()"
          [checked] = "idVerificationService.getShowIrrelevantHits() && idVerificationService.getIrrelevantHits() > 0"
          [disabled] = "idVerificationService.getIrrelevantHits() == 0"
          >
        <label 
        class="custom-checkbox-label" 
        for="show-irrelevant-hits"
        [tooltip] = "idVerificationService.getIrrelevantHits() == 0 ? 'There are no not relevant hits' : ''">
        Show hits marked as not relevant ( {{idVerificationService.getIrrelevantHits()}} not relevant hits )
      </label>
      </div>
    </div>
    <div class="hit-results-wrapper" *ngIf="(idVerificationService.getRelevantHits() > 0 || idVerificationService.getShowIrrelevantHits())">
      <div [id]="'apsHit' + i + 'result'" *ngFor="let hit of idVerificationService.getAPSFields().hits; let i = index" [ngClass]="i== 0  && activeFirstHit ?  '' : 'd-none'" class="hit-results-container">
        <div class="document-preview-tabs">
          <a (click)="apsChangeResultTab($event)" [id]="'apsHit' + i + 'keyData'" class="active aps-result-tab">
            {{'evidencePreview.apsPreview.label2' |translate}}
          </a>
          <a (click)="apsChangeResultTab($event)" *ngIf="hit.adverse.length > 0" [id]="'apsHit' + i + 'adverse'" class="aps-result-tab" >
            {{'digitalVerifications.aps.title3' | translate}}
          </a>
          <a (click)="apsChangeResultTab($event)" *ngIf="hit.sanctions.length > 0" [id]="'apsHit' + i + 'sanctions'" class="aps-result-tab">
            {{'digitalVerifications.aps.title' | translate}}
          </a>
          <a (click)="apsChangeResultTab($event)" *ngIf="hit.pep.length > 0" [id]="'apsHit' + i + 'pep'" class="aps-result-tab">
            {{'digitalVerifications.aps.title2' | translate}}
          </a>
          <a *ngIf="isFunds" (click)="apsChangeResultTab($event)" [id]="'history'" class="ml-auto mr-0 aps-result-tab">
            {{'evidencePreview.historyLabel' | translate}}
          </a>
        </div>
        <div  class="aps-result-tab-content" [id]="'apsHit' + i + 'keyData' + 'result'">
          <div class="form-clarification custom-collapse my-4 p-3">
            <div  [attr.href]="'#hitSummary_' + i" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="multiCollapseExample1" class="d-flex collapse-header">
              <h2  class="form-clarification-title pl-2 mb-0">
                Hit summary
              </h2>
              <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown">
            </div>
            <div class="collapse clarification-content show" [id]="'hitSummary_' + i">
              <div class="document-field-wrapper">
                <div  class="document-field" *ngIf="hit.keyData.hits">
                  <div class="document-field-label"> {{'evidencePreview.apsPreview.label3' | translate}}</div>
                  <div class="d-flex flex-wrap" style="width: 263px;">
                    <div class="d-flex" *ngFor="let hit of hit.keyData.hits">
                      <div  style="margin-top:10px;" class="purpose-tag purpose-tag-no-close">
                        {{hit}}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="document-field" *ngIf="hit.keyData.match_status">
                    <div class="document-field-label">{{'evidencePreview.apsPreview.label4' | translate }}</div>
                    <div class="document-field-value">{{hit.keyData.match_status}}</div>
                  </div>
                  <div class="document-field" *ngIf="hit.keyData.relevance">
                    <div class="document-field-label">{{'evidencePreview.apsPreview.label5' | translate }}</div>
                    <div class="document-field-value">{{hit.keyData.relevance}}</div>
                  </div>
                </div>
                <div class="document-field" *ngIf="hit.keyData.countries && hit.keyData.countries.length > 0">
                  <div class="document-field-label">{{'evidencePreview.apsPreview.label6' | translate }}</div>
                  <div class="document-field-value">
                    <span *ngFor="let country of hit.keyData.countries; last as isLast">
                      {{isLast ? country : country + ', '}}
                    </span>
                  </div>
                </div>
                <div class="document-field" *ngIf="hit.keyData.adverseCategories && hit.keyData.adverseCategories.length > 0">
                  <div class="document-field-label">{{'evidencePreview.apsPreview.label7' | translate}}</div>
                  <div class="document-field-value">
                    <span *ngFor="let category of hit.keyData.adverseCategories; last as isLast">
                      {{isLast ? category : category + ', '}}
                    </span>
                  </div>
                </div>
                <div class="document-field w-100" *ngIf="hit.keyData.aka">
                  <div class="document-field-label">{{'evidencePreview.apsPreview.label8' | translate}}</div>
                  <div class="document-field-value">
                    <span *ngFor="let aka of hit.keyData.aka;  last as isLast">
                      {{isLast ? aka.name : aka.name + ', '}}
                    </span>
              </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hit.historyLog && resetHits" class="form-clarification custom-collapse mt-2 mb-4 p-3">
            <div  [attr.href]="'#hitHistoryLog_' + i" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="multiCollapseExample1" class="d-flex collapse-header">
              <h2  class="form-clarification-title pl-2 mb-0">
                Hit history log
              </h2>
              <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown">
            </div>
            <div class="collapse clarification-content  hit-history-log" [id]="'hitHistoryLog_' + i" >
              <app-activity-log 
              [activityLog]="hit.historyLog"
              [tooltipPosition]="TooltipPositionDynamic"
              ></app-activity-log>
            </div>
          </div>
        </div>
        <div class="d-none aps-result-tab-content" [id]="'apsHit' + i + 'adverse' + 'result'">
          <div class="aps-media-wrapper" *ngFor="let media of hit.media">
            <a target="_blank" [href]="media.url" class="font-weight-bold sp-header aps-media-snippet">{{media.title}}</a>
            <div class="aps-media-date" [innerHTML] = " media.date ? ('evidencePreview.apsPreview.label9' | translate :
                                        {date : helperService.formatISODate(media.date)}) : ''" ></div>
            <div class="aps-media-title">{{media.snippet}}</div>
          </div>
        </div>
        <div class="d-none aps-result-tab-content" [id]="'apsHit' + i + 'sanctions' + 'result'">
          <div  class="single-sp-wrapper flex-wrap d-flex "*ngFor="let sanction of hit.sanctions">
            <a class="sp-header w-100 font-weight-bold" target="_blank " [href]="sanction.url">{{sanction.name}}</a>
            <div class="document-field" *ngFor = "let sourceField of sanction.sourceFields">
              <div class="document-field-label">{{sourceField.name}}</div>
              <div class="document-field-value">{{sourceField.value}}</div>
            </div>
          </div>
        </div>
        <div class="d-none aps-result-tab-content" [id]="'apsHit' + i + 'pep' + 'result'">
          <div  class="single-sp-wrapper flex-wrap d-flex "*ngFor="let pep of hit.pep">
            <div class="sp-header w-100 font-weight-bold" >{{pep.name}}</div>
            <div class="document-field" *ngFor = "let sourceField of pep.sourceFields">
              <div class="document-field-label">{{sourceField.name}}</div>
              <div class="document-field-value">{{sourceField.value}}</div>
            </div>
            <div *ngIf="hit.relatedUrls && hit.relatedUrls.length > 0">
              <div class="document-field pb-1">
              <div class="document-field-label">{{'evidencePreview.apsPreview.label10' | translate}}</div>

              </div>
              <div class="document-field py-0" *ngFor = "let relatedUrl of hit.relatedUrls">
                <a target="_blank" href="{{relatedUrl.value}}" >
                  {{relatedUrl.value | slice:0:66  }}{{relatedUrl.value.length > 66 ? '...' : '' }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- btn for whitelisting hit -->
       <button (click) = "onWhitelistingHit(i)" class="form-button form-button-link-2 float-right"
        *ngIf="!olderComplianceCheck">
          {{(hit.isWhitelisted ? 'Mark this hit as relevant' : 'Mark this hit as not relevant' )}}
        </button>
        <button (click) = "onCommentHit(i)" class="form-button form-button-link-2 float-right"
        *ngIf="!olderComplianceCheck">
          Add comment
        </button>
      </div>
      <!-- START HISTORY CONTENT -->
      <div *ngIf="isFunds" class="d-none aps-result-tab-content" [id]="'historyresult'">
        <div class="document-preview-tab-content">
          <div class="ev-preview-tab-content history-content history-content-aps">
            <div *ngIf='historyEntries' class="preview-label">{{'evidencePreview.apsPreview.label11' | translate}}</div>
            <div *ngIf='historyEntries'>
              <div [ngClass] = "historyEntry.class? historyEntry.class : ''"
              class="history-entry"
              *ngFor="let historyEntry of historyEntries">
                  {{historyEntry.text}}
                  <div class="history-entry-dot history-entry-dot-slim">
                    <div></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END HISTORY CONTENT-->

    </div>
  </div>
  <div  *ngIf="apsFields.hits.length === 0 " class="no-hits-container full-width">
    <img src="/assets/images/big-check.svg">
    <div class="no-hits-title">{{'evidencePreview.apsPreview.content1' | translate}}</div>
    <p>{{'evidencePreview.apsPreview.content2' | translate}}<p>
    <small *ngIf="apsFields.checkDateText">{{apsFields.checkDateText}}</small>
</div>
