import { Component, HostListener, OnInit } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { TooltipPosition } from './tooltip.enums';


@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

  position: TooltipPosition = TooltipPosition.DEFAULT;
  tooltip: string = '';
  left: number = 0;
  top: number = 0;
  visible: boolean = false;

  @HostListener('mouseleave', ['$event'])
  onMouseLeave($event: MouseEvent): void {
    let classList = document.elementFromPoint($event.clientX, $event.clientY).classList
    if(!(classList.contains("tooltip") || classList.contains("icon-tooltip-wrapper") || classList.contains("icon-tooltip")))
    {
      this.tooltipDirective.setHideTooltipTimeout();
    }
  }
  constructor(private tooltipDirective: TooltipDirective) {
  }

  ngOnInit(): void {
  }

}
