<!-- <app-header
style="min-height: 56px;"
[headerInfo]='headerInfo'
[headerClasses]='headerClasses'
[kvkResults]='kvkResults'
></app-header> -->
<app-header
  style="min-height: 56px;"
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  [ngClass]= "{
  'header-height-0' : (projectsType === 'investor' && getActiveProject() && reviewStatus !== 'review') ,
  'header-height-auto' : ((reviewStatus !== 'loading' && reviewStatus !== 'review') || projectsType !== 'investor') ,
  'header-hide-logo' : hideHeaderLogo
  }"
></app-header>

<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTypeAlignment]='formTypeAlignment'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>

  <app-lightbox *ngIf='topDisplayModal'
    [formType]='topFormType'
    [formTypeAlignment]='topFormTypeAlignment'
    [formTitle]='topFormTitle'
    [formClass]='topFormClass'
    [formSubTitle]='topFormSubTitle'
    [lightboxClass]='topLightboxClass'
    [btnOkText]='topBtnOkText'
    [btnCancelText]='topBtnCancelText'
    [displayModal]='topDisplayModal'
    [displayLoader]='topDisplayLoader'
    [contentDOM]="topLightboxContent"
    (confirmLightbox)="onLightboxConfirm($event)"
    (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>

  <app-lightbox *ngIf='foremostDisplayModal'
    [formType]='foremostFormType'
    [formTypeAlignment]='foremostFormTypeAlignment'
    [formTitle]='foremostFormTitle'
    [formClass]='foremostFormClass'
    [formSubTitle]='foremostFormSubTitle'
    [lightboxClass]='foremostLightboxClass'
    [btnOkText]='foremostBtnOkText'
    [btnCancelText]='foremostBtnCancelText'
    [displayModal]='foremostDisplayModal'
    [displayLoader]='foremostDisplayLoader'
    [contentDOM]="foremostLightboxContent"
    (confirmLightbox)="onLightboxConfirm($event)"
    (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>

  <div *ngIf="projectsType === 'investor' && getActiveInvestors() && getActiveProject()" style="margin-bottom: 24px;" 
  [ngClass] = "reviewStatus === 'no-review' ? 'd-flex' : 'd-none'"
  class="justify-content-center align-items-center mt-5 flex-column">
   
    <div class="disclaimer-wrapper disclaimer-wrapper-alternate-margin mb-1 d-flex align-items-center"
    [ngClass]="{'review-completed': reviewStatus === 'no-review', 'review-loading' : reviewStatus === 'loading'}">
      <div   class="content">
        <h3 class = "{{reviewStatus === 'no-review' ? 'review-completed' : reviewStatus === 'loading'? 'review-loading' : ''}}">
            {{reviewStatus === 'loading' ? ' ' :
              reviewStatus === 'review' ? ('dashboard.title' | translate) :
              ('dashboard.title3' | translate)
        }} </h3>
        {{ reviewStatus === 'review' ?
        ('dashboard.content' | translate):
        reviewStatus === 'loading' ? ' ' :
        ('dashboard.content3' | translate)}}
      </div>
      <div *ngIf="reviewStatus === 'review'"  class="buttons">
        <button (click) = "startReviewProcess()" class="form-button form-button-1">
          {{('dashboard.button' | translate)}}
        </button>
      </div>
      <div class="disclaimer-loader" *ngIf="reviewStatus === 'loading'" >
          <img src="/assets/images/spinner-green.svg"/>
      </div>
    </div>
    <div *ngIf="reviewStatus === 'no-review'  && dvInvestorStatus === 'warning'" 
          class="disclaimer-wrapper disclaimer-wrapper-alternate-margin  mb-1 mt-3 d-flex align-items-center review-pending">
      <div   class="content">
        <h3 class="review-pending">
          {{('dashboard.title2' | translate)}}
        </h3>
        {{('dashboard.content4' | translate)}}
      </div>
    </div>
  </div>
<div id="legalRepresentatives"
  [ngClass]="{'section-wrapper' : (activeUser === 1 && headerInfo['headerType'] === 'inline' && (groups.legalRepresentatives[0] | json) !== '{}'),
              'investor-flow' : projectsType === 'investor',
              'd-none' : projectsType === 'investor',
              'd-flex' : reviewStatus === 'no-review' }"
  class="justify-content-center"
  *ngIf="activeUser === 1 && headerInfo['headerType'] === 'inline' && (groups.legalRepresentatives[0] | json) !== '{}'"
>
  <app-content-builder style="max-width: 100vw;" [contentDOM]="groups.legalRepresentatives" (contentReturn)='parseContentReturn($event)' (elementChangesCompleted)="parseElementChangesCompleted()"></app-content-builder>
</div>

<div *ngIf="projectsType === 'investor'"
  class=" d-none justify-content-center align-items-center"
  [ngClass] = "{'d-flex' : reviewStatus === 'review'}" >
    <app-cdd-process-control-screen
    style="width: 100%; position: fixed; left: 0; top:0; width: 100%; height: 100vh; z-index: 1000; overflow: auto;"
    (pcsReviewFunction) = pcsReviewFunction($event)
    [isReview] = true>
    </app-cdd-process-control-screen>
</div>

<app-fullscren-loading-overlay

*ngIf="(((reviewStatus === 'loading' || !reviewStatus) && showPCSLoading) && projectsType === 'investor') || this.idVerificationService.forceLoadingScreen">

</app-fullscren-loading-overlay>

<app-footer></app-footer>
