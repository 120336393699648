import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { IntegrityService } from 'src/app/integrity-check/integrity.service';
import { UiService } from '../ui.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { Router } from '@angular/router';
import { HelperServiceService } from 'src/app/helper-service.service';
import { Subscription } from 'rxjs';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { ToastNotificationsService } from '../toast-notifications.service';

@Component({
  selector: 'app-content-builder',
  templateUrl: './content-builder.component.html',
  styleUrls: ['./content-builder.component.scss']
})
export class ContentBuilderComponent implements OnInit, OnDestroy, OnChanges {

  model: any = {};
  @Input() contentDOM: any[];
  @Input()  triggerValidation: any;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() elementChangesCompleted = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  groups: any;
  loadingLocked = false;
  lastLoad = false;
  loadMoreSub: Subscription;
  RRDateFilter: string ='0';

  constructor(
    private integrityService: IntegrityService,
    private uiService: UiService,
    private idVerificationService: IdVerificationService,
    private helper: HelperServiceService,
    private router: Router,
    private toastNotification: ToastNotificationsService) {
  }

  ngOnInit() {
    // initialize the content:
    this.groups = this.contentDOM;
    // parse the content
    this.loadMoreSub = this.idVerificationService.detectLoadMorePersons.subscribe( response => {
      if (response.resetFinalLoad) {
        this.lastLoad = false;
      } else {
        if (response.onlyOneLoad) {
          this.lastLoad = true;
        } else {
          this.loadingLocked = false;
          // setTimeout(function()
          // {
          // $('.group-beyond-wrapper').css('overflow','');
          // $('.group-beyond-wrapper').scrollTop(response.scrollTop);
          // ; },
          // 100);
        }
        if (response.finalLoad) {
          this.lastLoad = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.loadMoreSub.unsubscribe();
  }

  ngOnChanges(changes) {
    this.groups = this.contentDOM;
  }

  /**
   * this method will get executed on any button click
   * @param event: any
   */
  OnButtonClick(event) {
    event.preventDefault();
    const method = event.target.getAttribute('clickmethod');
    this.contentReturn.emit({
      'method' : method,
      'params' : event
    });
  }

  /**
   * this method will get executed on SUBMIT button click
   * @param event: any
   */
  OnSubmitClick(event) {
    event.preventDefault();
    console.log('TRIGGER CONTENT EDITOR VALIDATION');

    if ($(event.target).attr('isValidator') && $(event.target).attr('isValidator') === 'true') {
      if (!this.uiService.validateLightboxFields(event)) {
        return;
      }
    }
    // no validation needed, execute
    const method = event.target.getAttribute('clickmethod');
    console.log('THE FORM: ', event.target.getAttribute('formid'));
    console.log('THE FORM ARRAY: ', $('#' + event.target.getAttribute('formid')).serializeArray());
    this.contentReturn.emit({
      'method' : method,
      'params' : $('#' + event.target.getAttribute('formid')).serializeArray()
    });
  }

  /**
   * toggle expand the result pane on click on the arrow
   */
  OnResultToggle(event, originalclass, toggleclass) {
    // CHECK TO SEE IF WE ARE CLOSING THE SAME INVESTOR
    // ITS NOT RENDERED ALL OVER AGAIN IF WE ARE
    // remember the position of the scroll element so we can return when it refreshes
    const filters = this.idVerificationService.getActiveLegalrepresentativesFilters();
    if (filters) {
    filters.scrollTop = $(event.target).closest('.result-container').get(0).offsetTop;

    // check if element is expanded
    if ($('.person-list-entry.expanded').length > 0) {
      if ($('.person-list-entry.expanded').get(0).offsetTop < $(event.target).closest('.result-container').get(0).offsetTop ) {
        const expandedHeight = $('.person-list-entry.expanded').outerHeight();
        filters.scrollTop =  filters.scrollTop - expandedHeight + 60;
      }
    }
    this.idVerificationService.setActiveLegalrepresentativesFilters(filters);
    // remember the position of the scroll element so we can return when it refreshes
    }
    const investorElement = $(event.target).closest('.person-list-entry ');
    const investorKey = $(investorElement).attr('key');
    if (this.idVerificationService.getActiveInvestor() === investorKey || this.idVerificationService.getActivePerson() === investorKey) {
      $(investorElement).find('.actionsMenuWrapper').toggleClass('d-none');
      $(investorElement).closest('.actionsMenuWrapper').find('.action-icon').toggleClass('icon-minus');
    }
    let eventTarget = $(event.target);
    let eventImg = $(event.target).find('img');
    if (eventTarget.is('img')) {
      eventTarget = $(event.target).closest('a');
      eventImg = $(event.target);
    }
    // check if there is a method attached to the event target
    const method = eventImg.attr('method');
    const loaderInitMethods = ['onExpandActiveBox'];
    if (typeof method === 'string') {
      if (loaderInitMethods.indexOf(method) !== -1) {
        event.preventDefault();
        event.stopPropagation();
        $(event.target).attr('src', 'assets/images/spinner-gray.svg').width('17px').height('17px');
      } else {
        // do the expansion only if there is no onexpand method defined
        if (eventTarget.hasClass(originalclass)) {
          eventTarget.removeClass(originalclass);
          eventTarget.addClass(toggleclass);
          const resultContainer = eventTarget.closest('.result-container');
          // prev and next containers only serve to add a css class
          const prevResultContainer = resultContainer.prev();
          const nextResultContainer = resultContainer.next();
          resultContainer.addClass('expanded');
          prevResultContainer.addClass('prevExpanded');
          nextResultContainer.addClass('nextExpanded');
        } else {
          eventTarget.addClass(originalclass);
          eventTarget.removeClass(toggleclass);
          const resultContainer = eventTarget.closest('.result-container');
          // prev and next containers only serve to add a css class
          const prevResultContainer = resultContainer.prev();
          const nextResultContainer = resultContainer.next();
          resultContainer.removeClass('expanded');
          resultContainer.removeClass('prevExpanded');
          resultContainer.removeClass('nextExpanded');
          prevResultContainer.removeClass('prevExpanded');
          nextResultContainer.removeClass('nextExpanded');
        }
        eventTarget.closest('.result-container').addClass('verification-main-disabled');
          setTimeout(function() {
            eventTarget.closest('.result-container').removeClass('verification-main-disabled');
          },
          1000);
      }
      // remove the attribute
      eventImg.removeAttr('method');
      // execute the method
      this.contentReturn.emit({
        'method' : method,
        'params' : {
          'key': eventImg.closest('.result-container').attr('key'),
          'kvk': eventImg.closest('.result-container').attr('kvk'),
          'verificationKey': eventImg.closest('.result-container').attr('verificationKey'),
          'status': eventImg.closest('.result-container').attr('status'),
          'targetGroup': $.trim(eventImg.closest('section').find('.results-group-name').text()),
          'eventTarget': {  'target': event.target,
                            'originalClass': originalclass,
                            'toggleclass': toggleclass }
        }
      });
    } else {
      // do the expansion only if there is no onexpand method defined
      if (eventTarget.hasClass(originalclass)) {
        eventTarget.removeClass(originalclass);
        eventTarget.addClass(toggleclass);
        const resultContainer = eventTarget.closest('.result-container');
        // prev and next containers only serve to add a css class
        const prevResultContainer = resultContainer.prev();
        const nextResultContainer = resultContainer.next();
        resultContainer.addClass('expanded');
        prevResultContainer.addClass('prevExpanded');
        nextResultContainer.addClass('nextExpanded');
      } else {
        eventTarget.addClass(originalclass);
        eventTarget.removeClass(toggleclass);
        const resultContainer = eventTarget.closest('.result-container');
        // prev and next containers only serve to add a css class
        const prevResultContainer = resultContainer.prev();
        const nextResultContainer = resultContainer.next();
        resultContainer.removeClass('expanded');
        resultContainer.removeClass('prevExpanded');
        resultContainer.removeClass('nextExpanded');
        prevResultContainer.removeClass('prevExpanded');
        nextResultContainer.removeClass('nextExpanded');
      }
      eventTarget.closest('.result-container').addClass('verification-main-disabled');
      setTimeout(function() {
        eventTarget.closest('.result-container').removeClass('verification-main-disabled');
      },
      1000);
    }
    // attempt recovery from partial
    if (eventImg.attr('partial') === 'true') {
      // remove the attribute
      eventImg.removeAttr('partial');
      // execute the method
      this.contentReturn.emit({
        'method' : 'doPartialRecovery',
        'params' : {
          'key': eventImg.closest('.result-container').attr('key'),
          'kvk': eventImg.closest('.result-container').attr('kvk'),
          'verificationKey': eventImg.closest('.result-container').attr('verificationKey'),
          'status': eventImg.closest('.result-container').attr('status'),
          'targetGroup': $.trim(eventImg.closest('section').find('.results-group-name').text())
        }
      });
    }

  }

  toggleActionsMenu(event) {
    event.preventDefault();
    $(event.target).closest('.actionsMenuWrapper').find('ul').toggleClass('d-none');
    $(event.target).closest('.actionsMenuWrapper').find('.action-icon').toggleClass('icon-minus');
    

  }
  /**
   * execute actions menu action click
   */
  OnActionClick(event, method) {
    this.toggleActionsMenu(event);
    this.contentReturn.emit({
      'method' : method,
      'params' : event,
    });
  }

  /**
   * execute on clicking a refresh button
   */
  OnRefreshClick(event) {

    event.preventDefault();
    let eventTarget = $(event.target);
    let eventImg = $(event.target).find('img');
    if (eventTarget.is('img')) {
      eventTarget = $(event.target).closest('a');
      eventImg = $(event.target);
    }
    this.idVerificationService.idVerificationResolveAttempt = true;
    // check if there is a method attached to the event target
    const method = eventImg.attr('method');
    const loaderInitMethods = ['OnAttemptLazyRefresh'];
    if (typeof method === 'string') {
      if (loaderInitMethods.indexOf(method) !== -1) {
        event.preventDefault();
        event.stopPropagation();
        $(event.target).attr('src', 'assets/images/spinner-gray.svg').width('17px').height('17px');
      }

      // remove the attribute
      eventImg.removeAttr('method');
      // execute the method
      this.contentReturn.emit({
        'method' : method,
        'params' : {
          'key': eventImg.closest('.result-container').attr('key'),
          'kvk': eventImg.closest('.result-container').attr('kvk'),
          'verificationKey': eventImg.closest('.result-container').attr('verificationKey'),
          'status': eventImg.closest('.result-container').attr('status'),
          'targetGroup': $.trim(eventImg.closest('section').find('.results-group-name').text()),
          'eventTarget': {  'target': event.target }
        }
      });
    }
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    // simply re-emit the returnedAction
    this.contentReturn.emit(returnedAction);
  }

  /**
   * call this method on when elements content has finished updating
   */
  parseElementChangesCompleted() {
    // console.log('parseElementChangesCompleted');
    this.elementChangesCompleted.emit();
  }

  /**
   * set the loader in the lightbox to display, true or false
   * @param event event
   */
  OnDisplayLoader(event) {
    this.displayLoader.emit(event);
  }

  filterList(listType: string, activeFilter: string = 'none') {
    if (listType === activeFilter || listType === 'All') {
      listType = 'none';
    }
    if (listType !== 'none') {
      this.idVerificationService.getActiveLegalrepresentativesFilters().type = listType;
    } else {
      this.idVerificationService.getActiveLegalrepresentativesFilters().type = undefined
    }
    this.idVerificationService.filterType='filter_by_type';
    this.idVerificationService.setActiveInvestor('');
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    // reset the bookmark since we are changing persons
    this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);
    this.idVerificationService.retreiveActiveInvestors (
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor(),
      'funds',
      false,
      false,
      false,
      undefined,
      this.idVerificationService.getActiveLegalrepresentativesFilters().type,
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
      this.idVerificationService.getActiveLegalrepresentativesFilters().status,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
      this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
    );
  }

  showReportActions(event) {
    $('.actionsMenuReport ul').removeClass('d-none');

  }

  onNewInvestor(event) {
    // check to see if there are any templates for the organization
    // if there are offer the option for automatic template
    // if not continue to normal flow
    const organizationTemplates = this.idVerificationService.getfundsOrganizationTemplates();
    //  the option for autmatic templates will only show up if there are currently published templates (at least one)
    let publishedOrganizationTemplates = false;
    if (organizationTemplates) {
        organizationTemplates.forEach(template => {
        if (template.record.published) {
          publishedOrganizationTemplates = true;
        }});
      }
    if (publishedOrganizationTemplates) {
      $('.actionsMenuProfileDash ul').removeClass('d-none');
    } else {
      this.idVerificationService.skipToStep(8, {});
    }
  }
  onNewInvestorManual(event) {
    this.idVerificationService.skipToStep(8, {});
  }
  onNewInvestorAutomatic(event) {
    this.idVerificationService.skipToStep(19, {});
  }

  // if the toggle 'hideCompletedProfiles' is active we are geting from BE all profiles which are not completed
  // if the toggle 'hideCompletedProfiles' is not active we are geting from BE all profiles
  showHideCompletedProfiles( activeFilter: string = 'none', dontShowCompletedProfiles: boolean)
  {
    this.idVerificationService.setHideCompletedProfiles(!dontShowCompletedProfiles);
    if(!dontShowCompletedProfiles)
    {
      this.filterListStatus('NOT_COMPLETED', activeFilter);
    }
    else
    {
      this.filterListStatus('ALL_STATUSES', activeFilter);
    }
  }
  
  filterListStatus(listType: string, activeFilter: string = 'none') {
    if (listType === activeFilter || listType === 'ALL_STATUSES') {
      listType = 'none';
    }
    if (listType !== 'none') {
      this.idVerificationService.getActiveLegalrepresentativesFilters().status = listType;
    } else {
      this.idVerificationService.getActiveLegalrepresentativesFilters().status = undefined;
    }
    this.idVerificationService.filterType='filter_by_status';
    this.idVerificationService.setActiveInvestor('');
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    this.idVerificationService.retreiveActiveInvestors (
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor(),
      'funds',
      false,
      false,
      false,
      undefined,
      this.idVerificationService.getActiveLegalrepresentativesFilters().type,
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
      this.idVerificationService.getActiveLegalrepresentativesFilters().status,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
      this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
    );
  }

  viewProjectProfiles()
  {
    const currentUrl = this.router.url;
    const routeSegment = currentUrl.split('/').slice(0, 4).join('/'); // Extract the first 3 segments
    this.router.navigateByUrl('/verifications', { skipLocationChange: true }).then(() => {
      this.router.navigate([routeSegment]); // navigate to same route
    });
  }

  filterPersonList(listType: string, activeFilter: string = 'none', activeTags) {
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    // reset the bookmark since we are changing persons
    this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);
    // reset the list since we are changing persons
    this.idVerificationService.resetActiveLegalRepresentativesList();
    // set the filterStatus
    this.idVerificationService.getActiveLegalrepresentativesFilters().activeFilter = activeFilter;
    // set (reset) last lastLoad to false
    this.lastLoad = false;
    if (listType === activeFilter || listType === 'All') {
      listType = 'none';
    }
    if (listType === 'all_statuses' && activeFilter === 'all_statuses') {

    } else {
      this.contentReturn.emit({
        'method' : 'filterPersonList',
        'params' : {'filter' : listType}
      });
    }
  }

  filterPersonByTag(tag: string, activeTags: string[]) {
    let size = 0;
    if (!activeTags) {
      activeTags = [];
    }
    if (activeTags.includes(tag)) {
      activeTags = activeTags.filter(tagE => tagE !== tag);
    } else {
      activeTags.push(tag);
    }
    if (activeTags.length === 0) {
      size = 15;
    }
    // reset the bookmark since we are changing persons
    this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);
    // reset the list since we are changing persons
    this.idVerificationService.resetActiveLegalRepresentativesList();
    // add in the filter to the state
    this.lastLoad = false;
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
     this.idVerificationService.retrieveActiveLegalRepresentatives(
      this.idVerificationService.getActiveProject(),
      false,
      false,
      undefined,
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
      'desc',
      activeTags,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeFilter,
      size
    );
  }

  isActiveRRTag(tag:string):boolean
  {
    let activeTags : string [] = this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags
    if(activeTags)
    {
      return activeTags.includes(tag);
    }
    return false;
  }

  filterPersonByRiskRating(tag: string, clearFilter: boolean= false) {
    let activeTags = [];
    if(!clearFilter)
    {
      activeTags = this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags
      if (!activeTags) {
        activeTags = [];
      }
      if (activeTags.includes(tag)) {
        activeTags = activeTags.filter(tagE => tagE !== tag);
      } else {
        activeTags.push(tag);
      }
    }
   
    // reset the bookmark since we are changing persons
    this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);

    // add in the filter to the state
    this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags = activeTags;

    this.lastLoad = false;
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    this.idVerificationService.retreiveActiveInvestors (
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor(),
      'funds',
      false,
      false,
      false,
      undefined,
      this.idVerificationService.getActiveLegalrepresentativesFilters().type,
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
      this.idVerificationService.getActiveLegalrepresentativesFilters().status,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
      this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
    );
  }

  loadMorePeople(event) {
      const elem =  $('.group-beyond-wrapper');
      if (this.loadingLocked === false && this.lastLoad === false) {
      this.loadingLocked = true;
      $('.group-beyond-wrapper').addClass('loading');
      $('.filter-element').addClass('verification-main-disabled');
      const scrollTop = elem[0].scrollHeight - 120;
      const filterss =  this.idVerificationService.getActiveLegalrepresentativesFilters();
      filterss.scrollTop = scrollTop;
      filterss.scrolTopIgnore = true;
      this.idVerificationService.setActiveLegalrepresentativesFilters(filterss);
      this.idVerificationService.retrieveActiveLegalRepresentatives (
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
        false,
        this.idVerificationService.getActiveLegalRepresentativesBookmark(),
        this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
        'desc',
        undefined,
        this.idVerificationService.getActiveLegalrepresentativesFilters().activeFilter,
      );
    }
  }

  loadMoreInvestors(event) {
    this.loadingLocked = false; // why
    const elem =  $('.group-beyond-wrapper');
    console.log('loadingLocked : ' +  this.loadingLocked);
    console.log('lastLoad : ' +  this.lastLoad);
    if (this.loadingLocked === false && this.lastLoad === false) {
    this.loadingLocked = true;
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    const scrollTop = elem[0].scrollHeight - 120;
    const filterss =  this.idVerificationService.getActiveLegalrepresentativesFilters();
    filterss.scrollTop = scrollTop;
    filterss.scrolTopIgnore = true;
    this.idVerificationService.setActiveLegalrepresentativesFilters(filterss);
      this.idVerificationService.retreiveActiveInvestors (
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        'funds',
        false,
        false,
        false,
        this.idVerificationService.getActiveLegalRepresentativesBookmark(),
        this.idVerificationService.getActiveLegalrepresentativesFilters().type,
        this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
        this.idVerificationService.getActiveLegalrepresentativesFilters().status,
        this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
        this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
      );
    }
  }


  // created by or modified by filter
  OnChangePersonFilter(event, activeTags?) {
    $('.group-beyond-wrapper').addClass('loading');
    $('.filter-element').addClass('verification-main-disabled');
    const selectedFilter = $(event.target).children('option:selected').val();
    // reset the bookmark since we are changing persons
    this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);
    // reset the list since we are changing persons
    this.idVerificationService.resetActiveLegalRepresentativesList();
    // add in the filter to the state
    this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy = selectedFilter;
    // set (reset) last lastLoad to false
    this.lastLoad = false;

    this.idVerificationService.retrieveActiveLegalRepresentatives(
      this.idVerificationService.getActiveProject(),
      false,
      false,
      undefined,
      selectedFilter,
      'desc',
      activeTags,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeFilter,
      15
      );
    }

    OnChangeInvestorFilter(event) {
      this.idVerificationService.setActiveInvestor('');
      $('.group-beyond-wrapper').addClass('loading');
      $('.filter-element').addClass('verification-main-disabled');
      const selectedFilter = $(event.target).children('option:selected').val();
      this.idVerificationService.setActiveLegalRepresentativesBookmark(undefined);
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy = selectedFilter;
      this.idVerificationService.retreiveActiveInvestors (
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        'funds',
        false,
        false,
        false,
        undefined,
        this.idVerificationService.getActiveLegalrepresentativesFilters().type,
        this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
        this.idVerificationService.getActiveLegalrepresentativesFilters().status,
        this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
        this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
      );
    }

    onGenerateReportUsage() {
      if (this.idVerificationService.getCCType() === 'verification') {
        this.idVerificationService.setDisplayGenerateReportLightbox();
      } else {
        this.idVerificationService.setDisplayGenerateReportLightboxFunds();
      }
    }

    onGenerateReportStatus(event) {
      if (this.idVerificationService.getCCType() === 'verification') {
        this.idVerificationService.generateReportStatus(event, 'verification');
      } else {
        this.idVerificationService.generateReportStatus(event, 'funds');

      }
    }

    OnToggleNextReview() {
      if ($('#filterByNextReview').is(':checked')) {
          this.idVerificationService.toggleDisplayRangeFilter(true);
      } else {
        // reset the date filter
        this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview =  {
          next_review_after : null,
          next_review_before : null
        }
        this.RRDateFilter = '0';
        
        // reset the bookmark since we are changing persons
        this.idVerificationService.toggleDisplayRangeFilter(false);
        this.idVerificationService.setActiveInvestor('');
        $('.group-beyond-wrapper').addClass('loading');
        $('.filter-element').addClass('verification-main-disabled');
        this.idVerificationService.retreiveActiveInvestors (
          this.idVerificationService.getActiveProject(),
          this.idVerificationService.getActiveInvestor(),
          'funds',
          false,
          false,
          false,
          undefined,
          this.idVerificationService.getActiveLegalrepresentativesFilters().type,
          this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
          this.idVerificationService.getActiveLegalrepresentativesFilters().status,
          this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags
        );
      }
    }
    // get the value of the date filters
    getRRDate(filter:string)
    {
      let RRDateFilter =  this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
      if(RRDateFilter)
      {
        if(filter === 'from' && RRDateFilter.next_review_after)
        {
          return this.helper.getAsStartDate(this.helper.formatISODate(RRDateFilter.next_review_after,'yyy.MM.dd', false));
        }
        else if(filter === 'to' && RRDateFilter.next_review_before)
        {
          return this.helper.getAsStartDate(this.helper.formatISODate(RRDateFilter.next_review_before, 'yyy.MM.dd', false));
        }
      }
      return null;
    }

    applyNewRange() {

      let fromDate ;
      let toDate ;
      if(this.RRDateFilter=='0')
      {
        fromDate = null;
        toDate = null;
      }
      else if(this.RRDateFilter == "custom")
      {
        // fromDate= 
        fromDate =  $('#rangeFrom input').val()
        if(fromDate)
        {
          fromDate =  this.helper.convertToDate(fromDate.toString());
        }

        toDate =  $('#rangeTo input').val()
        if(toDate)
        {
          toDate =  this.helper.convertToDate(toDate.toString());
        }
      }
      else if(parseInt(this.RRDateFilter))
      {
        fromDate = new Date();

        toDate = new Date();
        toDate.setMonth(toDate.getMonth() + parseInt(this.RRDateFilter));
      }
      if (toDate && fromDate && (toDate < fromDate)) {
        this.toastNotification.newEvent({
          mode: 'error',
          title: 'Invalid date range selected! Please check and try again',
          fade: true
        });
        return;
      }

      this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview =  {
                                  next_review_after: fromDate ? fromDate.getTime() : null,
                                  next_review_before: toDate ? toDate.getTime() : null
                                }
      

      // reset the bookmark since we are changing persons
      this.idVerificationService.setActiveInvestor('');
      $('.group-beyond-wrapper').addClass('loading');
      $('.filter-element').addClass('verification-main-disabled');

      this.idVerificationService.retreiveActiveInvestors (
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        'funds',
        false,
        false,
        false,
        undefined,
        this.idVerificationService.getActiveLegalrepresentativesFilters().type,
        this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
        this.idVerificationService.getActiveLegalrepresentativesFilters().status,
        this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
        this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview
      );
    }

}
