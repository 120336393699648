<app-header
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  (selectedCompany)='OnSelectedCompanyChange($event)'
  (searchTerm)="onSearchTermChanged($event)"></app-header>
<app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formSubTitle]='formSubTitle'
  [contentDOM]="lightboxContent"
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox>
<app-footer></app-footer>
