<div class=" px-4 py-4 dv-review-step-one d-flex flex-column d-none mb-0 position-relative form-container-side">
    <div>
       <h4 *ngIf="!isContract" class="verify-heading my-3" [innerHTML]=
      "('review.digitalVerifications.title' | translate : {'entity' : (entityName ? entityName : 'entity')})"></h4>
      <p class="pr-2" *ngIf="!isContract && typeOfInvestor === 'LegalPerson'"
      [innerHTML] = "('review.digitalVerifications.content6' | translate : {'entity' : (entityName ? entityName : 'entity')})" >
      </p>

      <div class="mb-0 " *ngIf="isContract">
        <div class="d-flex justify-content-center"><img height="100" src="/assets/images/illustration-contract.svg" class="result-image request-share-img"></div>
        <h4 class="verify-heading my-3" [innerHTML]=
        "('review.digitalVerifications.signOnBehalfTitle' | translate : {'entity' : (entityName ? entityName : 'entity')})"></h4>
        <p class="pr-2" *ngIf="typeOfInvestor === 'LegalPerson'"
        [innerHTML] = "('review.digitalVerifications.content6' | translate : {'entity' : (entityName ? entityName : 'entity')})" >
        </p>
        <p class="mt-3">{{"review.digitalVerifications.signOnBehalfDescription" | translate}} </p>

        <!-- Block for adding collaborators -->
        <div class="form-clarification custom-collapse my-4 p-3" *ngIf="!isSigned">
          <div  href="#addCollaborators" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="multiCollapseExample1" class="d-flex collapse-header">
            <h2  class="form-clarification-title mb-0">
             {{"review.digitalVerifications.addCollaboratorsTitle"| translate}}
            </h2>
            <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown">
          </div>
          <div class="collapse clarification-content" id="addCollaborators">
            <div class="document-field-wrapper">
              <p class="mt-3">{{"review.digitalVerifications.addCollaboratorsDescription" | translate}} </p>

              <div class="evidence-fields w-100">
                <div class="element-wrapper mt-1">
                <span class="input-label font-weight-bold">{{"review.digitalVerifications.emailAddressCollaborator" | translate}}<sup class="sup-required">*</sup></span>
                  <!-- Input Text -->
                  <input
                      type="text"
                      value=''
                      class="text-input"
                      id="ContractEmail"
                      name="ContractEmail"
                      [attr.isValid] = 'true'
                      (keydown.enter)="$event.preventDefault()"
                      (keyup.enter)="initiateContract($event)"
                      (input)="newCollaborator()"
                      [attr.isRequired] = 'false'>
                      <div class="error-field contract-error-field d-none mt-0 bg-transparent"> {{'("validation.validation5" | translate)'}} </div>

                </div>
              </div>

              <button
              [attr.clickmethod]="''"
              [attr.data-evidenceKey] = " ( isIdentityGoRP || isContract ) ?  element.params.digitalEvidenceTypes[0]?.originalEvidenceKey :  element.params.evidenceKey"
              [attr.data-dvType] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
              [attr.data-verificationType]="isIdentityGo ? 'IdentityInstantAI' : isContract ? 'Contract' : 'Identity'"
              [attr.data-originalKey] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
              [attr.data-requestKey] = "element.params.requestKey"
              [attr.data-confirmPerson] = "true"
              (click)="initiateContract()"
              id="contractSubmitButton"
              class="form-button form-button-1 contract-button contract-button-contr "
              >
                <span>{{"review.digitalVerifications.inviteCollaboratorBtn" | translate}}</span>
                <img width="25" src="/assets/images/spinner-gray.svg">
              </button> <span class="invite-sent">Invite sent!</span>
              <small class="grey-font">{{"review.digitalVerifications.noteOneActiveCollaboratorAtGivenMoment" | translate}}</small>
            </div>
          </div>
        </div>
        <!--  End of block for adding collaborators -->
      </div>

      <div class="mb-3 contract-container" *ngIf="isContract">
         <!-- Block for adding co-signers -->
         <div class="form-clarification custom-collapse my-4 p-3">
          <div  href="#addCoSigners" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="multiCollapseExample1" class="d-flex collapse-header">
            <h2  class="form-clarification-title mb-0"
            [innerHTML]= "('review.digitalVerifications.addCoSignerTitle' | translate : {'entity' : (entityName ? entityName : 'entity')})">
            </h2>
            <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown">
          </div>
          <div class="collapse clarification-content" id="addCoSigners">
            <div class="document-field-wrapper">
              <p class="mt-3">{{"review.digitalVerifications.addCoSignerDescription" | translate}} </p>

              <div class="evidence-fields w-100">
                <div class="element-wrapper mt-1">
                <span class="input-label font-weight-bold">{{"review.digitalVerifications.addCoSignerEmail" | translate}}<sup class="sup-required">*</sup></span>
                  <!-- Input Text -->
                  <input
                      type="text"
                      (keydown.enter)="$event.preventDefault()"
                      (keyup.enter)="addSigner($event)"
                      (input)="deleteValidation()"
                      value=''
                      class="text-input"
                      id="ContractEmailSigner"
                      name="ContractEmailSigner"
                      [attr.isValid] = 'true'
                      [attr.isRequired] = 'false'>
                      <div class="error-field contract-signer-error-field d-none mt-0"> </div>

                </div>
              </div>
              <button
              [attr.clickmethod]="''"
              [attr.data-evidenceKey] = " ( isIdentityGoRP || isContract ) ?  element.params.digitalEvidenceTypes[0]?.originalEvidenceKey :  element.params.evidenceKey"
              [attr.data-dvType] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
              [attr.data-verificationType]="isIdentityGo ? 'IdentityInstantAI' : isContract ? 'Contract' : 'Identity'"
              [attr.data-originalKey] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
              [attr.data-requestKey] = "element.params.requestKey"
              [attr.data-confirmPerson] = "true"
              (click)="submitCosigner()"
              id="contractSubmitButtonSigner"
              class="form-button form-button-1 contract-button "
              >
              <span>{{"review.digitalVerifications.addCoSignerBtn" | translate}}</span>
              <img width="25" src="/assets/images/spinner-gray.svg">
              </button>
            </div>
          </div>
        </div>
        <!--  End of block for adding co-signers -->
      </div>


      <div *ngIf="cosigners && cosigners.length>0 && isContract" class="custom-table" >
        <p class="mb-0" style="font-weight: 700; color : #1a93a5 ">{{"review.digitalVerifications.currentCoSigners" | translate}}</p>
        <table>
          <thead>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tr *ngFor="let email of cosigners; let i=index">
            <td>{{email.email}}</td>
            <td>
              <a *ngIf="!email.isSigned"href="#" [id]="'delete-co-signer' + i"class="delete-rp-icon d-flex "
              (click)="removeCosigner($event, email.email, i)">
                <img class="icon-normal" src="assets/images/delete-document.svg" width="16px"/>
                <img class="icon-red" src="assets/images/delete-document-red.svg" width="16px"/>
                <img class="spinner" src="/assets/images/spinner-green.svg" width="16px">
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div class="d-flex justify-content-end mt-5">
        <button
        (click)="redirectInvestorToDocument()"
        id="continueToDocument"
        class="form-button form-button-1 "
        *ngIf="!isSigned"
        >
        <span>{{"review.digitalVerifications.continueToTheDocumentBtn" | translate}}</span>
        <img width="25" src="/assets/images/spinner-gray.svg">
        </button>
      </div>

      <p class="pr-2" *ngIf="typeOfInvestor !== 'LegalPerson' && !isContract"
      [innerHTML] = "('review.digitalVerifications.content' | translate : {'entity' : (entityName ? entityName : 'entity')})" >
      </p>
      <div *ngIf="!isContract">
        <div class="digital-evidence-label"
        [ngClass] = "{'d-none' : digitalEvidence.verificationKey}"
        *ngFor="let digitalEvidence of digitalEvidenceTypes">
            {{this.helperService.translateVerificationType(digitalEvidence.verificationType,
              digitalEvidence.verificationType === 'Contract' ? 'ContractAlternate' : undefined)}}
        </div>
      </div>

      <p  *ngIf="typeOfInvestor!=='LegalPerson' && !isContract" class="mt-4">
        {{"review.digitalVerifications.content1" | translate}}
      </p>
      <p *ngIf="typeOfInvestor==='LegalPerson'"
      [innerHTML] = "'review.digitalVerifications.content2' | translate : {'entityName' : (entityName ? entityName : 'entity')}" class="mt-4">
      </p>
      <button
      *ngIf="!isContract"
      [attr.clickmethod]="'htpmSubmitEvidenceFields'"
      [attr.data-evidenceKey] = " ( isIdentityGoRP || isContract ) ?  element.params.digitalEvidenceTypes[0]?.originalEvidenceKey :  element.params.evidenceKey"
      [attr.data-dvType] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
      [attr.data-verificationType]="isIdentityGo ? 'IdentityInstantAI' : isContract ? 'Contract' : 'Identity'"
      [attr.data-originalKey] = "element.params.digitalEvidenceTypes[0]?.originalEvidenceKey"
      [attr.data-requestKey] = "element.params.requestKey"
      [attr.data-confirmPerson] = "true"
      (click)="true ? onEmit(elementService.OnButtonClick($event,element.params.evidenceTypes)) : initiateContract()"
      id="dvGenerateLink"
      type="submit"
      class="form-button form-button-1 my-3"
      >
      {{"common.continue" | translate}}
      </button>
    </div>
    <div class="dv-review-step-loading d-none flex-column">
      <!-- <img src="/assets/images/spinner-green.svg" width="65px" height="65px" class="mb-4 mx-auto"> -->
      <p class="mb-1"> {{"review.digitalVerifications.content4" | translate}}</p>
      <p> <strong> {{"review.digitalVerifications.content5" | translate}}</strong></p>
      <div class="digital-evidence-labels-container">
        <div class="digital-evidence-label digital-evidence-label-sharing"
        [attr.evidenceKey] = 'digitalEvidence.key'
        [ngClass] = "{'d-none' : digitalEvidence.verificationKey}"
        *ngFor="let digitalEvidence of digitalEvidenceTypes">
          <div>
          {{ this.helperService.translateVerificationType
            (digitalEvidence.verificationType)
          }}</div>
          <img class="evidence-label-check d-none" src="assets/images/check.svg">
          <img class="evidence-label-spinner d-none" src="assets/images/spinner-green.svg">
        </div>
     </div>
    </div>
  </div>
