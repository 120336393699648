import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-kompany-seach',
  templateUrl: './kompany-seach.component.html',
  styleUrls: ['./kompany-seach.component.scss']
})
export class KompanySeachComponent implements OnInit {

  @Input() element;
  @Input() currentlySelectedCountry;
  @Output() contentReturn = new EventEmitter<any>();
  isoCountryList;

  constructor(
    public elementService: ElementsService,
    private idVerificationService: IdVerificationService,
    private userService: UserService,
    public translate: TranslateService
  ) { }

  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  ngOnInit(): void {
    this.isoCountryList = this.idVerificationService.getCountryListAVClean();
    if (this.currentlySelectedCountry) {
      this.idVerificationService.localFundCreationData.selectedCountryValue = this.currentlySelectedCountry;
      this.element.params.selectedCountryValue = this.currentlySelectedCountry;
    }
  }

  selectCountryKompany(event) {
    const selectedValue = $('#CountryOfRegistration option:selected').val();
    if (selectedValue !== 'emptySelect') {
      if (!this.idVerificationService.localFundCreationData.investorFields) {
        this.idVerificationService.localFundCreationData.investorFields = {};
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = this.getBECountryType(selectedValue);
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry = this.getBECountryType(selectedValue);
      } else {
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = this.getBECountryType(selectedValue);
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry = this.getBECountryType(selectedValue);
      }
      $('.kompany-search-input').removeClass('search-blurred');
    } else {
      $('.kompany-search-input').addClass('search-blurred');
    }
  }

  unParseCountryName(name: string) {
    name = name.replace(/ /g, '-');
    return name;
  }


  unParseCountryNameII(name: string) {
    name = name.replace(/ /g, '_');
    return name;
  }

  // get the BE country type from the type of kompany country selected
  getBECountryType(kompanyCountry) {
    if (kompanyCountry.includes('US-')) {
      return 'United_States_of_America';
    } else if (kompanyCountry.includes('RU')) {
      return 'Russian_Federation';
    }  else {
      return this.unParseCountryNameII(this.isoCountryList.
      find(country => country.value === kompanyCountry).label);
    }
  }


  kompanySearch() {
    const isAdvancedSearch = this.idVerificationService.localFundCreationData.advancedSearch;
    if (isAdvancedSearch) {
      if ( $('#kompanyInputAddress').val().toString().trim() === ''
      && $('#kompanyInputPhone').val().toString().trim() === ''
      && $('#kompanyInputVat').val().toString().trim() === '' ) {
      $('.kompany-search-error-field').addClass('d-flex');
      return;
      }
    } else {
      if ( $('#kompanyInput').val().toString().trim() === '') {
        $('.kompany-search-error-field').addClass('d-flex');
        return;
      }
    }
    const selectedCountry = $('#CountryOfRegistration option:selected').text().trim();
    const selectedCountryValue = $('#CountryOfRegistration option:selected').val();
    const selectedCountryCode = selectedCountryValue;
    let payload;
    payload = {};
    payload.country = selectedCountryCode;
    this.idVerificationService.localFundCreationData.selectedCountryValue = selectedCountryValue;
    if ($('#kompanySearchName').is(':checked') && !isAdvancedSearch) {
      if ( $('#kompanyInput').val() !== '') {
        payload.name = $('#kompanyInput').val();
      }
    } else if ($('#kompanySearchID').is(':checked')  && !isAdvancedSearch) {
      if ( $('#kompanyInput').val() !== '') {
        payload.number = $('#kompanyInput').val();
      }
    } else {
      if ($('#kompanyInput').val() !== ''  && !isAdvancedSearch) {
        payload.lei = $('#kompanyInput').val();
      }
    }
    if ($('#kompanyInputAddress').val().toString().trim() !== ''  && isAdvancedSearch) {
      payload.address = $('#kompanyInputAddress').val();
    }
    if ($('#kompanyInputPhone').val().toString().trim() !== '' && isAdvancedSearch) {
      payload.phone = $('#kompanyInputPhone').val();
    }
    if ($('#kompanyInputVat').val().toString().trim() !== '' && isAdvancedSearch) {
      payload.vat = $('#kompanyInputVat').val();
    }
    $('.kompany-loading').removeClass('d-none');
    $('.kompany-search-input').addClass('loading');
    this.element.params.loadingMode = true;

    if (payload.lei) {
      this.getKompanyDetails(undefined, payload);
    } else {
      this.kompanySearchCall(payload);
    }
  }

  kompanySearchCall(payload){
    this.userService.postEndPoint(this.idVerificationService.buildEndPoint('kompanySearch'), payload, {responseType: 'json'},
    {observe: 'body'}, 'funds').subscribe(response => {
    if (typeof response === undefined) {
      console.log('There was an unexpected error while posting a new project');
      return;
    }
    this.element.params.processStart = false;
    this.idVerificationService.localFundCreationData.fetchedCompanyList = response;
    if (this.idVerificationService.localFundCreationData.fetchedCompanyList.length === 0) {
      this.element.params.kompanyCallError = true;
        this.element.params.errorMessage = this.translate.instant('kompanySearch.error');
    } else {
      this.element.params.fetchedCompanyList = this.idVerificationService.localFundCreationData.fetchedCompanyList;
      if (!this.element.params.reviewProcess) {
        this.element.class = {};
      }
    }
    this.element.params.loadingMode = false;
    },
    error => {
     // there was an error with the kompany search
     // redirecting to manual search
     this.element.params.processStart = false;
     this.idVerificationService.localFundCreationData.kompanyCallError = true;
     this.element.params.kompanyCallError = true;
     this.element.params.errorMessage = this.translate.instant('kompanySearch.error2');
     this.element.params.loadingMode = false;
    });
  }

  cancelBasicFieldsProcess() {
    this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration = undefined;
    this.idVerificationService.localFundCreationData.fetchedCompanyList = undefined;
    this.idVerificationService.localFundCreationData.kompanyCallError = false;
    this.element.params.processStart = true;
    this.element.params.kompanyCallError = false;
    this.element.params.selectedCountryValue = 'emptySelect';
    this.idVerificationService.basicFieldsCompanySearch.next('cancel');
  }

  searchKompanyAgain(event) {
    this.idVerificationService.localFundCreationData.fetchedCompanyList = undefined;
    this.idVerificationService.localFundCreationData.kompanyCallError = false;
    this.element.params.fetchedCompanyList = undefined;
    this.element.params.processStart = true;
    this.element.params.kompanyCallError = false;
    this.element.params.dontBlur = true;
    }

  selectKompanyFromList(event) {
    $('.kompany-confirmation-dialogue').removeClass('d-none');
    const selectedCompanyName = $(event.target).find('.kompany-list-member-name').text();
    const selectedCompanyID = $(event.target).attr('idNumber');
    $(event.target).addClass('selected');
    $('.kompany-confirmation-dialogue-name').text(selectedCompanyName);
    $('.kompany-confirmation-dialogue-name').attr('idNumber', selectedCompanyID);
    $('.search-kompany-again').addClass('d-none');
  }

  selectKompanyFromListConfirm(event) {
    this.element.params.loadingMode = true;
    const selectedCompanyId = $('.kompany-confirmation-dialogue-name').attr('idNumber');
    this.getKompanyDetails(selectedCompanyId);
  }

  selectKompanyFromListCancel() {
    $('.kompany-confirmation-dialogue').addClass('d-none');
    $('.search-kompany-again').removeClass('d-none');
    $('.kompany-list-member.selected').removeClass('selected');
  }

  showOptionalParameters() {
    $('.show-optional-parameters-button').addClass('d-none');
    $('.hide-optional-parameters-button').removeClass('d-none')
    $('.optional-parameters').removeClass('d-none');
    $('.company-input-search-outer').removeClass('d-none');
    $('.company-input-search-inner').addClass('d-none');
    $('.kompany-main-input-wrapper').removeClass('kompany-main-input-wrapper');
    $('.kompany-search-error-field').removeClass('d-flex');
    $('.basic-search-container').addClass('search-blurred');
    this.idVerificationService.localFundCreationData.advancedSearch = true;
  }

  hideOptionalParameters() {
    $('.show-optional-parameters-button').removeClass('d-none');
    $('.hide-optional-parameters-button').addClass('d-none');
    $('.company-input-search-outer').addClass('d-none');
    $('.company-input-search-inner').removeClass('d-none');
    $('.optional-parameters').addClass('d-none');
    $('.kompany-search-error-field').removeClass('d-flex');
    $('.basic-search-container').removeClass('search-blurred');
    this.idVerificationService.localFundCreationData.advancedSearch = false;
  }

  getKompanyDetails(companyID, leiSearch?) {
    let endpoint = 'kompanyDetails';
    let callVariable = this.userService.getEndPoint
    (this.idVerificationService.buildEndPoint('kompanyDetails', companyID), {responseType: 'json'},
    {observe: 'body'}, 'funds');
    if (leiSearch) {
      endpoint = 'kompanySearch';
      callVariable = this.userService.postEndPoint
      (this.idVerificationService.buildEndPoint('kompanySearch'), leiSearch, {responseType: 'json'},
      {observe: 'body'}, 'funds');
    }
    callVariable.subscribe(response => {
    if (typeof response === undefined) {
      console.log('There was an unexpected error while posting a new project');
      return;
    }
    if (leiSearch) {
      response = response[0];
    }
    this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils = response;
    this.mapKompanyDetailsToFields(response, this.idVerificationService.localFundCreationData,this.element.params.reviewProcess);
    this.idVerificationService.localFundCreationData.linkToStep = 'manual';
    this.idVerificationService.localFundCreationData.unhideFields = true;
    this.element.params.loadingMode = false;
    if (this.element.params.reviewProcess) {
      const evidenceKey =  this.element.params.evidenceKey;
      const evidenceTypes = this.element.params.evidenceTypes;
      const evidenceType = this.idVerificationService.returnEvidenceFieldByKey(evidenceKey, evidenceTypes);
      evidenceType.fields = {};
      // evidenceType = undefined;
      const flowType = $('.document-category-main.position-relative.category-active').attr('data-flowtype');
      const currentlyActiveEvidence = $('.documentUploaderSide.evidence-is-active').attr('id');
      const currentlyActiveRequest =  $('.documentUploaderSide.evidence-is-active').attr('data-requestkey');
      const nextActiveRequest =  $('.document-category-main[data-EvidenceKey="' + evidenceKey + '"]').attr('data-requestkey');
      this.idVerificationService.saveFieldsState(currentlyActiveEvidence, evidenceTypes, undefined, undefined, undefined, true);
      this.idVerificationService.setExpandedEvidence(evidenceKey);
      // this happens only when we're in the review flow and we use the search function
      // fieldsource will be available to the review / update flow at all other times
      // make sure the data from the fieldsSource is properly added to the investor data / evidence type data
      if (true) {
        this.idVerificationService.getActiveInvestorDetaisl().fieldsSource = [];
        this.idVerificationService.getActiveInvestorDetaisl()
            .fieldsSource.push(this.idVerificationService.localFundCreationData.fieldsSource);
            this.idVerificationService.getActiveInvestorDetaisl().investorFields =
            this.idVerificationService.localFundCreationData.investorFields;
            evidenceType.fields =  this.idVerificationService.localFundCreationData.investorFields;
      }
      this.idVerificationService.loadEvidenceFieldAndImages(
        this.idVerificationService.getActiveProject(),
        nextActiveRequest,
        evidenceKey,
        flowType,
        evidenceTypes,
        false,
        evidenceType?.RProle ? false : true // only true if it is not an RP
      );
    } else if (this.element.params.basicFieldsProcess) {
      this.idVerificationService.basicFieldsCompanySearch.next('finish');
    } else {
      if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
        this.idVerificationService.skipToStep(12.3);
        } else {
        let additionalData : any = {};
        additionalData.type = 'LegalPerson';
        if ($('#automaticRequestTemplate').val() !== '') {
          additionalData.selectedTemplate = $('#automaticRequestTemplate').val();
          additionalData.EmailAddress = $('#automaticRequestEmail').val();
          additionalData.allowInvestorEdit = $('#allowInvestorProfileDetails').prop('checked');
        }
        this.idVerificationService.skipToStep(5.3, additionalData);
      }
    }
    },
    error => {
     // there was an error with the kompany search
     // redirecting to manual search
     this.element.params.loadingMode = false;
     this.idVerificationService.localFundCreationData.kompanyCallError = true;
     this.element.params.kompanyCallError = true;
     this.element.params.processStart = false;
     if (error.error === 'No results found') {
      this.element.params.errorMessage = this.translate.instant('kompanySearch.error');;
     }
     if (this.element.params.basicFieldsProcess) {
      // console.log('todo');
    } else {
      // if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
      //   this.idVerificationService.skipToStep(12.1);
      //   } else {
      //   this.idVerificationService.skipToStep(5.2);
      //  }
    }
    });
  }

   /**
    @param data data to parse
    @param returnObject object to attach the parsed data to
    @param isReviewProcess if it's the element in the review process we also need to change the HTML
   */
  mapKompanyDetailsToFields(data, returnObject, isReviewProcess) {
    if (!returnObject.investorFields) {
      returnObject.investorFields = {};
    }
    // create the fieldSource object
    let fieldsSource;
    fieldsSource = {};
    fieldsSource.name = 'CompanySearch';
    // add in the country
    fieldsSource.fieldNames = ['CountryOfRegistration', 'RegisteredAddressCountry'];
    if (data.requestTime) {
      fieldsSource.requestTime = data.requestTime;
    }
    if (data.lastUpdate !== undefined || data.lastUpdate !== 0) {
      fieldsSource.lastUpdate =  data.lastUpdate;
    } else {
      fieldsSource.lastUpdate = data.requestTime;

    }
    if(isReviewProcess && this.idVerificationService.localFundCreationData.investorFields) {
      $('#LegalPersonCountryOfRegistration').
      val(this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration);
      $('#LegalPersonRegisteredAddressCountry').
      val(this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration);
      returnObject.investorFields.CountryOfRegistration = this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;
      returnObject.investorFields.RegisteredAddressCountry = this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;

    }
    fieldsSource.fieldsLocked = true;
    if (data.registrationNumber) {
      returnObject.investorFields.RegistrationNumber = data.registrationNumber;
      fieldsSource.fieldNames.push('RegistrationNumber');
      if (isReviewProcess) {
        $('#LegalPersonRegistrationNumber').val(data.registrationNumber);
      }
    }
    if (data.name) {
      returnObject.investorFields.RegisteredName = data.name;
      fieldsSource.fieldNames.push('RegisteredName');
      if (isReviewProcess) {
        $('#LegalPersonRegisteredName').val(data.name);
      }
    }
    if (data.status) {
      if (data.status === 'LIVE') {
        returnObject.investorFields.Status = 'ACTIVE';
      } else {
        returnObject.investorFields.Status = 'INACTIVE';
      }
      fieldsSource.fieldNames.push('Status');
    }
    if (data.formattedAddress) {
      this.idVerificationService.localFundCreationData.formattedAddress = true;
      if (data.formattedAddress.number) {
        returnObject.investorFields.RegisteredAddressNumber = data.formattedAddress.number;
        fieldsSource.fieldNames.push('RegisteredAddressNumber');
        if (isReviewProcess) {
          $('#LegalPersonRegisteredAddressNumber').val(data.formattedAddress.number);
        }
      }
      if (data.formattedAddress.street) {
        returnObject.investorFields.RegisteredAddressStreet = data.formattedAddress.street;
        fieldsSource.fieldNames.push('RegisteredAddressStreet');
        if (isReviewProcess) {
          $('#LegalPersonRegisteredAddressStreet').val(data.formattedAddress.street);
        }
      }
      if (data.formattedAddress.city) {
        returnObject.investorFields.RegisteredAddressCity = data.formattedAddress.city;
        fieldsSource.fieldNames.push('RegisteredAddressCity');
        if (isReviewProcess) {
          $('#LegalPersonRegisteredAddressCity').val(data.formattedAddress.city);
        }
      }
      if (data.formattedAddress.zip) {
        returnObject.investorFields.RegisteredAddressPostalCode = data.formattedAddress.zip;
        fieldsSource.fieldNames.push('RegisteredAddressPostalCode');
        if (isReviewProcess) {
          $('#LegalPersonRegisteredAddressPostalCode').val(data.formattedAddress.zip);
        }
      }
    }
    returnObject.fieldsSource = fieldsSource;
  }

}
