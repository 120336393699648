import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError, of, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, filter, mergeMap  } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { HelperServiceService } from './helper-service.service';
import { Router } from '@angular/router';
import { WindowRefService } from './window-ref.service';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl: string;
  private userEndpoints: {};
  private activeUser: {};
  private permissions;
  private organization;
  private organizationKey;
  private organizationId;
  private _userPermissions = new BehaviorSubject <any>(null);

  private activeUserEvent = new Subject<any>();
  private updateUserInfo = new Subject<any>();
  public _organisationUpdate = new BehaviorSubject <any>(null);
  public activeUserStatus = this.activeUserEvent.asObservable();
  public updateUserInfoStatus = this.updateUserInfo.asObservable();

  constructor(private http: HttpClient,
              public router: Router,
              private helper: HelperServiceService,
              private winRef: WindowRefService,
              private authService: AuthService
              ) {
    this.baseUrl = environment.APIEndpoint; // 'https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.4/';
    this.userEndpoints = {
      'userAuth': 'api/auth',  // check if the user is authenticated to make BE calls or use the app altogether
      'userInfo': 'api/user',  // Fetch user profile information of the logged in user. If no user are logged we will get 401
      'userPreferences': 'api/user/preferences', // Used to fetch or set logged in user preferences
    };
  }

  buildEndPoint(endpoint, key = '', query = '') {
    return this.baseUrl + this.userEndpoints[endpoint]
        .replace('{key}', key)
        + query;
  }

  /**
   * authenticate user
   * check if user is authorized to use the platform
   * and resolve user role/permissions
   */
  auth(withRedirect: boolean = true) {
    this.getEndPoint(this.buildEndPoint('userAuth')).subscribe(
      (activeUser => {
        // temporary workaround auth endpoint not working
        this.activeUser = activeUser;
        // this.activeUser = JSON.parse('{"username":"demo1@singletruth.io","orgname":"Integrity Check Organisation 01","permissions":["access:addressverification","access:admin","access:aps-verification","access:company-search","access:contract-verification","access:funds","access:idin-verification","access:kyc-template-management","access:reporting","access:risk-manager","access:screening","access:standalone-api","access:standalone-cdd-api","access:verification","write:branding","write:contract-authoring","write:org"],"organizationKey":"ORG:19118844-89cb-4233-a38e-61cd6867083a"}');
        this.permissions = this.activeUser['permissions'];
        this.organization = this.activeUser['orgname'];
        this.organizationKey = (this.activeUser['organizationKey']) ? this.activeUser['organizationKey'] : null;
        // this.organizationId = (this.activeUser['organizationKey']) ? this.activeUser['organizationKey'].replace('ORG:', '') : null;
        this.setUserOrganizationId((this.activeUser['organizationKey']) ? this.activeUser['organizationKey'].replace('ORG:', '') : null);
        this._userPermissions.next(this.activeUser['permissions']);
        this.validateUser();
        this.activeUserEvent.next(this.activeUser);
        if (withRedirect) {
          if (this.activeUser['permissions'].includes('access:integrity') &&
            (this.router.url.includes('/integrity-check/dashboard') || this.router.url === '/')) {
            this.router.navigate(['/integrity-check/dashboard']);
          } else if ((this.activeUser['permissions'].includes('access:verification') || (this.activeUser['permissions'].includes('access:addressverification')))
          && (this.router.url.includes('/id-verification/dashboard') || this.router.url === '/')) {
            if (this.router.url === '/') {
              this.router.navigate(['/id-verification/dashboard']);
            } else {
              this.router.navigate([this.router.url]);
            }
          } else if (this.activeUser['permissions'].includes('access:funds')
          && (this.router.url.includes('/funds/dashboard') ||  this.router.url === '/')) {
            if (this.router.url === '/') {
              this.router.navigate(['/funds/dashboard']);
            } else {
              this.router.navigate([this.router.url]);
            }
          } else if (this.activeUser['permissions'].includes('access:investor')
              && (this.router.url.includes('/investor/dashboard') || this.router.url === '/')) {
                if (this.router.url === '/') {
                  this.router.navigate(['/investor/dashboard']);
                } else {
                  this.router.navigate([this.router.url]);
                }
          } else if (!this.activeUser['permissions'].includes('access:integrity')
          && !this.activeUser['permissions'].includes('access:verification')
          && !this.activeUser['permissions'].includes('access:addressverification')
          && !this.activeUser['permissions'].includes('access:funds')
          && !this.activeUser['permissions'].includes('access:investor')
          ) {
            this.router.navigate(['/forbidden']);
          }
        }
      }),
      (error => {
        console.log(error);
        // this.authService.login();
      })
    );
  }

  /**
   * retrieves all user permissions
   */
  getUserPermissions() {
    return this.permissions;
  }

  getUserOrganization() {
    return this.organization;
  }

  getUserOrganizationKey() {
    return this.organizationKey;
  }

  getUserOrganizationId() {
    return this.organizationId;
  }

  getUserPermissionsSubject() {
    return this._userPermissions.asObservable();
  }

  setUserOrganizationId(orgId) {
    this.organizationId = orgId;
    this._organisationUpdate.next(this.organizationId);
  }

  /**
   * retrieves user permission
   * @param permission - the title of the permission that we are retrieving.
   * @return null if no permission is found, the stored value of the permission otherwise
   */
  getUserPermission(permission: string) {
    if (this.permissions && this.permissions.includes(permission)) {
      return true;
    }
    return false;
  }

  /**
   * clears active user and permissions
   * and redirects to BE handled logout
   */
  logout(): void {
    this.activeUser = undefined;
    this.permissions = undefined;
    this.winRef.nativeWindow.parent.location = '/logout';
  }

  /**
   * check if the current user is authenticated
   * @returns boolean, true if authenticated, false if not
   */
  isAuthenticated(): boolean {
    return this.activeUser !== undefined;
  }

  /**
   * checks if the user is valid and authenticated
   * moved out from the integrity service
   */
  validateUser() {
    if (this.permissions && this.permissions.includes('access:integrity')) {
      this.getEndPoint(this.buildEndPoint('userInfo')).subscribe(
        (activeUser => {
        if (typeof activeUser === undefined) {
          console.error('ERROR FETCHING ACTIVE USER FROM BACKEND');
          this.activeUser = 0;
          delete this.activeUser['userInfoUserName'];
          delete this.activeUser['userEmail'];
          delete this.activeUser['userCompanyName'];
          delete this.activeUser['preferences'];
          this.activeUserEvent.next(false);
          return;
        }
        // this.activeUser['userInfo'] = this.helper.sanitize(activeUser);
        const userInfo = this.helper.sanitize(activeUser);
        this.activeUser['userInfoUserName'] = userInfo.username;
        this.activeUser['userEmail'] = userInfo.email;
        this.activeUser['userCompanyName'] = userInfo.companyName;
        this.activeUser['preferences'] = userInfo.preferences;
        this.activeUserEvent.next(activeUser);
      }),
      error => {
        console.error(error);
        this.activeUser = 0;
        this.activeUserEvent.next(false);
      }
      );
    }
  }

  /**
   * retrieves user preference
   * @param preference - the title of the preference that we are retrieving.
   * @return null if no preference is found, the stored value of the preference otherwise
   */
  getUserPreference(preference) {
    if (!this.activeUser['preferences'] || this.activeUser['preferences'][preference] === undefined) {
      return null;
    }
    return this.activeUser['preferences'][preference];
  }

  /**
   * retrieves user info
     * @return null if no user is found, the stored user object otherwise
   */
  getUserInfo() {
    if (typeof this.activeUser === undefined) {
      return null;
    }
    return this.activeUser;
  }

  /**
   * set user preference
   * @param preferences: {} - the title of the preference that we are updating
   */
  updateUserPreferences(preferences) {
    if (!(preferences instanceof Object)) {
      console.log('CANNOT UPDATE USER PREFERENCES');
      return;
    }
    this.postEndPoint(this.buildEndPoint('userPreferences'), preferences).subscribe(status => {
      if (status === undefined) {
        console.log('ERROR UPDATING USER PREFERENCES');
        return;
      }
      // update is a success update the parameter for the local user object as well:
      console.log(status);
      this.updateLocalPreferences(preferences);
    },
    error => {
      console.log('The following error occured when updating user preference', error);
    });
  }

  /**
   * set user
   * @param params: {} - the formatted params of the user
   */
  updateUser(params) {
    if (!(params instanceof Object)) {
      console.log('CANNOT UPDATE USER INFO');
      this.updateUserInfo.next(null);
      return;
    }
    this.postEndPoint(this.buildEndPoint('userInfo'), params).subscribe(status => {
      if (status === undefined) {
        console.log('ERROR UPDATING USER PREFERENCES');
        return;
      }
      // update is a success update the parameter for the local user object as well:
      const userInfo = status;
      this.activeUser['userInfoUserName'] = userInfo['username'];
      this.activeUser['preferences'] = userInfo['preferences'];
      this.activeUser['userEmail'] = userInfo['email'];
      this.activeUser['userCompanyName'] = userInfo['companyName'];
      this.updateUserInfo.next('User information was successfully updated!');
    },
    error => {
      console.log('The following error occured when updating user preference', error);
      this.updateUserInfo.next(null);
    });
  }

  /**
   * updates the preferences stored in the local user object
   * but only if they were successfully updated in the BE
   * @param preferences: {} - the title of the preference that we are updating
   */
  updateLocalPreferences(preferences: {}) {
    if (!this.activeUser['preferences']) {
      this.activeUser['preferences'] = [];
    }
    for (const preference in preferences) {
      if (preferences[preference]) {
        this.activeUser['preferences'][preference] = this.helper.sanitize(preferences[preference], SecurityContext.HTML);
      }
    }
  }

  getEndPoint(target, responseType = {responseType: 'json'}, observe = {observe: 'body'}, additionalHeaders = '') {
    return this.authService.getTokenSilently$().pipe(
      mergeMap(token => {
        return this.http.get(target, {headers: {Authorization: `Bearer ${token}`, cc: additionalHeaders}
                                   , responseType: responseType.responseType as 'json'
                                   , observe: observe.observe as 'body'});
      }),
      catchError(err => throwError(err))
    );
  }

  postEndPoint<Object, Blob>(target, payload, responseType = {responseType: 'json'}, observe = {observe: 'body'}, additionalHeaders = '') {
    return this.authService.getTokenSilently$().pipe(
      mergeMap(token => {
        return this.http.post(target, payload, {headers: {Authorization: `Bearer ${token}`, cc: additionalHeaders}
                                              , responseType: responseType.responseType as 'json'
                                              , observe: observe.observe as 'body'});
      }),
      catchError(err => throwError(err))
    );
  }

  putEndPoint(target, payload, responseType = {responseType: 'json'}, observe = {observe: 'body'}, additionalHeaders = '') {
    return this.authService.getTokenSilently$().pipe(
      mergeMap(token => {
        return this.http.put(target, payload, {headers: {Authorization: `Bearer ${token}`, cc: additionalHeaders}
                                              , responseType: responseType.responseType as 'json'
                                              , observe: observe.observe as 'body'});
      }),
      catchError(err => throwError(err))
    );
  }

  deleteEndpoint(target, responseType = {responseType: 'json'}, observe = {observe: 'body'}, additionalHeaders = '') {
    return this.authService.getTokenSilently$().pipe(
      mergeMap(token => {
        return this.http.delete(target, {headers: {Authorization: `Bearer ${token}`, cc: additionalHeaders}
                                   , responseType: responseType.responseType as 'json'
                                   , observe: observe.observe as 'body'});
      }),
      catchError(err => throwError(err))
    );
  }

}
