import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { IntegrityService } from '../../integrity.service';
import { NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { ContentBuilderComponent } from 'src/app/ui/content-builder/content-builder.component';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { Subscription } from 'rxjs';
import { HelperServiceService } from 'src/app/helper-service.service';
import { UiService } from 'src/app/ui/ui.service';
import { UserService } from 'src/app/user.service';
declare var $: any;

@Component({
  selector: 'app-integrity-check-result',
  templateUrl: './integrity-check-result.component.html',
  styleUrls: ['./integrity-check-result.component.scss']
})
export class IntegrityCheckResultComponent implements OnInit, OnDestroy {

  // user variables
  activeUser: number; // -1 - unknown, 0 - not permitted, 1 - permitted on the system

  // header variables
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;

  // local variables
  content: string;
  requests: any;  // this will store the activeIntegrityChecks, closedIntegrityChecks and integrityRequests as they come from backend
  groups: any;    // this will store the parsed activeIntegrityChecks, closedIntegrityChecks and integrityRequests groups
  openIntegrityChecks: any; // list of all opened integrity checks
  closedIntegrityChecks: any; // list of all closed integrity checks
  flaggedCompanies: any; // list of all companies flagged by me
  requestIntegrityCheckForKvkNumber: string; // the KVK number of the company we are requesting more information
  requestIntegrityCheckForKey: string; // the key of the integrity check we are requesting more information
  endIntegrityCheckKey: string; // the key of the integrity check we are attempting to close
  postinformationRequestKey: string;
  @ViewChildren(ContentBuilderComponent) builderComponents: QueryList<ContentBuilderComponent>;

  // content variables
  contentDOM: any[];

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  refreshType: string;

  // subscriptions
  activeUserStatus: Subscription;
  postIntegrityCheckStatus: Subscription;
  activeIntegrityChecks: Subscription;
  activeIntegrityRequests: Subscription;
  requestInfoStatus: Subscription;
  postInfoStatus: Subscription;
  integrityCloseStatus: Subscription;
  scrollToCurrentCompany: Subscription;
  logActivityStatus: Subscription;
  integrityFlagsStatus: Subscription;
  icReopenStatus: Subscription;
  hideOrangeDotStatus: Subscription;
  loggedActivitiesStatus: Subscription;
  loadPastRequestsStatus: Subscription;
  partialRecoveryStatus: Subscription;
  fetchKnownEmailAddressesStatus: Subscription;
  grantIdentityConsentStatus: Subscription;
  denyIdentityConsentStatus: Subscription;

  constructor(private cd: ChangeDetectorRef,
    private helper: HelperServiceService,
    private integrityService: IntegrityService,
    private router: Router,
    private el: ElementRef,
    private UIservice: UiService,
    private userService: UserService) { }

  ngOnInit() {

    // set the default user status to -1
    this.activeUser = -1;
    if (this.userService.getUserInfo() !== null) {
      this.activeUser = 1;
    }

    // by default set this to false
    this.integrityService.setIsScrolled(false);

    // check if I am allowed to be on this page:
    const kvkNumber = this.integrityService.getCurrentCompanyKvkNumber();
    // if ( kvkNumber === undefined) {
    //   // redurect to search url
    //   this.router.navigateByUrl('integrity-check/search');
    //   return;
    // }

    // initialize the header
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };

    // init modal lightbox
    this.displayModal = false;
    this.formType = 'Request for Information';
    this.formTitle = ''; // 'Financial Holdings B.V';

    // initialize the content:
    console.log('*******************************************INITIALIZING*********************************');
    this.requests = {
      'activeIntegrityChecks' : [],
      'closedIntegrityChecks' : [],
      'integrityRequests' : [],
      'flaggedCompanies': [],
    };
    this.groups = {
      'activeIntegrityChecks' : [],
      'closedIntegrityChecks' : [],
      'integrityRequests' : [],
      'flaggedCompanies': [],
    };

    // check if user is valid
    // this.integrityService.validateUser();

    // handle user status
    this.activeUserStatus = this.integrityService.activeUserStatus.subscribe(
      status => {
        if (status !== false) {
            // get active integrity checks
            this.integrityService.getIntegrityChecks('open');

            // get active integrity requests
            this.integrityService.getIntegrityRequests();

            // get closed integrity checks
            this.integrityService.getIntegrityChecks('closed');

            // get flagged companies
            this.integrityService.getMyFlaggedCompanies();

            // set user to permitted
            this.activeUser = 1;
        } else {
          // set user to forbidden
          this.activeUser = 0;
        }
      }
    );

    // subscribe to post integrity check status
    this.postIntegrityCheckStatus = this.integrityService.postIntegrityCheckStatus.subscribe(
      (data: any) => {
        console.log('the integrity check was posted successfully', data);
        this.integrityService.setCurrentCompanyDetails(data);
        this.integrityService.setScrollToKey(data.key);
        this.displayLoader = false; // hide the loading screen
        this.displayModal = false;
        if (typeof data !== 'undefined' && !data.record.isNew) {
          if (data.record.noOfFlags > 0) {
            // refresh active integrity checks
            this.refreshType = 'open';
          } else {
            // refresh closed integrity checks
            this.refreshType = 'closed';
          }
          if (data.isNew === false) {
            this.OnConfirmViewExistingCheck();
          } else {
            // check if the search returned opened or closed integrity check
            // if (data.record.noOfFlags > 0) {
            //   this.integrityService.getIntegrityChecks('open');
            // } else {
            //   this.integrityService.getIntegrityChecks('closed');
            // }
            // in most cases both active and closed integrity checks need to be refreshed so do that
            this.integrityService.getIntegrityChecks('open');
            this.integrityService.getIntegrityChecks('closed');
            this.displayLoader = false; // hide the loading screen
            this.displayModal = false;
            this.integrityService.scrollToSelectedCompany();
            this.router.navigateByUrl('integrity-check/dashboard');
          }
        }

      },
      error => {
        console.log('something went wrong', error);
      }
    );


    // same subscription and method will handle both open and closed checks
    // hint: if record.staus = open we handle active integrity check
    this.activeIntegrityChecks = this.integrityService.activeIntegrityChecks.subscribe( response => {
      const details = response.checks;
      const status = response.status;
      if (typeof details === 'undefined' || details === null || typeof details[0] === 'undefined') {
        if (status === 'open') {
          this.groups.activeIntegrityChecks[0] = {};
        } else {
          this.groups.closedIntegrityChecks[0] = {};
        }
        return;
      }
      // toggle the header type
      this.toggleInlineHeader();
      // parse the integrity checks
      if (status === 'open') {
        this.openIntegrityChecks = details;
        this.parseIntegrityChecksActive(details);
      } else {
        this.closedIntegrityChecks = details;
        this.parseIntegrityChecksClosed(details);
      }
    });

    // subscribe to integrity requests
    this.activeIntegrityRequests = this.integrityService.activeIntegrityRequests.subscribe( details => {
      if (typeof details === 'undefined' || details === null  || (Array.isArray(details) && details.length < 1)) {
        // reset the integrityRequests
        this.groups.integrityRequests[0] = {};
        return;
      }
      // toggle the header type
      this.toggleInlineHeader();
      // parse the requests
      this.parseIntegrityRequests(details);
    });

    this.requestInfoStatus = this.integrityService.requestInfoStatus.subscribe((status => {
      // this.companyDetails.next(status);
      // console.log("SDTATUS OF REQUEST", status);
      console.log('status of request integrity check: ', status);

      if (status !== 'error!' && status !== null) {
        this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'SENT'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Your list of questions have been sent to the previous service provider. You will receive a notification once the requested information has been provided.'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.displayLoader = false;
        // refresh active integrity checks
        this.integrityService.getIntegrityChecks('open');
        this.scrollToElementWithKey(); // if scrolling by key fails it will fallback to scrolling by id
        this.cd.detectChanges();
      } else {
        this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Your list of questions have NOT been sent due to unexpected error. Please try again later!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.displayLoader = false;
        this.cd.detectChanges();
      }
    }));

    this.postInfoStatus = this.integrityService.postInfoStatus.subscribe((status => {
      // this.companyDetails.next(status);
      // console.log("SDTATUS OF REQUEST", status);
      this.displayLoader = false;
      console.log('status of request integrity check: ', status);

      if (status !== 'error!') {
        this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': (status === 'sent')  ? 'SENT' : 'REJECTED'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content':
                (status === 'sent')
                ? 'Your answers have been sent.'
                : 'Your rejection of the request for information has been noted by the service provider'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        // refresh integrity requests
        this.integrityService.getIntegrityRequests();
        this.cd.detectChanges();
      } else {
        this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Your list of questions have NOT been sent due to unexpected error. Please try again later!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.cd.detectChanges();
      }
    }));

    this.integrityCloseStatus = this.integrityService.integrityCloseStatus.subscribe( status => {
      console.log('THIS IS THE STATUS I GET BACK FROM BE', status);
      if (status !== null) {
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'confirmation_end_integrity_check',
          'result_details' : [
            {
            'group_result_title' : 'confirm-end-integrity',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/illustration-attachment.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Done'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'The integrity check is closed successfully'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.displayLoader = false;
        this.cd.detectChanges();
        // reload the active and closed integrity checks
        this.integrityService.getIntegrityChecks('open');
        this.integrityService.getIntegrityChecks('closed');
        this.integrityService.scrollToSelectedCompany();
      } else {
        // this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/illustration-attachment.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'We were unable to end the integrity check for you due to unexpected error. Please try again later!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayLoader = false;
        this.displayModal = true;
        this.cd.detectChanges();
      }
    });


    // subscribe to Scroll To Company event
    this.scrollToCurrentCompany = this.integrityService.scrollToCurrentCompany.subscribe(
      key => {
        this.integrityService.setIsScrolled(false);
      }
    );

    this.logActivityStatus = this.integrityService.logActivityStatus.subscribe((status => {
      // this.companyDetails.next(status);
      // console.log("SDTATUS OF REQUEST", status);
      console.log('status of request integrity check: ', status);

      if (status !== 'error!') {
        // this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/illustration-attachment.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Done'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': status
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.displayLoader = false;
        // refresh active integrity checks
        this.integrityService.getIntegrityChecks('open');
        this.cd.detectChanges();
      } else {
        this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/illustration-attachment.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Your activity could NOT have been logged due to unexpected error. Please try again later!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
        this.cd.detectChanges();
      }
    }));

    this.integrityFlagsStatus = this.integrityService.integrityFlagsStatus.subscribe( details => {
      if (typeof details === 'undefined' || details === null || (Array.isArray(details) && details.length < 1)) {
        this.groups.flaggedCompanies[0] = {};
        return;
      }
      // toggle the header type
      this.toggleInlineHeader();
      console.log('OVA SE DETALITE ZA FLAGGED COMPANIES OD BE: ', details);
      // parse the integrity checks
      this.flaggedCompanies = details;
      this.parseFlaggedCompanies(details);
    });

    this.icReopenStatus = this.integrityService.icReopenStatus.subscribe(status => {
      event.preventDefault();
      // display lightbox
      this.formType = 'Re-open Integrity';
      this.formTitle = ''; // 'Financial Holdings B.V';
      this.btnOkText = '';
      this.formValidator = '';
      this.btnCancelText = (status.status !== 'refresh') ? '' : 'OK';
      // build the content for the Request Information light box
      const group = {
        'section_class' : 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'perform_integrity_check',
        'result_details' : [
          {
          'group_result_title' : 'perform_reopen_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          'elements' : [
            {
              'type': 'h3',
              'params': {
                'content': 'Re-open integrity check'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': status.message
              }
            },
          ]
        }]
      };
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      if (status.status !== 'refresh') {
        this.displayModal = true;
        this.displayLoader = true;
      } else {
        this.displayModal = true;
        this.displayLoader = false;
        // reload active integrity checks
        this.integrityService.getIntegrityChecks('open');
        // reload closed integrity checks
        this.integrityService.getIntegrityChecks('closed');
      }
    });

    this.hideOrangeDotStatus = this.integrityService.hideOrangeDotStatus.subscribe(status => {
      console.log(this.integrityService.getScrollToId());
      $('[key="' + this.integrityService.getScrollToKey() + '"]').removeClass('orange-dot');
    });

    this.loggedActivitiesStatus = this.integrityService.loggedActivitiesStatus.subscribe(status => {
      const key = status.key;
      const targetGroup = $.trim(status.targetGroup);
      status = status.status;
      if (status === null || (status.records !== undefined && status.records.length === 0)) {
        this.removeActivitiesLoader(targetGroup, key);
        return;
      }
      // parse and display the logged data:
      const ulElements = [];
      status.records.forEach(record => {
        ulElements.push({
          'content': ((record.Record.type === 'call') ? 'Phone call, ' : 'Face to face, ') + record.Record.dateOfActivity,
          'additional_content': record.Record.loggedActivity,
          'type': 'text'
        });
      });
      this.displayLoadedActivities(targetGroup, key, [
          {
            'type': 'title',
            'params': {
              'content': 'Logged activity',
              'display': true
            }
          },
          {
            'type' : 'unordered_list',
            'isOrange': false, // indicates that this request has not yet been seen by the sending side
            'params': ulElements
          },
        ]);
    });

    this.loadPastRequestsStatus = this.integrityService.loadPastRequestsStatus.subscribe(result => {
      console.log('STATUS', result);
      const key = result.key;
      const targetGroup = $.trim(result.targetGroup);
      status = result.status;
      if (result !== null && (result.status.requests !== undefined && result.status.requests.length !== 0)) {
        // parse and display the logged data:
        this.displayPastRequests(targetGroup, key, result.status.requests);
      }

      // expand the box even if there are no results and if there is an event target:
      if (result.eventTarget !== undefined && result.eventTarget !== null ) {
        let eventTarget = $(result.eventTarget.target);
        let eventImg = eventTarget.find('img');
        if (eventTarget.is('img')) {
          eventTarget = $(eventTarget).closest('a');
          eventImg = $(eventTarget);
        }
        eventTarget.find('img').attr('src', 'assets/images/result_arrow_down.png').height('10px');
        eventTarget.removeClass(result.eventTarget.originalclass);
        eventTarget.addClass(result.eventTarget.toggleclass);
        eventTarget.closest('.result-container').addClass('expanded');
        // simulate click:
        $(eventTarget).click();
      }

    });


    this.partialRecoveryStatus = this.integrityService.partialRecoveryStatus.subscribe(check => {
      if (check.result === null || check.result === 503) {
        // handle the failed to recover scenario
        // for now only remove the loader for the integrity check!
        this.removeCompanyDetailsLoader(check.target, check.key);
      } else {
        // handle successfully recovered
        const result = check.result.record;
        console.log('RETURNED BY SUBSCRIPTION TO RECOVERY', check.result);
        console.log('THIS IS THE RESULT', result);
        this.updateCompanyDetails(check.target, check.key, result.company.businessName, {
          'id': 'general_information',
          'title': 'Company Information',
          'status': 'active',
          'content': [
            { 'key': 'Kvk number', 'content': result.company.kvkNumber }, // '22517301',
            { 'key': 'Place', 'content': result.company.address.city }, // 'Rotterdam',
            { 'key': 'Activities', 'content' : this.helper.parseActivities( result.company.businessActivities ), 'type': 'html' },
            { 'key': 'Address', 'content': ((result.company.address.street !== undefined) ? (result.company.address.street + ' ') : '')
                      + ((result.company.address.houseNumber !== undefined) ? (result.company.address.houseNumber + ', ') : '')
                      + ((result.company.address.postalCode !== undefined) ? (result.company.address.postalCode + ' ') : '')
                      + ((result.company.address.city !== undefined) ? (result.company.address.city) : '') }
          ]
        });
      }
    });

    this.fetchKnownEmailAddressesStatus = this.integrityService.fetchKnownEmailAddressesStatus.subscribe(status => {
      if (status.result === null || status.result.record === undefined || status.result.emails === undefined) {
        // execute no known addresses flow
        this.OnRequestInformationFromExternalTrustNoKnownMailAddresses(status.companyName);
        return;
      }
      this.OnRequestInformationFromExternalTrustKnownMailAddresses(status.companyName);
    });

    this.grantIdentityConsentStatus = this.integrityService.grantIdentityConsentStatus.subscribe(status => {
      // the identity consent has been granted display the question
      this.displayLoader = false;
      if (!status.result) {
        this.displayErrorBox(status.reason);
      }
      console.log( status.result.requests);
      this.OnInitiateDisplaySubmitInfo(null
        , status.result.record.company.businessName
        , status.result.record.identity.organization);
    });

    this.denyIdentityConsentStatus = this.integrityService.denyIdentityConsentStatus.subscribe(status => {
      // the identity consent has been denied display the question
      this.displayLoader = false;
      if (!status.result) {
        this.displayErrorBox(status.reason);
      }
      this.OnInitiateDisplaySubmitInfo(
        null
        , this.integrityService.getInformationRequestCompanyName());
    });

  } // ngOnInit

  displayErrorBox(message: string = '') {
    this.formTitle = this.integrityService.getCurrentCompanyName();
        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'elements' : [
            {
              'type': 'image',
              'params': {
                'src': 'assets/images/form_image_example.png'
              }
            },
            {
              'type': 'h3',
              'params': {
                'content': 'Unexpected Error',
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': (message !== '') ? message : 'An unexpected error occured!'
              }
            },
            // let's use the lightbox close button as we don't need to execute anything special
          ]
          }]
        };
        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnCancelText = 'Close';
        this.displayModal = true;
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.activeUserStatus.unsubscribe();
    this.postIntegrityCheckStatus.unsubscribe();
    this.activeIntegrityChecks.unsubscribe();
    this.activeIntegrityRequests.unsubscribe();
    this.requestInfoStatus.unsubscribe();
    this.postInfoStatus.unsubscribe();
    this.integrityCloseStatus.unsubscribe();
    this.scrollToCurrentCompany.unsubscribe();
    this.logActivityStatus.unsubscribe();
    this.integrityFlagsStatus.unsubscribe();
    this.icReopenStatus.unsubscribe();
    this.hideOrangeDotStatus.unsubscribe();
    this.loggedActivitiesStatus.unsubscribe();
    this.loadPastRequestsStatus.unsubscribe();
    this.partialRecoveryStatus.unsubscribe();
    this.fetchKnownEmailAddressesStatus.unsubscribe();
    this.grantIdentityConsentStatus.unsubscribe();

    this.integrityService.setScrollToId(null);
    this.integrityService.setScrollToKey(null);
    this.integrityService.clearCurrentCompany();
    this.integrityService.setIsScrolled(false);
  }

  removeCompanyDetailsLoader(targetGroup, key) {
    const activeICQL = this.queryTargetGroup(targetGroup);
    const acl = this.queryTargetElement(key, activeICQL);
    acl.elements.forEach((element, key) => {
      if (element.type === 'tabbed_content') {
        acl.elements[key].params.tabs[0].displayLoader = false;
      }
    });
  }
  updateCompanyDetails(targetGroup, key, companyTitle, companyDetails) {
    const activeICQL = this.queryTargetGroup(targetGroup);
    const acl = this.queryTargetElement(key, activeICQL);
    acl.result_title = companyTitle;
    acl.elements.forEach((element, key) => {
      if (element.type === 'tabbed_content') {
        acl.elements[key].params.tabs[0].displayLoader = false;
      }
    });
  }

  removeActivitiesLoader(targetGroup, key) {
    const activeICQL = this.queryTargetGroup(targetGroup);
    const acl = this.queryTargetElement(key, activeICQL);
    acl.elements[6].params[0].result_details[0].elements[1].displayLoader = false;
  }

  displayLoadedActivities(targetGroup, key, ulParams) {
    const activeICQL = this.queryTargetGroup(targetGroup);
    const acl = this.queryTargetElement(key, activeICQL);
    acl.elements[6].params[0].result_details[0].elements = ulParams;
  }

  displayPastRequests(targetGroup, key, requests) {
    const activeICQL = this.queryTargetGroup(targetGroup);
    const acl = this.queryTargetElement(key, activeICQL);
    console.log(acl.elements);
    console.log(acl.elements[4]);
    acl.elements[4].params.elements = requests;
  }

  /**
   * toggle the type of the header (full => inline)
   */
  toggleInlineHeader() {
    // initialize the header
    this.headerInfo = {
      'headerType': 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_auto',
    };

    // this.cd.detectChanges();
  }


  /**
   * toggle expand the result pane on click on the arrow
   */
  OnResultToggle(event, originalclass, toggleclass) {
    const hasClass = event.target.classList.contains(originalclass);

    if (hasClass) {
      event.target.classList.remove(originalclass);
      event.target.classList.add(toggleclass);
    } else {
      event.target.classList.add(originalclass);
      event.target.classList.remove(toggleclass);
    }
  }

  /**
   * parse results from API into user friendly data
   * This approach is depercated
   */
  parseCompanyDetails(result) {
    // don't use default lightbox buttons
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';

    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center',
      'group_name' : 'Active Integrity Checks',
      'result_container_class': 'result-container my-2',
      'is_collapsable': true,
      'result_details' : [{
        'group_result_title' : 'group_2_result_1',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : result.company.businessName, // SELECTED COMPANY NAME!
        // 'result_caption' : 'The service provider submitted the requested information', // not needed for this template
        'elements' : [{
          'type': 'alert',
          'params': {
            // set to alert-container-1 if green alert needs to be displayed
            'alert_type': (result.noOfFlags < 1) ? 'alert-container-1'  : 'alert-container-2',
            'alert_title': (result.noOfFlags < 1) ? 'All Looks Good!' : 'Hit Found',
            'alert_content':
            (result.noOfFlags < 1) ?
                'This entity has not been flagged by a service provider. You will be alerted if the entity is flagged within the next 12 months.'
              :
                'This entity has previously been serviced by another service provider, who ended the relationship for integrity reasons.'
          }
        },
        {
          'type': 'title',
          'params': {
            'content': 'Serviced by ' + result.noOfFlags + ' Trust companies in the past 5 years.'
          }
        },
        {
          'type': 'subtitle',
          'params': {
            'content': 'Most recent relationships for this entry:',
            'display' : true
          }
        },
        {
          'type': 'most_recent_relationship',
          'params': {
            'startdate': (result.firstReportedAt) ? this.helper.convertToDate(result.firstReportedAt) : '', // '14th October 2017',
            'enddate': (result.lastReportedAt) ? this.helper.convertToDate(result.lastReportedAt) : '', // 22nd February 2018',
          }
        },
        {
          'type': 'title',
          'params': {
            'content': 'Latest KVK Information'
          }
        },
        {
          'type': 'tabbed_content',
          'params': {
            'tabs': [
              {
                'id': 'general_information',
                'title': 'Company information',
                'status': 'active',
                'content': [
                  { 'key': 'Kvk number', 'content': result.company.kvkNumber }, // '22517301',
                  { 'key': 'Place', 'content': result.company.address.city }, // 'Rotterdam',
                  { 'key': 'Activities', 'content' : this.helper.parseActivities( result.company.businessActivities ), 'type': 'html' },
                  // 'activities': [
                  //   'SBI-code: 6420 - Financiële holdings',
                  //   'SBI-code: 4110 - Projectontwikkeling'
                  // ],
                  { 'key': 'Address', 'content': ((result.company.address.street !== undefined) ? (result.company.address.street + ' ') : '')
                            + ((result.company.address.houseNumber !== undefined) ? (result.company.address.houseNumber + ', ') : '')
                            + ((result.company.address.postalCode !== undefined) ? (result.company.address.postalCode + ' ') : '')
                            + ((result.company.address.city !== undefined) ? (result.company.address.city) : '') }
                            // 'Prins Bernhardplein 200, 1097JB Amsterdam',
                  // 'postal_address': 'Petroleumweg 44, 3196KD Vondelingenplaat Rt'
                ]
              },
              // {
              //   'id': 'shareholders',
              //   'title': 'Shareholders',
              //   'status': '',
              //   'content': ''
              // }
            ]
          }
        },
        {
          'type': 'button_group',
          'params': [
            {
              'content': 'Request information',
              'action': 'OnRequestInformation',
              'display': (result.noOfFlags !== 0) ? true : false
            },
            {
              'content': 'Log activity',
              'action': 'OnLogActivity',
              'display': true
            }
          ]
        },
      ]
      }]
    };
    // this.groups.push(group);
    this.groups['companyDetails'].push(group);
    this.contentDOM = this.groups['companyDetails'];
    this.cd.detectChanges();
  }

  parseIntegrityChecksActive(checks) {

    // check if valid array:
    if (!Array.isArray(checks) || (Array.isArray(checks) && checks.length < 1)) {
      console.log('ERROR: EMPTY RESULT SET WAS MET');
      return;
    }

    // set the group type
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center',
      'group_name' : 'Active Integrity Checks',
      'is_collapsable': true,
    };

    // parse the group blocks
    group['result_details'] = [];
    checks.forEach(check => {
      // parse answers from previous companies
      const parsedRequests = [];
      parsedRequests.push({
        'type': 'title',
        'params': {
          'content': 'Info provided by previous service provider'
        }
      });
      if (typeof check.requests !== 'undefined') {
        check.requests.forEach(request => {
          const parsedListItems = [];
          request.record.questions.forEach(question => {
            parsedListItems.push({
              'content': question.questionText,
              'additional_content': (typeof question.answer !== 'undefined' )
                  ? question.answer.record
                  : 'MANUALY ASSINGNED ANSWER SINCE IT IS MISSING IN THE API',
              'type': question.questionType
            });
          });
          parsedRequests.push({
            'type' : 'unordered_list',
            'isOrange': (request.record.status === 'answered'
                            || request.record.status === 'rejected')
                            ? true
                            : false, // indicates that this request has not yet been seen by the sending side
            'params': parsedListItems
          });
          if (request.record.status === 'rejected') {
            parsedRequests.push({
              'type': 'paragraph',
              'params': {
                'content': 'The service provider has denied your request for more information'
              }
            });
          }
        });
      }

      // Define Content
      const result = check.record;
      group['result_details'].push(this.generateActiveIntegrityCheckStructure(result, check.key, check.requests, parsedRequests));
    });

    this.groups.activeIntegrityChecks[0] = group;
    this.requests.activeIntegrityChecks = checks;
    this.contentDOM = this.groups.activeIntegrityChecks;
    // this.cd.detectChanges();
    // this.scrollToElementWithId(this.integrityService.getCurrentCompanyKvkNumber());
    this.scrollToElementWithKey('', 'Active Integrity Checks');
  }

  generateActiveIntegrityCheckStructure(result, integrityCheckKey, requests, parsedRequests) {
    let result_caption = (result.noOfFlags < 1) ? 'No flags were found for this entity' : 'A flag was found for this entity';
    let requests_caption = 'Pending information from previous service provider';
    if (requests !== undefined && requests.length > 0) {
      // switch (requests[requests.length - 1].record.status) {
      switch (requests[0].record.status) {
        case 'answered':
            result_caption = 'The service provider submitted the requested information';
            requests_caption = 'The information requested has been provided';
          break;
        case 'answered_seen':
          result_caption = 'The service provider submitted the requested information';
          requests_caption = 'The information requested has been provided';
        break;
        case 'rejected':
          result_caption = 'The service provider rejected your request for information';
          requests_caption = 'The information requested has been provided';
        break;
        case 'rejected_seen':
          result_caption = 'The service provider rejected your request for information';
          requests_caption = 'The information requested has been provided';
        break;
        case 'closed':
          result_caption = (result.noOfFlags < 1) ? 'No flags were found for this entity' : 'A flag was found for this entity';
          requests_caption = 'The information requested has been provided';
        break;
        default:
            result_caption = 'You submitted a request for additional information';
            requests_caption = 'Pending information from previous service provider';
          break;
      }
    }

    if (integrityCheckKey === this.integrityService.getCurrentCompanyKey()
                || result.company.kvkNumber === this.integrityService.getScrollToId()) {
                  let key = this.integrityService.getCurrentCompanyKey();
                  if (key === undefined || key === null || key === false) {
                    key = this.integrityService.getScrollToKey();
                  }
                  // event target is not needed as this is happening on load
                  this.integrityService.loadRequestsHistory(key, 'Active Integrity Checks', null);
    }

    const isNew = (integrityCheckKey === this.integrityService.getCurrentCompanyKey()
                  || integrityCheckKey === this.integrityService.getScrollToKey()
                  || result.company.kvkNumber === this.integrityService.getScrollToId())
                  ? false
                  : true;
    // for testing purposes
    // result.externalTrustKey = 'EXTERNAL:COMPANY:UNIQUE:IDENTIFIER';
    // result.identity = {
    //   "status": "IDENTITY_REQUESTED",
    //   "organization": "org1"
    // };
    return {
      'id' : integrityCheckKey, // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
      'result_container_class': 'result-container my-2' + ((isNew) ? '' : ' expanded')
                                                        + ((result.result === 'unknown')
                                                        ? ' tealish_left_border'
                                                        : (result.result === 'no-hit') ? ' green_left_border'  : ' red_left_border'),
      'kvk' : result.company.kvkNumber, // KVK Number
      'key' : integrityCheckKey, // key string
      'trcKey' : (result.externalTrustKey) ? result.externalTrustKey : '', // external trust company key string
      'identity' : (result.identity && result.identity.status === 'IDENTITY_GRANTED')
                    ? result.identity.organization
                    : '', // identity revealed
      'isPartial' : result.company.partial,
      'isNew':  isNew,
      'display_orange_dot': this.isNewAnswers(requests),
      'onArrowClickMethod': (isNew) ? 'onExpandActiveBox' : null,
      'additional_info': this.helper.formatISODate( result.createdOn ),
      'additional_info_classes': 'gray-date',
      'group_result_title' : 'group_2_result_' + result.company.kvkNumber + (new Date().getTime()),
      'result_title' : (!result.company.partial) ? result.company.businessName : result.company.kvkNumber, // SELECTED COMPANY NAME!
      'result_caption' : result_caption, // not needed for this template
      'result_title_classes' : 'mt-0',
      'result_identity_granted': (!result.identity || (result.identity && result.identity.status !== 'IDENTITY_GRANTED'))
                                  ? false
                                  : true,
      'result_identity': (!result.identity || (result.identity && result.identity.status !== 'IDENTITY_GRANTED'))
                        ? 'The corporate identity of the counterparty is not yet known.'
                        : 'Verified counterparty: ' + result.identity.organization,
      'result_identity_display': (result.result !== 'unknown' && result.result !== 'no-hit')
                        ? true
                        : false,
      'elements' : [{
        'type': 'alert',
        'params': {
          // set to alert-container-1 if green alert needs to be displayed
          'alert_type': (result.result === 'unknown')
                        ? 'alert-container-3'
                        : (result.result === 'no-hit') ? 'alert-container-1'  : 'alert-container-2',
          'alert_title': (result.result === 'unknown')
                          ? 'Unknown'
                          : (result.result === 'no-hit') ? 'All Looks Good!' : 'Hit Found!',
          'alert_date': (result.createdOn) ? this.helper.convertToDate(result.createdOn) : '',
          'alert_content':
          (result.result === 'unknown')
          ? 'The legal entity has previously been serviced by a service provider, who is currently not a member of the Finos platform. You may still request information from this service provider by clicking the \'Request Information\' button below.'
          :
          (result.result === 'no-hit') ?
              'This entity has not been flagged by a service provider. You will be alerted if the entity is flagged within the next 12 months.'
            :
              'This entity has previously been serviced by another service provider, who has ended the relationship and flagged the entity.'
        }
      },
      {
        'type': 'title',
        'params': {
          'content': 'Flagged by ' + result.noOfFlags
                      + ' service '
                      + ( (result.noOfFlags > 1) ? 'providers' : 'provider' )
                      + ' in the past 5 years.',
          'display': (result.externalTrustKey) ? false : true
        }
      },
      {
        'type': 'subtitle',
        'params': {
          'content': 'Most recent relationship for this entity:',
          'display': (result.externalTrustKey) ? false
                      : (result.serviceStartDate !== undefined || result.serviceEndDate !== undefined) ? true : false
        }
      },
      {
        'type': 'most_recent_relationship',
        'params': {
          'startdate': (result.serviceStartDate) ? this.helper.convertToDate(result.serviceStartDate) : '', // '14th October 2017',
          'enddate': (result.serviceEndDate) ? this.helper.convertToDate(result.serviceEndDate) : '', // 22nd February 2018',
          'display': (result.externalTrustKey) ? false : true
        }
      },
      {
        'type': 'requestsHistory',
        'params': {
          'title': 'Info provided by previous service provider',
          'section_class' : 'd-flex flex-column p-0',
          'elements' : [],
        }
      },
      {
        'type': 'requestsHistory',
        'params': {
          'title': requests_caption,
          'section_class' : 'd-flex flex-column p-0',
          'elements' : requests,
        }
      },
      // {
      //   'type': 'contentBuilder',
      //   'params': [{
      //     'section_class' : 'd-flex flex-column p-0',
      //     'result_container_class': '', // result-container
      //     'is_collapsable': false,
      //     'result_details' : [{
      //       'group_result_title' : '',
      //       'result_title' : '',
      //       'elements' : parsedRequests
      //     }],
      //   }]
      // },
      {
        'type': 'contentBuilder',
        'display': this.integrityService.getSystemProperty('isLogActivitiesEnabled'),
        'params': [{
          'id': 'log_activity_' + result.company.kvkNumber,
          'section_class' : 'd-flex flex-column p-0',
          'result_container_class': '', // result-container
          'is_collapsable': false,
          'result_details' : [{
            'group_result_title' : '',
            'result_title' : '',
            'elements' : [{
              'type': 'title',
              'params': {
                'content': 'Logged activity',
                'display': true
              }
            },
            {
              'type': 'unordered_list',
              'isOrange': false,
              'displayLoader': true,
              'params': [{
                'content': 'There is no extra activity loaded.',
                'additional_content': '',
                'type': 'text'
              }]
            },
          ],
          }],
        }]
      },
      {
        'type': 'title',
        'params': {
          'content': 'Latest KVK Information'
        }
      },
      {
        'type': 'tabbed_content',
        'params': {
          'tabs': [
            (!result.company.partial) ?
            {
              'id': 'general_information',
              'title': 'Company information',
              'status': 'active',
              'content': [
                { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                { 'key': 'Place', 'content' : result.company.address.city }, // 'Rotterdam',
                { 'key': 'Activities', 'content' : this.helper.parseActivities( result.company.businessActivities ), 'type': 'html' },
                // 'activities': [
                //   'SBI-code: 6420 - Financiële holdings',
                //   'SBI-code: 4110 - Projectontwikkeling'
                // ],
                { 'key': 'Address', 'content' : ((result.company.address.street !== undefined) ? (result.company.address.street + ' ') : '')
                          + ((result.company.address.houseNumber !== undefined) ? (result.company.address.houseNumber + ', ') : '')
                          + ((result.company.address.postalCode !== undefined) ? (result.company.address.postalCode + ' ') : '')
                          + ((result.company.address.city !== undefined) ? (result.company.address.city) : '') }
                          // 'Prins Bernhardplein 200, 1097JB Amsterdam',
                // 'postal_address': 'Petroleumweg 44, 3196KD Vondelingenplaat Rt'
              ]
            }
            :
            {
              'id': 'general_information',
              'title': 'Company information',
              'status': 'active',
              'displayLoader': true,
              'content': [
                { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                { 'key': 'Notification', 'content': 'Information cannot displayed at this time' },
              ]
            },
            // {
            //   'id': 'shareholders',
            //   'title': 'Shareholders',
            //   'status': '',
            //   'content': ''
            // }
          ]
        }
      },
      {
        'type': 'hr',
        'params': {
          'class': 'result-hr'
        }
      },
      {
        'type': 'anchor',
        'params': {
          'content': 'See more detailed information about this company',
          'url': 'https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer='
                  + (result.company.kvkNumber
                  + '000000000000').substring(0, 12),
          'open_new_tab': true,
          'external': true
        }
      },
      {
        'type': 'conditional_paragraph',
        'params': {
          'content': 'You may request information from the previous service provider or close the integrity check.',
          'display': true,
        }
      },
      {
        'type': 'button_group',
        'params': [
          {
            'content': 'Request information',
            'action': 'OnRequestInformation',
            'display': (result.externalTrustKey)
                        ? false
                        : (result.noOfFlags > 0
                          && (!result.identity
                             || (result.identity && result.identity.status !== 'IDENTITY_GRANTED'))) ? true : false
          },
          {
            'content': 'Request information',
            'action': 'OnRequestInformationAskForIdentity',
            'display': (result.externalTrustKey)
                        ? false
                        : (result.noOfFlags > 0
                          && (result.identity && result.identity.status === 'IDENTITY_GRANTED')) ? true : false
          },
          {
            'content': 'Request information',
            'action': 'OnRequestInformationFromExternalTrust',
            'display': (result.externalTrustKey)
                        ? true
                        : false
          },
          {
            'content': 'Log activity',
            'action': 'OnLogActivity',
            'display': this.integrityService.getSystemProperty('isLogActivitiesEnabled'),
          }
        ]
      },
      {
        'type': 'action_link',
        'params': {
          'content': 'End Integrity Check',
          'action': 'OnEndIntegrityCheck'
        }
      },
    ]
    };
  }

  isNewAnswers(requests) {
    // REQUESTS THAT WILL DETERMINE IF ORANGE DOT
    let result = false;
    if (requests !== undefined) {
      requests.forEach(request => {
        if (request.record !== undefined && (request.record.status === 'answered'
                                            || request.record.status === 'rejected')) {
          result = true;
        }
      });
    }
    return result;
  }

  parseIntegrityChecksClosed(checks) {
    // check if valid array:
    if (!Array.isArray(checks) || (Array.isArray(checks) && checks.length < 1)) {
      console.log('ERROR: EMPTY RESULT SET WAS MET');
      return;
    }

    // set the group type
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center',
      'group_name' : 'Closed Integrity Checks',
      'is_collapsable': true,
    };

    // parse the group blocks
    group['result_details'] = [];
    checks.forEach(check => {
      // parse answers from previous companies
      const parsedRequests = [];
      parsedRequests.push({
        'type': 'title',
        'params': {
          'content': 'Info provided by previous service provider'
        }
      });
      if (typeof check.requests !== 'undefined') {
        check.requests.forEach(request => {
          const parsedListItems = [];
          request.record.questions.forEach(question => {
            parsedListItems.push({
              'content': question.questionText,
              'additional_content': (typeof question.answer !== 'undefined' )
                  ? question.answer.record
                  : 'MANUALY ASSINGNED ANSWER SINCE IT IS MISSING IN THE API',
              'type': question.questionType
            });
          });
          parsedRequests.push({
            'type' : 'unordered_list',
            'params': parsedListItems
          });
        });
      }

      // Define Content
      const result = check.record;
      group['result_details'].push(this.generateClosedIntegrityCheckStricture(result, check.key, check.requests, parsedRequests));
    });

    this.groups.closedIntegrityChecks[0] = group;
    this.requests.closedIntegrityChecks = checks;
    this.contentDOM = this.groups.closedIntegrityChecks;
    this.scrollToElementWithKey();
  }

  generateClosedIntegrityCheckStricture(result, integrityCheckKey, requests, parsedRequests) {
    let result_caption = (result.noOfFlags < 1) ? 'No flags were found for this entity' : 'A flag was found for this entity';
    let requests_caption = 'Pending information from previous service provider';
    if (requests !== undefined && requests.length > 0) {
      switch (requests[requests.length - 1].record.status) {
        case 'answered':
        result_caption = (result.noOfFlags < 1)
                        ? 'The service provider submitted the requested information'
                        : 'A flag was found for this entity, and additional information was provided';
            requests_caption = 'The information requested has been provided';
          break;
        case 'answered_seen':
          result_caption = (result.noOfFlags < 1)
                          ? 'The service provider submitted the requested information'
                          : 'A flag was found for this entity, and additional information was provided';
          requests_caption = 'The information requested has been provided';
        break;
        case 'rejected':
        result_caption = (result.noOfFlags < 1)
                          ? 'The service provider rejected your request for information'
                          : 'A flag was found for this entity, and additional information was rejected';
          requests_caption = 'The information requested has been provided';
        break;
        case 'rejected_seen':
          result_caption = (result.noOfFlags < 1)
                          ? 'The service provider rejected your request for information'
                          : 'A flag was found for this entity, and additional information was rejected';
          requests_caption = 'The information requested has been provided';
        break;
        case 'closed':
          result_caption = (result.noOfFlags < 1) ? 'No flags were found for this entity' : 'A flag was found for this entity';
          requests_caption = 'The information requested has been provided';
        break;
        default:
            result_caption = 'You submitted a request for additional information';
            requests_caption = 'Pending information from previous service provider';
          break;
      }
    }

    if (integrityCheckKey === this.integrityService.getCurrentCompanyKey()
                || result.company.kvkNumber === this.integrityService.getScrollToId()) {
                  let key = this.integrityService.getCurrentCompanyKey();
                  if (key === undefined || key === null || key === false) {
                    key = this.integrityService.getScrollToKey();
                  }
                  // event target is not needed as this is happening on load
                  this.integrityService.loadRequestsHistory(key, 'Closed Integrity Checks', null);
    }

    const isNew = (integrityCheckKey === this.integrityService.getCurrentCompanyKey()
    || integrityCheckKey === this.integrityService.getScrollToKey()
    || result.company.kvkNumber === this.integrityService.getScrollToId())
    ? false
    : true;

    return {
      'id' : integrityCheckKey, // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
      'result_container_class': 'result-container my-2' + ((isNew) ? '' : ' expanded')
                                                        + ((result.result === 'unknown')
                                                          ? ' tealish_left_border'
                                                          : (result.result === 'no-hit') ? ' green_left_border'  : ' red_left_border'),
      'kvk' : result.company.kvkNumber, // KVK Number
      'key' : integrityCheckKey, // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
      'isPartial' : result.company.partial,
      'isNew':  isNew,
      'onArrowClickMethod': (isNew) ? 'onExpandActiveBox' : null,
      'additional_info': this.helper.formatISODate( result.createdOn ),
      'additional_info_classes': 'gray-date',
      'group_result_title' : 'group_2_result_' + result.company.kvkNumber + (new Date().getTime()),
      'result_title' : (!result.company.partial) ? result.company.businessName : result.company.kvkNumber, // SELECTED COMPANY NAME!
      'result_caption' : result_caption,
      'result_title_classes' : 'mt-0',
      'result_identity_granted': (!result.identity || (result.identity && result.identity.status !== 'IDENTITY_GRANTED'))
                                  ? false
                                  : true,
      'result_identity': (!result.identity || (result.identity && result.identity.status !== 'IDENTITY_GRANTED'))
                        ? 'The corporate identity of the counterparty is not yet known.'
                        : 'Verified counterparty: ' + result.identity.organization,
      'result_identity_display': (result.result !== 'unknown' && result.result !== 'no-hit')
                        ? true
                        : false,
      'elements' : [{
        'type': 'alert',
        'params': {
          // set to alert-container-1 if green alert needs to be displayed
          'alert_type': (result.result === 'unknown')
                        ? 'alert-container-3'
                        : (result.result === 'no-hit') ? 'alert-container-1'  : 'alert-container-2',
          'alert_title': (result.result === 'unknown')
                          ? 'Unknown'
                          : (result.result === 'no-hit') ? 'No Flags Found!' : 'Hit Found!',
          'alert_date': (result.createdOn) ? this.helper.convertToDate(result.createdOn) : '',
          'alert_content':
          (result.result === 'unknown')
          ? 'The legal entity has previously been serviced by a service provider, who is currently not a member of the Finos platform. You may still request information from this service provider by clicking the \'Request Information\' button below.'
          :
          (result.result === 'no-hit') ?
              'This entity has not been flagged by a service provider. You will be alerted if the entity is flagged within the next 12 months.'
            :
              'This entity has previously been serviced by another service provider, who has ended the relationship and flagged the entity.'

        }
      },
      {
        'type': 'title',
        'params': {
          'content': 'Flagged by ' + result.noOfFlags
                      + ' service '
                      + ( (result.noOfFlags > 1) ? 'providers' : 'provider' )
                      + ' in the past 5 years.',
          'display': (typeof result.noOfFlags !== 'undefined' && result.noOfFlags > 0) ? true : false,
        }
      },
      {
        'type': 'subtitle',
        'params': {
          'content': 'Most recent relationship for this entity:',
          'display': (result.serviceStartDate !== undefined || result.serviceEndDate !== undefined) ? true : false
        }
      },
      {
        'type': 'most_recent_relationship',
        'params': {
          'startdate': (result.serviceStartDate) ? this.helper.convertToDate(result.serviceStartDate) : '', // '14th October 2017',
          'enddate': (result.serviceEndDate) ? this.helper.convertToDate(result.serviceEndDate) : '', // 22nd February 2018',
        }
      },
      {
        'type': 'requestsHistory',
        'params': {
          'title': 'Info provided by previous service provider',
          'section_class' : 'd-flex flex-column p-0',
          'elements' : [],
        }
      },
      {
        'type': 'requestsHistory',
        'params': {
          'title': requests_caption,
          'section_class' : 'd-flex flex-column p-0',
          'elements' : requests,
        }
      },
      // {
      //   'type': 'contentBuilder',
      //   'params': [{
      //     'section_class' : 'd-flex flex-column p-0',
      //     // 'group_name' : 'TEST',
      //     'result_container_class': '', // result-container
      //     'is_collapsable': false,
      //     'result_details' : [{
      //       'group_result_title' : '',
      //       'result_title' : '',
      //       'elements' : parsedRequests
      //     }],
      //   }]
      // },
      {
        'type': 'contentBuilder',
        'display': this.integrityService.getSystemProperty('isLogActivitiesEnabled'),
        'params': [{
          'id': 'log_activity_' + result.company.kvkNumber,
          'section_class' : 'd-flex flex-column p-0',
          'result_container_class': '', // result-container
          'is_collapsable': false,
          'result_details' : [{
            'group_result_title' : '',
            'result_title' : '',
            'elements' : [{
              'type': 'title',
              'params': {
                'content': 'Logged activity',
                'display': true
              }
            },
            {
              'type': 'unordered_list',
              'isOrange': false,
              'displayLoader': true,
              'params': [{
                'content': 'There is no extra activity loaded.',
                'additional_content': '',
                'type': 'text'
              }]
            },
          ],
          }],
        }]
      },
      {
        'type': 'title',
        'params': {
          'content': 'Latest KVK Information'
        }
      },
      {
        'type': 'tabbed_content',
        'params': {
          'tabs': [
            (!result.company.partial) ?
            {
              'id': 'general_information',
              'title': 'Company information',
              'status': 'active',
              'content': [
                { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                { 'key': 'Place', 'content' : result.company.address.city }, // 'Rotterdam',
                { 'key': 'Activities', 'content' : this.helper.parseActivities( result.company.businessActivities ), 'type': 'html' },
                // 'activities': [
                //   'SBI-code: 6420 - Financiële holdings',
                //   'SBI-code: 4110 - Projectontwikkeling'
                // ],
                { 'key': 'Address', 'content' : ((result.company.address.street !== undefined) ? (result.company.address.street + ' ') : '')
                          + ((result.company.address.houseNumber !== undefined) ? (result.company.address.houseNumber + ', ') : '')
                          + ((result.company.address.postalCode !== undefined) ? (result.company.address.postalCode + ' ') : '')
                          + ((result.company.address.city !== undefined) ? (result.company.address.city) : '') }
                          // 'Prins Bernhardplein 200, 1097JB Amsterdam',
                // 'postal_address': 'Petroleumweg 44, 3196KD Vondelingenplaat Rt'
              ]
            }
            :
            {
              'id': 'general_information',
              'title': 'Company information',
              'status': 'active',
              'displayLoader': true,
              'content': [
                { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                { 'key': 'Notification', 'content': 'Information cannot displayed at this time' },
              ]
            },
            // {
            //   'id': 'shareholders',
            //   'title': 'Shareholders',
            //   'status': '',
            //   'content': ''
            // }
          ]
        }
      },
      {
        'type': 'anchor',
        'params': {
          'content': 'See more detailed information about this company',
          'url': 'https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer='
                  + (result.company.kvkNumber
                  + '000000000000').substring(0, 12),
          'open_new_tab': true,
          'external': true
        }
      },
      {
        'type': 'hr',
        'params': {
          'class': 'result-hr',
          'display': (result.noOfFlags < 1) ? false : true,
        }
      },
      {
        'type': 'button_group',
        'display': (result.noOfFlags < 1) ? false : true,
        'params': [
          {
            'content': 'Re-open check',
            'action': 'OnReOpenIntegrityCheck',
            'display': (result.noOfFlags < 1) ? false : true,
          },
        ]
      },

    ]
    };
  }

  parseIntegrityRequests(requests) {
    // check if valid array:
    console.log(requests);
    if (!Array.isArray(requests) || (Array.isArray(requests) && requests.length < 1)) {
      console.log('ERROR: EMPTY RESULT SET WAS MET');
      return;
    }

    // set the group type
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center',
      'group_name' : 'Requests for information',
      // 'result_container_class': 'result-container my-2',
      'is_collapsable': true,
    };

    // parse the group blocks
    group['result_details'] = [];
    requests.forEach(request => {
      const result = request.record;
      // parse questions into list items
      const parsedListItems: any[] = [];
      console.log('result integrity request', result);
      result.questions.forEach(question => {
        parsedListItems.push({
          content: question.questionText,
          type: question.questionType
        });
      });
      console.log('#####REQUEST:', request);
      // for testing purposes only
      // result.identity = {
      //   "status": "IDENTITY_GRANTED",
      //   "organization": "org1"
      // };

      // Define Content
      group['result_details'].push({
        'id' : request.key,
        'result_container_class': 'result-container my-2',
        'kvk' : result.company.kvkNumber, // KVK Number
        'key' : request.key, // key string
        'identity' : (result.identity && result.identity.status === 'IDENTITY_GRANTED')
                    ? result.identity.organization
                    : '', // identity revealed
        'isNew': (request.record.status === 'open') ? true : false,
        'display_orange_dot': (request.record.status === 'open') ? true : false,
        'onArrowClickMethod': 'markRequestForInformationSeen',
        'additional_info': this.helper.formatISODate( result.createdOn ),
        'additional_info_classes': 'gray-date',
        'group_result_title' : 'group_2_result_' + result.company.kvkNumber + (new Date().getTime()),  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : result.company.businessName, // SELECTED COMPANY NAME!
        'result_caption' : 'A service provider needs additional information about one of your previous clients',
        'result_title_classes' : 'mt-0',
        'elements' : [{
          'type': 'unordered_list',
          'params': parsedListItems
        },

        {
          'type': 'button',
          'params': {
            'content': 'Submit Info',
            'action': (result.identity && result.identity.status === 'IDENTITY_REQUESTED')
                      ? 'OnConsentRevealIdentity'
                      : 'OnInitiateDisplaySubmitInfo',
            // for testing purposes ALWAYS ASK FOR CONSENT TO REVEAL IDENTITY
            // 'action': 'OnConsentRevealIdentity',
            'display': true
          }
        },
      ]
      });
    });

    this.groups.integrityRequests[0] = group;
    this.contentDOM = this.groups.integrityRequests;
    // console.log('THIS IS PASSED TO HTML', this.groups.integrityRequests);
    this.requests.integrityRequests = requests;
    this.cd.detectChanges();
  }


  OnButtonClick(event) {
    const method = event.target.getAttribute('clickmethod');
    if (typeof(method) === 'string' ) { // allow only strings as acceptable method name
      this[method](); // call it
    }
  }

  OnRequestInformation(event) {
    this.DisplayRequestInformation(event, false);
  }
  OnRequestInformationAskForIdentity(event) {
    this.DisplayRequestInformation(event, true);
  }
  /**
   * display modal dialog for requesting information about company integrity
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5c043e8bd829bb2c7225a67a
   */
  DisplayRequestInformation(event, askForIdentity = false) {
    // set the scrollTo destination
    this.integrityService.setScrollToKey($(event.target).closest('.result-container').attr('key'));
    // set the key for integrity check that we are requestion information for
    if (this.getIntegrityCheckKey(event.target) === '') {
      alert('Unexpected error');
      console.log('the integrity key could not be identified for selected integrity check');
      // do not allow to proceed
      return;
    }
    // check if there are already posted questions that need to be grayed out:
    const grayedOutQuestions = [];
    this.openIntegrityChecks.forEach(openIntegrityCheck => {
      if (openIntegrityCheck.key === this.getIntegrityCheckKey(event.target)) {
        // check if there is a request associated with this IC
        if (openIntegrityCheck.requests !== undefined) {
          openIntegrityCheck.requests.forEach(request => {
            if (request.record.questions !== undefined) {
              request.record.questions.forEach(question => {
                grayedOutQuestions.push(question.questionText);
              });
            }
          });
        }
      }
    });

    console.log('WE HAVE GRAYED OUT QUESTIONS: ', grayedOutQuestions);
    // draw the lightbox
    this.formType = 'Request for information';
    // don't use default lightbox buttons
    this.btnOkText = '';
    this.formValidator = 'true';
    this.btnCancelText = '';

    // set form title
    this.formTitle = $(event.target)
        .closest('.result-container')
        .find('.result-title > span:eq(0)')
        .text(); // this.integrityService.getCurrentCompanyName();

    const identityOrg = $(event.target)
        .closest('.result-container')
        .attr('identity');

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      // 'group_name' : 'Active Integrity Checks',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'submit_btn': {
        'type': 'submit',
        'params': {
          'form_id': 'request_information_form',
          'content': 'Send request',
          'action': 'OnSendRequestInformation',
          'formValidator': 'true',
          'display': true
        }
      },
      'result_details' : [
        {
        'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'paragraph',
          'params': {
            'content': 'The questions below will be forwarded to the previous service provider.'
          }
        },
          {
            'type': 'checkbox_group',
            'params': {
              // 'required': true,
              'checkboxes': [
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_1',
                    'name': 'integrity_risk_question_1',
                    'label': 'What was the nature of the integrity risk?',
                    'value': 'What was the nature of the integrity risk?',
                    'disabled': (grayedOutQuestions.includes('What was the nature of the integrity risk?')) ? true : false
                  }
                },
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_2',
                    'name': 'integrity_risk_question_2',
                    'label': 'What were the activities of the entity?',
                    'value': 'What were the activities of the entity?',
                    'disabled': (grayedOutQuestions.includes('What were the activities of the entity?')) ? true : false
                  }
                },
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_3',
                    'name': 'integrity_risk_question_3',
                    'label': 'Does the integrity risk relate to a person not directly related to the entity?',
                    'value': 'Does the integrity risk relate to a person not directly related to the entity?',
                    'disabled': (grayedOutQuestions.includes('Does the integrity risk relate to a person not directly related to the entity?')) ? true : false
                  }
                },
              ]
            }
          },
          {
            'type': 'reveal_on_click',  // the fields below will be hidden until the link has been clicked to show them
            'params': {
              'id': 'toggleAdditionalInfo',
              'content': '+ Request additional info',
              'contentElements': [
                  {
                    'type': 'paragraph',
                    'params': {
                      'content': 'What else would you like to know?'
                    }
                  },
                  {
                    'type': 'simple_textarea',
                    'params': {
                      'id': 'additional_info',
                      'name': 'additional_info',
                      'default_value' : '',
                    },
                  },
                ],
            }
          },
          {
            'type': 'checkbox',
            'params': {
              'id': 'integrity_risk_identity_reveal',
              'name': 'integrity_risk_identity_reveal',
              'label': 'Reveal your corporate identity to the counterparty, and request the counterparty reveal their theirs to you.',
              'class': 'font-weight-bold',
              'value': '1',
              'checked': true,
              'disabled': (grayedOutQuestions.includes('Does the integrity risk relate to a person not directly related to the entity?')) ? true : false,
              'display': !askForIdentity
            }
          },
          {
            'type': 'paragraphIcon',
            'params': {
              'display': askForIdentity,
              'class': 'font-weight-bold',
              // tslint:disable-next-line:max-line-length
              'content': 'You are requesting information from a verified counterparty: ' + identityOrg,
              'svgIcon': {
                'src': '/assets/images/prize-badge-with-star-and-ribbon-svgrepo-com.svg',
                // 'class': 'svg-orange',
                'width': 20,  // required
                'height': 16.9,  // required
                },
              },
              'displayHistory': false
          },
          {
            'type': 'custom_error_field',
            'params': {
              'id': 'request-info-custom-error'
            }
          },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.displayModal = true;
    this.lightboxContent.push(group);
    this.cd.detectChanges();
  }

  /**
   * fetch known email addresses for selected trust company
   * if response is not empty it will call OnRequestInformationFromExternalTrustKnownMailAddresses
   * otherwise it will call OnRequestInformationFromExternalTrustNoKnownMailAddresses
   */
  OnRequestInformationFromExternalTrust(event) {
    if (this.getIntegrityCheckKey(event.target) === '') {
      alert('Unexpected error');
      console.log('the integrity key could not be identified for selected integrity check');
      // do not allow to proceed
      return;
    }
    // check if there are already posted questions that need to be grayed out:
    console.log('MY KEY: ', this.requestIntegrityCheckForKey);
    // display dialog with base info and loader animation
    this.btnOkText = '';
    this.formValidator = 'true';
    this.btnCancelText = '';

    // set form title
    this.formTitle = $(event.target)
        .closest('.result-container')
        .find('.result-title > span:eq(0)')
        .text(); // this.integrityService.getCurrentCompanyName();

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      // 'group_name' : 'Active Integrity Checks',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'result_details' : [
        {
        'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : 'Fetching known contacts at service provider',
        'elements' : [
        {
          'type': 'paragraph',
          'params': {
            'content': 'Please stand by while we fetch known email addresses for the service provider'
          }
        },

      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.displayModal = true;
    this.lightboxContent.push(group);
    this.displayLoader = true;
    this.cd.detectChanges();
    const trcKey = $(event.target).closest('.result-container').attr('trcKey');
    if (trcKey !== undefined) {
      this.integrityService.fetchKnownEmailAddressesForExternalTrust(trcKey,
          $(event.target).closest('.result-container').attr('key'),
          $(event.target).closest('.result-container').find('.result-title span:eq(0)').text());
    }
  }

  /**
   * display modal dialog for requesting information about company integrity
   * last serviced by an external trust company outside of the finos platform
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5cc02d97d8f7b95389d90612
   */
  OnRequestInformationFromExternalTrustKnownMailAddresses(companyName) {
    // set the scrollTo destination
    // we don't need to scrollTo we are already there
    // this.integrityService.setScrollToKey($(event.target).closest('.result-container').attr('key'));
    // set the key for integrity check that we are requestion information for

    // check if there are already posted questions that need to be grayed out:
    const grayedOutQuestions = [];
    this.openIntegrityChecks.forEach(openIntegrityCheck => {
      if (openIntegrityCheck.key === this.requestIntegrityCheckForKey) {
        // check if there is a request associated with this IC
        if (openIntegrityCheck.requests !== undefined) {
          openIntegrityCheck.requests.forEach(request => {
            if (request.record.questions !== undefined) {
              request.record.questions.forEach(question => {
                grayedOutQuestions.push(question.questionText);
              });
            }
          });
        }
      }
    });

    console.log('WE HAVE GRAYED OUT QUESTIONS: ', grayedOutQuestions);
    // draw the lightbox
    this.formType = 'Request for information';
    // don't use default lightbox buttons
    this.btnOkText = '';
    this.formValidator = 'true';
    this.btnCancelText = '';

    // set form title
    this.formTitle = companyName; // this.integrityService.getCurrentCompanyName();

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      // 'group_name' : 'Active Integrity Checks',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'submit_btn': {
        'type': 'submit',
        'params': {
          'form_id': 'request_information_form',
          'content': 'Send request',
          'action': 'OnSendRequestInformationToExternalTrust',
          'formValidator': 'true',
          'display': true
        }
      },
      'result_details' : [
        {
        'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : 'Known contacts at service provider',
        'elements' : [
        {
          'type': 'readmore_content',
          'params': {
            'excerpt': 'Below are the known e-mail addresses for the service provider, which can be used to request information. Please select one or more addresses to send the request for information.',
            'readMoreContent': 'Read more',
            'content': 'Your request for information will be sent by email to a contact at the previous service provider. The recipient will receive a unique link, which they can use to indicate if this legal entity has any known integrity risks associated with it, as well as submit any additionally requested information. You will be alerted when the information is provided.'
          }
        },
        {
          'type': 'checkbox_group',
          'params': {
            // 'required': true,
            'checkboxes': [
              {
                'type': 'checkbox',
                'params': {
                  'id': 'integrity_risk_mail_address_1',
                  'name': 'integrity_risk_mail_1',
                  'label': 'main.e-mail@thetrustnotusing.com',
                  'value': 'main.e-mail@thetrustnotusing.com',
                  'floatlabel': 'Verified',
                  'disabled': false
                }
              },
              {
                'type': 'checkbox',
                'params': {
                  'id': 'integrity_risk_mail_address_2',
                  'name': 'integrity_risk_mail_2',
                  'label': 'other.e-mail@thetrustnotusing.com',
                  'value': 'other.e-mail@thetrustnotusing.com',
                  'disabled': false
                }
              },
              {
                'type': 'checkbox',
                'params': {
                  'id': 'integrity_risk_mail_address_3',
                  'name': 'integrity_risk_mail_3',
                  'label': 'and.another.e-mail@thetrustnotusing.com',
                  'value': 'and.another.e-mail@thetrustnotusing.com',
                  'disabled': false
                }
              },
            ]
          }
        },
        {
          'type': 'reveal_on_click',  // the fields below will be hidden until the link has been clicked to show them
          'params': {
            'id': 'toggleAdditionalEmail',
            'content': '+ Add an additional e-mail address',
            'contentElements': [
                {
                  'type': 'paragraph',
                  'params': {
                    'content': 'Enter an additional e-mail address'
                  }
                },
                {
                  'type': 'inputText',
                  'params': {
                    'id': 'additional_email',
                    'name': 'additional_email',
                    'default_value' : '',
                  },
                },
              ],
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'What would you like to know?',
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'The questions below will be forwarded to the previous service provider.'
          }
        },
        {
            'type': 'checkbox_group',
            'params': {
              // 'required': true,
              'checkboxes': [
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_1',
                    'name': 'integrity_risk_question_1',
                    'label': 'What was the nature of the integrity risk?',
                    'value': 'What was the nature of the integrity risk?',
                    'disabled': (grayedOutQuestions.includes('What was the nature of the integrity risk?')) ? true : false
                  }
                },
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_2',
                    'name': 'integrity_risk_question_2',
                    'label': 'What were the activities of the entity?',
                    'value': 'What were the activities of the entity?',
                    'disabled': (grayedOutQuestions.includes('What were the activities of the entity?')) ? true : false
                  }
                },
              ]
            }
          },
          {
            'type': 'reveal_on_click',  // the fields below will be hidden until the link has been clicked to show them
            'params': {
              'id': 'toggleAdditionalInfo',
              'content': '+ Request additional info',
              'contentElements': [
                  {
                    'type': 'paragraph',
                    'params': {
                      'content': 'What else would you like to know?'
                    }
                  },
                  {
                    'type': 'simple_textarea',
                    'params': {
                      'id': 'additional_info',
                      'name': 'additional_info',
                      'default_value' : '',
                    },
                  },
                ],
            }
          },
          {
            'type': 'checkbox',
            'params': {
              'id': 'integrity_risk_identity_reveal',
              'name': 'integrity_risk_identity_reveal',
              'label': 'I accept to reveal my identity to the counterparty, only if they also accept to reveal their identity to me',
              'value': '1',
              'checked': true,
              'disabled': (grayedOutQuestions.includes('Does the integrity risk relate to a person not directly related to the entity?')) ? true : false
            }
          },
          {
            'type': 'custom_error_field',
            'params': {
              'id': 'request-info-custom-error'
            }
          },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.displayModal = true;
    this.lightboxContent.push(group);
    this.cd.detectChanges();
  }

  /**
   * display modal dialog for requesting information about company integrity
   * last serviced by an external trust company outside of the finos platform
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5cc02d971d49f35456d8011b
   */
  OnRequestInformationFromExternalTrustNoKnownMailAddresses(companyName: string) {
    // set the scrollTo destination
    // we don't need to scrollTo we are already there
    // this.integrityService.setScrollToKey($(event.target).closest('.result-container').attr('key'));
    // set the key for integrity check that we are requestion information for

    // check if there are already posted questions that need to be grayed out:
    const grayedOutQuestions = [];
    this.openIntegrityChecks.forEach(openIntegrityCheck => {
      if (openIntegrityCheck.key === this.requestIntegrityCheckForKey) {
        // check if there is a request associated with this IC
        if (openIntegrityCheck.requests !== undefined) {
          openIntegrityCheck.requests.forEach(request => {
            if (request.record.questions !== undefined) {
              request.record.questions.forEach(question => {
                grayedOutQuestions.push(question.questionText);
              });
            }
          });
        }
      }
    });

    console.log('WE HAVE GRAYED OUT QUESTIONS: ', grayedOutQuestions);
    // draw the lightbox
    this.formType = 'Request for information';
    // don't use default lightbox buttons
    this.btnOkText = '';
    this.formValidator = 'true';
    this.btnCancelText = '';

    // set form title
    this.formTitle = companyName; // this.integrityService.getCurrentCompanyName();

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      // 'group_name' : 'Active Integrity Checks',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'submit_btn': {
        'type': 'submit',
        'params': {
          'form_id': 'request_information_form',
          'content': 'Send request',
          'action': 'OnSendRequestInformationToExternalTrustNoContacts',
          'formValidator': 'true',
          'display': true
        }
      },
      'result_details' : [
        {
        'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : 'No contacts available',
        'elements' : [
        {
          'type': 'readmore_content',
          'params': {
            'excerpt': 'Unfortunately there are no known contacts at the previous service provider. Please enter the e-mail address of your contact within '+ companyName,
            'readMoreContent': 'Read more',
            'content': 'Your request for information will be sent by email to a contact at the previous service provider. The recipient will receive a unique link, which they can use to indicate if this legal entity has any known integrity risks associated with it, as well as submit any additionally requested information. You will be alerted when the information is provided.'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'Enter e-mail address'
          }
        },
        {
          'type': 'inputText',
          'params': {
            'id': 'additional_email',
            'name': 'additional_email',
            'default_value' : '',
          },
        },
        {
          'type': 'h3',
          'params': {
            'content': 'What would you like to know?',
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'The questions below will be forwarded to the previous service provider.'
          }
        },
        {
            'type': 'checkbox_group',
            'params': {
              // 'required': true,
              'checkboxes': [
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_1',
                    'name': 'integrity_risk_question_1',
                    'label': 'What was the nature of the integrity risk?',
                    'value': 'What was the nature of the integrity risk?',
                    'disabled': (grayedOutQuestions.includes('What was the nature of the integrity risk?')) ? true : false
                  }
                },
                {
                  'type': 'checkbox',
                  'params': {
                    'id': 'integrity_risk_2',
                    'name': 'integrity_risk_question_2',
                    'label': 'What were the activities of the entity?',
                    'value': 'What were the activities of the entity?',
                    'disabled': (grayedOutQuestions.includes('What were the activities of the entity?')) ? true : false
                  }
                },
              ]
            }
          },
          {
            'type': 'reveal_on_click',  // the fields below will be hidden until the link has been clicked to show them
            'params': {
              'id': 'toggleAdditionalInfo',
              'content': '+ Request additional info',
              'contentElements': [
                  {
                    'type': 'paragraph',
                    'params': {
                      'content': 'What else would you like to know?'
                    }
                  },
                  {
                    'type': 'simple_textarea',
                    'params': {
                      'id': 'additional_info',
                      'name': 'additional_info',
                      'default_value' : '',
                    },
                  },
                ],
            }
          },
          {
            'type': 'checkbox',
            'params': {
              'id': 'integrity_risk_identity_reveal',
              'name': 'integrity_risk_identity_reveal',
              'label': 'I accept to reveal my identity to the counterparty, only if they also accept to reveal their identity to me',
              'value': '1',
              'checked': true,
              'disabled': (grayedOutQuestions.includes('Does the integrity risk relate to a person not directly related to the entity?')) ? true : false
            }
          },
          {
            'type': 'custom_error_field',
            'params': {
              'id': 'request-info-custom-error'
            }
          },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.displayModal = true;
    this.lightboxContent.push(group);
    this.cd.detectChanges();
  }

  /**
   * display modal dialog for logging activity about company integrity
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5c043e8c60866701d3d69f0b
   */
  OnLogActivity() {
    // set scroll to destination
    // this.integrityService.setScrollToId($(event.target).closest('.result-container').attr('id'));
    this.integrityService.setScrollToKey($(event.target).closest('.result-container').attr('key'));

    if (this.getIntegrityCheckKey(event.target) === '') {
      alert('Unexpected error');
      console.log('the integrity key could not be identified for selected integrity check');
      // do not allow to proceed
      return;
    }

    // set form title
    this.formTitle = $(event.target)
        .closest('.result-container')
        .find('.result-title > span:eq(0)')
        .text();

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      // 'group_name' : 'Active Integrity Checks',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'request_information_form',
      'submit_btn': {
        'type': 'submit',
        'params': {
          'content': 'Log Activity',
          'action': 'OnLogRequestedActivity',
          'display': true
        }
      },
      'result_details' : [
        {
        'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_title' : 'What activity would you like to log?',
        'elements' : [

        // BEGINING OF FORM
          {
            'type': 'radiobutton_group',
            'params': [
              {
                'type': 'radio',
                'params': {
                  'id': 'phone_call',
                  'name': 'activity',
                  'label': 'Phone Call',
                  'value': 'Phone Call'
                }
              },
              {
                'type': 'radio',
                'params': {
                  'id': 'face_to_face',
                  'name': 'activity',
                  'label': 'Face to face meeting',
                  'value': 'Face to face meeting'
                }
              }
            ]
          },
          {
            'type': 'input_date',
            'params': {
              'id': 'date_of_activity',
              'name': 'date_of_activity',
              'label': 'Date of activity',
              'default_value': '',
            }
          },
          {
            'type': 'paragraph',
            'params': {
              'content': 'What is the information that you would like to log?',
            }
          },
          {
            'type': 'textarea',
            'params': {
              'id': 'additional_info',
              'name': 'additional_info',
              'default_value' : '',
            }
          },
          // EOF elements of form
        // EOF FORM

      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.displayModal = true;
    this.cd.detectChanges();

  }

  /**
   * display modal dialog for ending integirty check
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5c2f746e59561eb036cecf7f
   */
  OnEndIntegrityCheck(event) {
    console.log(event.target);
    if (this.getIntegrityCheckKey(event.target) === '') {
      alert('Unexpected error');
      console.log('the integrity key could not be identified for selected integrity check');
      // do not allow to proceed
      return;
    }
    // set the endIntegrityCheckKey
    this.endIntegrityCheckKey = this.getIntegrityCheckKey(event.target);
    // set form type
    this.formType = 'End Integrity Check';
    // set form title
    this.formTitle = $(event.target)
      .closest('.result-container')
      .find('.result-title > span:eq(0)')
      .text();

    // build the content for the Request Information light box
    const group = {
    'section_class' : 'results d-flex flex-column justify-items-center p-0',
    'result_container_class': '',
    'is_collapsable': false,
    'form_id': 'request_information_form',
    'submit_btn': {
      'type': 'submit',
      'params': {
        'content': 'End integrity check',
        'action': 'OnEndIntegrityCheckConfirm',
        'className': 'form-button-2',
        'display': true
      }
    },
    'cancel_btn': {
      'type': 'submit',
      'params': {
        'content': 'Keep active',
        'action': 'OnEndIntegrityCheckCancel',
        'className': 'form-button-1',
        'display': true
      }
    },
    'result_details' : [
    {
    'group_result_title' : 'lighbox-end-integrity-check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
    'result_title' : 'Are you sure you want to end the integrity check?',
    'elements' : [

    // BEGINING OF FORM
      {
        'type': 'paragraph',
        'params': {
          'content': 'You are about to close the integrity check and will not be able to take any more actions on it. You can re-open it at any time by going to your closed integrity checks.',
        }
      },
      // EOF elements of form
    // EOF FORM

    ]
    }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.displayModal = true;
    this.cd.detectChanges();
  }

  getIntegrityCheckKey(element) {
    this.requestIntegrityCheckForKey = $(element).closest('.result-container').attr('key');
    return this.requestIntegrityCheckForKey;
  }

  getIntegrityCheckKvk(element) {
    this.requestIntegrityCheckForKvkNumber = $(element).closest('.result-container').attr('kvk');
    return this.requestIntegrityCheckForKvkNumber;
  }

  /**
   * it will initiate display modal dialog for logging activity about company integrity
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5c043e8fc0df7501f121a356
   */
  OnInitiateDisplaySubmitInfo(event, companyTitle: string = null, askingCompanyIdentity: string  = null) {
    // this.formType   = (askingCompanyIdentity !== null)
    //                   ? 'Information requested by ' + askingCompanyIdentity
    //                   : 'Request for Information';
    this.formType   = 'Request for Information';
    let targetKey   = this.integrityService.getInformationRequestKey();
    this.formTitle  = companyTitle;
    if (event !== null) {
      // if this was directly clicked override targetKey with the key of the clicked request for information
      targetKey = this.postinformationRequestKey = $(event.target).closest('.result-container').attr('id');
      // set form title
      this.formTitle = $(event.target)
        .closest('.result-container')
        .find('.result-title > span:eq(0)')
        .text();
      if (askingCompanyIdentity === null) {
        askingCompanyIdentity = $(event.target)
                                  .closest('.result-container')
                                  .attr('identity');
      }
    }

    // iterate through all this.groups.integrityRequests
    // find the group that is clicked
    // parse the questions and display the form for that group
    this.requests.integrityRequests.forEach(request => {
      if (request.key === targetKey) {

        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_form',
          'submit_btn': {
            'type': 'submit',
            'params': {
              'form_id': 'request_information_form',
              'content': 'Submit Info',
              'action': 'OnSubmitRequestedInfo',
              'display': true
            }
          },
          'cancel_btn': {
            'type': 'submit',
            'params': {
              'form_id': 'request_information_form',
              'content': 'Deny request',
              'action': 'OnDenyRequest',
              'display': true
            }
          },
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'result_title' : 'Provide information regarding integrity risk',
            'elements' : [
            {
              'type': 'paragraphIcon',
              'params': {
                'display': (askingCompanyIdentity
                            && askingCompanyIdentity !== null
                            && askingCompanyIdentity !== ''),
                'class': '',
                // tslint:disable-next-line:max-line-length
                'content': 'You are providing information to a verified counterparty: ' + askingCompanyIdentity,
                'svgIcon': {
                  'src': '/assets/images/prize-badge-with-star-and-ribbon-svgrepo-com.svg',
                  // 'class': 'svg-orange',
                  'width': 20,  // required
                  'height': 16.9,  // required
                  },
                },
                'displayHistory': false
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'A service provider needs your information about one of your previous client entities'
              }
            },
            {
              'type': 'paragraph',
              'params': {
                'content': 'Please answer the following questions. The answers will be sent to the service provider.'
              }
            },
            // FORM will be appended here
            // END OF FORM
          ]
          }]
        };

        const result = request.record;
        // parse questions into list items with form elements
        const parsedListItems: any = group.result_details[0].elements;
        // console.log('questions: ', result.questions);
        result.questions.forEach(question => {
          switch (question.questionType) {
            case 'text':
              // push the question it self as label
              // parsedListItems.push({
              //   'type': 'paragraph',
              //   'params': {
              //     'content': question.questionText
              //   }
              // });
              // push the form element
              parsedListItems.push({
                'type': 'textarea',
                'params': {
                  'label' : question.questionText,
                  'content': '',
                  'name': question.key
                }
              });
              break;

            default:
                // push dummy element to display message that it needs to be handled
                parsedListItems.push({
                  'type': 'dummy',
                });
              break;
          }
        });

        // group.result_details[0].elements.push(parsedListItems);

        // set the submit buttons
        // parsedListItems.push({
        //   'type': 'submit',
        //   'params': {
        //     'content': 'Submit Info',
        //     'action': 'OnSubmitRequestedInfo',
        //     'display': true
        //   }
        // }),

        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnOkText = '';
        this.formValidator = '';
        this.btnCancelText = '';
        this.displayModal = true;
        // this.cd.detectChanges();
      }
    });
  }

  /**
   * it will initiate display modal dialog for consenting to reveal identity on answering side
   */
  OnConsentRevealIdentity(event) {
    const targetKey = this.postinformationRequestKey = $(event.target).closest('.result-container').attr('id');
    this.integrityService.setInformationRequestData({   'key': targetKey
                                                      , 'company': this.formTitle = $(event.target)
                                                          .closest('.result-container')
                                                          .find('.result-title > span:eq(0)')
                                                          .text()});
    // iterate through all this.groups.integrityRequests
    // find the group that is clicked
    // parse the questions and display the form for that group
    this.requests.integrityRequests.forEach(request => {
      if (request.key === targetKey) {
        // set form title
        this.formTitle = $(event.target)
          .closest('.result-container')
          .find('.result-title > span:eq(0)')
          .text();

        // build the content for the Request Information light box
        const group = {
          'section_class' : 'results d-flex flex-column justify-items-center p-0',
          // 'group_name' : 'Active Integrity Checks',
          'result_container_class': '',
          'is_collapsable': false,
          'form_id': 'request_information_consent_idenity_form',
          'submit_btn': {
            'type': 'submit',
            'params': {
              'form_id': 'request_information_consent_idenity_form',
              'content': 'Reveal identity',
              'action': 'OnGrantIdentityConsent',
              'display': true
            }
          },
          'cancel_btn': {
            'type': 'submit',
            'params': {
              'form_id': 'request_information_form',
              'content': 'Deny request',
              'action': 'OnDenyIdentityConsent',
              'display': true
            }
          },
          'result_details' : [
            {
            'group_result_title' : 'lighbox-request-information',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            'result_title' : 'Reveal your identity to the asking party',
            'elements' : [
            // {
            //   'type': 'paragraph',
            //   'params': {
            //     'content': 'The asking party has asked to reveal your identity and agreed to do the same should you accept!'
            //   }
            // },
            {
              'type': 'paragraph',
              'params': {
                'content': 'A service provider has requested you reveal your corporate identity to them, and has offered to mutually reveal theirs to you. If you decline, neither party will reveal their corporate identity. Do you agree to reveal your identity?'
              }
            },
            // FORM will be appended here
            // END OF FORM
          ]
          }]
        };

        // set the content to the lightbox
        this.lightboxContent = [];
        this.lightboxContent.push(group);
        this.btnOkText = '';
        this.formValidator = '';
        this.btnCancelText = '';
        this.displayModal = true;
        // this.cd.detectChanges();
      }
    });
  }

  /**
   * grant consent to reveal identity from answering side
   */
  OnGrantIdentityConsent() {
    this.displayLoader = true;
    this.integrityService.initiateGrantIdentityConsent(this.integrityService.getInformationRequestKey());
  }

  /**
   * the answering party doesn't consent to reveal it's identity
   */
  OnDenyIdentityConsent() {
    this.displayLoader = true;
    this.integrityService.initiateDenyIdentityConsent(this.integrityService.getInformationRequestKey());
  }

  /**
   * it will parse the companies flagged by me
   * @param flags: {}
   */
  parseFlaggedCompanies(flags) {

    // check if valid array:
    if (!Array.isArray(flags) || (Array.isArray(flags) && flags.length < 1)) {
      console.log('ERROR: EMPTY RESULT SET WAS MET');
      return;
    }

    // set the group type
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center',
      'group_name' : 'Flagged Companies',
      'is_collapsable': true,
    };

    console.log('FLAGS: ', flags);
    // parse the group blocks
    group['result_details'] = [];
    flags.forEach(flag => {
      // parse answers from previous companies
      const parsedRequests = [];
      parsedRequests.push({
        'type': 'title',
        'params': {
          'content': 'Info provided by previous service provider'
        }
      });
      if (typeof flag.requests !== 'undefined') {
        flag.requests.forEach(request => {
          const parsedListItems = [];
          request.record.questions.forEach(question => {
            parsedListItems.push({
              'content': question.questionText,
              'additional_content': (typeof question.answer !== 'undefined' )
                  ? question.answer.record
                  : 'MANUALY ASSINGNED ANSWER SINCE IT IS MISSING IN THE API',
              'type': question.questionType
            });
          });
          parsedRequests.push({
            'type' : 'unordered_list',
            'params': parsedListItems
          });
        });
      }

      // Define Content
      const result = flag.record;
      group['result_details'].push({
        'id' : flag.key, // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'result_container_class': 'result-container my-2',
        'kvk' : result.company.kvkNumber, // KVK Number
        'key' : flag.key, // key string
        'isPartial' : result.company.partial,
        'isNew':  (flag.key === this.integrityService.getCurrentCompanyKey()
                  || flag.key === this.integrityService.getScrollToKey())
                  ? false
                  : true,
        'group_result_title' : 'group_2_result_' + result.company.kvkNumber + (new Date().getTime()),
        'additional_info': this.helper.formatISODate( result.createdOn ),
        'additional_info_classes': 'gray-date',
        'result_title' : (!result.company.partial) ? result.company.businessName : result.company.kvkNumber, // SELECTED COMPANY NAME!
        'result_caption' : 'You have flagged this legal entity',
        'result_title_classes' : 'mt-0',
        'elements' : [{
          'type': 'alert',
          'params': {
            // set to alert-container-1 if green alert needs to be displayed
            'alert_type': (result.noOfFlags < 1) ? 'alert-container-1'  : 'alert-container-2',
            'alert_image_class': 'icon-thick',
            'alert_title': 'Flag submitted',
            'alert_title_class': 'mb-0',
            'alert_date': (result.createdOn) ? this.helper.convertToDate(result.createdOn) : '',
            'alert_content':
              'You have successfully flagged this entity. The information you provided below will be visible to other service providers'
          }
        },
        {
          'type': 'title',
          'params': {
            'content': 'Information provided',
            'display': true
          }
        },
        // {
        //   'type': 'subtitle',
        //   'params': {
        //     'content': 'Most recent relationship for this entity:',
        //     'display': (result.serviceStartDate !== '' && result.serviceEndDate !== '') ? true : false
        //   }
        // },
        {
          'type': 'most_recent_relationship',
          'params': {
            'startdate': (result.serviceStartDate) ? this.helper.convertToDate(result.serviceStartDate) : '', // '14th October 2017',
            'enddate': (result.serviceEndDate) ? this.helper.convertToDate(result.serviceEndDate) : '', // 22nd February 2018',
          }
        },
        {
          'type': 'contentBuilder',
          'display': false,
          'params': [{
            'section_class' : 'd-flex flex-column p-0',
            // 'group_name' : 'TEST',
            'result_container_class': '', // result-container
            'is_collapsable': false,
            'result_details' : [{
              'group_result_title' : '',
              'result_title' : '',
              'elements' : parsedRequests
            }],
          }]
        },
        {
          'type': 'title',
          'params': {
            'content': 'Latest KVK Information'
          }
        },
        {
          'type': 'tabbed_content',
          'params': {
            'tabs': [
              (!result.company.partial) ?
              {
                'id': 'general_information',
                'title': 'Company information',
                'status': 'active',
                'content': [
                  { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                  { 'key': 'Place', 'content' : result.company.address.city }, // 'Rotterdam',
                  { 'key': 'Activities', 'content' : this.helper.parseActivities( result.company.businessActivities ), 'type': 'html' },
                  // 'activities': [
                  //   'SBI-code: 6420 - Financiële holdings',
                  //   'SBI-code: 4110 - Projectontwikkeling'
                  // ],
                  { 'key': 'Address', 'content' : ((result.company.address.street !== undefined) ? (result.company.address.street + ' ') : '')
                            + ((result.company.address.houseNumber !== undefined) ? (result.company.address.houseNumber + ', ') : '')
                            + ((result.company.address.postalCode !== undefined) ? (result.company.address.postalCode + ' ') : '')
                            + ((result.company.address.city !== undefined) ? (result.company.address.city) : '') }
                            // 'Prins Bernhardplein 200, 1097JB Amsterdam',
                  // 'postal_address': 'Petroleumweg 44, 3196KD Vondelingenplaat Rt'
                ]
              }
              :
              {
                'id': 'general_information',
                'title': 'Company information',
                'status': 'active',
                'displayLoader': true,
                'content': [
                  { 'key': 'Kvk number', 'content' : result.company.kvkNumber }, // '22517301',
                  { 'key': 'Notification', 'content': 'Information cannot displayed at this time' },
                ]
              },
              // {
              //   'id': 'shareholders',
              //   'title': 'Shareholders',
              //   'status': '',
              //   'content': ''
              // }
            ]
          }
        },
        {
          'type': 'anchor',
          'params': {
            'content': 'See more detailed information about this company',
            'url': 'https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer='
                    + (result.company.kvkNumber
                    + '000000000000').substring(0, 12),
            'open_new_tab': true,
            'external': true
          }
        },
      ]
      });
    });

    console.log('************FLAGGED COMPANIES GROUP', group);

    this.groups.flaggedCompanies[0] = group;
    this.contentDOM = this.groups.flaggedCompanies;
    // this.cd.detectChanges();
    // this.scrollToElementWithId(this.integrityService.getCurrentCompanyKvkNumber());
    this.scrollToElementWithKey('', 'Flagged Companies');
  }



  /**
   * Executed when company is selected from autosuggest box and/or perform search button is clicked
   * @param companyDetails array
   */
  OnSelectedCompanyChange(companyDetails) {
    // console.log('ACTIVE USER PREFERENCES', this.integrityService.getUserPreference('showIntegrityCheckRecordConfirmDialog'));
    // check if I should display the message or directly perform the postIntegrityCheck()
    if (this.integrityService.getUserPreference('showIntegrityCheckRecordConfirmDialog') === false) {
      // display a loading lightbox (but no confirmation message)
      this.formTitle = companyDetails.companyName;
      this.integrityService.setCurrentCompany(companyDetails);
      this.formType = 'Perform Integrity Check';
      this.formTitle = ''; // 'Financial Holdings B.V';
      this.btnOkText = '';
      this.formValidator = '';
      this.btnCancelText = '';
      this.btnOkEnabled = false;
      this.btnCancelEnabled = false;

      // set form title
      this.formTitle = this.integrityService.getCurrentCompanyName();

      // build the content for the Request Information light box
      const group = {
        'section_class' : 'results d-flex flex-column justify-items-center p-0',
        'result_container_class': '',
        'is_collapsable': false,
        'form_id': 'loading_dialog_box',
        'result_details' : [
          {
          'group_result_title' : 'loading_dialog_box',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          'elements' : [
          {
            'type': 'image',
            'params': {
              'src': 'assets/images/integrity_check_image.png'
            }
          },
          {
            'type': 'h3',
            'params': {
              'content': 'Please stand by while we do the integrity check for you ...'
            }
          },
        ]
        }]
      };
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
      this.displayLoader = true;
      this.cd.detectChanges();

      // make the integrity Check
      this.integrityService.postIntegrityCheck();
      // don't display the message lighbox
      return;
    }
    // display the confirmation  message:
    this.formTitle = companyDetails.companyName;
    this.integrityService.setCurrentCompany(companyDetails);
    this.formType = 'Perform Integrity Check';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = 'Agree';
    this.formValidator = '';
    this.btnCancelText = 'Disagree';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;

    // set form title
    this.formTitle = this.integrityService.getCurrentCompanyName();

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        // 'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'image',
          'params': {
            'src': 'assets/images/integrity_check_image.png'
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'Be aware ...'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'By performing an integrity check you agree that all actions you take on this entity from this point forward will be recorded for future retrieval by anyone within your organisation'
          }
        },
        {
          'type': 'checkbox',
          'params': {
            'id': 'hide-message',
            'name': 'hide-message',
            'checked': true,
            'label': 'Don\'t show this message again',
          }
        },
        // BEGINING OF FORM
        // disable this for now
        // {
        //   'type': 'checkbox',
        //   'params': {
        //     'id': 'dont_show_message',
        //     'name': 'dont_show_message',
        //     'label': 'Don\'t show this message again',
        //     'value': '1'
        //   }
        // },
        // EOF elements of form

        // don't submit for now
        // {
        //   'type': 'submit',
        //   'params': {
        //     'content': 'Send request',
        //     'action': 'OnSendRequestInformation',
        //     'display': true
        //   }
        // },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  onSearchTermChanged(event) {
    if (event === '') {
      this.kvkResults = null;
      this.cd.detectChanges();
      return;
    }
    this.integrityService.getIntegrityCheckResults(event)
      .subscribe((data: any) => {
        // TEMPORARY SET AS IF 503 STATUS:
        // this.integrityService.setKvkStatusFailed(true);
        // return;
        console.log('this is the data from subscription to searchCompany', data);
        if (data === undefined || data.length === 0) {
          data = null;
        }
        this.kvkResults = data.companies;
        setTimeout(function() {
          $('.autosuggest-container').niceScroll({
            cursorcolor: '#105f6b',
            cursorwidth: '4px',
            cursoropacitymin: 1,
            railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
          });
        }, 150);
        this.cd.detectChanges();
        },
        error => {
          if (error.status === 503) {
            this.integrityService.setKvkStatusFailed(true, 'headerSearch');
          } else {
            console.log('this is the ERROR from subscription to searchCompany', error);
          }
        }
      );
  }

  onLightboxConfirm(event) {
    if (typeof(event.method) === 'string' && event.method !== '') { // allow only strings as acceptable method name
      let params = '';
      if (Array.isArray(event.params)) {
        params = event.params;
      }
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(event.method + ' needs to be defined before it is called', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    // this.router.navigateByUrl('integrity-check/dashboard');
    if (typeof(event.method) === 'undefined' ) { // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
      this.integrityService.postIntegrityCheck(this.integrityService.getCurrentCompany().showMessage);
    }
  }

  onLightboxClose() {
    this.displayModal = false;
  }

  OnSendRequestInformation(params) {
    const filtered = [];
    let revealIdentity = false;
    params.forEach(param => {
      if (param.name === 'integrity_risk_identity_reveal') {
        revealIdentity = true;
      } else if (param.value !== '') {
        filtered.push(param);
      }
    });
    if (filtered.length === 0 ) {
      $('#toggleAdditionalInfo').show();
      this.UIservice.setInvalidInput( $('.checkbox-container'), 'Please check at least one question or populate the text field below');
      //this.UIservice.validateInput( $('.text-input-div'));
      this.UIservice.setInvalidInput( $('.text-input-div'), '');
      // $('#request-info-custom-error').addClass('invalid-input').attr('validatormessage', 'Please check at least one question or populate the text field below');
      return;
    }
    this.displayModal = true;
    this.displayLoader = true;
    console.log('I will pass the following params: ', filtered);
    // return;
    this.integrityService.requestIntegrityCheck(filtered, this.requestIntegrityCheckForKey, revealIdentity);
  }

  OnSendRequestInformationToExternalTrust(params) {
    const filtered = [];
    const filteredg1 = [];
    const filteredg2 = [];
    params.forEach(param => {
      if ((param.name === 'additional_email'
            || param.name.indexOf('integrity_risk_mail') !== -1)
          && param.value !== '') {
        filtered['emails'].push(param.value);
        filteredg1.push(param);
      }
      if ((param.name === 'additional_info'
            || param.name.indexOf('integrity_risk_question') !== -1)
          && param.value !== '') {
        filtered.push(param);
        filteredg2.push(param);
      }
    });
    if (filtered.length === 0) {
      $('#toggleAdditionalEmail').show();
      $('#toggleAdditionalInfo').show();
      this.UIservice.setInvalidInput( $('.checkbox-container'),
                                        'Please check at least one question or populate the text field below');
      this.UIservice.setInvalidInput( $('.text-input-div'), '');
      this.UIservice.setInvalidInput( $('#additional_email'), '');
      this.scrollToLightboxElement($('.checkbox-container:eq(0)'));
      return;
    }
    if (filteredg1.length === 0) {
      $('#toggleAdditionalEmail').show();
      this.UIservice.setInvalidInput( $('.checkbox-container:eq(0)'),
                                      'Please check at least one question or populate the text field below');
      this.UIservice.setInvalidInput( $('#additional_email'), '');
      this.scrollToLightboxElement($('.checkbox-container:eq(0)'));
      return;
    }
    if (filteredg2.length === 0) {
      $('#toggleAdditionalInfo').show();
      this.UIservice.setInvalidInput( $('.checkbox-container:eq(1)'),
                                      'Please check at least one question or populate the text field below');
      this.UIservice.setInvalidInput( $('.text-input-div'), '');
      this.scrollToLightboxElement($('.checkbox-container:eq(1)'));
      return;
    }
    this.displayModal = true;
    this.displayLoader = true;
    console.log('I will pass the following params: ', filtered);
    // TODO: connect sending request for information to external trust company to BE
    return;
    this.integrityService.requestIntegrityCheck(filtered, this.requestIntegrityCheckForKey);
  }

  OnSendRequestInformationToExternalTrustNoContacts(params) {
    const filtered = [];
    const filteredg1 = [];
    const filteredg2 = [];

    params.forEach(param => {
      if ((param.name === 'additional_email'
            || param.name.indexOf('integrity_risk_mail') !== -1)
          && param.value !== '') {
        filtered.push(param);
        filteredg1.push(param);
      }
      if ((param.name === 'additional_info'
            || param.name.indexOf('integrity_risk_question') !== -1)
          && param.value !== '') {
        filtered.push(param);
        filteredg2.push(param);
      }
    });

    if (filtered.length === 0) {
      $('#toggleAdditionalEmail').show();
      $('#toggleAdditionalInfo').show();
      this.UIservice.setInvalidInput( $('.checkbox-container'),
                                        'Please check at least one question or populate the text field below');
      this.UIservice.setInvalidInput( $('.text-input-div'), '');
      this.UIservice.setInvalidInput( $('#additional_email'), '');
      this.scrollToLightboxElement($('additional_email'));
      return;
    }
    if (filteredg1.length === 0) {
      $('#toggleAdditionalEmail').show();
      this.UIservice.setInvalidInput( $('#additional_email'), 'Please provide a service provider email address');
      this.scrollToLightboxElement($('additional_email'));
      return;
    }
    if (filteredg2.length === 0) {
      $('#toggleAdditionalInfo').show();
      this.UIservice.setInvalidInput( $('.checkbox-container'),
                                      'Please check at least one question or populate the text field below');
      this.UIservice.setInvalidInput( $('.text-input-div'), '');
      this.scrollToLightboxElement($('.checkbox-container'));
      return;
    }
    this.displayModal = true;
    this.displayLoader = true;
    this.integrityService.requestIntegrityCheck(filtered, this.requestIntegrityCheckForKey);
  }

  OnConfirmViewExistingCheck() {
    this.formType = 'Perform Integrity Check';
    this.formTitle = this.integrityService.getCurrentCompanyName();
    this.btnOkText = '';
    this.formValidator = '';
    this.btnCancelText = '';
    this.btnOkEnabled = false;
    this.btnCancelEnabled = false;

    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        // 'result_title' : 'What would you like to know?',
        'elements' : [
        {
          'type': 'image',
          'params': {
            'src': 'assets/images/arrow_already_there_for_you.png'
          }
        },
        {
          'type': 'h3',
          'params': {
            'content': 'Already there for you.'
          }
        },
        {
          'type': 'paragraph',
          'params': {
            'content': 'You have an active integrity check on this legal entity.'
          }
        },
        {
          'type': 'button_group',
          'params': [
            {
              'content': 'Go to existing check',
              'action': 'OnGoToExistingCheck',
              'display': true
            },
            {
              'content': 'Cancel',
              'action': 'OnCancelToExistingCheck',
              'class': 'form-button-gray',
              'display': true
            }
          ]
        },
      ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
    this.cd.detectChanges();
  }

  OnGoToExistingCheck() {
    if (this.refreshType === 'open') {
      // refresh active integrity checks
      this.integrityService.getIntegrityChecks('open');
    } else {
      // refresh closed integrity checks
      this.integrityService.getIntegrityChecks('closed');
    }
    this.displayLoader = false; // hide the loading screen
    this.displayModal = false;
    this.integrityService.scrollToSelectedCompany();
    this.router.navigateByUrl('integrity-check/dashboard');
  }

  OnCancelToExistingCheck() {
    this.refreshType = '';
    this.displayLoader = false; // hide the loading screen
    this.displayModal = false;
    this.integrityService.clearCurrentCompany();
    this.integrityService.setScrollToKey(null);
    this.router.navigateByUrl('integrity-check/dashboard');
  }

  OnEndIntegrityCheckConfirm() {
    this.displayLoader = true; // show the loading screen
    this.integrityService.setCurrentCompany({'kvkNumber' : this.requestIntegrityCheckForKvkNumber });
    this.integrityService.endIntegrityCheck(this.endIntegrityCheckKey);
  }

  OnEndIntegrityCheckCancel() {
    this.refreshType = '';
    // reset the endIntegrityCheckKey
    this.endIntegrityCheckKey = null;
    this.displayLoader = false; // hide the loading screen
    this.displayModal = false;
  }

  /**
   * call this method to finally log the activity for integrity check
   * @param params any[]
   */
  OnLogRequestedActivity(params)  {
    // handle the date:
    // params.push({'dateOfActivity': $('input[ng-reflect-name=date_of_activity]').val()});
    // parse the params:
    const outputParams = {
      'type': (params[0].value === 'Phone Call') ? 'call' : 'face',
      'dateOfActivity': $('#date_of_activity > input').val(),
      'loggedActivity': params[1].value
    };
    // console.log('I will send the following params to logging endpoint when ready: ', outputParams);
    // return;
    this.displayLoader = true;
    this.integrityService.logIntegrityCheck(outputParams, this.requestIntegrityCheckForKey);
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
  }

  /**
   * call when submitting answers to integrityRequest questions
   */
  OnSubmitRequestedInfo(params) {
    const answers = [];
    params.forEach(answer => {
      answers.push({
        'questionKey': answer.name,
        'answerText': answer.value
      });
    });
    console.log(answers);
    this.integrityService.submitAnswersToRequestedInfo({'answers': answers}, this.postinformationRequestKey);
    this.displayModal = true;
    this.displayLoader = true;
  }

  /**
   * call when rejecting the reqest for information
   */
  OnDenyRequest(params) {
    this.integrityService.rejectRequestedInfo(this.postinformationRequestKey);
    this.displayModal = true;
    this.displayLoader = true;
  }

  /**
   * it will perform an actual scroll to element with set key or set ID
   * @param key: string, if not passed it will attempt to fallback to scrollToKey property of the integrity Service
   */
  scrollToElementWithKey(key = '', targetGroup = '') {
    // if no key is passed then use the scrollToKey property from the integrity Service
    if (key === '') {
      key = this.integrityService.getScrollToKey();
    }
    const _this = this;

    setTimeout(function() {
      // the isScrolled parameter is depercated for now
      // if the window is already scrolled to the element
      // it will simply stay at that position
      // but since it will mess with the user scroll this is brougth
      // back in use, it is now handled through integrityService
      const isScrolled = _this.integrityService.getIsScrolled();
      if (!isScrolled && key !== '' && ($('.result-container[key="' + key + '"]').length) > 0) {
      // if (key !== '' && ($('.result-container[key="' + key + '"]').length) > 0) {
        _this.integrityService.setIsScrolled(true);
        $('html, body').animate({
            scrollTop: $('.result-container[key="' + key + '"]').offset().top
        }, 800);
        // TODO: also animate the arrow
        // this.animateArrow(key, targetGroup); - not working
      }
    }, 120);
  }

  scrollToLightboxElement(htmlElement) {
    $('.form-lightbox').animate({
      scrollTop: htmlElement.offset().top - $('.form-lightbox').offset().top
  }, 800);
  }

  /**
   * DEPERCATED
   * we will no longer support scrollTo by ID
   * it will perform an actual scroll to element with set ID
   * @param id: string, if not passed it will attempt to fallback to scrollToId property of the integrity Service
   */
  // scrollToElementWithId(id = '') {
  //   // if no id is passed then use the scrollToId property from the integrity Service
  //   if (id === '') {
  //     id = this.integrityService.getScrollToId();
  //   }

  //   if (!this.isScrolled && id !== '' && ($('#' + id).length) > 0) {
  //     this.isScrolled = true;
  //     $('html, body').animate({
  //         scrollTop: $('#' + id).offset().top
  //     }, 800);
  //   }
  // }

  /**
   * it will animate the arrow on scrollToElement With Key or ID
   * @param targetGroup string, the title of the targeted group, can be
   *  Active Integrity Checks
   *  Closed Integrity Checks
   *  Flagged Companies
   * @param key the key of the box we are scrolling to
   */
  animateArrow(targetGroup, key) {
    this.el.nativeElement.querySelector('#' + 'ICE\\:0f7b4080-75c4-4fe9-a9b4-cec616eae4de > a').classList.add('button-collapse');
  }



  /**
   * Initialize re-open closed integrity check
   * @param event: event
   */
  OnReOpenIntegrityCheck(event) {
    event.preventDefault();
    // display lightbox
    this.formType = 'Re-open Integrity';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';
    this.formValidator = '';
    // this.btnCancelText = 'Disagree';
    // build the content for the Request Information light box
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'perform_integrity_check',
      'result_details' : [
        {
        'group_result_title' : 'perform_reopen_integrity_check',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'h3',
            'params': {
              'content': 'Re-open integrity check'
            }
          },
          {
            'type': 'paragraph',
            'params': {
              'content': 'Please stand by while we re-open the integrity check for you ...'
            }
          },
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.integrityService.scrollToSelectedCompany();
    this.displayModal = true;
    this.displayLoader = true;

    // send to BE
    this.integrityService.reopenIntegrityCheck(
      $(event.target).closest('.result-container').attr('key'),
      $(event.target).closest('.result-container').attr('kvk'));
  }

  /**
   * mark request as seen
   */
  markRequestForInformationSeen(params) {
    if (typeof params.key !== 'string'  || params.key === null) {
      console.log('We could not mark this request as seen');
      return;
    }
    // this.integrityService.setScrollToId(params.kvk);
    this.integrityService.setScrollToKey(params.key);
    this.integrityService.markRequestForInformationSeen(params.key);
  }

  /**
   * old mark request as seen on sending side
   * new it will execute all additional methods needed to happen on expanding an active box
   * 1. markRequestForInformationSeenSendingSide
   * 2. loadLoggedActivities
   * 3. loadRequestsHistory
   */
  onExpandActiveBox (params) {
    console.log(params);
    if (typeof params.key !== 'string'  || params.key === null) {
      console.log('We could not mark this request as seen');
      return;
    }
    // this.integrityService.setScrollToId(params.kvk);
    this.integrityService.setScrollToKey(params.key);
    this.integrityService.markRequestForInformationSeenSendingSide(params.key);
    this.integrityService.loadRequestsHistory(params.key, params.targetGroup, params.eventTarget);
    // only if logging feature is enabled and targetGroup is Active or Closed Integrity Checks
    if (  this.integrityService.getSystemProperty('isLogActivitiesEnabled')
          && (params.targetGroup === 'Active Integrity Checks' || params.targetGroup === 'Closed Integrity Checks') ) {
      this.integrityService.loadLoggedActivities(params.key, params.targetGroup, params.eventTarget);
    }
  }

  /**
   * query content builder component
   * @param targetGroup the type of the group we want to query, enum(
   * 'Active Ingerity Checks',
   * 'Close integrity Checks',
   * 'Request Information',
   * 'Flagged Companies')
   */
  queryTargetGroup(targetGroup): ContentBuilderComponent {
    return this.builderComponents.find(function(element) {
      return element.groups[0].group_name === targetGroup;
    });
  }

  /**
   * query element of targeted builder component
   */
  queryTargetElement(key, targetGroup) {
    return targetGroup.groups[0].result_details.find(function(element) {
      return element.key === key;
    });
  }

  /**
   * initiating a recovery from a partial integrity check
   */
  doPartialRecovery (params) {
    // console.log(params);
    // return;
    if (typeof params.key !== 'string'  || params.key === null) {
      console.log('We could not initiate a recovery from partial integrity check');
      return;
    }
    // this.integrityService.setScrollToId(params.kvk);
    this.integrityService.setScrollToKey(params.key);
    this.integrityService.initiatePartialRecovery(params.key, params.targetGroup);
  }

}
