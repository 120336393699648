import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation  } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../../elements.service';
;

@Component({
  selector: 'app-related-party-content-investor-side',
  templateUrl: './related-party-content-investor-side.component.html',
  styleUrls: ['./related-party-content-investor-side.component.scss']
})
export class RelatedPartyContentInvestorSideComponent implements OnInit {

  @Input() element;
  @Input() relatedParty;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  constructor(
    private helperService: HelperServiceService,
    private elementService: ElementsService,
    private idVerificationService : IdVerificationService,
  ) { }

  ngOnInit(): void {
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onDisplayLoader(emitPayload: boolean) {
      if (emitPayload) {
        this.displayLoader.emit(emitPayload);
      }
    }

    /* COMPONENT SPECIFIC FUNCTIONS */

}
