<ng-template #lightBox>
    <div class="form-lightbox d-flex justify-content-center">
        <form>
            <div class="form-container">
                <a class="close-form-button t">
                    <img src="assets/images/close_form_icon.png">
                </a>
                <h1 class="form-type-heading" *ngIf="formType">{{ formType }}</h1>
                <h2 class="form-title-heading" *ngIf="formTitle">
                    <img src="assets/images/form_title_triangle.png">
                    {{ formTitle }}
                </h2>
                <div class="form-content-container">

                    {{ content }}

                    <!-- BUTTONS  -->
                    <div class="element-wrapper">
                        <button class="form-button form-button-1" [attr.isValidator]="formValidator">
                            {{ btnOkText }}
                        </button>
                            <button class="form-button form-button-2">
                            {{ btnCancelText }}
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </div>
    </ng-template>
