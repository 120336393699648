import { Component, OnInit, Input, TemplateRef, OnChanges, Output, EventEmitter, ChangeDetectorRef,
  ChangeDetectionStrategy, OnDestroy, AfterViewChecked, AfterViewInit, AfterContentInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LighboxContentComponent } from '../lighbox-content/lighbox-content.component';
import * as $ from 'jquery';
import { UiService } from '../ui.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {


  content: any;
  model: any = {};
  modalRef: BsModalRef;
  @Input() displayLoader = false;
  @Input() displayModal: boolean;
  @Input() formType: string;
  @Input() formTypeAlignment = 'left';
  @Input() formClass: string;
  @Input() formTitle: string;
  @Input() lightboxClass: any;
  @Input() formSubTitle: string;
  @Input() contentDOM: any[];
  @Input() btnOkText: string;
  @Input() formValidator: string;
  @Input() btnOkEnabled: boolean;
  @Input() btnCancelText: string;
  @Input() btnCancelEnabled: boolean;
  @Input() btnContentElements: string;
  @Output() confirmLightbox = new EventEmitter();
  @Output() closeLightbox = new EventEmitter();

  // for route manipulation
  // needed for fund lightboxes
  routerSubscription: Subscription;
  evidenceSubscription: Subscription;
  detectChangesSubscription: Subscription;
  originalUrl: string;
  mobileSteps;
  evidenceMobileDropdown;

  constructor
    (private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private uiService: UiService,
    private idVerificationService: IdVerificationService,
    private location: Location,
    private router: Router ) {
    }

  ngOnInit() {
    this.parseContentElements();
    this.originalUrl = this.location.path();
    if (this.lightboxClass && this.lightboxClass.routeManipulation) {
      this.routerSubscription = new Subscription();
      this.location.go(this.location.path() + '/');
      this.location.go(this.location.path() + '/lightbox');
      this.routerSubscription.add(this.location.subscribe(event => {
        $('.close-form-button,#lightBoxCloseImage').trigger('click');
      }));
    }

    // add event listener
    if (this.lightboxClass && this.lightboxClass.confirmDialogue) {
      window.addEventListener('beforeunload', this.beforeUnloadFunc, true);
    }
  }

  ngAfterContentInit() {
          // subscribe to the evidence if marked
    if (this.lightboxClass && this.lightboxClass.evidenceMobileDropdown) {
      this.evidenceSubscription = this.idVerificationService.detectReviewDataLoadedIn.subscribe(response => {
        const evidenceList = $('.document-category-main-all');
        // we need to build the steps for the mobile view based on the info from the HTML
        let steps = [];
        evidenceList.each(function() {
          const nameSelector = $(this).find('h3 div').clone().remove().end().text()?.trim();
          steps.push({
            'name' : nameSelector ,
            'subName' :  $(this).find('h3 span').text()?.trim(),
            'evidenceKey' : $(this).attr('data-evidenceKey'),
            'disabled' : $(this).find('a.document-category-main').hasClass('evidence-disabled'),
            'active' : $(this).find('a.document-category-main').hasClass('category-active'),
            'basicField' : $(this).find('h3').hasClass('basic-field-name'),
          });

        });
        if(steps.length > 1) {
          this.mobileSteps = steps;
          this.evidenceMobileDropdown = true;
        }
        this.cd.detectChanges();
      });
    }
  }

  beforeUnloadFunc(event) {
    event.returnValue = '-';
    return 'You will lose any unsaved data if you close this window. Are you sure you want to proceed?';
  }

  ngOnChanges(changes) {
    if (changes.displayModal) {
      if (changes.displayModal.currentValue === true) {
        // hide body scrollbar
        $('body').css('overflow', 'hidden');
      } else {
        $('body').css('overflow', 'auto');
      }
    }
  }

  OnConfirmLightbox(event) {
    event.preventDefault();
    if ($(event.target).attr('isValidator') && $(event.target).attr('isValidator') === 'true') {
      if (!this.uiService.validateLightboxFields( event )) {
        return;
      }
    }
    this.confirmLightbox.emit(event);
  }

  OnCloseLightbox(event) {
    let classes = '';
    if ($(event.target).hasClass('close-form-button')) {
      classes =  $(event.target).attr('class');
    } else {
      classes =  $(event.target).closest('.close-form-button').attr('class');
    }
    let lightboxType = 'displayModal';
    if (classes && classes.includes('top-dialog-box')) {
      lightboxType = 'topDisplayModal';
    }
    if (classes && classes.includes('foremost-dialog-box')) {
      lightboxType = 'foremostDisplayModal';
    }
    this[lightboxType] = false;
      if (event !== undefined) {
        event.preventDefault();
      }
      // we reload the fund if we sent a flag that we should
      if (event && this.lightboxClass && this.lightboxClass.reloadfund) {
        // console.log('CLOSE BUTTON COLLAPSED');
        this.idVerificationService.loadAdditionalInvestorDetail(
          this.idVerificationService.getActiveProject(), this.lightboxClass.reloadfund, true);
      }
      // we reload the profile if there was whitelisting
      if(event && this.lightboxClass && this.lightboxClass.reloadAPS && this.idVerificationService.getComplianceCheckWasWhitelisted())
      {
        if( this.idVerificationService.getProjectType() === 'id-verification'){
          this.idVerificationService.loadAdditionalPersonDetails(this.idVerificationService.getActiveProject(),
          this.lightboxClass.reloadAPS,
          false);
          }
          else{
            this.idVerificationService.loadAdditionalInvestorDetail(
              this.idVerificationService.getActiveProject(), this.lightboxClass.reloadAPS, true);
          }
          this.idVerificationService.setComplianceCheckWasWhitelisted(false);
      }
      // remove beforeunload event if there's a special marker
      if (this.lightboxClass && this.lightboxClass.confirmDialogue) {
        window.removeEventListener('beforeunload', this.beforeUnloadFunc, true);
      }

      this.location.go(this.originalUrl);
      this.closeLightbox.emit(event);
      if (this.lightboxClass && this.lightboxClass.reviewFlowProcess) {
        this.idVerificationService.reviewWindowClosed.next();
        //remove the DV iframe if it exists
        $('#dvIframe').remove();
        setTimeout(function () {
          $('#dvIframe').remove();
        }, 1500);
      }
  }

  OnCloseLightboxAlternate(event){
    // this is in case when we want to reload when we close the lightbox but not always, just in specific cases this will be triggerd
    // for example clicking on a certain button will reload the fund but not clicking on the close icon
    if (event !== undefined) {
      event.preventDefault();
    }
    this.lightboxClass.reloadfund =  $(event.target).attr('data-reloadfund');
    $('#lightBoxCloseImage').trigger('click');
  }

  OnCloseLightboxCheck(event) {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (confirm('You will lose any unsaved data if you close this window. Are you sure you want to proceed?')) {
      this.OnCloseLightbox(event);
    }
  }

  parseContentReturn(returnedAction) {
    // console.log('emitting from parse content', returnedAction);
    this.confirmLightbox.emit(returnedAction);
    return;
  }

  // temp, this needs to go into the calling component
  OnSendRequestInformation() {
    this.confirmLightbox.emit();
  }

  parseContentElements() {
    this.content = `
    <!-- SUBTITLE -->
                <h3 class="form-subtitle" *ngIf="formSubTitle">{{ formSubTitle }}</h3>

                <!-- FORM IMAGE -->
               <div class="element-wrapper">
                    <img class="form-image" src="assets/images/form_image_example.png">
               </div>

                <!-- PARAGRAPHS -->
                <p class="form-paragraph">A service provider needs your information about one of your previous client entities.</p>
                <p class="form-paragraph">Please answer the following questions. Your answers will be sent to the requesting service provider.</p>

                <!-- TEXT INPUT -->
                <div class="element-wrapper">
                    <span class="input-label">
                       What was the nature of the integrity risk?
                    </span>
                    <div contenteditable="true" class="text-input-div"></div>
                </div>

                <!-- RADIO INPUT  -->
                <div class="element-wrapper">
                    <span class="input-label">
                        Does the integrity risk relate to a person not directly related to the entity?
                    </span>
                    <input class="custom-radio-button" type="radio" id="test2" name="radio-group">
                    <label class="custom-radio-label" for="test2">Yes</label>
                    <input class="custom-radio-button" type="radio" id="test3" name="radio-group">
                    <label class="custom-radio-label" for="test3">No</label>
                </div>

                <!-- CHECKBOX  -->
                <div class="element-wrapper">
                    <span class="input-label">
                        Does the integrity risk relate to a person not directly related to the entity?
                    </span>
                    <div class="checkbox-container">
                        <div class="single-checkbox-wrapper">
                            <input class="custom-checkbox" type="checkbox" id="check_1" />
                            <label class="custom-checkbox-label" for="check_1">What was the nature of the integrity risk?</label>
                        </div>
                        <div class="single-checkbox-wrapper">
                            <input class="custom-checkbox" type="checkbox" id="check_2" />
                            <label class="custom-checkbox-label" for="check_2">What were the activities of the entity?</label>
                        </div>
                        <div class="single-checkbox-wrapper">
                            <input class="custom-checkbox" type="checkbox" id="check_3" />
                            <label class="custom-checkbox-label" for="check_3">Does the integrity risk relate to a person not directly related to the entity?</label>
                        </div>
                    </div>
                </div>

                <!-- DATEPICKER -->
                <div class="element-wrapper">
                    <span class="input-label">
                        Date of activity
                       <img class="datepicker-icon" src="assets/images/datepicker_icon.png">
                    </span>
                    <input id="datepicker" />
                </div>`;
  }



  clickEvidence(event) {
    const evidenceKey = $(event.target).attr('evidenceKey');
    const element = $(".document-category-main-all[data-evidenceKey='" + evidenceKey + "']");
    element.find('.clickable-element').trigger('click');
    const mainName = $(event.target).find('.sub-step-name').text();
    const subName = $(event.target).find('.sub-step-subname').text();


    this.toggleInactiveMobileSteps();
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  OnDisplayLoader(event) {
    console.log('In Content elements: should display loader!!!', event);
    this.displayLoader = event;
  }

  onNewInvestor() {
    this.idVerificationService.changeInformationRequestStep(8, {});
  }

  toggleInactiveMobileSteps() {
    $('.form-step-main:eq(0)').toggleClass('expand');
    $('.steps-inactive').toggleClass('d-none');
  }

  clickSubstep(event) {
    if ($(event.target).attr('clickMethod') === 'changeTemplateMainStep' ) {
      const localTemplateData = this.idVerificationService.getLocalTemplateData();
      localTemplateData.activeEdit = {'mainType' : 'mainRequest', 'type' :  $(event.target).attr('type'),
                                      'count' : parseInt($(event.target).attr('count'), 10)};
      this.idVerificationService.skipToStep(22, localTemplateData);
    } else if ($(event.target).attr('clickMethod') === 'changeTemplateRPStep') {
      const localTemplateData = this.idVerificationService.getLocalTemplateData();
      localTemplateData.activeEdit = {'mainType' : 'relatedParty', 'type' :  $(event.target).attr('type'),
                                      'count' : parseInt($(event.target).attr('count'), 10)};
      this.idVerificationService.skipToStep(22, localTemplateData);
    }
  }

  ngOnDestroy() {
    // always restore body scroll bar on lightbox close
    if (this.lightboxClass && this.lightboxClass.noScroll === true) {
    } else {
      $('body').css('overflow', 'auto');
    }
    if (this.routerSubscription !== undefined) {
      this.routerSubscription.unsubscribe();
    }
    if (this.evidenceSubscription !== undefined) {
      this.evidenceSubscription.unsubscribe();
    }
  }

}
