import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilderServiceService } from '../form-builder-service.service';

@Component({
  selector: 'app-form-builder-header',
  templateUrl: './form-builder-header.component.html',
  styleUrls: ['./form-builder-header.component.scss']
})
export class FormBuilderHeaderComponent implements OnInit {

  @Input() activeProjectList;
  @Output() formBuilderPreview: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formBuilderService: FormBuilderServiceService
  ) { }

  ngOnInit(): void {
  }

  previewForm($event, type) {
    $event.preventDefault();
    this.formBuilderPreview.emit({method: type});
  }

}
