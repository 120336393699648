// Get the HTMLComponent from the components listing.
const HTMLComponent = Formio.Components.components.htmlelement;
import paragraphComponentForm from './paragraph.form';

/**
 * Create a Header compoennt and extend from the HTMLComponent.
 */
class ParagraphExtendedComponent extends HTMLComponent {
  /**
   * Define the default schema to change the type and tag and label. 
   */
   static schema(...extend) {
    return super.schema({
          type: 'ParagraphExtendedComponent',
          label: "",
          any_other_fields: "",
    }, ...extend)};
  
  static get builderInfo() {
    return {
      title: 'Description',
      group: 'layout',
      icon: 'paragraph',
      weight: 2,
      schema: this.schema()
    };
  }

  render() {
    const submission = _.get(this.root, 'submission', {});
    this.tpl = `
        <p class="questionare-paragraph">${this.component.label}</p>`;
    // Note the use of the 'ref' tag, which is used later to find
    // parts of your rendered element.
    // If you need to render the superclass,
    // you can do that with
    // tpl+=super.render(element);
    // This wraps your template to give it the standard label and bulider decorations
    return Formio.Components.components.component.prototype.render.call(this, this.tpl);
  }  
}


ParagraphExtendedComponent.editForm = paragraphComponentForm;


Formio.Components.addComponent('ParagraphExtendedComponent', ParagraphExtendedComponent);
export default ParagraphExtendedComponent;
