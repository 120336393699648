<div 
[attr.data-evidenceKey]=evidence.key 
[attr.data-dvType]=evidence.originalEvidenceKey 
[ngClass]="{
    'investor-side' : element.investorSide,
    'sp-side' : !element.investorSide,
    'before-claim' : element.beforeClaim,
    'review-documents' : (element.beforeClaim || (element.investorSide && evidence.reviewState)),
    'non-interactive' : ((element.beforeClaim || (evidence.type === 'DigitalVerification' && evidence.claimedOn && !evidence.isResolved))
                        || (element.investorSide && evidence.reviewState)),
    'review-state' : (element.beforeClaim || (element.investorSide && evidence.reviewState))

    }" 
class="document-inner-entry  {{className}} {{classStatus}} "
(click)="generateClickOnBox($event)">
    <div class="document-upload-entry-image" [ngClass]="{'reviewState': element.beforeClaim}">
        <img [src]="idVerificationService.returnimgrlByType(evidence.type)">
    </div>
    <div class="document-upload-entry-detail">
        <h5 class="document-name">
            {{
            evidence.customEvidenceType ?
            elementService.parseBackendName(evidence.customEvidenceType) :
            evidence.verificationType ?
            helperService.translateVerificationType(evidence.verificationType) :
            helperService.translateEvidenceType(evidence.type)
            }}
        </h5>
        <div class="download-btn-wrapper expired-icon-wrapper" >
            <img class="expired-icon" *ngIf="evidence.expired && !element.investorSide" src="/assets/images/icon-triangle-orange.svg">
            <a *ngIf="showDownloadBtn" class="download-btn">
                <img (click)="downloadDocuments($event, evidence)" class="preview-download-icon" src="/assets/images/download.svg">
            </a>
        </div>
        <div *ngIf="!element.beforeClaim  && !element.investorSide" class="document-status">
            <div class="document-status-icon"></div>
            <div class="document-status-text">
                {{
                helperService.translateEvidenceStatus(evidence.status)
                }}
            </div>
        </div>
    </div>
</div>