<div class="toggle d-flex justify-content-center">
    <label class="toggle-switch mb-0"  [tooltip]="toggleTooltip">
        <!-- click -->
        <input 
        type = "checkbox" 
        id = "{{toggleId}}"
        [checked] = "toggleChecked"
        [disabled] = "toggleDisabled"
        (click) = "toggle.emit()">
        <span class="toggle-slider round"></span>
    </label>
</div>