
<!-- <div  style="background-color:#00889c; display: flex; align-items: center; height:100vh">
  <div class="unauth-message text-center mx-auto my-auto">
    NO MESSAGE
  </div>
</div> -->
<header *ngIf="elementsVisible && !errorState" class="d-flex flex-column {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}">

  <div class="sharing-info-container d-flex flex-column justify-content-center">
    <div *ngIf="currentStep === 'terms' || currentStep === 'finished'" class="sharing-info-logo d-flex justify-content-center">
      <img class="finos-logo-img" src = {{logo}} >
    </div>
    <h1 *ngIf="currentStep === 'terms'">{{'shareRequest.terms.title' | translate}}</h1>
    <h1 *ngIf="currentStep === 'finished'">{{'shareRequest.terms.title2' | translate}}</h1>
    <div  class="agreement-container">
      <div  *ngIf="currentStep === 'terms'">
        <div [innerHTML] = "'shareRequest.terms.content' | translate"></div>
        <div class="element-wrapper mt-3">
          <input class="custom-checkbox"
            type="checkbox"
            id="termsAndConditions"
            name="termsAndConditions"
            value="Terms and Conditions"
            [checked]="false"
            (change)="agreeToTerms($event)"
          >
          <label class="custom-checkbox-label unselectable"
            for="termsAndConditions"
            > {{'shareRequest.terms.checkbox' | translate}}</label>
        </div>
        <button
        (click)="agreeWithTermsClick()"
        id="provideInformationButton"
        [disabled]='true'
        class="form-button form-button-1 unselectable">{{'shareRequest.terms.button' | translate}}</button>
      </div>
      <div *ngIf="currentStep === 'sendEmail'">
        <h3>{{'shareRequest.sendEmail.title' | translate}}</h3>
        <h2>{{'shareRequest.sendEmail.subTitle' | translate}}</h2>
        <p [innerHTML] = "'shareRequest.sendEmail.emailInfo' | translate" class="email-info">

        </p>
        <a (click)="OnClaimEmail($event)" class="unselectable d-flex new-password-link">   {{'shareRequest.sendEmail.newPassword' | translate}} </a>
        <p class="mb-0 text-capitalize">{{'common.thanks' | translate}}!</p>
        <p><strong>Mesh ID</strong></p>
        <hr class="hr-email">
        <p [innerHTML] = "'shareRequest.sendEmail.support' | translate : {'supportLink' : 'support@meshid.com'}" class="subnote">
        </p>
      </div>
      <div *ngIf="currentStep === 'finished'">
        <img class="completed-image" src="/assets/images/request_screening.svg">
        <h2 class="mb-3">{{'shareRequest.completed.title' | translate}}</h2>
        <p [innerHTML] = "'shareRequest.completed.email' | translate : {'email' : email}" class="mb-3">
        </p>
        <p>{{'shareRequest.completed.close' | translate}}</p>
      </div>
      <div *ngIf="currentStep === 'error'">
        <img class="completed-image" src="/assets/images/request_screening.svg">
        <h2>{{'shareRequest.completed.error' | translate}}</h2>
      </div>
      <div class="loader-overlay d-none">
      </div>
    </div>
  </div>
</header>

