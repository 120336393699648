<header class="p-2 p-sm-3 error-page">
    <div class="header-top">
        <div class="navbar p-0">
            <a [routerLink]="['/']" class="navbar-brand">
                <img alt="Mesh ID logo" class="meshid-logo-img" src="assets/images/logo-alternate.svg">
            </a>
        </div>
    </div>
    <div class="error-msg-wrapper">
        <p class="error-msg" [innerHTML]="errorMsg"></p>
        <div *ngIf="tooltipErrorMsg">
            <app-tooltip [tooltipText]="tooltipErrorMsg" [classWrapper]="'invalid-link-page'"></app-tooltip>
        </div>
    </div>
</header>