<header class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}" [ngStyle]="{'background-color': projectColor}" *ngIf="!consent">
  <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar p-0">
        <a [routerLink]="['/']" class="navbar-brand"><img class="finos-logo-img" src ="{{logo}}" *ngIf="logo !== null"></a>
      </div>
  </div>
  <div class="d-flex flex-column flex-fill justify-content-center">
    <div class="signup-wrapper flex-column d-flex mx-auto p-4 py-5">
      <h1 class="dark-color p-2">{{h1}}</h1>
      <div class="message dark-color p-2">{{message}}</div>
      <div class="p-2">
        <div class="signup-label pt-4">SIGN UP FOR A DEMO</div>
        <input type="text"><button class="signup-btn">Sign up</button>
      </div>
    </div>
  </div>
  <div class="load-overlay d-flex position-fixed w-100 h-100" *ngIf="displayLoader">
    <img src="/assets/images/spinner.svg" width="66px" height="66px" class="my-auto mx-auto">
  </div>
  <app-lightbox *ngIf='displayModal'
    [formType]='formType'
    [formTitle]='formTitle'
    [formSubTitle]='formSubTitle'
    [btnOkText]='btnOkText'
    [formValidator] = 'formValidator'
    [btnCancelText]='btnCancelText'
    [displayModal]='displayModal'
    [displayLoader]='displayLoader'
    [contentDOM]="lightboxContent"
    (confirmLightbox)="onLightboxConfirm($event)"
    (closeLightbox)="onLightboxClose($event)"
    ></app-lightbox>
</header>
