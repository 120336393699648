import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IdVerificationService } from '../../id-verification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelperServiceService } from 'src/app/helper-service.service';


@Component({
  selector: 'app-id-verification-check',
  templateUrl: './id-verification-check.component.html',
  styleUrls: ['./id-verification-check.component.scss']
})
export class IdVerificationCheckComponent implements OnInit {

  private verificationKey: string;
  verificationKeySegments: string[];
  headerInfo: {};
  headerClasses: {};
  detectVerificationValidityStatus: Subscription;
  checkStatus: boolean;
  @Output() contentDOM: any[];

  constructor(private activatedRoute: ActivatedRoute,
              private idVerificationService: IdVerificationService,
              private helper: HelperServiceService) { }

  ngOnInit() {
    this.headerInfo = {
      'headerType': 'full',
      'headerTitle': 'Verification Check',
      'activeNavLink': 'idVerification'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };
    this.checkStatus = true;

    // check if verification key has been passed
    if (this.activatedRoute.snapshot.params.verificationKey !== undefined) {
      const verificationKey = this.generateRealVerificationKey(this.activatedRoute.snapshot.params.verificationKey);
      if (verificationKey !== '') {
        this.idVerificationService.checkVerificationValidity( verificationKey );
        this.contentDOM = [{
          'type': 'alertLoader',
          'class': 'mt-0',
        }];
      }
    } else {
      this.verificationKeySegments = ['', '' , '', '', ''];
    }

    this.detectVerificationValidityStatus = this.idVerificationService.detectVerificationValidityCheckStatus.subscribe(response => {
      if (response === null) {
        this.displayAlert(false);
        return;
      }
      this.displayAlert(true, response);
    },
    error => {
      this.displayAlert(false);
    });

  }

  private generateRealVerificationKey(verificationKey: string) {
    const alphaNumericPatternV1 = /^([0-9a-zA-Z]{8})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{4})-([0-9a-zA-Z]{12})$/;
    const alphaNumericPatternV2 = /^[0-9a-zA-Z]{32}$/;
    const alphaNumericPatternV3 = /^([0-9a-zA-Z]{8})([0-9a-zA-Z]{4})([0-9a-zA-Z]{4})([0-9a-zA-Z]{4})([0-9a-zA-Z]{12})$/;
    let verificationKeyParsed = '';
    let verificationKeySegments = [];
    if (verificationKey.match(alphaNumericPatternV1)) {
      this.verificationKeySegments = verificationKey.split('-');
      // just prepend VFE:
      verificationKeyParsed = 'VFE:' + verificationKey.toLowerCase();
    } else if (verificationKey.match(alphaNumericPatternV2)) {
      // split by length
      verificationKeySegments = Array.from(alphaNumericPatternV3.exec(verificationKey));
      verificationKeySegments.shift();
      this.verificationKeySegments = verificationKeySegments;
      verificationKeyParsed = 'VFE:' + (verificationKeySegments.join('-').toLowerCase());
    } else {
      this.contentDOM = [{
        'type': 'alert',
        'class': 'mt-0',
        'params': {
          // set to alert-container-1 if green alert needs to be displayed
          'alert_type': 'alert-container-2 fixed-mob-height',
          'alert_title': 'Invalid input',
          'alert_content': 'The link is invalid, The length of the key match the following pattern: 8-4-4-4-12!'
        }
      }];
    }
    return verificationKeyParsed;
  }

  displayAlert(checkStatus: boolean, response = {}) {
    console.log(response);
    let displayMob = false;
    // if tablet or mobile
    if ($(window).width() < 992) {
      displayMob = true;
      $('.id-verification-check-container .ic-input-container').attr('style', 'display: none !important');
    }
    this.contentDOM = [{
      'type': 'alert',
      'closeBtn': (displayMob) ? true : false,
      // add fullHeight for full screen background transparent white
      'wrapperClass': (displayMob) ? 'd-flex position-relative align-items-center p-4' : 'p-4',
      'class': 'mt-0',
      'params': {
        // set to alert-container-1 if green alert needs to be displayed
        'alert_type': (displayMob)
                      ? (checkStatus) ? 'alert-container-1 fixed-mob-height'  : 'alert-container-2 fixed-mob-height'
                      : (checkStatus) ? 'alert-container-1'  : 'alert-container-2',
        'alert_title': (checkStatus) ? 'Verification is valid' : 'Not Found',
        'alert_content':
        (checkStatus && this.helper.formatISODate(response['date'])) ?
            'The code you entered matches a verification issued on ' + this.helper.formatISODate(response['date'])
          :
        (checkStatus && !this.helper.formatISODate(response['date'])) ?
            'The code you entered matches a valid verification'
          :
            'This code doesn’t correspond with any of our issued verifications. Please check for typos or double check the source of the pdf.'
      }
    }];
  }

  OnSubmitVerificationKeyForReview(displayMob = false) {


    const verificationKeySegments = [$('#seg1').val(), $('#seg2').val(), $('#seg3').val(), $('#seg4').val(), $('#seg5').val()];
    if (displayMob) {
      $('.id-verification-check-container .ic-input-container').attr('style', 'display: none !important');
    }
    if (this.validateForm()) {
      this.idVerificationService.checkVerificationValidity( 'VFE:' + ( verificationKeySegments.join('-').toLowerCase()) );
      this.contentDOM = [{
        'type': 'alertLoader',
        'wrapperClass': (displayMob) ? 'position-relative align-items-center p-4' : 'p-4',
        'class': 'mt-0',
      }];
    } else {
      this.contentDOM = [{
        'type': 'alert',
        'closeBtn': (displayMob) ? true : false,
        // add fullHeight for full screen background transparent white
        'wrapperClass': (displayMob) ? 'd-flex position-relative align-items-center p-4' : 'p-4',
        'class': 'mt-0',
        'params': {
          // set to alert-container-1 if green alert needs to be displayed
          'alert_type': (displayMob) ? 'alert-container-2 fixed-mob-height' : 'alert-container-2',
          'alert_title': 'Invalid input',
          'alert_content': 'The fields accept only alphanumeric characters and length of the fields above should folow the following pattern: 8-4-4-4-12!'
        }
      }];
    }
  }

  closeAlert() {
      $('.id-verification-check-container .ic-input-container').attr('style', '');
      delete this.contentDOM;
  }

  validateForm() {
    const alphaNumericPatternLarge = /^[0-9a-zA-Z]{12}$/;
    const alphaNumericPatternBig = /^[0-9a-zA-Z]{8}$/;
    const alphaNumericPatternSmall = /^[0-9a-zA-Z]{4}$/;
    let res = true;
    $.each($('input'), function(index) {
      console.log('checking', $(this).val());
      if (index === 0) {
        if (!($(this).val() as string).match(alphaNumericPatternBig)) {
          res = false;
        }
      } else if (index === 4) {
        if (!($(this).val() as string).match(alphaNumericPatternLarge)) {
          res = false;
        }
      } else {
        if (!($(this).val() as string).match(alphaNumericPatternSmall)) {
          res = false;
        }
      }

    });
    return res;
  }

  /**
   * moves the cursor to the next INPUT sibling if it exists
   * @param event event
   * @param maxlength the max num of characters that are supported by the current input field
   */
  movecur(event, maxlength) {
    // as next sibling element is span, get the second next
    const nextInput = event.srcElement.nextElementSibling.nextElementSibling;
    const target = event.target || event.srcElement;

    if (nextInput == null)  {
        return;
    }  else if (target.value.length === maxlength) {
        nextInput.focus();   // focus to next sibling if not null
    }
  }

}


