import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { UiService } from 'src/app/ui/ui.service';
import { ElementsService } from '../elements.service';

declare var google: any;

@Component({
  selector: 'app-parse-evidence-fields',
  templateUrl: './parse-evidence-fields.component.html',
  styleUrls: ['./parse-evidence-fields.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParseEvidenceFieldsComponent implements OnInit {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @ViewChild('gautocomplete') addresstextElement: ElementRef;
  map: google.maps.Map;
  @ViewChild('mapWrapper') mapElement: ElementRef;
  autocomplete: any;
  selectedProfile: any = {};
  noSelectedTypeError = false;
  showRelatedProfile = false;
  relatedProfiles: any = [];
  confirmationMode = false;
  currentlySelectedCountry;
  showAdditionalInformation;
  private observerHack = new MutationObserver(() => {
    this.observerHack.disconnect();
    // this.addresstextElement.nativeElement.setAttribute('autocomplete', 'street-address');
    this.addresstextElement.nativeElement.setAttribute('autocomplete', 'chrome-off');
  });

  constructor(
      public elementService: ElementsService
    , private ref: ChangeDetectorRef
    , private uiService: UiService
    , private ipService: IpServiceService
    , private idVerificationService: IdVerificationService
    , public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.map = null;
    if(this.translate.currentLang == "nl" && this.element?.params?.evidenceCategories)
    {
      this.element.params.evidenceCategories.forEach(evidence => {
        if(evidence.name=="Title")
        {
          evidence.selectOptionsList=evidence.selectOptionsList.filter(title => title.key != 'Ms.' && title.key != 'Miss');
        }
      });
    }
  }

  isFieldChecked(field: string = '', fieldHide: string = '', id = '') {
    if (fieldHide) {
      return !($(`#NaturalPerson${fieldHide}`).prop('checked') || $(`#LegalPerson${fieldHide}`).prop('checked'));
    }
    return $(`#NaturalPerson${field}`).prop('checked') || $(`#LegalPerson${field}`).prop('checked');
  }

  OnInitGoogleAutocomplete(e) {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    if (!this.addresstextElement || this.autocomplete) {
      console.log('no address text element or autocomplete already initialized! returning ...');
      return;
    }
    this.addresstextElement.nativeElement.style.borderColor = '#00889c';
    // make sure google maps disable autocomplete for street-address
    this.observerHack.observe(this.addresstextElement.nativeElement, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });
    // $('.text-input.google-autocomplete').attr('autocomplete', 'chrome-off');
    this.autocomplete = new google.maps.places.Autocomplete(this.addresstextElement.nativeElement,
    {
        // componentRestrictions: { country: 'US' },
        types: ['address'],  // 'establishment' / 'address' / 'geocode'
        fields: ['address_components', 'geometry']
    });
    const _this = this;
    google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
        const place = _this.autocomplete.getPlace();
        _this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    const naturalAddressFields = {
        AddressStreet: 'getStreet'
      , AddressNumber: 'getStreetNumber'
      , AddressCity: 'getCity'
      , AddressPostalCode: 'getPostCode'
      , AddressCountry: 'getCountry'
      , AddressCountryShort: 'getCountryShort'
    };
    const legalAddressFields = {
        RegisteredAddressStreet: 'getStreet'
      , RegisteredAddressNumber: 'getStreetNumber'
      , RegisteredAddressCity: 'getCity'
      , RegisteredAddressPostalCode: 'getPostCode'
      , RegisteredAddressCountry: 'getCountry'
      , RegisteredAddressCountryShort: 'getCountryShort'
    };

    if (this.element.id === 'LegalPerson') {
      for (const [key, value] of Object.entries(legalAddressFields)) {
        if (this.ipService[value](place)) {
          if (key === 'AddressCountry' || key === 'RegisteredAddressCountry') {
            let locPlace = this.ipService[value](place).replace(/ /g, '_');
            $(`[name="${key}"]`).val(locPlace);
            // if we cannot find the long name try the short name
            // this happened when using different localizations of chrome
            if (!$(`[name="${key}"]`).val()) {
              const name_short = this.ipService['getCountryShort'](place);
              locPlace = this.idVerificationService.getCountryListAV().find(country => country.value === name_short);
              if (locPlace) {
                locPlace = locPlace.label.toString().replace(/ /g, '_');
                $(`[name="${key}"]`).val(locPlace);
              }
            }
          } else {
            $(`[name="${key}"]`).val(this.ipService[value](place));
          }
          this.uiService.clearInvalidationTarget($(`[name="${key}"]`));
        } else {
          if (!$(`[name="useClassicAddress"]`).prop('checked')) {
            $(`[name="useClassicAddress"]`).trigger('click');
          }
          this.uiService.validateInput($(`[name="${key}"]`));
        }
      }
    } else {
        for (const [key, value] of Object.entries(naturalAddressFields)) {
          if (this.ipService[value](place)) {
            if (key === 'AddressCountry' || key === 'RegisteredAddressCountry') {
              let locPlace = this.ipService[value](place).replace(/ /g, '_');
            $(`[name="${key}"]`).val(locPlace);
            // if we cannot find the long name try the short name
            // this happened when using different localizations of chrome
            if (!$(`[name="${key}"]`).val()) {
              const name_short = this.ipService['getCountryShort'](place);
              locPlace = this.idVerificationService.getCountryListAV().find(country => country.value === name_short);
              if (locPlace) {
                locPlace = locPlace.label.toString().replace(/ /g, '_');
                $(`[name="${key}"]`).val(locPlace);
              }
            }
            } else {
              $(`[name="${key}"]`).val(this.ipService[value](place));
            }
            this.uiService.clearInvalidationTarget($(`[name="${key}"]`));
          } else {
            if (!$(`[name="useClassicAddress"]`).prop('checked')) {
              $(`[name="useClassicAddress"]`).trigger('click');
            }
            this.uiService.validateInput($(`[name="${key}"]`));
          }
        }
    }

    if ($('#LocationInputField').length > 0) {
      $('#LocationInputField').val(this.ipService.getAddrLocation(place, 'string') as string);
    }
    this.initializeMap(place.geometry.location.lat(), place.geometry.location.lng());
    this.ref.detectChanges();

    // show the manual fields everytime an address is added
    if(!$('input[name*="useClassicAddress"]').prop('checked')) {
      $('input[name*="useClassicAddress"]').siblings('label').trigger('click');
    }

  }

  initializeMap(lat, long) {
    // console.log('initializing map with lat', lat, 'long', long);
    // this.addressImageURL = 'https://maps.google.com/maps?q=,139.664123&hl=en&z=13&amp;output=embed';
    const lngLat = new google.maps.LatLng(lat, long);

    const mapOptions = {
      zoom: 13,
      center: lngLat,
      scrollwheel: false
    };

    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      const marker = new google.maps.Marker({
        position: lngLat
      });
      marker.setMap(this.map);
      this.ref.detectChanges();
    }, 500);
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  parseDate(value) {
    return value;
  }

  selectValueIsValid(value, options) {
    if(!value || value === '') {
      return false;
    }
    value = value.trim();
    if(options && options.length){
      for(let i=0; i< options.length; i++)
      {
        if(options[i].key === value)
        {
          return true;
        }
      }
    }
    return false;
  }

  textInputValueisValid(value) {
    if(!value) {
      return false;
    }
    value = value.trim();
    if(value === '') {
      return false;
    }
    return true;
  }

  onShowAdditionalInformation() {
    this.showAdditionalInformation = true;
    const countryElement = $('select[name*="CountryOfRegistration"]');
    this.currentlySelectedCountry = $('select[name*="CountryOfRegistration"]').val();
    countryElement.hide();
    const countryElementParent = countryElement.closest('.element-wrapper.input-transparent');
    countryElementParent.hide();
  }

  onCancelAdditionalInformation() {
    this.showAdditionalInformation = false;
    const countryElement = $('select[name*="CountryOfRegistration"]');
    this.currentlySelectedCountry = undefined;
    countryElement.show();
    const countryElementParent = countryElement.closest('.element-wrapper.input-transparent');
    countryElementParent.show();
  }
}
