import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { ElementsService } from '../elements.service';

@Component({
  selector: 'app-entry-notifications-sp-side',
  templateUrl: './entry-notifications-sp-side.component.html',
  styleUrls: ['./entry-notifications-sp-side.component.scss']
})
export class EntryNotificationsSpSideComponent implements OnInit {

  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  constructor(
      public elementService: ElementsService
    , private idVerificationService: IdVerificationService
  ) { }

  ngOnInit(): void {
  }

   /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onEmit(emitPayload) {
      if (emitPayload) {
        this.contentReturn.emit(emitPayload);
      }
    }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
    onDisplayLoader(emitPayload: boolean) {
      if (emitPayload) {
        this.displayLoader.emit(emitPayload);
      }
    }

}
