import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { FormBuilderServiceService } from '../form-builder-service.service';

@Component({
  selector: 'app-form-builder-list-of-contracts',
  templateUrl: './form-builder-list-of-contracts.component.html',
  styleUrls: ['./form-builder-list-of-contracts.component.scss']
})
export class FormBuilderListOfContractsComponent implements OnInit, OnDestroy {

  myOrganisationTemplates = null;
  copyFrom = null;
  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  refreshType: string;
  deleteForm = null;
  publishForm = null;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private idVerification: IdVerificationService,
    public formBuilderService: FormBuilderServiceService
  ) { }

  ngOnInit() {
    this.userService._organisationUpdate
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(orgId => {
      if (orgId) {  // orgId is initially null
        this.loadMyOrgIdTemplates();
      }
    });
  }

  async loadMyOrgIdTemplates(): Promise<void> {
    // 1. refresh the templates cache
    await this.userService
        .getEndPoint(`api/pdf/templates/cache-full`
        , {responseType: 'json'}, {observe: 'body'}, 'verification')
        .toPromise()
        .then(response => {
          // Cache is refreshed
        })
        .catch(error => console.error(error));

        // 2. receive the list of templates
    this.userService
        .getEndPoint(`api/pdf/templates/templates-and-drafts/${this.userService.getUserOrganizationId()}`
        , {responseType: 'json'}, {observe: 'body'}, 'verification')
        .toPromise()
        .then(response => {
          this.idVerification.parseContractTemplatesWithTypes(response, 'funds');
          this.setMyOrganisationTemplates(this.idVerification.organizationContractTemplates);
        })
        .catch(error => console.error(error));
  }

  setMyOrganisationTemplates(templates) {
    this.myOrganisationTemplates = templates;
    this.formBuilderService.myOrganisationTemplates = templates;
  }

  AddNewForm() {
    $('.button-add').addClass('d-none');
    $('.new-form-choice').removeClass('d-none');
  }

  CreateNewForm() {
    if (!!this.formBuilderService.currentTemplate) {
      this.DisplayConfirmNewForm();
    } else {
      this.formBuilderService.togglePreviewList();
    }
  }

  DisplayConfirmNewForm() {
    // build the content for the Create new project lightbox
    this.formType = `Confirm starting a new template`;
    this.formClass = '';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = '';
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'delete_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `You are currently editing template <strong>${this.formBuilderService.getNameBySlug(this.formBuilderService.currentTemplate)}</strong>. Are you sure you want to start a new form? Any changes made to the current form will not be saved!`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Ok',
                // 'id': 'accept',
                'type': 'button',
                'action': 'onCreateNewFormAccept',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'type': 'button',
                'action': 'onDeleteTemplateReject',
                'class': 'form-button-2',
                'display': true
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onCreateNewFormAccept() {
    this.formBuilderService.clearCurrentTemplate();
    this.formBuilderService.togglePreviewList();
  }

  CloseNewForm() {
    $('.button-add').removeClass('d-none');
    $('.new-form-choice').addClass('d-none');
  }

  OnFormEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    // preferedDraft = return draft version if exists, otherwise attempt to load publish version of the template
    this.formBuilderService.loadMyForm(closestCard.find('.body').attr('data-template-key'), closestCard.find('.body').attr('data-template-mode'), 'preferedDraft');
  }

  toggleActionsMenu(event) {
    event.preventDefault();
    $(event.target).closest('.actionsMenuWrapper').find('ul').toggleClass('d-none');
    $(event.target).closest('.actionsMenuWrapper').find('.action-icon').toggleClass('icon-minus');
  }

  OnCopyFrom(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    this.copyFrom = closestCard.find('.body').attr('data-template-key');
    this.DisplayEnterNameCopyForm(closestCard.find('.body').attr('data-template-name'));
  }

  OnFormDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    const closestCardBody = $(event.target).closest('.card').find('.body');
    this.deleteForm = {
      templateKey: closestCardBody.attr('data-template-key'),
      templateMode: closestCardBody.attr('data-template-mode'),
    };

    this.DisplayConfirmDeleteForm(
      closestCardBody.attr('data-template-name')
    );
  }

  async copyMyForm(newName) {

    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {'newName': newName};
      await this.userService
          // .postEndPoint('api/pdf/templates/'
          //   + this.copyFrom
          //   + '/copy/'
          //   + this.userService.getUserOrganizationId()
          //   , payload
          //   , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .postEndPoint('api/pdf/templates/'
            + this.copyFrom
            + '/template/'
            + this.userService.getUserOrganizationId()
            + '/copy'
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(schema => {
            this.formBuilderService.formattedJson =  JSON.parse(schema[0].data);
            this.formBuilderService.setFormJson(this.formBuilderService.formattedJson, true);
            this.formBuilderService.togglePreviewList();
          })
          .catch(error => console.error(error));
    }
  }

  OnFormUnpublish(event) {
    this.OnAttemptPublishForm(event, 'unpublish');
  }

  OnFormPublish(event) {
    this.OnAttemptPublishForm(event, 'publish');
  }

  OnAttemptPublishForm(event, mode) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest('.card');
    const closestCardBody = $(event.target).closest('.card').find('.body');
    console.log($(event.target), $(event.target).closest('.card'), closestCardBody.attr('data-template-key'), closestCardBody.attr('data-template-mode'));
    const templateKey = closestCardBody.attr('data-template-key');
    const templateName = closestCardBody.attr('data-template-name');
    const templateMode = closestCardBody.attr('data-template-mode');

    this.publishForm = {
      templateKey: templateKey,
      templateName: templateName,
      templateMode: templateMode
    }

    if (templateKey && (templateMode === 'draft' || templateMode === 'published')) {
      this.ConfirmPublishExitingForm(mode);
    }
  }

  ConfirmPublishExitingForm(mode) {
    this.formType = `Confirm ${(mode === 'publish') ? 'publishing' : 'unpublishing'} a template`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top text-center px-4 mt-5 location-text',
            'params': {
              'content': 'Please confirm your action'
            }
          },
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `You are about to update the content and/or status of the "<strong>${this.publishForm.templateName}</strong>" template. Please confirm that this is intentional as the action is non-reversable!`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Ok',
                // 'id': 'accept',
                'action': (mode === 'publish') ? 'onConfirmPublishExistingForm' : 'onConfirmUnpublishExistingForm',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'action': 'onCancelForm',
                'class': 'form-button-2',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onConfirmPublishExistingForm() {
    this.publishMyForm(this.publishForm.templateKey, 'publish');
    this.displayModal = false;
  }

  onConfirmUnpublishExistingForm() {
    this.publishMyForm(this.publishForm.templateKey, 'unpublish');
    this.displayModal = false;
  }

  async publishMyForm(newName, mode) {
    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = {
        'newName': newName
      };
      await this.userService
          .postEndPoint('api/pdf/templates/'
            + newName
            + '/template/'
            + this.userService.getUserOrganizationId()
            + `/${mode}`
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(response => {
            this.idVerification.parseContractTemplatesWithTypes(response, 'funds');
            this.setMyOrganisationTemplates(this.idVerification.organizationContractTemplates);
            console.log('my org templates after delete', this.myOrganisationTemplates);
            this.displayLoader = false;
            this.displayModal = false;
          })
          .catch(error => console.error(error));
    }
  }

  async deleteMyForm() {
    // create a copy of the form
    if (this.userService.getUserOrganizationId() && this.deleteForm) {
      const payload = {'templateKey': this.deleteForm.templateKey, 'templateMode': this.deleteForm.templateMode};
      await this.userService
          // .postEndPoint('api/pdf/templates/'
          //   + this.copyFrom
          //   + '/copy/'
          //   + this.userService.getUserOrganizationId()
          //   , payload
          //   , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .postEndPoint('api/pdf/templates/'
            + this.deleteForm.templateKey
            + '/template/'
            + this.userService.getUserOrganizationId()
            + '/delete'
            , payload
            , {responseType: 'json'}, {observe: 'body'}, 'verification')
          .toPromise()
          .then(response => {
            this.idVerification.parseContractTemplatesWithTypes(response, 'funds');
            this.setMyOrganisationTemplates(this.idVerification.organizationContractTemplates);
            console.log('my org templates after delete', this.myOrganisationTemplates);
            this.displayLoader = false;
            this.displayModal = false;
          })
          .catch(error => console.error(error));
    }
  }

  DisplayEnterNameCopyForm(suggestedName) {
    this.formType = `Clone a template`;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'copy_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'subtitle-alternate',
            'class': 'increased-margin-top  mt-3 location-text',
            'params': {
              'content': 'Name the new contract template, which includes the form and the related document templates'
            }
          },
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `Please enter the name for the new form that will be based on ${suggestedName}`
            }
          },
          {
            'type' : 'inputText',
            'params' : {
                'label': 'New contract template name',
                'id': 'newFormName',
                'name': 'newFormName',
                'content': `Copy of ${suggestedName}`,
                'required': 'true',
                'labelClass': 'font-weight-bold',
                'maxLength' : 60,
              },
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Save',
                // 'id': 'accept',
                'action': 'onCopyForm',
                'class': 'form-button form-button-1',
                'display': true
              },
              {
                'content': 'Go back',
                // 'id': 'rescan',
                'action': 'onCancelCopy',
                'class': 'form-button form-button-6 ',
                'display': true // TODO: check if user may edit the information
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  DisplayConfirmDeleteForm(suggestedName) {
    // build the content for the Create new project lightbox
    this.formType = `Confirm deleting a template`;
    this.formClass = '';
    this.formTitle = ''; // 'Financial Holdings B.V';
    this.btnOkText = '';  // 'Yes, I am sure';
    this.formValidator = '';
    this.btnCancelText = '';  // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = '';
    const group = {
      'section_class' : 'results d-flex flex-column justify-items-center p-0',
      'result_container_class': '',
      'is_collapsable': false,
      'form_id': 'delete_form',
      'result_details' : [
        {
        'group_result_title' : 'enter_name_for_the_new_form',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
        'elements' : [
          {
            'type': 'paragraph',
            'class' : 'text-center mb-3',
            'params': {
              'content':
                `Please confirm that you are sure you want to delete the template <strong>${suggestedName}</strong>`
            }
          },
          {
            'type': 'button_group',
            'params': [
              {
                'content': 'Delete',
                // 'id': 'accept',
                'type': 'button',
                'action': 'onDeleteTemplateAccept',
                'class': 'form-button-2',
                'display': true
              },
              {
                'content': 'Cancel',
                // 'id': 'rescan',
                'type': 'button',
                'action': 'onDeleteTemplateReject',
                'class': 'form-button form-button-1',
                'display': true
              }
            ]
          }
        ]
      }]
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onLightboxConfirm(returnedAction) {
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
    this.displayLoader = true;
  }

  onLightboxClose($event) {
    this.copyFrom = null;
    this.displayModal = false;
  }

  onCopyForm() {
    if ($('#newFormName').val() !== '') {
      this.copyMyForm(this.formBuilderService.slugify($('#newFormName').val()));
    } else {
      this.displayModal = false;
      alert('You must provide name for the new template');
    }
  }

  onDeleteTemplateAccept(event) {
    this.displayLoader = true;
    this.deleteMyForm();
  }

  onCancelCopy() {
    this.onCancelForm();
  }

  onDeleteTemplateReject() {
    this.deleteForm = null;
    this.onCancelForm();
  }

  onCancelForm() {
    this.onLightboxClose(null);
  }

  isBroken(template) {
    return template.type === 'empty';
  }

  OnFilterTemplates(event) {
    this.formBuilderService.filterTemplates(event.target.value);
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    // console.log('returned action', returnedAction);
    if (typeof(returnedAction.method) === 'string' && returnedAction.method !== '') { // allow only strings as acceptable method name
      let params = '';
      // if (Array.isArray(returnedAction.params)) {
        params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, 'is not declared as method!', error);
      }
    } else {
      console.log('**method name not string or empty string');
    }
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}
