import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,AfterContentInit } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { IpServiceService } from 'src/app/ip-service.service';
import { UiService } from 'src/app/ui/ui.service';
import { ElementsService } from '../elements.service';

declare var google: any;

@Component({
  selector: 'app-digital-verification-evidence-address-verification',
  templateUrl: './digital-verification-evidence-address-verification.component.html',
  styleUrls: ['./digital-verification-evidence-address-verification.component.scss']
})
export class DigitalVerificationEvidenceAddressVerificationComponent implements OnInit, AfterContentInit {

  autocomplete: any;
  @Input() element;
  @Input() digitalVerification;
  @Output() contentReturn = new EventEmitter<any>();
  @ViewChild('gautocomplete') addresstextElement: ElementRef;
  map: google.maps.Map;
  @ViewChild('mapWrapper') mapElement: ElementRef;
  addressImageURL: string;
  hasPresets;
  manualAddress;
  isReview;
  addressData;
  isLastElement;
  countryListAv;
  showManualAddressFields;
  allowedDocuments;
  existingAdddressDocuments;

  private observerHack = new MutationObserver(() => {
    this.observerHack.disconnect();
    this.addresstextElement.nativeElement.setAttribute('autocomplete', 'chrome-off');
  });

  constructor(
      private elementService: ElementsService
    , private ref: ChangeDetectorRef
    , private uiService: UiService
    , private ipService: IpServiceService
    , private idVerificationService: IdVerificationService,
    private helperService: HelperServiceService
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    this.manualAddress = this.digitalVerification && this.digitalVerification.verificationData &&
                         this.digitalVerification.verificationData.verificationPresets &&
                         this.digitalVerification.verificationData.verificationPresets.addressRequested === false;

    this.showManualAddressFields = this.digitalVerification && this.digitalVerification.verificationData &&
    this.digitalVerification.verificationData.verificationPresets &&
    this.digitalVerification.verificationData.verificationPresets.addressRequested === false &&
    this.digitalVerification.verificationData.verificationPresets.addressData;

    this.hasPresets  = this.digitalVerification.verificationData ?
                       this.digitalVerification.verificationData.verificationPresets : undefined;

    if (this.hasPresets && this.hasPresets.allowedDocuments) {
      this.allowedDocuments = this.hasPresets.allowedDocuments;
      this.existingAdddressDocuments = [];
      this.allowedDocuments.forEach(element => {
        this.existingAdddressDocuments.push({
          'type' : element,
          'counter': 1
        });
      });
    } else {
      this.existingAdddressDocuments = [
        {'type' : 'UtilityBill', 'counter' : 0},
        {'type' : 'BankStatement', 'counter' : 0},
        {'type' : 'CreditCardStatement', 'counter' : 0},
        {'type' : 'InsuranceAgreement', 'counter' : 0},
      ];
    }
    //
    this.isReview = this.element.params.reviewSide;
    //
    this.addressData = this.hasPresets && this.hasPresets.addressData;

    this.isLastElement = this.elementService.isLastDVEvidenceToReview(
      this.element.params.evidenceTypes,
      this.digitalVerification,
      this.element.params.reviewSide
    );
    this.countryListAv = this.idVerificationService.getCountryListAV();

  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
   onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  /**
   * Google autocomplete related methods:
   */
  OnInitGoogleAutocomplete(e) {
    this.getPlaceAutocomplete();
  }
  private getPlaceAutocomplete() {
    if (!this.addresstextElement || this.autocomplete) {
      console.log('no address text element or autocomplete already initialized! returning ...');
      return;
    }
    this.addresstextElement.nativeElement.style.borderColor = '#00889c';
    // make sure google maps disable autocomplete for street-address
    this.observerHack.observe(this.addresstextElement.nativeElement, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });
    // $('.text-input.google-autocomplete').attr('autocomplete', 'chrome-off');
    this.autocomplete = new google.maps.places.Autocomplete(this.addresstextElement.nativeElement,
    {
        // componentRestrictions: { country: 'US' },
        types: ['address'],  // 'establishment' / 'address' / 'geocode'
        fields: ['address_components', 'geometry']
    });
    const _this = this;
    google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
        const place = _this.autocomplete.getPlace();
        _this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    const AddressFields = {
      AddressInputField: 'getStreet'
    , NumberInputField: 'getStreetNumber'
    , CityInputField: 'getCity'
    , ZipInputField: 'getPostCode'
    , CountryInputField: 'getCountryShort'
  };

  console.log('PLACE', place);
  for (const [key, value] of Object.entries(AddressFields)) {
    if (this.ipService[value](place)) {
      if (key === 'CountryInputField') {
        let locPlace = this.ipService[value](place).replace(/ /g, '_');
        $(`[id="${key}"]`).val(locPlace);
        if (!$(`[id="${key}"]`).val()) {
          const name_short = this.ipService['getCountryShort'](place);
          locPlace = this.idVerificationService.getCountryListAV().find(country => country.value === name_short);
          if (locPlace) {
            locPlace = locPlace.label.toString().replace(/ /g, '_');
            $(`[id="${key}"]`).val(locPlace);
          }
        }
      } else {
        $(`[id="${key}"]`).val(this.ipService[value](place));
      }
      this.uiService.clearInvalidationTarget($(`[id="${key}"]`));
    } else {
      if (!$(`[name="useClassicAddress"]`).prop('checked')) {
        $(`[name="useClassicAddress"]`).trigger('click');
      }
      this.uiService.validateInput($(`[id="${key}"]`));
      console.log(key);
    }
  }

    // const streetNumber = (this.ipService.getStreetNumber(place)) ? `, ${this.ipService.getStreetNumber(place)}` : '';
    // // this.getControl('street').patchValue(`${this.getStreet(place)}${streetNumber}`, {onlySelf: true} );
    // // this.getControl('town').patchValue(this.getCity(place), {onlySelf: true});
    // // this.addresstextElement.nativeElement.value = `${this.ipService.getStreet(place)}${streetNumber}`;
    // $('#AddressInputField').val(this.ipService.getStreet(place));
    // $('#NumberInputField').val(streetNumber);
    // $('#CountryInputField').val(this.ipService.getCountryShort(place));
    // this.uiService.clearInvalidationTarget($('#CountryInputField'));
    // $('#CityInputField').val(this.ipService.getCity(place));
    // this.uiService.clearInvalidationTarget($('#CityInputField'));
    // const zip  = this.ipService.getPostCode(place);
    // if (zip || zip === '') {
    //   $('#ZipInputField').val(zip);
    //   this.uiService.clearInvalidationTarget($('#ZipInputField'));
    // }
    if ($('#LocationInputField').length > 0) {
      $('#LocationInputField').val(this.ipService.getAddrLocation(place, 'string') as string);
    }
    this.initializeMap(place.geometry.location.lat(), place.geometry.location.lng());
    this.ref.detectChanges();
  }

  initializeMap(lat, long) {
    console.log('initializing map with lat', lat, 'long', long);
    this.addressImageURL = 'https://maps.google.com/maps?q=,139.664123&hl=en&z=13&amp;output=embed';
    const lngLat = new google.maps.LatLng(lat, long);

    const mapOptions = {
      zoom: 13,
      center: lngLat,
      scrollwheel: false
    };

    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      const marker = new google.maps.Marker({
        position: lngLat
      });
      marker.setMap(this.map);
      this.ref.detectChanges();
    }, 500);
  }

  useClassicAddressToggle() {
    if ($('#useClassicAddress').is(':checked')) {
      $('.manualInput').removeClass('d-none');
    } else {
      $('.manualInput').addClass('d-none');
    }
  }

  parseAdressDataStreet(addressData) {
    if(addressData && addressData.Address && addressData.Number) {
      return addressData.Address.substring(0, addressData.Address.length - (`, ${addressData.Number}`).length);
    }
    return '';
  }

}
