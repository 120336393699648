import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { VerificationsService } from './verifications.service';
import { HelperServiceService } from '../helper-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-verifications-go',
  templateUrl: './verifications-go.component.html',
  styleUrls: ['./verifications.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class VerificationsGoComponent implements OnInit, OnDestroy {

  debugMode: boolean; // if set to true it will test local iframe embedding for debugging purposes
  unsubscribe$: Subject<void> = new Subject<void>();
  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  projectMessage: string;
  message: string;
  verificationKey: string;
  headerInfo: {};
  headerClasses: {};
  iframeSrc;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;
  displayLoader: boolean;
  iosUnsupported: boolean;
  androidUnsupported: boolean;
  isUnsupportedBrowser: boolean;
  isCustomLogo = false; // Variable for adding extra classes for logo size
  userAgent;
  countryList;
  resolved = false;
  configCompleted = false;
  preselectedCountry;
  showBackButton;
  backbuttonText;

  constructor(
              private activatedRoute: ActivatedRoute,
              private verificationsService: VerificationsService,
              private userService : UserService,
              private helper: HelperServiceService,
              private domSanitizer: DomSanitizer,
              private router: Router,
              public translate: TranslateService,
              private http: HttpClient,
              ) { }

  ngOnInit() {
    this.debugMode = false; // SET THIS TO TRUE TO DIRECTLY EMBED A SUCCESS OR FAILURE PAGE
    this.displayLoader = false;
    this.logo = null;
    this.projectColor = '#ffffff';
    // the default is already set even though background color is not retreived
    this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
    this.message = '';
    this.consent = false;
    this.project = '';
    this.iosUnsupported = false;
    this.isUnsupportedBrowser = false;
    this.androidUnsupported = false;
    this.userAgent = navigator.userAgent;
    this.headerInfo = {
      'headerType': 'full', // 'inline',
      'headerTitle': 'Integrity Check',
      'activeNavLink': 'integrityCheck'
    };
    this.headerClasses = {
      'backgroundClass': 'tealish-gradient-bg',
      'sectionClass': 'ic_height_full_page', // 'ic_height_auto',
    };

    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;


    this.displayLoader = true;
    this.http.get('/api/digital/verifications/' + this.verificationKey + '/identity',).subscribe(response => {
      this.displayLoader = false;
      this.preselectedCountry = response['verificationConfig'].country;
      if(!this.preselectedCountry)
      {
        this.preselectedCountry = "NLD"
      }

      this.showBackButton = window.top.location.href.includes('lightbox');
      this.backbuttonText = this.translate.instant('common.goBack');
      
      this.displayLoader = true;
      this.http.post('/api/digital/verifications/' + this.verificationKey + '/identity',
      {country: this.preselectedCountry }).subscribe(response => {
        if(response['projectLogo'])
        {
          this.logo = response['projectLogo'];
        }
        else this.logo = 'assets/images/logo-alternate.svg';
        this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(response['webLink']);
        this.displayLoader = false;
        this.configCompleted = true;
      }, error => {
        this.message = this.translate.instant('IDPCS.error');
        this.displayLoader = false;
        this.countryList = null;
      });

    }, error => {
      this.message = this.translate.instant('IDPCS.error');
      this.displayLoader = false;
      this.countryList = null;
    });




    const isSafari =
    !(/CriOS/).test(navigator.userAgent) &&
    !(/FxiOS/).test(navigator.userAgent) &&
    !(/OPiOS/).test(navigator.userAgent) &&
    !(/mercury/).test(navigator.userAgent) &&
    !(/EdgiOS/).test(navigator.userAgent);
    const isIos = (/(iPad|iPhone|iPod)/gi).test(navigator.userAgent);
    if (isIos && !isSafari) {
      this.iosUnsupported = true;
    }
    const browser = this.helper.myBrowser();
    if (!this.helper.isMobile() &&  browser !== 'Chrome' && browser !== 'Safari' && browser !== 'Firefox' && browser !== 'Edge') {
      this.isUnsupportedBrowser = true;
    }
    const isAndroid = navigator.userAgent.match(/(android)|(webOS)/i);
    const isAndroidSupportedBrowser = navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.match(/SamsungBrowser/i);
    if (isAndroid && !isAndroidSupportedBrowser) {
      this.androidUnsupported = true;
    }
    window.addEventListener('message', (event) => {
      // extract the data from the message event
      console.log('LOGGING IFRAME MESSAGE');
    });

    const _this = this;
    window.onmessage = function(e) {
      if (e.data === 'lightbox id-verification is completed GO') {
        console.log(e);
        _this.onJumioIframeCloseGO();
     }
   };

  }

  startVerification(event) {
    this.displayLoader = true;
    this.http.post('/api/digital/verifications/' + this.verificationKey + '/identity',
    {country: $('#jumio_go_country_list option:selected').val()}).subscribe(response => {
      this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(response['webLink']);
      this.displayLoader = false;
      this.configCompleted = true;
    }, error => {
      this.message = this.translate.instant('IDPCS.error');
      this.displayLoader = false;
      this.countryList = null;
    });
  }




  onJumioIframeCloseGO () {
    $('.iframe-verifications').remove();
    parent.postMessage('iframe is closed GO',undefined);
    const currentUrl = this.router.url; 
    const newUrl = currentUrl.replace('/lightbox', ''); 
    this.router.navigateByUrl(newUrl); 
  }


  goBackButton() {
    parent.postMessage('iframe is closed back',undefined);
    const currentUrl = this.router.url; 
    const newUrl = currentUrl.replace('/lightbox', ''); 
    this.router.navigateByUrl(newUrl); 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
